export default {
  App: {
    appBlack191F32: 'rgb(25, 31, 50)',
    'App Black 191F32': 'rgb(25, 31, 50)',
    custom_rgb229_229_229: 'rgb(229, 229, 229)',
    'Custom Color_4': 'rgb(229, 229, 229)',
    custom_rgba102_0_0_0: 'rgba(102, 0, 0, 0)',
    'Custom Color_5': 'rgba(102, 0, 0, 0)',
    appText2ndary7F868C: 'rgb(127, 134, 140)',
    'App Text 2ndary 7F868C': 'rgb(127, 134, 140)',
    custom_rgb170_167_163: 'rgb(170, 167, 163)',
    'Custom #aaa7a3': 'rgb(170, 167, 163)',
    custom_rgb242_164_93: 'rgb(242, 164, 93)',
    'Custom Color_13': 'rgb(242, 164, 93)',
    custom_rgb253_253_253: 'rgb(253, 253, 253)',
    'Custom Color_15': 'rgb(253, 253, 253)',
    peoplebitDarkBlue: 'rgb(0, 43, 75)',
    Peoplebit_Dark_Blue: 'rgb(0, 43, 75)',
    peopleBitLightBrown: 'rgb(138, 132, 125)',
    People_Bit_Light_Brown: 'rgb(138, 132, 125)',
    peoplebitOrange: 'rgb(240, 100, 84)',
    Peoplebit_Orange: 'rgb(240, 100, 84)',
    peoplebitSalmonRed: 'rgb(211, 83, 109)',
    Peoplebit_Salmon_Red: 'rgb(211, 83, 109)',
    peoplebitSapphire: 'rgb(0, 55, 75)',
    Peoplebit_Sapphire: 'rgb(0, 55, 75)',
    peoplebitStoneGray: 'rgb(172, 172, 172)',
    Peoplebit_Stone_Gray: 'rgb(172, 172, 172)',
    peoplebitTurquoise: 'rgb(6, 48, 63)',
    Peoplebit_Turquoise: 'rgb(6, 48, 63)',
    studilyForrestShade: 'rgb(126, 209, 172)',
    Studily_Forrest_Shade: 'rgb(126, 209, 172)',
    studilyWhiteShade1: 'rgb(227, 231, 249)',
    Studily_White_Shade_1: 'rgb(227, 231, 249)',
    studilyWhiteShade3: 'rgb(231, 235, 241)',
    Studily_White_Shade_3: 'rgb(231, 235, 241)',
    tabDivider: 'rgb(231, 235, 241)',
    Tab_Divider: 'rgb(231, 235, 241)',
    appBlueLowEmphasis: 'rgb(234, 245, 251)',
    'App Blue/Low Emphasis': 'rgb(234, 245, 251)',
    custom_rgb172_172_172: 'rgb(172, 172, 172)',
    'Custom #acacac': 'rgb(172, 172, 172)',
    custom_rgb240_100_84: 'rgb(240, 100, 84)',
    'Custom #f06454': 'rgb(240, 100, 84)',
    studilyMilkWhite: 'rgb(236, 240, 246)',
    Studily_Milk_White: 'rgb(236, 240, 246)',
    studilySkyBluePrimary: 'rgb(86, 184, 244)',
    Studily_Sky_Blue_Primary: 'rgb(86, 184, 244)',
    studilyWhiteShade5: 'rgb(243, 245, 249)',
    Studily_White_Shade_5: 'rgb(243, 245, 249)',
    appDisabledButtonText7F868C: 'rgb(127, 134, 140)',
    'App Disabled Button Text 7F868C': 'rgb(127, 134, 140)',
    appSunOrangeF4A100: 'rgb(244, 161, 0)',
    'App Sun Orange F4A100': 'rgb(244, 161, 0)',
    appBlueC7EAF3: 'rgb(199, 234, 243)',
    'App Blue C7EAF3': 'rgb(199, 234, 243)',
    custom_rgb20_73_92: 'rgb(20, 73, 92)',
    'Custom #14495c': 'rgb(20, 73, 92)',
    custom_rgb50_159_179: 'rgb(50, 159, 179)',
    'Custom #329fb3': 'rgb(50, 159, 179)',
    peoplebitLightStoneGray: 'rgb(216, 216, 216)',
    Peoplebit_Light_Stone_Gray: 'rgb(216, 216, 216)',
    studilyBrightBlue: 'rgb(86, 184, 244)',
    Studily_Bright_Blue: 'rgb(86, 184, 244)',
    studilyOpacity25: 'rgba(0, 0, 0, 0.25)',
    Studily_Opacity_25: 'rgba(0, 0, 0, 0.25)',
    studilyWhiteBG: 'rgb(243, 245, 249)',
    Studily_White_BG: 'rgb(243, 245, 249)',
    appBlueEDF4FB: 'rgb(237, 244, 251)',
    'App Blue EDF4FB': 'rgb(237, 244, 251)',
    custom_rgb39_174_96: 'rgb(39, 174, 96)',
    'Custom Color_8': 'rgb(39, 174, 96)',
    custom_rgb250_250_250: 'rgb(250, 250, 250)',
    'Custom Color_19': 'rgb(250, 250, 250)',
    custom_rgb219_219_219: 'rgb(219, 219, 219)',
    'Custom #dbdbdb': 'rgb(219, 219, 219)',
    getFitOrange: 'rgb(254, 151, 15)',
    'GetFit Orange': 'rgb(254, 151, 15)',
    iconOpacity: 'rgba(0, 0, 0, 0.25)',
    Icon_Opacity: 'rgba(0, 0, 0, 0.25)',
    peoplebitWhite: 'rgb(255, 255, 255)',
    Peoplebit_White: 'rgb(255, 255, 255)',
    studilyLightNavySecondary: 'rgb(80, 118, 198)',
    Studily_Light_Navy_Secondary: 'rgb(80, 118, 198)',
    studilyPrimaryUIDark: 'rgb(55, 66, 83)',
    Studily_Primary_UI_Dark: 'rgb(55, 66, 83)',
    studilySilverWhite: 'rgb(236, 240, 246)',
    Studily_Silver_White: 'rgb(236, 240, 246)',
    studilySlateBlueDark: 'rgb(56, 67, 84)',
    Studily_Slate_Blue_Dark: 'rgb(56, 67, 84)',
    switchColorForSettingsPage: 'rgb(96, 212, 204)',
    Switch_Color_for_Settings_Page: 'rgb(96, 212, 204)',
    blueLowEmphasis: 'rgb(234, 245, 251)',
    'Blue/Low Emphasis': 'rgb(234, 245, 251)',
    custom_rgb255_255_255: 'rgb(255, 255, 255)',
    'Custom Color': 'rgb(255, 255, 255)',
    custom_rgb252_248_236: 'rgb(252, 248, 236)',
    'Custom Color_6': 'rgb(252, 248, 236)',
    custom_rgb128_128_128: 'rgb(128, 128, 128)',
    'Custom Color_21': 'rgb(128, 128, 128)',
    studilyDarkUI: 'rgb(17, 17, 17)',
    Studily_Dark_UI: 'rgb(17, 17, 17)',
    studilyGrayMachine: 'rgb(133, 141, 152)',
    Studily_Gray_Machine: 'rgb(133, 141, 152)',
    custom_rgb243_102_102: 'rgb(243, 102, 102)',
    'Custom Color_11': 'rgb(243, 102, 102)',
    custom_rgb158_158_158: 'rgb(158, 158, 158)',
    'Custom Color_20': 'rgb(158, 158, 158)',
    studilySecondaryUI: 'rgb(133, 141, 152)',
    Studily_Secondary_UI: 'rgb(133, 141, 152)',
    custom_rgb211_238_245: 'rgb(211, 238, 245)',
    'Custom Color_23': 'rgb(211, 238, 245)',
    blue: 'rgb(14, 165, 233)',
    Blue: 'rgb(14, 165, 233)',
    custom_rgb216_216_216: 'rgb(216, 216, 216)',
    'Custom #d8d8d8': 'rgb(216, 216, 216)',
    studilyLightNavyBlue: 'rgb(56, 67, 84)',
    Studily_Light_Navy_Blue: 'rgb(56, 67, 84)',
    custom_rgb246_198_68: 'rgb(246, 198, 68)',
    'Custom Color_9': 'rgb(246, 198, 68)',
    peoplebitDarkEmeraldGreen: 'rgb(20, 73, 92)',
    Peoplebit_Dark_Emerald_Green: 'rgb(20, 73, 92)',
    appBlueEef6fa: 'rgb(238, 246, 250)',
    'App Blue eef6fa': 'rgb(238, 246, 250)',
    appBlue4007fd4: 'rgb(0, 127, 212)',
    'App Blue#4 007fd4': 'rgb(0, 127, 212)',
    studilyPrimary: 'rgb(85, 108, 246)',
    Studily_Primary: 'rgb(85, 108, 246)',
    studilyPurpleLight: 'rgb(208, 215, 250)',
    Studily_Purple_Light: 'rgb(208, 215, 250)',
    studilyWhite: 'rgb(255, 255, 255)',
    Studily_White: 'rgb(255, 255, 255)',
    viewBG: 'rgba(152, 153, 156, 0.25)',
    ViewBG: 'rgba(152, 153, 156, 0.25)',
    appGreyInputsE2E2E2: 'rgb(226, 226, 226)',
    'App Grey Inputs E2E2E2': 'rgb(226, 226, 226)',
    custom_rgb0_123_211: 'rgb(0, 123, 211)',
    'Custom Color_2': 'rgb(0, 123, 211)',
    optionSelectedColor: 'rgb(97, 214, 204)',
    Option_Selected_Color: 'rgb(97, 214, 204)',
    peoplebitOceanBlue: 'rgb(50, 159, 179)',
    Peoplebit_Ocean_Blue: 'rgb(50, 159, 179)',
    settingsIconBGColor: 'rgb(175, 185, 249)',
    Settings_Icon_BG_Color: 'rgb(175, 185, 249)',
    studilyLavenderPurple: 'rgb(208, 215, 250)',
    Studily_Lavender_Purple: 'rgb(208, 215, 250)',
    appRedEB3E26: 'rgb(235, 62, 38)',
    'App Red EB3E26': 'rgb(235, 62, 38)',
    appGoldFFC400: 'rgb(255, 196, 0)',
    'App Gold #FFC400': 'rgb(255, 196, 0)',
    appGoldFFA900: 'rgb(255, 169, 0)',
    'App Gold FFA900': 'rgb(255, 169, 0)',
    custom_rgb0_0_0: 'rgb(0, 0, 0)',
    'Custom Color_22': 'rgb(0, 0, 0)',
    custom_rgb255_255_255: 'rgb(255, 255, 255)',
    'Custom #ffffff': 'rgb(255, 255, 255)',
    studilyBlueNight: 'rgb(80, 118, 198)',
    Studily_Blue_Night: 'rgb(80, 118, 198)',
    studilyLightBlue: 'rgb(55, 66, 83)',
    Studily_Light_Blue: 'rgb(55, 66, 83)',
    studilySnowWhite: 'rgb(230, 235, 244)',
    Studily_Snow_White: 'rgb(230, 235, 244)',
    textPlaceholder: 'rgb(136, 144, 153)',
    TextPlaceholder: 'rgb(136, 144, 153)',
    darkSurface: 'rgb(30, 34, 75)',
    DarkSurface: 'rgb(30, 34, 75)',
    appGreen: 'rgb(0, 204, 154)',
    'App Green': 'rgb(0, 204, 154)',
    peoplebitLightBrown: 'rgb(170, 167, 163)',
    Peoplebit_Light_Brown: 'rgb(170, 167, 163)',
    peoplebitLightGray: 'rgb(219, 219, 219)',
    Peoplebit_Light_Gray: 'rgb(219, 219, 219)',
    studilyWhiteShade2: 'rgb(230, 235, 244)',
    Studily_White_Shade_2: 'rgb(230, 235, 244)',
    appGreyE5E5E5: 'rgb(229, 229, 229)',
    'App Grey E5E5E5': 'rgb(229, 229, 229)',
    custom_rgb91_150_212: 'rgb(91, 150, 212)',
    'Custom Color_7': 'rgb(91, 150, 212)',
    custom_rgb95_90_83: 'rgb(95, 90, 83)',
    'Custom #5f5a53': 'rgb(95, 90, 83)',
    custom_rgb240_237_255: 'rgb(240, 237, 255)',
    'Custom Color_18': 'rgb(240, 237, 255)',
    textPlaceholder: 'rgb(136, 144, 153)',
    'text placeholder': 'rgb(136, 144, 153)',
    appBrandBlue2C9AD9: 'rgb(44, 154, 217)',
    'App Brand Blue 2C9AD9': 'rgb(44, 154, 217)',
    custom_rgb250_223_150: 'rgb(250, 223, 150)',
    'Custom Color_10': 'rgb(250, 223, 150)',
    bGGray: 'rgba(225, 218, 218, 0.15)',
    'BG Gray': 'rgba(225, 218, 218, 0.15)',
    custom_rgb0_55_75: 'rgb(0, 55, 75)',
    'Custom #00374b': 'rgb(0, 55, 75)',
    custom_rgb138_132_125: 'rgb(138, 132, 125)',
    'Custom #8a847d': 'rgb(138, 132, 125)',
    custom_rgb211_83_109: 'rgb(211, 83, 109)',
    'Custom #d3536d': 'rgb(211, 83, 109)',
    opacityBlueForButton: 'rgba(85, 108, 246, 0.05)',
    Opacity_Blue_for_Button: 'rgba(85, 108, 246, 0.05)',
    studilyEmerald: 'rgb(126, 209, 172)',
    Studily_Emerald: 'rgb(126, 209, 172)',
    studilyWashedLavenderWhite: 'rgb(227, 231, 249)',
    Studily_Washed_Lavender_White: 'rgb(227, 231, 249)',
    shopAppBlue: 'rgb(14, 134, 212)',
    ShopAppBlue: 'rgb(14, 134, 212)',
    appOrangeFDF9EC: 'rgb(253, 249, 236)',
    'App Orange #FDF9EC': 'rgb(253, 249, 236)',
    custom_rgb108_108_108: 'rgb(108, 108, 108)',
    'Custom Color_14': 'rgb(108, 108, 108)',
    custom_rgb156_164_171: 'rgb(156, 164, 171)',
    'Custom Color_16': 'rgb(156, 164, 171)',
    peoplebitEarthyBrown: 'rgb(95, 90, 83)',
    Peoplebit_Earthy_Brown: 'rgb(95, 90, 83)',
    studilyDarkPrimary: 'rgb(17, 17, 17)',
    Studily_Dark_Primary: 'rgb(17, 17, 17)',
    custom_rgb255_0_0: 'rgb(255, 0, 0)',
    'Custom Color_3': 'rgb(255, 0, 0)',
    appDisabledButtonEDEDEF: 'rgb(237, 237, 239)',
    'App Disabled Button EDEDEF': 'rgb(237, 237, 239)',
    appTransparent: 'rgba(0, 0, 0, 0.4)',
    'App Transparent': 'rgba(0, 0, 0, 0.4)',
    appMintF0F8F2: 'rgb(240, 248, 242)',
    'App Mint #F0F8F2': 'rgb(240, 248, 242)',
    appTextGreen1DB058: 'rgb(29, 176, 88)',
    'App Text Green #1DB058': 'rgb(29, 176, 88)',
    custom_rgb0_43_75: 'rgb(0, 43, 75)',
    'Custom #002b4b': 'rgb(0, 43, 75)',
    custom_rgb6_48_63: 'rgb(6, 48, 63)',
    'Custom #06303f': 'rgb(6, 48, 63)',
    custom_rgb86_198_237: 'rgb(86, 198, 237)',
    'Custom Color_12': 'rgb(86, 198, 237)',
    custom_rgb189_189_189: 'rgb(189, 189, 189)',
    'Custom Color_17': 'rgb(189, 189, 189)',
    greenBG: 'rgba(0, 204, 154, 0.21)',
    'Green BG': 'rgba(0, 204, 154, 0.21)',
    studilyMintGreen: 'rgb(97, 214, 204)',
    Studily_Mint_Green: 'rgb(97, 214, 204)',
    white: 'rgb(255, 255, 255)',
    White: 'rgb(255, 255, 255)',
  },
  Brand: {
    strong: 'rgba(18, 20, 44, 1)',
    Strong: 'rgba(18, 20, 44, 1)',
    mediumInverse: 'rgba(255, 255, 255, 0.87)',
    Medium_Inverse: 'rgba(255, 255, 255, 0.87)',
    background: 'rgba(251, 252, 253, 1)',
    Background: 'rgba(251, 252, 253, 1)',
    divider: 'rgba(234, 237, 242, 1)',
    Divider: 'rgba(234, 237, 242, 1)',
    medium: 'rgba(70, 78, 88, 1)',
    Medium: 'rgba(70, 78, 88, 1)',
    error: 'rgba(255, 69, 100, 1)',
    Error: 'rgba(255, 69, 100, 1)',
    strongInverse: 'rgba(255, 255, 255, 1)',
    'Strong Inverse': 'rgba(255, 255, 255, 1)',
    strongInverse: 'rgba(255, 255, 255, 1)',
    Strong_Inverse: 'rgba(255, 255, 255, 1)',
    surface: 'rgba(255, 255, 255, 1)',
    Surface: 'rgba(255, 255, 255, 1)',
    light: 'rgba(165, 173, 183, 1)',
    Light: 'rgba(165, 173, 183, 1)',
    primary: 'rgba(90, 69, 255, 1)',
    Primary: 'rgba(90, 69, 255, 1)',
    mediumInverse: 'rgba(255, 255, 255, 0.87)',
    'Medium Inverse': 'rgba(255, 255, 255, 0.87)',
    lightInverse: 'rgba(255, 255, 255, 0.68)',
    'Light Inverse': 'rgba(255, 255, 255, 0.68)',
    lightInverse: 'rgba(255, 255, 255, 0.68)',
    Light_Inverse: 'rgba(255, 255, 255, 0.68)',
    secondary: 'rgba(59, 201, 234, 1)',
    Secondary: 'rgba(59, 201, 234, 1)',
  },
};
