import React from 'react';

const getLongLessonName = lessonNumber => {
  if (lessonNumber === 0) {
    return 'Completion Bonus';
  }
  if (lessonNumber < 10) {
    return 'Lesson 0' + lessonNumber;
  }

  return 'Lesson ' + lessonNumber;
};

export default getLongLessonName;
