import React from 'react';
import ConfettiCannon from 'react-native-confetti-cannon';
import * as GlobalVariableContext from '../config/GlobalVariableContext';
// export {ConfettiCannon}

export const Confetti = () => {
  const variables = GlobalVariableContext.useValues();
  return (
    <>
      {variables.SHOT && (
        <ConfettiCannon
          count={200}
          origin={{ x: -10, y: 0 }}
          fadeOut={true}
          explosionSpeed={350}
          colors={[
            '#F4A100',
            '#E2E2E2',
            '#2C9AD9',
            '#F4A100',
            '#FFFFFF',
            '#EB3E26',
            '#1DB058',
          ]}
        />
      )}
    </>
  );
};
