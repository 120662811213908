import React from 'react';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import * as Track from '../custom-files/Track';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import { Button, ScreenContainer, withTheme } from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import * as WebBrowser from 'expo-web-browser';
import {
  ActivityIndicator,
  Image,
  ImageBackground,
  Platform,
  ScrollView,
  Text,
  View,
} from 'react-native';

const UpdateAppRequiredScreen = props => {
  const { theme } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const getAppStoreLink = () => {
    if (Platform.OS === 'ios') {
      return 'https://apps.apple.com/us/app/sunsational-swim-instructor/id6445966896';
    }

    return 'https://play.google.com/store/apps/details?id=com.sunsationalswimschool.instructorhq';
  };
  React.useEffect(() => {
    Track.onEnterScreen(
      'UpdateAppRequiredScreen',
      Variables,
      setGlobalVariableValue,
      props.navigation.navigate
    );
  }, []);
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      setGlobalVariableValue({
        key: 'empty',
        value: Constants['empty'],
      });
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={false}
      hasTopSafeArea={true}
      style={StyleSheet.applyWidth(
        { backgroundColor: palettes.App['App Gold #FFC400'] },
        dimensions.width
      )}
    >
      <ImageBackground
        resizeMode={'cover'}
        source={Images.Group162}
        style={StyleSheet.applyWidth(
          { height: '100%', width: '100%' },
          dimensions.width
        )}
      >
        {/* View Page Container */}
        <View
          style={StyleSheet.applyWidth(
            {
              height: '100%',
              justifyContent: 'center',
              paddingLeft: '5.35%',
              paddingRight: '5.35%',
              width: '100%',
            },
            dimensions.width
          )}
        >
          {/* View Logo Container */}
          <View
            style={StyleSheet.applyWidth(
              { alignItems: 'center', height: '30%', justifyContent: 'center' },
              dimensions.width
            )}
          >
            <Image
              resizeMode={'cover'}
              source={Images.Logo}
              style={StyleSheet.applyWidth(
                { height: 92, width: 96 },
                dimensions.width
              )}
            />
          </View>

          <ScrollView
            bounces={true}
            horizontal={false}
            keyboardShouldPersistTaps={'never'}
            nestedScrollEnabled={false}
            showsHorizontalScrollIndicator={true}
            showsVerticalScrollIndicator={true}
            contentContainerStyle={StyleSheet.applyWidth(
              { justifyContent: 'center' },
              dimensions.width
            )}
          >
            {/* View Card Container */}
            <View
              style={StyleSheet.applyWidth(
                {
                  backgroundColor: palettes.App['Custom Color'],
                  borderRadius: 24,
                  justifyContent: 'space-between',
                  paddingBottom: 32,
                  paddingLeft: 20,
                  paddingRight: 20,
                  paddingTop: 32,
                },
                dimensions.width
              )}
            >
              {/* View Warning Container */}
              <View
                style={StyleSheet.applyWidth(
                  { alignItems: 'center', marginBottom: 24 },
                  dimensions.width
                )}
              >
                {/* View Warning Icon Container */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      backgroundColor: palettes.App['App Gold #FFC400'],
                      borderRadius: 50,
                      height: 64,
                      justifyContent: 'center',
                      width: 64,
                    },
                    dimensions.width
                  )}
                >
                  <Image
                    resizeMode={'cover'}
                    source={Images.IconInfoCircle}
                    style={StyleSheet.applyWidth(
                      { height: 24, width: 24 },
                      dimensions.width
                    )}
                  />
                </View>
              </View>
              {/* View Title Container */}
              <View
                style={StyleSheet.applyWidth(
                  { alignItems: 'center', marginBottom: 8 },
                  dimensions.width
                )}
              >
                <Text
                  accessible={true}
                  style={StyleSheet.applyWidth(
                    {
                      color: palettes.App['App Black 191F32'],
                      fontFamily: 'Poppins_600SemiBold',
                      fontSize: 20,
                      textAlign: 'center',
                    },
                    dimensions.width
                  )}
                >
                  {'App update required.'}
                </Text>
              </View>
              {/* View Text Container */}
              <View
                style={StyleSheet.applyWidth(
                  { alignItems: 'center', marginBottom: 24 },
                  dimensions.width
                )}
              >
                <Text
                  accessible={true}
                  style={StyleSheet.applyWidth(
                    {
                      color: palettes.App['App Text 2ndary 7F868C'],
                      fontFamily: 'Poppins_400Regular',
                      textAlign: 'center',
                    },
                    dimensions.width
                  )}
                >
                  {'Your app version '}
                  {null}
                  {' is no longer supported. Update your app now to continue.'}
                </Text>
              </View>
              {/* View Button Container */}
              <View
                style={StyleSheet.applyWidth(
                  { alignItems: 'center' },
                  dimensions.width
                )}
              >
                {/* Ok Button */}
                <View
                  style={StyleSheet.applyWidth(
                    { alignItems: 'center', width: '100%' },
                    dimensions.width
                  )}
                >
                  {/* Update App */}
                  <Button
                    iconPosition={'left'}
                    onPress={() => {
                      const handler = async () => {
                        try {
                          const appStoreLink = getAppStoreLink();
                          await WebBrowser.openBrowserAsync(`${appStoreLink}`);
                        } catch (err) {
                          console.error(err);
                        }
                      };
                      handler();
                    }}
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor: palettes.App['App Red EB3E26'],
                        borderRadius: 50,
                        fontFamily: 'BebasNeue_400Regular',
                        fontSize: 18,
                        paddingBottom: 14,
                        paddingTop: 14,
                        textAlign: 'center',
                        width: '100%',
                      },
                      dimensions.width
                    )}
                    title={'Update app'}
                  />
                  {/* View Activity Indicator */}
                  <>
                    {!Constants['is_loading'] ? null : (
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            backgroundColor: palettes.App['App Red EB3E26'],
                            borderRadius: 50,
                            marginTop: -50,
                            opacity: 0.8,
                            paddingBottom: 7,
                            paddingTop: 7,
                            width: '100%',
                          },
                          dimensions.width
                        )}
                      >
                        <ActivityIndicator
                          animating={true}
                          hidesWhenStopped={true}
                          size={'small'}
                          color={palettes.App['Custom Color']}
                          style={StyleSheet.applyWidth(
                            { height: 36, width: 36 },
                            dimensions.width
                          )}
                        />
                      </View>
                    )}
                  </>
                </View>
              </View>
            </View>
          </ScrollView>
        </View>
      </ImageBackground>
    </ScreenContainer>
  );
};

export default withTheme(UpdateAppRequiredScreen);
