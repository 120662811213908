// SYNC NEW
import moment from 'moment-timezone';

const formatDate = date => moment(date).format('MM/DD/YYYY');
const KNACK_API_BASE_URL = 'https://api.knack.com/v1';

const apiRequest = async (method, url, headers, data) => {
  console.log('API REQUEST');
  try {
    const requestOptions = {
      method,
      headers,
      body: data ? JSON.stringify(data) : undefined,
    };

    const response = await fetch(url, requestOptions);

    if (!response.ok) {
      throw new Error(`Error ${method} ${url}: ${response.statusText}`);
    }

    return response;
  } catch (error) {
    console.error(`Error ${method} ${url}:`, error);
    return error;
  }
};
export const shouldSavePlatformOSToServer = Variables => {
  if (!Variables.DEVICE_OS) {
    return true;
  }
  return false;
};
export const saveDevicePlatformOSToServer = async (
  Variables,
  os,
  knack_user_id,
  auth_knack_token
) => {
  console.log('Device platform not saved. Saving device os to server.');
  console.log('os === ', os);
  const SCENE_ID = 'scene_1156';
  const VIEW_ID = 'view_2141';
  const url = `${KNACK_API_BASE_URL}/pages/${SCENE_ID}/views/${VIEW_ID}/records/${knack_user_id}`;

  const data = {
    field_2460: os,
  };

  const headers = {
    'X-Knack-Application-Id': Variables.APP_ID,
    Authorization: auth_knack_token,
    'content-type': 'application/json',
  };

  return apiRequest('PUT', url, headers, data);
};

const saveAppVersionToServer = async (
  Variables,
  knack_user_id,
  auth_knack_token
) => {
  console.log('App version not saved. Saving app version to server.');
  const { APP_ID, APP_VERSION, LATEST_APP_VERSION } = Variables;

  const SCENE_ID = 'scene_1156';
  const VIEW_ID = 'view_2141';
  const url = `${KNACK_API_BASE_URL}/pages/${SCENE_ID}/views/${VIEW_ID}/records/${knack_user_id}`;

  const data = {
    field_2411: LATEST_APP_VERSION.id,
  };

  const headers = {
    'X-Knack-Application-Id': APP_ID,
    Authorization: auth_knack_token,
    'content-type': 'application/json',
  };

  return apiRequest('PUT', url, headers, data);
};

export const shouldSyncAppVersionToServer = async (
  Variables,
  setGlobalVariableValue,
  auth_knack_user_id,
  auth_knack_token
) => {
  console.log('Execute appVersionToServer check.');
  const { APP_VERSION, LATEST_APP_VERSION } = Variables;

  if (APP_VERSION.id === LATEST_APP_VERSION.id) {
    console.log('App version previously saved to server.');
    return true;
  }

  try {
    // Send put request to save the user's app version to the server
    const saveResponse = await saveAppVersionToServer(
      Variables,
      auth_knack_user_id,
      auth_knack_token
    );

    if (!saveResponse.ok) {
      throw new Error(
        'Error saving app version to server: ',
        saveResponse.statusText
      );
    }

    const instructorData = await saveResponse.json();
    const savedAppVersionID = instructorData.record.field_2411_raw[0].id;

    // Verify the save was successful, comparing the version id on the users phone to the app version received in the save response
    if (LATEST_APP_VERSION.id === savedAppVersionID) {
      setGlobalVariableValue({
        key: 'APP_VERSION',
        value: { ...LATEST_APP_VERSION },
      });
      console.log(`User's app version saved to server.`);
      return true;
    }
    return false;
  } catch (error) {
    console.error('Error saving app version to server: ', error);
    return false;
  }
};

const getScreensDataFromServer = async Variables => {
  console.log('Fetching screens data from server: ');
  const { APP_ID, AUTH_KNACK_USER_ID, AUTH_KNACK_TOKEN } = Variables;
  const SCENE_ID = 'scene_1148';
  const VIEW_ID = 'view_2140';
  const url = `${KNACK_API_BASE_URL}/pages/${SCENE_ID}/views/${VIEW_ID}/records/${AUTH_KNACK_USER_ID}`;

  const headers = {
    'X-Knack-Application-Id': APP_ID,
    Authorization: AUTH_KNACK_TOKEN,
    'content-type': 'application/json',
  };

  return apiRequest('GET', url, headers);
};

const sendAppActivityToServer = async (Variables, appData, currentDate) => {
  console.log('Sending screens data to server: ');
  const { APP_ID, AUTH_KNACK_USER_ID, AUTH_KNACK_TOKEN } = Variables;
  const SCENE_ID = 'scene_1156';
  const VIEW_ID = 'view_2141';
  const url = `${KNACK_API_BASE_URL}/pages/${SCENE_ID}/views/${VIEW_ID}/records/${AUTH_KNACK_USER_ID}`;

  appData.lastDateSyncedWithServer = currentDate;

  const data = {
    field_2442: currentDate, // sync date
    field_2441: appData,
    field_2440: appData.lastDateUsed,
  };

  const headers = {
    'X-Knack-Application-Id': APP_ID,
    Authorization: AUTH_KNACK_TOKEN,
    'content-type': 'application/json',
  };

  return apiRequest('PUT', url, headers, data);
};

const updateLocalDataWithServerData = (serverData, localData) => {
  for (const screenName in serverData.screensData) {
    if (localData.screensData.hasOwnProperty(screenName)) {
      localData.screensData[screenName].visits +=
        serverData.screensData[screenName].visits;
      localData.screensData[screenName].screenTime +=
        serverData.screensData[screenName].screenTime;
    } else {
      localData.screensData[screenName] = serverData.screensData[screenName];
    }
  }
  return localData;
};

const resetLocalScreenActivityValues = localData => {
  for (const screenName in localData.screensData) {
    localData.screensData[screenName].visits = 0;
    localData.screensData[screenName].screenTime = 0;
  }
};

export const syncAppActivityData = async (
  Variables,
  setGlobalVariableValue,
  updatedAppActivityData
) => {
  // switch appActivityData to regular js variable so can manipulate it without set method.
  console.log('Syncing app activity data to server.');

  let localAppActivityData = Variables.appActivityData;

  const currentDate = formatDate(new Date());

  if (localAppActivityData.lastDateSyncedWithServer !== currentDate) {
    try {
      console.log('Last date synced with server expired. Continue syncing.');
      // Fetch current visits and screenTime values from the server
      const fetchResponse = await getScreensDataFromServer(Variables);

      if (!fetchResponse.ok) {
        throw new Error(
          'Error fetching app activity data:',
          fetchResponse.statusText
        );
      }

      const serverData = await fetchResponse.json();

      // check if previous app activity data exists on server.
      if (serverData.field_2441) {
        const serverAppActivityData = JSON.parse(serverData.field_2441);

        // Add the app's current values to the fetched values
        localAppActivityData = updateLocalDataWithServerData(
          serverAppActivityData,
          localAppActivityData
        );
      }

      // Send a PUT request to update the server with the new totals
      const updateServer = await sendAppActivityToServer(
        Variables,
        updatedAppActivityData,
        currentDate
      );

      if (updateServer.ok) {
        // Reset the app's visits and screenTime values
        resetLocalScreenActivityValues(localAppActivityData);
        localAppActivityData.lastDateSyncedWithServer = currentDate;

        // set stateful variable
        setGlobalVariableValue({
          key: 'appActivityData',
          value: localAppActivityData,
        });
        console.log('App activity data synced.');
      } else {
        console.error(
          'Error updating app activity data:',
          updateResponse.statusText
        );
      }
    } catch (error) {
      console.error('Error syncing app activity data:', error);
    }
  }
};
