import * as convertTime from './convertTime';

export const getThisPayDay = () => {
  const today = new Date();
  const dayOfWeek = today.getDay(); // 0 (Sunday) - 6 (Saturday)
  const daysTillThisThursday = (4 + 7 - dayOfWeek) % 7; // 4 represents Thursday
  const thisThursday = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate() + daysTillThisThursday
  );
  thisThursday.setHours(0, 0, 0, 0);
  return thisThursday;
};

export const getNextPayDay = () => {
  const today = new Date();
  const dayOfWeek = today.getDay(); // 0 (Sunday) - 6 (Saturday)
  const daysTillThisThursday = (4 + 7 - dayOfWeek) % 7; // 4 represents Thursday
  const nextThursday = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate() + daysTillThisThursday + 7
  );
  nextThursday.setHours(0, 0, 0, 0);
  return nextThursday;
};

export const getLessonPayDate = dateLessonSubmittedForPay => {
  let dateLessonSubmittedForPayISO = convertTime.toISOformat(
    dateLessonSubmittedForPay,
    'MM/DD/YYYY h:mma'
  );

  const dateAfter72HourHold = dateLessonSubmittedForPay
    ? new Date(dateLessonSubmittedForPayISO)
    : new Date();
  dateAfter72HourHold.setDate(dateAfter72HourHold.getDate() + 3);
  let dayOfWeek = dateAfter72HourHold.getDay();
  let daysToNextThursday = 4 - dayOfWeek;
  if (daysToNextThursday <= 0) {
    daysToNextThursday += 7;
  }
  const payDate = new Date(dateAfter72HourHold);
  payDate.setDate(payDate.getDate() + daysToNextThursday);
  payDate.setHours(0, 0, 0, 0);
  return payDate;
};
