import React from 'react';

const checkAccountStatus = async (
  Variables,
  setGlobalVariableValue,
  refreshedData
) => {
  if (!refreshedData) {
    const {
      gBioImage,
      gBioText,
      gBioComplete,
      gCprImage,
      gCprExpirationDate,
      gAccountReadyForClients,
      gAccountComplete,
      gTipaltiComplete,
      gSunsational101Complete,
    } = Variables;

    const cprActive = new Date() <= new Date(gCprExpirationDate);
    const bioComplete = gBioImage && gBioText;
    const accountComplete =
      bioComplete && gCprImage && gSunsational101Complete && gTipaltiComplete;

    setGlobalVariableValue({
      key: 'gBioComplete',
      value: bioComplete,
    });

    setGlobalVariableValue({
      key: 'gCprActive',
      value: cprActive,
    });

    setGlobalVariableValue({
      key: 'gAccountComplete',
      value: accountComplete,
    });

    setGlobalVariableValue({
      key: 'gAccountReadyForClients',
      value:
        bioComplete && cprActive && gSunsational101Complete && gTipaltiComplete,
    });

    return;
  }

  const {
    field_321_raw, // user profile image
    field_45_raw, // user biography text
    field_177_raw, // cpr image
    field_48, // cpr expiration date
    field_1366_raw, // tipalti status boolean
    field_1105_raw, // sunsational101 status boolean
    field_1845, // onboarding calls attended
    field_6, // user fullname
    field_6_raw, // user first name
    field_46_raw, // user address object
    field_2387_raw, // instructor profile bio status
  } = refreshedData;

  const bioComplete = field_321_raw && field_45_raw;
  const cprActive = new Date() <= new Date(field_48);
  const accountComplete =
    field_321_raw &&
    field_45_raw &&
    field_177_raw &&
    field_1105_raw &&
    field_1366_raw;
  const sunsational101Complete = field_1105_raw || field_1845;
  const tipaltiComplete = field_1366_raw;

  console.log(
    'gAccountReadyForClients === ',
    bioComplete && cprActive && sunsational101Complete && tipaltiComplete
  );

  setGlobalVariableValue({
    key: 'gBioComplete',
    value: bioComplete,
  });

  setGlobalVariableValue({
    key: 'gCprActive',
    value: cprActive,
  });

  setGlobalVariableValue({
    key: 'gAccountComplete',
    value: accountComplete,
  });

  setGlobalVariableValue({
    key: 'gSunsational101Complete',
    value: sunsational101Complete,
  });

  setGlobalVariableValue({
    key: 'gAccountReadyForClients',
    value:
      bioComplete && cprActive && sunsational101Complete && tipaltiComplete,
  });
};

export default checkAccountStatus;
