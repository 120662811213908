import * as StyleSheet from './utils/StyleSheet';

import Breakpoints from './utils/Breakpoints';

import palettes from './themes/palettes';

export const SwitchRowStyles = theme =>
  StyleSheet.create({
    availability: {
      style: {
        color: palettes.App['App Sun Orange F4A100'],
        fontFamily: 'Poppins_500Medium',
        fontSize: 14,
        marginLeft: 0,
        marginRight: 0,
      },
      props: {},
    },
  });

export const PickerStyles = theme =>
  StyleSheet.create({
    pickerTime: {
      style: {
        fontFamily: 'Poppins_400Regular',
        fontSize: 12,
        marginTop: -8,
        paddingBottom: 4,
      },
      props: {},
    },
  });

export const ViewStyles = theme =>
  StyleSheet.create({
    'Button single bottom': {
      style: {
        backgroundColor: palettes.App['Custom Color'],
        borderColor: palettes.App['App Blue C7EAF3'],
        borderTopWidth: 2,
        bottom: 0,
        paddingLeft: '5.35%',
        paddingRight: '5.35%',
        position: 'absolute',
        width: '100%',
      },
      props: {},
    },
    'Button single bottom 2': {
      style: {
        backgroundColor: palettes.App['Custom Color'],
        borderColor: palettes.App['App Blue C7EAF3'],
        borderTopWidth: 2,
        bottom: 0,
        paddingLeft: '5.35%',
        paddingRight: '5.35%',
        position: 'absolute',
        width: '100%',
      },
      props: {},
    },
    'Button single bottom 3': {
      style: {
        backgroundColor: palettes.App['Custom Color'],
        borderColor: palettes.App['App Blue C7EAF3'],
        borderTopWidth: 2,
        bottom: 0,
        paddingLeft: '5.35%',
        paddingRight: '5.35%',
        position: 'absolute',
        width: '100%',
      },
      props: {},
    },
    'Footer Nav Icons': {
      style: {
        alignItems: 'center',
        alignSelf: 'auto',
        flex: 0,
        flexDirection: 'row',
        justifyContent: 'space-around',
        marginBottom: 12,
        marginTop: 12,
      },
      props: {},
    },
    'Footer Navigation 2023-09-08': {
      style: {
        backgroundColor: palettes.App['Custom #ffffff'],
        bottom: 0,
        minHeight: 67,
        position: 'absolute',
        width: '100%',
        zIndex: 100,
      },
      props: {},
    },
    'Footer Navigation 2023-09-10': {
      style: {
        backgroundColor: palettes.App['Custom #ffffff'],
        bottom: 0,
        minHeight: 67,
        position: 'absolute',
        width: '100%',
        zIndex: 100,
      },
      props: {},
    },
    'Footer Navigation 2023-09-11': {
      style: {
        backgroundColor: palettes.App['Custom #ffffff'],
        bottom: 0,
        minHeight: 67,
        position: 'absolute',
        width: '100%',
        zIndex: 100,
      },
      props: {},
    },
    'Footer Navigation 2023-09-12': {
      style: {
        backgroundColor: palettes.App['Custom #ffffff'],
        bottom: 0,
        minHeight: 67,
        position: 'absolute',
        width: '100%',
        zIndex: 100,
      },
      props: {},
    },
    'Footer Navigation 2023-09-13': {
      style: {
        backgroundColor: palettes.App['Custom #ffffff'],
        bottom: 0,
        minHeight: 67,
        position: 'absolute',
        width: '100%',
        zIndex: 100,
      },
      props: {},
    },
    'Footer Navigation 2023-09-14': {
      style: {
        backgroundColor: palettes.App['Custom #ffffff'],
        bottom: 0,
        minHeight: 67,
        position: 'absolute',
        width: '100%',
        zIndex: 100,
      },
      props: {},
    },
    'Footer Navigation 2023-09-9': {
      style: {
        backgroundColor: palettes.App['Custom #ffffff'],
        bottom: 0,
        minHeight: 67,
        position: 'absolute',
        width: '100%',
        zIndex: 100,
      },
      props: {},
    },
    'Footer Navigation Icons Container': {
      style: {
        alignItems: 'center',
        alignSelf: 'auto',
        flex: 0,
        flexDirection: 'row',
        justifyContent: 'space-around',
        marginBottom: 12,
        marginTop: 12,
      },
      props: {},
    },
    'Message confirmation sent': {
      style: {
        borderColor: palettes.App['App Text 2ndary 7F868C'],
        borderRadius: 50,
        borderWidth: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 10,
        paddingBottom: 14,
        paddingLeft: 16,
        paddingRight: 16,
        paddingTop: 14,
      },
      props: {},
    },
    'View Buttons': {
      style: { alignItems: 'center', marginBottom: 32, width: '100%' },
      props: {},
    },
    'View Buttons 2': {
      style: { alignItems: 'center', marginBottom: 32, width: '100%' },
      props: {},
    },
    'View Buttons 3': {
      style: { alignItems: 'center', marginBottom: 32, width: '100%' },
      props: {},
    },
    'View H1': {
      style: { alignItems: 'baseline', flexDirection: 'row', marginBottom: 26 },
      props: {},
    },
    'View H2': {
      style: { alignItems: 'baseline', flexDirection: 'row', marginBottom: 26 },
      props: {},
    },
    'View Top Nav - Subtitle': {
      style: {
        backgroundColor: palettes.App['App Gold #FFC400'],
        width: '100%',
      },
      props: {},
    },
    'View Top Nav - Subtitle 2': {
      style: {
        backgroundColor: palettes.App['App Gold #FFC400'],
        width: '100%',
      },
      props: {},
    },
    'View Top Nav - Subtitle 3': {
      style: {
        backgroundColor: palettes.App['App Gold #FFC400'],
        width: '100%',
      },
      props: {},
    },
    'additional student details block': {
      style: {
        alignItems: 'flex-start',
        borderColor: palettes.App['Custom Color_6'],
        borderRadius: 16,
        borderWidth: 1,
        flexDirection: 'row',
        paddingBottom: 8,
        paddingLeft: 16,
        paddingRight: 16,
        paddingTop: 8,
      },
      props: {},
    },
    'additional student details block 2': {
      style: {
        alignItems: 'flex-start',
        borderColor: palettes.App['Custom Color_6'],
        borderRadius: 16,
        borderWidth: 1,
        flexDirection: 'row',
        paddingBottom: 8,
        paddingLeft: 16,
        paddingRight: 16,
        paddingTop: 8,
      },
      props: {},
    },
    'additional student details block 3': {
      style: {
        alignItems: 'flex-start',
        borderColor: palettes.App['Custom Color_6'],
        borderRadius: 16,
        borderWidth: 1,
        flexDirection: 'row',
        paddingBottom: 8,
        paddingLeft: 16,
        paddingRight: 16,
        paddingTop: 8,
      },
      props: {},
    },
    'article list item': {
      style: {
        backgroundColor: palettes.App['Custom #ffffff'],
        borderBottomWidth: 1,
        borderColor: theme.colors.border.brand,
        borderLeftWidth: 1,
        borderRadius: 20,
        borderRightWidth: 1,
        borderTopWidth: 1,
        marginBottom: 18,
        paddingBottom: 14,
        paddingLeft: 20,
        paddingRight: 20,
        paddingTop: 14,
      },
      props: {},
    },
    'article list item 2': {
      style: {
        backgroundColor: palettes.App['Custom #ffffff'],
        borderBottomWidth: 1,
        borderColor: theme.colors.border.brand,
        borderLeftWidth: 1,
        borderRadius: 20,
        borderRightWidth: 1,
        borderTopWidth: 1,
        marginBottom: 18,
        paddingBottom: 14,
        paddingLeft: 20,
        paddingRight: 20,
        paddingTop: 14,
      },
      props: {},
    },
    'button full width with disabled': {
      style: { alignItems: 'center', marginBottom: 32, width: '100%' },
      props: {},
    },
    'button small filter': {
      style: {
        backgroundColor: palettes.App['App Disabled Button EDEDEF'],
        borderRadius: 40,
        paddingBottom: 4,
        paddingLeft: 6,
        paddingRight: 6,
        paddingTop: 4,
      },
      props: {},
    },
    buttonIncremental: {
      style: {
        alignItems: 'center',
        justifyContent: 'flex-end',
        paddingBottom: 4,
        width: '10%',
      },
      props: {},
    },
    'card block': { style: { marginBottom: 24 }, props: {} },
    'client header component': {
      style: { flexDirection: 'row', justifyContent: 'space-between' },
      props: {},
    },
    'decline survey prompt': {
      style: {
        backgroundColor: palettes.App['App Gold #FFC400'],
        borderRadius: 9,
        marginBottom: 16,
        marginLeft: '5.35%',
        paddingBottom: 9,
        paddingLeft: '9.1%',
        paddingRight: '9.1%',
        paddingTop: 9,
      },
      props: {},
    },
    'error message': {
      style: {
        backgroundColor: palettes.App['App Red EB3E26'],
        borderRadius: 4,
        marginBottom: 8,
        marginTop: 0,
        paddingBottom: 8,
        paddingLeft: 8,
        paddingRight: 8,
        paddingTop: 8,
        width: '100%',
      },
      props: {},
    },
    'field instructions': {
      style: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingBottom: 20,
      },
      props: {},
    },
    'footer nav - 2023-09-08': {
      style: {
        backgroundColor: palettes.App['Custom Color'],
        bottom: 0,
        minHeight: 67,
        position: 'absolute',
        width: '100%',
        zIndex: 100,
      },
      props: {},
    },
    'footer nav - knowledge - 2023-09-08': {
      style: { alignItems: 'center', flex: 1, justifyContent: 'space-between' },
      props: {},
    },
    'footer nav 2023-09-07': {
      style: {
        backgroundColor: palettes.App['Custom Color'],
        bottom: 0,
        minHeight: 67,
        position: 'absolute',
        width: '100%',
        zIndex: 100,
      },
      props: {},
    },
    'footer nav 2023-09-08': {
      style: {
        backgroundColor: palettes.App['Custom Color'],
        bottom: 0,
        minHeight: 67,
        position: 'absolute',
        width: '100%',
        zIndex: 100,
      },
      props: {},
    },
    'footer nav 2023-09-10': {
      style: {
        backgroundColor: palettes.App['Custom #ffffff'],
        bottom: 0,
        minHeight: 67,
        position: 'absolute',
        width: '100%',
        zIndex: 100,
      },
      props: {},
    },
    'footer nav 2023-09-11': {
      style: {
        backgroundColor: palettes.App['Custom #ffffff'],
        bottom: 0,
        minHeight: 67,
        position: 'absolute',
        width: '100%',
        zIndex: 100,
      },
      props: {},
    },
    'footer nav 2023-09-8': {
      style: {
        backgroundColor: palettes.App['Custom Color'],
        bottom: 0,
        minHeight: 67,
        position: 'absolute',
        width: '100%',
        zIndex: 100,
      },
      props: {},
    },
    'footer nav 2023-09-9': {
      style: {
        backgroundColor: palettes.App['Custom #ffffff'],
        bottom: 0,
        minHeight: 67,
        position: 'absolute',
        width: '100%',
        zIndex: 100,
      },
      props: {},
    },
    'input text area': { style: { height: 145 }, props: {} },
    'kb featured article 2': { style: {}, props: {} },
    'modal close': {
      style: {
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 6,
      },
      props: {},
    },
    'modal close 2': {
      style: {
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 6,
      },
      props: {},
    },
    'modal close 3': {
      style: {
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 6,
      },
      props: {},
    },
    'name icon': {
      style: { height: 48, marginRight: 8, width: 48 },
      props: {},
    },
    'name icon 2': {
      style: { height: 48, marginRight: 8, width: 48 },
      props: {},
    },
    'pool type block': {
      style: {
        alignItems: 'flex-start',
        borderColor: palettes.App['Custom Color_6'],
        borderRadius: 16,
        borderWidth: 1,
        flexDirection: 'column',
        paddingBottom: 8,
        paddingLeft: 16,
        paddingRight: 16,
        paddingTop: 8,
      },
      props: {},
    },
    radioRowContainer: { style: { width: '100%', zIndex: 2 }, props: {} },
    'requested payment block': {
      style: {
        backgroundColor: palettes.App['App Gold #FFC400'],
        borderRadius: 8,
        marginBottom: 15,
        marginLeft: '5.35%',
        paddingBottom: 8,
        paddingLeft: '9%',
        paddingRight: '9%',
        paddingTop: 8,
      },
      props: {},
    },
    'search box': { style: { marginBottom: 10 }, props: {} },
    'tab switches - 2': { style: { flex: 1 }, props: {} },
    'tab switches - 3': { style: { flex: 1 }, props: {} },
    'tab switches - 4': {
      style: {
        alignItems: 'center',
        flexDirection: 'row',
        marginLeft: '5.35%',
        marginRight: '5.35%',
        marginTop: 20,
      },
      props: {},
    },
    'unselected tab': {
      style: {
        alignItems: 'center',
        borderBottomWidth: 1,
        borderColor: palettes.App.DarkSurface,
        borderLeftWidth: 1,
        borderRadius: 100,
        borderRightWidth: 1,
        borderTopWidth: 1,
        flexDirection: 'row',
        justifyContent: 'center',
        paddingLeft: 14,
        paddingRight: 14,
      },
      props: {},
    },
    'welcome page': { style: { height: '57%', width: '100%' }, props: {} },
    'welcome page body': {
      style: {
        backgroundColor: palettes.App['Custom Color'],
        borderRadius: 16,
        height: '43%',
        paddingBottom: '10.7%',
        paddingLeft: '5.35%',
        paddingRight: '5.35%',
        paddingTop: '6.4%',
        width: '100%',
      },
      props: {},
    },
  });

export const RadioButtonRowStyles = theme =>
  StyleSheet.create({
    radio: {
      style: {
        color: palettes.App['App Black 191F32'],
        fontFamily: 'Poppins_400Regular',
        fontSize: 14,
      },
      props: {},
    },
  });

export const KeyboardAvoidingViewStyles = theme =>
  StyleSheet.create({
    'contact sss success': {
      style: {
        backgroundColor: palettes.App['App Transparent'],
        bottom: 0,
        paddingTop: '125%',
        position: 'absolute',
        width: '100%',
      },
      props: {},
    },
    'contact sss success 2': {
      style: {
        backgroundColor: palettes.App['App Transparent'],
        bottom: 0,
        paddingTop: '125%',
        position: 'absolute',
        width: '100%',
      },
      props: {},
    },
    modalBackground: {
      style: {
        backgroundColor: palettes.App['App Transparent'],
        bottom: 0,
        paddingTop: '110%',
        position: 'absolute',
      },
      props: {},
    },
  });

export const SVGStyles = theme =>
  StyleSheet.create({ SVG: { style: { height: 100, width: 100 }, props: {} } });

export const TextStyles = theme =>
  StyleSheet.create({
    Text: { style: { color: theme.colors.text.strong }, props: {} },
    primary14: {
      style: {
        alignSelf: 'auto',
        color: palettes.App['App Black 191F32'],
        fontFamily: 'Poppins_400Regular',
        textAlign: 'auto',
      },
      props: {},
    },
    primary15: {
      style: {
        color: palettes.App['App Black 191F32'],
        fontFamily: 'Poppins_400Regular',
      },
      props: {},
    },
    secondary12: {
      style: {
        alignSelf: 'auto',
        color: palettes.App['App Text 2ndary 7F868C'],
        fontFamily: 'Poppins_400Regular',
        fontSize: 12,
        textAlign: 'auto',
      },
      props: {},
    },
    secondary14: {
      style: {
        color: palettes.App['App Text 2ndary 7F868C'],
        fontFamily: 'Poppins_400Regular',
      },
      props: {},
    },
  });

export const FetchStyles = theme =>
  StyleSheet.create({ Fetch: { style: { minHeight: 40 }, props: {} } });

export const SurfaceStyles = theme =>
  StyleSheet.create({ Surface: { style: {}, props: {} } });

export const CheckboxRowStyles = theme =>
  StyleSheet.create({
    'Checkbox Row': { style: { minHeight: 50 }, props: {} },
  });

export const ActionSheetItemStyles = theme =>
  StyleSheet.create({
    'Action Sheet Item': { style: { textAlign: 'center' }, props: {} },
  });

export const ButtonStyles = theme =>
  StyleSheet.create({
    Button: {
      style: {
        backgroundColor: theme.colors.branding.primary,
        borderRadius: 8,
        fontFamily: 'System',
        fontWeight: '700',
        textAlign: 'center',
      },
      props: {},
    },
    disabled: {
      style: {
        backgroundColor: palettes.App['App Disabled Button EDEDEF'],
        borderRadius: 50,
        color: palettes.App['App Disabled Button Text 7F868C'],
        fontFamily: 'BebasNeue_400Regular',
        fontSize: 18,
        paddingBottom: 14,
        paddingTop: 14,
        textAlign: 'center',
        width: '100%',
      },
      props: {},
    },
  });

export const FlatListStyles = theme =>
  StyleSheet.create({ List: { style: { flex: 1 }, props: {} } });

export const ImageBackgroundStyles = theme =>
  StyleSheet.create({
    'Image Background': { style: { height: '100%', width: '100%' }, props: {} },
  });

export const TextInputStyles = theme =>
  StyleSheet.create({
    'Text Input': {
      style: {
        borderBottomWidth: 1,
        borderColor: theme.colors.border.brand,
        borderLeftWidth: 1,
        borderRadius: 8,
        borderRightWidth: 1,
        borderTopWidth: 1,
        paddingBottom: 8,
        paddingLeft: 8,
        paddingRight: 8,
        paddingTop: 8,
      },
      props: {},
    },
  });

export const MapViewStyles = theme =>
  StyleSheet.create({
    'Map View': {
      style: { flex: 1, height: '100%', width: '100%' },
      props: {},
    },
  });

export const PressableStyles = theme =>
  StyleSheet.create({
    'full-width-height': {
      style: { height: '100%', width: '100%' },
      props: {},
    },
  });

export const ActivityIndicatorStyles = theme =>
  StyleSheet.create({
    'Activity Indicator': { style: { height: 36, width: 36 }, props: {} },
  });

export const SwiperStyles = theme =>
  StyleSheet.create({ Swiper: { style: {}, props: {} } });

export const DividerStyles = theme =>
  StyleSheet.create({ Divider: { style: { height: 1 }, props: {} } });

export const ImageStyles = theme =>
  StyleSheet.create({
    Image: { style: { height: 100, width: 100 }, props: {} },
  });

export const DeckSwiperStyles = theme =>
  StyleSheet.create({
    'Deck Swiper': { style: { position: 'absolute' }, props: {} },
  });

export const DeckSwiperCardStyles = theme =>
  StyleSheet.create({
    'Deck Swiper Card': {
      style: {
        alignItems: 'center',
        borderWidth: 2,
        justifyContent: 'center',
        padding: 20,
      },
      props: {},
    },
  });

export const LinkStyles = theme =>
  StyleSheet.create({
    Link: {
      style: {
        color: palettes.App['App Blue#4 007fd4'],
        fontFamily: 'Poppins_400Regular',
        fontSize: 12,
      },
      props: {},
    },
    'Link 2': {
      style: {
        color: palettes.App['App Blue#4 007fd4'],
        fontFamily: 'Poppins_400Regular',
        fontSize: 12,
      },
      props: {},
    },
  });

export const FlashListStyles = theme =>
  StyleSheet.create({
    'filter tags large - dark blue': {
      style: { flex: 1, flexDirection: 'row' },
      props: {},
    },
    'filter tags large - dark blue 2': {
      style: { flex: 1, flexDirection: 'row' },
      props: {},
    },
  });

export const WebViewStyles = theme =>
  StyleSheet.create({ 'HTML View': { style: { flex: 1 }, props: {} } });

export const TouchableStyles = theme =>
  StyleSheet.create({
    'customer service': { style: { marginBottom: 18 }, props: {} },
    'customer service 2': { style: { marginBottom: 18 }, props: {} },
  });
