import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as KnackAPIApi from '../apis/KnackAPIApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import * as Haptics from '../custom-files/Haptics';
import * as Track from '../custom-files/Track';
import * as Utility from '../custom-files/Utility';
import * as convertTime from '../custom-files/convertTime';
import * as validateAppVersionAndAuthToken from '../custom-files/validateAppVersionAndAuthToken';
import * as viewOpportunities from '../custom-files/viewOpportunities';
import Haptic from '../global-functions/Haptic';
import amplitudeEvents from '../global-functions/amplitudeEvents';
import getUserInitials from '../global-functions/getUserInitials';
import mathRound from '../global-functions/mathRound';
import openMap from '../global-functions/openMap';
import resetSendDeclinePackageResponse from '../global-functions/resetSendDeclinePackageResponse';
import resetSendRequestPackageResponse from '../global-functions/resetSendRequestPackageResponse';
import sendDeclinePackageNotice from '../global-functions/sendDeclinePackageNotice';
import splitStringOnComma from '../global-functions/splitStringOnComma';
import palettes from '../themes/palettes';
import * as Utils from '../utils';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  Button,
  Checkbox,
  DatePicker,
  Icon,
  IconButton,
  KeyboardAvoidingView,
  Link,
  NumberInput,
  Picker,
  Pressable,
  RadioButtonGroup,
  RadioButtonRow,
  SVG,
  ScreenContainer,
  Spacer,
  Surface,
  Switch,
  SwitchRow,
  TextInput,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { FlashList } from '@shopify/flash-list';
import {
  ActivityIndicator,
  Alert,
  ImageBackground,
  Modal,
  Platform,
  ScrollView,
  Text,
  View,
} from 'react-native';
import { Fetch } from 'react-request';

const OpportunityDetailsLiveScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [LSGEligible, setLSGEligible] = React.useState('');
  const [agreedToClientSchedule, setAgreedToClientSchedule] =
    React.useState(false);
  const [alternateScheduleExpanded, setAlternateScheduleExpanded] =
    React.useState(true);
  const [alternateScheduleFilled, setAlternateScheduleFilled] =
    React.useState(false);
  const [alternateScheduleRequested, setAlternateScheduleRequested] =
    React.useState(false);
  const [alternateScheduleShouldBeSent, setAlternateScheduleShouldBeSent] =
    React.useState(false);
  const [availableDays, setAvailableDays] = React.useState('');
  const [availableForRequest, setAvailableForRequest] = React.useState(true);
  const [availableTimesWeekDays, setAvailableTimesWeekDays] =
    React.useState('');
  const [availableTimesWeekEnds, setAvailableTimesWeekEnds] =
    React.useState('');
  const [availableUntil, setAvailableUntil] = React.useState('');
  const [basePayPerLesson, setBasePayPerLesson] = React.useState(0);
  const [checkboxRowValue, setCheckboxRowValue] = React.useState('');
  const [checkboxRowValue2, setCheckboxRowValue2] = React.useState('');
  const [checkboxValue, setCheckboxValue] = React.useState(false);
  const [checkboxValue2, setCheckboxValue2] = React.useState(false);
  const [checkboxValue3, setCheckboxValue3] = React.useState(false);
  const [clientName, setClientName] = React.useState('');
  const [completionBonusOwed, setCompletionBonusOwed] = React.useState(0);
  const [currentlyBookedSI, setCurrentlyBookedSI] = React.useState('');
  const [datePickerValue, setDatePickerValue] = React.useState(new Date());
  const [desiredStartDate, setDesiredStartDate] = React.useState('');
  const [drivingDistance, setDrivingDistance] = React.useState(0);
  const [empty, setEmpty] = React.useState('');
  const [enableRequestButton, setEnableRequestButton] = React.useState(false);
  const [falseValue, setFalseValue] = React.useState(false);
  const [flexibleSchedule, setFlexibleSchedule] = React.useState('');
  const [instructorBid, setInstructorBid] = React.useState(0);
  const [is_loading, setIs_loading] = React.useState(false);
  const [lessonDuration, setLessonDuration] = React.useState(0);
  const [lessonFrequencyPerWeek, setLessonFrequencyPerWeek] = React.useState(0);
  const [lessonsToBeCompleted, setLessonsToBeCompleted] = React.useState(0);
  const [opportunityStatus, setOpportunityStatus] = React.useState('');
  const [packageID, setPackageID] = React.useState(0);
  const [packageStatus, setPackageStatus] = React.useState('');
  const [pickerValue, setPickerValue] = React.useState('');
  const [pickerValue10, setPickerValue10] = React.useState('');
  const [pickerValue11, setPickerValue11] = React.useState('');
  const [pickerValue12, setPickerValue12] = React.useState('');
  const [pickerValue13, setPickerValue13] = React.useState('');
  const [pickerValue14, setPickerValue14] = React.useState('');
  const [pickerValue15, setPickerValue15] = React.useState('');
  const [pickerValue16, setPickerValue16] = React.useState('');
  const [pickerValue17, setPickerValue17] = React.useState('');
  const [pickerValue18, setPickerValue18] = React.useState('');
  const [pickerValue19, setPickerValue19] = React.useState('');
  const [pickerValue2, setPickerValue2] = React.useState('');
  const [pickerValue20, setPickerValue20] = React.useState('');
  const [pickerValue21, setPickerValue21] = React.useState('');
  const [pickerValue22, setPickerValue22] = React.useState('');
  const [pickerValue23, setPickerValue23] = React.useState('');
  const [pickerValue24, setPickerValue24] = React.useState('');
  const [pickerValue25, setPickerValue25] = React.useState('');
  const [pickerValue26, setPickerValue26] = React.useState('');
  const [pickerValue27, setPickerValue27] = React.useState('');
  const [pickerValue28, setPickerValue28] = React.useState('');
  const [pickerValue29, setPickerValue29] = React.useState('');
  const [pickerValue3, setPickerValue3] = React.useState('');
  const [pickerValue30, setPickerValue30] = React.useState('');
  const [pickerValue31, setPickerValue31] = React.useState('');
  const [pickerValue32, setPickerValue32] = React.useState('');
  const [pickerValue33, setPickerValue33] = React.useState('');
  const [pickerValue34, setPickerValue34] = React.useState('');
  const [pickerValue35, setPickerValue35] = React.useState('');
  const [pickerValue36, setPickerValue36] = React.useState('');
  const [pickerValue37, setPickerValue37] = React.useState('');
  const [pickerValue38, setPickerValue38] = React.useState('');
  const [pickerValue39, setPickerValue39] = React.useState('');
  const [pickerValue4, setPickerValue4] = React.useState('');
  const [pickerValue40, setPickerValue40] = React.useState('');
  const [pickerValue41, setPickerValue41] = React.useState('');
  const [pickerValue42, setPickerValue42] = React.useState('');
  const [pickerValue5, setPickerValue5] = React.useState('');
  const [pickerValue6, setPickerValue6] = React.useState('');
  const [pickerValue7, setPickerValue7] = React.useState('');
  const [pickerValue8, setPickerValue8] = React.useState('');
  const [pickerValue9, setPickerValue9] = React.useState('');
  const [poolAccess, setPoolAccess] = React.useState('');
  const [poolAddress, setPoolAddress] = React.useState('');
  const [poolAddressObj, setPoolAddressObj] = React.useState({});
  const [poolType, setPoolType] = React.useState('');
  const [radioButtonGroupValue, setRadioButtonGroupValue] = React.useState('');
  const [requestDate, setRequestDate] = React.useState('');
  const [showDeclineModal, setShowDeclineModal] = React.useState(false);
  const [showDeclineQuestions, setShowDeclineQuestions] = React.useState(true);
  const [showHigherPayQuestionnaire, setShowHigherPayQuestionnaire] =
    React.useState(false);
  const [showModalAPIError, setShowModalAPIError] = React.useState(false);
  const [showModalPackageStatusLegend, setShowModalPackageStatusLegend] =
    React.useState(false);
  const [showModalRequestSuccess, setShowModalRequestSuccess] =
    React.useState(false);
  const [showModalSSSGuarantee, setShowModalSSSGuarantee] =
    React.useState(false);
  const [showSubmitModal, setShowSubmitModal] = React.useState(false);
  const [specialSchedulingRequest, setSpecialSchedulingRequest] =
    React.useState('');
  const [studentDescription, setStudentDescription] = React.useState('');
  const [studentNotes, setStudentNotes] = React.useState('');
  const [switchRowValue, setSwitchRowValue] = React.useState(false);
  const [switchRowValue2, setSwitchRowValue2] = React.useState(false);
  const [switchRowValue3, setSwitchRowValue3] = React.useState(false);
  const [switchRowValue4, setSwitchRowValue4] = React.useState(false);
  const [switchRowValue5, setSwitchRowValue5] = React.useState(false);
  const [switchRowValue6, setSwitchRowValue6] = React.useState(false);
  const [switchRowValue7, setSwitchRowValue7] = React.useState(false);
  const [switchValue, setSwitchValue] = React.useState(false);
  const [textReasonWhy, setTextReasonWhy] = React.useState('');
  const [timeslotMonday, setTimeslotMonday] = React.useState(1);
  const [timeslotsFriday, setTimeslotsFriday] = React.useState(1);
  const [timeslotsSaturday, setTimeslotsSaturday] = React.useState(1);
  const [timeslotsSunday, setTimeslotsSunday] = React.useState(1);
  const [timeslotsThursday, setTimeslotsThursday] = React.useState(1);
  const [timeslotsTuesday, setTimeslotsTuesday] = React.useState(1);
  const [timeslotsWednesday, setTimeslotsWednesday] = React.useState(1);
  const [unpaidBasePay, setUnpaidBasePay] = React.useState(0);
  const [date, setDate] = React.useState(new Date());
  const sendPackageRequest = async (Variables, setGlobalVariableValue) => {
    setGlobalVariableValue({
      key: 'is_loading',
      value: true,
    });

    const { gInstructorEmailAddress } = Variables;

    let siResponse;
    let requestSource;
    let siSchedule = '';
    let supportingDetails = textReasonWhy;
    let requestedPayment = instructorBid;

    let mondaySchedule = '';
    let mondayTimeslot = '';
    let mondayTimeslot2 = '';
    let mondayTimeslot3 = '';

    let tuesdaySchedule = '';
    let tuesdayTimeslot = '';
    let tuesdayTimeslot2 = '';
    let tuesdayTimeslot3 = '';

    let wednesdaySchedule = '';
    let wednesdayTimeslot = '';
    let wednesdayTimeslot2 = '';
    let wednesdayTimeslot3 = '';

    let thursdaySchedule = '';
    let thursdayTimeslot = '';
    let thursdayTimeslot2 = '';
    let thursdayTimeslot3 = '';

    let fridaySchedule = '';
    let fridayTimeslot = '';
    let fridayTimeslot2 = '';
    let fridayTimeslot3 = '';

    let saturdaySchedule = '';
    let saturdayTimeslot = '';
    let saturdayTimeslot2 = '';
    let saturdayTimeslot3 = '';

    let sundaySchedule = '';
    let sundayTimeslot = '';
    let sundayTimeslot2 = '';
    let sundayTimeslot3 = '';

    let siStartDate = 'Start date: Same as client';
    let siAvailability = 'Availability: Same as client';

    if (agreedToClientSchedule) {
      siSchedule = 'Same as client';
    }

    if (alternateScheduleRequested) {
      // create siSchedule string

      // set monday schedule
      if (switchRowValue) {
        mondaySchedule += 'Monday: ';
        if (pickerValue && pickerValue2 && timeslotMonday >= 1) {
          mondayTimeslot += `${pickerValue} - ${pickerValue2}`;
        }
        if (pickerValue3 && pickerValue4 && timeslotMonday >= 2) {
          mondayTimeslot2 += `, ${pickerValue3} - ${pickerValue4}`;
        }
        if (pickerValue5 && pickerValue6 && timeslotMonday >= 3) {
          mondayTimeslot3 += `, ${pickerValue5} - ${pickerValue6}`;
        }

        mondaySchedule += `${mondayTimeslot}${mondayTimeslot2}${mondayTimeslot3} /`;
      }

      //set tuesday schedule
      if (switchRowValue2) {
        tuesdaySchedule += 'Tuesday: ';
        if (pickerValue7 && pickerValue8 && timeslotsTuesday >= 1) {
          tuesdayTimeslot += `${pickerValue7} - ${pickerValue8}`;
        }
        if (pickerValue9 && pickerValue10 && timeslotsTuesday >= 2) {
          tuesdayTimeslot2 += `, ${pickerValue9} - ${pickerValue10}`;
        }
        if (pickerValue11 && pickerValue12 && timeslotsTuesday >= 3) {
          tuesdayTimeslot3 += `, ${pickerValue11} - ${pickerValue12}`;
        }

        tuesdaySchedule += `${tuesdayTimeslot}${tuesdayTimeslot2}${tuesdayTimeslot3} /`;
      }

      //set wednesday schedule
      if (switchRowValue3) {
        wednesdaySchedule += 'Wednesday: ';
        if (pickerValue13 && pickerValue14 && timeslotsWednesday >= 1) {
          wednesdayTimeslot += `${pickerValue13} - ${pickerValue14}`;
        }
        if (pickerValue15 && pickerValue16 && timeslotsWednesday >= 2) {
          wednesdayTimeslot2 += `, ${pickerValue15} - ${pickerValue16}`;
        }
        if (pickerValue17 && pickerValue18 && timeslotsWednesday >= 3) {
          wednesdayTimeslot3 += `, ${pickerValue17} - ${pickerValue18}`;
        }

        wednesdaySchedule += `${wednesdayTimeslot}${wednesdayTimeslot2}${wednesdayTimeslot3} /`;
      }

      //set thursday schedule
      if (switchRowValue4) {
        thursdaySchedule += 'Thursday: ';
        if (pickerValue19 && pickerValue20 && timeslotsThursday >= 1) {
          thursdayTimeslot += `${pickerValue19} - ${pickerValue20}`;
        }
        if (pickerValue21 && pickerValue22 && timeslotsThursday >= 2) {
          thursdayTimeslot2 += `, ${pickerValue21} - ${pickerValue22}`;
        }
        if (pickerValue23 && pickerValue24 && timeslotsThursday >= 3) {
          thursdayTimeslot3 += `, ${pickerValue23} - ${pickerValue24}`;
        }

        thursdaySchedule += `${thursdayTimeslot}${thursdayTimeslot2}${thursdayTimeslot3} /`;
      }

      //set friday schedule
      if (switchRowValue5) {
        fridaySchedule += 'Friday: ';
        if (pickerValue25 && pickerValue26 && timeslotsFriday >= 1) {
          fridayTimeslot += `${pickerValue25} - ${pickerValue26}`;
        }
        if (pickerValue27 && pickerValue28 && timeslotsFriday >= 2) {
          fridayTimeslot2 += `, ${pickerValue27} - ${pickerValue28}`;
        }
        if (pickerValue29 && pickerValue30 && timeslotsFriday >= 3) {
          fridayTimeslot3 += `, ${pickerValue29} - ${pickerValue30}`;
        }

        fridaySchedule += `${fridayTimeslot}${fridayTimeslot2}${fridayTimeslot3} /`;
      }

      //set saturday schedule
      if (switchRowValue6) {
        saturdaySchedule += 'Saturday: ';
        if (pickerValue31 && pickerValue32 && timeslotsSaturday >= 1) {
          saturdayTimeslot += `${pickerValue31} - ${pickerValue32}`;
        }
        if (pickerValue33 && pickerValue34 && timeslotsSaturday >= 2) {
          saturdayTimeslot2 += `, ${pickerValue33} - ${pickerValue34}`;
        }
        if (pickerValue35 && pickerValue36 && timeslotsSaturday >= 3) {
          saturdayTimeslot3 += `, ${pickerValue35} - ${pickerValue36}`;
        }

        saturdaySchedule += `${saturdayTimeslot}${saturdayTimeslot2}${saturdayTimeslot3} /`;
      }

      //set sunday schedule
      if (switchRowValue7) {
        sundaySchedule += 'Sunday: ';
        if (pickerValue37 && pickerValue38 && timeslotsSunday >= 1) {
          sundayTimeslot += `${pickerValue37} - ${pickerValue38}`;
        }
        if (pickerValue39 && pickerValue40 && timeslotsSunday >= 2) {
          sundayTimeslot2 += `, ${pickerValue39} - ${pickerValue40}`;
        }
        if (pickerValue41 && pickerValue42 && timeslotsSunday >= 3) {
          sundayTimeslot3 += `, ${pickerValue41} - ${pickerValue42}`;
        }

        sundaySchedule += `${sundayTimeslot}${sundayTimeslot2}${sundayTimeslot3} /`;
      }

      //set alternate start date
      if (switchValue) {
        console.log('datePickerValue === ', datePickerValue);
        const formattedStartDate = convertTime
          .moment(datePickerValue)
          .format('MM/DD/YY');
        siStartDate = `Proposed Start Date: ${formattedStartDate}`;
        console.log('siStartDate === ', siStartDate);
      }

      if (
        mondaySchedule ||
        tuesdaySchedule ||
        wednesdaySchedule ||
        thursdaySchedule ||
        fridaySchedule ||
        saturdaySchedule ||
        sundaySchedule
      ) {
        siAvailability = `Availability: ${mondaySchedule} ${tuesdaySchedule} ${wednesdaySchedule} ${thursdaySchedule} ${fridaySchedule} ${saturdaySchedule} ${sundaySchedule}`;
      }

      siSchedule = `${siStartDate} | ${siAvailability}`;
    }

    const opportunityId2 = `${packageID}-${gInstructorEmailAddress}`;

    const data = {
      siReponse: 'Requested',
      requestSource: requestSource ?? 'app',
      opportunityId2,
      siSchedule,
      supportingDetails,
      requestedPayment,
    };

    try {
      const sendRequest = await fetch(
        `https://hook.us1.make.com/buphwh7yjenaklgddxdnrrvu8fqwhdwm`,
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        }
      );

      const sendRequestResponse = await sendRequest.json();

      if (!sendRequest.ok) {
        console.log('REPONSE NOT OK BLOCK');
        // setGlobalVariableValue({
        //     key: 'authError',
        //     value: sendRequest.errorDescription
        // })

        setGlobalVariableValue({
          key: 'sendRequestPackageResponse',
          value: sendRequestResponse.errorDescription,
        });

        setGlobalVariableValue({
          key: 'is_loading',
          value: false,
        });

        setShowModalAPIError(true);

        throw new Error(
          `Error! status: ${sendRequestResponse.errorsDescription}`
        );
      }

      setGlobalVariableValue({
        key: 'is_loading',
        value: false,
      });

      setShowSubmitModal(false);

      setGlobalVariableValue({
        key: 'sendRequestPackageResponse',
        value: sendRequestResponse.message,
      });

      setShowModalRequestSuccess(true);
      Haptics.Haptic('medium');
    } catch (err) {
      console.error(`Error! ${err}`);
    }
  };

  const alertDeclineReasonRequired = () => {
    alert('Please select a decline reason.');
  };

  const changeTimeslotsCount = (day, slotCount, math) => {
    const allSlotCounts = {
      mon: setTimeslotMonday,
      tue: setTimeslotsTuesday,
      wed: setTimeslotsWednesday,
      thu: setTimeslotsThursday,
      fri: setTimeslotsFriday,
      sat: setTimeslotsSaturday,
      sun: setTimeslotsSunday,
    };

    if (math === 'increment') {
      if (slotCount >= 3) {
        return;
      }

      slotCount++;
    } else {
      slotCount--;
    }

    allSlotCounts[day](slotCount);
  };

  const checkShouldSendAlternateSchedule = () => {
    if (alternateScheduleRequested && alternateScheduleFilled) {
      setAlternateScheduleShouldBeSent(true);
    }
  };

  const closeModalRequestSuccess = async (
    Variables,
    setGlobalVariableValue
  ) => {
    setGlobalVariableValue({
      key: 'sendRequestPackageResponse',
      value: '',
    });

    setShowModalRequestSuccess(false);
  };

  const mathRound = num => {
    return Math.round(num);
  };

  const calcBidPayPerLesson = (instructorBid, numberOfLessons) => {
    const payPerLesson = instructorBid / numberOfLessons;
    return Math.round(payPerLesson, 2);
  };

  const getDate = string => {
    let date = string.replace('On a particular day', '');

    return date;
  };

  const getStudentsFirstName = students => {
    if (!students) {
      return;
    }

    let studentNamesStr = '';
    let studentsArr = students.split(',');

    studentsArr.forEach((student, i) => {
      let studentNames = student.split(' ');

      if (i < 1) {
        studentNamesStr += studentNames[0];
      }

      if (i > 0) {
        studentNamesStr += ` and ${studentNames[1]}`;
      }
    });

    return studentNamesStr;
  };

  const extractEmailAddress = string => {
    const emailRegex = /[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}/;
    const match = string.match(emailRegex);

    if (match) {
      return match[0];
    } else {
      return null;
    }
  };

  const shouldPreventJobRequest = (navigation, Variables) => {
    const { gAccountReadyForClients } = Variables;

    // if (!gAccountReadyForClients) {
    //     if (Platform.OS == 'web') {
    //         alert('Hold! 🏊 Before diving into requesting packages, you must complete your account setup.');
    //     } else {
    //         Alert.alert('Hold! 🏊', 'Before diving into requesting packages, you must complete your account setup.', [
    //             {
    //                 text: 'Finish Setup',
    //                 onPress: () => {
    //                     navigation.navigate('MyAccountScreen')
    //                 }
    //             }
    //         ]);
    //     }
    //     return true;
    // }

    return false;
  };

  const alertPoolType = () => {
    let headline = poolType;
    let msg = `These lessons take place at the client's ${poolType}`;

    if (poolType.includes('No Pool')) {
      headline = `This Client Has No Pool`;
      msg = `You must host this client at a pool you provide. This client will travel to you.`;
    }

    if (Platform.OS == 'web') {
      alert(msg);
    } else {
      Alert.alert(headline, msg, [
        {
          text: 'OK',
        },
      ]);
    }
  };

  const setScreenVariables = (Variables, opportunity) => {
    const {
      field_123_raw, // packageID
      field_127_raw, //
      field_62_raw,
      field_176_raw,
      field_170_raw,
      field_692_raw, //total unpaid base pay
      field_171_raw,
      field_173_raw,
      field_253_raw,
      field_249_raw,
      field_314_raw,
      field_2394_raw,
      field_2395_raw,
      field_167_raw,
      field_460_raw,
      field_733_raw,
      field_2377_raw,
      field_455_raw,
      field_1890_raw,
      field_726_raw,
      field_99_raw,
      field_126_raw,
      field_449_raw,
      field_521_raw,
      field_239,
      field_130, //requestDate (date instructor requested or declined package)
      field_231_raw, //package status
    } = opportunity;

    // console.log('opportunity === ', opportunity);

    const matchedSwimInstructor = Utility.extractEmailAddress(field_239);
    const determinedPackageStatus = viewOpportunities.determinePackageStatus(
      field_231_raw,
      matchedSwimInstructor,
      field_127_raw,
      Variables.gInstructorEmailAddress
    );

    setPackageID(field_123_raw[0].identifier);
    setOpportunityStatus(field_127_raw);
    setLessonsToBeCompleted(field_62_raw);
    setLessonDuration(field_176_raw);
    // setUnpaidBasePay(field_170_raw);
    setUnpaidBasePay(field_692_raw);
    setBasePayPerLesson(field_171_raw);
    setCompletionBonusOwed(field_173_raw);
    setLessonFrequencyPerWeek(field_253_raw);
    setLSGEligible(field_249_raw === true);
    setAvailableDays(field_314_raw);
    setAvailableTimesWeekDays(field_2394_raw);
    setAvailableTimesWeekEnds(field_2395_raw);
    setSpecialSchedulingRequest(field_167_raw);
    setDesiredStartDate(field_460_raw);
    setAvailableUntil(field_733_raw);
    setFlexibleSchedule(field_2377_raw);
    setClientName(field_455_raw);
    setPoolAddress(field_1890_raw.full);
    setPoolAddressObj(field_1890_raw);
    setPoolAccess(field_726_raw);
    setPoolType(field_99_raw);
    setDrivingDistance(field_126_raw);
    setStudentDescription(field_449_raw);
    setStudentNotes(field_521_raw);
    setCurrentlyBookedSI(matchedSwimInstructor);
    setPackageStatus(determinedPackageStatus);
    setRequestDate(field_130);
  };
  React.useEffect(() => {
    Track.onEnterScreen(
      'OpportunityDetailsLiveScreen',
      Variables,
      setGlobalVariableValue,
      props?.route?.params?.skipAuth
    );
  }, []);

  React.useEffect(() => {
    function alternateTimesRequested() {
      return (
        switchRowValue ||
        switchRowValue2 ||
        switchRowValue3 ||
        switchRowValue4 ||
        switchRowValue5 ||
        switchRowValue6 ||
        switchRowValue7
      );
    }

    function alternateTimesFilled() {
      return (
        (switchRowValue && pickerValue && pickerValue2) ||
        (switchRowValue2 && pickerValue7 && pickerValue8) ||
        (switchRowValue3 && pickerValue13 && pickerValue14) ||
        (switchRowValue4 && pickerValue19 && pickerValue20) ||
        (switchRowValue5 && pickerValue25 && pickerValue26) ||
        (switchRowValue6 && pickerValue31 && pickerValue32) ||
        (switchRowValue7 && pickerValue37 && pickerValue38)
      );
    }

    function shouldSendAlternateSchedule() {
      if (
        (alternateScheduleRequested && alternateTimesFilled()) ||
        alternateStartDate
      ) {
        return true;
      }

      return false;
    }

    function shouldEnableRequestButton() {
      if (agreedToClientSchedule) {
        return true;
      }

      if (alternateTimesRequested() && alternateTimesFilled()) {
        return true;
      }

      if (!alternateTimesRequested() && alternateStartDate) {
        return true;
      }

      return false;
    }

    function alternateScheduleFilled() {
      return alternateTimesFilled() || alternateStartDate;
    }

    let alternateStartDate = switchValue;

    if (alternateScheduleRequested) {
      setAlternateScheduleFilled(alternateScheduleFilled());
    }

    setAlternateScheduleShouldBeSent(shouldSendAlternateSchedule());
    setEnableRequestButton(shouldEnableRequestButton());
  });
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      setInstructorBid(null);
      setCheckboxValue2(null);
      setAlternateScheduleRequested(null);
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={false}
      hasTopSafeArea={true}
      style={StyleSheet.applyWidth(
        { backgroundColor: palettes.App['App Gold #FFC400'] },
        dimensions.width
      )}
    >
      {/* View Available Finished Loading */}
      <View>
        {/* View Available Status */}
        <>
          {!(null === 'Unmatched') ? null : (
            <View
              style={StyleSheet.applyWidth(
                { borderRadius: 8, flexDirection: 'row' },
                dimensions.width
              )}
            >
              {/* Not Requested By SI */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    backgroundColor: palettes.App['App Blue/Low Emphasis'],
                    borderRadius: 8,
                    paddingBottom: 8,
                    paddingLeft: 8,
                    paddingRight: 8,
                    paddingTop: 8,
                  },
                  dimensions.width
                )}
              >
                {/* Available */}
                <Text
                  accessible={true}
                  style={StyleSheet.applyWidth(
                    {
                      color: palettes.App['App Blue#4 007fd4'],
                      fontFamily: 'Poppins_400Regular',
                      fontSize: 12,
                    },
                    dimensions.width
                  )}
                >
                  {'Available'}
                </Text>
              </View>
            </View>
          )}
        </>
      </View>

      <KnackAPIApi.FetchGetOpportunityDetailsGET
        handlers={{
          onData: fetchData => {
            try {
              setScreenVariables(Variables, fetchData);
            } catch (err) {
              console.error(err);
            }
          },
        }}
        opportunityID={
          props.route?.params?.opportunityID ?? '65b208dec3764800293c763a'
        }
      >
        {({ loading, error, data, refetchGetOpportunityDetails }) => {
          const fetchData = data?.json;
          if (loading) {
            return <ActivityIndicator />;
          }

          if (error || data?.status < 200 || data?.status >= 300) {
            return <ActivityIndicator />;
          }

          return null;
        }}
      </KnackAPIApi.FetchGetOpportunityDetailsGET>
      {/* View Header Container */}
      <View
        style={StyleSheet.applyWidth(
          { justifyContent: 'center' },
          dimensions.width
        )}
      >
        {/* Spacer Top Nav */}
        <Spacer bottom={0} left={0} right={0} top={44} />
        {/* View Header Navigation */}
        <View
          style={StyleSheet.applyWidth(
            {
              flexDirection: 'row',
              justifyContent: 'space-between',
              paddingBottom: 22,
              paddingLeft: '5.35%',
              paddingRight: '5.35%',
              paddingTop: 14,
            },
            dimensions.width
          )}
        >
          {/* View Back Button Container */}
          <View
            style={StyleSheet.applyWidth(
              { height: 24, width: 24 },
              dimensions.width
            )}
          >
            <Touchable
              onPress={() => {
                try {
                  navigation.navigate('JobsScreen');
                } catch (err) {
                  console.error(err);
                }
              }}
              style={StyleSheet.applyWidth(
                { height: '100%', width: '100%' },
                dimensions.width
              )}
            >
              {/* View Touchable Settings */}
              <View
                style={StyleSheet.applyWidth(
                  { height: '100%', width: '100%' },
                  dimensions.width
                )}
              >
                <SVG
                  source={Constants['uiBackArrow']}
                  style={StyleSheet.applyWidth(
                    { height: '100%', width: '100%' },
                    dimensions.width
                  )}
                />
              </View>
            </Touchable>
          </View>
          {/* View Page Title Container */}
          <View>
            {/* Page Title */}
            <Text
              accessible={true}
              style={StyleSheet.applyWidth(
                {
                  alignSelf: 'center',
                  color: theme.colors.text.strong,
                  fontFamily: 'Poppins_700Bold',
                },
                dimensions.width
              )}
            >
              {'Job Details'}
            </Text>
          </View>
          {/* Spacer */}
          <View
            style={StyleSheet.applyWidth(
              { height: 24, width: 24 },
              dimensions.width
            )}
          >
            {/* Spacer */}
            <Link
              accessible={true}
              onPress={() => {
                try {
                  navigation.navigate('Login2Screen');
                } catch (err) {
                  console.error(err);
                }
              }}
              style={StyleSheet.applyWidth(
                {
                  color: palettes.App['App Black 191F32'],
                  fontFamily: 'BebasNeue_400Regular',
                  fontSize: 18,
                  textDecorationLine: 'underline',
                  textTransform: 'uppercase',
                },
                dimensions.width
              )}
              title={''}
            />
          </View>
        </View>
      </View>
      {/* Curve Container */}
      <View
        style={StyleSheet.applyWidth(
          {
            backgroundColor: palettes.App['Custom Color'],
            borderTopLeftRadius: 16,
            borderTopRightRadius: 16,
            paddingTop: 15,
          },
          dimensions.width
        )}
      >
        <ScrollView
          bounces={true}
          horizontal={false}
          keyboardShouldPersistTaps={'never'}
          nestedScrollEnabled={false}
          showsHorizontalScrollIndicator={true}
          showsVerticalScrollIndicator={true}
          contentContainerStyle={StyleSheet.applyWidth(
            {
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
              paddingBottom: 100,
            },
            dimensions.width
          )}
        >
          {/* Loading Placeholder */}
          <>
            {packageID ? null : (
              <View>
                {/* loading client block */}
                <View>
                  {/* Placeholder Primary Details */}
                  <View
                    style={StyleSheet.applyWidth(
                      { paddingLeft: '5.35%', paddingRight: '5.35%' },
                      dimensions.width
                    )}
                  >
                    {/* Placeholder Card Title */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          marginBottom: 24,
                          width: '100%',
                        },
                        dimensions.width
                      )}
                    >
                      {/* Client Name Placeholder */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            backgroundColor: palettes.App['App Blue eef6fa'],
                            borderRadius: 8,
                            height: 56,
                            width: '72%',
                          },
                          dimensions.width
                        )}
                      />
                      {/* Loading Status */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'flex-end',
                            justifyContent: 'center',
                            width: '24%',
                          },
                          dimensions.width
                        )}
                      >
                        {/* View Status Loading */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: 'center',
                              backgroundColor:
                                palettes.App['App Disabled Button EDEDEF'],
                              borderRadius: 8,
                              flexDirection: 'row',
                              justifyContent: 'center',
                              paddingBottom: 8,
                              paddingLeft: 8,
                              paddingRight: 8,
                              paddingTop: 8,
                              width: '100%',
                            },
                            dimensions.width
                          )}
                        >
                          <ActivityIndicator
                            animating={true}
                            hidesWhenStopped={true}
                            size={'small'}
                            style={StyleSheet.applyWidth(
                              { height: 36, width: 36 },
                              dimensions.width
                            )}
                          />
                        </View>
                      </View>
                    </View>
                    {/* Placeholder Payment */}
                    <View
                      style={StyleSheet.applyWidth(
                        { marginBottom: 24 },
                        dimensions.width
                      )}
                    >
                      {/* Placeholder Surface */}
                      <Surface
                        elevation={3}
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            backgroundColor:
                              palettes.App['App Disabled Button EDEDEF'],
                            borderRadius: 16,
                            flexDirection: 'row',
                            height: 96,
                            justifyContent: 'space-around',
                            marginBottom: -16,
                            paddingBottom: 16,
                            paddingLeft: 8,
                            paddingRight: 8,
                            paddingTop: 16,
                            zIndex: 2,
                          },
                          dimensions.width
                        )}
                      />
                      {/* Placeholder Bottom */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            backgroundColor: palettes.App['App Blue eef6fa'],
                            borderBottomLeftRadius: 16,
                            borderBottomRightRadius: 16,
                            flexDirection: 'column',
                            height: 132,
                            justifyContent: 'center',
                            paddingBottom: 16,
                            paddingLeft: 16,
                            paddingRight: 16,
                            paddingTop: 32,
                          },
                          dimensions.width
                        )}
                      >
                        <ActivityIndicator
                          animating={true}
                          hidesWhenStopped={true}
                          size={'small'}
                          {...GlobalStyles.ActivityIndicatorStyles(theme)[
                            'Activity Indicator'
                          ].props}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.ActivityIndicatorStyles(theme)[
                                'Activity Indicator'
                              ].style,
                              { height: 48, width: 48 }
                            ),
                            dimensions.width
                          )}
                        />
                      </View>
                    </View>
                    {/* Placeholder Location Block */}
                    <View
                      style={StyleSheet.applyWidth(
                        { marginBottom: 24 },
                        dimensions.width
                      )}
                    >
                      {/* Placeholder Title */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'baseline',
                            borderRadius: 8,
                            flexDirection: 'row',
                            height: 32,
                            marginBottom: 16,
                          },
                          dimensions.width
                        )}
                      >
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              backgroundColor:
                                palettes.App['App Disabled Button EDEDEF'],
                              borderRadius: 8,
                              height: 32,
                              marginRight: 8,
                              width: 56,
                            },
                            dimensions.width
                          )}
                        />
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              backgroundColor:
                                palettes.App['App Disabled Button EDEDEF'],
                              borderRadius: 8,
                              height: 32,
                              marginRight: 8,
                              width: 112,
                            },
                            dimensions.width
                          )}
                        />
                      </View>
                      {/* Placeholder Address Block */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            backgroundColor: palettes.App['App Blue eef6fa'],
                            borderRadius: 16,
                            flexDirection: 'row',
                            flexWrap: 'nowrap',
                            height: 148,
                            paddingBottom: 16,
                            paddingLeft: 16,
                            paddingRight: 16,
                            paddingTop: 16,
                          },
                          dimensions.width
                        )}
                      />
                    </View>
                    {/* Placeholder Student Block */}
                    <View
                      style={StyleSheet.applyWidth(
                        { marginBottom: 24 },
                        dimensions.width
                      )}
                    >
                      {/* Placeholder Title */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'baseline',
                            borderRadius: 8,
                            flexDirection: 'row',
                            height: 32,
                            marginBottom: 16,
                          },
                          dimensions.width
                        )}
                      >
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              backgroundColor:
                                palettes.App['App Disabled Button EDEDEF'],
                              borderRadius: 8,
                              height: 32,
                              marginRight: 8,
                              width: 56,
                            },
                            dimensions.width
                          )}
                        />
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              backgroundColor:
                                palettes.App['App Disabled Button EDEDEF'],
                              borderRadius: 8,
                              height: 32,
                              marginRight: 8,
                              width: 112,
                            },
                            dimensions.width
                          )}
                        />
                      </View>
                      {/* Placeholder Student Details */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            backgroundColor: palettes.App['App Blue eef6fa'],
                            borderRadius: 16,
                            flexDirection: 'row',
                            height: 72,
                            marginBottom: 6,
                            paddingBottom: 16,
                            paddingLeft: 16,
                            paddingRight: 16,
                            paddingTop: 16,
                          },
                          dimensions.width
                        )}
                      />
                    </View>
                  </View>
                </View>
              </View>
            )}
          </>
          {/* View Card Container */}
          <>
            {!packageID ? null : (
              <View
                style={StyleSheet.applyWidth(
                  {
                    backgroundColor: palettes.App['Custom Color'],
                    borderRadius: 0,
                    borderTopLeftRadius: '16',
                    borderTopRightRadius: '16',
                    height: '90%',
                    justifyContent: 'space-between',
                    paddingBottom: 180,
                    width: '100%',
                  },
                  dimensions.width
                )}
              >
                {/* View Primary Details */}
                <View
                  style={StyleSheet.applyWidth(
                    { paddingLeft: '5.35%', paddingRight: '5.35%' },
                    dimensions.width
                  )}
                >
                  {/* View Card Title Container */}
                  <View
                    style={StyleSheet.applyWidth(
                      { marginBottom: 24, width: '100%' },
                      dimensions.width
                    )}
                  >
                    {/* View Top Details */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                        },
                        dimensions.width
                      )}
                    >
                      {/* Client Details */}
                      <View
                        style={StyleSheet.applyWidth(
                          { flexDirection: 'row' },
                          dimensions.width
                        )}
                      >
                        {/* View Client PP */}
                        <View
                          style={StyleSheet.applyWidth(
                            { height: 48, marginRight: 8, width: 48 },
                            dimensions.width
                          )}
                        >
                          <Surface
                            elevation={3}
                            style={StyleSheet.applyWidth(
                              {
                                borderBottomWidth: 1.25,
                                borderColor: palettes.App['Custom Color'],
                                borderLeftWidth: 1.25,
                                borderRadius: 100,
                                borderRightWidth: 1.25,
                                borderTopWidth: 1.25,
                                height: '100%',
                                width: '100%',
                              },
                              dimensions.width
                            )}
                          >
                            {/* SVG Avatar */}
                            <SVG
                              source={Constants['uiAvatar3']}
                              style={StyleSheet.applyWidth(
                                { height: '100%', width: '100%' },
                                dimensions.width
                              )}
                            />
                          </Surface>
                          {/* View Initials Container */}
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: 'center',
                                height: 48,
                                justifyContent: 'center',
                                marginTop: -48,
                                width: 48,
                              },
                              dimensions.width
                            )}
                          >
                            {/* Initials */}
                            <Text
                              accessible={true}
                              style={StyleSheet.applyWidth(
                                {
                                  color: palettes.App['Custom Color'],
                                  fontFamily: 'Poppins_500Medium',
                                  fontSize: 24,
                                  textTransform: 'uppercase',
                                },
                                dimensions.width
                              )}
                            >
                              {getUserInitials(clientName)}
                            </Text>
                          </View>
                        </View>
                        {/* View Client Details */}
                        <View>
                          {/* View Client Name */}
                          <View>
                            {/* Client Name */}
                            <Text
                              accessible={true}
                              style={StyleSheet.applyWidth(
                                {
                                  color: palettes.App['App Black 191F32'],
                                  fontFamily: 'Poppins_600SemiBold',
                                },
                                dimensions.width
                              )}
                            >
                              {clientName}
                            </Text>
                          </View>
                          {/* Package ID */}
                          <View>
                            {/* Package ID */}
                            <Text
                              accessible={true}
                              style={StyleSheet.applyWidth(
                                {
                                  color: palettes.App['App Text 2ndary 7F868C'],
                                  fontFamily: 'Poppins_400Regular',
                                  fontSize: 12,
                                },
                                dimensions.width
                              )}
                            >
                              {'ID '}
                              {packageID}
                            </Text>
                          </View>
                        </View>
                      </View>
                      {/* Package Status Details */}
                      <View
                        style={StyleSheet.applyWidth(
                          { alignItems: 'flex-end', justifyContent: 'center' },
                          dimensions.width
                        )}
                      >
                        <Pressable
                          onPress={() => {
                            try {
                              setShowModalPackageStatusLegend(true);
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                        >
                          {/* Requested by SI */}
                          <>
                            {!(packageStatus === 'Requested') ? null : (
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    backgroundColor:
                                      palettes.App['App Orange #FDF9EC'],
                                    borderRadius: 8,
                                    flexDirection: 'row',
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    paddingRight: 8,
                                    paddingTop: 8,
                                  },
                                  dimensions.width
                                )}
                              >
                                {/* Requested Status */}
                                <Text
                                  accessible={true}
                                  style={StyleSheet.applyWidth(
                                    {
                                      color: palettes.App['App Gold FFA900'],
                                      fontFamily: 'Poppins_400Regular',
                                      fontSize: 12,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {'Requested'}
                                </Text>
                              </View>
                            )}
                          </>
                          {/* Matched with Current SI Status */}
                          <>
                            {!(packageStatus === 'Matched') ? null : (
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    backgroundColor:
                                      palettes.App['App Mint #F0F8F2'],
                                    borderRadius: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    paddingRight: 8,
                                    paddingTop: 8,
                                  },
                                  dimensions.width
                                )}
                              >
                                {/* Matched Status */}
                                <Text
                                  accessible={true}
                                  style={StyleSheet.applyWidth(
                                    {
                                      color:
                                        palettes.App['App Text Green #1DB058'],
                                      fontFamily: 'Poppins_400Regular',
                                      fontSize: 12,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {'Matched'}
                                </Text>
                              </View>
                            )}
                          </>
                          {/* Unavailable Status */}
                          <>
                            {!(packageStatus === 'Unavailable') ? null : (
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    backgroundColor:
                                      palettes.App[
                                        'App Disabled Button EDEDEF'
                                      ],
                                    borderRadius: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    paddingRight: 8,
                                    paddingTop: 8,
                                  },
                                  dimensions.width
                                )}
                              >
                                {/* Unavailable Status */}
                                <Text
                                  accessible={true}
                                  style={StyleSheet.applyWidth(
                                    {
                                      color:
                                        palettes.App['App Text 2ndary 7F868C'],
                                      fontFamily: 'Poppins_400Regular',
                                      fontSize: 12,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {'Unavailable'}
                                </Text>
                              </View>
                            )}
                          </>
                          {/* Available Status */}
                          <>
                            {!(packageStatus === 'Available') ? null : (
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    backgroundColor:
                                      palettes.App['App Blue/Low Emphasis'],
                                    borderRadius: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    paddingRight: 8,
                                    paddingTop: 8,
                                  },
                                  dimensions.width
                                )}
                              >
                                {/* Available */}
                                <Text
                                  accessible={true}
                                  style={StyleSheet.applyWidth(
                                    {
                                      color: palettes.App['App Blue#4 007fd4'],
                                      fontFamily: 'Poppins_400Regular',
                                      fontSize: 12,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {'Available'}
                                </Text>
                              </View>
                            )}
                          </>
                          {/* View Status Loading */}
                          <>
                            {!is_loading ? null : (
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    alignItems: 'center',
                                    backgroundColor:
                                      palettes.App[
                                        'App Disabled Button EDEDEF'
                                      ],
                                    borderRadius: 8,
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    paddingRight: 8,
                                    paddingTop: 8,
                                  },
                                  dimensions.width
                                )}
                              >
                                <ActivityIndicator
                                  animating={true}
                                  hidesWhenStopped={true}
                                  size={'small'}
                                  style={StyleSheet.applyWidth(
                                    { height: 36, width: 36 },
                                    dimensions.width
                                  )}
                                />
                              </View>
                            )}
                          </>
                        </Pressable>
                      </View>
                    </View>
                    {/* View LSG Eligibility */}
                    <>
                      {!LSGEligible ? null : (
                        <View>
                          <Pressable
                            onPress={() => {
                              try {
                                setShowModalSSSGuarantee(true);
                              } catch (err) {
                                console.error(err);
                              }
                            }}
                          >
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: 'center',
                                  backgroundColor:
                                    palettes.App['App Blue/Low Emphasis'],
                                  borderColor: palettes.App['App Blue C7EAF3'],
                                  borderRadius: 8,
                                  borderWidth: 1,
                                  flexDirection: 'row',
                                  flexWrap: 'nowrap',
                                  justifyContent: 'space-between',
                                  marginTop: 10,
                                  padding: 4,
                                },
                                dimensions.width
                              )}
                            >
                              <Icon
                                size={24}
                                color={palettes.App['Custom Color_7']}
                                name={'MaterialCommunityIcons/swim'}
                                style={StyleSheet.applyWidth(
                                  { marginRight: 4 },
                                  dimensions.width
                                )}
                              />
                              <View
                                style={StyleSheet.applyWidth(
                                  { flexWrap: 'nowrap', maxWidth: '90%' },
                                  dimensions.width
                                )}
                              >
                                {/* Learn To Swim Guaranteed */}
                                <Text
                                  accessible={true}
                                  {...GlobalStyles.TextStyles(theme)['Text']
                                    .props}
                                  style={StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                      GlobalStyles.TextStyles(theme)['Text']
                                        .style,
                                      {
                                        color: palettes.App['Custom Color_7'],
                                        fontFamily: 'Poppins_400Regular',
                                      }
                                    ),
                                    dimensions.width
                                  )}
                                >
                                  {'This package is Learn To Swim Guaranteed™'}
                                </Text>
                              </View>
                              <IconButton
                                color={palettes.App['Custom Color_7']}
                                icon={
                                  'MaterialCommunityIcons/information-outline'
                                }
                                size={18}
                              />
                            </View>
                          </Pressable>
                        </View>
                      )}
                    </>
                  </View>
                  {/* View Row Pay and Lesson Details */}
                  <View
                    style={StyleSheet.applyWidth(
                      { marginBottom: 24 },
                      dimensions.width
                    )}
                  >
                    {/* Surface Lesson Pay Details */}
                    <Surface
                      elevation={3}
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'flex-start',
                          borderRadius: 16,
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          marginBottom: -16,
                          minHeight: 40,
                          paddingBottom: 16,
                          paddingLeft: 8,
                          paddingRight: 8,
                          paddingTop: 16,
                          zIndex: 2,
                        },
                        dimensions.width
                      )}
                    >
                      {/* View No Instructor Bid */}
                      <>
                        {instructorBid ? null : (
                          <View
                            style={StyleSheet.applyWidth(
                              { flexDirection: 'row', width: '63%' },
                              dimensions.width
                            )}
                          >
                            {/* View Base Pay */}
                            <View
                              style={StyleSheet.applyWidth(
                                { width: '57%' },
                                dimensions.width
                              )}
                            >
                              {/* Label Base Pay */}
                              <Text
                                accessible={true}
                                style={StyleSheet.applyWidth(
                                  {
                                    alignSelf: 'center',
                                    color:
                                      palettes.App['App Text 2ndary 7F868C'],
                                    fontFamily: 'Poppins_400Regular',
                                    fontSize: 14,
                                    marginBottom: 4,
                                  },
                                  dimensions.width
                                )}
                              >
                                {'Base Pay'}
                              </Text>
                              {/* Base Pay */}
                              <Text
                                accessible={true}
                                style={StyleSheet.applyWidth(
                                  {
                                    alignSelf: 'center',
                                    color:
                                      palettes.App['App Text Green #1DB058'],
                                    fontFamily: 'Poppins_600SemiBold',
                                    fontSize: 20,
                                  },
                                  dimensions.width
                                )}
                              >
                                {'$'}
                                {unpaidBasePay}
                              </Text>
                            </View>
                            {/* View Bonus Pay */}
                            <>
                              {!(completionBonusOwed > 0) ? null : (
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      borderColor:
                                        palettes.App[
                                          'App Disabled Button Text 7F868C'
                                        ],
                                      borderLeftWidth: 1,
                                      borderRightWidth: 1,
                                      width: '43%',
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {/* Label Bonus Pay */}
                                  <Text
                                    accessible={true}
                                    style={StyleSheet.applyWidth(
                                      {
                                        alignSelf: 'center',
                                        color:
                                          palettes.App[
                                            'App Text 2ndary 7F868C'
                                          ],
                                        fontFamily: 'Poppins_400Regular',
                                        fontSize: 14,
                                        marginBottom: 4,
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    {'Bonus'}
                                  </Text>
                                  {/* Bonus pay */}
                                  <Text
                                    accessible={true}
                                    style={StyleSheet.applyWidth(
                                      {
                                        alignSelf: 'center',
                                        color:
                                          palettes.App['App Sun Orange F4A100'],
                                        fontFamily: 'Poppins_600SemiBold',
                                        fontSize: 20,
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    {'+ $'}
                                    {completionBonusOwed}
                                  </Text>
                                </View>
                              )}
                            </>
                          </View>
                        )}
                      </>
                      {/* View Instructor Bid */}
                      <>
                        {!instructorBid ? null : (
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                borderColor:
                                  palettes.App[
                                    'App Disabled Button Text 7F868C'
                                  ],
                                borderRightWidth: 1,
                                flexDirection: 'column',
                                width: '63%',
                              },
                              dimensions.width
                            )}
                          >
                            {/* Label Your Bid */}
                            <Text
                              accessible={true}
                              style={StyleSheet.applyWidth(
                                {
                                  alignSelf: 'center',
                                  color: palettes.App['App Text 2ndary 7F868C'],
                                  fontFamily: 'Poppins_400Regular',
                                  fontSize: 14,
                                  marginBottom: 4,
                                },
                                dimensions.width
                              )}
                            >
                              {'Your Bid'}
                            </Text>
                            {/* Instructor Bid Amount */}
                            <Text
                              accessible={true}
                              style={StyleSheet.applyWidth(
                                {
                                  alignSelf: 'center',
                                  color: palettes.App['App Sun Orange F4A100'],
                                  fontFamily: 'Poppins_600SemiBold',
                                  fontSize: 20,
                                },
                                dimensions.width
                              )}
                            >
                              {'$'}
                              {instructorBid}
                              {'.00'}
                            </Text>
                          </View>
                        )}
                      </>
                      {/* View Lesson Details */}
                      <View
                        style={StyleSheet.applyWidth(
                          { width: '36%' },
                          dimensions.width
                        )}
                      >
                        {/* Label Total Lessons */}
                        <Text
                          accessible={true}
                          style={StyleSheet.applyWidth(
                            {
                              alignSelf: 'center',
                              color: palettes.App['App Text 2ndary 7F868C'],
                              fontFamily: 'Poppins_400Regular',
                              fontSize: 14,
                              marginBottom: 4,
                            },
                            dimensions.width
                          )}
                        >
                          {'Lesson Pack'}
                        </Text>
                        {/* Lesson Package */}
                        <Text
                          accessible={true}
                          style={StyleSheet.applyWidth(
                            {
                              alignSelf: 'center',
                              color: palettes.App['App Black 191F32'],
                              fontFamily: 'Poppins_600SemiBold',
                              fontSize: 20,
                            },
                            dimensions.width
                          )}
                        >
                          {lessonsToBeCompleted}
                          {'x'}
                          {lessonDuration}
                          {' min'}
                        </Text>
                      </View>
                    </Surface>
                    {/* View Paid Per Lesson Container */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          backgroundColor: palettes.App['Custom Color_6'],
                          borderBottomLeftRadius: 16,
                          borderBottomRightRadius: 16,
                          flexDirection: 'row',
                          paddingBottom: 16,
                          paddingLeft: 16,
                          paddingRight: 16,
                          paddingTop: 32,
                        },
                        dimensions.width
                      )}
                    >
                      <Icon
                        color={palettes.App['App Sun Orange F4A100']}
                        name={'Ionicons/information-circle-outline'}
                        size={18}
                      />
                      {/* Get paid x per lesson */}
                      <>
                        {instructorBid ? null : (
                          <Text
                            accessible={true}
                            style={StyleSheet.applyWidth(
                              {
                                color: palettes.App['App Sun Orange F4A100'],
                                fontFamily: 'Poppins_400Regular',
                                marginLeft: 4,
                              },
                              dimensions.width
                            )}
                          >
                            {'Get paid $'}
                            {basePayPerLesson}
                            {' per lesson'}
                          </Text>
                        )}
                      </>
                      {/* Get paid x per lesson bid */}
                      <>
                        {!instructorBid ? null : (
                          <Text
                            accessible={true}
                            style={StyleSheet.applyWidth(
                              {
                                color: palettes.App['App Sun Orange F4A100'],
                                fontFamily: 'Poppins_400Regular',
                                marginLeft: 4,
                              },
                              dimensions.width
                            )}
                          >
                            {'Get paid $'}
                            {calcBidPayPerLesson(instructorBid, null)}
                            {' per lesson bid'}
                          </Text>
                        )}
                      </>
                    </View>
                  </View>
                  {/* Package Body */}
                  <>
                    {!(packageStatus !== 'Unavailable') ? null : (
                      <View>
                        {/* View Location Block */}
                        <View
                          style={StyleSheet.applyWidth(
                            { marginBottom: 24 },
                            dimensions.width
                          )}
                        >
                          {/* View Title */}
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: 'baseline',
                                flexDirection: 'row',
                                marginBottom: 16,
                              },
                              dimensions.width
                            )}
                          >
                            {/* H1 */}
                            <Text
                              accessible={true}
                              style={StyleSheet.applyWidth(
                                {
                                  color: palettes.App['App Black 191F32'],
                                  fontFamily: 'BebasNeue_400Regular',
                                  fontSize: 24,
                                  marginRight: 4,
                                },
                                dimensions.width
                              )}
                            >
                              {'Client'}
                            </Text>
                            {/* H1 Accent */}
                            <Text
                              accessible={true}
                              style={StyleSheet.applyWidth(
                                {
                                  color: palettes.App['App Red EB3E26'],
                                  fontFamily: 'LeckerliOne_400Regular',
                                  fontSize: 22,
                                },
                                dimensions.width
                              )}
                            >
                              {'Location'}
                            </Text>
                          </View>
                          {/* View Address Block */}
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: 'center',
                                backgroundColor: palettes.App['Custom Color_6'],
                                borderRadius: 16,
                                flexDirection: 'row',
                                flexWrap: 'nowrap',
                                paddingBottom: 16,
                                paddingLeft: 16,
                                paddingRight: 16,
                                paddingTop: 16,
                              },
                              dimensions.width
                            )}
                          >
                            {/* View Icon Container */}
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: 'flex-start',
                                  height: 40,
                                  width: 52,
                                },
                                dimensions.width
                              )}
                            >
                              <SVG
                                source={Constants['uiLocationIcon']}
                                style={StyleSheet.applyWidth(
                                  { height: 40, width: 40 },
                                  dimensions.width
                                )}
                              />
                            </View>

                            <Pressable
                              onPress={() => {
                                try {
                                  openMap(
                                    poolAddressObj?.street,
                                    poolAddressObj?.city,
                                    poolAddressObj?.zip
                                  );
                                } catch (err) {
                                  console.error(err);
                                }
                              }}
                              style={StyleSheet.applyWidth(
                                { maxWidth: '96%' },
                                dimensions.width
                              )}
                            >
                              {/* View Address and Icon Container */}
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    alignItems: 'center',
                                    flexDirection: 'row',
                                    paddingRight: 16,
                                  },
                                  dimensions.width
                                )}
                              >
                                {/* View Address and Distance Conatiner */}
                                <View
                                  style={StyleSheet.applyWidth(
                                    { flexWrap: 'nowrap', width: '80%' },
                                    dimensions.width
                                  )}
                                >
                                  {/* Address Container */}
                                  <View>
                                    {/* Address */}
                                    <Text
                                      accessible={true}
                                      style={StyleSheet.applyWidth(
                                        {
                                          color: theme.colors.text.strong,
                                          fontFamily: 'Poppins_600SemiBold',
                                          fontSize: 14,
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      {poolAddress}
                                    </Text>
                                  </View>
                                  {/* View Distance Container */}
                                  <View>
                                    {/* Distance */}
                                    <Text
                                      accessible={true}
                                      style={StyleSheet.applyWidth(
                                        {
                                          color:
                                            palettes.App[
                                              'App Text 2ndary 7F868C'
                                            ],
                                          fontFamily: 'Poppins_400Regular',
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      {mathRound(drivingDistance)}
                                      {' Miles Away'}
                                    </Text>
                                  </View>
                                </View>
                                {/* View Right Arrow Icon Container */}
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      alignItems: 'center',
                                      height: 16,
                                      width: '20%',
                                    },
                                    dimensions.width
                                  )}
                                >
                                  <SVG
                                    source={Constants['uiArrowRightBlack']}
                                    style={StyleSheet.applyWidth(
                                      { height: 16, width: 16 },
                                      dimensions.width
                                    )}
                                  />
                                </View>
                              </View>
                            </Pressable>
                          </View>
                          {/* Pool type */}
                          <View
                            {...GlobalStyles.ViewStyles(theme)[
                              'pool type block'
                            ].props}
                            style={StyleSheet.applyWidth(
                              GlobalStyles.ViewStyles(theme)['pool type block']
                                .style,
                              dimensions.width
                            )}
                          >
                            {/* Alert pool type */}
                            <Touchable
                              onPress={() => {
                                try {
                                  alertPoolType();
                                } catch (err) {
                                  console.error(err);
                                }
                              }}
                              style={StyleSheet.applyWidth(
                                { width: '100%' },
                                dimensions.width
                              )}
                            >
                              {/* View Row */}
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                  },
                                  dimensions.width
                                )}
                              >
                                {/* Pool Icon */}
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                      marginLeft: 20,
                                      marginRight: 12,
                                      width: 4,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {/* Home Pool */}
                                  <>
                                    {!(poolType === 'Home Pool') ? null : (
                                      <Icon
                                        color={palettes.App['App Black 191F32']}
                                        name={'MaterialCommunityIcons/home'}
                                        size={18}
                                        style={StyleSheet.applyWidth(
                                          { marginRight: 6 },
                                          dimensions.width
                                        )}
                                      />
                                    )}
                                  </>
                                  {/* Housing Community Pool */}
                                  <>
                                    {!(
                                      poolType === 'Housing Community Pool'
                                    ) ? null : (
                                      <Icon
                                        color={palettes.App['App Black 191F32']}
                                        name={
                                          'MaterialCommunityIcons/home-city-outline'
                                        }
                                        size={18}
                                      />
                                    )}
                                  </>
                                  {/* Public Pool */}
                                  <>
                                    {!(poolType === 'Pubic Pool') ? null : (
                                      <Icon
                                        color={palettes.App['App Black 191F32']}
                                        name={'MaterialCommunityIcons/city'}
                                        size={20}
                                      />
                                    )}
                                  </>
                                  {/* Hotel Pool */}
                                  <>
                                    {!(poolType === 'Hotel Pool') ? null : (
                                      <Icon
                                        color={palettes.App['App Black 191F32']}
                                        name={
                                          'MaterialCommunityIcons/office-building'
                                        }
                                        size={20}
                                      />
                                    )}
                                  </>
                                  {/* Members Only */}
                                  <>
                                    {!poolType.includes(
                                      'Members Only'
                                    ) ? null : (
                                      <Icon
                                        name={
                                          'MaterialCommunityIcons/card-account-details-star-outline'
                                        }
                                        size={18}
                                      />
                                    )}
                                  </>
                                  {/* Other Pool */}
                                  <>
                                    {!(poolType === 'Other Pool') ? null : (
                                      <Icon
                                        name={
                                          'MaterialCommunityIcons/home-modern'
                                        }
                                        size={20}
                                      />
                                    )}
                                  </>
                                  {/* No Pool */}
                                  <>
                                    {!poolType.includes('No Pool') ? null : (
                                      <Icon
                                        color={palettes.App['App Red EB3E26']}
                                        name={
                                          'MaterialCommunityIcons/alert-rhombus'
                                        }
                                        size={20}
                                      />
                                    )}
                                  </>
                                </View>
                                {/* View Pool Type */}
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      flexDirection: 'row',
                                      justifyContent: 'space-between',
                                      width: '90%',
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {/* Pool Type */}
                                  <Text
                                    accessible={true}
                                    {...GlobalStyles.TextStyles(theme)['Text']
                                      .props}
                                    style={StyleSheet.applyWidth(
                                      StyleSheet.compose(
                                        GlobalStyles.TextStyles(theme)['Text']
                                          .style,
                                        {
                                          color:
                                            palettes.App['App Black 191F32'],
                                          fontFamily: 'Poppins_400Regular',
                                          fontSize: 12,
                                        }
                                      ),
                                      dimensions.width
                                    )}
                                  >
                                    {poolType}
                                  </Text>
                                  {/* Info */}
                                  <IconButton
                                    color={
                                      palettes.App[
                                        'App Disabled Button Text 7F868C'
                                      ]
                                    }
                                    icon={
                                      'MaterialCommunityIcons/information-outline'
                                    }
                                    size={16}
                                  />
                                </View>
                              </View>
                            </Touchable>
                          </View>
                        </View>
                        {/* View Student Block */}
                        <View
                          style={StyleSheet.applyWidth(
                            { marginBottom: 24 },
                            dimensions.width
                          )}
                        >
                          {/* View Title */}
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: 'baseline',
                                flexDirection: 'row',
                                marginBottom: 16,
                              },
                              dimensions.width
                            )}
                          >
                            {/* H1 */}
                            <Text
                              accessible={true}
                              style={StyleSheet.applyWidth(
                                {
                                  color: palettes.App['App Black 191F32'],
                                  fontFamily: 'BebasNeue_400Regular',
                                  fontSize: 24,
                                  marginRight: 4,
                                },
                                dimensions.width
                              )}
                            >
                              {'Student'}
                            </Text>
                            {/* H1 Accent */}
                            <Text
                              accessible={true}
                              style={StyleSheet.applyWidth(
                                {
                                  color: palettes.App['App Red EB3E26'],
                                  fontFamily: 'LeckerliOne_400Regular',
                                  fontSize: 22,
                                },
                                dimensions.width
                              )}
                            >
                              {'Details'}
                            </Text>
                          </View>
                          <FlashList
                            data={splitStringOnComma(studentDescription)}
                            estimatedItemSize={50}
                            horizontal={false}
                            inverted={false}
                            keyExtractor={(flashListData, index) =>
                              flashListData?.id ??
                              flashListData?.uuid ??
                              index.toString()
                            }
                            listKey={'KvgzIgNo'}
                            numColumns={1}
                            onEndReachedThreshold={0.5}
                            renderItem={({ item, index }) => {
                              const flashListData = item;
                              return (
                                <>
                                  {/* View Student Details Block */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        alignItems: 'center',
                                        backgroundColor:
                                          palettes.App['Custom Color_6'],
                                        borderRadius: 16,
                                        flexDirection: 'row',
                                        marginBottom: 6,
                                        paddingBottom: 16,
                                        paddingLeft: 16,
                                        paddingRight: 16,
                                        paddingTop: 16,
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    {/* View Icon Container */}
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          alignItems: 'center',
                                          height: 40,
                                          justifyContent: 'center',
                                          marginRight: 12,
                                          width: 40,
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      <SVG
                                        source={
                                          Constants['uiProfileOrangeBgCircle']
                                        }
                                        style={StyleSheet.applyWidth(
                                          { height: 40, width: 40 },
                                          dimensions.width
                                        )}
                                      />
                                    </View>
                                    {/* View Name and Age */}
                                    <View>
                                      {/* Name */}
                                      <View>
                                        {/* Student name */}
                                        <Text
                                          accessible={true}
                                          style={StyleSheet.applyWidth(
                                            {
                                              color: theme.colors.text.strong,
                                              fontFamily: 'Poppins_600SemiBold',
                                              textTransform: 'capitalize',
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          {flashListData}
                                        </Text>
                                      </View>
                                      {/* Age */}
                                      <>
                                        {!falseValue ? null : (
                                          <View>
                                            {/* Age */}
                                            <Text
                                              accessible={true}
                                              style={StyleSheet.applyWidth(
                                                {
                                                  color:
                                                    palettes.App[
                                                      'App Text 2ndary 7F868C'
                                                    ],
                                                  fontFamily:
                                                    'Poppins_400Regular',
                                                },
                                                dimensions.width
                                              )}
                                            >
                                              {'30 - 40 Years Old'}
                                            </Text>
                                          </View>
                                        )}
                                      </>
                                    </View>
                                  </View>
                                </>
                              );
                            }}
                            showsHorizontalScrollIndicator={true}
                            showsVerticalScrollIndicator={true}
                            contentContainerStyle={StyleSheet.applyWidth(
                              { flex: 1 },
                              dimensions.width
                            )}
                          />
                          {/* View Additional Student Details */}
                          <View
                            {...GlobalStyles.ViewStyles(theme)[
                              'additional student details block'
                            ].props}
                            style={StyleSheet.applyWidth(
                              GlobalStyles.ViewStyles(theme)[
                                'additional student details block'
                              ].style,
                              dimensions.width
                            )}
                          >
                            {/* View Icon Container */}
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  marginLeft: 20,
                                  marginRight: 12,
                                  width: 4,
                                },
                                dimensions.width
                              )}
                            >
                              <Icon
                                color={palettes.App['App Black 191F32']}
                                name={'MaterialCommunityIcons/book-account'}
                                size={18}
                                style={StyleSheet.applyWidth(
                                  { marginRight: 6 },
                                  dimensions.width
                                )}
                              />
                            </View>

                            <View
                              style={StyleSheet.applyWidth(
                                { width: '90%' },
                                dimensions.width
                              )}
                            >
                              {/* Title */}
                              <Text
                                accessible={true}
                                {...GlobalStyles.TextStyles(theme)['Text']
                                  .props}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.TextStyles(theme)['Text']
                                      .style,
                                    {
                                      color: palettes.App['App Black 191F32'],
                                      fontFamily: 'Poppins_400Regular',
                                      fontSize: 12,
                                    }
                                  ),
                                  dimensions.width
                                )}
                              >
                                {'More information about '}
                                {getStudentsFirstName(studentDescription)}
                              </Text>
                              {/* Additional student info */}
                              <Text
                                accessible={true}
                                {...GlobalStyles.TextStyles(theme)['Text']
                                  .props}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.TextStyles(theme)['Text']
                                      .style,
                                    {
                                      color:
                                        palettes.App[
                                          'App Disabled Button Text 7F868C'
                                        ],
                                      fontFamily: 'Poppins_400Regular',
                                      fontSize: 12,
                                      marginTop: 6,
                                    }
                                  ),
                                  dimensions.width
                                )}
                              >
                                {studentNotes}
                              </Text>
                            </View>
                          </View>
                        </View>
                        {/* View Divider */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              backgroundColor: palettes.App['App Grey E5E5E5'],
                              height: 4,
                              width: '100%',
                            },
                            dimensions.width
                          )}
                        />
                        {/* View Scheduling Details */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              paddingLeft: '5.35%',
                              paddingRight: '5.35%',
                              paddingTop: 24,
                            },
                            dimensions.width
                          )}
                        >
                          {/* View Schedule Block */}
                          <View
                            style={StyleSheet.applyWidth(
                              { marginBottom: 24 },
                              dimensions.width
                            )}
                          >
                            {/* View Title */}
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: 'baseline',
                                  flexDirection: 'row',
                                  marginBottom: 16,
                                },
                                dimensions.width
                              )}
                            >
                              {/* H1 */}
                              <Text
                                accessible={true}
                                style={StyleSheet.applyWidth(
                                  {
                                    color: palettes.App['App Black 191F32'],
                                    fontFamily: 'BebasNeue_400Regular',
                                    fontSize: 24,
                                    marginRight: 4,
                                  },
                                  dimensions.width
                                )}
                              >
                                {'Requested'}
                              </Text>
                              {/* H1 Accent */}
                              <Text
                                accessible={true}
                                style={StyleSheet.applyWidth(
                                  {
                                    color: palettes.App['App Red EB3E26'],
                                    fontFamily: 'LeckerliOne_400Regular',
                                    fontSize: 22,
                                  },
                                  dimensions.width
                                )}
                              >
                                {'Schedule'}
                              </Text>
                            </View>
                            {/* Scheduling Start and Lessons Req */}
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: 'flex-start',
                                  borderBottomWidth: 1,
                                  borderColor:
                                    palettes.App['App Grey Inputs E2E2E2'],
                                  flexDirection: 'row',
                                  paddingBottom: 16,
                                },
                                dimensions.width
                              )}
                            >
                              {/* Start Date Block */}
                              <View
                                style={StyleSheet.applyWidth(
                                  { width: '50%' },
                                  dimensions.width
                                )}
                              >
                                {/* Label */}
                                <View>
                                  {/* Start */}
                                  <Text
                                    accessible={true}
                                    style={StyleSheet.applyWidth(
                                      {
                                        color:
                                          palettes.App[
                                            'App Text 2ndary 7F868C'
                                          ],
                                        fontFamily: 'Poppins_400Regular',
                                        fontSize: 12,
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    {'Start Date'}
                                  </Text>
                                </View>
                                {/* Date */}
                                <View>
                                  {/* Date */}
                                  <Text
                                    accessible={true}
                                    style={StyleSheet.applyWidth(
                                      {
                                        color: theme.colors.text.strong,
                                        fontFamily: 'Poppins_600SemiBold',
                                        fontSize: 14,
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    {getDate(desiredStartDate)}
                                  </Text>
                                </View>
                              </View>
                              {/* Lessons Per Week Block */}
                              <View
                                style={StyleSheet.applyWidth(
                                  { width: '50%' },
                                  dimensions.width
                                )}
                              >
                                {/* Label */}
                                <View>
                                  {/* Lessons / Week */}
                                  <Text
                                    accessible={true}
                                    style={StyleSheet.applyWidth(
                                      {
                                        color:
                                          palettes.App[
                                            'App Text 2ndary 7F868C'
                                          ],
                                        fontFamily: 'Poppins_400Regular',
                                        fontSize: 12,
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    {'Lessons / Week'}
                                  </Text>
                                </View>
                                {/* Lessons */}
                                <View>
                                  {/* No. of Lessons */}
                                  <Text
                                    accessible={true}
                                    style={StyleSheet.applyWidth(
                                      {
                                        color: theme.colors.text.strong,
                                        fontFamily: 'Poppins_600SemiBold',
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    {lessonFrequencyPerWeek}
                                    {' Lessons'}
                                  </Text>
                                </View>
                              </View>
                            </View>
                            {/* View Scheduling Notes */}
                            <>
                              {!specialSchedulingRequest ? null : (
                                <View
                                  {...GlobalStyles.ViewStyles(theme)[
                                    'additional student details block'
                                  ].props}
                                  style={StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                      GlobalStyles.ViewStyles(theme)[
                                        'additional student details block'
                                      ].style,
                                      {
                                        borderBottomWidth: 1,
                                        borderColor:
                                          palettes.App[
                                            'App Grey Inputs E2E2E2'
                                          ],
                                        borderLeftWidth: 0,
                                        borderRadius: 0,
                                        borderRightWidth: 0,
                                        borderTopWidth: 0,
                                        paddingLeft: 10,
                                        paddingRight: 10,
                                      }
                                    ),
                                    dimensions.width
                                  )}
                                >
                                  {/* View Icon Container */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        marginRight: 12,
                                        width: 4,
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    <Icon
                                      color={palettes.App['App Black 191F32']}
                                      name={
                                        'MaterialCommunityIcons/calendar-text-outline'
                                      }
                                      size={18}
                                      style={StyleSheet.applyWidth(
                                        { marginRight: 6 },
                                        dimensions.width
                                      )}
                                    />
                                  </View>

                                  <View
                                    style={StyleSheet.applyWidth(
                                      { width: '90%' },
                                      dimensions.width
                                    )}
                                  >
                                    {/* Title */}
                                    <Text
                                      accessible={true}
                                      {...GlobalStyles.TextStyles(theme)['Text']
                                        .props}
                                      style={StyleSheet.applyWidth(
                                        StyleSheet.compose(
                                          GlobalStyles.TextStyles(theme)['Text']
                                            .style,
                                          {
                                            color:
                                              palettes.App['App Black 191F32'],
                                            fontFamily: 'Poppins_400Regular',
                                            fontSize: 12,
                                          }
                                        ),
                                        dimensions.width
                                      )}
                                    >
                                      {'Client notes about their schedule'}
                                    </Text>
                                    {/* Scheduling notes */}
                                    <Text
                                      accessible={true}
                                      {...GlobalStyles.TextStyles(theme)['Text']
                                        .props}
                                      style={StyleSheet.applyWidth(
                                        StyleSheet.compose(
                                          GlobalStyles.TextStyles(theme)['Text']
                                            .style,
                                          {
                                            color:
                                              palettes.App[
                                                'App Disabled Button Text 7F868C'
                                              ],
                                            fontFamily: 'Poppins_400Regular',
                                            fontSize: 12,
                                            marginTop: 6,
                                          }
                                        ),
                                        dimensions.width
                                      )}
                                    >
                                      {specialSchedulingRequest}
                                    </Text>
                                  </View>
                                </View>
                              )}
                            </>
                          </View>
                          {/* View Days Available Block */}
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                borderBottomWidth: 1,
                                borderColor:
                                  palettes.App['App Grey Inputs E2E2E2'],
                                marginBottom: 16,
                                paddingBottom: 16,
                              },
                              dimensions.width
                            )}
                          >
                            {/* Label */}
                            <View
                              style={StyleSheet.applyWidth(
                                { marginBottom: 8 },
                                dimensions.width
                              )}
                            >
                              {/* Days Available */}
                              <Text
                                accessible={true}
                                style={StyleSheet.applyWidth(
                                  {
                                    color:
                                      palettes.App['App Text 2ndary 7F868C'],
                                    fontFamily: 'Poppins_400Regular',
                                    fontSize: 12,
                                  },
                                  dimensions.width
                                )}
                              >
                                {'Days available'}
                              </Text>
                            </View>
                            {/* View Days Available Container */}
                            <View>
                              <Utils.CustomCodeErrorBoundary>
                                <viewOpportunities.availableDays
                                  availableDaysClient={availableDays}
                                />
                              </Utils.CustomCodeErrorBoundary>
                            </View>
                            {/* Legend */}
                            <View
                              style={StyleSheet.applyWidth(
                                { alignItems: 'center', flexDirection: 'row' },
                                dimensions.width
                              )}
                            >
                              {/* Icon */}
                              <View
                                style={StyleSheet.applyWidth(
                                  { marginRight: 6 },
                                  dimensions.width
                                )}
                              >
                                <Icon
                                  color={palettes.App['App Brand Blue 2C9AD9']}
                                  name={'FontAwesome/circle'}
                                  size={8}
                                />
                              </View>
                              {/* Key */}
                              <View>
                                {/* Client is available */}
                                <Text
                                  accessible={true}
                                  style={StyleSheet.applyWidth(
                                    {
                                      color:
                                        palettes.App['App Text 2ndary 7F868C'],
                                      fontFamily: 'Poppins_400Regular',
                                      fontSize: 12,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {'Client is available'}
                                </Text>
                              </View>
                            </View>
                          </View>
                          {/* View Weekdays Times Available Block */}
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                borderBottomWidth: 1,
                                borderColor:
                                  palettes.App['App Grey Inputs E2E2E2'],
                                marginBottom: 16,
                                paddingBottom: 16,
                              },
                              dimensions.width
                            )}
                          >
                            {/* Label */}
                            <View
                              style={StyleSheet.applyWidth(
                                { marginBottom: 8 },
                                dimensions.width
                              )}
                            >
                              {/* Weekdays Times */}
                              <Text
                                accessible={true}
                                style={StyleSheet.applyWidth(
                                  {
                                    color:
                                      palettes.App['App Text 2ndary 7F868C'],
                                    fontFamily: 'Poppins_400Regular',
                                    fontSize: 12,
                                  },
                                  dimensions.width
                                )}
                              >
                                {'Weekdays Times Available'}
                              </Text>
                            </View>
                            {/* View Days Available Container */}
                            <View>
                              <Utils.CustomCodeErrorBoundary>
                                <viewOpportunities.TimesAvailable
                                  availableTimesClient={availableTimesWeekDays}
                                />
                              </Utils.CustomCodeErrorBoundary>
                            </View>
                          </View>
                          {/* View Weekends Times Available Block */}
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                borderBottomWidth: 1,
                                borderColor:
                                  palettes.App['App Grey Inputs E2E2E2'],
                                marginBottom: 16,
                                paddingBottom: 16,
                              },
                              dimensions.width
                            )}
                          >
                            {/* Label */}
                            <View
                              style={StyleSheet.applyWidth(
                                { marginBottom: 8 },
                                dimensions.width
                              )}
                            >
                              {/* Weekends Times */}
                              <Text
                                accessible={true}
                                style={StyleSheet.applyWidth(
                                  {
                                    color:
                                      palettes.App['App Text 2ndary 7F868C'],
                                    fontFamily: 'Poppins_400Regular',
                                    fontSize: 12,
                                  },
                                  dimensions.width
                                )}
                              >
                                {'Weekends Times Available'}
                              </Text>
                            </View>
                            {/* View Days Available Container */}
                            <View>
                              <Utils.CustomCodeErrorBoundary>
                                <viewOpportunities.TimesAvailable
                                  availableTimesClient={availableTimesWeekEnds}
                                />
                              </Utils.CustomCodeErrorBoundary>
                            </View>
                          </View>
                        </View>
                        {/* Spacer When Alternate Schedule Available */}
                        <Spacer left={8} right={8} bottom={20} top={20} />
                      </View>
                    )}
                  </>
                </View>
              </View>
            )}
          </>
        </ScrollView>
      </View>
      {/* View Bottom Anchor */}
      <View
        style={StyleSheet.applyWidth(
          {
            backgroundColor: palettes.App['Custom Color'],
            borderColor: palettes.App['App Disabled Button Text 7F868C'],
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            borderTopWidth: 2,
            bottom: 0,
            paddingLeft: '5.35%',
            paddingRight: '5.35%',
            position: 'absolute',
            width: '100%',
          },
          dimensions.width
        )}
      >
        {/* Package Status Available */}
        <>
          {!(packageStatus === 'Available') ? null : (
            <View>
              {/* View Show Higher Pay Bid */}
              <>
                {!instructorBid ? null : (
                  <View>
                    {/* View Title */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          flexDirection: 'row',
                          paddingBottom: 12,
                          paddingTop: 12,
                        },
                        dimensions.width
                      )}
                    >
                      {/* Requesting this package for */}
                      <Text
                        accessible={true}
                        style={StyleSheet.applyWidth(
                          {
                            color: palettes.App['App Black 191F32'],
                            fontFamily: 'Poppins_500Medium',
                            fontSize: 16,
                            marginRight: 2,
                          },
                          dimensions.width
                        )}
                      >
                        {'Requesting this package for '}
                      </Text>
                      {/* Edit bid */}
                      <IconButton
                        onPress={() => {
                          try {
                            setGlobalVariableValue({
                              key: 'showModalDeclineSurvey',
                              value: true,
                            });
                            setRadioButtonGroupValue('pay');
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        color={palettes.App['App Gold #FFC400']}
                        icon={'MaterialCommunityIcons/pencil-outline'}
                        size={18}
                        style={StyleSheet.applyWidth(
                          { marginLeft: 4 },
                          dimensions.width
                        )}
                      />
                      {/* Instructor Bid */}
                      <Text
                        accessible={true}
                        style={StyleSheet.applyWidth(
                          {
                            color: palettes.App['App Gold FFA900'],
                            fontFamily: 'Poppins_500Medium',
                            fontSize: 16,
                          },
                          dimensions.width
                        )}
                      >
                        {'$'}
                        {instructorBid}
                        {'.00'}
                      </Text>
                    </View>
                  </View>
                )}
              </>
              {/* Client Not Open To Alternate Schedules */}
              <>
                {!(flexibleSchedule !== 'YES') ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor: '"rgba(0, 0, 0, 0)"',
                        borderBottomWidth: 1,
                        borderColor: palettes.App['App Black 191F32'],
                        borderLeftWidth: 1,
                        borderRadius: 40,
                        borderRightWidth: 1,
                        borderTopWidth: 1,
                        flexDirection: 'row',
                        justifyContent: 'center',
                        marginTop: 20,
                        paddingBottom: '1.75%',
                        paddingLeft: '1.75%',
                        paddingRight: '1.75%',
                        paddingTop: '1.75%',
                      },
                      dimensions.width
                    )}
                  >
                    <Text
                      accessible={true}
                      style={StyleSheet.applyWidth(
                        {
                          alignSelf: 'center',
                          color: palettes.App['App Black 191F32'],
                          fontFamily: 'Poppins_400Regular',
                          fontSize: 12,
                        },
                        dimensions.width
                      )}
                    >
                      {'Client accepts their requested schedule only'}
                    </Text>
                  </View>
                )}
              </>
              {/* Surface Agree to Client Sched */}
              <Surface
                {...GlobalStyles.SurfaceStyles(theme)['Surface'].props}
                elevation={3}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.SurfaceStyles(theme)['Surface'].style,
                    { borderRadius: 8, marginTop: 20 }
                  ),
                  dimensions.width
                )}
              >
                {/* Agree to Client Schedule Container */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      backgroundColor: palettes.App['App Blue/Low Emphasis'],
                      borderRadius: 8,
                      flexDirection: 'row',
                      paddingBottom: '1.75%',
                      paddingLeft: '1.75%',
                      paddingRight: '1.75%',
                      paddingTop: '1.75%',
                    },
                    dimensions.width
                  )}
                >
                  {/* View Checkbox Container */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginRight: 8,
                        width: '8%',
                      },
                      dimensions.width
                    )}
                  >
                    <Checkbox
                      onPress={newCheckboxValue => {
                        try {
                          setCheckboxValue(newCheckboxValue);
                          setAgreedToClientSchedule(newCheckboxValue);
                          setAlternateScheduleRequested(false);
                          setCheckboxValue2(false);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      checkedIcon={'FontAwesome/check-circle'}
                      color={palettes.App['App Blue#4 007fd4']}
                      size={18}
                      status={checkboxValue}
                      style={StyleSheet.applyWidth(
                        { height: 8, width: 8 },
                        dimensions.width
                      )}
                      uncheckedColor={palettes.App['App Blue#4 007fd4']}
                      uncheckedIcon={'Entypo/circle'}
                    />
                  </View>
                  {/* View Agreement Text Container */}
                  <View
                    style={StyleSheet.applyWidth(
                      { width: '90%' },
                      dimensions.width
                    )}
                  >
                    <Touchable
                      onPress={() => {
                        try {
                          Haptic('light');
                          setCheckboxValue(!checkboxValue);
                          setAgreedToClientSchedule(!agreedToClientSchedule);
                          setCheckboxValue2(false);
                          setAlternateScheduleRequested(false);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                    >
                      {/* View Preferred Tag */}
                      <>
                        {!falseValue ? null : (
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: 'flex-start',
                                alignSelf: 'flex-start',
                                backgroundColor:
                                  palettes.App['App Blue#4 007fd4'],
                                borderRadius: 40,
                                marginBottom: 4,
                                paddingBottom: 2,
                                paddingLeft: 8,
                                paddingRight: 8,
                                paddingTop: 2,
                              },
                              dimensions.width
                            )}
                          >
                            <Text
                              accessible={true}
                              style={StyleSheet.applyWidth(
                                {
                                  color: palettes.App['Custom Color'],
                                  fontFamily: 'Poppins_400Regular',
                                  fontSize: 10,
                                },
                                dimensions.width
                              )}
                            >
                              {'preferred'}
                            </Text>
                          </View>
                        )}
                      </>
                      <Text
                        accessible={true}
                        style={StyleSheet.applyWidth(
                          {
                            color: palettes.App['App Blue#4 007fd4'],
                            fontFamily: 'Poppins_400Regular',
                            fontSize: 12,
                          },
                          dimensions.width
                        )}
                      >
                        {'I can start and teach '}
                        {lessonFrequencyPerWeek}
                        {
                          " lessons per week within the client's listed availability."
                        }
                      </Text>
                    </Touchable>
                  </View>
                </View>
              </Surface>
              {/* Surface Share Alt Sched */}
              <>
                {!(flexibleSchedule === 'YES') ? null : (
                  <Surface
                    {...GlobalStyles.SurfaceStyles(theme)['Surface'].props}
                    elevation={3}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.SurfaceStyles(theme)['Surface'].style,
                        { borderRadius: 8, marginTop: 20 }
                      ),
                      dimensions.width
                    )}
                  >
                    {/* Share Alternate Schedule */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          backgroundColor: palettes.App['Custom Color_6'],
                          borderRadius: 8,
                          flexDirection: 'row',
                          marginTop: 0,
                          paddingBottom: '1.75%',
                          paddingLeft: '1.75%',
                          paddingRight: '1.75%',
                          paddingTop: '1.75%',
                        },
                        dimensions.width
                      )}
                    >
                      {/* View Checkbox Container */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginRight: 8,
                            width: '8%',
                          },
                          dimensions.width
                        )}
                      >
                        {/* CheckboxAltSchedule */}
                        <Checkbox
                          onPress={newCheckboxAltScheduleValue => {
                            try {
                              setCheckboxValue2(newCheckboxAltScheduleValue);
                              setAlternateScheduleRequested(
                                newCheckboxAltScheduleValue
                              );
                              setCheckboxValue(false);
                              setAgreedToClientSchedule(false);
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          checkedIcon={'FontAwesome/check-circle'}
                          color={palettes.App['App Sun Orange F4A100']}
                          size={18}
                          status={checkboxValue2}
                          uncheckedColor={palettes.App['App Sun Orange F4A100']}
                          uncheckedIcon={'Entypo/circle'}
                        />
                      </View>
                      {/* View Agreement Text Container */}
                      <View
                        style={StyleSheet.applyWidth(
                          { width: '90%' },
                          dimensions.width
                        )}
                      >
                        <Touchable
                          onPress={() => {
                            try {
                              setCheckboxValue2(!checkboxValue2);
                              setAlternateScheduleRequested(
                                !alternateScheduleRequested
                              );
                              setCheckboxValue(false);
                              setAgreedToClientSchedule(false);
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                        >
                          <Text
                            accessible={true}
                            style={StyleSheet.applyWidth(
                              {
                                color: palettes.App['App Sun Orange F4A100'],
                                fontFamily: 'Poppins_400Regular',
                                fontSize: 12,
                              },
                              dimensions.width
                            )}
                          >
                            {
                              'I can not teach within the clients availability. Share my alternate availability with the client.'
                            }
                          </Text>
                        </Touchable>
                      </View>
                    </View>
                  </Surface>
                )}
              </>
              {/* Fill Alternate Schedule */}
              <>
                {!alternateScheduleRequested ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor: palettes.App['Custom Color_6'],
                        borderBottomLeftRadius: 8,
                        borderBottomRightRadius: 8,
                        borderTopLeftRadius: 8,
                        borderTopRightRadius: 8,
                        flexDirection: 'column',
                        marginTop: 15,
                        paddingBottom: '1.75%',
                        paddingLeft: '1.75%',
                        paddingRight: '1.75%',
                        paddingTop: 1.75,
                      },
                      dimensions.width
                    )}
                  >
                    {/* Expand/Contract Alternate Sched */}
                    <Touchable
                      onPress={() => {
                        try {
                          setAlternateScheduleExpanded(
                            !alternateScheduleExpanded
                          );
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                    >
                      {/* View Title */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            flexDirection: 'row',
                            paddingBottom: 10,
                            paddingTop: 10,
                          },
                          dimensions.width
                        )}
                      >
                        {/* Icon container */}
                        <View
                          style={StyleSheet.applyWidth(
                            { marginRight: 8, width: '8%' },
                            dimensions.width
                          )}
                        >
                          {/* Open Icon */}
                          <>
                            {!alternateScheduleExpanded ? null : (
                              <Icon
                                size={24}
                                color={palettes.App['App Sun Orange F4A100']}
                                name={'Entypo/chevron-down'}
                              />
                            )}
                          </>
                          {/* Closed Icon */}
                          <>
                            {alternateScheduleExpanded ? null : (
                              <Icon
                                size={24}
                                color={palettes.App['App Sun Orange F4A100']}
                                name={'Entypo/chevron-right'}
                              />
                            )}
                          </>
                        </View>

                        <Text
                          accessible={true}
                          style={StyleSheet.applyWidth(
                            {
                              color: palettes.App['App Sun Orange F4A100'],
                              fontFamily: 'Poppins_500Medium',
                              fontSize: 14,
                            },
                            dimensions.width
                          )}
                        >
                          {'My alternate availability'}
                        </Text>
                      </View>
                    </Touchable>
                    {/* View Share Alternate Schedule */}
                    <>
                      {!alternateScheduleExpanded ? null : (
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignContent: 'center',
                              alignItems: 'flex-start',
                              alignSelf: 'center',
                              backgroundColor: palettes.App['Custom Color'],
                              borderBottomWidth: 1,
                              borderColor:
                                palettes.App['App Sun Orange F4A100'],
                              borderLeftWidth: 1,
                              borderRadius: 8,
                              borderRightWidth: 1,
                              borderTopWidth: 1,
                              paddingBottom: 6,
                              paddingLeft: 9,
                              paddingRight: 9,
                              paddingTop: 6,
                              width: '98%',
                            },
                            dimensions.width
                          )}
                        >
                          {/* Monday Availability */}
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                paddingBottom: 6,
                                paddingTop: 6,
                                width: '100%',
                              },
                              dimensions.width
                            )}
                          >
                            {/* Monday */}
                            <SwitchRow
                              onValueChange={newMondayValue => {
                                try {
                                  setSwitchRowValue(newMondayValue);
                                } catch (err) {
                                  console.error(err);
                                }
                              }}
                              {...GlobalStyles.SwitchRowStyles(theme)[
                                'availability'
                              ].props}
                              activeTrackColor={
                                palettes.App['App Blue#4 007fd4']
                              }
                              direction={'row'}
                              label={'Monday'}
                              style={StyleSheet.applyWidth(
                                GlobalStyles.SwitchRowStyles(theme)[
                                  'availability'
                                ].style,
                                dimensions.width
                              )}
                              value={switchRowValue}
                            />
                            {/* View Monday Time Options1 */}
                            <>
                              {!switchRowValue ? null : (
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      flexDirection: 'row',
                                      justifyContent: 'space-between',
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {/* View Start Time */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      { width: '40%' },
                                      dimensions.width
                                    )}
                                  >
                                    {/* Picker Monday1Start */}
                                    <Picker
                                      autoDismissKeyboard={true}
                                      dropDownBackgroundColor={
                                        theme.colors.background.brand
                                      }
                                      dropDownBorderColor={
                                        theme.colors.border.brand
                                      }
                                      dropDownBorderRadius={8}
                                      dropDownBorderWidth={1}
                                      dropDownTextColor={
                                        theme.colors.text.strong
                                      }
                                      leftIconMode={'inset'}
                                      mode={'native'}
                                      onValueChange={newPickerMonday1StartValue => {
                                        try {
                                          setPickerValue(
                                            newPickerMonday1StartValue
                                          );
                                        } catch (err) {
                                          console.error(err);
                                        }
                                      }}
                                      selectedIconColor={
                                        theme.colors.text.strong
                                      }
                                      selectedIconName={'Feather/check'}
                                      selectedIconSize={20}
                                      {...GlobalStyles.PickerStyles(theme)[
                                        'pickerTime'
                                      ].props}
                                      iconSize={16}
                                      options={
                                        Constants['gAlternateScheduleTimes']
                                      }
                                      placeholder={'Available at'}
                                      placeholderTextColor={
                                        palettes.App['App Text 2ndary 7F868C']
                                      }
                                      rightIconName={
                                        'Entypo/chevron-with-circle-down'
                                      }
                                      style={StyleSheet.applyWidth(
                                        GlobalStyles.PickerStyles(theme)[
                                          'pickerTime'
                                        ].style,
                                        dimensions.width
                                      )}
                                      type={'underline'}
                                      value={pickerValue}
                                    />
                                  </View>
                                  {/* View End Time */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      { width: '40%' },
                                      dimensions.width
                                    )}
                                  >
                                    {/* Picker Monday1End */}
                                    <>
                                      {!pickerValue ? null : (
                                        <Picker
                                          autoDismissKeyboard={true}
                                          dropDownBackgroundColor={
                                            theme.colors.background.brand
                                          }
                                          dropDownBorderColor={
                                            theme.colors.border.brand
                                          }
                                          dropDownBorderRadius={8}
                                          dropDownBorderWidth={1}
                                          dropDownTextColor={
                                            theme.colors.text.strong
                                          }
                                          leftIconMode={'inset'}
                                          mode={'native'}
                                          onValueChange={newPickerMonday1EndValue => {
                                            try {
                                              setPickerValue2(
                                                newPickerMonday1EndValue
                                              );
                                            } catch (err) {
                                              console.error(err);
                                            }
                                          }}
                                          selectedIconColor={
                                            theme.colors.text.strong
                                          }
                                          selectedIconName={'Feather/check'}
                                          selectedIconSize={20}
                                          {...GlobalStyles.PickerStyles(theme)[
                                            'pickerTime'
                                          ].props}
                                          iconSize={16}
                                          options={
                                            Constants['gAlternateScheduleTimes']
                                          }
                                          placeholder={'Until time'}
                                          placeholderTextColor={
                                            palettes.App[
                                              'App Text 2ndary 7F868C'
                                            ]
                                          }
                                          rightIconName={
                                            'Entypo/chevron-with-circle-down'
                                          }
                                          style={StyleSheet.applyWidth(
                                            GlobalStyles.PickerStyles(theme)[
                                              'pickerTime'
                                            ].style,
                                            dimensions.width
                                          )}
                                          type={'underline'}
                                          value={pickerValue2}
                                        />
                                      )}
                                    </>
                                  </View>
                                  {/* View Add Monday Time Row */}
                                  <View
                                    {...GlobalStyles.ViewStyles(theme)[
                                      'buttonIncremental'
                                    ].props}
                                    style={StyleSheet.applyWidth(
                                      GlobalStyles.ViewStyles(theme)[
                                        'buttonIncremental'
                                      ].style,
                                      dimensions.width
                                    )}
                                  >
                                    <>
                                      {!pickerValue2 ? null : (
                                        <IconButton
                                          onPress={() => {
                                            try {
                                              if (timeslotMonday >= 3) {
                                                return;
                                              }
                                              setTimeslotMonday(
                                                timeslotMonday + 1
                                              );
                                            } catch (err) {
                                              console.error(err);
                                            }
                                          }}
                                          color={
                                            palettes.App['App Blue#4 007fd4']
                                          }
                                          icon={'FontAwesome/plus-circle'}
                                          size={26}
                                        />
                                      )}
                                    </>
                                  </View>
                                </View>
                              )}
                            </>
                            {/* View Monday Time Options2 */}
                            <>
                              {!(timeslotMonday > 1) ? null : (
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      flexDirection: 'row',
                                      justifyContent: 'space-between',
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {/* View Start Time */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      { paddingTop: 8, width: '40%' },
                                      dimensions.width
                                    )}
                                  >
                                    {/* Picker Monday2Start */}
                                    <Picker
                                      autoDismissKeyboard={true}
                                      dropDownBackgroundColor={
                                        theme.colors.background.brand
                                      }
                                      dropDownBorderColor={
                                        theme.colors.border.brand
                                      }
                                      dropDownBorderRadius={8}
                                      dropDownBorderWidth={1}
                                      dropDownTextColor={
                                        theme.colors.text.strong
                                      }
                                      leftIconMode={'inset'}
                                      mode={'native'}
                                      onValueChange={newPickerMonday2StartValue => {
                                        try {
                                          setPickerValue3(
                                            newPickerMonday2StartValue
                                          );
                                        } catch (err) {
                                          console.error(err);
                                        }
                                      }}
                                      selectedIconColor={
                                        theme.colors.text.strong
                                      }
                                      selectedIconName={'Feather/check'}
                                      selectedIconSize={20}
                                      {...GlobalStyles.PickerStyles(theme)[
                                        'pickerTime'
                                      ].props}
                                      iconSize={16}
                                      options={
                                        Constants['gAlternateScheduleTimes']
                                      }
                                      placeholder={'Start time'}
                                      placeholderTextColor={
                                        palettes.App['App Text 2ndary 7F868C']
                                      }
                                      rightIconName={
                                        'Entypo/chevron-with-circle-down'
                                      }
                                      style={StyleSheet.applyWidth(
                                        GlobalStyles.PickerStyles(theme)[
                                          'pickerTime'
                                        ].style,
                                        dimensions.width
                                      )}
                                      type={'underline'}
                                      value={pickerValue3}
                                    />
                                  </View>
                                  {/* View End Time */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      { paddingTop: 8, width: '40%' },
                                      dimensions.width
                                    )}
                                  >
                                    {/* Picker Monday2End */}
                                    <>
                                      {!pickerValue3 ? null : (
                                        <Picker
                                          autoDismissKeyboard={true}
                                          dropDownBackgroundColor={
                                            theme.colors.background.brand
                                          }
                                          dropDownBorderColor={
                                            theme.colors.border.brand
                                          }
                                          dropDownBorderRadius={8}
                                          dropDownBorderWidth={1}
                                          dropDownTextColor={
                                            theme.colors.text.strong
                                          }
                                          leftIconMode={'inset'}
                                          mode={'native'}
                                          onValueChange={newPickerMonday2EndValue => {
                                            try {
                                              setPickerValue4(
                                                newPickerMonday2EndValue
                                              );
                                            } catch (err) {
                                              console.error(err);
                                            }
                                          }}
                                          selectedIconColor={
                                            theme.colors.text.strong
                                          }
                                          selectedIconName={'Feather/check'}
                                          selectedIconSize={20}
                                          {...GlobalStyles.PickerStyles(theme)[
                                            'pickerTime'
                                          ].props}
                                          iconSize={16}
                                          options={
                                            Constants['gAlternateScheduleTimes']
                                          }
                                          placeholder={'Until time'}
                                          placeholderTextColor={
                                            palettes.App[
                                              'App Text 2ndary 7F868C'
                                            ]
                                          }
                                          rightIconName={
                                            'Entypo/chevron-with-circle-down'
                                          }
                                          style={StyleSheet.applyWidth(
                                            GlobalStyles.PickerStyles(theme)[
                                              'pickerTime'
                                            ].style,
                                            dimensions.width
                                          )}
                                          type={'underline'}
                                          value={pickerValue4}
                                        />
                                      )}
                                    </>
                                  </View>
                                  {/* View Minus Monday Time Row */}
                                  <View
                                    {...GlobalStyles.ViewStyles(theme)[
                                      'buttonIncremental'
                                    ].props}
                                    style={StyleSheet.applyWidth(
                                      GlobalStyles.ViewStyles(theme)[
                                        'buttonIncremental'
                                      ].style,
                                      dimensions.width
                                    )}
                                  >
                                    <IconButton
                                      onPress={() => {
                                        try {
                                          setTimeslotMonday(timeslotMonday - 1);
                                        } catch (err) {
                                          console.error(err);
                                        }
                                      }}
                                      color={
                                        palettes.App[
                                          'App Disabled Button EDEDEF'
                                        ]
                                      }
                                      icon={'FontAwesome/minus-circle'}
                                      size={26}
                                    />
                                  </View>
                                </View>
                              )}
                            </>
                            {/* View Monday Time Options3 */}
                            <>
                              {!(timeslotMonday > 2) ? null : (
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      flexDirection: 'row',
                                      justifyContent: 'space-between',
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {/* View Start Time */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      { paddingTop: 8, width: '40%' },
                                      dimensions.width
                                    )}
                                  >
                                    {/* Picker Monday3Start */}
                                    <Picker
                                      autoDismissKeyboard={true}
                                      dropDownBackgroundColor={
                                        theme.colors.background.brand
                                      }
                                      dropDownBorderColor={
                                        theme.colors.border.brand
                                      }
                                      dropDownBorderRadius={8}
                                      dropDownBorderWidth={1}
                                      dropDownTextColor={
                                        theme.colors.text.strong
                                      }
                                      leftIconMode={'inset'}
                                      mode={'native'}
                                      onValueChange={newPickerMonday3StartValue => {
                                        try {
                                          setPickerValue5(
                                            newPickerMonday3StartValue
                                          );
                                        } catch (err) {
                                          console.error(err);
                                        }
                                      }}
                                      selectedIconColor={
                                        theme.colors.text.strong
                                      }
                                      selectedIconName={'Feather/check'}
                                      selectedIconSize={20}
                                      {...GlobalStyles.PickerStyles(theme)[
                                        'pickerTime'
                                      ].props}
                                      iconSize={16}
                                      options={
                                        Constants['gAlternateScheduleTimes']
                                      }
                                      placeholder={'Start time'}
                                      placeholderTextColor={
                                        palettes.App['App Text 2ndary 7F868C']
                                      }
                                      rightIconName={
                                        'Entypo/chevron-with-circle-down'
                                      }
                                      style={StyleSheet.applyWidth(
                                        GlobalStyles.PickerStyles(theme)[
                                          'pickerTime'
                                        ].style,
                                        dimensions.width
                                      )}
                                      type={'underline'}
                                      value={pickerValue5}
                                    />
                                  </View>
                                  {/* View End Time */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      { paddingTop: 8, width: '40%' },
                                      dimensions.width
                                    )}
                                  >
                                    {/* Picker Monday3End */}
                                    <>
                                      {!pickerValue5 ? null : (
                                        <Picker
                                          autoDismissKeyboard={true}
                                          dropDownBackgroundColor={
                                            theme.colors.background.brand
                                          }
                                          dropDownBorderColor={
                                            theme.colors.border.brand
                                          }
                                          dropDownBorderRadius={8}
                                          dropDownBorderWidth={1}
                                          dropDownTextColor={
                                            theme.colors.text.strong
                                          }
                                          leftIconMode={'inset'}
                                          mode={'native'}
                                          onValueChange={newPickerMonday3EndValue => {
                                            try {
                                              setPickerValue6(
                                                newPickerMonday3EndValue
                                              );
                                            } catch (err) {
                                              console.error(err);
                                            }
                                          }}
                                          selectedIconColor={
                                            theme.colors.text.strong
                                          }
                                          selectedIconName={'Feather/check'}
                                          selectedIconSize={20}
                                          {...GlobalStyles.PickerStyles(theme)[
                                            'pickerTime'
                                          ].props}
                                          iconSize={16}
                                          options={
                                            Constants['gAlternateScheduleTimes']
                                          }
                                          placeholder={'Until time'}
                                          placeholderTextColor={
                                            palettes.App[
                                              'App Text 2ndary 7F868C'
                                            ]
                                          }
                                          rightIconName={
                                            'Entypo/chevron-with-circle-down'
                                          }
                                          style={StyleSheet.applyWidth(
                                            GlobalStyles.PickerStyles(theme)[
                                              'pickerTime'
                                            ].style,
                                            dimensions.width
                                          )}
                                          type={'underline'}
                                          value={pickerValue6}
                                        />
                                      )}
                                    </>
                                  </View>
                                  {/* View Add Monday Time Row */}
                                  <View
                                    {...GlobalStyles.ViewStyles(theme)[
                                      'buttonIncremental'
                                    ].props}
                                    style={StyleSheet.applyWidth(
                                      GlobalStyles.ViewStyles(theme)[
                                        'buttonIncremental'
                                      ].style,
                                      dimensions.width
                                    )}
                                  >
                                    <IconButton
                                      onPress={() => {
                                        try {
                                          setTimeslotMonday(timeslotMonday - 1);
                                        } catch (err) {
                                          console.error(err);
                                        }
                                      }}
                                      color={
                                        palettes.App[
                                          'App Disabled Button EDEDEF'
                                        ]
                                      }
                                      icon={'FontAwesome/minus-circle'}
                                      size={26}
                                    />
                                  </View>
                                </View>
                              )}
                            </>
                          </View>
                          {/* Tuesday Availability */}
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                paddingBottom: 6,
                                paddingTop: 6,
                                width: '100%',
                              },
                              dimensions.width
                            )}
                          >
                            {/* Tuesday */}
                            <SwitchRow
                              onValueChange={newTuesdayValue => {
                                try {
                                  setSwitchRowValue2(newTuesdayValue);
                                } catch (err) {
                                  console.error(err);
                                }
                              }}
                              {...GlobalStyles.SwitchRowStyles(theme)[
                                'availability'
                              ].props}
                              activeTrackColor={
                                palettes.App['App Blue#4 007fd4']
                              }
                              direction={'row'}
                              label={'Tuesday'}
                              style={StyleSheet.applyWidth(
                                GlobalStyles.SwitchRowStyles(theme)[
                                  'availability'
                                ].style,
                                dimensions.width
                              )}
                              value={switchRowValue2}
                            />
                            {/* View Tuesday Time Options1 */}
                            <>
                              {!switchRowValue2 ? null : (
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      flexDirection: 'row',
                                      justifyContent: 'space-between',
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {/* View Start Time */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      { paddingTop: 8, width: '40%' },
                                      dimensions.width
                                    )}
                                  >
                                    {/* Picker Tuesday1Start */}
                                    <Picker
                                      autoDismissKeyboard={true}
                                      dropDownBackgroundColor={
                                        theme.colors.background.brand
                                      }
                                      dropDownBorderColor={
                                        theme.colors.border.brand
                                      }
                                      dropDownBorderRadius={8}
                                      dropDownBorderWidth={1}
                                      dropDownTextColor={
                                        theme.colors.text.strong
                                      }
                                      leftIconMode={'inset'}
                                      mode={'native'}
                                      onValueChange={newPickerTuesday1StartValue => {
                                        try {
                                          setPickerValue7(
                                            newPickerTuesday1StartValue
                                          );
                                        } catch (err) {
                                          console.error(err);
                                        }
                                      }}
                                      selectedIconColor={
                                        theme.colors.text.strong
                                      }
                                      selectedIconName={'Feather/check'}
                                      selectedIconSize={20}
                                      {...GlobalStyles.PickerStyles(theme)[
                                        'pickerTime'
                                      ].props}
                                      iconSize={16}
                                      options={
                                        Constants['gAlternateScheduleTimes']
                                      }
                                      placeholder={'Available at'}
                                      placeholderTextColor={
                                        palettes.App['App Text 2ndary 7F868C']
                                      }
                                      rightIconName={
                                        'Entypo/chevron-with-circle-down'
                                      }
                                      style={StyleSheet.applyWidth(
                                        GlobalStyles.PickerStyles(theme)[
                                          'pickerTime'
                                        ].style,
                                        dimensions.width
                                      )}
                                      type={'underline'}
                                      value={pickerValue7}
                                    />
                                  </View>
                                  {/* View End Time */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      { paddingTop: 8, width: '40%' },
                                      dimensions.width
                                    )}
                                  >
                                    {/* Picker Tuesday1End */}
                                    <>
                                      {!pickerValue7 ? null : (
                                        <Picker
                                          autoDismissKeyboard={true}
                                          dropDownBackgroundColor={
                                            theme.colors.background.brand
                                          }
                                          dropDownBorderColor={
                                            theme.colors.border.brand
                                          }
                                          dropDownBorderRadius={8}
                                          dropDownBorderWidth={1}
                                          dropDownTextColor={
                                            theme.colors.text.strong
                                          }
                                          leftIconMode={'inset'}
                                          mode={'native'}
                                          onValueChange={newPickerTuesday1EndValue => {
                                            try {
                                              setPickerValue8(
                                                newPickerTuesday1EndValue
                                              );
                                            } catch (err) {
                                              console.error(err);
                                            }
                                          }}
                                          selectedIconColor={
                                            theme.colors.text.strong
                                          }
                                          selectedIconName={'Feather/check'}
                                          selectedIconSize={20}
                                          {...GlobalStyles.PickerStyles(theme)[
                                            'pickerTime'
                                          ].props}
                                          iconSize={16}
                                          options={
                                            Constants['gAlternateScheduleTimes']
                                          }
                                          placeholder={'Until time'}
                                          placeholderTextColor={
                                            palettes.App[
                                              'App Text 2ndary 7F868C'
                                            ]
                                          }
                                          rightIconName={
                                            'Entypo/chevron-with-circle-down'
                                          }
                                          style={StyleSheet.applyWidth(
                                            GlobalStyles.PickerStyles(theme)[
                                              'pickerTime'
                                            ].style,
                                            dimensions.width
                                          )}
                                          type={'underline'}
                                          value={pickerValue8}
                                        />
                                      )}
                                    </>
                                  </View>
                                  {/* View Add Monday Time Row */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        width: '10%',
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    <>
                                      {!pickerValue8 ? null : (
                                        <IconButton
                                          onPress={() => {
                                            try {
                                              if (timeslotsTuesday >= 3) {
                                                return;
                                              }
                                              setTimeslotsTuesday(
                                                timeslotsTuesday + 1
                                              );
                                            } catch (err) {
                                              console.error(err);
                                            }
                                          }}
                                          color={
                                            palettes.App['App Blue#4 007fd4']
                                          }
                                          icon={'FontAwesome/plus-circle'}
                                          size={26}
                                        />
                                      )}
                                    </>
                                  </View>
                                </View>
                              )}
                            </>
                            {/* View Tuesday Time Options2 */}
                            <>
                              {!(timeslotsTuesday > 1) ? null : (
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      flexDirection: 'row',
                                      justifyContent: 'space-between',
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {/* View Start Time */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      { paddingTop: 8, width: '40%' },
                                      dimensions.width
                                    )}
                                  >
                                    {/* Picker Tuesday2Start */}
                                    <Picker
                                      autoDismissKeyboard={true}
                                      dropDownBackgroundColor={
                                        theme.colors.background.brand
                                      }
                                      dropDownBorderColor={
                                        theme.colors.border.brand
                                      }
                                      dropDownBorderRadius={8}
                                      dropDownBorderWidth={1}
                                      dropDownTextColor={
                                        theme.colors.text.strong
                                      }
                                      leftIconMode={'inset'}
                                      mode={'native'}
                                      onValueChange={newPickerTuesday2StartValue => {
                                        try {
                                          setPickerValue9(
                                            newPickerTuesday2StartValue
                                          );
                                        } catch (err) {
                                          console.error(err);
                                        }
                                      }}
                                      selectedIconColor={
                                        theme.colors.text.strong
                                      }
                                      selectedIconName={'Feather/check'}
                                      selectedIconSize={20}
                                      {...GlobalStyles.PickerStyles(theme)[
                                        'pickerTime'
                                      ].props}
                                      iconSize={16}
                                      options={
                                        Constants['gAlternateScheduleTimes']
                                      }
                                      placeholder={'Available at'}
                                      placeholderTextColor={
                                        palettes.App['App Text 2ndary 7F868C']
                                      }
                                      rightIconName={
                                        'Entypo/chevron-with-circle-down'
                                      }
                                      style={StyleSheet.applyWidth(
                                        GlobalStyles.PickerStyles(theme)[
                                          'pickerTime'
                                        ].style,
                                        dimensions.width
                                      )}
                                      type={'underline'}
                                      value={pickerValue9}
                                    />
                                  </View>
                                  {/* View End Time */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      { paddingTop: 8, width: '40%' },
                                      dimensions.width
                                    )}
                                  >
                                    {/* Picker Tuesday2End */}
                                    <>
                                      {!pickerValue9 ? null : (
                                        <Picker
                                          autoDismissKeyboard={true}
                                          dropDownBackgroundColor={
                                            theme.colors.background.brand
                                          }
                                          dropDownBorderColor={
                                            theme.colors.border.brand
                                          }
                                          dropDownBorderRadius={8}
                                          dropDownBorderWidth={1}
                                          dropDownTextColor={
                                            theme.colors.text.strong
                                          }
                                          leftIconMode={'inset'}
                                          mode={'native'}
                                          onValueChange={newPickerTuesday2EndValue => {
                                            try {
                                              setPickerValue10(
                                                newPickerTuesday2EndValue
                                              );
                                            } catch (err) {
                                              console.error(err);
                                            }
                                          }}
                                          selectedIconColor={
                                            theme.colors.text.strong
                                          }
                                          selectedIconName={'Feather/check'}
                                          selectedIconSize={20}
                                          {...GlobalStyles.PickerStyles(theme)[
                                            'pickerTime'
                                          ].props}
                                          iconSize={16}
                                          options={
                                            Constants['gAlternateScheduleTimes']
                                          }
                                          placeholder={'Until time'}
                                          placeholderTextColor={
                                            palettes.App[
                                              'App Text 2ndary 7F868C'
                                            ]
                                          }
                                          rightIconName={
                                            'Entypo/chevron-with-circle-down'
                                          }
                                          style={StyleSheet.applyWidth(
                                            GlobalStyles.PickerStyles(theme)[
                                              'pickerTime'
                                            ].style,
                                            dimensions.width
                                          )}
                                          type={'underline'}
                                          value={pickerValue10}
                                        />
                                      )}
                                    </>
                                  </View>
                                  {/* View Minus Tuesday Time Row */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        width: '10%',
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    <IconButton
                                      onPress={() => {
                                        try {
                                          setTimeslotsTuesday(
                                            timeslotsTuesday - 1
                                          );
                                        } catch (err) {
                                          console.error(err);
                                        }
                                      }}
                                      color={
                                        palettes.App[
                                          'App Disabled Button EDEDEF'
                                        ]
                                      }
                                      icon={'FontAwesome/minus-circle'}
                                      size={26}
                                    />
                                  </View>
                                </View>
                              )}
                            </>
                            {/* View Tuesday Time Options3 */}
                            <>
                              {!(timeslotsTuesday > 2) ? null : (
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      flexDirection: 'row',
                                      justifyContent: 'space-between',
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {/* View Start Time */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      { paddingTop: 8, width: '40%' },
                                      dimensions.width
                                    )}
                                  >
                                    {/* Picker Tuesday3Start */}
                                    <Picker
                                      autoDismissKeyboard={true}
                                      dropDownBackgroundColor={
                                        theme.colors.background.brand
                                      }
                                      dropDownBorderColor={
                                        theme.colors.border.brand
                                      }
                                      dropDownBorderRadius={8}
                                      dropDownBorderWidth={1}
                                      dropDownTextColor={
                                        theme.colors.text.strong
                                      }
                                      leftIconMode={'inset'}
                                      mode={'native'}
                                      onValueChange={newPickerTuesday3StartValue => {
                                        try {
                                          setPickerValue11(
                                            newPickerTuesday3StartValue
                                          );
                                        } catch (err) {
                                          console.error(err);
                                        }
                                      }}
                                      selectedIconColor={
                                        theme.colors.text.strong
                                      }
                                      selectedIconName={'Feather/check'}
                                      selectedIconSize={20}
                                      {...GlobalStyles.PickerStyles(theme)[
                                        'pickerTime'
                                      ].props}
                                      iconSize={16}
                                      options={
                                        Constants['gAlternateScheduleTimes']
                                      }
                                      placeholder={'Available at'}
                                      placeholderTextColor={
                                        palettes.App['App Text 2ndary 7F868C']
                                      }
                                      rightIconName={
                                        'Entypo/chevron-with-circle-down'
                                      }
                                      style={StyleSheet.applyWidth(
                                        GlobalStyles.PickerStyles(theme)[
                                          'pickerTime'
                                        ].style,
                                        dimensions.width
                                      )}
                                      type={'underline'}
                                      value={pickerValue11}
                                    />
                                  </View>
                                  {/* View End Time */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      { paddingTop: 8, width: '40%' },
                                      dimensions.width
                                    )}
                                  >
                                    {/* Picker Tuesday3End */}
                                    <>
                                      {!pickerValue11 ? null : (
                                        <Picker
                                          autoDismissKeyboard={true}
                                          dropDownBackgroundColor={
                                            theme.colors.background.brand
                                          }
                                          dropDownBorderColor={
                                            theme.colors.border.brand
                                          }
                                          dropDownBorderRadius={8}
                                          dropDownBorderWidth={1}
                                          dropDownTextColor={
                                            theme.colors.text.strong
                                          }
                                          leftIconMode={'inset'}
                                          mode={'native'}
                                          onValueChange={newPickerTuesday3EndValue => {
                                            try {
                                              setPickerValue12(
                                                newPickerTuesday3EndValue
                                              );
                                            } catch (err) {
                                              console.error(err);
                                            }
                                          }}
                                          selectedIconColor={
                                            theme.colors.text.strong
                                          }
                                          selectedIconName={'Feather/check'}
                                          selectedIconSize={20}
                                          {...GlobalStyles.PickerStyles(theme)[
                                            'pickerTime'
                                          ].props}
                                          iconSize={16}
                                          options={
                                            Constants['gAlternateScheduleTimes']
                                          }
                                          placeholder={'Until time'}
                                          placeholderTextColor={
                                            palettes.App[
                                              'App Text 2ndary 7F868C'
                                            ]
                                          }
                                          rightIconName={
                                            'Entypo/chevron-with-circle-down'
                                          }
                                          style={StyleSheet.applyWidth(
                                            GlobalStyles.PickerStyles(theme)[
                                              'pickerTime'
                                            ].style,
                                            dimensions.width
                                          )}
                                          type={'underline'}
                                          value={pickerValue12}
                                        />
                                      )}
                                    </>
                                  </View>
                                  {/* View Add Monday Time Row */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        width: '10%',
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    <IconButton
                                      onPress={() => {
                                        try {
                                          undefined;
                                        } catch (err) {
                                          console.error(err);
                                        }
                                      }}
                                      color={
                                        palettes.App[
                                          'App Disabled Button EDEDEF'
                                        ]
                                      }
                                      icon={'FontAwesome/minus-circle'}
                                      size={26}
                                    />
                                  </View>
                                </View>
                              )}
                            </>
                          </View>
                          {/* Wednesday Availability */}
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                paddingBottom: 6,
                                paddingTop: 6,
                                width: '100%',
                              },
                              dimensions.width
                            )}
                          >
                            {/* Wednesday */}
                            <SwitchRow
                              onValueChange={newWednesdayValue => {
                                try {
                                  setSwitchRowValue3(newWednesdayValue);
                                } catch (err) {
                                  console.error(err);
                                }
                              }}
                              {...GlobalStyles.SwitchRowStyles(theme)[
                                'availability'
                              ].props}
                              activeTrackColor={
                                palettes.App['App Blue#4 007fd4']
                              }
                              direction={'row'}
                              label={'Wednesday'}
                              style={StyleSheet.applyWidth(
                                GlobalStyles.SwitchRowStyles(theme)[
                                  'availability'
                                ].style,
                                dimensions.width
                              )}
                              value={switchRowValue3}
                            />
                            {/* View Wednesday Time Options1 */}
                            <>
                              {!switchRowValue3 ? null : (
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      flexDirection: 'row',
                                      justifyContent: 'space-between',
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {/* View Start Time */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      { paddingTop: 8, width: '40%' },
                                      dimensions.width
                                    )}
                                  >
                                    {/* Picker Wed1Start */}
                                    <Picker
                                      autoDismissKeyboard={true}
                                      dropDownBackgroundColor={
                                        theme.colors.background.brand
                                      }
                                      dropDownBorderColor={
                                        theme.colors.border.brand
                                      }
                                      dropDownBorderRadius={8}
                                      dropDownBorderWidth={1}
                                      dropDownTextColor={
                                        theme.colors.text.strong
                                      }
                                      leftIconMode={'inset'}
                                      mode={'native'}
                                      onValueChange={newPickerWed1StartValue => {
                                        try {
                                          setPickerValue13(
                                            newPickerWed1StartValue
                                          );
                                        } catch (err) {
                                          console.error(err);
                                        }
                                      }}
                                      selectedIconColor={
                                        theme.colors.text.strong
                                      }
                                      selectedIconName={'Feather/check'}
                                      selectedIconSize={20}
                                      {...GlobalStyles.PickerStyles(theme)[
                                        'pickerTime'
                                      ].props}
                                      iconSize={16}
                                      options={
                                        Constants['gAlternateScheduleTimes']
                                      }
                                      placeholder={'Available at'}
                                      placeholderTextColor={
                                        palettes.App['App Text 2ndary 7F868C']
                                      }
                                      rightIconName={
                                        'Entypo/chevron-with-circle-down'
                                      }
                                      style={StyleSheet.applyWidth(
                                        GlobalStyles.PickerStyles(theme)[
                                          'pickerTime'
                                        ].style,
                                        dimensions.width
                                      )}
                                      type={'underline'}
                                      value={pickerValue13}
                                    />
                                  </View>
                                  {/* View End Time */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      { paddingTop: 8, width: '40%' },
                                      dimensions.width
                                    )}
                                  >
                                    {/* Picker Wed1End */}
                                    <>
                                      {!pickerValue13 ? null : (
                                        <Picker
                                          autoDismissKeyboard={true}
                                          dropDownBackgroundColor={
                                            theme.colors.background.brand
                                          }
                                          dropDownBorderColor={
                                            theme.colors.border.brand
                                          }
                                          dropDownBorderRadius={8}
                                          dropDownBorderWidth={1}
                                          dropDownTextColor={
                                            theme.colors.text.strong
                                          }
                                          leftIconMode={'inset'}
                                          mode={'native'}
                                          onValueChange={newPickerWed1EndValue => {
                                            try {
                                              setPickerValue14(
                                                newPickerWed1EndValue
                                              );
                                            } catch (err) {
                                              console.error(err);
                                            }
                                          }}
                                          selectedIconColor={
                                            theme.colors.text.strong
                                          }
                                          selectedIconName={'Feather/check'}
                                          selectedIconSize={20}
                                          {...GlobalStyles.PickerStyles(theme)[
                                            'pickerTime'
                                          ].props}
                                          iconSize={16}
                                          options={
                                            Constants['gAlternateScheduleTimes']
                                          }
                                          placeholder={'Until time'}
                                          placeholderTextColor={
                                            palettes.App[
                                              'App Text 2ndary 7F868C'
                                            ]
                                          }
                                          rightIconName={
                                            'Entypo/chevron-with-circle-down'
                                          }
                                          style={StyleSheet.applyWidth(
                                            GlobalStyles.PickerStyles(theme)[
                                              'pickerTime'
                                            ].style,
                                            dimensions.width
                                          )}
                                          type={'underline'}
                                          value={pickerValue14}
                                        />
                                      )}
                                    </>
                                  </View>
                                  {/* View Add Monday Time Row */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        width: '10%',
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    <>
                                      {!pickerValue14 ? null : (
                                        <IconButton
                                          onPress={() => {
                                            try {
                                              if (timeslotsWednesday >= 3) {
                                                return;
                                              }
                                              setTimeslotsWednesday(
                                                timeslotsWednesday + 1
                                              );
                                            } catch (err) {
                                              console.error(err);
                                            }
                                          }}
                                          color={
                                            palettes.App['App Blue#4 007fd4']
                                          }
                                          icon={'FontAwesome/plus-circle'}
                                          size={26}
                                        />
                                      )}
                                    </>
                                  </View>
                                </View>
                              )}
                            </>
                            {/* View Wednesday Time Options2 */}
                            <>
                              {!(timeslotsWednesday > 1) ? null : (
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      flexDirection: 'row',
                                      justifyContent: 'space-between',
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {/* View Start Time */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      { paddingTop: 8, width: '40%' },
                                      dimensions.width
                                    )}
                                  >
                                    {/* Picker Wed2Start */}
                                    <Picker
                                      autoDismissKeyboard={true}
                                      dropDownBackgroundColor={
                                        theme.colors.background.brand
                                      }
                                      dropDownBorderColor={
                                        theme.colors.border.brand
                                      }
                                      dropDownBorderRadius={8}
                                      dropDownBorderWidth={1}
                                      dropDownTextColor={
                                        theme.colors.text.strong
                                      }
                                      leftIconMode={'inset'}
                                      mode={'native'}
                                      onValueChange={newPickerWed2StartValue => {
                                        try {
                                          setPickerValue15(
                                            newPickerWed2StartValue
                                          );
                                        } catch (err) {
                                          console.error(err);
                                        }
                                      }}
                                      selectedIconColor={
                                        theme.colors.text.strong
                                      }
                                      selectedIconName={'Feather/check'}
                                      selectedIconSize={20}
                                      {...GlobalStyles.PickerStyles(theme)[
                                        'pickerTime'
                                      ].props}
                                      iconSize={16}
                                      options={
                                        Constants['gAlternateScheduleTimes']
                                      }
                                      placeholder={'Available at'}
                                      placeholderTextColor={
                                        palettes.App['App Text 2ndary 7F868C']
                                      }
                                      rightIconName={
                                        'Entypo/chevron-with-circle-down'
                                      }
                                      style={StyleSheet.applyWidth(
                                        GlobalStyles.PickerStyles(theme)[
                                          'pickerTime'
                                        ].style,
                                        dimensions.width
                                      )}
                                      type={'underline'}
                                      value={pickerValue15}
                                    />
                                  </View>
                                  {/* View End Time */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      { paddingTop: 8, width: '40%' },
                                      dimensions.width
                                    )}
                                  >
                                    {/* Picker Wed2End */}
                                    <>
                                      {!pickerValue15 ? null : (
                                        <Picker
                                          autoDismissKeyboard={true}
                                          dropDownBackgroundColor={
                                            theme.colors.background.brand
                                          }
                                          dropDownBorderColor={
                                            theme.colors.border.brand
                                          }
                                          dropDownBorderRadius={8}
                                          dropDownBorderWidth={1}
                                          dropDownTextColor={
                                            theme.colors.text.strong
                                          }
                                          leftIconMode={'inset'}
                                          mode={'native'}
                                          onValueChange={newPickerWed2EndValue => {
                                            try {
                                              setPickerValue16(
                                                newPickerWed2EndValue
                                              );
                                            } catch (err) {
                                              console.error(err);
                                            }
                                          }}
                                          selectedIconColor={
                                            theme.colors.text.strong
                                          }
                                          selectedIconName={'Feather/check'}
                                          selectedIconSize={20}
                                          {...GlobalStyles.PickerStyles(theme)[
                                            'pickerTime'
                                          ].props}
                                          iconSize={16}
                                          options={
                                            Constants['gAlternateScheduleTimes']
                                          }
                                          placeholder={'Until time'}
                                          placeholderTextColor={
                                            palettes.App[
                                              'App Text 2ndary 7F868C'
                                            ]
                                          }
                                          rightIconName={
                                            'Entypo/chevron-with-circle-down'
                                          }
                                          style={StyleSheet.applyWidth(
                                            GlobalStyles.PickerStyles(theme)[
                                              'pickerTime'
                                            ].style,
                                            dimensions.width
                                          )}
                                          type={'underline'}
                                          value={pickerValue16}
                                        />
                                      )}
                                    </>
                                  </View>
                                  {/* View Add Monday Time Row */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        width: '10%',
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    <IconButton
                                      onPress={() => {
                                        try {
                                          changeTimeslotsCount(
                                            'wed',
                                            timeslotsWednesday,
                                            'decrement'
                                          );
                                        } catch (err) {
                                          console.error(err);
                                        }
                                      }}
                                      color={
                                        palettes.App[
                                          'App Disabled Button EDEDEF'
                                        ]
                                      }
                                      icon={'FontAwesome/minus-circle'}
                                      size={26}
                                    />
                                  </View>
                                </View>
                              )}
                            </>
                            {/* View Wednesday Time Options3 */}
                            <>
                              {!(timeslotsWednesday > 2) ? null : (
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      flexDirection: 'row',
                                      justifyContent: 'space-between',
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {/* View Start Time */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      { paddingTop: 8, width: '40%' },
                                      dimensions.width
                                    )}
                                  >
                                    {/* Picker Wed3Start */}
                                    <Picker
                                      autoDismissKeyboard={true}
                                      dropDownBackgroundColor={
                                        theme.colors.background.brand
                                      }
                                      dropDownBorderColor={
                                        theme.colors.border.brand
                                      }
                                      dropDownBorderRadius={8}
                                      dropDownBorderWidth={1}
                                      dropDownTextColor={
                                        theme.colors.text.strong
                                      }
                                      leftIconMode={'inset'}
                                      mode={'native'}
                                      onValueChange={newPickerWed3StartValue => {
                                        try {
                                          setPickerValue17(
                                            newPickerWed3StartValue
                                          );
                                        } catch (err) {
                                          console.error(err);
                                        }
                                      }}
                                      selectedIconColor={
                                        theme.colors.text.strong
                                      }
                                      selectedIconName={'Feather/check'}
                                      selectedIconSize={20}
                                      {...GlobalStyles.PickerStyles(theme)[
                                        'pickerTime'
                                      ].props}
                                      iconSize={16}
                                      options={
                                        Constants['gAlternateScheduleTimes']
                                      }
                                      placeholder={'Available at'}
                                      placeholderTextColor={
                                        palettes.App['App Text 2ndary 7F868C']
                                      }
                                      rightIconName={
                                        'Entypo/chevron-with-circle-down'
                                      }
                                      style={StyleSheet.applyWidth(
                                        GlobalStyles.PickerStyles(theme)[
                                          'pickerTime'
                                        ].style,
                                        dimensions.width
                                      )}
                                      type={'underline'}
                                      value={pickerValue17}
                                    />
                                  </View>
                                  {/* View End Time */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      { paddingTop: 8, width: '40%' },
                                      dimensions.width
                                    )}
                                  >
                                    {/* Picker Wed3End */}
                                    <>
                                      {!pickerValue17 ? null : (
                                        <Picker
                                          autoDismissKeyboard={true}
                                          dropDownBackgroundColor={
                                            theme.colors.background.brand
                                          }
                                          dropDownBorderColor={
                                            theme.colors.border.brand
                                          }
                                          dropDownBorderRadius={8}
                                          dropDownBorderWidth={1}
                                          dropDownTextColor={
                                            theme.colors.text.strong
                                          }
                                          leftIconMode={'inset'}
                                          mode={'native'}
                                          onValueChange={newPickerWed3EndValue => {
                                            try {
                                              setPickerValue18(
                                                newPickerWed3EndValue
                                              );
                                            } catch (err) {
                                              console.error(err);
                                            }
                                          }}
                                          selectedIconColor={
                                            theme.colors.text.strong
                                          }
                                          selectedIconName={'Feather/check'}
                                          selectedIconSize={20}
                                          {...GlobalStyles.PickerStyles(theme)[
                                            'pickerTime'
                                          ].props}
                                          iconSize={16}
                                          options={
                                            Constants['gAlternateScheduleTimes']
                                          }
                                          placeholder={'Until time'}
                                          placeholderTextColor={
                                            palettes.App[
                                              'App Text 2ndary 7F868C'
                                            ]
                                          }
                                          rightIconName={
                                            'Entypo/chevron-with-circle-down'
                                          }
                                          style={StyleSheet.applyWidth(
                                            GlobalStyles.PickerStyles(theme)[
                                              'pickerTime'
                                            ].style,
                                            dimensions.width
                                          )}
                                          type={'underline'}
                                          value={pickerValue18}
                                        />
                                      )}
                                    </>
                                  </View>
                                  {/* View Add Monday Time Row */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        width: '10%',
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    <IconButton
                                      onPress={() => {
                                        try {
                                          changeTimeslotsCount(
                                            'wed',
                                            undefined,
                                            'decrement'
                                          );
                                        } catch (err) {
                                          console.error(err);
                                        }
                                      }}
                                      color={
                                        palettes.App[
                                          'App Disabled Button EDEDEF'
                                        ]
                                      }
                                      icon={'FontAwesome/minus-circle'}
                                      size={26}
                                    />
                                  </View>
                                </View>
                              )}
                            </>
                          </View>
                          {/* Thursday Availability */}
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                paddingBottom: 6,
                                paddingTop: 6,
                                width: '100%',
                              },
                              dimensions.width
                            )}
                          >
                            <SwitchRow
                              onValueChange={newSwitchRowValue => {
                                try {
                                  setSwitchRowValue4(newSwitchRowValue);
                                } catch (err) {
                                  console.error(err);
                                }
                              }}
                              {...GlobalStyles.SwitchRowStyles(theme)[
                                'availability'
                              ].props}
                              activeTrackColor={
                                palettes.App['App Blue#4 007fd4']
                              }
                              direction={'row'}
                              label={'Thursday'}
                              style={StyleSheet.applyWidth(
                                GlobalStyles.SwitchRowStyles(theme)[
                                  'availability'
                                ].style,
                                dimensions.width
                              )}
                              value={switchRowValue4}
                            />
                            {/* View Thursday Time Options1 */}
                            <>
                              {!switchRowValue4 ? null : (
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      flexDirection: 'row',
                                      justifyContent: 'space-between',
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {/* View Start Time */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      { paddingTop: 8, width: '40%' },
                                      dimensions.width
                                    )}
                                  >
                                    {/* Picker Thur1Start */}
                                    <Picker
                                      autoDismissKeyboard={true}
                                      dropDownBackgroundColor={
                                        theme.colors.background.brand
                                      }
                                      dropDownBorderColor={
                                        theme.colors.border.brand
                                      }
                                      dropDownBorderRadius={8}
                                      dropDownBorderWidth={1}
                                      dropDownTextColor={
                                        theme.colors.text.strong
                                      }
                                      leftIconMode={'inset'}
                                      mode={'native'}
                                      onValueChange={newPickerThur1StartValue => {
                                        try {
                                          setPickerValue19(
                                            newPickerThur1StartValue
                                          );
                                        } catch (err) {
                                          console.error(err);
                                        }
                                      }}
                                      selectedIconColor={
                                        theme.colors.text.strong
                                      }
                                      selectedIconName={'Feather/check'}
                                      selectedIconSize={20}
                                      {...GlobalStyles.PickerStyles(theme)[
                                        'pickerTime'
                                      ].props}
                                      iconSize={16}
                                      options={
                                        Constants['gAlternateScheduleTimes']
                                      }
                                      placeholder={'Available at'}
                                      placeholderTextColor={
                                        palettes.App['App Text 2ndary 7F868C']
                                      }
                                      rightIconName={
                                        'Entypo/chevron-with-circle-down'
                                      }
                                      style={StyleSheet.applyWidth(
                                        GlobalStyles.PickerStyles(theme)[
                                          'pickerTime'
                                        ].style,
                                        dimensions.width
                                      )}
                                      type={'underline'}
                                      value={pickerValue19}
                                    />
                                  </View>
                                  {/* View End Time */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      { paddingTop: 8, width: '40%' },
                                      dimensions.width
                                    )}
                                  >
                                    {/* Picker Thur1End */}
                                    <>
                                      {!pickerValue19 ? null : (
                                        <Picker
                                          autoDismissKeyboard={true}
                                          dropDownBackgroundColor={
                                            theme.colors.background.brand
                                          }
                                          dropDownBorderColor={
                                            theme.colors.border.brand
                                          }
                                          dropDownBorderRadius={8}
                                          dropDownBorderWidth={1}
                                          dropDownTextColor={
                                            theme.colors.text.strong
                                          }
                                          leftIconMode={'inset'}
                                          mode={'native'}
                                          onValueChange={newPickerThur1EndValue => {
                                            try {
                                              setPickerValue20(
                                                newPickerThur1EndValue
                                              );
                                            } catch (err) {
                                              console.error(err);
                                            }
                                          }}
                                          selectedIconColor={
                                            theme.colors.text.strong
                                          }
                                          selectedIconName={'Feather/check'}
                                          selectedIconSize={20}
                                          {...GlobalStyles.PickerStyles(theme)[
                                            'pickerTime'
                                          ].props}
                                          iconSize={16}
                                          options={
                                            Constants['gAlternateScheduleTimes']
                                          }
                                          placeholder={'Until time'}
                                          placeholderTextColor={
                                            palettes.App[
                                              'App Text 2ndary 7F868C'
                                            ]
                                          }
                                          rightIconName={
                                            'Entypo/chevron-with-circle-down'
                                          }
                                          style={StyleSheet.applyWidth(
                                            GlobalStyles.PickerStyles(theme)[
                                              'pickerTime'
                                            ].style,
                                            dimensions.width
                                          )}
                                          type={'underline'}
                                          value={pickerValue20}
                                        />
                                      )}
                                    </>
                                  </View>
                                  {/* View Add Thursday Time Row */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        width: '10%',
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    <>
                                      {!pickerValue20 ? null : (
                                        <IconButton
                                          onPress={() => {
                                            try {
                                              changeTimeslotsCount(
                                                'thu',
                                                timeslotsThursday,
                                                'increment'
                                              );
                                            } catch (err) {
                                              console.error(err);
                                            }
                                          }}
                                          color={
                                            palettes.App['App Blue#4 007fd4']
                                          }
                                          icon={'FontAwesome/plus-circle'}
                                          size={26}
                                        />
                                      )}
                                    </>
                                  </View>
                                </View>
                              )}
                            </>
                            {/* View Thursday Time Options2 */}
                            <>
                              {!(timeslotsThursday > 1) ? null : (
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      flexDirection: 'row',
                                      justifyContent: 'space-between',
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {/* View Start Time */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      { paddingTop: 8, width: '40%' },
                                      dimensions.width
                                    )}
                                  >
                                    {/* Picker Thur2Start */}
                                    <Picker
                                      autoDismissKeyboard={true}
                                      dropDownBackgroundColor={
                                        theme.colors.background.brand
                                      }
                                      dropDownBorderColor={
                                        theme.colors.border.brand
                                      }
                                      dropDownBorderRadius={8}
                                      dropDownBorderWidth={1}
                                      dropDownTextColor={
                                        theme.colors.text.strong
                                      }
                                      leftIconMode={'inset'}
                                      mode={'native'}
                                      onValueChange={newPickerThur2StartValue => {
                                        try {
                                          setPickerValue21(
                                            newPickerThur2StartValue
                                          );
                                        } catch (err) {
                                          console.error(err);
                                        }
                                      }}
                                      selectedIconColor={
                                        theme.colors.text.strong
                                      }
                                      selectedIconName={'Feather/check'}
                                      selectedIconSize={20}
                                      {...GlobalStyles.PickerStyles(theme)[
                                        'pickerTime'
                                      ].props}
                                      iconSize={16}
                                      options={
                                        Constants['gAlternateScheduleTimes']
                                      }
                                      placeholder={'Available at'}
                                      placeholderTextColor={
                                        palettes.App['App Text 2ndary 7F868C']
                                      }
                                      rightIconName={
                                        'Entypo/chevron-with-circle-down'
                                      }
                                      style={StyleSheet.applyWidth(
                                        GlobalStyles.PickerStyles(theme)[
                                          'pickerTime'
                                        ].style,
                                        dimensions.width
                                      )}
                                      type={'underline'}
                                      value={pickerValue21}
                                    />
                                  </View>
                                  {/* View End Time */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      { paddingTop: 8, width: '40%' },
                                      dimensions.width
                                    )}
                                  >
                                    {/* Picker Thur2End */}
                                    <>
                                      {!pickerValue21 ? null : (
                                        <Picker
                                          autoDismissKeyboard={true}
                                          dropDownBackgroundColor={
                                            theme.colors.background.brand
                                          }
                                          dropDownBorderColor={
                                            theme.colors.border.brand
                                          }
                                          dropDownBorderRadius={8}
                                          dropDownBorderWidth={1}
                                          dropDownTextColor={
                                            theme.colors.text.strong
                                          }
                                          leftIconMode={'inset'}
                                          mode={'native'}
                                          onValueChange={newPickerThur2EndValue => {
                                            try {
                                              setPickerValue22(
                                                newPickerThur2EndValue
                                              );
                                            } catch (err) {
                                              console.error(err);
                                            }
                                          }}
                                          selectedIconColor={
                                            theme.colors.text.strong
                                          }
                                          selectedIconName={'Feather/check'}
                                          selectedIconSize={20}
                                          {...GlobalStyles.PickerStyles(theme)[
                                            'pickerTime'
                                          ].props}
                                          iconSize={16}
                                          options={
                                            Constants['gAlternateScheduleTimes']
                                          }
                                          placeholder={'Until time'}
                                          placeholderTextColor={
                                            palettes.App[
                                              'App Text 2ndary 7F868C'
                                            ]
                                          }
                                          rightIconName={
                                            'Entypo/chevron-with-circle-down'
                                          }
                                          style={StyleSheet.applyWidth(
                                            GlobalStyles.PickerStyles(theme)[
                                              'pickerTime'
                                            ].style,
                                            dimensions.width
                                          )}
                                          type={'underline'}
                                          value={pickerValue22}
                                        />
                                      )}
                                    </>
                                  </View>
                                  {/* View Add Monday Time Row */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        width: '10%',
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    <IconButton
                                      onPress={() => {
                                        try {
                                          changeTimeslotsCount(
                                            'thu',
                                            timeslotsThursday,
                                            'decrement'
                                          );
                                        } catch (err) {
                                          console.error(err);
                                        }
                                      }}
                                      color={
                                        palettes.App[
                                          'App Disabled Button EDEDEF'
                                        ]
                                      }
                                      icon={'FontAwesome/minus-circle'}
                                      size={26}
                                    />
                                  </View>
                                </View>
                              )}
                            </>
                            {/* View Thursday Time Options3 */}
                            <>
                              {!(timeslotsThursday > 2) ? null : (
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      flexDirection: 'row',
                                      justifyContent: 'space-between',
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {/* View Start Time */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      { paddingTop: 8, width: '40%' },
                                      dimensions.width
                                    )}
                                  >
                                    {/* Picker Thur3Start */}
                                    <Picker
                                      autoDismissKeyboard={true}
                                      dropDownBackgroundColor={
                                        theme.colors.background.brand
                                      }
                                      dropDownBorderColor={
                                        theme.colors.border.brand
                                      }
                                      dropDownBorderRadius={8}
                                      dropDownBorderWidth={1}
                                      dropDownTextColor={
                                        theme.colors.text.strong
                                      }
                                      leftIconMode={'inset'}
                                      mode={'native'}
                                      onValueChange={newPickerThur3StartValue => {
                                        try {
                                          setPickerValue23(
                                            newPickerThur3StartValue
                                          );
                                        } catch (err) {
                                          console.error(err);
                                        }
                                      }}
                                      selectedIconColor={
                                        theme.colors.text.strong
                                      }
                                      selectedIconName={'Feather/check'}
                                      selectedIconSize={20}
                                      {...GlobalStyles.PickerStyles(theme)[
                                        'pickerTime'
                                      ].props}
                                      iconSize={16}
                                      options={
                                        Constants['gAlternateScheduleTimes']
                                      }
                                      placeholder={'Available at'}
                                      placeholderTextColor={
                                        palettes.App['App Text 2ndary 7F868C']
                                      }
                                      rightIconName={
                                        'Entypo/chevron-with-circle-down'
                                      }
                                      style={StyleSheet.applyWidth(
                                        GlobalStyles.PickerStyles(theme)[
                                          'pickerTime'
                                        ].style,
                                        dimensions.width
                                      )}
                                      type={'underline'}
                                      value={pickerValue23}
                                    />
                                  </View>
                                  {/* View End Time */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      { paddingTop: 8, width: '40%' },
                                      dimensions.width
                                    )}
                                  >
                                    {/* Picker Thur3End */}
                                    <>
                                      {!pickerValue23 ? null : (
                                        <Picker
                                          autoDismissKeyboard={true}
                                          dropDownBackgroundColor={
                                            theme.colors.background.brand
                                          }
                                          dropDownBorderColor={
                                            theme.colors.border.brand
                                          }
                                          dropDownBorderRadius={8}
                                          dropDownBorderWidth={1}
                                          dropDownTextColor={
                                            theme.colors.text.strong
                                          }
                                          leftIconMode={'inset'}
                                          mode={'native'}
                                          onValueChange={newPickerThur3EndValue => {
                                            try {
                                              setPickerValue24(
                                                newPickerThur3EndValue
                                              );
                                            } catch (err) {
                                              console.error(err);
                                            }
                                          }}
                                          selectedIconColor={
                                            theme.colors.text.strong
                                          }
                                          selectedIconName={'Feather/check'}
                                          selectedIconSize={20}
                                          {...GlobalStyles.PickerStyles(theme)[
                                            'pickerTime'
                                          ].props}
                                          iconSize={16}
                                          options={
                                            Constants['gAlternateScheduleTimes']
                                          }
                                          placeholder={'Until time'}
                                          placeholderTextColor={
                                            palettes.App[
                                              'App Text 2ndary 7F868C'
                                            ]
                                          }
                                          rightIconName={
                                            'Entypo/chevron-with-circle-down'
                                          }
                                          style={StyleSheet.applyWidth(
                                            GlobalStyles.PickerStyles(theme)[
                                              'pickerTime'
                                            ].style,
                                            dimensions.width
                                          )}
                                          type={'underline'}
                                          value={pickerValue24}
                                        />
                                      )}
                                    </>
                                  </View>
                                  {/* View Add Monday Time Row */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        width: '10%',
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    <IconButton
                                      onPress={() => {
                                        try {
                                          changeTimeslotsCount(
                                            'thu',
                                            timeslotsThursday,
                                            'decrement'
                                          );
                                        } catch (err) {
                                          console.error(err);
                                        }
                                      }}
                                      color={
                                        palettes.App[
                                          'App Disabled Button EDEDEF'
                                        ]
                                      }
                                      icon={'FontAwesome/minus-circle'}
                                      size={26}
                                    />
                                  </View>
                                </View>
                              )}
                            </>
                          </View>
                          {/* Friday Availability */}
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                paddingBottom: 6,
                                paddingTop: 6,
                                width: '100%',
                              },
                              dimensions.width
                            )}
                          >
                            {/* Friday */}
                            <SwitchRow
                              onValueChange={newFridayValue => {
                                try {
                                  setSwitchRowValue5(newFridayValue);
                                } catch (err) {
                                  console.error(err);
                                }
                              }}
                              {...GlobalStyles.SwitchRowStyles(theme)[
                                'availability'
                              ].props}
                              activeTrackColor={
                                palettes.App['App Blue#4 007fd4']
                              }
                              direction={'row'}
                              label={'Friday'}
                              style={StyleSheet.applyWidth(
                                GlobalStyles.SwitchRowStyles(theme)[
                                  'availability'
                                ].style,
                                dimensions.width
                              )}
                              value={switchRowValue5}
                            />
                            {/* View Friday Time Options1 */}
                            <>
                              {!switchRowValue5 ? null : (
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      flexDirection: 'row',
                                      justifyContent: 'space-between',
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {/* View Start Time */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      { paddingTop: 8, width: '40%' },
                                      dimensions.width
                                    )}
                                  >
                                    {/* Picker Fri1Start */}
                                    <Picker
                                      autoDismissKeyboard={true}
                                      dropDownBackgroundColor={
                                        theme.colors.background.brand
                                      }
                                      dropDownBorderColor={
                                        theme.colors.border.brand
                                      }
                                      dropDownBorderRadius={8}
                                      dropDownBorderWidth={1}
                                      dropDownTextColor={
                                        theme.colors.text.strong
                                      }
                                      leftIconMode={'inset'}
                                      mode={'native'}
                                      onValueChange={newPickerFri1StartValue => {
                                        try {
                                          setPickerValue25(
                                            newPickerFri1StartValue
                                          );
                                        } catch (err) {
                                          console.error(err);
                                        }
                                      }}
                                      selectedIconColor={
                                        theme.colors.text.strong
                                      }
                                      selectedIconName={'Feather/check'}
                                      selectedIconSize={20}
                                      {...GlobalStyles.PickerStyles(theme)[
                                        'pickerTime'
                                      ].props}
                                      iconSize={16}
                                      options={
                                        Constants['gAlternateScheduleTimes']
                                      }
                                      placeholder={'Available at'}
                                      placeholderTextColor={
                                        palettes.App['App Text 2ndary 7F868C']
                                      }
                                      rightIconName={
                                        'Entypo/chevron-with-circle-down'
                                      }
                                      style={StyleSheet.applyWidth(
                                        GlobalStyles.PickerStyles(theme)[
                                          'pickerTime'
                                        ].style,
                                        dimensions.width
                                      )}
                                      type={'underline'}
                                      value={pickerValue25}
                                    />
                                  </View>
                                  {/* View End Time */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      { paddingTop: 8, width: '40%' },
                                      dimensions.width
                                    )}
                                  >
                                    {/* Picker Fri1End */}
                                    <>
                                      {!pickerValue25 ? null : (
                                        <Picker
                                          autoDismissKeyboard={true}
                                          dropDownBackgroundColor={
                                            theme.colors.background.brand
                                          }
                                          dropDownBorderColor={
                                            theme.colors.border.brand
                                          }
                                          dropDownBorderRadius={8}
                                          dropDownBorderWidth={1}
                                          dropDownTextColor={
                                            theme.colors.text.strong
                                          }
                                          leftIconMode={'inset'}
                                          mode={'native'}
                                          onValueChange={newPickerFri1EndValue => {
                                            try {
                                              setPickerValue26(
                                                newPickerFri1EndValue
                                              );
                                            } catch (err) {
                                              console.error(err);
                                            }
                                          }}
                                          selectedIconColor={
                                            theme.colors.text.strong
                                          }
                                          selectedIconName={'Feather/check'}
                                          selectedIconSize={20}
                                          {...GlobalStyles.PickerStyles(theme)[
                                            'pickerTime'
                                          ].props}
                                          iconSize={16}
                                          options={
                                            Constants['gAlternateScheduleTimes']
                                          }
                                          placeholder={'Until time'}
                                          placeholderTextColor={
                                            palettes.App[
                                              'App Text 2ndary 7F868C'
                                            ]
                                          }
                                          rightIconName={
                                            'Entypo/chevron-with-circle-down'
                                          }
                                          style={StyleSheet.applyWidth(
                                            GlobalStyles.PickerStyles(theme)[
                                              'pickerTime'
                                            ].style,
                                            dimensions.width
                                          )}
                                          type={'underline'}
                                          value={pickerValue26}
                                        />
                                      )}
                                    </>
                                  </View>
                                  {/* View Add Friday Time Row */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        width: '10%',
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    <>
                                      {!pickerValue26 ? null : (
                                        <IconButton
                                          onPress={() => {
                                            try {
                                              changeTimeslotsCount(
                                                'fri',
                                                timeslotsFriday,
                                                'increment'
                                              );
                                            } catch (err) {
                                              console.error(err);
                                            }
                                          }}
                                          color={
                                            palettes.App['App Blue#4 007fd4']
                                          }
                                          icon={'FontAwesome/plus-circle'}
                                          size={26}
                                        />
                                      )}
                                    </>
                                  </View>
                                </View>
                              )}
                            </>
                            {/* View Friday Time Options2 */}
                            <>
                              {!(timeslotsFriday > 1) ? null : (
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      flexDirection: 'row',
                                      justifyContent: 'space-between',
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {/* View Start Time */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      { paddingTop: 8, width: '40%' },
                                      dimensions.width
                                    )}
                                  >
                                    {/* Picker Fri2Start */}
                                    <Picker
                                      autoDismissKeyboard={true}
                                      dropDownBackgroundColor={
                                        theme.colors.background.brand
                                      }
                                      dropDownBorderColor={
                                        theme.colors.border.brand
                                      }
                                      dropDownBorderRadius={8}
                                      dropDownBorderWidth={1}
                                      dropDownTextColor={
                                        theme.colors.text.strong
                                      }
                                      leftIconMode={'inset'}
                                      mode={'native'}
                                      onValueChange={newPickerFri2StartValue => {
                                        try {
                                          setPickerValue27(
                                            newPickerFri2StartValue
                                          );
                                        } catch (err) {
                                          console.error(err);
                                        }
                                      }}
                                      selectedIconColor={
                                        theme.colors.text.strong
                                      }
                                      selectedIconName={'Feather/check'}
                                      selectedIconSize={20}
                                      {...GlobalStyles.PickerStyles(theme)[
                                        'pickerTime'
                                      ].props}
                                      iconSize={16}
                                      options={
                                        Constants['gAlternateScheduleTimes']
                                      }
                                      placeholder={'Available at'}
                                      placeholderTextColor={
                                        palettes.App['App Text 2ndary 7F868C']
                                      }
                                      rightIconName={
                                        'Entypo/chevron-with-circle-down'
                                      }
                                      style={StyleSheet.applyWidth(
                                        GlobalStyles.PickerStyles(theme)[
                                          'pickerTime'
                                        ].style,
                                        dimensions.width
                                      )}
                                      type={'underline'}
                                      value={pickerValue27}
                                    />
                                  </View>
                                  {/* View End Time */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      { paddingTop: 8, width: '40%' },
                                      dimensions.width
                                    )}
                                  >
                                    {/* Picker Fri2End */}
                                    <>
                                      {!pickerValue27 ? null : (
                                        <Picker
                                          autoDismissKeyboard={true}
                                          dropDownBackgroundColor={
                                            theme.colors.background.brand
                                          }
                                          dropDownBorderColor={
                                            theme.colors.border.brand
                                          }
                                          dropDownBorderRadius={8}
                                          dropDownBorderWidth={1}
                                          dropDownTextColor={
                                            theme.colors.text.strong
                                          }
                                          leftIconMode={'inset'}
                                          mode={'native'}
                                          onValueChange={newPickerFri2EndValue => {
                                            try {
                                              setPickerValue28(
                                                newPickerFri2EndValue
                                              );
                                            } catch (err) {
                                              console.error(err);
                                            }
                                          }}
                                          selectedIconColor={
                                            theme.colors.text.strong
                                          }
                                          selectedIconName={'Feather/check'}
                                          selectedIconSize={20}
                                          {...GlobalStyles.PickerStyles(theme)[
                                            'pickerTime'
                                          ].props}
                                          iconSize={16}
                                          options={
                                            Constants['gAlternateScheduleTimes']
                                          }
                                          placeholder={'Until time'}
                                          placeholderTextColor={
                                            palettes.App[
                                              'App Text 2ndary 7F868C'
                                            ]
                                          }
                                          rightIconName={
                                            'Entypo/chevron-with-circle-down'
                                          }
                                          style={StyleSheet.applyWidth(
                                            GlobalStyles.PickerStyles(theme)[
                                              'pickerTime'
                                            ].style,
                                            dimensions.width
                                          )}
                                          type={'underline'}
                                          value={pickerValue28}
                                        />
                                      )}
                                    </>
                                  </View>
                                  {/* View Add Monday Time Row */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        width: '10%',
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    <IconButton
                                      onPress={() => {
                                        try {
                                          changeTimeslotsCount(
                                            'fri',
                                            timeslotsFriday,
                                            'decrement'
                                          );
                                        } catch (err) {
                                          console.error(err);
                                        }
                                      }}
                                      color={
                                        palettes.App[
                                          'App Disabled Button EDEDEF'
                                        ]
                                      }
                                      icon={'FontAwesome/minus-circle'}
                                      size={26}
                                    />
                                  </View>
                                </View>
                              )}
                            </>
                            {/* View Friday Time Options3 */}
                            <>
                              {!(timeslotsFriday > 2) ? null : (
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      flexDirection: 'row',
                                      justifyContent: 'space-between',
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {/* View Start Time */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      { paddingTop: 8, width: '40%' },
                                      dimensions.width
                                    )}
                                  >
                                    {/* Picker Fri3Start */}
                                    <Picker
                                      autoDismissKeyboard={true}
                                      dropDownBackgroundColor={
                                        theme.colors.background.brand
                                      }
                                      dropDownBorderColor={
                                        theme.colors.border.brand
                                      }
                                      dropDownBorderRadius={8}
                                      dropDownBorderWidth={1}
                                      dropDownTextColor={
                                        theme.colors.text.strong
                                      }
                                      leftIconMode={'inset'}
                                      mode={'native'}
                                      onValueChange={newPickerFri3StartValue => {
                                        try {
                                          setPickerValue29(
                                            newPickerFri3StartValue
                                          );
                                        } catch (err) {
                                          console.error(err);
                                        }
                                      }}
                                      selectedIconColor={
                                        theme.colors.text.strong
                                      }
                                      selectedIconName={'Feather/check'}
                                      selectedIconSize={20}
                                      {...GlobalStyles.PickerStyles(theme)[
                                        'pickerTime'
                                      ].props}
                                      iconSize={16}
                                      options={
                                        Constants['gAlternateScheduleTimes']
                                      }
                                      placeholder={'Available at'}
                                      placeholderTextColor={
                                        palettes.App['App Text 2ndary 7F868C']
                                      }
                                      rightIconName={
                                        'Entypo/chevron-with-circle-down'
                                      }
                                      style={StyleSheet.applyWidth(
                                        GlobalStyles.PickerStyles(theme)[
                                          'pickerTime'
                                        ].style,
                                        dimensions.width
                                      )}
                                      type={'underline'}
                                      value={pickerValue29}
                                    />
                                  </View>
                                  {/* View End Time */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      { paddingTop: 8, width: '40%' },
                                      dimensions.width
                                    )}
                                  >
                                    {/* Picker Fri3End */}
                                    <>
                                      {!pickerValue29 ? null : (
                                        <Picker
                                          autoDismissKeyboard={true}
                                          dropDownBackgroundColor={
                                            theme.colors.background.brand
                                          }
                                          dropDownBorderColor={
                                            theme.colors.border.brand
                                          }
                                          dropDownBorderRadius={8}
                                          dropDownBorderWidth={1}
                                          dropDownTextColor={
                                            theme.colors.text.strong
                                          }
                                          leftIconMode={'inset'}
                                          mode={'native'}
                                          onValueChange={newPickerFri3EndValue => {
                                            try {
                                              setPickerValue30(
                                                newPickerFri3EndValue
                                              );
                                            } catch (err) {
                                              console.error(err);
                                            }
                                          }}
                                          selectedIconColor={
                                            theme.colors.text.strong
                                          }
                                          selectedIconName={'Feather/check'}
                                          selectedIconSize={20}
                                          {...GlobalStyles.PickerStyles(theme)[
                                            'pickerTime'
                                          ].props}
                                          iconSize={16}
                                          options={
                                            Constants['gAlternateScheduleTimes']
                                          }
                                          placeholder={'Until time'}
                                          placeholderTextColor={
                                            palettes.App[
                                              'App Text 2ndary 7F868C'
                                            ]
                                          }
                                          rightIconName={
                                            'Entypo/chevron-with-circle-down'
                                          }
                                          style={StyleSheet.applyWidth(
                                            GlobalStyles.PickerStyles(theme)[
                                              'pickerTime'
                                            ].style,
                                            dimensions.width
                                          )}
                                          type={'underline'}
                                          value={pickerValue30}
                                        />
                                      )}
                                    </>
                                  </View>
                                  {/* View Add Monday Time Row */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        width: '10%',
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    <IconButton
                                      onPress={() => {
                                        try {
                                          changeTimeslotsCount(
                                            'fri',
                                            timeslotsFriday,
                                            'decrement'
                                          );
                                        } catch (err) {
                                          console.error(err);
                                        }
                                      }}
                                      color={
                                        palettes.App[
                                          'App Disabled Button EDEDEF'
                                        ]
                                      }
                                      icon={'FontAwesome/minus-circle'}
                                      size={26}
                                    />
                                  </View>
                                </View>
                              )}
                            </>
                          </View>
                          {/* Saturday Availability */}
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                paddingBottom: 6,
                                paddingTop: 6,
                                width: '100%',
                              },
                              dimensions.width
                            )}
                          >
                            {/* Saturday */}
                            <SwitchRow
                              onValueChange={newSaturdayValue => {
                                try {
                                  setSwitchRowValue6(newSaturdayValue);
                                } catch (err) {
                                  console.error(err);
                                }
                              }}
                              {...GlobalStyles.SwitchRowStyles(theme)[
                                'availability'
                              ].props}
                              activeTrackColor={
                                palettes.App['App Blue#4 007fd4']
                              }
                              direction={'row'}
                              label={'Saturday'}
                              style={StyleSheet.applyWidth(
                                GlobalStyles.SwitchRowStyles(theme)[
                                  'availability'
                                ].style,
                                dimensions.width
                              )}
                              value={switchRowValue6}
                            />
                            {/* View Saturday Time Options1 */}
                            <>
                              {!switchRowValue6 ? null : (
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      flexDirection: 'row',
                                      justifyContent: 'space-between',
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {/* View Start Time */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      { paddingTop: 8, width: '40%' },
                                      dimensions.width
                                    )}
                                  >
                                    {/* Picker Sat1Start */}
                                    <Picker
                                      autoDismissKeyboard={true}
                                      dropDownBackgroundColor={
                                        theme.colors.background.brand
                                      }
                                      dropDownBorderColor={
                                        theme.colors.border.brand
                                      }
                                      dropDownBorderRadius={8}
                                      dropDownBorderWidth={1}
                                      dropDownTextColor={
                                        theme.colors.text.strong
                                      }
                                      leftIconMode={'inset'}
                                      mode={'native'}
                                      onValueChange={newPickerSat1StartValue => {
                                        try {
                                          setPickerValue31(
                                            newPickerSat1StartValue
                                          );
                                        } catch (err) {
                                          console.error(err);
                                        }
                                      }}
                                      selectedIconColor={
                                        theme.colors.text.strong
                                      }
                                      selectedIconName={'Feather/check'}
                                      selectedIconSize={20}
                                      {...GlobalStyles.PickerStyles(theme)[
                                        'pickerTime'
                                      ].props}
                                      iconSize={16}
                                      options={
                                        Constants['gAlternateScheduleTimes']
                                      }
                                      placeholder={'Available at'}
                                      placeholderTextColor={
                                        palettes.App['App Text 2ndary 7F868C']
                                      }
                                      rightIconName={
                                        'Entypo/chevron-with-circle-down'
                                      }
                                      style={StyleSheet.applyWidth(
                                        GlobalStyles.PickerStyles(theme)[
                                          'pickerTime'
                                        ].style,
                                        dimensions.width
                                      )}
                                      type={'underline'}
                                      value={pickerValue31}
                                    />
                                  </View>
                                  {/* View End Time */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      { paddingTop: 8, width: '40%' },
                                      dimensions.width
                                    )}
                                  >
                                    {/* Picker Sat1End */}
                                    <>
                                      {!pickerValue31 ? null : (
                                        <Picker
                                          autoDismissKeyboard={true}
                                          dropDownBackgroundColor={
                                            theme.colors.background.brand
                                          }
                                          dropDownBorderColor={
                                            theme.colors.border.brand
                                          }
                                          dropDownBorderRadius={8}
                                          dropDownBorderWidth={1}
                                          dropDownTextColor={
                                            theme.colors.text.strong
                                          }
                                          leftIconMode={'inset'}
                                          mode={'native'}
                                          onValueChange={newPickerSat1EndValue => {
                                            try {
                                              setPickerValue32(
                                                newPickerSat1EndValue
                                              );
                                            } catch (err) {
                                              console.error(err);
                                            }
                                          }}
                                          selectedIconColor={
                                            theme.colors.text.strong
                                          }
                                          selectedIconName={'Feather/check'}
                                          selectedIconSize={20}
                                          {...GlobalStyles.PickerStyles(theme)[
                                            'pickerTime'
                                          ].props}
                                          iconSize={16}
                                          options={
                                            Constants['gAlternateScheduleTimes']
                                          }
                                          placeholder={'Until time'}
                                          placeholderTextColor={
                                            palettes.App[
                                              'App Text 2ndary 7F868C'
                                            ]
                                          }
                                          rightIconName={
                                            'Entypo/chevron-with-circle-down'
                                          }
                                          style={StyleSheet.applyWidth(
                                            GlobalStyles.PickerStyles(theme)[
                                              'pickerTime'
                                            ].style,
                                            dimensions.width
                                          )}
                                          type={'underline'}
                                          value={pickerValue32}
                                        />
                                      )}
                                    </>
                                  </View>
                                  {/* View Add Friday Time Row */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        width: '10%',
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    <>
                                      {!pickerValue32 ? null : (
                                        <IconButton
                                          onPress={() => {
                                            try {
                                              changeTimeslotsCount(
                                                'sat',
                                                timeslotsSaturday,
                                                'increment'
                                              );
                                            } catch (err) {
                                              console.error(err);
                                            }
                                          }}
                                          color={
                                            palettes.App['App Blue#4 007fd4']
                                          }
                                          icon={'FontAwesome/plus-circle'}
                                          size={26}
                                        />
                                      )}
                                    </>
                                  </View>
                                </View>
                              )}
                            </>
                            {/* View Saturday Time Options2 */}
                            <>
                              {!(timeslotsSaturday > 1) ? null : (
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      flexDirection: 'row',
                                      justifyContent: 'space-between',
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {/* View Start Time */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      { paddingTop: 8, width: '40%' },
                                      dimensions.width
                                    )}
                                  >
                                    {/* Picker Sat2Start */}
                                    <Picker
                                      autoDismissKeyboard={true}
                                      dropDownBackgroundColor={
                                        theme.colors.background.brand
                                      }
                                      dropDownBorderColor={
                                        theme.colors.border.brand
                                      }
                                      dropDownBorderRadius={8}
                                      dropDownBorderWidth={1}
                                      dropDownTextColor={
                                        theme.colors.text.strong
                                      }
                                      leftIconMode={'inset'}
                                      mode={'native'}
                                      onValueChange={newPickerSat2StartValue => {
                                        try {
                                          setPickerValue33(
                                            newPickerSat2StartValue
                                          );
                                        } catch (err) {
                                          console.error(err);
                                        }
                                      }}
                                      selectedIconColor={
                                        theme.colors.text.strong
                                      }
                                      selectedIconName={'Feather/check'}
                                      selectedIconSize={20}
                                      {...GlobalStyles.PickerStyles(theme)[
                                        'pickerTime'
                                      ].props}
                                      iconSize={16}
                                      options={
                                        Constants['gAlternateScheduleTimes']
                                      }
                                      placeholder={'Available at'}
                                      placeholderTextColor={
                                        palettes.App['App Text 2ndary 7F868C']
                                      }
                                      rightIconName={
                                        'Entypo/chevron-with-circle-down'
                                      }
                                      style={StyleSheet.applyWidth(
                                        GlobalStyles.PickerStyles(theme)[
                                          'pickerTime'
                                        ].style,
                                        dimensions.width
                                      )}
                                      type={'underline'}
                                      value={pickerValue33}
                                    />
                                  </View>
                                  {/* View End Time */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      { paddingTop: 8, width: '40%' },
                                      dimensions.width
                                    )}
                                  >
                                    {/* Picker Sat2End */}
                                    <>
                                      {!pickerValue33 ? null : (
                                        <Picker
                                          autoDismissKeyboard={true}
                                          dropDownBackgroundColor={
                                            theme.colors.background.brand
                                          }
                                          dropDownBorderColor={
                                            theme.colors.border.brand
                                          }
                                          dropDownBorderRadius={8}
                                          dropDownBorderWidth={1}
                                          dropDownTextColor={
                                            theme.colors.text.strong
                                          }
                                          leftIconMode={'inset'}
                                          mode={'native'}
                                          onValueChange={newPickerSat2EndValue => {
                                            try {
                                              setPickerValue34(
                                                newPickerSat2EndValue
                                              );
                                            } catch (err) {
                                              console.error(err);
                                            }
                                          }}
                                          selectedIconColor={
                                            theme.colors.text.strong
                                          }
                                          selectedIconName={'Feather/check'}
                                          selectedIconSize={20}
                                          {...GlobalStyles.PickerStyles(theme)[
                                            'pickerTime'
                                          ].props}
                                          iconSize={16}
                                          options={
                                            Constants['gAlternateScheduleTimes']
                                          }
                                          placeholder={'Until time'}
                                          placeholderTextColor={
                                            palettes.App[
                                              'App Text 2ndary 7F868C'
                                            ]
                                          }
                                          rightIconName={
                                            'Entypo/chevron-with-circle-down'
                                          }
                                          style={StyleSheet.applyWidth(
                                            GlobalStyles.PickerStyles(theme)[
                                              'pickerTime'
                                            ].style,
                                            dimensions.width
                                          )}
                                          type={'underline'}
                                          value={pickerValue34}
                                        />
                                      )}
                                    </>
                                  </View>
                                  {/* View Add Monday Time Row */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        width: '10%',
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    <IconButton
                                      onPress={() => {
                                        try {
                                          changeTimeslotsCount(
                                            'sat',
                                            timeslotsSaturday,
                                            'decrement'
                                          );
                                        } catch (err) {
                                          console.error(err);
                                        }
                                      }}
                                      color={
                                        palettes.App[
                                          'App Disabled Button EDEDEF'
                                        ]
                                      }
                                      icon={'FontAwesome/minus-circle'}
                                      size={26}
                                    />
                                  </View>
                                </View>
                              )}
                            </>
                            {/* View Saturday Time Options3 */}
                            <>
                              {!(timeslotsSaturday > 2) ? null : (
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      flexDirection: 'row',
                                      justifyContent: 'space-between',
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {/* View Start Time */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      { paddingTop: 8, width: '40%' },
                                      dimensions.width
                                    )}
                                  >
                                    {/* Picker Sat3Start */}
                                    <Picker
                                      autoDismissKeyboard={true}
                                      dropDownBackgroundColor={
                                        theme.colors.background.brand
                                      }
                                      dropDownBorderColor={
                                        theme.colors.border.brand
                                      }
                                      dropDownBorderRadius={8}
                                      dropDownBorderWidth={1}
                                      dropDownTextColor={
                                        theme.colors.text.strong
                                      }
                                      leftIconMode={'inset'}
                                      mode={'native'}
                                      onValueChange={newPickerSat3StartValue => {
                                        try {
                                          setPickerValue35(
                                            newPickerSat3StartValue
                                          );
                                        } catch (err) {
                                          console.error(err);
                                        }
                                      }}
                                      selectedIconColor={
                                        theme.colors.text.strong
                                      }
                                      selectedIconName={'Feather/check'}
                                      selectedIconSize={20}
                                      {...GlobalStyles.PickerStyles(theme)[
                                        'pickerTime'
                                      ].props}
                                      iconSize={16}
                                      options={
                                        Constants['gAlternateScheduleTimes']
                                      }
                                      placeholder={'Available at'}
                                      placeholderTextColor={
                                        palettes.App['App Text 2ndary 7F868C']
                                      }
                                      rightIconName={
                                        'Entypo/chevron-with-circle-down'
                                      }
                                      style={StyleSheet.applyWidth(
                                        GlobalStyles.PickerStyles(theme)[
                                          'pickerTime'
                                        ].style,
                                        dimensions.width
                                      )}
                                      type={'underline'}
                                      value={pickerValue35}
                                    />
                                  </View>
                                  {/* View End Time */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      { paddingTop: 8, width: '40%' },
                                      dimensions.width
                                    )}
                                  >
                                    {/* Picker Sat3End */}
                                    <>
                                      {!pickerValue35 ? null : (
                                        <Picker
                                          autoDismissKeyboard={true}
                                          dropDownBackgroundColor={
                                            theme.colors.background.brand
                                          }
                                          dropDownBorderColor={
                                            theme.colors.border.brand
                                          }
                                          dropDownBorderRadius={8}
                                          dropDownBorderWidth={1}
                                          dropDownTextColor={
                                            theme.colors.text.strong
                                          }
                                          leftIconMode={'inset'}
                                          mode={'native'}
                                          onValueChange={newPickerSat3EndValue => {
                                            try {
                                              setPickerValue36(
                                                newPickerSat3EndValue
                                              );
                                            } catch (err) {
                                              console.error(err);
                                            }
                                          }}
                                          selectedIconColor={
                                            theme.colors.text.strong
                                          }
                                          selectedIconName={'Feather/check'}
                                          selectedIconSize={20}
                                          {...GlobalStyles.PickerStyles(theme)[
                                            'pickerTime'
                                          ].props}
                                          iconSize={16}
                                          options={
                                            Constants['gAlternateScheduleTimes']
                                          }
                                          placeholder={'Until time'}
                                          placeholderTextColor={
                                            palettes.App[
                                              'App Text 2ndary 7F868C'
                                            ]
                                          }
                                          rightIconName={
                                            'Entypo/chevron-with-circle-down'
                                          }
                                          style={StyleSheet.applyWidth(
                                            GlobalStyles.PickerStyles(theme)[
                                              'pickerTime'
                                            ].style,
                                            dimensions.width
                                          )}
                                          type={'underline'}
                                          value={pickerValue36}
                                        />
                                      )}
                                    </>
                                  </View>
                                  {/* View Add Monday Time Row */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        width: '10%',
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    <IconButton
                                      onPress={() => {
                                        try {
                                          changeTimeslotsCount(
                                            'sat',
                                            timeslotsSaturday,
                                            'decrement'
                                          );
                                        } catch (err) {
                                          console.error(err);
                                        }
                                      }}
                                      color={
                                        palettes.App[
                                          'App Disabled Button EDEDEF'
                                        ]
                                      }
                                      icon={'FontAwesome/minus-circle'}
                                      size={26}
                                    />
                                  </View>
                                </View>
                              )}
                            </>
                          </View>
                          {/* Sunday Availability */}
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                paddingBottom: 6,
                                paddingTop: 6,
                                width: '100%',
                              },
                              dimensions.width
                            )}
                          >
                            {/* Sunday */}
                            <SwitchRow
                              onValueChange={newSundayValue => {
                                try {
                                  setSwitchRowValue7(newSundayValue);
                                } catch (err) {
                                  console.error(err);
                                }
                              }}
                              {...GlobalStyles.SwitchRowStyles(theme)[
                                'availability'
                              ].props}
                              activeTrackColor={
                                palettes.App['App Blue#4 007fd4']
                              }
                              label={'Sunday'}
                              style={StyleSheet.applyWidth(
                                GlobalStyles.SwitchRowStyles(theme)[
                                  'availability'
                                ].style,
                                dimensions.width
                              )}
                              value={switchRowValue7}
                            />
                            {/* View Sunday Time Options1 */}
                            <>
                              {!switchRowValue7 ? null : (
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      flexDirection: 'row',
                                      justifyContent: 'space-between',
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {/* View Start Time */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      { paddingTop: 8, width: '40%' },
                                      dimensions.width
                                    )}
                                  >
                                    {/* Picker Sun1Start */}
                                    <Picker
                                      autoDismissKeyboard={true}
                                      dropDownBackgroundColor={
                                        theme.colors.background.brand
                                      }
                                      dropDownBorderColor={
                                        theme.colors.border.brand
                                      }
                                      dropDownBorderRadius={8}
                                      dropDownBorderWidth={1}
                                      dropDownTextColor={
                                        theme.colors.text.strong
                                      }
                                      leftIconMode={'inset'}
                                      mode={'native'}
                                      onValueChange={newPickerSun1StartValue => {
                                        try {
                                          setPickerValue37(
                                            newPickerSun1StartValue
                                          );
                                        } catch (err) {
                                          console.error(err);
                                        }
                                      }}
                                      selectedIconColor={
                                        theme.colors.text.strong
                                      }
                                      selectedIconName={'Feather/check'}
                                      selectedIconSize={20}
                                      {...GlobalStyles.PickerStyles(theme)[
                                        'pickerTime'
                                      ].props}
                                      iconSize={16}
                                      options={
                                        Constants['gAlternateScheduleTimes']
                                      }
                                      placeholder={'Available at'}
                                      placeholderTextColor={
                                        palettes.App['App Text 2ndary 7F868C']
                                      }
                                      rightIconName={
                                        'Entypo/chevron-with-circle-down'
                                      }
                                      style={StyleSheet.applyWidth(
                                        GlobalStyles.PickerStyles(theme)[
                                          'pickerTime'
                                        ].style,
                                        dimensions.width
                                      )}
                                      type={'underline'}
                                      value={pickerValue37}
                                    />
                                  </View>
                                  {/* View End Time */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      { paddingTop: 8, width: '40%' },
                                      dimensions.width
                                    )}
                                  >
                                    {/* Picker Sun1End */}
                                    <>
                                      {!pickerValue37 ? null : (
                                        <Picker
                                          autoDismissKeyboard={true}
                                          dropDownBackgroundColor={
                                            theme.colors.background.brand
                                          }
                                          dropDownBorderColor={
                                            theme.colors.border.brand
                                          }
                                          dropDownBorderRadius={8}
                                          dropDownBorderWidth={1}
                                          dropDownTextColor={
                                            theme.colors.text.strong
                                          }
                                          leftIconMode={'inset'}
                                          mode={'native'}
                                          onValueChange={newPickerSun1EndValue => {
                                            try {
                                              setPickerValue38(
                                                newPickerSun1EndValue
                                              );
                                            } catch (err) {
                                              console.error(err);
                                            }
                                          }}
                                          selectedIconColor={
                                            theme.colors.text.strong
                                          }
                                          selectedIconName={'Feather/check'}
                                          selectedIconSize={20}
                                          {...GlobalStyles.PickerStyles(theme)[
                                            'pickerTime'
                                          ].props}
                                          iconSize={16}
                                          options={
                                            Constants['gAlternateScheduleTimes']
                                          }
                                          placeholder={'Until time'}
                                          placeholderTextColor={
                                            palettes.App[
                                              'App Text 2ndary 7F868C'
                                            ]
                                          }
                                          rightIconName={
                                            'Entypo/chevron-with-circle-down'
                                          }
                                          style={StyleSheet.applyWidth(
                                            GlobalStyles.PickerStyles(theme)[
                                              'pickerTime'
                                            ].style,
                                            dimensions.width
                                          )}
                                          type={'underline'}
                                          value={pickerValue38}
                                        />
                                      )}
                                    </>
                                  </View>
                                  {/* View Add Friday Time Row */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        width: '10%',
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    <>
                                      {!pickerValue38 ? null : (
                                        <IconButton
                                          onPress={() => {
                                            try {
                                              changeTimeslotsCount(
                                                'sun',
                                                timeslotsSunday,
                                                'increment'
                                              );
                                            } catch (err) {
                                              console.error(err);
                                            }
                                          }}
                                          color={
                                            palettes.App['App Blue#4 007fd4']
                                          }
                                          icon={'FontAwesome/plus-circle'}
                                          size={26}
                                        />
                                      )}
                                    </>
                                  </View>
                                </View>
                              )}
                            </>
                            {/* View Sunday Time Options2 */}
                            <>
                              {!(timeslotsSunday > 1) ? null : (
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      flexDirection: 'row',
                                      justifyContent: 'space-between',
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {/* View Start Time */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      { paddingTop: 8, width: '40%' },
                                      dimensions.width
                                    )}
                                  >
                                    {/* Picker Sun2Start */}
                                    <Picker
                                      autoDismissKeyboard={true}
                                      dropDownBackgroundColor={
                                        theme.colors.background.brand
                                      }
                                      dropDownBorderColor={
                                        theme.colors.border.brand
                                      }
                                      dropDownBorderRadius={8}
                                      dropDownBorderWidth={1}
                                      dropDownTextColor={
                                        theme.colors.text.strong
                                      }
                                      leftIconMode={'inset'}
                                      mode={'native'}
                                      onValueChange={newPickerSun2StartValue => {
                                        try {
                                          setPickerValue39(
                                            newPickerSun2StartValue
                                          );
                                        } catch (err) {
                                          console.error(err);
                                        }
                                      }}
                                      selectedIconColor={
                                        theme.colors.text.strong
                                      }
                                      selectedIconName={'Feather/check'}
                                      selectedIconSize={20}
                                      {...GlobalStyles.PickerStyles(theme)[
                                        'pickerTime'
                                      ].props}
                                      iconSize={16}
                                      options={
                                        Constants['gAlternateScheduleTimes']
                                      }
                                      placeholder={'Available at'}
                                      placeholderTextColor={
                                        palettes.App['App Text 2ndary 7F868C']
                                      }
                                      rightIconName={
                                        'Entypo/chevron-with-circle-down'
                                      }
                                      style={StyleSheet.applyWidth(
                                        GlobalStyles.PickerStyles(theme)[
                                          'pickerTime'
                                        ].style,
                                        dimensions.width
                                      )}
                                      type={'underline'}
                                      value={pickerValue39}
                                    />
                                  </View>
                                  {/* View End Time */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      { paddingTop: 8, width: '40%' },
                                      dimensions.width
                                    )}
                                  >
                                    {/* Picker Sun2End */}
                                    <>
                                      {!pickerValue39 ? null : (
                                        <Picker
                                          autoDismissKeyboard={true}
                                          dropDownBackgroundColor={
                                            theme.colors.background.brand
                                          }
                                          dropDownBorderColor={
                                            theme.colors.border.brand
                                          }
                                          dropDownBorderRadius={8}
                                          dropDownBorderWidth={1}
                                          dropDownTextColor={
                                            theme.colors.text.strong
                                          }
                                          leftIconMode={'inset'}
                                          mode={'native'}
                                          onValueChange={newPickerSun2EndValue => {
                                            try {
                                              setPickerValue40(
                                                newPickerSun2EndValue
                                              );
                                            } catch (err) {
                                              console.error(err);
                                            }
                                          }}
                                          selectedIconColor={
                                            theme.colors.text.strong
                                          }
                                          selectedIconName={'Feather/check'}
                                          selectedIconSize={20}
                                          {...GlobalStyles.PickerStyles(theme)[
                                            'pickerTime'
                                          ].props}
                                          iconSize={16}
                                          options={
                                            Constants['gAlternateScheduleTimes']
                                          }
                                          placeholder={'Until time'}
                                          placeholderTextColor={
                                            palettes.App[
                                              'App Text 2ndary 7F868C'
                                            ]
                                          }
                                          rightIconName={
                                            'Entypo/chevron-with-circle-down'
                                          }
                                          style={StyleSheet.applyWidth(
                                            GlobalStyles.PickerStyles(theme)[
                                              'pickerTime'
                                            ].style,
                                            dimensions.width
                                          )}
                                          type={'underline'}
                                          value={pickerValue40}
                                        />
                                      )}
                                    </>
                                  </View>
                                  {/* View Add Monday Time Row */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        width: '10%',
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    <IconButton
                                      onPress={() => {
                                        try {
                                          changeTimeslotsCount(
                                            'sun',
                                            timeslotsSunday,
                                            'decrement'
                                          );
                                        } catch (err) {
                                          console.error(err);
                                        }
                                      }}
                                      color={
                                        palettes.App[
                                          'App Disabled Button EDEDEF'
                                        ]
                                      }
                                      icon={'FontAwesome/minus-circle'}
                                      size={26}
                                    />
                                  </View>
                                </View>
                              )}
                            </>
                            {/* View Sunday Time Options3 */}
                            <>
                              {!(timeslotsSunday > 2) ? null : (
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      flexDirection: 'row',
                                      justifyContent: 'space-between',
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {/* View Start Time */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      { paddingTop: 8, width: '40%' },
                                      dimensions.width
                                    )}
                                  >
                                    {/* Picker Sun3Start */}
                                    <Picker
                                      autoDismissKeyboard={true}
                                      dropDownBackgroundColor={
                                        theme.colors.background.brand
                                      }
                                      dropDownBorderColor={
                                        theme.colors.border.brand
                                      }
                                      dropDownBorderRadius={8}
                                      dropDownBorderWidth={1}
                                      dropDownTextColor={
                                        theme.colors.text.strong
                                      }
                                      leftIconMode={'inset'}
                                      mode={'native'}
                                      onValueChange={newPickerSun3StartValue => {
                                        try {
                                          setPickerValue41(
                                            newPickerSun3StartValue
                                          );
                                        } catch (err) {
                                          console.error(err);
                                        }
                                      }}
                                      selectedIconColor={
                                        theme.colors.text.strong
                                      }
                                      selectedIconName={'Feather/check'}
                                      selectedIconSize={20}
                                      {...GlobalStyles.PickerStyles(theme)[
                                        'pickerTime'
                                      ].props}
                                      iconSize={16}
                                      options={
                                        Constants['gAlternateScheduleTimes']
                                      }
                                      placeholder={'Available at'}
                                      placeholderTextColor={
                                        palettes.App['App Text 2ndary 7F868C']
                                      }
                                      rightIconName={
                                        'Entypo/chevron-with-circle-down'
                                      }
                                      style={StyleSheet.applyWidth(
                                        GlobalStyles.PickerStyles(theme)[
                                          'pickerTime'
                                        ].style,
                                        dimensions.width
                                      )}
                                      type={'underline'}
                                      value={pickerValue41}
                                    />
                                  </View>
                                  {/* View End Time */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      { paddingTop: 8, width: '40%' },
                                      dimensions.width
                                    )}
                                  >
                                    {/* Picker Sun3End */}
                                    <>
                                      {!pickerValue41 ? null : (
                                        <Picker
                                          autoDismissKeyboard={true}
                                          dropDownBackgroundColor={
                                            theme.colors.background.brand
                                          }
                                          dropDownBorderColor={
                                            theme.colors.border.brand
                                          }
                                          dropDownBorderRadius={8}
                                          dropDownBorderWidth={1}
                                          dropDownTextColor={
                                            theme.colors.text.strong
                                          }
                                          leftIconMode={'inset'}
                                          mode={'native'}
                                          onValueChange={newPickerSun3EndValue => {
                                            try {
                                              setPickerValue42(
                                                newPickerSun3EndValue
                                              );
                                            } catch (err) {
                                              console.error(err);
                                            }
                                          }}
                                          selectedIconColor={
                                            theme.colors.text.strong
                                          }
                                          selectedIconName={'Feather/check'}
                                          selectedIconSize={20}
                                          {...GlobalStyles.PickerStyles(theme)[
                                            'pickerTime'
                                          ].props}
                                          iconSize={16}
                                          options={
                                            Constants['gAlternateScheduleTimes']
                                          }
                                          placeholder={'Until time'}
                                          placeholderTextColor={
                                            palettes.App[
                                              'App Text 2ndary 7F868C'
                                            ]
                                          }
                                          rightIconName={
                                            'Entypo/chevron-with-circle-down'
                                          }
                                          style={StyleSheet.applyWidth(
                                            GlobalStyles.PickerStyles(theme)[
                                              'pickerTime'
                                            ].style,
                                            dimensions.width
                                          )}
                                          type={'underline'}
                                          value={pickerValue42}
                                        />
                                      )}
                                    </>
                                  </View>
                                  {/* View Add Monday Time Row */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        width: '10%',
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    <IconButton
                                      onPress={() => {
                                        try {
                                          changeTimeslotsCount(
                                            'sun',
                                            timeslotsSunday,
                                            'decrement'
                                          );
                                        } catch (err) {
                                          console.error(err);
                                        }
                                      }}
                                      color={
                                        palettes.App[
                                          'App Disabled Button EDEDEF'
                                        ]
                                      }
                                      icon={'FontAwesome/minus-circle'}
                                      size={26}
                                    />
                                  </View>
                                </View>
                              )}
                            </>
                          </View>
                        </View>
                      )}
                    </>
                    {/* View Proposed Start Date Container */}
                    <>
                      {!alternateScheduleExpanded ? null : (
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignContent: 'center',
                              alignSelf: 'center',
                              backgroundColor: palettes.App['Custom Color'],
                              borderRadius: 8,
                              paddingBottom: 6,
                              paddingTop: 6,
                              width: '98%',
                            },
                            dimensions.width
                          )}
                        >
                          {/* View Orange Border Title and Switch */}
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                borderColor:
                                  palettes.App['App Sun Orange F4A100'],
                                borderRadius: 8,
                                borderWidth: 1,
                                paddingBottom: 6,
                                paddingLeft: 9,
                                paddingRight: 9,
                                paddingTop: 6,
                              },
                              dimensions.width
                            )}
                          >
                            {/* Title */}
                            <View>
                              {/* Propsed Start Date */}
                              <Text
                                accessible={true}
                                {...GlobalStyles.TextStyles(theme)['Text']
                                  .props}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.TextStyles(theme)['Text']
                                      .style,
                                    {
                                      color:
                                        palettes.App['App Sun Orange F4A100'],
                                      fontFamily: 'Poppins_500Medium',
                                    }
                                  ),
                                  dimensions.width
                                )}
                              >
                                {'New Proposed Start Date'}
                              </Text>
                            </View>
                            {/* View Switch Row */}
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  flexDirection: 'row',
                                  justifyContent: 'space-between',
                                  paddingBottom: 4,
                                },
                                dimensions.width
                              )}
                            >
                              <View>
                                {/* Same as client */}
                                <>
                                  {switchValue ? null : (
                                    <Text
                                      accessible={true}
                                      {...GlobalStyles.TextStyles(theme)['Text']
                                        .props}
                                      style={StyleSheet.applyWidth(
                                        StyleSheet.compose(
                                          GlobalStyles.TextStyles(theme)['Text']
                                            .style,
                                          {
                                            color:
                                              palettes.App[
                                                'App Sun Orange F4A100'
                                              ],
                                            fontFamily: 'Poppins_400Regular',
                                            fontSize: 12,
                                          }
                                        ),
                                        dimensions.width
                                      )}
                                    >
                                      {'Same as client'}
                                    </Text>
                                  )}
                                </>
                                {/* New start date */}
                                <>
                                  {!switchValue ? null : (
                                    <Text
                                      accessible={true}
                                      {...GlobalStyles.TextStyles(theme)['Text']
                                        .props}
                                      style={StyleSheet.applyWidth(
                                        StyleSheet.compose(
                                          GlobalStyles.TextStyles(theme)['Text']
                                            .style,
                                          {
                                            color:
                                              palettes.App[
                                                'App Sun Orange F4A100'
                                              ],
                                            fontFamily: 'Poppins_400Regular',
                                            fontSize: 12,
                                          }
                                        ),
                                        dimensions.width
                                      )}
                                    >
                                      {'Share alternate start date'}
                                    </Text>
                                  )}
                                </>
                              </View>
                              {/* View 2 */}
                              <View>
                                {/* proposeNewDate */}
                                <Switch
                                  onValueChange={newProposeNewDateValue => {
                                    const switchValue = newProposeNewDateValue;
                                    try {
                                      setSwitchValue(newProposeNewDateValue);
                                    } catch (err) {
                                      console.error(err);
                                    }
                                  }}
                                  activeTrackColor={
                                    palettes.App['App Blue#4 007fd4']
                                  }
                                  value={switchValue}
                                />
                              </View>
                            </View>
                            {/* View Start Date Container */}
                            <>
                              {!switchValue ? null : (
                                <View>
                                  {/* Date Picker - Start Date */}
                                  <DatePicker
                                    autoDismissKeyboard={true}
                                    disabled={false}
                                    hideLabel={false}
                                    label={'Date'}
                                    leftIconMode={'inset'}
                                    mode={'date'}
                                    onDateChange={newDatePickerStartDateValue => {
                                      const date = newDatePickerStartDateValue;
                                      try {
                                        setDatePickerValue(
                                          newDatePickerStartDateValue
                                        );
                                        console.log(
                                          newDatePickerStartDateValue
                                        );
                                      } catch (err) {
                                        console.error(err);
                                      }
                                    }}
                                    date={datePickerValue}
                                    style={StyleSheet.applyWidth(
                                      {
                                        borderColor: '"rgba(0, 0, 0, 0)"',
                                        color:
                                          palettes.App['App Sun Orange F4A100'],
                                        fontFamily: 'Poppins_400Regular',
                                      },
                                      dimensions.width
                                    )}
                                    type={'underline'}
                                  />
                                </View>
                              )}
                            </>
                          </View>
                        </View>
                      )}
                    </>
                  </View>
                )}
              </>
              {/* View Row Button Container */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    marginBottom: 0,
                    marginTop: 20,
                    paddingBottom: 0,
                    width: '100%',
                  },
                  dimensions.width
                )}
              >
                {/* Decline Button Container */}
                <View
                  style={StyleSheet.applyWidth(
                    { alignItems: 'center', width: '48%' },
                    dimensions.width
                  )}
                >
                  {/* Decline Button */}
                  <Button
                    iconPosition={'left'}
                    onPress={() => {
                      try {
                        const preventJobRequest = shouldPreventJobRequest(
                          navigation,
                          Variables
                        );
                        if (preventJobRequest) {
                          return;
                        }
                        setGlobalVariableValue({
                          key: 'showModalDeclineSurvey',
                          value: true,
                        });
                        amplitudeEvents(
                          Variables,
                          'start decline job',
                          {
                            step: '01',
                            funnel: 'decline job',
                            origin: 'open job details screen',
                            category: 'opportunity management',
                          },
                          undefined
                        );
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor: palettes.App['Custom Color'],
                        borderBottomWidth: 1,
                        borderColor: palettes.App['App Red EB3E26'],
                        borderLeftWidth: 1,
                        borderRadius: 50,
                        borderRightWidth: 1,
                        borderTopWidth: 1,
                        color: palettes.App['App Red EB3E26'],
                        fontFamily: 'BebasNeue_400Regular',
                        fontSize: 18,
                        paddingBottom: 14,
                        paddingTop: 14,
                        textAlign: 'center',
                        width: '100%',
                      },
                      dimensions.width
                    )}
                    title={'DECLINE'}
                  />
                </View>
                {/* Request Button Container */}
                <View
                  style={StyleSheet.applyWidth(
                    { alignItems: 'center', width: '48%' },
                    dimensions.width
                  )}
                >
                  {/* Request Disabled */}
                  <>
                    {enableRequestButton ? null : (
                      <Button
                        iconPosition={'left'}
                        style={StyleSheet.applyWidth(
                          {
                            backgroundColor:
                              palettes.App['App Disabled Button EDEDEF'],
                            borderRadius: 50,
                            color:
                              palettes.App['App Disabled Button Text 7F868C'],
                            fontFamily: 'BebasNeue_400Regular',
                            fontSize: 18,
                            paddingBottom: 14,
                            paddingTop: 14,
                            textAlign: 'center',
                            width: '100%',
                          },
                          dimensions.width
                        )}
                        title={'REQUEST'}
                      />
                    )}
                  </>
                  {/* Request Enabled */}
                  <>
                    {!enableRequestButton ? null : (
                      <Button
                        iconPosition={'left'}
                        onPress={() => {
                          try {
                            const preventJobRequest = shouldPreventJobRequest(
                              navigation,
                              Variables
                            );
                            if (preventJobRequest) {
                              return;
                            }
                            setShowSubmitModal(true);
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        style={StyleSheet.applyWidth(
                          {
                            backgroundColor: palettes.App['App Red EB3E26'],
                            borderRadius: 50,
                            fontFamily: 'BebasNeue_400Regular',
                            fontSize: 18,
                            paddingBottom: 14,
                            paddingTop: 14,
                            textAlign: 'center',
                            width: '100%',
                          },
                          dimensions.width
                        )}
                        title={'REQUEST'}
                      />
                    )}
                  </>
                </View>
              </View>
            </View>
          )}
        </>
        {/* Package Status Requested */}
        <>
          {!(packageStatus === 'Requested') ? null : (
            <View>
              {/* Status message */}
              <View
                style={StyleSheet.applyWidth(
                  { marginTop: 20 },
                  dimensions.width
                )}
              >
                <Text
                  accessible={true}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Text'].style,
                      { fontFamily: 'Poppins_600SemiBold' }
                    ),
                    dimensions.width
                  )}
                >
                  {'You requested this package on '}
                  {requestDate}
                  {
                    '. Your request is pending and you may still be matched with this client.'
                  }
                </Text>
              </View>
              {/* Button Container */}
              <View
                style={StyleSheet.applyWidth(
                  { marginTop: 20 },
                  dimensions.width
                )}
              >
                {/* View Other Jobs */}
                <Button
                  iconPosition={'left'}
                  onPress={() => {
                    try {
                      navigation.navigate('JobsScreen');
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  style={StyleSheet.applyWidth(
                    {
                      backgroundColor: palettes.App['App Red EB3E26'],
                      borderRadius: 50,
                      fontFamily: 'BebasNeue_400Regular',
                      fontSize: 18,
                      paddingBottom: 14,
                      paddingTop: 14,
                      textAlign: 'center',
                      width: '100%',
                    },
                    dimensions.width
                  )}
                  title={'View Other Open Jobs'}
                />
              </View>
            </View>
          )}
        </>
        {/* Package Status Matched */}
        <>
          {!(packageStatus === 'Matched') ? null : (
            <View>
              {/* Status message */}
              <View
                style={StyleSheet.applyWidth(
                  { marginTop: 20 },
                  dimensions.width
                )}
              >
                <Text
                  accessible={true}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Text'].style,
                      { fontFamily: 'Poppins_600SemiBold' }
                    ),
                    dimensions.width
                  )}
                >
                  {
                    'Congrats! You are the assigned swim instructor for this package. Why not get a few more?'
                  }
                </Text>
              </View>
              {/* Button Container */}
              <View
                style={StyleSheet.applyWidth(
                  { marginTop: 20 },
                  dimensions.width
                )}
              >
                {/* Request More Clients */}
                <Button
                  iconPosition={'left'}
                  onPress={() => {
                    try {
                      navigation.navigate('JobsScreen');
                      amplitudeEvents(
                        Variables,
                        'view open jobs list',
                        {
                          step: '01',
                          view: 'job unavailable',
                          funnel: 'request job',
                          origin: 'open job details screen',
                          category: 'opportunity management',
                        },
                        undefined
                      );
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  style={StyleSheet.applyWidth(
                    {
                      backgroundColor: palettes.App['App Red EB3E26'],
                      borderRadius: 50,
                      fontFamily: 'BebasNeue_400Regular',
                      fontSize: 18,
                      paddingBottom: 14,
                      paddingTop: 14,
                      textAlign: 'center',
                      width: '100%',
                    },
                    dimensions.width
                  )}
                  title={'Request More Clients'}
                />
              </View>
              {/* Button Container */}
              <View
                style={StyleSheet.applyWidth(
                  { marginTop: 20 },
                  dimensions.width
                )}
              >
                {/* View My Clients */}
                <Button
                  iconPosition={'left'}
                  onPress={() => {
                    try {
                      navigation.navigate('Clients2Screen');
                      amplitudeEvents(
                        Variables,
                        'view client list',
                        {
                          view: 'job unavailable',
                          origin: 'open job details screen',
                          category: 'client management',
                        },
                        undefined
                      );
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  style={StyleSheet.applyWidth(
                    {
                      backgroundColor: palettes.App['Custom Color'],
                      borderColor: palettes.App['App Gold FFA900'],
                      borderRadius: 50,
                      borderWidth: 1,
                      color: palettes.App['App Gold FFA900'],
                      fontFamily: 'BebasNeue_400Regular',
                      fontSize: 18,
                      paddingBottom: 14,
                      paddingTop: 14,
                      textAlign: 'center',
                      width: '100%',
                    },
                    dimensions.width
                  )}
                  title={'View My Clients'}
                />
              </View>
            </View>
          )}
        </>
        {/* Package Status Unavailable */}
        <>
          {!(packageStatus === 'Unavailable') ? null : (
            <View>
              {/* Status message */}
              <View
                style={StyleSheet.applyWidth(
                  { marginTop: 20 },
                  dimensions.width
                )}
              >
                <Text
                  accessible={true}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Text'].style,
                      { fontFamily: 'Poppins_600SemiBold' }
                    ),
                    dimensions.width
                  )}
                >
                  {
                    "Oh, buoy! This swim package has swam away and is unavailable. Don't worry, check other open jobs near you now!"
                  }
                </Text>
              </View>
              {/* Button Container */}
              <View
                style={StyleSheet.applyWidth(
                  { marginTop: 20 },
                  dimensions.width
                )}
              >
                {/* View More Jobs */}
                <Button
                  iconPosition={'left'}
                  onPress={() => {
                    try {
                      navigation.navigate('JobsScreen');
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  style={StyleSheet.applyWidth(
                    {
                      backgroundColor: palettes.App['App Red EB3E26'],
                      borderRadius: 50,
                      fontFamily: 'BebasNeue_400Regular',
                      fontSize: 18,
                      paddingBottom: 14,
                      paddingTop: 14,
                      textAlign: 'center',
                      width: '100%',
                    },
                    dimensions.width
                  )}
                  title={'View More Open Jobs'}
                />
              </View>
            </View>
          )}
        </>
        <Spacer bottom={34} left={0} right={0} top={0} />
      </View>
      {/* Modal Submit Request */}
      <>
        {!showSubmitModal ? null : (
          <Modal
            supportedOrientations={['portrait', 'landscape']}
            animationType={'slide'}
            presentationStyle={'overFullScreen'}
            transparent={true}
          >
            <KeyboardAvoidingView
              behavior={'padding'}
              enabled={true}
              keyboardVerticalOffset={0}
              style={StyleSheet.applyWidth(
                {
                  backgroundColor: palettes.App['App Transparent'],
                  bottom: 0,
                  paddingTop: '125%',
                  position: 'absolute',
                  width: '100%',
                },
                dimensions.width
              )}
            >
              {/* View Card */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    backgroundColor: palettes.App['Custom Color'],
                    borderTopLeftRadius: 16,
                    borderTopRightRadius: 16,
                    bottom: 0,
                    paddingLeft: '5.35%',
                    paddingRight: '5.35%',
                    paddingTop: 13,
                    width: '100%',
                  },
                  dimensions.width
                )}
              >
                {/* View Modal Close Slide */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      marginBottom: 6,
                    },
                    dimensions.width
                  )}
                >
                  {/* View Spacer */}
                  <View
                    style={StyleSheet.applyWidth(
                      { width: 40 },
                      dimensions.width
                    )}
                  />
                  {/* View Slide Close */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor:
                          palettes.App['App Disabled Button EDEDEF'],
                        borderRadius: 40,
                        height: 3,
                        width: 40,
                      },
                      dimensions.width
                    )}
                  >
                    <Touchable
                      onPress={() => {
                        try {
                          setShowSubmitModal(false);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      style={StyleSheet.applyWidth(
                        { height: '100%', width: '100%' },
                        dimensions.width
                      )}
                    />
                  </View>
                  {/* View Close Button */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor:
                          palettes.App['App Disabled Button EDEDEF'],
                        borderRadius: 40,
                        paddingBottom: 4,
                        paddingLeft: 6,
                        paddingRight: 6,
                        paddingTop: 4,
                      },
                      dimensions.width
                    )}
                  >
                    <Touchable
                      onPress={() => {
                        try {
                          setShowSubmitModal(false);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                    >
                      {/* Close */}
                      <Text
                        accessible={true}
                        style={StyleSheet.applyWidth(
                          {
                            color: palettes.App['App Text 2ndary 7F868C'],
                            fontFamily: 'Poppins_400Regular',
                            fontSize: 12,
                          },
                          dimensions.width
                        )}
                      >
                        {'close x'}
                      </Text>
                    </Touchable>
                  </View>
                </View>
                {/* View Hide While Request is Uploading */}
                <>
                  {Constants['is_loading'] ? null : (
                    <View>
                      {/* View Title */}
                      <View
                        style={StyleSheet.applyWidth(
                          { paddingBottom: 24 },
                          dimensions.width
                        )}
                      >
                        {/* Title */}
                        <Text
                          accessible={true}
                          style={StyleSheet.applyWidth(
                            {
                              color: palettes.App['App Black 191F32'],
                              fontFamily: 'Poppins_500Medium',
                              fontSize: 16,
                            },
                            dimensions.width
                          )}
                        >
                          {
                            "Any additional notes about schedule or why you're a good fit?"
                          }
                        </Text>
                      </View>
                      {/* View Text Area Container */}
                      <View
                        style={StyleSheet.applyWidth(
                          { height: 145, paddingBottom: 16 },
                          dimensions.width
                        )}
                      >
                        <TextInput
                          autoCorrect={true}
                          changeTextDelay={500}
                          multiline={true}
                          numberOfLines={4}
                          onChangeText={newTextAreaValue => {
                            try {
                              setTextReasonWhy(newTextAreaValue);
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          textAlignVertical={'top'}
                          webShowOutline={true}
                          editable={true}
                          placeholder={'Enter your short message'}
                          style={StyleSheet.applyWidth(
                            {
                              borderBottomWidth: 1,
                              borderColor:
                                palettes.App['App Grey Inputs E2E2E2'],
                              borderLeftWidth: 1,
                              borderRadius: 8,
                              borderRightWidth: 1,
                              borderTopWidth: 1,
                              color: palettes.App['App Black 191F32'],
                              fontFamily: 'Poppins_400Regular',
                              height: '100%',
                              paddingBottom: 16,
                              paddingLeft: 16,
                              paddingRight: 16,
                              paddingTop: 16,
                              width: '100%',
                            },
                            dimensions.width
                          )}
                        />
                      </View>
                      {/* View Instructions */}
                      <View
                        {...GlobalStyles.ViewStyles(theme)['field instructions']
                          .props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.ViewStyles(theme)['field instructions']
                            .style,
                          dimensions.width
                        )}
                      >
                        {/* View Icon Holder */}
                        <View
                          style={StyleSheet.applyWidth(
                            { width: '5%' },
                            dimensions.width
                          )}
                        >
                          <Icon
                            color={palettes.App['App Text 2ndary 7F868C']}
                            name={'Feather/info'}
                            size={16}
                            style={StyleSheet.applyWidth(
                              { height: 16, width: 16 },
                              dimensions.width
                            )}
                          />
                        </View>
                        {/* View Instructions Text */}
                        <View
                          style={StyleSheet.applyWidth(
                            { width: '93%' },
                            dimensions.width
                          )}
                        >
                          <Text
                            accessible={true}
                            style={StyleSheet.applyWidth(
                              {
                                color: palettes.App['App Text 2ndary 7F868C'],
                                fontFamily: 'Poppins_400Regular',
                                fontSize: 12,
                              },
                              dimensions.width
                            )}
                          >
                            {
                              'This field is optional but could help improve your chance to get matched with this client.'
                            }
                          </Text>
                        </View>
                      </View>
                    </View>
                  )}
                </>
                {/* View Show While Request Uploading */}
                <>
                  {!Constants['is_loading'] ? null : (
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          borderRadius: 8,
                          height: 200,
                          marginBottom: 16,
                          overflow: 'hidden',
                          width: '97%',
                        },
                        dimensions.width
                      )}
                    >
                      {/* View Title */}
                      <View
                        style={StyleSheet.applyWidth(
                          { paddingBottom: 24 },
                          dimensions.width
                        )}
                      >
                        {/* Title */}
                        <Text
                          accessible={true}
                          style={StyleSheet.applyWidth(
                            {
                              color: palettes.App['App Black 191F32'],
                              fontFamily: 'Poppins_500Medium',
                              fontSize: 16,
                            },
                            dimensions.width
                          )}
                        >
                          {'Sending your request!'}
                        </Text>
                      </View>

                      <ImageBackground
                        resizeMode={'cover'}
                        source={{
                          uri: 'https://thumbs.gfycat.com/WickedAdventurousFairyfly-size_restricted.gif',
                        }}
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            height: '100%',
                            width: '100%',
                          },
                          dimensions.width
                        )}
                      >
                        {/* View Activity Holder */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: 'center',
                              alignSelf: 'center',
                              height: '100%',
                              paddingTop: 50,
                              width: '100%',
                            },
                            dimensions.width
                          )}
                        >
                          <ActivityIndicator
                            animating={true}
                            hidesWhenStopped={true}
                            color={palettes.App['Custom Color']}
                            size={'large'}
                            style={StyleSheet.applyWidth(
                              { height: 36, width: 36 },
                              dimensions.width
                            )}
                          />
                        </View>
                      </ImageBackground>
                    </View>
                  )}
                </>
                {/* View Request Button */}
                <View
                  style={StyleSheet.applyWidth(
                    { alignItems: 'center', marginBottom: 32, width: '100%' },
                    dimensions.width
                  )}
                >
                  {/* Submit Request Disabled */}
                  <>
                    {!falseValue ? null : (
                      <Button
                        iconPosition={'left'}
                        style={StyleSheet.applyWidth(
                          {
                            backgroundColor:
                              palettes.App['App Disabled Button EDEDEF'],
                            borderRadius: 50,
                            color:
                              palettes.App['App Disabled Button Text 7F868C'],
                            fontFamily: 'BebasNeue_400Regular',
                            fontSize: 18,
                            paddingBottom: 14,
                            paddingTop: 14,
                            textAlign: 'center',
                            width: '100%',
                          },
                          dimensions.width
                        )}
                        title={'Submit Request'}
                      />
                    )}
                  </>
                  {/* Submit Request Enabled */}
                  <Button
                    iconPosition={'left'}
                    onPress={() => {
                      const handler = async () => {
                        try {
                          await sendPackageRequest(
                            Variables,
                            setGlobalVariableValue
                          );
                          amplitudeEvents(
                            Variables,
                            'request job submit',
                            {
                              step: '03',
                              funnel: 'request job',
                              origin: 'open job details screen',
                              category: 'opportunity management',
                            },
                            agreedToClientSchedule
                          );
                        } catch (err) {
                          console.error(err);
                        }
                      };
                      handler();
                    }}
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor: palettes.App['App Red EB3E26'],
                        borderRadius: 50,
                        fontFamily: 'BebasNeue_400Regular',
                        fontSize: 18,
                        paddingBottom: 14,
                        paddingTop: 14,
                        textAlign: 'center',
                        width: '100%',
                      },
                      dimensions.width
                    )}
                    title={'Submit Request'}
                  />
                  {/* View Activity Indicator */}
                  <>
                    {!Constants['is_loading'] ? null : (
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            backgroundColor: palettes.App['App Red EB3E26'],
                            borderRadius: 50,
                            marginTop: -50,
                            opacity: 0.8,
                            paddingBottom: 7,
                            paddingTop: 7,
                            width: '100%',
                          },
                          dimensions.width
                        )}
                      >
                        <ActivityIndicator
                          animating={true}
                          hidesWhenStopped={true}
                          size={'small'}
                          color={palettes.App['Custom Color']}
                          style={StyleSheet.applyWidth(
                            { height: 36, width: 36 },
                            dimensions.width
                          )}
                        />
                      </View>
                    )}
                  </>
                </View>
                {/* Spacer Move Modal Up While Sending Request */}
                <>
                  {!Constants['is_loading'] ? null : (
                    <Spacer left={8} right={8} bottom={135} top={135} />
                  )}
                </>
              </View>
            </KeyboardAvoidingView>
          </Modal>
        )}
      </>
      {/* Modal Request Success */}
      <>
        {!showModalRequestSuccess ? null : (
          <Modal
            supportedOrientations={['portrait', 'landscape']}
            transparent={false}
            animationType={'slide'}
            presentationStyle={'overFullScreen'}
          >
            {/* View Card */}
            <View
              style={StyleSheet.applyWidth(
                {
                  backgroundColor: palettes.App['Custom Color'],
                  borderTopLeftRadius: 16,
                  borderTopRightRadius: 16,
                  bottom: 0,
                  paddingLeft: '5.35%',
                  paddingRight: '5.35%',
                  paddingTop: 30,
                },
                dimensions.width
              )}
            >
              {/* View Modal Close Slide */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    marginBottom: 6,
                  },
                  dimensions.width
                )}
              >
                {/* View Spacer */}
                <View
                  style={StyleSheet.applyWidth({ width: 40 }, dimensions.width)}
                />
                {/* View Slide Close */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      backgroundColor: '"rgba(0, 0, 0, 0)"',
                      borderRadius: 40,
                      height: 3,
                      width: 40,
                    },
                    dimensions.width
                  )}
                >
                  <Touchable
                    onPress={() => {
                      const handler = async () => {
                        try {
                          await closeModalRequestSuccess(
                            Variables,
                            setGlobalVariableValue
                          );
                          navigation.navigate('JobsScreen');
                        } catch (err) {
                          console.error(err);
                        }
                      };
                      handler();
                    }}
                    style={StyleSheet.applyWidth(
                      { height: '100%', width: '100%' },
                      dimensions.width
                    )}
                  />
                </View>
                {/* View Close Button */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      backgroundColor:
                        palettes.App['App Disabled Button EDEDEF'],
                      borderRadius: 40,
                      paddingBottom: 4,
                      paddingLeft: 6,
                      paddingRight: 6,
                      paddingTop: 4,
                    },
                    dimensions.width
                  )}
                >
                  <Touchable
                    onPress={() => {
                      const handler = async () => {
                        try {
                          await closeModalRequestSuccess(
                            Variables,
                            setGlobalVariableValue
                          );
                          navigation.navigate('JobsScreen');
                        } catch (err) {
                          console.error(err);
                        }
                      };
                      handler();
                    }}
                  >
                    {/* Close */}
                    <Text
                      accessible={true}
                      style={StyleSheet.applyWidth(
                        {
                          color: palettes.App['App Text 2ndary 7F868C'],
                          fontFamily: 'Poppins_400Regular',
                          fontSize: 12,
                        },
                        dimensions.width
                      )}
                    >
                      {'close x'}
                    </Text>
                  </Touchable>
                </View>
              </View>
              {/* View Title */}
              <View
                style={StyleSheet.applyWidth(
                  { paddingBottom: 24 },
                  dimensions.width
                )}
              >
                {/* Title */}
                <Text
                  accessible={true}
                  style={StyleSheet.applyWidth(
                    {
                      color: palettes.App['App Black 191F32'],
                      fontFamily: 'Poppins_500Medium',
                      fontSize: 16,
                    },
                    dimensions.width
                  )}
                >
                  {Constants['sendRequestPackageResponse']}
                  {
                    '. Great job! Request or decline more packages near you now.'
                  }
                </Text>
              </View>
              {/* View Request More Button */}
              <View
                style={StyleSheet.applyWidth(
                  { alignItems: 'center', marginBottom: 34, width: '100%' },
                  dimensions.width
                )}
              >
                {/* See more open jobs */}
                <Button
                  iconPosition={'left'}
                  onPress={() => {
                    const handler = async () => {
                      try {
                        await closeModalRequestSuccess(
                          Variables,
                          setGlobalVariableValue
                        );
                        navigation.navigate('JobsScreen');
                        amplitudeEvents(
                          Variables,
                          'view open jobs list',
                          {
                            step: '01',
                            view: 'request modal success',
                            funnel: 'request job',
                            origin: 'open job details screen',
                            category: 'opportunity management',
                          },
                          undefined
                        );
                      } catch (err) {
                        console.error(err);
                      }
                    };
                    handler();
                  }}
                  style={StyleSheet.applyWidth(
                    {
                      backgroundColor: palettes.App['App Red EB3E26'],
                      borderRadius: 50,
                      fontFamily: 'BebasNeue_400Regular',
                      fontSize: 18,
                      paddingBottom: 14,
                      paddingTop: 14,
                      textAlign: 'center',
                      width: '100%',
                    },
                    dimensions.width
                  )}
                  title={'Request more packages'}
                />
                {/* View Activity Indicator */}
                <>
                  {!Constants['is_loading'] ? null : (
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          backgroundColor: palettes.App['App Red EB3E26'],
                          borderRadius: 50,
                          marginTop: -52,
                          opacity: 0.8,
                          paddingBottom: 7,
                          paddingTop: 7,
                          width: '100%',
                        },
                        dimensions.width
                      )}
                    >
                      <>
                        {!Constants['is_loading'] ? null : (
                          <ActivityIndicator
                            animating={true}
                            hidesWhenStopped={true}
                            size={'small'}
                            color={palettes.App['Custom Color']}
                            style={StyleSheet.applyWidth(
                              { height: 36, width: 36 },
                              dimensions.width
                            )}
                          />
                        )}
                      </>
                    </View>
                  )}
                </>
              </View>
            </View>
          </Modal>
        )}
      </>
      {/* Modal Decline Survey */}
      <>
        {!Constants['showModalDeclineSurvey'] ? null : (
          <Modal
            supportedOrientations={['portrait', 'landscape']}
            animationType={'slide'}
            presentationStyle={'overFullScreen'}
            transparent={true}
          >
            <KeyboardAvoidingView
              behavior={'padding'}
              enabled={true}
              keyboardVerticalOffset={0}
              {...GlobalStyles.KeyboardAvoidingViewStyles(theme)[
                'modalBackground'
              ].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.KeyboardAvoidingViewStyles(theme)[
                    'modalBackground'
                  ].style,
                  { width: '100%' }
                ),
                dimensions.width
              )}
            >
              {/* View Card */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    backgroundColor: palettes.App['Custom Color'],
                    borderTopLeftRadius: 16,
                    borderTopRightRadius: 16,
                    bottom: 0,
                    paddingLeft: '5.35%',
                    paddingRight: '5.35%',
                    paddingTop: 13,
                  },
                  dimensions.width
                )}
              >
                {/* View Modal Close Slide */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      marginBottom: 6,
                    },
                    dimensions.width
                  )}
                >
                  {/* View Spacer */}
                  <View
                    style={StyleSheet.applyWidth(
                      { width: 40 },
                      dimensions.width
                    )}
                  />
                  {/* View Slide Close */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor:
                          palettes.App['App Disabled Button EDEDEF'],
                        borderRadius: 40,
                        height: 3,
                        width: 40,
                      },
                      dimensions.width
                    )}
                  >
                    <Touchable
                      onPress={() => {
                        try {
                          setGlobalVariableValue({
                            key: 'showModalDeclineSurvey',
                            value: false,
                          });
                          setRadioButtonGroupValue(Constants['empty']);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      style={StyleSheet.applyWidth(
                        { height: '100%', width: '100%' },
                        dimensions.width
                      )}
                    />
                  </View>
                  {/* View Close Button */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor:
                          palettes.App['App Disabled Button EDEDEF'],
                        borderRadius: 40,
                        paddingBottom: 4,
                        paddingLeft: 6,
                        paddingRight: 6,
                        paddingTop: 4,
                      },
                      dimensions.width
                    )}
                  >
                    <Touchable
                      onPress={() => {
                        try {
                          setRadioButtonGroupValue(Constants['empty']);
                          setGlobalVariableValue({
                            key: 'showModalDeclineSurvey',
                            value: false,
                          });
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                    >
                      {/* Close */}
                      <Text
                        accessible={true}
                        style={StyleSheet.applyWidth(
                          {
                            color: palettes.App['App Text 2ndary 7F868C'],
                            fontFamily: 'Poppins_400Regular',
                            fontSize: 12,
                          },
                          dimensions.width
                        )}
                      >
                        {'close x'}
                      </Text>
                    </Touchable>
                  </View>
                </View>
                {/* View Decline Questions */}
                <View>
                  {/* View Title */}
                  <View
                    style={StyleSheet.applyWidth(
                      { paddingBottom: 24 },
                      dimensions.width
                    )}
                  >
                    {/* Title */}
                    <Text
                      accessible={true}
                      style={StyleSheet.applyWidth(
                        {
                          color: palettes.App['App Black 191F32'],
                          fontFamily: 'Poppins_500Medium',
                          fontSize: 16,
                        },
                        dimensions.width
                      )}
                    >
                      {
                        'What is the main reason you are declining this package?'
                      }
                    </Text>
                  </View>
                  {/* View Questionnaire Container */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignContent: 'flex-start',
                        marginBottom: 20,
                        marginTop: -15,
                      },
                      dimensions.width
                    )}
                  >
                    <RadioButtonGroup
                      onValueChange={newRadioButtonGroupValue => {
                        try {
                          setRadioButtonGroupValue(newRadioButtonGroupValue);
                          amplitudeEvents(
                            Variables,
                            'select decline reason',
                            {
                              step: '02',
                              funnel: 'decline job',
                              origin: 'open job details screen',
                              category: 'opportunity management',
                            },
                            newRadioButtonGroupValue
                          );
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      style={StyleSheet.applyWidth(
                        { marginLeft: -20 },
                        dimensions.width
                      )}
                    >
                      {/* Schedule row */}
                      <View
                        style={StyleSheet.applyWidth(
                          { alignItems: 'center', flexDirection: 'row' },
                          dimensions.width
                        )}
                      >
                        {/* Radio Container */}
                        <View
                          style={StyleSheet.applyWidth(
                            { marginRight: '-85%', width: '100%', zIndex: 2 },
                            dimensions.width
                          )}
                        >
                          <RadioButtonRow
                            color={palettes.App['App Blue#4 007fd4']}
                            direction={'row-reverse'}
                            label={Constants['empty']}
                            style={StyleSheet.applyWidth(
                              { marginBottom: 0 },
                              dimensions.width
                            )}
                            unselectedColor={palettes.App['App Black 191F32']}
                            value={'schedule'}
                          />
                        </View>
                        {/* Reason */}
                        <View>
                          {/* Schedule */}
                          <Text
                            accessible={true}
                            {...GlobalStyles.TextStyles(theme)['primary14']
                              .props}
                            style={StyleSheet.applyWidth(
                              GlobalStyles.TextStyles(theme)['primary14'].style,
                              dimensions.width
                            )}
                          >
                            {"Schedule doesn't work for me"}
                          </Text>
                        </View>
                      </View>
                      {/* Prompt Suggest Alternate Sched */}
                      <>
                        {!(radioButtonGroupValue === 'schedule') ? null : (
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                backgroundColor:
                                  palettes.App['App Gold #FFC400'],
                                borderRadius: 9,
                                marginBottom: 16,
                                marginLeft: '5.35%',
                                paddingBottom: 9,
                                paddingLeft: '9.1%',
                                paddingRight: '9.1%',
                                paddingTop: 9,
                              },
                              dimensions.width
                            )}
                          >
                            <View
                              style={StyleSheet.applyWidth(
                                { paddingBottom: 6 },
                                dimensions.width
                              )}
                            >
                              {/* Request with alt sched */}
                              <Text
                                accessible={true}
                                style={StyleSheet.applyWidth(
                                  {
                                    color: theme.colors.text.strong,
                                    fontFamily: 'Poppins_400Regular',
                                  },
                                  dimensions.width
                                )}
                              >
                                {
                                  'Optional: Request this package with an alternate schedule. Clients are often flexible to accommodate the right instructor.'
                                }
                              </Text>
                            </View>
                          </View>
                        )}
                      </>
                      {/* Not accepting clients */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            flexDirection: 'row',
                            marginTop: -15,
                          },
                          dimensions.width
                        )}
                      >
                        {/* Radio Container */}
                        <View
                          style={StyleSheet.applyWidth(
                            { marginRight: '-85%', width: '100%', zIndex: 2 },
                            dimensions.width
                          )}
                        >
                          <RadioButtonRow
                            color={palettes.App['App Blue#4 007fd4']}
                            direction={'row-reverse'}
                            label={Constants['empty']}
                            unselectedColor={palettes.App['App Black 191F32']}
                            value={'not accepting clients'}
                          />
                        </View>
                        {/* Reason */}
                        <View>
                          {/* Not accepting clients */}
                          <Text
                            accessible={true}
                            {...GlobalStyles.TextStyles(theme)['primary14']
                              .props}
                            style={StyleSheet.applyWidth(
                              GlobalStyles.TextStyles(theme)['primary14'].style,
                              dimensions.width
                            )}
                          >
                            {"I'm not currently accepting new clients"}
                          </Text>
                        </View>
                      </View>
                      {/* Distance */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            flexDirection: 'row',
                            marginTop: -15,
                          },
                          dimensions.width
                        )}
                      >
                        {/* Radio Container */}
                        <View
                          style={StyleSheet.applyWidth(
                            { marginRight: '-85%', width: '100%', zIndex: 2 },
                            dimensions.width
                          )}
                        >
                          <RadioButtonRow
                            color={palettes.App['App Blue#4 007fd4']}
                            direction={'row-reverse'}
                            label={Constants['empty']}
                            unselectedColor={palettes.App['App Black 191F32']}
                            value={'distance'}
                          />
                        </View>
                        {/* Reason */}
                        <View>
                          {/* Distance */}
                          <Text
                            accessible={true}
                            {...GlobalStyles.TextStyles(theme)['primary14']
                              .props}
                            style={StyleSheet.applyWidth(
                              GlobalStyles.TextStyles(theme)['primary14'].style,
                              dimensions.width
                            )}
                          >
                            {"It's too far"}
                          </Text>
                        </View>
                      </View>
                      {/* Pay */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            flexDirection: 'row',
                            marginTop: -15,
                          },
                          dimensions.width
                        )}
                      >
                        {/* Radio Container */}
                        <View
                          style={StyleSheet.applyWidth(
                            { marginRight: '-85%', width: '100%', zIndex: 2 },
                            dimensions.width
                          )}
                        >
                          <RadioButtonRow
                            color={palettes.App['App Blue#4 007fd4']}
                            direction={'row-reverse'}
                            label={Constants['empty']}
                            unselectedColor={palettes.App['App Black 191F32']}
                            value={'pay'}
                          />
                        </View>
                        {/* Reason */}
                        <View>
                          {/* Pay */}
                          <Text
                            accessible={true}
                            {...GlobalStyles.TextStyles(theme)['primary14']
                              .props}
                            style={StyleSheet.applyWidth(
                              GlobalStyles.TextStyles(theme)['primary14'].style,
                              dimensions.width
                            )}
                          >
                            {'Pay is not enough'}
                          </Text>
                        </View>
                      </View>
                      {/* Request Amount Block */}
                      <>
                        {!(radioButtonGroupValue === 'pay') ? null : (
                          <View
                            {...GlobalStyles.ViewStyles(theme)[
                              'requested payment block'
                            ].props}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.ViewStyles(theme)[
                                  'requested payment block'
                                ].style,
                                {
                                  borderRadius: 9,
                                  marginBottom: 16,
                                  paddingBottom: 9,
                                  paddingLeft: '9.1%',
                                  paddingRight: '9.1%',
                                  paddingTop: 9,
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            <View
                              style={StyleSheet.applyWidth(
                                { paddingBottom: 6 },
                                dimensions.width
                              )}
                            >
                              {/* What pay would you accept */}
                              <Text
                                accessible={true}
                                style={StyleSheet.applyWidth(
                                  {
                                    color: theme.colors.text.strong,
                                    fontFamily: 'Poppins_400Regular',
                                  },
                                  dimensions.width
                                )}
                              >
                                {
                                  'Optional: what pay would you accept to teach this package?'
                                }
                              </Text>
                            </View>
                            {/* View Negotiated pay */}
                            <View
                              style={StyleSheet.applyWidth(
                                { flexDirection: 'column', paddingBottom: 6 },
                                dimensions.width
                              )}
                            >
                              {/* View Row */}
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    alignItems: 'center',
                                    flexDirection: 'row',
                                  },
                                  dimensions.width
                                )}
                              >
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      backgroundColor:
                                        palettes.App['Custom Color'],
                                      borderBottomLeftRadius: 8,
                                      borderTopLeftRadius: 8,
                                      paddingBottom: 8,
                                      paddingLeft: 8,
                                      paddingRight: 8,
                                      paddingTop: 8,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {/* $ */}
                                  <Text
                                    accessible={true}
                                    style={StyleSheet.applyWidth(
                                      {
                                        color: theme.colors.text.strong,
                                        fontFamily: 'Poppins_400Regular',
                                        fontSize: 14,
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    {'$'}
                                  </Text>
                                </View>

                                <View>
                                  {/* NegotiatedPay */}
                                  <NumberInput
                                    changeTextDelay={500}
                                    onChangeText={newNegotiatedPayValue => {
                                      try {
                                        setInstructorBid(newNegotiatedPayValue);
                                      } catch (err) {
                                        console.error(err);
                                      }
                                    }}
                                    webShowOutline={true}
                                    clearTextOnFocus={true}
                                    editable={true}
                                    maxLength={4}
                                    placeholder={'Enter bid'}
                                    placeholderTextColor={
                                      palettes.App[
                                        'App Disabled Button Text 7F868C'
                                      ]
                                    }
                                    style={StyleSheet.applyWidth(
                                      {
                                        backgroundColor:
                                          palettes.App['Custom Color'],
                                        borderBottomRightRadius: 8,
                                        borderBottomWidth: 1,
                                        borderColor: theme.colors.border.brand,
                                        borderLeftWidth: 1,
                                        borderRightWidth: 1,
                                        borderTopRightRadius: 8,
                                        borderTopWidth: 1,
                                        fontFamily: 'Poppins_400Regular',
                                        paddingBottom: 8,
                                        paddingLeft: 8,
                                        paddingRight: 8,
                                        paddingTop: 8,
                                        textAlign: 'right',
                                        width: 100,
                                      },
                                      dimensions.width
                                    )}
                                    value={instructorBid}
                                  />
                                </View>
                              </View>
                            </View>
                          </View>
                        )}
                      </>
                      {/* Student */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            flexDirection: 'row',
                            marginTop: -15,
                          },
                          dimensions.width
                        )}
                      >
                        {/* Radio Container */}
                        <View
                          style={StyleSheet.applyWidth(
                            { marginRight: '-85%', width: '100%', zIndex: 2 },
                            dimensions.width
                          )}
                        >
                          <RadioButtonRow
                            color={palettes.App['App Blue#4 007fd4']}
                            direction={'row-reverse'}
                            label={Constants['empty']}
                            unselectedColor={palettes.App['App Black 191F32']}
                            value={'student'}
                          />
                        </View>
                        {/* Reason */}
                        <View>
                          {/* Student */}
                          <Text
                            accessible={true}
                            {...GlobalStyles.TextStyles(theme)['primary14']
                              .props}
                            style={StyleSheet.applyWidth(
                              GlobalStyles.TextStyles(theme)['primary14'].style,
                              dimensions.width
                            )}
                          >
                            {'Student level/age not for me'}
                          </Text>
                        </View>
                      </View>
                      {/* Lesson length */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            flexDirection: 'row',
                            marginTop: -15,
                          },
                          dimensions.width
                        )}
                      >
                        {/* Radio Container */}
                        <View
                          style={StyleSheet.applyWidth(
                            { marginRight: '-85%', width: '100%', zIndex: 2 },
                            dimensions.width
                          )}
                        >
                          <RadioButtonRow
                            color={palettes.App['App Blue#4 007fd4']}
                            direction={'row-reverse'}
                            label={Constants['empty']}
                            unselectedColor={palettes.App['App Black 191F32']}
                            value={'lesson-length'}
                          />
                        </View>
                        {/* Reason */}
                        <View>
                          {/* Student */}
                          <Text
                            accessible={true}
                            {...GlobalStyles.TextStyles(theme)['primary14']
                              .props}
                            style={StyleSheet.applyWidth(
                              GlobalStyles.TextStyles(theme)['primary14'].style,
                              dimensions.width
                            )}
                          >
                            {'Lessons are too long or too short'}
                          </Text>
                        </View>
                      </View>
                    </RadioButtonGroup>
                  </View>
                  {/* View Button Request W/ Alt Sched */}
                  <>
                    {!(radioButtonGroupValue === 'schedule') ? null : (
                      <View
                        style={StyleSheet.applyWidth(
                          { marginBottom: 10 },
                          dimensions.width
                        )}
                      >
                        {/* Request With Alt Sched */}
                        <Button
                          iconPosition={'left'}
                          onPress={() => {
                            try {
                              setGlobalVariableValue({
                                key: 'showModalDeclineSurvey',
                                value: false,
                              });
                              setCheckboxValue2(true);
                              setAlternateScheduleRequested(true);
                              setCheckboxValue(false);
                              setAgreedToClientSchedule(false);
                              setRadioButtonGroupValue(Constants['empty']);
                              amplitudeEvents(
                                Variables,
                                'view open job details',
                                {
                                  step: '02',
                                  funnel: 'request job',
                                  origin: 'open job details screen',
                                  source: 'decline survey',
                                  category: 'opportunity management',
                                  exitPath: 'alternate schedule',
                                },
                                undefined
                              );
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.ButtonStyles(theme)['Button'].style,
                              {
                                backgroundColor:
                                  palettes.App['App Gold #FFC400'],
                                borderColor:
                                  palettes.App['App Sun Orange F4A100'],
                                borderRadius: 50,
                                borderWidth: 1,
                                color: palettes.App['App Black 191F32'],
                                fontFamily: 'BebasNeue_400Regular',
                                fontSize: 18,
                                paddingBottom: 14,
                                paddingTop: 14,
                                width: '100%',
                              }
                            ),
                            dimensions.width
                          )}
                          title={'Request With Alt Schedule'}
                        />
                      </View>
                    )}
                  </>
                  {/* View Button Request With Higher Pay */}
                  <>
                    {!(radioButtonGroupValue === 'pay') ? null : (
                      <View
                        style={StyleSheet.applyWidth(
                          { marginBottom: 10 },
                          dimensions.width
                        )}
                      >
                        {/* Request With Higher Pay */}
                        <>
                          {!(instructorBid > 100) ? null : (
                            <Button
                              iconPosition={'left'}
                              onPress={() => {
                                try {
                                  setGlobalVariableValue({
                                    key: 'showModalDeclineSurvey',
                                    value: false,
                                  });
                                  amplitudeEvents(
                                    Variables,
                                    'view open job details',
                                    {
                                      step: '02',
                                      funnel: 'request job',
                                      origin: 'open job details screen',
                                      source: 'decline survey',
                                      category: 'opportunity management',
                                      exitPath: 'job bid',
                                    },
                                    undefined
                                  );
                                } catch (err) {
                                  console.error(err);
                                }
                              }}
                              {...GlobalStyles.ButtonStyles(theme)['Button']
                                .props}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.ButtonStyles(theme)['Button']
                                    .style,
                                  {
                                    backgroundColor:
                                      palettes.App['App Gold #FFC400'],
                                    borderColor:
                                      palettes.App['App Sun Orange F4A100'],
                                    borderRadius: 50,
                                    borderWidth: 1,
                                    color: palettes.App['App Black 191F32'],
                                    fontFamily: 'BebasNeue_400Regular',
                                    fontSize: 18,
                                    paddingBottom: 14,
                                    paddingTop: 14,
                                    width: '100%',
                                  }
                                ),
                                dimensions.width
                              )}
                              title={'Request With Higher Pay'}
                            />
                          )}
                        </>
                      </View>
                    )}
                  </>
                  {/* View Button Decline */}
                  <View
                    style={StyleSheet.applyWidth(
                      { alignItems: 'center', marginBottom: 32, width: '100%' },
                      dimensions.width
                    )}
                  >
                    {/* Decline Package Disabled */}
                    <>
                      {radioButtonGroupValue ? null : (
                        <Button
                          iconPosition={'left'}
                          onPress={() => {
                            try {
                              alertDeclineReasonRequired();
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          style={StyleSheet.applyWidth(
                            {
                              backgroundColor:
                                palettes.App['App Disabled Button EDEDEF'],
                              borderRadius: 50,
                              color:
                                palettes.App['App Disabled Button Text 7F868C'],
                              fontFamily: 'BebasNeue_400Regular',
                              fontSize: 18,
                              paddingBottom: 14,
                              paddingTop: 14,
                              textAlign: 'center',
                              width: '100%',
                            },
                            dimensions.width
                          )}
                          title={'Decline Package'}
                        />
                      )}
                    </>
                    {/* Decline Package Enabled */}
                    <>
                      {!radioButtonGroupValue ? null : (
                        <Button
                          iconPosition={'left'}
                          onPress={() => {
                            const handler = async () => {
                              try {
                                await sendDeclinePackageNotice(
                                  Variables,
                                  setGlobalVariableValue,
                                  Constants['gInstructorEmailAddress'],
                                  packageID,
                                  radioButtonGroupValue
                                );
                                amplitudeEvents(
                                  Variables,
                                  'decline job submit',
                                  {
                                    step: '03',
                                    funnel: 'decline job',
                                    origin: 'open job details screen',
                                    category: 'opportunity management',
                                  },
                                  radioButtonGroupValue
                                );
                              } catch (err) {
                                console.error(err);
                              }
                            };
                            handler();
                          }}
                          style={StyleSheet.applyWidth(
                            {
                              backgroundColor: palettes.App['App Red EB3E26'],
                              borderRadius: 50,
                              fontFamily: 'BebasNeue_400Regular',
                              fontSize: 18,
                              paddingBottom: 14,
                              paddingTop: 14,
                              textAlign: 'center',
                              width: '100%',
                            },
                            dimensions.width
                          )}
                          title={'Decline Package'}
                        />
                      )}
                    </>
                    {/* View Activity Indicator */}
                    <>
                      {!Constants['is_loading'] ? null : (
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: 'center',
                              backgroundColor: palettes.App['App Red EB3E26'],
                              borderRadius: 50,
                              marginTop: -50,
                              opacity: 0.8,
                              paddingBottom: 7,
                              paddingTop: 7,
                              width: '100%',
                            },
                            dimensions.width
                          )}
                        >
                          <>
                            {!Constants['is_loading'] ? null : (
                              <ActivityIndicator
                                animating={true}
                                hidesWhenStopped={true}
                                size={'small'}
                                color={palettes.App['Custom Color']}
                                style={StyleSheet.applyWidth(
                                  { height: 36, width: 36 },
                                  dimensions.width
                                )}
                              />
                            )}
                          </>
                        </View>
                      )}
                    </>
                  </View>
                </View>
              </View>
            </KeyboardAvoidingView>
          </Modal>
        )}
      </>
      {/* Modal Decline Success */}
      <>
        {!Constants['showModalDeclineSuccess'] ? null : (
          <Modal
            supportedOrientations={['portrait', 'landscape']}
            transparent={false}
            animationType={'slide'}
            presentationStyle={'overFullScreen'}
          >
            {/* View Card */}
            <View
              style={StyleSheet.applyWidth(
                {
                  backgroundColor: palettes.App['Custom Color'],
                  borderTopLeftRadius: 16,
                  borderTopRightRadius: 16,
                  bottom: 0,
                  paddingLeft: '5.35%',
                  paddingRight: '5.35%',
                  paddingTop: 30,
                },
                dimensions.width
              )}
            >
              {/* View Modal Close Slide */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    marginBottom: 6,
                  },
                  dimensions.width
                )}
              >
                {/* View Spacer */}
                <View
                  style={StyleSheet.applyWidth({ width: 40 }, dimensions.width)}
                />
                {/* View Slide Close */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      backgroundColor: '"rgba(0, 0, 0, 0)"',
                      borderRadius: 40,
                      height: 3,
                      width: 40,
                    },
                    dimensions.width
                  )}
                >
                  <Touchable
                    onPress={() => {
                      try {
                        setGlobalVariableValue({
                          key: 'showModalDeclineSuccess',
                          value: false,
                        });
                        navigation.navigate('JobsScreen');
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    style={StyleSheet.applyWidth(
                      { height: '100%', width: '100%' },
                      dimensions.width
                    )}
                  />
                </View>
                {/* View Close Button */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      backgroundColor:
                        palettes.App['App Disabled Button EDEDEF'],
                      borderRadius: 40,
                      paddingBottom: 4,
                      paddingLeft: 6,
                      paddingRight: 6,
                      paddingTop: 4,
                    },
                    dimensions.width
                  )}
                >
                  <Touchable
                    onPress={() => {
                      try {
                        setGlobalVariableValue({
                          key: 'showModalDeclineSuccess',
                          value: false,
                        });
                        navigation.navigate('JobsScreen');
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                  >
                    {/* Close */}
                    <Text
                      accessible={true}
                      style={StyleSheet.applyWidth(
                        {
                          color: palettes.App['App Text 2ndary 7F868C'],
                          fontFamily: 'Poppins_400Regular',
                          fontSize: 12,
                        },
                        dimensions.width
                      )}
                    >
                      {'close x'}
                    </Text>
                  </Touchable>
                </View>
              </View>
              {/* View Title */}
              <View
                style={StyleSheet.applyWidth(
                  { paddingBottom: 24 },
                  dimensions.width
                )}
              >
                {/* Title */}
                <Text
                  accessible={true}
                  style={StyleSheet.applyWidth(
                    {
                      color: palettes.App['App Black 191F32'],
                      fontFamily: 'Poppins_500Medium',
                      fontSize: 16,
                    },
                    dimensions.width
                  )}
                >
                  {Constants['sendDeclinePackageResponse']}
                  {
                    '. Great job! Request or decline more packages near you now.'
                  }
                </Text>
              </View>
              {/* View Decline Button */}
              <View
                style={StyleSheet.applyWidth(
                  { alignItems: 'center', marginBottom: 32, width: '100%' },
                  dimensions.width
                )}
              >
                {/* See more open jobs */}
                <Button
                  iconPosition={'left'}
                  onPress={() => {
                    try {
                      navigation.navigate('JobsScreen');
                      setGlobalVariableValue({
                        key: 'showModalDeclineSuccess',
                        value: false,
                      });
                      amplitudeEvents(
                        Variables,
                        'view open jobs list',
                        {
                          step: '01',
                          view: 'decline modal success',
                          funnel: 'request job',
                          origin: 'open job details screen',
                          category: 'opportunity management',
                        },
                        undefined
                      );
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  style={StyleSheet.applyWidth(
                    {
                      backgroundColor: palettes.App['App Red EB3E26'],
                      borderRadius: 50,
                      fontFamily: 'BebasNeue_400Regular',
                      fontSize: 18,
                      paddingBottom: 14,
                      paddingTop: 14,
                      textAlign: 'center',
                      width: '100%',
                    },
                    dimensions.width
                  )}
                  title={'See More Open Jobs'}
                />
                {/* View Activity Indicator */}
                <>
                  {!Constants['is_loading'] ? null : (
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          backgroundColor: palettes.App['App Red EB3E26'],
                          borderRadius: 50,
                          marginTop: -50,
                          opacity: 0.8,
                          paddingBottom: 7,
                          paddingTop: 7,
                          width: '100%',
                        },
                        dimensions.width
                      )}
                    >
                      <>
                        {!Constants['is_loading'] ? null : (
                          <ActivityIndicator
                            animating={true}
                            hidesWhenStopped={true}
                            size={'small'}
                            color={palettes.App['Custom Color']}
                            style={StyleSheet.applyWidth(
                              { height: 36, width: 36 },
                              dimensions.width
                            )}
                          />
                        )}
                      </>
                    </View>
                  )}
                </>
              </View>
            </View>
          </Modal>
        )}
      </>
      {/* Modal API Error Message 2 */}
      <>
        {!showModalAPIError ? null : (
          <Modal
            supportedOrientations={['portrait', 'landscape']}
            transparent={false}
            animationType={'slide'}
            presentationStyle={'overFullScreen'}
          >
            {/* View Card */}
            <View
              style={StyleSheet.applyWidth(
                {
                  backgroundColor: palettes.App['Custom Color'],
                  borderTopLeftRadius: 16,
                  borderTopRightRadius: 16,
                  bottom: 0,
                  paddingLeft: '5.35%',
                  paddingRight: '5.35%',
                  paddingTop: 30,
                },
                dimensions.width
              )}
            >
              {/* View Modal Close Slide */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    marginBottom: 6,
                  },
                  dimensions.width
                )}
              >
                {/* View Spacer */}
                <View
                  style={StyleSheet.applyWidth({ width: 40 }, dimensions.width)}
                />
                {/* View Slide Close */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      backgroundColor: '"rgba(0, 0, 0, 0)"',
                      borderRadius: 40,
                      height: 3,
                      width: 40,
                    },
                    dimensions.width
                  )}
                >
                  <Touchable
                    onPress={() => {
                      try {
                        resetSendRequestPackageResponse(
                          Variables,
                          setGlobalVariableValue
                        );
                        setShowModalAPIError(false);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    style={StyleSheet.applyWidth(
                      { height: '100%', width: '100%' },
                      dimensions.width
                    )}
                  />
                </View>
                {/* View Close Button */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      backgroundColor:
                        palettes.App['App Disabled Button EDEDEF'],
                      borderRadius: 40,
                      paddingBottom: 4,
                      paddingLeft: 6,
                      paddingRight: 6,
                      paddingTop: 4,
                    },
                    dimensions.width
                  )}
                >
                  <Touchable
                    onPress={() => {
                      try {
                        resetSendRequestPackageResponse(
                          Variables,
                          setGlobalVariableValue
                        );
                        setShowModalAPIError(false);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                  >
                    {/* Close */}
                    <Text
                      accessible={true}
                      style={StyleSheet.applyWidth(
                        {
                          color: palettes.App['App Text 2ndary 7F868C'],
                          fontFamily: 'Poppins_400Regular',
                          fontSize: 12,
                        },
                        dimensions.width
                      )}
                    >
                      {'close x'}
                    </Text>
                  </Touchable>
                </View>
              </View>
              {/* View Title */}
              <View
                style={StyleSheet.applyWidth(
                  { paddingBottom: 24 },
                  dimensions.width
                )}
              >
                {/* Title */}
                <Text
                  accessible={true}
                  style={StyleSheet.applyWidth(
                    {
                      color: palettes.App['App Black 191F32'],
                      fontFamily: 'Poppins_500Medium',
                      fontSize: 16,
                    },
                    dimensions.width
                  )}
                >
                  {'Uh oh, we hit a ripple. Please close and try again.'}
                </Text>
              </View>
              {/* View Message body */}
              <View
                style={StyleSheet.applyWidth(
                  { paddingBottom: 24 },
                  dimensions.width
                )}
              >
                {/* Error message */}
                <Text
                  accessible={true}
                  style={StyleSheet.applyWidth(
                    {
                      color: palettes.App['App Text 2ndary 7F868C'],
                      fontFamily: 'Poppins_400Regular',
                      fontSize: 12,
                    },
                    dimensions.width
                  )}
                >
                  {'Error message: '}
                  {Constants['sendRequestPackageResponse']}
                </Text>
              </View>
              {/* View Decline Button */}
              <View
                style={StyleSheet.applyWidth(
                  { alignItems: 'center', marginBottom: 32, width: '100%' },
                  dimensions.width
                )}
              >
                {/* Close and retry */}
                <Button
                  iconPosition={'left'}
                  onPress={() => {
                    try {
                      resetSendRequestPackageResponse(
                        Variables,
                        setGlobalVariableValue
                      );
                      setShowModalAPIError(false);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  style={StyleSheet.applyWidth(
                    {
                      backgroundColor: palettes.App['App Red EB3E26'],
                      borderRadius: 50,
                      fontFamily: 'BebasNeue_400Regular',
                      fontSize: 18,
                      paddingBottom: 14,
                      paddingTop: 14,
                      textAlign: 'center',
                      width: '100%',
                    },
                    dimensions.width
                  )}
                  title={'Close and retry'}
                />
                {/* View Activity Indicator */}
                <>
                  {!Constants['is_loading'] ? null : (
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          backgroundColor: palettes.App['App Red EB3E26'],
                          borderRadius: 50,
                          marginTop: -50,
                          opacity: 0.8,
                          paddingBottom: 7,
                          paddingTop: 7,
                          width: '100%',
                        },
                        dimensions.width
                      )}
                    >
                      <>
                        {!Constants['is_loading'] ? null : (
                          <ActivityIndicator
                            animating={true}
                            hidesWhenStopped={true}
                            size={'small'}
                            color={palettes.App['Custom Color']}
                            style={StyleSheet.applyWidth(
                              { height: 36, width: 36 },
                              dimensions.width
                            )}
                          />
                        )}
                      </>
                    </View>
                  )}
                </>
              </View>
            </View>
          </Modal>
        )}
      </>
      {/* Modal LSG */}
      <>
        {!showModalSSSGuarantee ? null : (
          <Modal
            supportedOrientations={['portrait', 'landscape']}
            transparent={false}
            animationType={'slide'}
            presentationStyle={'pageSheet'}
          >
            <View
              style={StyleSheet.applyWidth(
                {
                  backgroundColor: palettes.App['App Brand Blue 2C9AD9'],
                  height: '100%',
                  paddingBottom: '5.35%',
                  paddingLeft: '5.35%',
                  paddingRight: '5.35%',
                  paddingTop: '5.35%',
                  width: '100%',
                },
                dimensions.width
              )}
            >
              {/* View Modal Close Slide */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    marginBottom: 6,
                  },
                  dimensions.width
                )}
              >
                {/* View Spacer */}
                <View
                  style={StyleSheet.applyWidth({ width: 40 }, dimensions.width)}
                />
                {/* View Slide Close */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      backgroundColor:
                        palettes.App['App Disabled Button EDEDEF'],
                      borderRadius: 40,
                      height: 3,
                      width: 40,
                    },
                    dimensions.width
                  )}
                >
                  <Touchable
                    onPress={() => {
                      try {
                        setShowModalSSSGuarantee(false);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    style={StyleSheet.applyWidth(
                      { height: '100%', width: '100%' },
                      dimensions.width
                    )}
                  />
                </View>
                {/* View Close Button */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      backgroundColor:
                        palettes.App['App Disabled Button EDEDEF'],
                      borderRadius: 40,
                      paddingBottom: 4,
                      paddingLeft: 6,
                      paddingRight: 6,
                      paddingTop: 4,
                    },
                    dimensions.width
                  )}
                >
                  <Touchable
                    onPress={() => {
                      try {
                        setShowModalSSSGuarantee(false);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                  >
                    {/* Close */}
                    <Text
                      accessible={true}
                      style={StyleSheet.applyWidth(
                        {
                          color: palettes.App['App Text 2ndary 7F868C'],
                          fontFamily: 'Poppins_400Regular',
                          fontSize: 12,
                        },
                        dimensions.width
                      )}
                    >
                      {'close x'}
                    </Text>
                  </Touchable>
                </View>
              </View>
              {/* Title */}
              <Text
                accessible={true}
                {...GlobalStyles.TextStyles(theme)['Text'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Text'].style,
                    {
                      color: palettes.App['Custom Color'],
                      fontFamily: 'Poppins_600SemiBold',
                      fontSize: 18,
                      marginBottom: 14,
                    }
                  ),
                  dimensions.width
                )}
              >
                {'Sunsational Learn To Swim Guaranteed™'}
              </Text>

              <ScrollView
                bounces={true}
                horizontal={false}
                keyboardShouldPersistTaps={'never'}
                nestedScrollEnabled={false}
                showsHorizontalScrollIndicator={true}
                showsVerticalScrollIndicator={true}
              >
                <Text
                  accessible={true}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Text'].style,
                      {
                        color: palettes.App['Custom Color'],
                        fontFamily: 'Poppins_400Regular',
                        marginBottom: 12,
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {
                    'When you request a package that is Sunsational Learn to Swim Guaranteed, you agree to teach and have the students be able to complete the Sunsational Safety Sequence by the end of the last lesson in the package. The Sunsational Safety Sequence is a skill set for young beginners that states the swimmer should be able to:'
                  }
                </Text>
                {/* Bullet */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'flex-start',
                      flexDirection: 'row',
                      marginBottom: 6,
                    },
                    dimensions.width
                  )}
                >
                  <Icon
                    color={palettes.App['Custom Color']}
                    name={
                      'MaterialCommunityIcons/checkbox-multiple-marked-circle'
                    }
                    size={18}
                    style={StyleSheet.applyWidth(
                      { marginRight: 8, marginTop: 2 },
                      dimensions.width
                    )}
                  />
                  <Text
                    accessible={true}
                    {...GlobalStyles.TextStyles(theme)['Text'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'].style,
                        {
                          color: palettes.App['Custom Color'],
                          fontFamily: 'Poppins_400Regular',
                          marginBottom: 8,
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {
                      'Turn around to face the edge of the pool from where they entered.'
                    }
                  </Text>
                </View>
                {/* Bullet */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'flex-start',
                      flexDirection: 'row',
                      marginBottom: 6,
                    },
                    dimensions.width
                  )}
                >
                  <Icon
                    color={palettes.App['Custom Color']}
                    name={
                      'MaterialCommunityIcons/checkbox-multiple-marked-circle'
                    }
                    size={18}
                    style={StyleSheet.applyWidth(
                      { marginRight: 8, marginTop: 2 },
                      dimensions.width
                    )}
                  />
                  <Text
                    accessible={true}
                    {...GlobalStyles.TextStyles(theme)['Text'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'].style,
                        {
                          color: palettes.App['Custom Color'],
                          fontFamily: 'Poppins_400Regular',
                          marginBottom: 8,
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Safely resurface.'}
                  </Text>
                </View>
                {/* Bullet */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'flex-start',
                      flexDirection: 'row',
                      marginBottom: 6,
                    },
                    dimensions.width
                  )}
                >
                  <Icon
                    color={palettes.App['Custom Color']}
                    name={
                      'MaterialCommunityIcons/checkbox-multiple-marked-circle'
                    }
                    size={18}
                    style={StyleSheet.applyWidth(
                      { marginRight: 8, marginTop: 2 },
                      dimensions.width
                    )}
                  />
                  <Text
                    accessible={true}
                    {...GlobalStyles.TextStyles(theme)['Text'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'].style,
                        {
                          color: palettes.App['Custom Color'],
                          fontFamily: 'Poppins_400Regular',
                          marginBottom: 8,
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Kick back to the edge of the pool.'}
                  </Text>
                </View>
                {/* Bullet */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'flex-start',
                      flexDirection: 'row',
                      marginBottom: 12,
                    },
                    dimensions.width
                  )}
                >
                  <Icon
                    color={palettes.App['Custom Color']}
                    name={
                      'MaterialCommunityIcons/checkbox-multiple-marked-circle'
                    }
                    size={18}
                    style={StyleSheet.applyWidth(
                      { marginRight: 8, marginTop: 2 },
                      dimensions.width
                    )}
                  />
                  <Text
                    accessible={true}
                    {...GlobalStyles.TextStyles(theme)['Text'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'].style,
                        {
                          color: palettes.App['Custom Color'],
                          fontFamily: 'Poppins_400Regular',
                          marginBottom: 8,
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Grab onto the wall.'}
                  </Text>
                </View>

                <Text
                  accessible={true}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Text'].style,
                      {
                        color: palettes.App['Custom Color'],
                        fontFamily: 'Poppins_400Regular',
                        marginBottom: 12,
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {
                    'If by the end of the last lesson in the package the student(s) is not able to complete the Sunsational Safety Sequence, you agree to teach up to 4 additional lessons at half the original pay rate.'
                  }
                </Text>

                <Text
                  accessible={true}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Text'].style,
                      {
                        color: palettes.App['Custom Color'],
                        fontFamily: 'Poppins_400Regular',
                        marginBottom: 12,
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {
                    'Email office@sunsationalswimschool.com after the 8th or 9th lesson in the series if you believe the a child will require additional lessons to meet the guarantee.'
                  }
                </Text>

                <Text
                  accessible={true}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Text'].style,
                      {
                        color: palettes.App['Custom Color'],
                        fontFamily: 'Poppins_400Regular',
                        marginBottom: 12,
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {
                    'In the event a student needs additional lessons to meet the guarantee, do NOT schedule additional lessons with the client before consulting Sunsational.'
                  }
                </Text>

                <Text
                  accessible={true}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Text'].style,
                      {
                        color: palettes.App['Custom Color'],
                        fontFamily: 'Poppins_400Regular',
                        marginBottom: 12,
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {
                    'If you are not confident you can teach and have the students be able to complete the Sunsational Safety Sequence within the lesson package, you should not request a Sunsational Learn to Swim Guaranteed package.'
                  }
                </Text>
              </ScrollView>
            </View>
          </Modal>
        )}
      </>
      {/* Modal Package Status Legend */}
      <Modal
        supportedOrientations={['portrait', 'landscape']}
        transparent={false}
        animationType={'slide'}
        presentationStyle={'pageSheet'}
        visible={showModalPackageStatusLegend}
      >
        <View
          style={StyleSheet.applyWidth(
            {
              height: '100%',
              paddingBottom: '5.35%',
              paddingLeft: '5.35%',
              paddingRight: '5.35%',
              paddingTop: '5.35%',
              width: '100%',
            },
            dimensions.width
          )}
        >
          {/* View Modal Close Slide */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginBottom: 6,
              },
              dimensions.width
            )}
          >
            {/* View Spacer */}
            <View
              style={StyleSheet.applyWidth({ width: 40 }, dimensions.width)}
            />
            {/* View Slide Close */}
            <View
              style={StyleSheet.applyWidth(
                {
                  backgroundColor: palettes.App['App Disabled Button EDEDEF'],
                  borderRadius: 40,
                  height: 3,
                  width: 40,
                },
                dimensions.width
              )}
            >
              <Touchable
                onPress={() => {
                  try {
                    setShowModalPackageStatusLegend(false);
                  } catch (err) {
                    console.error(err);
                  }
                }}
                style={StyleSheet.applyWidth(
                  { height: '100%', width: '100%' },
                  dimensions.width
                )}
              />
            </View>
            {/* View Close Button */}
            <View
              style={StyleSheet.applyWidth(
                {
                  backgroundColor: palettes.App['App Disabled Button EDEDEF'],
                  borderRadius: 40,
                  paddingBottom: 4,
                  paddingLeft: 6,
                  paddingRight: 6,
                  paddingTop: 4,
                },
                dimensions.width
              )}
            >
              <Touchable
                onPress={() => {
                  try {
                    setShowModalPackageStatusLegend(false);
                  } catch (err) {
                    console.error(err);
                  }
                }}
              >
                {/* Close */}
                <Text
                  accessible={true}
                  style={StyleSheet.applyWidth(
                    {
                      color: palettes.App['App Text 2ndary 7F868C'],
                      fontFamily: 'Poppins_400Regular',
                      fontSize: 12,
                    },
                    dimensions.width
                  )}
                >
                  {'close x'}
                </Text>
              </Touchable>
            </View>
          </View>
          {/* Title */}
          <Text
            accessible={true}
            {...GlobalStyles.TextStyles(theme)['Text'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'].style, {
                color: palettes.App['App Black 191F32'],
                fontFamily: 'Poppins_600SemiBold',
                fontSize: 18,
                marginBottom: 14,
              }),
              dimensions.width
            )}
          >
            {'Package Statuses Legend'}
          </Text>

          <ScrollView
            bounces={true}
            horizontal={false}
            keyboardShouldPersistTaps={'never'}
            nestedScrollEnabled={false}
            showsHorizontalScrollIndicator={true}
            showsVerticalScrollIndicator={true}
          >
            {/* Available */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'flex-start',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  marginBottom: 24,
                },
                dimensions.width
              )}
            >
              {/* Description */}
              <View
                style={StyleSheet.applyWidth(
                  { width: '72%' },
                  dimensions.width
                )}
              >
                <Text
                  accessible={true}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Text'].style,
                      {
                        color: palettes.App['App Black 191F32'],
                        fontFamily: 'Poppins_400Regular',
                        marginBottom: 12,
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {'The package is available for request.'}
                </Text>
              </View>
              {/* Available */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    alignSelf: 'flex-start',
                    backgroundColor: palettes.App['App Blue/Low Emphasis'],
                    borderRadius: 8,
                    paddingBottom: 8,
                    paddingLeft: 8,
                    paddingRight: 8,
                    paddingTop: 8,
                    width: '24%',
                  },
                  dimensions.width
                )}
              >
                {/* Available */}
                <Text
                  accessible={true}
                  style={StyleSheet.applyWidth(
                    {
                      color: palettes.App['App Blue#4 007fd4'],
                      fontFamily: 'Poppins_400Regular',
                      fontSize: 12,
                    },
                    dimensions.width
                  )}
                >
                  {'Available'}
                </Text>
              </View>
            </View>
            {/* Requested */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'flex-start',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  marginBottom: 24,
                },
                dimensions.width
              )}
            >
              {/* Description */}
              <View
                style={StyleSheet.applyWidth(
                  { width: '72%' },
                  dimensions.width
                )}
              >
                <Text
                  accessible={true}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Text'].style,
                      {
                        color: palettes.App['App Black 191F32'],
                        fontFamily: 'Poppins_400Regular',
                        marginBottom: 12,
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {
                    'You requested this package and your request is pending. You may still be matched with this package.'
                  }
                </Text>
              </View>
              {/* View Requested Status */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    backgroundColor: palettes.App['App Orange #FDF9EC'],
                    borderRadius: 8,
                    flexDirection: 'row',
                    justifyContent: 'center',
                    paddingBottom: 8,
                    paddingLeft: 8,
                    paddingRight: 8,
                    paddingTop: 8,
                    width: '24%',
                  },
                  dimensions.width
                )}
              >
                {/* Requested Status */}
                <Text
                  accessible={true}
                  style={StyleSheet.applyWidth(
                    {
                      color: palettes.App['App Gold FFA900'],
                      fontFamily: 'Poppins_400Regular',
                      fontSize: 12,
                    },
                    dimensions.width
                  )}
                >
                  {'Requested'}
                </Text>
              </View>
            </View>
            {/* Matched */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'flex-start',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  marginBottom: 24,
                },
                dimensions.width
              )}
            >
              {/* Description */}
              <View
                style={StyleSheet.applyWidth(
                  { width: '72%' },
                  dimensions.width
                )}
              >
                <Text
                  accessible={true}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Text'].style,
                      {
                        color: palettes.App['App Black 191F32'],
                        fontFamily: 'Poppins_400Regular',
                        marginBottom: 12,
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {
                    'You are the assigned Sunsational swim instructor for this package.'
                  }
                </Text>
              </View>
              {/* View Matched With SI */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    backgroundColor: palettes.App['App Mint #F0F8F2'],
                    borderRadius: 8,
                    paddingBottom: 8,
                    paddingLeft: 8,
                    paddingRight: 8,
                    paddingTop: 8,
                    width: '24%',
                  },
                  dimensions.width
                )}
              >
                {/* Matched Status */}
                <Text
                  accessible={true}
                  style={StyleSheet.applyWidth(
                    {
                      color: palettes.App['App Text Green #1DB058'],
                      fontFamily: 'Poppins_400Regular',
                      fontSize: 12,
                    },
                    dimensions.width
                  )}
                >
                  {'Matched'}
                </Text>
              </View>
            </View>
            {/* Unavailable */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'flex-start',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  marginBottom: 24,
                },
                dimensions.width
              )}
            >
              {/* Description */}
              <View
                style={StyleSheet.applyWidth(
                  { width: '72%' },
                  dimensions.width
                )}
              >
                <Text
                  accessible={true}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Text'].style,
                      {
                        color: palettes.App['App Black 191F32'],
                        fontFamily: 'Poppins_400Regular',
                        marginBottom: 12,
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {
                    'The package is not currently available for request. The package may have been matched with another instructor, the client may not be ready to start or varying other reasons make this package currently unavailable.'
                  }
                </Text>
              </View>
              {/* Unavailable */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    backgroundColor: palettes.App['App Disabled Button EDEDEF'],
                    borderRadius: 8,
                    paddingBottom: 8,
                    paddingLeft: 8,
                    paddingRight: 8,
                    paddingTop: 8,
                    width: '24%',
                  },
                  dimensions.width
                )}
              >
                {/* Unavailable Status */}
                <Text
                  accessible={true}
                  style={StyleSheet.applyWidth(
                    {
                      color: palettes.App['App Text 2ndary 7F868C'],
                      fontFamily: 'Poppins_400Regular',
                      fontSize: 12,
                    },
                    dimensions.width
                  )}
                >
                  {'Unavailable'}
                </Text>
              </View>
            </View>
          </ScrollView>
        </View>
      </Modal>
    </ScreenContainer>
  );
};

export default withTheme(OpportunityDetailsLiveScreen);
