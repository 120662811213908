import * as React from 'react';
import {
  useQuery,
  useMutation,
  useIsFetching,
  useQueryClient,
} from 'react-query';
import useFetch from 'react-fetch-hook';
import { useIsFocused } from '@react-navigation/native';
import { handleResponse, isOkStatus } from '../utils/handleRestApiResponse';
import usePrevious from '../utils/usePrevious';
import {
  encodeQueryParam,
  renderParam,
  renderQueryString,
} from '../utils/encodeQueryParam';
import * as GlobalVariables from '../config/GlobalVariableContext';

const cleanHeaders = headers =>
  Object.fromEntries(Object.entries(headers).filter(kv => kv[1] != null));

export const checkTipaltiReadyGET = async (
  Constants,
  { auth_knack_id },
  handlers = {}
) => {
  const paramsDict = {};
  if (auth_knack_id !== undefined) {
    paramsDict['id'] = renderParam(auth_knack_id);
  }
  const url = `https://hook.us1.make.com/sa9vuli8o1a1xryqt4l37m33yxhirswy${renderQueryString(
    paramsDict
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useCheckTipaltiReadyGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['tipalti_account', args],
    () => checkTipaltiReadyGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['tipalti_accounts']),
    }
  );
};

export const FetchCheckTipaltiReadyGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  auth_knack_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useCheckTipaltiReadyGET(
    { auth_knack_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchCheckTipaltiReady: refetch });
};

export const requestTipaltiInvitePOST = async (
  Constants,
  { AUTH_KNACK_USER_ID },
  handlers = {}
) => {
  const url = `https://hook.us1.make.com/j3fexqqa9ck93u7dywafjr4hh1kyqaer`;
  const options = {
    body: JSON.stringify({ AUTH_KNACK_USER_ID: AUTH_KNACK_USER_ID }),
    headers: cleanHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }),
    method: 'POST',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useRequestTipaltiInvitePOST = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['tipalti_invite', args],
    () => requestTipaltiInvitePOST(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['tipalti_invites']),
    }
  );
};

export const FetchRequestTipaltiInvitePOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  AUTH_KNACK_USER_ID,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useRequestTipaltiInvitePOST(
    { AUTH_KNACK_USER_ID },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchRequestTipaltiInvite: refetch,
  });
};

export const sendClientAReviewRequestPOST = async (
  Constants,
  { clientEmail, messageBody, packageKnackID, packageName, siEmail, siKnackID },
  handlers = {}
) => {
  const url = `https://hook.us1.make.com/a3jlcmpgdtlg4ce6h4niv2mb83nwdxba`;
  const options = {
    body: JSON.stringify({
      siKnackID: siKnackID,
      siEmail: siEmail,
      clientEmail: clientEmail,
      packageKnackID: packageKnackID,
      packageName: packageName,
      messageBody: messageBody,
    }),
    headers: cleanHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }),
    method: 'POST',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useSendClientAReviewRequestPOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      sendClientAReviewRequestPOST(
        Constants,
        { ...initialArgs, ...args },
        handlers
      ),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('review_request', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('review_request');
        queryClient.invalidateQueries('review_requests');
      },
    }
  );
};

export const FetchSendClientAReviewRequestPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  clientEmail,
  messageBody,
  packageKnackID,
  packageName,
  siEmail,
  siKnackID,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useSendClientAReviewRequestPOST(
    {
      clientEmail,
      messageBody,
      packageKnackID,
      packageName,
      siEmail,
      siKnackID,
    },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchSendClientAReviewRequest: refetch,
  });
};

export const submitReviewBonusPOST = async (
  Constants,
  {
    clientEmail,
    clientKnackID,
    clientName,
    packageID,
    packageKnackID,
    platform,
    reviewLink,
    siEmail,
    siKnackID,
  },
  handlers = {}
) => {
  const url = `https://hook.us1.make.com/159nd85cky8cccfranmkfop5l0dv6alm`;
  const options = {
    body: JSON.stringify({
      siEmail: siEmail,
      clientEmail: clientEmail,
      siKnackID: siKnackID,
      clientKnackID: clientKnackID,
      clientName: clientName,
      packageID: packageID,
      packageKnackID: packageKnackID,
      reviewLink: reviewLink,
      platform: platform,
    }),
    headers: cleanHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }),
    method: 'POST',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useSubmitReviewBonusPOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      submitReviewBonusPOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('review_bonus', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('review_bonus');
        queryClient.invalidateQueries('review_bonuses');
      },
    }
  );
};

export const FetchSubmitReviewBonusPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  clientEmail,
  clientKnackID,
  clientName,
  packageID,
  packageKnackID,
  platform,
  reviewLink,
  siEmail,
  siKnackID,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useSubmitReviewBonusPOST(
    {
      clientEmail,
      clientKnackID,
      clientName,
      packageID,
      packageKnackID,
      platform,
      reviewLink,
      siEmail,
      siKnackID,
    },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchSubmitReviewBonus: refetch });
};
