import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as KnackAPIApi from '../apis/KnackAPIApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import * as Track from '../custom-files/Track';
import * as convertTime from '../custom-files/convertTime';
import * as nativeProgress from '../custom-files/nativeProgress';
import * as validateAppVersionAndAuthToken from '../custom-files/validateAppVersionAndAuthToken';
import amplitudeEvents from '../global-functions/amplitudeEvents';
import getUserInitials from '../global-functions/getUserInitials';
import useCustomerAvatar from '../global-functions/useCustomerAvatar';
import palettes from '../themes/palettes';
import * as Utils from '../utils';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import { MapMarker, MapView } from '@draftbit/maps';
import {
  Button,
  Pressable,
  SVG,
  ScreenContainer,
  Spacer,
  Surface,
  TextInput,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { FlashList } from '@shopify/flash-list';
import {
  ActivityIndicator,
  ImageBackground,
  Modal,
  Platform,
  ScrollView,
  Text,
  View,
} from 'react-native';
import { Fetch } from 'react-request';

const Clients2Screen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [any, setAny] = React.useState(false);
  const [currentClients, setCurrentClients] = React.useState([]);
  const [currentClientsCount, setCurrentClientsCount] = React.useState(0);
  const [filterActive, setFilterActive] = React.useState(false);
  const [filterDays, setFilterDays] = React.useState('');
  const [filteredJobsCount, setFilteredJobsCount] = React.useState(0);
  const [filteredOpenJobs, setFilteredOpenJobs] = React.useState([]);
  const [filters, setFilters] = React.useState({
    match: 'and',
    rules: [
      { field: 'field_54', value: 'Unbooked', operator: 'is not' },
      {
        field: 'field_312',
        value: { date: '08/14/2022' },
        operator: 'is after',
      },
      { field: 'field_312', operator: 'is not blank' },
    ],
  });
  const [is_loading, setIs_loading] = React.useState(true);
  const [jobsList, setJobsList] = React.useState('current');
  const [matchedLessonsFetched, setMatchedLessonsFetched] = React.useState('');
  const [modalDeclineVisible, setModalDeclineVisible] = React.useState(false);
  const [modalFilterVisible, setModalFilterVisible] = React.useState(false);
  const [modalSortVisible, setModalSortVisible] = React.useState(false);
  const [modalVisible, setModalVisible] = React.useState(true);
  const [noCurrentClientsMessages, setNoCurrentClientsMessages] =
    React.useState([
      "The pool's looking a little empty at the moment. Why not request some nearby clients and make some new swim buddies?",
      'There may not be any clients in your queue at the moment, but there are plenty of little ones eager to learn how to swim. Request some nearby clients now!',
      "The water's just a little too calm at the moment. Request some nearby clients to create some ripples!",
      'We hate to see a talented swim instructor like you without any clients. Time to request some nearby swimmers and show them your skills!',
    ]);
  const [pastClientsCount, setPastClientsCount] = React.useState(0);
  const [requestedAndDeclinedPackages, setRequestedAndDeclinedPackages] =
    React.useState('');
  const [searchBarInput, setSearchBarInput] = React.useState('');
  const [searchBarIsActive, setSearchBarIsActive] = React.useState(false);
  const [selectedLessonPackageId, setSelectedLessonPackageId] =
    React.useState(0);
  const [selectedPackageId2, setSelectedPackageId2] = React.useState('');
  const [selectedPackageKnackId, setSelectedPackageKnackId] =
    React.useState('');
  const [siLessonRecords, setSiLessonRecords] = React.useState([]);
  const [sortBy, setSortBy] = React.useState('newest');
  const [sort_field, setSort_field] = React.useState('field_312');
  const [sort_order, setSort_order] = React.useState('desc');
  const [textInputValue, setTextInputValue] = React.useState('');
  const [uniquePackages, setUniquePackages] = React.useState({});
  const [viewType, setViewType] = React.useState('list');
  const encodeUrlSafeComponent = input => {
    return encodeURIComponent(input);
  };

  const getPastClientsCount = filteredClients => {
    console.log('filteredClients past ==== ', filteredClients);
    let clientCount = filteredClients.length;
    setPastClientsCount(clientCount);
    return filteredClients;
  };

  const clearFilterParams = () => {
    setMonday(false);
    setTuesday(false);
    setWednesday(false);
    setThursday(false);
    setFriday(false);
    setSaturday(false);
    setSunday(false);
    setAny(false);
    setMaxLessons(0);
  };

  const extractPackageId = packageIdentifiers => {
    const packageId = packageIdentifiers[0].identifier;

    return packageId;
  };

  const joinArray = arr => {
    return arr.join(' ');
  };

  const convertStringToBoolean = stringYesOrNo => {
    if (stringYesOrNo.length > 0) {
      return stringYesOrNo.toLowerCase() == 'yes' ? true : false;
    }

    return false;
  };

  const concatAddress = addressObject => {
    const { street, street2, city, state, zip, latitude, longitude } =
      addressObject;

    let address;

    street2
      ? (address = `${street} ${street2}, ${city}, ${state} ${zip}`)
      : (address = `${street}, ${city}, ${state} ${zip}`);

    return address;
  };

  const extractDateFromString = str => {
    let date = str.replace('On a particular day', '');

    return date;
  };

  const removeRequestedAndDeclinedPackages = packageList => {
    const filteredList = packageList.filter(
      lessonPackage => !requestedAndDeclinedPackages.includes(lessonPackage.id)
    );

    return filteredList;
  };

  const addPackageToExcludeList = (Variables, setGlobalVariableValue, id2) => {
    let currentList = requestedAndDeclinedPackages + ' ';
    setRequestedAndDeclinedPackages((currentList += id2));
  };

  const closeConfirmationModal = async (Variables, setGlobalVariableValue) => {
    try {
      await refetchGetOpenOpportunities();
      resetSendDeclinePackageResponse();
      setModalDeclineVisible(false);
    } catch (err) {
      console.error(err);
    }
  };

  const log = item => {
    log('log ========= ', item);
  };

  const getCurrentClientsCount = filteredClients => {
    let clientCount = filteredClients.length;
    setCurrentClientsCount(clientCount);
    return filteredClients;
  };

  const filterIncompleteLessonPackages = uniquePackages => {
    return uniquePackages.filter(lessonPackage => {
      return (
        lessonPackage.lessonsCompleted + lessonPackage.lessonsCancelled ===
        lessonPackage.totalLessons
      );
    });
  };

  const getRandomMessage = messages => {
    const randomIndex = Math.floor(Math.random() * messages.length);
    return messages[randomIndex];
  };

  const getNextScheduledLessonDate = lessonsInPackage => {
    const now = new Date();
    const nextUpcomingLesson = lessonsInPackage.filter(lesson => {
      const status = lesson.lessonStatus;
      if (
        lesson.scheduledDateUnixTimestamp &&
        (status === 'Matched' || status === 'Paused' || status === 'Scheduled')
      ) {
        return (
          lesson.scheduledDateUnixTimestamp > now.setHours(now.getHours() - 0)
        );
      }
    })[0];

    if (!nextUpcomingLesson) {
      return 'None scheduled';
    }

    const dt = convertTime.toLocalMachine(
      nextUpcomingLesson.scheduledDateTimeString,
      'MM/DD/YYYY h:mm A',
      'MMM DD h:mmA'
    );

    return dt;
  };

  const getLastLessonCompletedDate = completedLessons => {
    const latestCompletionDate = completedLessons.sort((a, b) => {
      const dateA = convertTime.moment(
        a.lessonCompletionDate,
        'MM/DD/YYYY h:mma'
      );
      const dateB = convertTime.moment(
        b.lessonCompletionDate,
        'MM/DD/YYYY h:mma'
      );
      return dateB - dateA;
    })[0];

    if (!latestCompletionDate) {
      return 'No Lessons Completed';
    }

    const dt = convertTime.toLocalMachine(
      latestCompletionDate.lessonCompletionDate,
      'MM/DD/YYYY h:mm A',
      'MMM DD'
    );

    return dt;
  };

  const checkIfMatchedLessonsAreFetched = fetchedLessons => {
    setMatchedLessonsFetched(Object.keys(fetchedLessons).length > 0);
  };

  const searchFilter = packages => {
    if (!searchBarInput) {
      return packages;
    }

    return packages.filter(
      lessonPackage =>
        lessonPackage.client
          .toLowerCase()
          .includes(searchBarInput.toLocaleLowerCase()) ||
        lessonPackage.packageId
          ?.toString()
          .includes(searchBarInput.toLocaleUpperCase())
    );
  };

  // Filter out completed packages. Only keep packages where the amountPaid does not equal totalPay, meaning there are still lessons that need to be paid in the lesson package.
  const filterCompletedLessonPackages = uniquePackages => {
    return uniquePackages.filter(lessonPackage => {
      return (
        lessonPackage.lessonsCompleted + lessonPackage.lessonsCancelled !==
        lessonPackage.totalLessons
      );
    });
  };

  const getUniquePackages = allLessons => {
    const uniquePackages = [];
    const packages = {};
    const map = new Map();

    for (const lesson of allLessons) {
      let packageId = lesson.field_58_raw[0].identifier;
      let lessonNumber = lesson.field_320_raw;
      let lessonStatus = lesson.field_54;
      let lessonPaymentStatus = lesson.field_534;
      let lessonPay = lesson.field_69_raw;
      let lessonScheduleDate = lesson.field_202;
      let lessonScheduleDateUnixTimestamp =
        lesson.field_202_raw?.unix_timestamp;
      let lessonCompletionDate = lesson.field_205;
      let lessonMatchingDate = lesson.field_312;

      if (!map.has(packageId)) {
        map.set(packageId);
        packages.packageId = {
          scheduledLessons: [],
          completedLessons: [],
          cancelledLessons: [],
          lessonsCompleted: 0,
          lessonsCancelled: 0,
          totalLessons: 0,
          paidLessons: 0,
          amountPaid: 0,
          payBonus: 0,
          totalPay: 0,
          completeness: 0,
          packageId,
          client: lesson.field_455_raw,
          poolAddress: lesson.field_98_raw,
          packageKnackId: lesson.field_58_raw[0].id,
          packageStatus: lesson.field_231_raw,
          clientAvatar: lesson.field_2415_raw,
          matchingDate: new Date('1970-01-01'),
          nextScheduledLesson: new Date('3000-01-01'),
        };

        uniquePackages.push(packages.packageId);
      }

      if (lessonStatus === 'Completed' && lessonNumber > 0) {
        packages.packageId.lessonsCompleted++;
        packages.packageId.completedLessons.push({
          lessonNumber,
          lessonCompletionDate,
        });
      }

      if (lessonStatus === 'Cancelled ' && lessonNumber > 0) {
        packages.packageId.lessonsCancelled++;
        packages.packageId.cancelledLessons.push({
          lessonNumber,
          lessonCompletionDate,
        });
      }

      if (lessonPaymentStatus === 'Paid') {
        packages.packageId.paidLessons++;
        packages.packageId.amountPaid += lessonPay;
      }

      if (lessonNumber > 0) {
        packages.packageId.totalLessons++;
      } else {
        packages.packageId.payBonus += lessonPay;
      }

      packages.packageId.totalPay += lessonPay;
      packages.packageId.scheduledLessons.push({
        lessonNumber,
        // scheduledDate: lessonScheduleDate ?? new Date(lessonScheduleDate),
        scheduledDateTimeString: lessonScheduleDate ?? null,
        scheduledDateUnixTimestamp: lessonScheduleDateUnixTimestamp ?? null,
        lessonStatus,
      });

      if (lessonMatchingDate) {
        packages.packageId.matchingDate =
          new Date(lessonMatchingDate) > packages.packageId.matchingDate
            ? new Date(lessonMatchingDate)
            : packages.packageId.matchingDate;
      }

      if (lessonScheduleDate) {
        packages.packageId.nextScheduledLesson =
          new Date(lessonScheduleDate) < packages.packageId.nextScheduledLesson
            ? new Date(nextScheduledLesson)
            : packages.packageId.nextScheduledLesson;
      }
    }

    for (const lessonPackage of uniquePackages) {
      lessonPackage.scheduledLessons.sort(
        (a, b) => a.lessonNumber - b.lessonNumber
      );
      lessonPackage.completeness = Math.round(
        (lessonPackage.lessonsCompleted / lessonPackage.totalLessons) * 100
      );
    }

    return uniquePackages;
  };

  const sortClients = packages => {
    if (!sortBy) {
      console.log('sort by default');
      return packages;
    }

    if (sortBy === 'newest') {
      return packages.sort((a, b) => {
        const dateA = a.matchingDate;
        const dateB = b.matchingDate;

        return dateB - dateA;
      });
    }

    if (sortBy === 'earliest') {
      return packages.sort((a, b) => {
        const dateA = a.matchingDate;
        const dateB = b.matchingDate;

        return dateA - dateB;
      });
    }

    if (sortBy === 'upcomingLesson') {
      return packages.sort((a, b) => {
        const dateA = a.nextScheduledLesson;
        const dateB = b.nextScheduledLesson;

        return dateA - dateB;
      });
    }

    if (sortBy === 'mostComplete') {
      return packages.sort((a, b) => {
        const completnessA = a.completeness;
        const completnessB = b.completeness;

        return completnessB - completnessA;
      });
    }

    if (sortBy === 'mostUnfinished') {
      return packages.sort((a, b) => {
        const completnessA = a.completeness;
        const completnessB = b.completeness;

        return completnessA - completnessB;
      });
    }

    if (sortBy === 'clientNameAZ') {
      console.log('sort by client name A to Z');
      return packages.sort((a, b) => {
        const nameA = a.client.toLowerCase();
        const nameB = b.client.toLowerCase();

        if (nameA > nameB) {
          return 1;
        }

        if (nameA < nameB) {
          return -1;
        }

        //if names are equal
        return 0;
      });
    }

    if (sortBy === 'clientNameZA') {
      console.log('sort by client name Z to A');
      return packages.sort((a, b) => {
        const nameA = a.client.toLowerCase();
        const nameB = b.client.toLowerCase();

        if (nameA < nameB) {
          return 1;
        }

        if (nameA > nameB) {
          return -1;
        }

        //if names are equal
        return 0;
      });
    }
  };

  const createFilterParams = (status, previousDays) => {
    const getDateXDaysAgo = previousDays => {
      const currentDate = convertTime.moment();
      const dateXDaysAgo = currentDate.subtract(previousDays, 'days');
      const date = dateXDaysAgo.toDate();
      const formattedDate = convertTime.moment(date).format('MM/DD/YYYY');
      return formattedDate;
    };

    function FilterPackageStatus(status) {
      this.field = 'field_231';
      this.operator = 'is';
      this.value = status;
    }

    function FilterCompletionDateAfter(previousDays = 365) {
      this.field = 'field_205';
      this.operator = 'is after';
      this.value = { date: getDateXDaysAgo(previousDays) };
    }

    function FilterLessonBookingDateAfter(previousDays = 365) {
      this.field = 'field_312';
      this.operator = 'is after';
      this.value = { date: getDateXDaysAgo(previousDays) };
    }

    function FilterLessonIsExpired() {
      (this.field = ''), (this.operator = ''), (this.value = '');
    }

    function FilterLessonBookingDateNotBlank() {
      this.field = 'field_312';
      this.operator = 'is not blank';
    }

    function FilterPackageStatusNotCompleted() {
      this.field = 'field_231';
      this.operator = 'is not';
      this.value = 'Completed';
    }

    function FilterPackageStatusNotPaused() {
      this.field = 'field_231';
      this.operator = 'is not';
      this.value = 'Paused';
    }

    function FilterLessonStatusNotUnbooked() {
      this.field = 'field_54';
      this.operator = 'is not';
      this.value = 'Unbooked';
    }

    let match = 'and';
    const rules = [];

    if (status === 'Matched') {
      console.log('Get matched packages');
      rules.push(new FilterLessonStatusNotUnbooked());
      rules.push(new FilterLessonBookingDateAfter(previousDays));
      rules.push(new FilterLessonBookingDateNotBlank());
      rules.push(new FilterPackageStatusNotPaused());
      // rules.push(new FilterPackageStatusNotCompleted());
      // rules.push(new FilterCompletionDateAfter(previousDays));
    }

    if (status === 'Completed') {
      console.log('Get completed packages');
      rules.push(new FilterLessonStatusNotUnbooked());
      rules.push(new FilterLessonBookingDateAfter(previousDays));
      rules.push(new FilterLessonBookingDateNotBlank());
      rules.push(new FilterPackageStatusNotPaused());
      rules.push(new FilterCompletionDateAfter(previousDays));
      // rules.push(new FilterPackageStatus(status));
    }

    const filterRules = {
      match,
      rules,
    };

    console.log('filterRules === ', filterRules);
    return filterRules;
  };
  React.useEffect(() => {
    Track.onEnterScreen(
      'Clients2Screen',
      Variables,
      setGlobalVariableValue,
      props.navigation.navigate,
      props?.route?.params?.skipAuth
    );
    const matchedLessonsFilter = createFilterParams('Matched');
    setFilters(matchedLessonsFilter);
  }, []);
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      setGlobalVariableValue({
        key: 'screen',
        value: 'clients',
      });
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={false}
      hasTopSafeArea={false}
    >
      {/* View Top Navigation - Subtitle */}
      <View
        style={StyleSheet.applyWidth(
          { width: '100%', zIndex: 10 },
          dimensions.width
        )}
      >
        {/* Spacer Top Nav */}
        <Spacer bottom={0} left={0} right={0} top={44} />
        {/* View Row Top Nav Container */}
        <View
          style={StyleSheet.applyWidth(
            {
              alignContent: 'center',
              alignItems: 'center',
              flexDirection: 'row',
              justifyContent: 'space-between',
              paddingLeft: '5.35%',
              paddingRight: '5.35%',
            },
            dimensions.width
          )}
        >
          {/* View Left */}
          <View
            style={StyleSheet.applyWidth(
              { flexDirection: 'row' },
              dimensions.width
            )}
          >
            {/* View Back Arrow Container */}
            <View
              style={StyleSheet.applyWidth(
                { height: 24, marginRight: 8, width: 24 },
                dimensions.width
              )}
            >
              <Pressable
                onPress={() => {
                  try {
                    navigation.goBack();
                  } catch (err) {
                    console.error(err);
                  }
                }}
                {...GlobalStyles.PressableStyles(theme)['full-width-height']
                  .props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.PressableStyles(theme)['full-width-height']
                    .style,
                  dimensions.width
                )}
              >
                <SVG
                  source={Constants['uiIconArrowLeftWhite']}
                  style={StyleSheet.applyWidth(
                    { height: '100%', width: '100%' },
                    dimensions.width
                  )}
                />
              </Pressable>
            </View>
            {/* View Page Title Container */}
            <>
              {searchBarIsActive ? null : (
                <View
                  style={StyleSheet.applyWidth(
                    { justifyContent: 'center' },
                    dimensions.width
                  )}
                >
                  {/* Title */}
                  <Text
                    accessible={true}
                    style={StyleSheet.applyWidth(
                      {
                        color: palettes.App['Custom Color'],
                        fontFamily: 'Poppins_700Bold',
                        fontSize: 18,
                      },
                      dimensions.width
                    )}
                  >
                    {'My Clients'}
                  </Text>
                </View>
              )}
            </>
          </View>
          {/* View Right Filters */}
          <>
            {searchBarIsActive ? null : (
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                  },
                  dimensions.width
                )}
              >
                {/* View Sort Icon */}
                <>
                  {searchBarIsActive ? null : (
                    <View
                      style={StyleSheet.applyWidth(
                        { marginRight: 16 },
                        dimensions.width
                      )}
                    >
                      <Touchable
                        onPress={() => {
                          try {
                            setModalSortVisible(true);
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                      >
                        <SVG
                          source={Constants['uiIconSortWhite']}
                          style={StyleSheet.applyWidth(
                            { height: 26, width: 26 },
                            dimensions.width
                          )}
                        />
                      </Touchable>
                    </View>
                  )}
                </>
                {/* View Search Icon */}
                <>
                  {searchBarIsActive ? null : (
                    <View>
                      <Touchable
                        onPress={() => {
                          try {
                            setSearchBarIsActive(true);
                            amplitudeEvents(
                              Variables,
                              'search clients',
                              {
                                origin: 'client list screen',
                                category: 'client management',
                              },
                              undefined
                            );
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                      >
                        <SVG
                          source={Constants['uiSearchIconWhite']}
                          style={StyleSheet.applyWidth(
                            { height: 26, width: 26 },
                            dimensions.width
                          )}
                        />
                      </Touchable>
                    </View>
                  )}
                </>
              </View>
            )}
          </>
          {/* Search Bar Active Container */}
          <>
            {!searchBarIsActive ? null : (
              <View
                style={StyleSheet.applyWidth(
                  {
                    flexDirection: 'row',
                    flexGrow: 1,
                    justifyContent: 'flex-end',
                    width: '85%',
                  },
                  dimensions.width
                )}
              >
                {/* Search Bar Container */}
                <View
                  style={StyleSheet.applyWidth(
                    { flexGrow: 1 },
                    dimensions.width
                  )}
                >
                  {/* Search Bar */}
                  <TextInput
                    autoCapitalize={'none'}
                    autoCorrect={true}
                    changeTextDelay={500}
                    onChangeText={newSearchBarValue => {
                      try {
                        setSearchBarInput(newSearchBarValue);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    webShowOutline={true}
                    {...GlobalStyles.TextInputStyles(theme)['Text Input'].props}
                    placeholder={'Search client name or ID'}
                    placeholderTextColor={
                      palettes.App['App Disabled Button Text 7F868C']
                    }
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextInputStyles(theme)['Text Input'].style,
                        {
                          backgroundColor: palettes.App['Custom Color'],
                          fontFamily: 'Poppins_400Regular',
                          width: '90%',
                        }
                      ),
                      dimensions.width
                    )}
                    value={searchBarInput}
                  />
                </View>
                {/* Done */}
                <View>
                  <Button
                    iconPosition={'left'}
                    onPress={() => {
                      try {
                        setSearchBarIsActive(false);
                        setSearchBarInput(Constants['empty']);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ButtonStyles(theme)['Button'].style,
                        {
                          backgroundColor: palettes.App['App Blue C7EAF3'],
                          borderRadius: 50,
                          color: palettes.App['App Blue#4 007fd4'],
                          fontFamily: 'BebasNeue_400Regular',
                        }
                      ),
                      dimensions.width
                    )}
                    title={'Done'}
                  />
                </View>
              </View>
            )}
          </>
        </View>
      </View>
      {/* View Main Buttons Section */}
      <View
        style={StyleSheet.applyWidth(
          { marginTop: -115, width: '100%' },
          dimensions.width
        )}
      >
        {/* View Sunset Background */}
        <View
          style={StyleSheet.applyWidth(
            { height: 200, marginBottom: -80, width: '100%' },
            dimensions.width
          )}
        >
          {/* SVG Sunset Background */}
          <SVG
            source={Constants['uiBgWavesBlueRectangle']}
            style={StyleSheet.applyWidth({ width: '100%' }, dimensions.width)}
          />
        </View>
        {/* View Top Main Screens Container */}
        <View
          style={StyleSheet.applyWidth(
            {
              flexDirection: 'row',
              paddingBottom: 16,
              paddingLeft: '5.35%',
              paddingRight: '5.35%',
              paddingTop: 16,
            },
            dimensions.width
          )}
        >
          {/* View Current Clients */}
          <View
            style={StyleSheet.applyWidth({ marginRight: 12 }, dimensions.width)}
          >
            {/* Current Jobs Active */}
            <>
              {!(jobsList === 'current') ? null : (
                <Button
                  iconPosition={'left'}
                  style={StyleSheet.applyWidth(
                    {
                      backgroundColor: palettes.App['App Red EB3E26'],
                      borderRadius: 50,
                      fontFamily: 'Poppins_500Medium',
                      paddingBottom: 10,
                      paddingLeft: 16,
                      paddingRight: 16,
                      paddingTop: 10,
                      textAlign: 'center',
                    },
                    dimensions.width
                  )}
                  title={'Current'}
                />
              )}
            </>
            {/* Current Jobs Inactive */}
            <>
              {!(jobsList === 'past') ? null : (
                <Button
                  iconPosition={'left'}
                  onPress={() => {
                    try {
                      setJobsList('current');
                      const filters = createFilterParams('Matched', 365);
                      setFilters(filters);
                      amplitudeEvents(
                        Variables,
                        'view client list',
                        {
                          origin: 'client list screen',
                          category: 'client management',
                        },
                        undefined
                      );
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  style={StyleSheet.applyWidth(
                    {
                      backgroundColor: palettes.App['Custom Color'],
                      borderRadius: 50,
                      color: palettes.App['App Red EB3E26'],
                      fontFamily: 'Poppins_500Medium',
                      paddingBottom: 10,
                      paddingLeft: 16,
                      paddingRight: 16,
                      paddingTop: 10,
                      textAlign: 'center',
                    },
                    dimensions.width
                  )}
                  title={'Current'}
                />
              )}
            </>
          </View>
          {/* View Past Jobs */}
          <View
            style={StyleSheet.applyWidth({ marginRight: 12 }, dimensions.width)}
          >
            {/* Past Jobs Active */}
            <>
              {!(jobsList === 'past') ? null : (
                <Button
                  iconPosition={'left'}
                  style={StyleSheet.applyWidth(
                    {
                      backgroundColor: palettes.App['App Red EB3E26'],
                      borderRadius: 50,
                      fontFamily: 'Poppins_400Regular',
                      paddingBottom: 10,
                      paddingLeft: 16,
                      paddingRight: 16,
                      paddingTop: 10,
                      textAlign: 'center',
                    },
                    dimensions.width
                  )}
                  title={'Past'}
                />
              )}
            </>
            {/* Past Jobs Inactive */}
            <>
              {!(jobsList === 'current') ? null : (
                <Button
                  iconPosition={'left'}
                  onPress={() => {
                    try {
                      setJobsList('past');
                      amplitudeEvents(
                        Variables,
                        'view past client list',
                        {
                          origin: 'client list screen',
                          category: 'client management',
                        },
                        undefined
                      );
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  style={StyleSheet.applyWidth(
                    {
                      backgroundColor: palettes.App['Custom Color'],
                      borderRadius: 50,
                      color: palettes.App['App Red EB3E26'],
                      fontFamily: 'Poppins_500Medium',
                      paddingBottom: 10,
                      paddingLeft: 16,
                      paddingRight: 16,
                      paddingTop: 10,
                      textAlign: 'center',
                    },
                    dimensions.width
                  )}
                  title={'Past'}
                />
              )}
            </>
          </View>
        </View>
      </View>
      {/* View History Filters */}
      <>
        {!(jobsList === 'past') ? null : (
          <View
            style={StyleSheet.applyWidth(
              {
                flexDirection: 'row',
                paddingLeft: '5.35%',
                paddingRight: '5.35%',
                paddingTop: 20,
              },
              dimensions.width
            )}
          >
            {/* View Past Year */}
            <View
              style={StyleSheet.applyWidth(
                {
                  backgroundColor: palettes.App['App Disabled Button EDEDEF'],
                  borderRadius: 50,
                  paddingBottom: 6,
                  paddingLeft: 6,
                  paddingRight: 6,
                  paddingTop: 6,
                },
                dimensions.width
              )}
            >
              {/* Past 1 Year */}
              <Text
                accessible={true}
                style={StyleSheet.applyWidth(
                  {
                    color: palettes.App['App Text 2ndary 7F868C'],
                    fontFamily: 'Poppins_500Medium',
                    fontSize: 12,
                  },
                  dimensions.width
                )}
              >
                {'Past 1 Year'}
              </Text>
            </View>
          </View>
        )}
      </>
      <ScrollView
        bounces={true}
        horizontal={false}
        keyboardShouldPersistTaps={'never'}
        nestedScrollEnabled={false}
        showsHorizontalScrollIndicator={true}
        showsVerticalScrollIndicator={true}
      >
        {/* View Current Clients */}
        <>
          {!(jobsList === 'current') ? null : (
            <View
              style={StyleSheet.applyWidth(
                { backgroundColor: palettes.App['Custom Color'] },
                dimensions.width
              )}
            >
              {/* View List View */}
              <>
                {!(viewType === 'list') ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      { paddingBottom: 98, paddingTop: 5 },
                      dimensions.width
                    )}
                  >
                    {/* Fetch Matched Clients */}
                    <KnackAPIApi.FetchGetLessonsListGET
                      filters={JSON.stringify(filters)}
                      handlers={{
                        onData: fetchMatchedClientsData => {
                          try {
                            setIs_loading(true);
                            console.log('MATCHED JOBS FETCHED', jobsList);
                            setFilteredJobsCount(
                              fetchMatchedClientsData?.total_records
                            );

                            const valuevMhbT6Cw =
                              fetchMatchedClientsData?.records;
                            setSiLessonRecords(valuevMhbT6Cw);
                            const records = valuevMhbT6Cw;
                            const uniquePackages = getUniquePackages(
                              fetchMatchedClientsData?.records
                            );
                            const incompletePackages =
                              filterCompletedLessonPackages(uniquePackages);
                            const filteredClients =
                              getCurrentClientsCount(incompletePackages);
                            setCurrentClients(filteredClients);
                            setIs_loading(false);
                          } catch (err) {
                            console.error(err);
                          }
                        },
                      }}
                      rows_per_page={1000}
                      sort_order={sort_order}
                    >
                      {({ loading, error, data, refetchGetLessonsList }) => {
                        const fetchMatchedClientsData = data?.json;
                        if (loading) {
                          return (
                            <>
                              {/* FlashList 2 */}
                              <FlashList
                                data={currentClients}
                                estimatedItemSize={50}
                                horizontal={false}
                                inverted={false}
                                keyExtractor={(flashList2Data, index) =>
                                  flashList2Data?.id ??
                                  flashList2Data?.uuid ??
                                  index.toString()
                                }
                                listKey={'oF0qh6Rb'}
                                numColumns={1}
                                onEndReachedThreshold={0.5}
                                renderItem={({ item, index }) => {
                                  const flashList2Data = item;
                                  return (
                                    <>
                                      {/* Loading Screen Placeholder 2 */}
                                      <View>
                                        {/* Placeholder Card */}
                                        <View
                                          style={StyleSheet.applyWidth(
                                            {
                                              borderBottomWidth: 8,
                                              borderColor:
                                                palettes.App[
                                                  'App Disabled Button EDEDEF'
                                                ],
                                              marginBottom: 24,
                                              paddingBottom: 16,
                                              paddingLeft: '5.35%',
                                              paddingRight: '5.35%',
                                              paddingTop: 16,
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          {/* Row */}
                                          <View
                                            style={StyleSheet.applyWidth(
                                              {
                                                flexDirection: 'row',
                                                justifyContent: 'space-between',
                                                marginBottom: 8,
                                              },
                                              dimensions.width
                                            )}
                                          >
                                            {/* Left Name Plate */}
                                            <View
                                              style={StyleSheet.applyWidth(
                                                {
                                                  alignContent: 'flex-end',
                                                  alignItems: 'center',
                                                  backgroundColor:
                                                    palettes.App[
                                                      'App Blue/Low Emphasis'
                                                    ],
                                                  borderRadius: 100,
                                                  height: 48,
                                                  justifyContent: 'center',
                                                  width: 48,
                                                },
                                                dimensions.width
                                              )}
                                            />
                                            {/* Right Status Plate */}
                                            <View
                                              style={StyleSheet.applyWidth(
                                                {
                                                  alignItems: 'center',
                                                  backgroundColor:
                                                    palettes.App[
                                                      'App Blue/Low Emphasis'
                                                    ],
                                                  borderRadius: 8,
                                                  height: 48,
                                                  justifyContent: 'center',
                                                  width: '80%',
                                                },
                                                dimensions.width
                                              )}
                                            />
                                          </View>
                                          {/* Bottom Status Plate */}
                                          <View
                                            style={StyleSheet.applyWidth(
                                              {
                                                alignItems: 'center',
                                                backgroundColor:
                                                  palettes.App[
                                                    'App Blue/Low Emphasis'
                                                  ],
                                                borderRadius: 8,
                                                height: 64,
                                                justifyContent: 'center',
                                                width: '100%',
                                              },
                                              dimensions.width
                                            )}
                                          >
                                            <ActivityIndicator
                                              animating={true}
                                              hidesWhenStopped={true}
                                              size={'small'}
                                              {...GlobalStyles.ActivityIndicatorStyles(
                                                theme
                                              )['Activity Indicator'].props}
                                              style={StyleSheet.applyWidth(
                                                GlobalStyles.ActivityIndicatorStyles(
                                                  theme
                                                )['Activity Indicator'].style,
                                                dimensions.width
                                              )}
                                            />
                                          </View>
                                        </View>
                                        {/* Placeholder Card */}
                                        <View
                                          style={StyleSheet.applyWidth(
                                            {
                                              borderBottomWidth: 8,
                                              borderColor:
                                                palettes.App[
                                                  'App Disabled Button EDEDEF'
                                                ],
                                              marginBottom: 24,
                                              paddingBottom: 16,
                                              paddingLeft: '5.35%',
                                              paddingRight: '5.35%',
                                              paddingTop: 16,
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          {/* Row */}
                                          <View
                                            style={StyleSheet.applyWidth(
                                              {
                                                flexDirection: 'row',
                                                justifyContent: 'space-between',
                                                marginBottom: 8,
                                              },
                                              dimensions.width
                                            )}
                                          >
                                            {/* Left Name Plate */}
                                            <View
                                              style={StyleSheet.applyWidth(
                                                {
                                                  alignContent: 'flex-end',
                                                  alignItems: 'center',
                                                  backgroundColor:
                                                    palettes.App[
                                                      'App Blue/Low Emphasis'
                                                    ],
                                                  borderRadius: 100,
                                                  height: 48,
                                                  justifyContent: 'center',
                                                  width: 48,
                                                },
                                                dimensions.width
                                              )}
                                            />
                                            {/* Right Status Plate */}
                                            <View
                                              style={StyleSheet.applyWidth(
                                                {
                                                  alignItems: 'center',
                                                  backgroundColor:
                                                    palettes.App[
                                                      'App Blue/Low Emphasis'
                                                    ],
                                                  borderRadius: 8,
                                                  height: 48,
                                                  justifyContent: 'center',
                                                  width: '80%',
                                                },
                                                dimensions.width
                                              )}
                                            />
                                          </View>
                                          {/* Bottom Status Plate */}
                                          <View
                                            style={StyleSheet.applyWidth(
                                              {
                                                alignItems: 'center',
                                                backgroundColor:
                                                  palettes.App[
                                                    'App Blue/Low Emphasis'
                                                  ],
                                                borderRadius: 8,
                                                height: 64,
                                                justifyContent: 'center',
                                                width: '100%',
                                              },
                                              dimensions.width
                                            )}
                                          >
                                            <ActivityIndicator
                                              animating={true}
                                              hidesWhenStopped={true}
                                              size={'small'}
                                              {...GlobalStyles.ActivityIndicatorStyles(
                                                theme
                                              )['Activity Indicator'].props}
                                              style={StyleSheet.applyWidth(
                                                GlobalStyles.ActivityIndicatorStyles(
                                                  theme
                                                )['Activity Indicator'].style,
                                                dimensions.width
                                              )}
                                            />
                                          </View>
                                        </View>
                                      </View>
                                    </>
                                  );
                                }}
                                showsHorizontalScrollIndicator={true}
                                showsVerticalScrollIndicator={true}
                              />
                            </>
                          );
                        }

                        if (
                          error ||
                          data?.status < 200 ||
                          data?.status >= 300
                        ) {
                          return <ActivityIndicator />;
                        }

                        return (
                          <>
                            <>
                              {!(
                                fetchMatchedClientsData?.total_records > 0
                              ) ? null : (
                                <FlashList
                                  data={sortClients(
                                    searchFilter(currentClients)
                                  )}
                                  horizontal={false}
                                  inverted={false}
                                  keyExtractor={(flashListData, index) =>
                                    flashListData?.packageId
                                  }
                                  listKey={'dwnzjqhU'}
                                  numColumns={1}
                                  onEndReachedThreshold={0.5}
                                  renderItem={({ item, index }) => {
                                    const flashListData = item;
                                    return (
                                      <Touchable
                                        onPress={() => {
                                          try {
                                            navigation.navigate(
                                              'ClientDetailsScreen',
                                              {
                                                packageKnackId:
                                                  flashListData?.packageKnackId,
                                              }
                                            );
                                            amplitudeEvents(
                                              Variables,
                                              'view client details',
                                              {
                                                origin: 'client list screen',
                                                category: 'client management',
                                              },
                                              undefined
                                            );
                                          } catch (err) {
                                            console.error(err);
                                          }
                                        }}
                                        activeOpacity={0.8}
                                        disabledOpacity={0.8}
                                      >
                                        {/* View Open Job Card */}
                                        <View
                                          style={StyleSheet.applyWidth(
                                            {
                                              borderBottomWidth: 8,
                                              borderColor:
                                                palettes.App['App Grey E5E5E5'],
                                              paddingBottom: 20,
                                              paddingTop: 20,
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          {/* View New Tag Holder */}
                                          <View
                                            style={StyleSheet.applyWidth(
                                              { alignItems: 'flex-start' },
                                              dimensions.width
                                            )}
                                          >
                                            {/* View New Tag */}
                                            <View
                                              style={StyleSheet.applyWidth(
                                                {
                                                  alignItems: 'flex-start',
                                                  backgroundColor:
                                                    palettes.App[
                                                      'App Brand Blue 2C9AD9'
                                                    ],
                                                  borderBottomLeftRadius: 0,
                                                  borderBottomRightRadius: 30,
                                                  borderTopRightRadius: 30,
                                                  paddingBottom: 2,
                                                  paddingLeft: 10,
                                                  paddingRight: 12,
                                                  paddingTop: 2,
                                                },
                                                dimensions.width
                                              )}
                                            >
                                              {/* New */}
                                              <Text
                                                accessible={true}
                                                style={StyleSheet.applyWidth(
                                                  {
                                                    color:
                                                      palettes.App[
                                                        'Custom Color'
                                                      ],
                                                    fontFamily:
                                                      'BebasNeue_400Regular',
                                                  },
                                                  dimensions.width
                                                )}
                                              >
                                                {'NEW'}
                                              </Text>
                                            </View>
                                          </View>
                                          {/* View Card Content */}
                                          <View
                                            style={StyleSheet.applyWidth(
                                              {
                                                paddingLeft: '5.35%',
                                                paddingRight: '5.35%',
                                              },
                                              dimensions.width
                                            )}
                                          >
                                            {/* View Top Details */}
                                            <View
                                              style={StyleSheet.applyWidth(
                                                {
                                                  flexDirection: 'row',
                                                  justifyContent:
                                                    'space-between',
                                                  paddingBottom: 16,
                                                },
                                                dimensions.width
                                              )}
                                            >
                                              {/* View Left Details */}
                                              <View
                                                style={StyleSheet.applyWidth(
                                                  { flexDirection: 'row' },
                                                  dimensions.width
                                                )}
                                              >
                                                {/* View Client PP */}
                                                <View
                                                  style={StyleSheet.applyWidth(
                                                    {
                                                      height: 48,
                                                      marginRight: 8,
                                                      width: 48,
                                                    },
                                                    dimensions.width
                                                  )}
                                                >
                                                  <Surface
                                                    elevation={3}
                                                    style={StyleSheet.applyWidth(
                                                      {
                                                        borderBottomWidth: 1.25,
                                                        borderColor:
                                                          palettes.App[
                                                            'Custom Color'
                                                          ],
                                                        borderLeftWidth: 1.25,
                                                        borderRadius: 100,
                                                        borderRightWidth: 1.25,
                                                        borderTopWidth: 1.25,
                                                        height: '100%',
                                                        width: '100%',
                                                      },
                                                      dimensions.width
                                                    )}
                                                  >
                                                    {/* SVG Avatar */}
                                                    <SVG
                                                      source={useCustomerAvatar(
                                                        Variables,
                                                        flashListData?.clientAvatar
                                                      )}
                                                      style={StyleSheet.applyWidth(
                                                        {
                                                          height: '100%',
                                                          width: '100%',
                                                        },
                                                        dimensions.width
                                                      )}
                                                    />
                                                  </Surface>
                                                  {/* View Initials Container */}
                                                  <View
                                                    style={StyleSheet.applyWidth(
                                                      {
                                                        alignItems: 'center',
                                                        height: 48,
                                                        justifyContent:
                                                          'center',
                                                        marginTop: -48,
                                                        width: 48,
                                                      },
                                                      dimensions.width
                                                    )}
                                                  >
                                                    {/* Initials */}
                                                    <Text
                                                      accessible={true}
                                                      style={StyleSheet.applyWidth(
                                                        {
                                                          color:
                                                            palettes.App[
                                                              'Custom Color'
                                                            ],
                                                          fontFamily:
                                                            'Poppins_500Medium',
                                                          fontSize: 24,
                                                        },
                                                        dimensions.width
                                                      )}
                                                    >
                                                      {getUserInitials(
                                                        flashListData?.client
                                                      )}
                                                    </Text>
                                                  </View>
                                                </View>
                                                {/* View Client Details */}
                                                <View>
                                                  {/* View Client Name */}
                                                  <View>
                                                    {/* Client Name */}
                                                    <Text
                                                      accessible={true}
                                                      style={StyleSheet.applyWidth(
                                                        {
                                                          color:
                                                            palettes.App[
                                                              'App Black 191F32'
                                                            ],
                                                          fontFamily:
                                                            'Poppins_600SemiBold',
                                                          textTransform:
                                                            'capitalize',
                                                        },
                                                        dimensions.width
                                                      )}
                                                    >
                                                      {flashListData?.client}
                                                    </Text>
                                                  </View>
                                                  {/* View St Address */}
                                                  <View>
                                                    {/* Street 1 */}
                                                    <Text
                                                      accessible={true}
                                                      style={StyleSheet.applyWidth(
                                                        {
                                                          color:
                                                            palettes.App[
                                                              'App Text 2ndary 7F868C'
                                                            ],
                                                          fontFamily:
                                                            'Poppins_400Regular',
                                                          fontSize: 12,
                                                        },
                                                        dimensions.width
                                                      )}
                                                    >
                                                      {'ID '}
                                                      {flashListData?.packageId}
                                                    </Text>
                                                  </View>
                                                </View>
                                              </View>
                                              {/* View Right Details */}
                                              <View
                                                style={StyleSheet.applyWidth(
                                                  { alignItems: 'flex-end' },
                                                  dimensions.width
                                                )}
                                              >
                                                {/* Upcoming lessons */}
                                                <View>
                                                  {/* Next lesson */}
                                                  <Text
                                                    accessible={true}
                                                    style={StyleSheet.applyWidth(
                                                      {
                                                        color:
                                                          palettes.App[
                                                            'App Text 2ndary 7F868C'
                                                          ],
                                                        fontFamily:
                                                          'Poppins_400Regular',
                                                        fontSize: 12,
                                                      },
                                                      dimensions.width
                                                    )}
                                                  >
                                                    {'Next lesson'}
                                                  </Text>
                                                </View>
                                                {/* Lesson Date */}
                                                <View
                                                  style={StyleSheet.applyWidth(
                                                    { flexDirection: 'row' },
                                                    dimensions.width
                                                  )}
                                                >
                                                  {/* Date */}
                                                  <Text
                                                    accessible={true}
                                                    style={StyleSheet.applyWidth(
                                                      {
                                                        color:
                                                          palettes.App[
                                                            'App Black 191F32'
                                                          ],
                                                        fontFamily:
                                                          'Poppins_600SemiBold',
                                                      },
                                                      dimensions.width
                                                    )}
                                                  >
                                                    {getNextScheduledLessonDate(
                                                      flashListData?.scheduledLessons
                                                    )}
                                                  </Text>
                                                </View>
                                              </View>
                                            </View>
                                            {/* View Progress Details */}
                                            <View
                                              style={StyleSheet.applyWidth(
                                                {
                                                  paddingBottom: 16,
                                                  paddingTop: 16,
                                                },
                                                dimensions.width
                                              )}
                                            >
                                              {/* View Progress Bar Lessons */}
                                              <View
                                                style={StyleSheet.applyWidth(
                                                  { marginBottom: -6 },
                                                  dimensions.width
                                                )}
                                              >
                                                <Utils.CustomCodeErrorBoundary>
                                                  <nativeProgress.Bar
                                                    lessonsCompleted={
                                                      flashListData?.lessonsCompleted
                                                    }
                                                    lessonsQuantity={
                                                      flashListData?.totalLessons
                                                    }
                                                  ></nativeProgress.Bar>
                                                </Utils.CustomCodeErrorBoundary>
                                              </View>
                                              {/* View Progress Bar Payment */}
                                              <View
                                                style={StyleSheet.applyWidth(
                                                  { marginBottom: 4 },
                                                  dimensions.width
                                                )}
                                              >
                                                <Utils.CustomCodeErrorBoundary>
                                                  <nativeProgress.Bar
                                                    lessonsCompleted={
                                                      flashListData?.amountPaid
                                                    }
                                                    lessonsQuantity={
                                                      flashListData?.totalPay
                                                    }
                                                    fillColor={'#1DB058'}
                                                    unfilledColor={'#FF'}
                                                    height={6}
                                                  ></nativeProgress.Bar>
                                                </Utils.CustomCodeErrorBoundary>
                                              </View>
                                              {/* Progress Details */}
                                              <View
                                                style={StyleSheet.applyWidth(
                                                  { flexDirection: 'row' },
                                                  dimensions.width
                                                )}
                                              >
                                                {/* Lessons complete */}
                                                <View
                                                  style={StyleSheet.applyWidth(
                                                    {
                                                      alignItems: 'flex-start',
                                                      flexDirection: 'row',
                                                      marginBottom: 8,
                                                      marginRight: 4,
                                                    },
                                                    dimensions.width
                                                  )}
                                                >
                                                  {/* Completed lessons */}
                                                  <Text
                                                    accessible={true}
                                                    style={StyleSheet.applyWidth(
                                                      {
                                                        color:
                                                          palettes.App[
                                                            'App Gold FFA900'
                                                          ],
                                                        fontFamily:
                                                          'Poppins_600SemiBold',
                                                        fontSize: 12,
                                                      },
                                                      dimensions.width
                                                    )}
                                                  >
                                                    {
                                                      flashListData?.lessonsCompleted
                                                    }{' '}
                                                  </Text>
                                                  {/* of All Lessons */}
                                                  <Text
                                                    accessible={true}
                                                    style={StyleSheet.applyWidth(
                                                      {
                                                        color:
                                                          palettes.App[
                                                            'App Text 2ndary 7F868C'
                                                          ],
                                                        fontFamily:
                                                          'Poppins_400Regular',
                                                        fontSize: 12,
                                                      },
                                                      dimensions.width
                                                    )}
                                                  >
                                                    {'of '}
                                                    {
                                                      flashListData?.totalLessons
                                                    }{' '}
                                                  </Text>
                                                  {/* Lessons */}
                                                  <Text
                                                    accessible={true}
                                                    style={StyleSheet.applyWidth(
                                                      {
                                                        color:
                                                          palettes.App[
                                                            'App Text 2ndary 7F868C'
                                                          ],
                                                        fontFamily:
                                                          'Poppins_400Regular',
                                                        fontSize: 12,
                                                      },
                                                      dimensions.width
                                                    )}
                                                  >
                                                    {'lessons '}
                                                  </Text>
                                                  {/* Completed */}
                                                  <Text
                                                    accessible={true}
                                                    style={StyleSheet.applyWidth(
                                                      {
                                                        color:
                                                          palettes.App[
                                                            'App Text 2ndary 7F868C'
                                                          ],
                                                        fontFamily:
                                                          'Poppins_400Regular',
                                                        fontSize: 12,
                                                      },
                                                      dimensions.width
                                                    )}
                                                  >
                                                    {'completed ·'}
                                                  </Text>
                                                </View>
                                                {/* Pay */}
                                                <View
                                                  style={StyleSheet.applyWidth(
                                                    {
                                                      flexDirection: 'row',
                                                      flexWrap: 'wrap',
                                                      marginBottom: 8,
                                                    },
                                                    dimensions.width
                                                  )}
                                                >
                                                  {/* Amount paid */}
                                                  <Text
                                                    accessible={true}
                                                    style={StyleSheet.applyWidth(
                                                      {
                                                        color:
                                                          palettes.App[
                                                            'App Text Green #1DB058'
                                                          ],
                                                        fontFamily:
                                                          'Poppins_600SemiBold',
                                                        fontSize: 12,
                                                      },
                                                      dimensions.width
                                                    )}
                                                  >
                                                    {'$'}
                                                    {
                                                      flashListData?.amountPaid
                                                    }{' '}
                                                  </Text>
                                                  {/* Total amount */}
                                                  <Text
                                                    accessible={true}
                                                    style={StyleSheet.applyWidth(
                                                      {
                                                        color:
                                                          palettes.App[
                                                            'App Text 2ndary 7F868C'
                                                          ],
                                                        fontFamily:
                                                          'Poppins_400Regular',
                                                        fontSize: 12,
                                                      },
                                                      dimensions.width
                                                    )}
                                                  >
                                                    {'of $'}
                                                    {flashListData?.totalPay}
                                                    {' paid'}
                                                  </Text>
                                                </View>
                                              </View>
                                            </View>
                                          </View>
                                        </View>
                                      </Touchable>
                                    );
                                  }}
                                  showsHorizontalScrollIndicator={true}
                                  showsVerticalScrollIndicator={true}
                                  contentContainerStyle={StyleSheet.applyWidth(
                                    { flex: 1 },
                                    dimensions.width
                                  )}
                                  estimatedItemSize={25}
                                />
                              )}
                            </>
                          </>
                        );
                      }}
                    </KnackAPIApi.FetchGetLessonsListGET>
                    {/* Loading Screen Placeholder */}
                    <>
                      {!is_loading ? null : (
                        <View>
                          {/* Placeholder Card */}
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                borderBottomWidth: 8,
                                borderColor:
                                  palettes.App['App Disabled Button EDEDEF'],
                                marginBottom: 24,
                                paddingBottom: 16,
                                paddingLeft: '5.35%',
                                paddingRight: '5.35%',
                                paddingTop: 16,
                              },
                              dimensions.width
                            )}
                          >
                            {/* Row */}
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  flexDirection: 'row',
                                  justifyContent: 'space-between',
                                  marginBottom: 8,
                                },
                                dimensions.width
                              )}
                            >
                              {/* Left Name Plate */}
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    alignContent: 'flex-end',
                                    alignItems: 'center',
                                    backgroundColor:
                                      palettes.App['App Blue/Low Emphasis'],
                                    borderRadius: 100,
                                    height: 48,
                                    justifyContent: 'center',
                                    width: 48,
                                  },
                                  dimensions.width
                                )}
                              />
                              {/* Right Status Plate */}
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    alignItems: 'center',
                                    backgroundColor:
                                      palettes.App['App Blue/Low Emphasis'],
                                    borderRadius: 8,
                                    height: 48,
                                    justifyContent: 'center',
                                    width: '80%',
                                  },
                                  dimensions.width
                                )}
                              />
                            </View>
                            {/* Bottom Status Plate */}
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: 'center',
                                  backgroundColor:
                                    palettes.App['App Blue/Low Emphasis'],
                                  borderRadius: 8,
                                  height: 64,
                                  justifyContent: 'center',
                                  width: '100%',
                                },
                                dimensions.width
                              )}
                            >
                              <ActivityIndicator
                                animating={true}
                                hidesWhenStopped={true}
                                size={'small'}
                                {...GlobalStyles.ActivityIndicatorStyles(theme)[
                                  'Activity Indicator'
                                ].props}
                                style={StyleSheet.applyWidth(
                                  GlobalStyles.ActivityIndicatorStyles(theme)[
                                    'Activity Indicator'
                                  ].style,
                                  dimensions.width
                                )}
                              />
                            </View>
                          </View>
                          {/* Placeholder Card */}
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                borderBottomWidth: 8,
                                borderColor:
                                  palettes.App['App Disabled Button EDEDEF'],
                                marginBottom: 24,
                                paddingBottom: 16,
                                paddingLeft: '5.35%',
                                paddingRight: '5.35%',
                                paddingTop: 16,
                              },
                              dimensions.width
                            )}
                          >
                            {/* Row */}
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  flexDirection: 'row',
                                  justifyContent: 'space-between',
                                  marginBottom: 8,
                                },
                                dimensions.width
                              )}
                            >
                              {/* Left Name Plate */}
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    alignContent: 'flex-end',
                                    alignItems: 'center',
                                    backgroundColor:
                                      palettes.App['App Blue/Low Emphasis'],
                                    borderRadius: 100,
                                    height: 48,
                                    justifyContent: 'center',
                                    width: 48,
                                  },
                                  dimensions.width
                                )}
                              />
                              {/* Right Status Plate */}
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    alignItems: 'center',
                                    backgroundColor:
                                      palettes.App['App Blue/Low Emphasis'],
                                    borderRadius: 8,
                                    height: 48,
                                    justifyContent: 'center',
                                    width: '80%',
                                  },
                                  dimensions.width
                                )}
                              />
                            </View>
                            {/* Bottom Status Plate */}
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: 'center',
                                  backgroundColor:
                                    palettes.App['App Blue/Low Emphasis'],
                                  borderRadius: 8,
                                  height: 64,
                                  justifyContent: 'center',
                                  width: '100%',
                                },
                                dimensions.width
                              )}
                            >
                              <ActivityIndicator
                                animating={true}
                                hidesWhenStopped={true}
                                size={'small'}
                                {...GlobalStyles.ActivityIndicatorStyles(theme)[
                                  'Activity Indicator'
                                ].props}
                                style={StyleSheet.applyWidth(
                                  GlobalStyles.ActivityIndicatorStyles(theme)[
                                    'Activity Indicator'
                                  ].style,
                                  dimensions.width
                                )}
                              />
                            </View>
                          </View>
                        </View>
                      )}
                    </>
                    {/* Loading Complete No Clients */}
                    <>
                      {is_loading ? null : (
                        <View
                          style={StyleSheet.applyWidth(
                            { alignItems: 'center' },
                            dimensions.width
                          )}
                        >
                          {/* no clients */}
                          <>
                            {!(currentClientsCount < 1) ? null : (
                              <View>
                                {/* empty result no jobs clients */}
                                <Surface
                                  {...GlobalStyles.SurfaceStyles(theme)[
                                    'Surface'
                                  ].props}
                                  elevation={3}
                                  style={StyleSheet.applyWidth(
                                    GlobalStyles.SurfaceStyles(theme)['Surface']
                                      .style,
                                    dimensions.width
                                  )}
                                >
                                  {/* View Card */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        alignItems: 'center',
                                        backgroundColor:
                                          palettes.App['Custom Color'],
                                        borderRadius: 16,
                                        padding: 20,
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    {/* Card Title */}
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          flexDirection: 'row',
                                          justifyContent: 'center',
                                          marginBottom: 20,
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      {/* H1 */}
                                      <Text
                                        accessible={true}
                                        style={StyleSheet.applyWidth(
                                          {
                                            color:
                                              palettes.App['App Black 191F32'],
                                            fontFamily: 'BebasNeue_400Regular',
                                            fontSize: 24,
                                            marginRight: 4,
                                          },
                                          dimensions.width
                                        )}
                                      >
                                        {'Calm Waters'}
                                      </Text>
                                      {/* H1 Accent */}
                                      <Text
                                        accessible={true}
                                        style={StyleSheet.applyWidth(
                                          {
                                            color:
                                              palettes.App['App Red EB3E26'],
                                            fontFamily:
                                              'LeckerliOne_400Regular',
                                            fontSize: 22,
                                          },
                                          dimensions.width
                                        )}
                                      >
                                        {'Here...'}
                                      </Text>
                                    </View>
                                    {/* Open Job Count */}
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          flexDirection: 'row',
                                          justifyContent: 'center',
                                          marginBottom: 20,
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      <SVG
                                        {...GlobalStyles.SVGStyles(theme)['SVG']
                                          .props}
                                        source={
                                          Constants['uiDrawingStarfishLounging']
                                        }
                                        style={StyleSheet.applyWidth(
                                          StyleSheet.compose(
                                            GlobalStyles.SVGStyles(theme)['SVG']
                                              .style,
                                            { height: 256, width: 232 }
                                          ),
                                          dimensions.width
                                        )}
                                      />
                                    </View>
                                    {/* H2 Container */}
                                    <View
                                      style={StyleSheet.applyWidth(
                                        { marginBottom: 8 },
                                        dimensions.width
                                      )}
                                    >
                                      <Text
                                        accessible={true}
                                        {...GlobalStyles.TextStyles(theme)[
                                          'Text'
                                        ].props}
                                        style={StyleSheet.applyWidth(
                                          StyleSheet.compose(
                                            GlobalStyles.TextStyles(theme)[
                                              'Text'
                                            ].style,
                                            {
                                              fontFamily: 'Poppins_600SemiBold',
                                              fontSize: 20,
                                              textAlign: 'center',
                                            }
                                          ),
                                          dimensions.width
                                        )}
                                      >
                                        {"You Don't Have Any Current Clients"}
                                      </Text>
                                    </View>

                                    <View>
                                      <Text
                                        accessible={true}
                                        {...GlobalStyles.TextStyles(theme)[
                                          'Text'
                                        ].props}
                                        style={StyleSheet.applyWidth(
                                          StyleSheet.compose(
                                            GlobalStyles.TextStyles(theme)[
                                              'Text'
                                            ].style,
                                            {
                                              color:
                                                palettes.App[
                                                  'App Disabled Button Text 7F868C'
                                                ],
                                              fontFamily: 'Poppins_400Regular',
                                              textAlign: 'center',
                                            }
                                          ),
                                          dimensions.width
                                        )}
                                      >
                                        {getRandomMessage(
                                          noCurrentClientsMessages
                                        )}
                                      </Text>
                                    </View>
                                  </View>
                                </Surface>
                              </View>
                            )}
                          </>
                          {/* button request more clients */}
                          <View
                            {...GlobalStyles.ViewStyles(theme)[
                              'button full width with disabled'
                            ].props}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.ViewStyles(theme)[
                                  'button full width with disabled'
                                ].style,
                                { marginTop: 16, width: '88%' }
                              ),
                              dimensions.width
                            )}
                          >
                            {/* View Open Jobs */}
                            <Button
                              iconPosition={'left'}
                              onPress={() => {
                                try {
                                  setGlobalVariableValue({
                                    key: 'screen',
                                    value: 'jobs',
                                  });
                                  navigation.navigate('JobsScreen');
                                  amplitudeEvents(
                                    Variables,
                                    'view open jobs list',
                                    {
                                      step: '01',
                                      funnel: 'request job',
                                      origin: 'client list screen',
                                      category: 'opportunity management',
                                    },
                                    undefined
                                  );
                                } catch (err) {
                                  console.error(err);
                                }
                              }}
                              style={StyleSheet.applyWidth(
                                {
                                  backgroundColor:
                                    palettes.App['App Red EB3E26'],
                                  borderRadius: 50,
                                  fontFamily: 'BebasNeue_400Regular',
                                  fontSize: 18,
                                  paddingBottom: 14,
                                  paddingTop: 14,
                                  textAlign: 'center',
                                  width: '100%',
                                },
                                dimensions.width
                              )}
                              title={'Request More Clients'}
                            />
                          </View>
                        </View>
                      )}
                    </>
                  </View>
                )}
              </>
            </View>
          )}
        </>
        {/* View Past Jobs */}
        <>
          {!(jobsList === 'past') ? null : (
            <View
              style={StyleSheet.applyWidth(
                { paddingBottom: 98 },
                dimensions.width
              )}
            >
              {/* View List View */}
              <View
                style={StyleSheet.applyWidth(
                  { paddingBottom: 98, paddingTop: 5 },
                  dimensions.width
                )}
              >
                <FlashList
                  data={sortClients(
                    searchFilter(
                      filterIncompleteLessonPackages(
                        getUniquePackages(siLessonRecords)
                      )
                    )
                  )}
                  horizontal={false}
                  inverted={false}
                  keyExtractor={(flashListData, index) =>
                    flashListData?.packageId
                  }
                  listKey={'YUanKpSN'}
                  numColumns={1}
                  onEndReachedThreshold={0.5}
                  renderItem={({ item, index }) => {
                    const flashListData = item;
                    return (
                      <Pressable
                        onPress={() => {
                          try {
                            navigation.navigate('ClientDetailsScreen', {
                              packageKnackId: flashListData?.packageKnackId,
                              packageStatus: 'complete',
                            });
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                      >
                        {/* View Open Job Card */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              borderBottomWidth: 8,
                              borderColor: palettes.App['App Grey E5E5E5'],
                              paddingBottom: 20,
                              paddingTop: 20,
                            },
                            dimensions.width
                          )}
                        >
                          {/* View Card Content */}
                          <View
                            style={StyleSheet.applyWidth(
                              { paddingLeft: '5.35%', paddingRight: '5.35%' },
                              dimensions.width
                            )}
                          >
                            {/* View Top Details */}
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  flexDirection: 'row',
                                  justifyContent: 'space-between',
                                  paddingBottom: 16,
                                },
                                dimensions.width
                              )}
                            >
                              {/* View Left Details */}
                              <View
                                style={StyleSheet.applyWidth(
                                  { flexDirection: 'row' },
                                  dimensions.width
                                )}
                              >
                                {/* View Client PP */}
                                <View
                                  style={StyleSheet.applyWidth(
                                    { height: 48, marginRight: 8, width: 48 },
                                    dimensions.width
                                  )}
                                >
                                  <Surface
                                    elevation={3}
                                    style={StyleSheet.applyWidth(
                                      {
                                        borderBottomWidth: 1.25,
                                        borderColor:
                                          palettes.App['Custom Color'],
                                        borderLeftWidth: 1.25,
                                        borderRadius: 100,
                                        borderRightWidth: 1.25,
                                        borderTopWidth: 1.25,
                                        height: '100%',
                                        width: '100%',
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    {/* SVG Avatar */}
                                    <SVG
                                      source={Constants['uiAvatar1']}
                                      style={StyleSheet.applyWidth(
                                        { height: '100%', width: '100%' },
                                        dimensions.width
                                      )}
                                    />
                                  </Surface>
                                  {/* View Initials Container */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        alignItems: 'center',
                                        height: 48,
                                        justifyContent: 'center',
                                        marginTop: -48,
                                        width: 48,
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    {/* Initials */}
                                    <Text
                                      accessible={true}
                                      style={StyleSheet.applyWidth(
                                        {
                                          color: palettes.App['Custom Color'],
                                          fontFamily: 'Poppins_500Medium',
                                          fontSize: 24,
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      {getUserInitials(flashListData?.client)}
                                    </Text>
                                  </View>
                                </View>
                                {/* View Client Details */}
                                <View>
                                  {/* View Client Name */}
                                  <View>
                                    {/* Client Name */}
                                    <Text
                                      accessible={true}
                                      style={StyleSheet.applyWidth(
                                        {
                                          color:
                                            palettes.App['App Black 191F32'],
                                          fontFamily: 'Poppins_600SemiBold',
                                          textTransform: 'capitalize',
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      {flashListData?.client}
                                    </Text>
                                  </View>
                                  {/* Package ID */}
                                  <View>
                                    {/* Package ID */}
                                    <Text
                                      accessible={true}
                                      style={StyleSheet.applyWidth(
                                        {
                                          color:
                                            palettes.App[
                                              'App Text 2ndary 7F868C'
                                            ],
                                          fontFamily: 'Poppins_400Regular',
                                          fontSize: 12,
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      {'ID '}
                                      {flashListData?.packageId}
                                    </Text>
                                  </View>
                                </View>
                              </View>
                              {/* View Right Details */}
                              <View
                                style={StyleSheet.applyWidth(
                                  { alignItems: 'flex-end' },
                                  dimensions.width
                                )}
                              >
                                {/* View Lesson Details */}
                                <View>
                                  {/* Completed */}
                                  <Text
                                    accessible={true}
                                    style={StyleSheet.applyWidth(
                                      {
                                        color:
                                          palettes.App[
                                            'App Text 2ndary 7F868C'
                                          ],
                                        fontFamily: 'Poppins_400Regular',
                                        fontSize: 12,
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    {'Completed'}
                                  </Text>
                                </View>
                                {/* View Pay Details */}
                                <View
                                  style={StyleSheet.applyWidth(
                                    { flexDirection: 'row' },
                                    dimensions.width
                                  )}
                                >
                                  {/* CompletionDate */}
                                  <Text
                                    accessible={true}
                                    style={StyleSheet.applyWidth(
                                      {
                                        color: palettes.App['App Black 191F32'],
                                        fontFamily: 'Poppins_600SemiBold',
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    {getLastLessonCompletedDate(
                                      flashListData?.completedLessons
                                    )}
                                  </Text>
                                </View>
                              </View>
                            </View>
                            {/* View Progress Details */}
                            <View
                              style={StyleSheet.applyWidth(
                                { paddingBottom: 16, paddingTop: 16 },
                                dimensions.width
                              )}
                            >
                              {/* View Progress Bar Lessons */}
                              <View
                                style={StyleSheet.applyWidth(
                                  { marginBottom: -6 },
                                  dimensions.width
                                )}
                              >
                                <Utils.CustomCodeErrorBoundary>
                                  <nativeProgress.Bar
                                    lessonsCompleted={
                                      flashListData?.lessonsCompleted
                                    }
                                    lessonsQuantity={
                                      flashListData?.totalLessons
                                    }
                                  ></nativeProgress.Bar>
                                </Utils.CustomCodeErrorBoundary>
                              </View>
                              {/* View Progress Bar Payment */}
                              <View
                                style={StyleSheet.applyWidth(
                                  { marginBottom: 4 },
                                  dimensions.width
                                )}
                              >
                                <Utils.CustomCodeErrorBoundary>
                                  <nativeProgress.Bar
                                    lessonsCompleted={flashListData?.amountPaid}
                                    lessonsQuantity={flashListData?.totalPay}
                                    fillColor={'#1DB058'}
                                    unfilledColor={'#FF'}
                                    height={6}
                                  ></nativeProgress.Bar>
                                </Utils.CustomCodeErrorBoundary>
                              </View>
                              {/* View Row */}
                              <View
                                style={StyleSheet.applyWidth(
                                  { flexDirection: 'row' },
                                  dimensions.width
                                )}
                              >
                                {/* Lessons complete */}
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      alignItems: 'flex-start',
                                      flexDirection: 'row',
                                      marginBottom: 8,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {/* Completed lessons */}
                                  <Text
                                    accessible={true}
                                    style={StyleSheet.applyWidth(
                                      {
                                        color:
                                          palettes.App[
                                            'App Text 2ndary 7F868C'
                                          ],
                                        fontFamily: 'Poppins_600SemiBold',
                                        fontSize: 12,
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    {flashListData?.lessonsCompleted}{' '}
                                  </Text>
                                  {/* of */}
                                  <Text
                                    accessible={true}
                                    style={StyleSheet.applyWidth(
                                      {
                                        color:
                                          palettes.App[
                                            'App Text 2ndary 7F868C'
                                          ],
                                        fontFamily: 'Poppins_400Regular',
                                        fontSize: 12,
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    {'of '}
                                  </Text>
                                  {/* All lessons */}
                                  <Text
                                    accessible={true}
                                    style={StyleSheet.applyWidth(
                                      {
                                        color:
                                          palettes.App[
                                            'App Text 2ndary 7F868C'
                                          ],
                                        fontFamily: 'Poppins_400Regular',
                                        fontSize: 12,
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    {flashListData?.totalLessons}{' '}
                                  </Text>
                                  {/* Lessons Completed */}
                                  <Text
                                    accessible={true}
                                    style={StyleSheet.applyWidth(
                                      {
                                        color:
                                          palettes.App[
                                            'App Text 2ndary 7F868C'
                                          ],
                                        fontFamily: 'Poppins_400Regular',
                                        fontSize: 12,
                                        marginRight: 4,
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    {'lessons completed · '}
                                  </Text>
                                </View>
                                {/* Pay */}
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      flexDirection: 'row',
                                      flexWrap: 'wrap',
                                      marginBottom: 8,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {/* Amount paid */}
                                  <Text
                                    accessible={true}
                                    style={StyleSheet.applyWidth(
                                      {
                                        color:
                                          palettes.App[
                                            'App Text Green #1DB058'
                                          ],
                                        fontFamily: 'Poppins_600SemiBold',
                                        fontSize: 12,
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    {'$'}
                                    {flashListData?.amountPaid}{' '}
                                  </Text>
                                  {/* Total amount */}
                                  <Text
                                    accessible={true}
                                    style={StyleSheet.applyWidth(
                                      {
                                        color:
                                          palettes.App[
                                            'App Text 2ndary 7F868C'
                                          ],
                                        fontFamily: 'Poppins_400Regular',
                                        fontSize: 12,
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    {'of $'}
                                    {flashListData?.totalPay}
                                    {' paid'}
                                  </Text>
                                </View>
                              </View>
                            </View>
                          </View>
                        </View>
                      </Pressable>
                    );
                  }}
                  showsHorizontalScrollIndicator={true}
                  showsVerticalScrollIndicator={true}
                  contentContainerStyle={StyleSheet.applyWidth(
                    { flex: 1 },
                    dimensions.width
                  )}
                  estimatedItemSize={25}
                />
              </View>
            </View>
          )}
        </>
      </ScrollView>
      {/* Footer Navigation 2023-09-08 */}
      <View
        {...GlobalStyles.ViewStyles(theme)['Footer Navigation 2023-09-9'].props}
        style={StyleSheet.applyWidth(
          GlobalStyles.ViewStyles(theme)['Footer Navigation 2023-09-9'].style,
          dimensions.width
        )}
      >
        <Surface
          {...GlobalStyles.SurfaceStyles(theme)['Surface'].props}
          elevation={3}
          style={StyleSheet.applyWidth(
            GlobalStyles.SurfaceStyles(theme)['Surface'].style,
            dimensions.width
          )}
        >
          {/* View Navigation Icons Container */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                alignSelf: 'auto',
                flex: 0,
                flexDirection: 'row',
                justifyContent: 'space-around',
                marginBottom: 12,
                marginTop: 12,
              },
              dimensions.width
            )}
          >
            {/* View Home Container */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  flex: 0,
                  justifyContent: 'space-between',
                  width: '20%',
                },
                dimensions.width
              )}
            >
              <Touchable
                onPress={() => {
                  try {
                    setGlobalVariableValue({
                      key: 'screen',
                      value: 'home',
                    });
                    navigation.navigate('HomeScreen');
                  } catch (err) {
                    console.error(err);
                  }
                }}
                style={StyleSheet.applyWidth(
                  { width: '100%' },
                  dimensions.width
                )}
              >
                {/* View Touchable Home */}
                <View
                  style={StyleSheet.applyWidth(
                    { alignItems: 'center', justifyContent: 'space-between' },
                    dimensions.width
                  )}
                >
                  {/* View Home Icon */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        height: 24,
                        justifyContent: 'center',
                        overflow: 'hidden',
                        width: 24,
                      },
                      dimensions.width
                    )}
                  >
                    {/* Home Icon Inactive */}
                    <>
                      {!(Constants['screen'] !== 'home') ? null : (
                        <ImageBackground
                          resizeMode={'cover'}
                          source={Images.IconHomeGrey}
                          style={StyleSheet.applyWidth(
                            { height: '100%', width: '100%' },
                            dimensions.width
                          )}
                        />
                      )}
                    </>
                    {/* Home Icon Active */}
                    <>
                      {!(Constants['screen'] === 'home') ? null : (
                        <ImageBackground
                          resizeMode={'cover'}
                          source={Images.IconHomeBlue}
                          style={StyleSheet.applyWidth(
                            { height: '100%', width: '100%' },
                            dimensions.width
                          )}
                        />
                      )}
                    </>
                  </View>
                  {/* Label Inactive */}
                  <>
                    {!(Constants['screen'] !== 'home') ? null : (
                      <Text
                        accessible={true}
                        style={StyleSheet.applyWidth(
                          {
                            alignSelf: 'center',
                            color: palettes.App['App Text 2ndary 7F868C'],
                            fontFamily: 'Poppins_400Regular',
                            fontSize: 11,
                          },
                          dimensions.width
                        )}
                      >
                        {'Home'}
                      </Text>
                    )}
                  </>
                  {/* Label Active */}
                  <>
                    {!(Constants['screen'] === 'home') ? null : (
                      <Text
                        accessible={true}
                        style={StyleSheet.applyWidth(
                          {
                            alignSelf: 'center',
                            color: palettes.App['App Black 191F32'],
                            fontFamily: 'Poppins_400Regular',
                            fontSize: 11,
                          },
                          dimensions.width
                        )}
                      >
                        {'Home'}
                      </Text>
                    )}
                  </>
                </View>
              </Touchable>
            </View>
            {/* View Jobs Container */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  flex: 0,
                  justifyContent: 'space-between',
                  width: '20%',
                },
                dimensions.width
              )}
            >
              <Touchable
                onPress={() => {
                  try {
                    setGlobalVariableValue({
                      key: 'screen',
                      value: 'jobs',
                    });
                    navigation.navigate('JobsScreen');
                  } catch (err) {
                    console.error(err);
                  }
                }}
                style={StyleSheet.applyWidth(
                  { width: '100%' },
                  dimensions.width
                )}
              >
                {/* View Touchable Jobs */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      paddingBottom: 0,
                      paddingLeft: 0,
                      paddingRight: 0,
                      paddingTop: 0,
                      width: '100%',
                    },
                    dimensions.width
                  )}
                >
                  {/* View Jobs Icon */}
                  <View
                    style={StyleSheet.applyWidth(
                      { height: 24, overflow: 'hidden', width: 24 },
                      dimensions.width
                    )}
                  >
                    {/* Jobs Icon Inactive */}
                    <>
                      {!(Constants['screen'] !== 'jobs') ? null : (
                        <ImageBackground
                          resizeMode={'cover'}
                          source={{
                            uri: 'https://apps-draftbit-com.s3.amazonaws.com/apps/GZlNa4ih/assets/9VnVvkN5D5yhOBLJhFW7N',
                          }}
                          style={StyleSheet.applyWidth(
                            { height: '100%', width: '100%' },
                            dimensions.width
                          )}
                        />
                      )}
                    </>
                    {/* Jobs Icon Active */}
                    <>
                      {!(Constants['screen'] === 'jobs') ? null : (
                        <ImageBackground
                          resizeMode={'cover'}
                          source={Images.IconSearchBlue}
                          style={StyleSheet.applyWidth(
                            { height: '100%', width: '100%' },
                            dimensions.width
                          )}
                        />
                      )}
                    </>
                  </View>
                  {/* Label Inactive */}
                  <>
                    {!(Constants['screen'] !== 'jobs') ? null : (
                      <Text
                        accessible={true}
                        style={StyleSheet.applyWidth(
                          {
                            alignSelf: 'center',
                            color: palettes.App['App Text 2ndary 7F868C'],
                            fontFamily: 'Poppins_400Regular',
                            fontSize: 11,
                          },
                          dimensions.width
                        )}
                      >
                        {'Jobs'}
                      </Text>
                    )}
                  </>
                  {/* Label Active */}
                  <>
                    {!(Constants['screen'] === 'jobs') ? null : (
                      <Text
                        accessible={true}
                        style={StyleSheet.applyWidth(
                          {
                            alignSelf: 'center',
                            color: palettes.App['App Black 191F32'],
                            fontFamily: 'Poppins_400Regular',
                            fontSize: 11,
                          },
                          dimensions.width
                        )}
                      >
                        {'Jobs'}
                      </Text>
                    )}
                  </>
                </View>
              </Touchable>
            </View>
            {/* View Clients Container */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  flex: 0,
                  justifyContent: 'space-between',
                  width: '20%',
                },
                dimensions.width
              )}
            >
              <Touchable
                onPress={() => {
                  try {
                    setGlobalVariableValue({
                      key: 'screen',
                      value: 'clients',
                    });
                    navigation.navigate('Clients2Screen');
                  } catch (err) {
                    console.error(err);
                  }
                }}
                style={StyleSheet.applyWidth(
                  { width: '100%' },
                  dimensions.width
                )}
              >
                {/* View Touchable Clients */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      paddingBottom: 0,
                      paddingLeft: 0,
                      paddingRight: 0,
                      paddingTop: 0,
                      width: '100%',
                    },
                    dimensions.width
                  )}
                >
                  {/* View Clients Icon */}
                  <View
                    style={StyleSheet.applyWidth(
                      { height: 24, overflow: 'hidden', width: 24 },
                      dimensions.width
                    )}
                  >
                    {/* Clients Icon Active */}
                    <>
                      {!(Constants['screen'] !== 'clients') ? null : (
                        <ImageBackground
                          resizeMode={'cover'}
                          source={Images.IconBriefcaseGrey}
                          style={StyleSheet.applyWidth(
                            { height: '100%', width: '100%' },
                            dimensions.width
                          )}
                        />
                      )}
                    </>
                    {/* Clients Icon Active */}
                    <>
                      {!(Constants['screen'] === 'clients') ? null : (
                        <ImageBackground
                          resizeMode={'cover'}
                          source={Images.IconBriefcaseBlue}
                          style={StyleSheet.applyWidth(
                            { height: '100%', width: '100%' },
                            dimensions.width
                          )}
                        />
                      )}
                    </>
                  </View>
                  {/* Label Inactive */}
                  <>
                    {!(Constants['screen'] !== 'clients') ? null : (
                      <Text
                        accessible={true}
                        style={StyleSheet.applyWidth(
                          {
                            alignSelf: 'center',
                            color: palettes.App['App Text 2ndary 7F868C'],
                            fontFamily: 'Poppins_400Regular',
                            fontSize: 11,
                          },
                          dimensions.width
                        )}
                      >
                        {'Clients'}
                      </Text>
                    )}
                  </>
                  {/* Label Active */}
                  <>
                    {!(Constants['screen'] === 'clients') ? null : (
                      <Text
                        accessible={true}
                        style={StyleSheet.applyWidth(
                          {
                            alignSelf: 'center',
                            color: palettes.App['App Black 191F32'],
                            fontFamily: 'Poppins_400Regular',
                            fontSize: 11,
                          },
                          dimensions.width
                        )}
                      >
                        {'Clients'}
                      </Text>
                    )}
                  </>
                </View>
              </Touchable>
            </View>
            {/* View Profile Container */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  alignSelf: 'center',
                  flex: 0,
                  justifyContent: 'space-between',
                  width: '20%',
                },
                dimensions.width
              )}
            >
              <Touchable
                onPress={() => {
                  try {
                    setGlobalVariableValue({
                      key: 'screen',
                      value: 'profile',
                    });
                    navigation.navigate('MyAccountScreen');
                  } catch (err) {
                    console.error(err);
                  }
                }}
                style={StyleSheet.applyWidth(
                  { width: '100%' },
                  dimensions.width
                )}
              >
                {/* View Touchable Profile */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      paddingBottom: 0,
                      paddingLeft: 0,
                      paddingRight: 0,
                      paddingTop: 0,
                      width: '100%',
                    },
                    dimensions.width
                  )}
                >
                  {/* View Profile Icon */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        height: 24,
                        overflow: 'hidden',
                        width: 24,
                      },
                      dimensions.width
                    )}
                  >
                    {/* Profile Icon Inactive */}
                    <>
                      {!(Constants['screen'] !== 'profile') ? null : (
                        <ImageBackground
                          resizeMode={'cover'}
                          source={Images.IconProfileGrey}
                          style={StyleSheet.applyWidth(
                            { height: '100%', width: '100%' },
                            dimensions.width
                          )}
                        />
                      )}
                    </>
                    {/* Profile Icon Active */}
                    <>
                      {!(Constants['screen'] === 'profile') ? null : (
                        <ImageBackground
                          resizeMode={'cover'}
                          source={Images.IconProfileBlue}
                          style={StyleSheet.applyWidth(
                            { height: '100%', width: '100%' },
                            dimensions.width
                          )}
                        />
                      )}
                    </>
                  </View>
                  {/* Label Inactive */}
                  <>
                    {!(Constants['screen'] !== 'profile') ? null : (
                      <Text
                        accessible={true}
                        style={StyleSheet.applyWidth(
                          {
                            alignSelf: 'center',
                            color: palettes.App['App Text 2ndary 7F868C'],
                            fontFamily: 'Poppins_400Regular',
                            fontSize: 11,
                          },
                          dimensions.width
                        )}
                      >
                        {'Account'}
                      </Text>
                    )}
                  </>
                  {/* Label Active */}
                  <>
                    {!(Constants['screen'] === 'profile') ? null : (
                      <Text
                        accessible={true}
                        style={StyleSheet.applyWidth(
                          {
                            alignSelf: 'center',
                            color: palettes.App['App Black 191F32'],
                            fontFamily: 'Poppins_400Regular',
                            fontSize: 11,
                          },
                          dimensions.width
                        )}
                      >
                        {'Account'}
                      </Text>
                    )}
                  </>
                </View>
              </Touchable>
            </View>
            {/* View Knowledge Container */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  flex: 0,
                  justifyContent: 'space-between',
                  width: '20%',
                },
                dimensions.width
              )}
            >
              <Touchable
                onPress={() => {
                  try {
                    setGlobalVariableValue({
                      key: 'screen',
                      value: 'knowledge',
                    });
                    navigation.navigate('KnowledgeBaseScreen');
                  } catch (err) {
                    console.error(err);
                  }
                }}
                style={StyleSheet.applyWidth(
                  { width: '100%' },
                  dimensions.width
                )}
              >
                {/* View Touchable Profile */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      paddingBottom: 0,
                      paddingLeft: 0,
                      paddingRight: 0,
                      paddingTop: 0,
                      width: '100%',
                    },
                    dimensions.width
                  )}
                >
                  {/* View Profile Icon */}
                  <View
                    style={StyleSheet.applyWidth(
                      { height: 24, overflow: 'hidden', width: 24 },
                      dimensions.width
                    )}
                  >
                    {/* Knowledge Icon Inactive */}
                    <>
                      {!(Constants['screen'] !== 'knowledge') ? null : (
                        <ImageBackground
                          resizeMode={'cover'}
                          source={Images.IconKnowledgeGrey}
                          style={StyleSheet.applyWidth(
                            { height: '100%', width: '100%' },
                            dimensions.width
                          )}
                        />
                      )}
                    </>
                    {/* Knowledge Icon Active */}
                    <>
                      {!(Constants['screen'] === 'knowledge') ? null : (
                        <ImageBackground
                          resizeMode={'cover'}
                          source={Images.IconKnowledgeBlue}
                          style={StyleSheet.applyWidth(
                            { height: '100%', width: '100%' },
                            dimensions.width
                          )}
                        />
                      )}
                    </>
                  </View>
                  {/* Label Inactive */}
                  <>
                    {!(Constants['screen'] !== 'profile') ? null : (
                      <Text
                        accessible={true}
                        style={StyleSheet.applyWidth(
                          {
                            alignSelf: 'center',
                            color: palettes.App['App Text 2ndary 7F868C'],
                            fontFamily: 'Poppins_400Regular',
                            fontSize: 11,
                          },
                          dimensions.width
                        )}
                      >
                        {'Know'}
                      </Text>
                    )}
                  </>
                  {/* Label Active */}
                  <>
                    {!(Constants['screen'] === 'profile') ? null : (
                      <Text
                        accessible={true}
                        style={StyleSheet.applyWidth(
                          {
                            alignSelf: 'center',
                            color: palettes.App['App Black 191F32'],
                            fontFamily: 'Poppins_400Regular',
                            fontSize: 11,
                          },
                          dimensions.width
                        )}
                      >
                        {'Know'}
                      </Text>
                    )}
                  </>
                </View>
              </Touchable>
            </View>
          </View>
          <>
            {!!(Platform.OS === 'android') ? null : (
              <Spacer bottom={34} left={0} right={0} top={0} />
            )}
          </>
        </Surface>
      </View>
      {/* Modal Sort Options */}
      <Modal
        supportedOrientations={['portrait', 'landscape']}
        animationType={'slide'}
        presentationStyle={'overFullScreen'}
        transparent={true}
        visible={modalSortVisible}
      >
        <View
          style={StyleSheet.applyWidth(
            {
              backgroundColor: palettes.App['App Transparent'],
              height: '100%',
              width: '100%',
            },
            dimensions.width
          )}
        >
          <View
            style={StyleSheet.applyWidth(
              {
                backgroundColor: palettes.App['Custom Color'],
                borderRadius: 0,
                borderTopLeftRadius: 20,
                borderTopRightRadius: 20,
                bottom: 0,
                justifyContent: 'flex-end',
                minHeight: '75%',
                paddingLeft: '5.35%',
                paddingRight: '5.35%',
                paddingTop: 13,
                position: 'absolute',
                width: '100%',
              },
              dimensions.width
            )}
          >
            {/* View Drag Container */}
            <View
              style={StyleSheet.applyWidth(
                { alignItems: 'center', marginBottom: 13 },
                dimensions.width
              )}
            >
              <Touchable
                onPress={() => {
                  try {
                    setModalSortVisible(false);
                  } catch (err) {
                    console.error(err);
                  }
                }}
              >
                <View
                  style={StyleSheet.applyWidth(
                    {
                      backgroundColor:
                        palettes.App['App Disabled Button EDEDEF'],
                      borderRadius: 40,
                      height: 3,
                      width: 40,
                    },
                    dimensions.width
                  )}
                />
              </Touchable>
            </View>
            {/* Modal Title Container */}
            <View
              style={StyleSheet.applyWidth(
                {
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  paddingBottom: 24,
                },
                dimensions.width
              )}
            >
              {/* View Modal Title */}
              <View>
                {/* Sort by */}
                <Text
                  accessible={true}
                  style={StyleSheet.applyWidth(
                    {
                      alignSelf: 'flex-start',
                      color: palettes.App['App Black 191F32'],
                      fontFamily: 'Poppins_600SemiBold',
                      fontSize: 16,
                      textAlign: 'left',
                    },
                    dimensions.width
                  )}
                >
                  {'Sort by'}
                </Text>
              </View>

              <Touchable
                onPress={() => {
                  try {
                    setModalSortVisible(false);
                  } catch (err) {
                    console.error(err);
                  }
                }}
              >
                {/* View Modal Title */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      backgroundColor:
                        palettes.App['App Disabled Button EDEDEF'],
                      borderRadius: 30,
                      flexDirection: 'column',
                      paddingBottom: 4,
                      paddingLeft: 6,
                      paddingRight: 6,
                      paddingTop: 4,
                    },
                    dimensions.width
                  )}
                >
                  {/* close */}
                  <Text
                    accessible={true}
                    style={StyleSheet.applyWidth(
                      {
                        alignSelf: 'flex-start',
                        color: palettes.App['App Disabled Button Text 7F868C'],
                        fontFamily: 'Poppins_400Regular',
                        fontSize: 12,
                        textAlign: 'left',
                      },
                      dimensions.width
                    )}
                  >
                    {'close x'}
                  </Text>
                </View>
              </Touchable>
            </View>
            {/* View Section Sort Options */}
            <View
              style={StyleSheet.applyWidth(
                { paddingBottom: 20, paddingTop: 0 },
                dimensions.width
              )}
            >
              {/* View Row Newest Job */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignContent: 'center',
                    borderBottomWidth: 1,
                    borderColor: palettes.App['App Grey Inputs E2E2E2'],
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    paddingBottom: 18,
                    paddingTop: 18,
                  },
                  dimensions.width
                )}
              >
                <Touchable
                  onPress={() => {
                    try {
                      setSortBy('newest');
                      amplitudeEvents(
                        Variables,
                        'sort clients',
                        {
                          origin: 'client list screen',
                          category: 'client management',
                        },
                        'newest'
                      );
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                >
                  {/* View Newest Job */}
                  <View
                    style={StyleSheet.applyWidth(
                      { flexDirection: 'row', justifyContent: 'space-between' },
                      dimensions.width
                    )}
                  >
                    {/* View Text Container */}
                    <View
                      style={StyleSheet.applyWidth(
                        { justifyContent: 'center' },
                        dimensions.width
                      )}
                    >
                      {/* Newest Clients */}
                      <Text
                        accessible={true}
                        style={StyleSheet.applyWidth(
                          {
                            color: palettes.App['App Black 191F32'],
                            fontFamily: 'Poppins_400Regular',
                          },
                          dimensions.width
                        )}
                      >
                        {'Newest Client'}
                      </Text>
                    </View>
                    {/* View Status Icon Container */}
                    <>
                      {!(sortBy === 'newest') ? null : (
                        <View
                          style={StyleSheet.applyWidth(
                            { flexDirection: 'row', justifyContent: 'center' },
                            dimensions.width
                          )}
                        >
                          {/* View Active  */}
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                height: 20,
                                justifyContent: 'center',
                                width: 20,
                              },
                              dimensions.width
                            )}
                          >
                            {/* Icon Active */}
                            <SVG
                              source={
                                'https://sss-app-graphics.s3.us-west-1.amazonaws.com/icons/icon_checkmark_complete.svg'
                              }
                              style={StyleSheet.applyWidth(
                                { height: '100%', width: '100%' },
                                dimensions.width
                              )}
                            />
                          </View>
                        </View>
                      )}
                    </>
                  </View>
                </Touchable>
              </View>
              {/* View Row Earliest Client */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignContent: 'center',
                    borderBottomWidth: 1,
                    borderColor: palettes.App['App Grey Inputs E2E2E2'],
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    paddingBottom: 18,
                    paddingTop: 18,
                  },
                  dimensions.width
                )}
              >
                <Touchable
                  onPress={() => {
                    try {
                      setSortBy('earliest');
                      amplitudeEvents(
                        Variables,
                        'sort clients',
                        {
                          origin: 'client list screen',
                          category: 'client management',
                        },
                        'earliest'
                      );
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                >
                  {/* View Earliest Job */}
                  <View
                    style={StyleSheet.applyWidth(
                      { flexDirection: 'row', justifyContent: 'space-between' },
                      dimensions.width
                    )}
                  >
                    {/* View Text Container */}
                    <View
                      style={StyleSheet.applyWidth(
                        { justifyContent: 'center' },
                        dimensions.width
                      )}
                    >
                      {/* Earliest Clients */}
                      <Text
                        accessible={true}
                        style={StyleSheet.applyWidth(
                          {
                            color: palettes.App['App Black 191F32'],
                            fontFamily: 'Poppins_400Regular',
                          },
                          dimensions.width
                        )}
                      >
                        {'Earliest Client'}
                      </Text>
                    </View>
                    {/* View Status Icon Container */}
                    <>
                      {!(sortBy === 'earliest') ? null : (
                        <View
                          style={StyleSheet.applyWidth(
                            { flexDirection: 'row', justifyContent: 'center' },
                            dimensions.width
                          )}
                        >
                          {/* View Active  */}
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                height: 20,
                                justifyContent: 'center',
                                width: 20,
                              },
                              dimensions.width
                            )}
                          >
                            {/* Icon Active */}
                            <SVG
                              source={
                                'https://sss-app-graphics.s3.us-west-1.amazonaws.com/icons/icon_checkmark_complete.svg'
                              }
                              style={StyleSheet.applyWidth(
                                { height: '100%', width: '100%' },
                                dimensions.width
                              )}
                            />
                          </View>
                        </View>
                      )}
                    </>
                  </View>
                </Touchable>
              </View>
              {/* View Row Upcoming Lesson */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignContent: 'center',
                    borderBottomWidth: 1,
                    borderColor: palettes.App['App Grey Inputs E2E2E2'],
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    paddingBottom: 18,
                    paddingTop: 18,
                  },
                  dimensions.width
                )}
              >
                <Touchable
                  onPress={() => {
                    try {
                      setSortBy('upcomingLesson');
                      amplitudeEvents(
                        Variables,
                        'sort clients',
                        {
                          origin: 'client list screen',
                          category: 'client management',
                        },
                        'soonest upcoming lesson'
                      );
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                >
                  {/* View Oldest Job */}
                  <View
                    style={StyleSheet.applyWidth(
                      { flexDirection: 'row', justifyContent: 'space-between' },
                      dimensions.width
                    )}
                  >
                    {/* View Text Container */}
                    <View
                      style={StyleSheet.applyWidth(
                        { justifyContent: 'center' },
                        dimensions.width
                      )}
                    >
                      {/* Next upcoming lesson */}
                      <Text
                        accessible={true}
                        style={StyleSheet.applyWidth(
                          {
                            color: palettes.App['App Black 191F32'],
                            fontFamily: 'Poppins_400Regular',
                          },
                          dimensions.width
                        )}
                      >
                        {'Soonest Upcoming Lesson'}
                      </Text>
                    </View>
                    {/* View Status Icon Container */}
                    <>
                      {!(sortBy === 'upcomingLesson') ? null : (
                        <View
                          style={StyleSheet.applyWidth(
                            { flexDirection: 'row', justifyContent: 'center' },
                            dimensions.width
                          )}
                        >
                          {/* View Active  */}
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                height: 20,
                                justifyContent: 'center',
                                width: 20,
                              },
                              dimensions.width
                            )}
                          >
                            {/* Icon Active */}
                            <SVG
                              source={
                                'https://sss-app-graphics.s3.us-west-1.amazonaws.com/icons/icon_checkmark_complete.svg'
                              }
                              style={StyleSheet.applyWidth(
                                { height: '100%', width: '100%' },
                                dimensions.width
                              )}
                            />
                          </View>
                        </View>
                      )}
                    </>
                  </View>
                </Touchable>
              </View>
              {/* View Row Most Complete */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignContent: 'center',
                    borderBottomWidth: 1,
                    borderColor: palettes.App['App Grey Inputs E2E2E2'],
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    paddingBottom: 18,
                    paddingTop: 18,
                  },
                  dimensions.width
                )}
              >
                <Touchable
                  onPress={() => {
                    try {
                      setSortBy('mostComplete');
                      amplitudeEvents(
                        Variables,
                        'sort clients',
                        {
                          origin: 'client list screen',
                          category: 'client management',
                        },
                        'most complete'
                      );
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                >
                  {/* View Most Complete Jobs */}
                  <View
                    style={StyleSheet.applyWidth(
                      { flexDirection: 'row', justifyContent: 'space-between' },
                      dimensions.width
                    )}
                  >
                    {/* View Text Container */}
                    <View
                      style={StyleSheet.applyWidth(
                        { justifyContent: 'center' },
                        dimensions.width
                      )}
                    >
                      {/* Most Complete */}
                      <Text
                        accessible={true}
                        style={StyleSheet.applyWidth(
                          {
                            color: palettes.App['App Black 191F32'],
                            fontFamily: 'Poppins_400Regular',
                          },
                          dimensions.width
                        )}
                      >
                        {'Most Complete'}
                      </Text>
                    </View>
                    {/* View Status Icon Container */}
                    <>
                      {!(sortBy === 'mostComplete') ? null : (
                        <View
                          style={StyleSheet.applyWidth(
                            { flexDirection: 'row', justifyContent: 'center' },
                            dimensions.width
                          )}
                        >
                          {/* View Active  */}
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                height: 20,
                                justifyContent: 'center',
                                width: 20,
                              },
                              dimensions.width
                            )}
                          >
                            {/* Icon Active */}
                            <SVG
                              source={
                                'https://sss-app-graphics.s3.us-west-1.amazonaws.com/icons/icon_checkmark_complete.svg'
                              }
                              style={StyleSheet.applyWidth(
                                { height: '100%', width: '100%' },
                                dimensions.width
                              )}
                            />
                          </View>
                        </View>
                      )}
                    </>
                  </View>
                </Touchable>
              </View>
              {/* View Row Most Unfinished */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignContent: 'center',
                    borderBottomWidth: 1,
                    borderColor: palettes.App['App Grey Inputs E2E2E2'],
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    paddingBottom: 18,
                    paddingTop: 18,
                  },
                  dimensions.width
                )}
              >
                <Touchable
                  onPress={() => {
                    try {
                      setSortBy('mostUnfinished');
                      amplitudeEvents(
                        Variables,
                        'sort clients',
                        {
                          origin: 'client list screen',
                          category: 'client management',
                        },
                        'most unfinished'
                      );
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                >
                  {/* View Most Unfinished Job */}
                  <View
                    style={StyleSheet.applyWidth(
                      { flexDirection: 'row', justifyContent: 'space-between' },
                      dimensions.width
                    )}
                  >
                    {/* View Text Container */}
                    <View
                      style={StyleSheet.applyWidth(
                        { justifyContent: 'center' },
                        dimensions.width
                      )}
                    >
                      {/* Most Unfinished */}
                      <Text
                        accessible={true}
                        style={StyleSheet.applyWidth(
                          {
                            color: palettes.App['App Black 191F32'],
                            fontFamily: 'Poppins_400Regular',
                          },
                          dimensions.width
                        )}
                      >
                        {'Most Unfinished'}
                      </Text>
                    </View>
                    {/* View Status Icon Container */}
                    <>
                      {!(sortBy === 'mostUnfinished') ? null : (
                        <View
                          style={StyleSheet.applyWidth(
                            { flexDirection: 'row', justifyContent: 'center' },
                            dimensions.width
                          )}
                        >
                          {/* View Active  */}
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                height: 20,
                                justifyContent: 'center',
                                width: 20,
                              },
                              dimensions.width
                            )}
                          >
                            {/* Icon Active */}
                            <SVG
                              source={
                                'https://sss-app-graphics.s3.us-west-1.amazonaws.com/icons/icon_checkmark_complete.svg'
                              }
                              style={StyleSheet.applyWidth(
                                { height: '100%', width: '100%' },
                                dimensions.width
                              )}
                            />
                          </View>
                        </View>
                      )}
                    </>
                  </View>
                </Touchable>
              </View>
              {/* View Row Client Name AZ */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignContent: 'center',
                    borderBottomWidth: 1,
                    borderColor: palettes.App['App Grey Inputs E2E2E2'],
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    paddingBottom: 18,
                    paddingTop: 18,
                  },
                  dimensions.width
                )}
              >
                <Touchable
                  onPress={() => {
                    try {
                      setSortBy('clientNameAZ');
                      amplitudeEvents(
                        Variables,
                        'sort clients',
                        {
                          origin: 'client list screen',
                          category: 'client management',
                        },
                        'client name az'
                      );
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                >
                  {/* View Client Name */}
                  <View
                    style={StyleSheet.applyWidth(
                      { flexDirection: 'row', justifyContent: 'space-between' },
                      dimensions.width
                    )}
                  >
                    {/* View Text Container */}
                    <View
                      style={StyleSheet.applyWidth(
                        { justifyContent: 'center' },
                        dimensions.width
                      )}
                    >
                      {/* Client Name AZ */}
                      <Text
                        accessible={true}
                        style={StyleSheet.applyWidth(
                          {
                            color: palettes.App['App Black 191F32'],
                            fontFamily: 'Poppins_400Regular',
                          },
                          dimensions.width
                        )}
                      >
                        {'Client Name (A to Z)'}
                      </Text>
                    </View>
                    {/* View Status Icon Container */}
                    <>
                      {!(sortBy === 'clientNameAZ') ? null : (
                        <View
                          style={StyleSheet.applyWidth(
                            { flexDirection: 'row', justifyContent: 'center' },
                            dimensions.width
                          )}
                        >
                          {/* View Active  */}
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                height: 20,
                                justifyContent: 'center',
                                width: 20,
                              },
                              dimensions.width
                            )}
                          >
                            {/* Icon Active */}
                            <SVG
                              source={
                                'https://sss-app-graphics.s3.us-west-1.amazonaws.com/icons/icon_checkmark_complete.svg'
                              }
                              style={StyleSheet.applyWidth(
                                { height: '100%', width: '100%' },
                                dimensions.width
                              )}
                            />
                          </View>
                        </View>
                      )}
                    </>
                  </View>
                </Touchable>
              </View>
              {/* View Row Client Name ZA */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignContent: 'center',
                    borderBottomWidth: 1,
                    borderColor: palettes.App['App Grey Inputs E2E2E2'],
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    paddingBottom: 18,
                    paddingTop: 18,
                  },
                  dimensions.width
                )}
              >
                <Touchable
                  onPress={() => {
                    try {
                      setSortBy('clientNameZA');
                      amplitudeEvents(
                        Variables,
                        'sort clients',
                        {
                          origin: 'client list screen',
                          category: 'client management',
                        },
                        'client name za'
                      );
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                >
                  {/* View Client Name */}
                  <View
                    style={StyleSheet.applyWidth(
                      { flexDirection: 'row', justifyContent: 'space-between' },
                      dimensions.width
                    )}
                  >
                    {/* View Text Container */}
                    <View
                      style={StyleSheet.applyWidth(
                        { justifyContent: 'center' },
                        dimensions.width
                      )}
                    >
                      {/* Client Name ZA */}
                      <Text
                        accessible={true}
                        style={StyleSheet.applyWidth(
                          {
                            color: palettes.App['App Black 191F32'],
                            fontFamily: 'Poppins_400Regular',
                          },
                          dimensions.width
                        )}
                      >
                        {'Client Name (Z to A)'}
                      </Text>
                    </View>
                    {/* View Status Icon Container */}
                    <>
                      {!(sortBy === 'clientNameZA') ? null : (
                        <View
                          style={StyleSheet.applyWidth(
                            { flexDirection: 'row', justifyContent: 'center' },
                            dimensions.width
                          )}
                        >
                          {/* View Active  */}
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                height: 20,
                                justifyContent: 'center',
                                width: 20,
                              },
                              dimensions.width
                            )}
                          >
                            {/* Icon Active */}
                            <SVG
                              source={
                                'https://sss-app-graphics.s3.us-west-1.amazonaws.com/icons/icon_checkmark_complete.svg'
                              }
                              style={StyleSheet.applyWidth(
                                { height: '100%', width: '100%' },
                                dimensions.width
                              )}
                            />
                          </View>
                        </View>
                      )}
                    </>
                  </View>
                </Touchable>
              </View>
            </View>
            {/* View Buttons Container */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  alignSelf: 'center',
                  bottom: 0,
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  position: 'relative',
                  width: '89.3%',
                },
                dimensions.width
              )}
            >
              <Surface
                elevation={3}
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    borderRadius: 50,
                    minHeight: 40,
                    width: '100%',
                  },
                  dimensions.width
                )}
              >
                {/* Sort */}
                <Button
                  iconPosition={'left'}
                  onPress={() => {
                    try {
                      setModalSortVisible(false);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  style={StyleSheet.applyWidth(
                    {
                      backgroundColor: palettes.App['App Red EB3E26'],
                      borderRadius: 50,
                      fontFamily: 'BebasNeue_400Regular',
                      fontSize: 18,
                      letterSpacing: 1.2,
                      paddingBottom: 14,
                      paddingTop: 14,
                      textAlign: 'center',
                      width: '100%',
                    },
                    dimensions.width
                  )}
                  title={'Sort'}
                />
                {/* View Activity Indicator */}
                <>
                  {!Constants['is_loading'] ? null : (
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          backgroundColor: palettes.App['App Red EB3E26'],
                          borderRadius: 50,
                          marginTop: -50,
                          opacity: 0.8,
                          paddingBottom: 7,
                          paddingTop: 7,
                          width: '100%',
                        },
                        dimensions.width
                      )}
                    >
                      <ActivityIndicator
                        animating={true}
                        hidesWhenStopped={true}
                        size={'small'}
                        color={palettes.App['Custom Color']}
                        style={StyleSheet.applyWidth(
                          { height: 36, width: 36 },
                          dimensions.width
                        )}
                      />
                    </View>
                  )}
                </>
              </Surface>
              {/* View Sort Button Container */}
              <View
                style={StyleSheet.applyWidth(
                  { alignItems: 'center', marginBottom: 32 },
                  dimensions.width
                )}
              />
            </View>
          </View>
        </View>
      </Modal>
    </ScreenContainer>
  );
};

export default withTheme(Clients2Screen);
