import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as KnackAPIApi from '../apis/KnackAPIApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import * as Track from '../custom-files/Track';
import * as Utility from '../custom-files/Utility';
import amplitudeEvents from '../global-functions/amplitudeEvents';
import getUserInitials from '../global-functions/getUserInitials';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import openImagePickerUtil from '../utils/openImagePicker';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  Button,
  Link,
  SVG,
  ScreenContainer,
  Spacer,
  TextInput,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import {
  ActivityIndicator,
  Image,
  ImageBackground,
  Text,
  View,
} from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';

const Onboarding2InstructorBioScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [bioCharacterCount, setBioCharacterCount] = React.useState(0);
  const [bioImageBase64, setBioImageBase64] = React.useState('');
  const [dontSaveImageToServer, setDontSaveImageToServer] =
    React.useState(true);
  const [previousBioImage, setPreviousBioImage] = React.useState({});
  const [previousBioText, setPreviousBioText] = React.useState('');
  const navOnboardingNext = () => {
    const {
      gBioImage,
      gBioText,
      gCprImage,
      gTipaltiComplete,
      gSunsational101Complete,
      onboardingSteps,
    } = Variables;

    if (gTipaltiComplete == false) {
      props.navigation.navigate('Onboarding3CompleteTipaltiFormsScreen');
    }

    if (!gSunsational101Complete) {
      props.navigation.navigate('OnboardingCompleteSunsational101Screen');
    }

    if (
      gBioImage == '' ||
      gBioText == '' ||
      gCprImage == '' ||
      gTipaltiComplete == false ||
      gSunsational101Complete
    ) {
      props.navigation.navigate('Onboard4IncompleteWarningScreen');
    }

    props.navigation.navigate('HomeScreen');
  };

  const shouldSkipTipalti = () => {
    if (Variables.gTipaltiComplete == false) {
      return false;
    }

    return true;
  };

  // Upload bio image to Knack
  const uploadBioImage = async (Variables, setGlobalVariableValue) => {
    const bioImageFromCamera = await Utility.openImagePicker({});

    if (!bioImageFromCamera) {
      console.log('No image uploaded');
      return;
    }

    await setGlobalVariableValue({
      key: 'gBioImage',
      value: bioImageFromCamera,
    });

    // await setGlobalVariableValue({
    //     key: 'is_loading',
    //     value: true
    // });

    let bioImage = bioImageFromCamera.split(',');
    let base64Data = bioImage[1];

    setBioImageBase64(base64Data);
    setDontSaveImageToServer(false);

    // let requestOptions = {
    //   method: 'POST',
    //   headers: {
    //         'Content-Type': 'application/json',
    //         'Accept': 'application/json'
    //   },
    //   body: JSON.stringify({
    //     base64Data: base64Data,
    //     knackUserRecordId: Variables.AUTH_KNACK_USER_ID,
    //     email: Variables.gInstructorEmailAddress
    //   })
    // }

    // try {
    //     const uploadImage = await fetch(`https://hook.integromat.com/nuut1szlzkxz6qa78nmz03t9m1ndpuqe`, requestOptions);
    //     const uploadImageResponse = await uploadImage.json();

    //     console.log('uploadImageResponse ==== ', uploadImageResponse.profilePicUrl)

    //     if (!uploadImage.ok) {
    //         setGlobalVariableValue({
    //             key: 'gUploadError',
    //             value: uploadImageResponse.errorMessage
    //         });

    //         throw new Error(`Error! status: ${response.status}`);
    //     }

    //     const updateAppVariables = [
    //       setGlobalVariableValue({
    //         key: 'gBioImageUrl',
    //         value: uploadImageResponse.profilePicUrl
    //       }),
    //       setGlobalVariableValue({
    //         key: 'is_loading',
    //         value: false
    //       })
    //     ];

    //     const updateAppVariablesComplete = Promise.all(updateAppVariables);

    // } catch (err) {
    //     console.log('CATCH ERROR BLOCK!')
    //     console.error(`What is the error?: ${err.message}`);

    //     setGlobalVariableValue({
    //         key: 'is_loading',
    //         value: false,
    //     });
    // }
  };

  const shouldUpdateServer = () => {
    if (
      Variables.gBioImage != previousBioImage ||
      Variables.gBioText != previousBioText
    ) {
      return true;
    }

    return false;
  };

  const shouldSaveImageToServer = Variables => {
    if (Variables.gBioImage != previousBioImage) {
      console.log('save image to server necessary');
    } else {
      console.log('do NOT save bio image');
    }

    return false;
  };

  const saveBioImageToServer = async (
    Variables,
    setGlobalVariableValue,
    newBioImage
  ) => {
    let bioImageData = newBioImage.split(',');
    let base64Data = bioImageData[1];

    let requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        base64Data: base64Data,
        knackUserRecordId: Variables.AUTH_KNACK_USER_ID,
        email: Variables.gInstructorEmailAddress,
      }),
    };

    try {
      const uploadImage = await fetch(
        `https://hook.integromat.com/nuut1szlzkxz6qa78nmz03t9m1ndpuqe`,
        requestOptions
      );
      const uploadImageResponse = await uploadImage.json();

      console.log(
        'uploadImageResponse ==== ',
        uploadImageResponse.profilePicUrl
      );

      if (!uploadImage.ok) {
        setGlobalVariableValue({
          key: 'gUploadError',
          value: uploadImageResponse.errorMessage,
        });

        throw new Error(`Error! status: ${response.status}`);
      }

      const updateAppVariables = [
        setGlobalVariableValue({
          key: 'gBioImageUrl',
          value: uploadImageResponse.profilePicUrl,
        }),
        // setGlobalVariableValue({
        //   key: 'is_loading',
        //   value: false
        // })
      ];

      const updateAppVariablesComplete = Promise.all(updateAppVariables);
    } catch (err) {
      console.log('CATCH ERROR BLOCK!');
      console.error(`What is the error?: ${err.message}`);

      setGlobalVariableValue({
        key: 'is_loading',
        value: false,
      });
    }
  };
  React.useEffect(() => {
    Track.onEnterScreen(
      'Onboarding2InstructorBioScreen',
      Variables,
      setGlobalVariableValue,
      props.navigation.navigate,
      props?.route?.params?.skipAuth
    );
    setPreviousBioImage(Variables.gBioImage);
    setPreviousBioText(Variables.gBioText);
    setBioCharacterCount(Variables.gBioText?.length);
  }, []);
  const knackAPIUpdateInstructorBiographyPUT =
    KnackAPIApi.useUpdateInstructorBiographyPUT();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      if (!(props.route?.params?.skip ?? '')) {
        return;
      }
      navigation.navigate('Onboarding3CompleteTipaltiFormsScreen', {
        skip: shouldSkipTipalti(),
      });
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={false}
      hasTopSafeArea={false}
      style={StyleSheet.applyWidth(
        { backgroundColor: palettes.App['App Gold #FFC400'] },
        dimensions.width
      )}
    >
      {/* View Header Container */}
      <View
        style={StyleSheet.applyWidth(
          {
            height: '10%',
            justifyContent: 'center',
            maxHeight: '10%',
            paddingBottom: 15,
          },
          dimensions.width
        )}
      >
        {/* Spacer Top Nav */}
        <Spacer bottom={0} left={0} right={0} top={44} />
        {/* View Header Navigation */}
        <View
          style={StyleSheet.applyWidth(
            {
              flexDirection: 'row',
              justifyContent: 'space-between',
              paddingLeft: '5.35%',
              paddingRight: '5.35%',
            },
            dimensions.width
          )}
        >
          {/* View Back Button Container */}
          <View
            style={StyleSheet.applyWidth(
              { height: 24, width: 24 },
              dimensions.width
            )}
          >
            <Touchable
              onPress={() => {
                try {
                  navigation.goBack();
                } catch (err) {
                  console.error(err);
                }
              }}
              style={StyleSheet.applyWidth(
                { height: '100%', width: '100%' },
                dimensions.width
              )}
            >
              {/* View Touchable Settings */}
              <View
                style={StyleSheet.applyWidth(
                  { height: '100%', width: '100%' },
                  dimensions.width
                )}
              >
                <SVG
                  source={Constants['uiBackArrow']}
                  style={StyleSheet.applyWidth(
                    { height: '100%', width: '100%' },
                    dimensions.width
                  )}
                />
              </View>
            </Touchable>
          </View>
          {/* View Page Title Container */}
          <View>
            {/* Page Title */}
            <Text
              accessible={true}
              style={StyleSheet.applyWidth(
                {
                  alignSelf: 'center',
                  color: theme.colors.text.strong,
                  fontFamily: 'Poppins_700Bold',
                },
                dimensions.width
              )}
            >
              {'Complete Profile'}
            </Text>
          </View>
          {/* View Skip Container */}
          <View>
            {/* Skip */}
            <Link
              accessible={true}
              onPress={() => {
                try {
                  navigation.navigate('Onboarding3CompleteTipaltiFormsScreen', {
                    skip: shouldSkipTipalti(),
                  });
                  amplitudeEvents(
                    Variables,
                    'skip profile setup',
                    {
                      step: '02',
                      funnel: 'onboarding',
                      origin: 'complete profile screen',
                      category: 'onboarding',
                    },
                    undefined
                  );
                } catch (err) {
                  console.error(err);
                }
              }}
              style={StyleSheet.applyWidth(
                {
                  color: palettes.App['App Black 191F32'],
                  fontFamily: 'BebasNeue_400Regular',
                  fontSize: 18,
                  textDecorationLine: 'underline',
                  textTransform: 'uppercase',
                },
                dimensions.width
              )}
              title={'SKIP'}
            />
          </View>
        </View>
      </View>
      {/* View Card Container */}
      <View
        style={StyleSheet.applyWidth(
          {
            backgroundColor: palettes.App['Custom Color'],
            borderRadius: 0,
            borderTopLeftRadius: '16',
            borderTopRightRadius: '16',
            height: '90%',
            justifyContent: 'space-between',
            paddingLeft: '5.35%',
            paddingRight: '5.35%',
            width: '100%',
          },
          dimensions.width
        )}
      >
        {/* View Card Title Container */}
        <View
          style={StyleSheet.applyWidth(
            { paddingTop: 22, width: '100%' },
            dimensions.width
          )}
        >
          {/* Title */}
          <Text
            accessible={true}
            style={StyleSheet.applyWidth(
              {
                color: palettes.App['App Black 191F32'],
                fontFamily: 'Poppins_600SemiBold',
                fontSize: 20,
                textAlign: 'center',
              },
              dimensions.width
            )}
          >
            {'Set Up Your Instructor Bio'}
          </Text>
        </View>
        {/* View Main Container */}
        <View
          style={StyleSheet.applyWidth(
            {
              borderBottomWidth: 0,
              borderLeftWidth: 0,
              borderRadius: 24,
              borderRightWidth: 0,
              borderStyle: 'dashed',
              borderTopWidth: 0,
              height: '70%',
              width: '100%',
            },
            dimensions.width
          )}
        >
          <KeyboardAwareScrollView
            keyboardShouldPersistTaps={'never'}
            showsVerticalScrollIndicator={true}
            viewIsInsideTabBar={false}
            enableAutomaticScroll={true}
            enableOnAndroid={true}
            enableResetScrollToCoords={true}
          >
            {/* View Upload Profile Photo Container */}
            <View
              style={StyleSheet.applyWidth(
                { alignContent: 'center', alignItems: 'center', width: '100%' },
                dimensions.width
              )}
            >
              <Touchable
                onPress={() => {
                  const handler = async () => {
                    try {
                      const newBioImage = await openImagePickerUtil({
                        mediaTypes: 'Images',
                        allowsEditing: true,
                        quality: 0.2,
                        allowsMultipleSelection: false,
                        permissionErrorMessage:
                          'Sorry, we need media library permissions to make this work.',
                        showAlertOnPermissionError: true,
                      });

                      if (!newBioImage) {
                        return;
                      }
                      setGlobalVariableValue({
                        key: 'gBioImage',
                        value: newBioImage,
                      });
                      setDontSaveImageToServer(false);
                      /* hidden 'Run a Custom Function' action */
                    } catch (err) {
                      console.error(err);
                    }
                  };
                  handler();
                }}
              >
                {/* View Icon Container */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignContent: 'center',
                      alignItems: 'center',
                      borderRadius: 100,
                      height: 144,
                      justifyContent: 'center',
                      marginBottom: 12,
                      overflow: 'hidden',
                      width: 144,
                    },
                    dimensions.width
                  )}
                >
                  {/* Image Background Placeholder */}
                  <>
                    {Constants['gBioImage'] ? null : (
                      <ImageBackground
                        resizeMode={'cover'}
                        source={Images.ProfileFiller}
                        style={StyleSheet.applyWidth(
                          { height: '100%', width: '100%' },
                          dimensions.width
                        )}
                      >
                        {/* View Initial Container */}
                        <>
                          {Constants['gBioImage'] ? null : (
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignContent: 'center',
                                  alignItems: 'center',
                                  alignSelf: 'center',
                                  justifyContent: 'center',
                                  paddingTop: 32,
                                },
                                dimensions.width
                              )}
                            >
                              {/* User Initials */}
                              <Text
                                accessible={true}
                                style={StyleSheet.applyWidth(
                                  {
                                    alignSelf: 'center',
                                    color: palettes.App['Custom Color'],
                                    fontFamily: 'Poppins_600SemiBold',
                                    fontSize: 32,
                                    textAlign: 'center',
                                  },
                                  dimensions.width
                                )}
                              >
                                {getUserInitials(Constants['userFullName'])}
                              </Text>
                            </View>
                          )}
                        </>
                      </ImageBackground>
                    )}
                  </>
                  {/* Image Background Uploaded */}
                  <>
                    {!Constants['gBioImage'] ? null : (
                      <ImageBackground
                        resizeMode={'cover'}
                        source={{ uri: `${Constants['gBioImage']}` }}
                        style={StyleSheet.applyWidth(
                          { height: '100%', width: '100%' },
                          dimensions.width
                        )}
                      />
                    )}
                  </>
                </View>
                {/* View Text Container */}
                <>
                  {Constants['gBioImage'] ? null : (
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          marginBottom: 12,
                          marginTop: -34,
                        },
                        dimensions.width
                      )}
                    >
                      {/* Upload Profile Picture */}
                      <Text
                        accessible={true}
                        {...GlobalStyles.TextStyles(theme)['Text'].props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'].style,
                            {
                              color: palettes.App['App Sun Orange F4A100'],
                              fontFamily: 'BebasNeue_400Regular',
                              fontSize: 18,
                              textDecorationLine: 'underline',
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {'UPLOAD PROFILE PICTURE'}
                      </Text>
                    </View>
                  )}
                </>
              </Touchable>
            </View>
            {/* View Bio Input Container */}
            <View
              style={StyleSheet.applyWidth(
                { marginBottom: 10 },
                dimensions.width
              )}
            >
              {/* View Instructions Container */}
              <View
                style={StyleSheet.applyWidth(
                  { marginBottom: 16 },
                  dimensions.width
                )}
              >
                <Text
                  accessible={true}
                  style={StyleSheet.applyWidth(
                    {
                      color: palettes.App['App Black 191F32'],
                      fontFamily: 'Poppins_600SemiBold',
                      fontSize: 16,
                    },
                    dimensions.width
                  )}
                >
                  {'Introduce yourself to your future clients and students'}
                </Text>
              </View>
              {/* View Text Area Container */}
              <View
                style={StyleSheet.applyWidth({ height: 145 }, dimensions.width)}
              >
                <TextInput
                  changeTextDelay={500}
                  multiline={true}
                  numberOfLines={4}
                  onChangeText={newTextAreaValue => {
                    try {
                      setGlobalVariableValue({
                        key: 'gBioText',
                        value: newTextAreaValue,
                      });
                      setBioCharacterCount(newTextAreaValue?.length);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  textAlignVertical={'top'}
                  webShowOutline={true}
                  autoCorrect={true}
                  defaultValue={Constants['gBioText']}
                  editable={true}
                  maxLength={750}
                  placeholder={'Write a short bio'}
                  style={StyleSheet.applyWidth(
                    {
                      borderBottomWidth: 1,
                      borderColor: palettes.App['App Grey Inputs E2E2E2'],
                      borderLeftWidth: 1,
                      borderRadius: 8,
                      borderRightWidth: 1,
                      borderTopWidth: 1,
                      color: palettes.App['App Black 191F32'],
                      fontFamily: 'Poppins_400Regular',
                      height: '100%',
                      paddingBottom: 16,
                      paddingLeft: 16,
                      paddingRight: 16,
                      paddingTop: 16,
                      width: '100%',
                    },
                    dimensions.width
                  )}
                />
              </View>
              {/* Character Count */}
              <Text
                accessible={true}
                {...GlobalStyles.TextStyles(theme)['Text'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Text'].style,
                    {
                      color: palettes.App['App Text 2ndary 7F868C'],
                      fontFamily: 'Poppins_400Regular',
                      fontSize: 12,
                      textAlign: 'right',
                    }
                  ),
                  dimensions.width
                )}
              >
                {bioCharacterCount}
                {' / 750'}
              </Text>
            </View>
            {/* View Tips Container */}
            <View
              style={StyleSheet.applyWidth(
                {
                  backgroundColor: palettes.App['App Blue eef6fa'],
                  borderRadius: 8,
                  flexDirection: 'row',
                  paddingBottom: 12,
                  paddingLeft: 12,
                  paddingRight: 12,
                  paddingTop: 12,
                },
                dimensions.width
              )}
            >
              {/* View Icon Container */}
              <View
                style={StyleSheet.applyWidth(
                  { alignItems: 'center', width: '11%' },
                  dimensions.width
                )}
              >
                <Image
                  resizeMode={'cover'}
                  source={Images.IconInfo}
                  style={StyleSheet.applyWidth(
                    { height: 16, width: 16 },
                    dimensions.width
                  )}
                />
              </View>
              {/* View Tips Text Container */}
              <View
                style={StyleSheet.applyWidth(
                  { alignItems: 'flex-start', width: '89%' },
                  dimensions.width
                )}
              >
                <Text
                  accessible={true}
                  style={StyleSheet.applyWidth(
                    {
                      color: palettes.App['App Brand Blue 2C9AD9'],
                      fontFamily: 'Poppins_400Regular',
                      fontSize: 12,
                    },
                    dimensions.width
                  )}
                >
                  {
                    'Pro Tip: Share your swimming journey and what makes you a great swim teacher!'
                  }
                </Text>
              </View>
            </View>
          </KeyboardAwareScrollView>
        </View>
        {/* View Bottom CTA Container */}
        <View
          style={StyleSheet.applyWidth(
            {
              alignItems: 'center',
              flexDirection: 'row',
              justifyContent: 'space-between',
              width: '100%',
            },
            dimensions.width
          )}
        >
          {/* View Left Container */}
          <View
            style={StyleSheet.applyWidth({ width: '48%' }, dimensions.width)}
          >
            {/* View Step Text Container */}
            <View
              style={StyleSheet.applyWidth(
                { marginBottom: 8 },
                dimensions.width
              )}
            >
              <Text
                accessible={true}
                style={StyleSheet.applyWidth(
                  {
                    color: palettes.App['App Text 2ndary 7F868C'],
                    fontFamily: 'Poppins_400Regular',
                  },
                  dimensions.width
                )}
              >
                {'Step 2 of 4'}
              </Text>
            </View>
            {/* View Progress Bar Container */}
            <View
              style={StyleSheet.applyWidth(
                { borderRadius: 8, flexDirection: 'row', width: '100%' },
                dimensions.width
              )}
            >
              {/* View 1st fill */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    backgroundColor: palettes.App['App Gold #FFC400'],
                    height: 5,
                    width: '26%',
                  },
                  dimensions.width
                )}
              />
              {/* View 2nd fill */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    backgroundColor: palettes.App['App Gold #FFC400'],
                    height: 5,
                    width: '25%',
                  },
                  dimensions.width
                )}
              />
              {/* View 3rd fill */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    backgroundColor: palettes.App['App Disabled Button EDEDEF'],
                    height: 5,
                    width: '24%',
                  },
                  dimensions.width
                )}
              />
              {/* View 4th fill */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    backgroundColor: palettes.App['App Disabled Button EDEDEF'],
                    height: 5,
                    width: '24%',
                  },
                  dimensions.width
                )}
              />
            </View>
          </View>
          {/* View Right Container */}
          <View
            style={StyleSheet.applyWidth({ width: '48%' }, dimensions.width)}
          >
            {/* View Next Button */}
            <View
              style={StyleSheet.applyWidth(
                { alignItems: 'center', width: '100%' },
                dimensions.width
              )}
            >
              {/* Next Disabled */}
              <>
                {Constants['gBioImage'] ? null : (
                  <Button
                    iconPosition={'left'}
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor:
                          palettes.App['App Disabled Button EDEDEF'],
                        borderRadius: 50,
                        color: palettes.App['App Disabled Button Text 7F868C'],
                        fontFamily: 'BebasNeue_400Regular',
                        fontSize: 18,
                        paddingBottom: 14,
                        paddingTop: 14,
                        textAlign: 'center',
                        width: '100%',
                      },
                      dimensions.width
                    )}
                    title={'NEXT' ?? 'Sign In'}
                  />
                )}
              </>
              {/* Next Enabled */}
              <>
                {!Constants['gBioImage'] ? null : (
                  <Button
                    iconPosition={'left'}
                    onPress={() => {
                      const handler = async () => {
                        try {
                          setGlobalVariableValue({
                            key: 'is_loading',
                            value: true,
                          });
                          (
                            await knackAPIUpdateInstructorBiographyPUT.mutateAsync(
                              {
                                AUTH_USER_KNACK_ID:
                                  Constants['AUTH_KNACK_USER_ID'],
                                inputBiography: Constants['gBioText'],
                              }
                            )
                          )?.json;
                          setGlobalVariableValue({
                            key: 'is_loading',
                            value: false,
                          });
                          navigation.navigate(
                            'Onboarding3CompleteTipaltiFormsScreen',
                            { skip: shouldSkipTipalti() }
                          );
                          if (dontSaveImageToServer) {
                            return;
                          }
                          await saveBioImageToServer(
                            Variables,
                            setGlobalVariableValue,
                            Constants['gBioImage']
                          );
                          amplitudeEvents(
                            Variables,
                            'complete profile setup',
                            {
                              step: '02',
                              funnel: 'onboarding',
                              origin: 'complete profile screen',
                              category: 'onboarding',
                            },
                            undefined
                          );
                        } catch (err) {
                          console.error(err);
                        }
                      };
                      handler();
                    }}
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor: palettes.App['App Red EB3E26'],
                        borderRadius: 50,
                        fontFamily: 'BebasNeue_400Regular',
                        fontSize: 18,
                        paddingBottom: 14,
                        paddingTop: 14,
                        textAlign: 'center',
                        width: '100%',
                      },
                      dimensions.width
                    )}
                    title={'Next' ?? 'Sign In'}
                  />
                )}
              </>
              {/* View Activity Indicator */}
              <>
                {!Constants['is_loading'] ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        backgroundColor: palettes.App['App Red EB3E26'],
                        borderRadius: 50,
                        marginTop: -50,
                        opacity: 0.8,
                        paddingBottom: 7,
                        paddingTop: 7,
                        width: '100%',
                      },
                      dimensions.width
                    )}
                  >
                    <ActivityIndicator
                      animating={true}
                      hidesWhenStopped={true}
                      size={'small'}
                      color={palettes.App['Custom Color']}
                      style={StyleSheet.applyWidth(
                        { height: 36, width: 36 },
                        dimensions.width
                      )}
                    />
                  </View>
                )}
              </>
            </View>
          </View>
        </View>
        <Spacer bottom={34} left={0} right={0} top={0} />
      </View>
    </ScreenContainer>
  );
};

export default withTheme(Onboarding2InstructorBioScreen);
