import React from 'react';
import * as GlobalVariables from '../config/GlobalVariableContext';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  Button,
  ScreenContainer,
  Spacer,
  TextInput,
  withTheme,
} from '@draftbit/ui';
import { ActivityIndicator, Text, View } from 'react-native';

const ResetpasswordScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [inputEmailAddress, setInputEmailAddress] = React.useState('');
  const [passwordResetMessage, setPasswordResetMessage] = React.useState('');
  const [passwordResetStatus, setPasswordResetStatus] = React.useState(false);
  const [shouldShowErrorMessage, setShouldShowErrorMessage] =
    React.useState(false);
  const [switchRowValue, setSwitchRowValue] = React.useState(false);
  // Send a request to reset user password.
  const resetUserPassword = async (
    Variables,
    setGlobalVariableValue,
    email
  ) => {
    const passwordBase = Math.random().toString(36).slice(-8);

    setGlobalVariableValue({
      key: 'is_loading',
      value: 'true',
    });

    try {
      const data = {
        email: email,
        password: passwordBase,
      };

      const rawResponse = await fetch(
        `https://hook.integromat.com/eoamz1kggq3yt1a9qo3ri8pbjvlutff8`,
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        }
      );

      const response = await rawResponse.json();

      if (!rawResponse.ok) {
        setGlobalVariableValue({
          key: 'is_loading',
          value: 'false',
        });

        throw new Error(`Error! Status: ${rawResponse.status}`);
      }

      setGlobalVariableValue({
        key: 'is_loading',
        value: 'false',
      });

      console.log('response ===== ', response);
      return response;
    } catch (err) {
      console.error(`What is the error?: ${err.message}`);
      setGlobalVariableValue({
        key: 'is_loading',
        value: 'false',
      });
      return response;
    }
  };

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={true}
      style={StyleSheet.applyWidth(
        { backgroundColor: '"rgba(0, 0, 0, 0)"' },
        dimensions.width
      )}
    >
      {/* Spacer Top Nav */}
      <Spacer bottom={0} left={0} right={0} top={44} />
      {/* View Main Content Container */}
      <View
        style={StyleSheet.applyWidth(
          {
            marginTop: '0%',
            paddingLeft: '5.35%',
            paddingRight: '5.35%',
            paddingTop: '6%',
          },
          dimensions.width
        )}
      >
        <Spacer bottom={8} left={8} right={8} top={'5%'} />
        {/* View Text Container */}
        <View
          style={StyleSheet.applyWidth(
            { marginBottom: 24, marginTop: 24 },
            dimensions.width
          )}
        >
          <Text
            accessible={true}
            style={StyleSheet.applyWidth(
              {
                color: palettes.App['App Text 2ndary 7F868C'],
                fontFamily: 'Poppins_400Regular',
              },
              dimensions.width
            )}
          >
            {
              'Enter the email address associated with your Sunsational Swim Instructor account.'
            }
          </Text>
        </View>
        {/* View Error Message */}
        <>
          {!Constants['showErrorMessage'] ? null : (
            <View
              style={StyleSheet.applyWidth(
                {
                  backgroundColor: palettes.App['App Red EB3E26'],
                  marginBottom: 8,
                  marginTop: 0,
                  paddingBottom: 8,
                  paddingLeft: 8,
                  paddingRight: 8,
                  paddingTop: 8,
                  width: '100%',
                },
                dimensions.width
              )}
            >
              {/* Error Message */}
              <Text
                accessible={true}
                style={StyleSheet.applyWidth(
                  { color: palettes.App['Custom Color'] },
                  dimensions.width
                )}
              >
                {'⚠️  '}
                {passwordResetMessage}
              </Text>
            </View>
          )}
        </>
        {/* View Inputs Container */}
        <View>
          {/* Text Input Email */}
          <TextInput
            autoCapitalize={'none'}
            autoCorrect={true}
            changeTextDelay={500}
            onChangeText={newTextInputEmailValue => {
              try {
                setInputEmailAddress(newTextInputEmailValue);
              } catch (err) {
                console.error(err);
              }
            }}
            webShowOutline={true}
            clearTextOnFocus={true}
            editable={true}
            keyboardType={'email-address'}
            placeholder={'Email'}
            secureTextEntry={false}
            style={StyleSheet.applyWidth(
              {
                backgroundColor: palettes.App['Custom Color'],
                borderBottomWidth: 1,
                borderColor: palettes.App['App Grey Inputs E2E2E2'],
                borderLeftWidth: 1,
                borderRadius: 8,
                borderRightWidth: 1,
                borderTopWidth: 1,
                fontFamily: 'Poppins_400Regular',
                marginBottom: 16,
                paddingBottom: 16,
                paddingLeft: 16,
                paddingRight: 16,
                paddingTop: 16,
              },
              dimensions.width
            )}
            textContentType={'emailAddress'}
          />
        </View>
      </View>
      {/* View Bottom Anchor */}
      <View
        style={StyleSheet.applyWidth(
          {
            bottom: 0,
            paddingLeft: '5.35%',
            paddingRight: '5.35%',
            position: 'absolute',
            width: '100%',
          },
          dimensions.width
        )}
      >
        {/* View Button Container */}
        <View
          style={StyleSheet.applyWidth(
            { alignItems: 'center', marginBottom: 32, width: '100%' },
            dimensions.width
          )}
        >
          {/* Reset Password Disabled */}
          <>
            {inputEmailAddress ? null : (
              <Button
                iconPosition={'left'}
                onPress={() => {
                  try {
                    console.log();
                  } catch (err) {
                    console.error(err);
                  }
                }}
                style={StyleSheet.applyWidth(
                  {
                    backgroundColor: palettes.App['App Disabled Button EDEDEF'],
                    borderRadius: 50,
                    color: palettes.App['App Disabled Button Text 7F868C'],
                    fontFamily: 'BebasNeue_400Regular',
                    fontSize: 18,
                    paddingBottom: 14,
                    paddingTop: 14,
                    textAlign: 'center',
                    width: '100%',
                  },
                  dimensions.width
                )}
                title={'RESET PASSWORD'}
              />
            )}
          </>
          {/* Reset Password Enabled */}
          <>
            {!inputEmailAddress ? null : (
              <Button
                iconPosition={'left'}
                onPress={() => {
                  const handler = async () => {
                    try {
                      const requestResponse = await resetUserPassword(
                        Variables,
                        setGlobalVariableValue,
                        inputEmailAddress
                      );
                      const responseMessage = requestResponse?.message;
                      setPasswordResetMessage(responseMessage);
                      const responseStatus = requestResponse?.complete;
                      setPasswordResetStatus(responseStatus);
                      console.log(passwordResetStatus);
                      const errorMessageStatus =
                        requestResponse?.shouldShowErrorMessage;
                      setGlobalVariableValue({
                        key: 'showErrorMessage',
                        value: errorMessageStatus,
                      });
                      setGlobalVariableValue({
                        key: 'is_loading',
                        value: false,
                      });
                      console.log(shouldShowErrorMessage);
                      if (!responseStatus) {
                        return;
                      }
                      navigation.navigate('ResetpasswordsuccessScreen');
                    } catch (err) {
                      console.error(err);
                    }
                  };
                  handler();
                }}
                style={StyleSheet.applyWidth(
                  {
                    backgroundColor: palettes.App['App Red EB3E26'],
                    borderRadius: 50,
                    fontFamily: 'BebasNeue_400Regular',
                    fontSize: 18,
                    paddingBottom: 14,
                    paddingTop: 14,
                    textAlign: 'center',
                    width: '100%',
                  },
                  dimensions.width
                )}
                title={'RESET PASSWORD'}
              />
            )}
          </>
          {/* View Activity Indicator */}
          <>
            {!Constants['is_loading'] ? null : (
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    backgroundColor: palettes.App['App Red EB3E26'],
                    borderRadius: 50,
                    marginTop: -50,
                    opacity: 0.8,
                    paddingBottom: 7,
                    paddingTop: 7,
                    width: '100%',
                  },
                  dimensions.width
                )}
              >
                <ActivityIndicator
                  animating={true}
                  hidesWhenStopped={true}
                  size={'small'}
                  color={palettes.App['Custom Color']}
                  style={StyleSheet.applyWidth(
                    { height: 36, width: 36 },
                    dimensions.width
                  )}
                />
              </View>
            )}
          </>
        </View>
        {/* View Button Container */}
        <View
          style={StyleSheet.applyWidth(
            { alignItems: 'center', marginBottom: 20, width: '100%' },
            dimensions.width
          )}
        >
          {/* Reset Password Enabled */}
          <Button
            iconPosition={'left'}
            onPress={() => {
              try {
                navigation.navigate('LoginHelpScreen');
              } catch (err) {
                console.error(err);
              }
            }}
            style={StyleSheet.applyWidth(
              {
                backgroundColor: palettes.App['Custom Color'],
                borderBottomWidth: 1,
                borderColor: palettes.App['App Sun Orange F4A100'],
                borderLeftWidth: 1,
                borderRadius: 50,
                borderRightWidth: 1,
                borderTopWidth: 1,
                color: palettes.App['App Sun Orange F4A100'],
                fontFamily: 'BebasNeue_400Regular',
                fontSize: 18,
                paddingBottom: 14,
                paddingTop: 14,
                textAlign: 'center',
                width: '100%',
              },
              dimensions.width
            )}
            title={'NEED HELP?' ?? 'RESET PASSWORD'}
          />
        </View>
        <Spacer bottom={34} left={0} right={0} top={0} />
      </View>
    </ScreenContainer>
  );
};

export default withTheme(ResetpasswordScreen);
