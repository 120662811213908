import React from 'react';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import * as Track from '../custom-files/Track';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import { Button, ScreenContainer, withTheme } from '@draftbit/ui';
import { ImageBackground, Text, View } from 'react-native';

const OnboardingGetPushTokenScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const shouldSkipCprUpload = () => {
    if (Variables.gCprImage == '') {
      return false;
    }

    return true;
  };

  const navOnboardingNext = () => {
    const {
      gBioImage,
      gBioText,
      gCprImage,
      gTipaltiComplete,
      onboardingSteps,
    } = Variables;

    // const {
    //     step1,
    //     step2,
    //     step3,
    //     step4
    // } = onboardingSteps;

    if (gCprImage == '') {
      props.navigation.navigate('Onboarding1UploadCPRScreen');
      // return;
    }

    if (gBioImage == '' || gBioText == '') {
      props.navigation.navigate('Onboarding2InstructorBioScreen');
      // return;
    }

    if (gTipaltiComplete == false) {
      props.navigation.navigate('Onboarding3CompleteTipaltiFormsScreen');
      // return;
    }
  };
  React.useEffect(() => {
    Track.onEnterScreen(
      'Onboarding0WelcomeScreen',
      Variables,
      setGlobalVariableValue,
      props.navigation.navigate,
      props?.route?.params?.skipAuth
    );
  }, []);

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={false}
      hasTopSafeArea={false}
      style={StyleSheet.applyWidth(
        { backgroundColor: palettes.App['App Blue C7EAF3'] },
        dimensions.width
      )}
    >
      {/* View Header Container */}
      <View
        style={StyleSheet.applyWidth(
          { height: '57%', width: '100%' },
          dimensions.width
        )}
      >
        <ImageBackground
          resizeMode={'cover'}
          source={Images.OnboardingWelcome}
          style={StyleSheet.applyWidth(
            {
              alignSelf: 'center',
              height: '100%',
              justifyContent: 'flex-end',
              width: '100%',
            },
            dimensions.width
          )}
        >
          {/* View Body Container */}
          <View
            style={StyleSheet.applyWidth(
              {
                backgroundColor: palettes.App['Custom Color'],
                borderRadius: 16,
                height: '43%',
                paddingBottom: '10.7%',
                paddingLeft: '5.35%',
                paddingRight: '5.35%',
                paddingTop: '6.4%',
                width: '100%',
              },
              dimensions.width
            )}
          >
            {/* View h4 Container */}
            <View
              style={StyleSheet.applyWidth(
                { marginBottom: '2%', width: '100%' },
                dimensions.width
              )}
            >
              {/* Text h4 */}
              <Text
                accessible={true}
                style={StyleSheet.applyWidth(
                  {
                    alignSelf: 'center',
                    color: theme.colors.text.strong,
                    fontFamily: 'Poppins_500Medium',
                    fontSize: 16,
                    textTransform: 'none',
                  },
                  dimensions.width
                )}
              >
                {'Congrats on becoming a'}
              </Text>
            </View>
            {/* View h1 Container */}
            <View
              style={StyleSheet.applyWidth(
                { marginBottom: '2%' },
                dimensions.width
              )}
            >
              {/* Text h1 */}
              <Text
                accessible={true}
                style={StyleSheet.applyWidth(
                  {
                    color: palettes.App['App Red EB3E26'],
                    fontFamily: 'BebasNeue_400Regular',
                    fontSize: 32,
                    textAlign: 'center',
                  },
                  dimensions.width
                )}
              >
                {'Sunsational Swim\nInstructor'}
              </Text>
            </View>
            {/* View Body Container */}
            <View
              style={StyleSheet.applyWidth(
                { marginBottom: '5%' },
                dimensions.width
              )}
            >
              {/* Text body */}
              <Text
                accessible={true}
                style={StyleSheet.applyWidth(
                  {
                    color: palettes.App['App Text 2ndary 7F868C'],
                    fontFamily: 'Poppins_400Regular',
                    textAlign: 'center',
                  },
                  dimensions.width
                )}
              >
                {
                  'Before you dive into teaching swimming lessons, we need to confirm a few things first.'
                }
              </Text>
            </View>
            {/* View Button */}
            <View
              style={StyleSheet.applyWidth(
                { alignItems: 'center', marginBottom: 32, width: '100%' },
                dimensions.width
              )}
            >
              {/* Let's Go */}
              <Button
                iconPosition={'left'}
                onPress={() => {
                  try {
                    navigation.navigate('Onboarding1UploadCPRScreen', {
                      skip: shouldSkipCprUpload(),
                    });
                    setGlobalVariableValue({
                      key: 'empty',
                      value: Constants['empty'],
                    });
                  } catch (err) {
                    console.error(err);
                  }
                }}
                style={StyleSheet.applyWidth(
                  {
                    backgroundColor: palettes.App['App Red EB3E26'],
                    borderRadius: 50,
                    fontFamily: 'BebasNeue_400Regular',
                    fontSize: 18,
                    paddingBottom: 14,
                    paddingTop: 14,
                    textAlign: 'center',
                    width: '50%',
                  },
                  dimensions.width
                )}
                title={"Let's Go" ?? 'Sign In'}
              />
            </View>
          </View>
        </ImageBackground>
      </View>
    </ScreenContainer>
  );
};

export default withTheme(OnboardingGetPushTokenScreen);
