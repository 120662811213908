import React from 'react';
import * as GlobalVariables from '../config/GlobalVariableContext';
import * as SelectSchedule from '../custom-files/SelectSchedule';
import * as Track from '../custom-files/Track';
import * as validateAppVersionAndAuthToken from '../custom-files/validateAppVersionAndAuthToken';
import palettes from '../themes/palettes';
import * as Utils from '../utils';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import openImagePickerUtil from '../utils/openImagePicker';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  Button,
  Picker,
  SVG,
  ScreenContainer,
  Spacer,
  Surface,
  TextInput,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import {
  ActivityIndicator,
  ImageBackground,
  Modal,
  Text,
  View,
} from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';

const AddPoolScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [apr, setApr] = React.useState(false);
  const [aug, setAug] = React.useState(false);
  const [dec, setDec] = React.useState(false);
  const [disableSaveButton, setDisableSaveButton] = React.useState(true);
  const [errorAddress, setErrorAddress] = React.useState(false);
  const [errorAvailability, setErrorAvailability] = React.useState(false);
  const [errorPoolType, setErrorPoolType] = React.useState(false);
  const [feb, setFeb] = React.useState(false);
  const [inputCityValue, setInputCityValue] = React.useState('');
  const [inputPoolType, setInputPoolType] = React.useState('');
  const [inputStateValue, setInputStateValue] = React.useState('');
  const [inputStreet1Value, setInputStreet1Value] = React.useState('');
  const [inputStreet2Value, setInputStreet2Value] = React.useState('');
  const [inputZipValue, setInputZipValue] = React.useState('');
  const [jan, setJan] = React.useState(false);
  const [jul, setJul] = React.useState(false);
  const [jun, setJun] = React.useState(false);
  const [knackPoolId, setKnackPoolId] = React.useState('');
  const [latitude, setLatitude] = React.useState(0);
  const [longitude, setLongitude] = React.useState(0);
  const [mar, setMar] = React.useState(false);
  const [may, setMay] = React.useState(false);
  const [modalMessage, setModalMessage] = React.useState('');
  const [modalVisible, setModalVisible] = React.useState(false);
  const [nov, setNov] = React.useState(false);
  const [oct, setOct] = React.useState(false);
  const [poolAvailability, setPoolAvailability] = React.useState([]);
  const [poolInstructionsValue, setPoolInstructionsValue] = React.useState('');
  const [poolPhoto, setPoolPhoto] = React.useState('');
  const [prevState, setPrevState] = React.useState(
    props.route?.params?.prevState ?? {}
  );
  const [sep, setSep] = React.useState(false);
  const modalCloseFunction = (Variables, setGlobalVariableValue) => {
    setModalVisible(false);

    setGlobalVariableValue({
      key: 'gModalVisible',
      value: false,
    });

    if (!errorPoolType && !errorAddress && !errorAvailability) {
      props.navigate.back();
    }
  };

  const uploadPoolData = async (Variables, setGlobalVariableValue) => {
    let currentAvailability = [];

    jan ? currentAvailability.push('January') : '';
    feb ? currentAvailability.push('February') : '';
    mar ? currentAvailability.push('March') : '';
    apr ? currentAvailability.push('April') : '';
    may ? currentAvailability.push('May') : '';
    jun ? currentAvailability.push('June') : '';
    jul ? currentAvailability.push('July') : '';
    aug ? currentAvailability.push('August') : '';
    sep ? currentAvailability.push('September') : '';
    oct ? currentAvailability.push('October') : '';
    nov ? currentAvailability.push('November') : '';
    dec ? currentAvailability.push('December') : '';

    setErrorPoolType(false);
    setErrorAddress(false);
    setErrorAvailability(false);

    if (!inputPoolType) {
      setErrorPoolType(true);
    }

    if (
      !inputStreet1Value ||
      !inputCityValue ||
      !inputStateValue ||
      !inputZipValue
    ) {
      setErrorAddress(true);
    }

    // if (!inputCityValue) {}
    // if (!inputStateValue) {}
    // if (!inputZipValue) {}

    if (currentAvailability.length < 1) {
      setErrorAvailability(true);
    }

    if (
      !inputPoolType ||
      !inputStreet1Value ||
      !inputCityValue ||
      !inputStateValue ||
      !inputZipValue ||
      currentAvailability.length < 1
    ) {
      setModalMessage(`Woops, Please complete all required fields`);
      setGlobalVariableValue({
        key: 'gModalVisible',
        value: true,
      });
      return;
    }
    // all fields filled.
    setModalMessage(`One moment, swimming this to our servers`);

    setGlobalVariableValue({
      key: 'is_loading',
      value: true,
    });

    setGlobalVariableValue({
      key: 'gModalVisible',
      value: true,
    });

    let poolImage = poolPhoto.split(',');
    let base64Data = poolImage[1];

    let dataPost = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        inputPoolType,
        base64Data,
        inputStreet1Value,
        inputStreet2Value,
        inputCityValue,
        inputStateValue,
        inputZipValue,
        currentAvailability,
        poolInstructionsValue,
        knackPoolId,
        knackUserRecordId: Variables.AUTH_KNACK_USER_ID,
      }),
    };

    try {
      const sendPoolDataToIntegromat = await fetch(
        `https://hook.integromat.com/6tasjcsuxnuum9c4v0n9rc7pmbw7jujp`,
        dataPost
      );
      const sendPoolDataToIntegromatResponse =
        await sendPoolDataToIntegromat.json();

      if (!sendPoolDataToIntegromat.ok) {
        throw new Error(`Error!`);
      }

      const getPoolDetails = await fetch(
        `https://api.knack.com/v1/pages/scene_1142/views/view_2124/records`,
        {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'X-Knack-Application-Id': Variables.APP_ID,
            Authorization: Variables.AUTH_KNACK_TOKEN,
          },
        }
      );

      const getPoolDetailsResponse = await getPoolDetails.json();

      if (!getPoolDetails.ok) {
        throw new Error(`Error! status: ${response.status}`);
      }

      const updateGlobalVariables = [
        setGlobalVariableValue({
          key: 'gSiPools',
          value: getPoolDetailsResponse.records,
        }),

        setGlobalVariableValue({
          key: 'is_loading',
          value: false,
        }),
      ];

      const updateAppVariablesComplete = await Promise.all(
        updateGlobalVariables
      );

      setModalMessage(`Great! Your settings have been saved.`);
    } catch (err) {
      setModalMessage(
        `Woops, we got this error message: ${err.message}. Please retry!`
      );

      setGlobalVariableValue({
        key: 'is_loading',
        value: false,
      });
      console.log('CATCH ERROR BLOCK!');
      console.error(`What is the error?: ${err.message}`);
    }
  };
  React.useEffect(() => {
    let imagePrev;
    let currentAvailability = [];

    jan ? currentAvailability.push('January') : '';
    feb ? currentAvailability.push('February') : '';
    mar ? currentAvailability.push('March') : '';
    apr ? currentAvailability.push('April') : '';
    may ? currentAvailability.push('May') : '';
    jun ? currentAvailability.push('June') : '';
    jul ? currentAvailability.push('July') : '';
    aug ? currentAvailability.push('August') : '';
    sep ? currentAvailability.push('September') : '';
    oct ? currentAvailability.push('October') : '';
    nov ? currentAvailability.push('November') : '';
    dec ? currentAvailability.push('December') : '';

    let currentAddress = {
      zip: inputZipValue,
      city: inputCityValue,
      state: inputStateValue,
      street: inputStreet1Value,
      street2: inputStreet2Value,
      latitude,
      longitude,
    };

    const scheduleSkeleton = {
      zip: '',
      city: '',
      state: '',
      street: '',
      street2: '',
      latitude: 0,
      longitude: 0,
    };

    const {
      field_1045, // pool type
      field_1044_raw, // address
      field_1046_raw, // availabililty months array
      field_1133_raw, // pool instructions
      field_2393_raw,
    } = prevState;

    if (field_2393_raw) {
      imagePrev = field_2393_raw.url;
    }

    function checkForChange(
      poolTypeCurrent,
      poolTypePrev,
      poolPhotoCurrent,
      poolPhotoPrev,
      poolInstructionsCurrent,
      poolInstructionsPrev,
      availabililtyCurrent,
      availabililtyPrev,
      addressCurrent,
      addressPrev
    ) {
      return (
        poolTypeCurrent === poolTypePrev &&
        poolPhotoCurrent === poolPhotoPrev &&
        poolInstructionsCurrent === poolInstructionsPrev &&
        availabililtyCurrent.length === availabililtyPrev.length &&
        availabililtyCurrent.every(
          month => availabililtyPrev.indexOf(month) > -1
        ) &&
        JSON.stringify(addressCurrent) === JSON.stringify(addressPrev)
      );
    }

    if (
      checkForChange(
        inputPoolType || null,
        field_1045 || null,
        poolPhoto || null,
        imagePrev || null,
        poolInstructionsValue || null,
        field_1133_raw || null,
        currentAvailability,
        field_1046_raw || [],
        currentAddress,
        field_1044_raw || scheduleSkeleton
      )
    ) {
      // console.log("no changes made. do nothing");
      setDisableSaveButton(true);
    } else {
      // console.log("changes made. do something!");
      setDisableSaveButton(false);
    }
  });

  React.useEffect(() => {
    Track.onEnterScreen(
      'AddPoolScreen',
      Variables,
      setGlobalVariableValue,
      props.navigation.navigate,
      props?.route?.params?.skipAuth
    );

    if (props?.route?.params) {
      const poolProps = props?.route?.params;

      poolProps.instructions
        ? setPoolInstructionsValue(poolProps.instructions)
        : '';
      poolProps.poolType
        ? setInputPoolType(poolProps.poolType)
        : setInputPoolType('');
      poolProps.poolPhoto ? setPoolPhoto(poolProps.poolPhoto) : '';
      poolProps.street1 ? setInputStreet1Value(poolProps.street1) : '';
      poolProps.street2 ? setInputStreet2Value(poolProps.street2) : '';
      poolProps.city ? setInputCityValue(poolProps.city) : '';
      poolProps.state ? setInputStateValue(poolProps.state) : '';
      poolProps.zip ? setInputZipValue(poolProps.zip) : '';
      poolProps.prevState ? setPrevState(poolProps.prevState) : '';
      poolProps.pool_id ? setKnackPoolId(poolProps.pool_id) : '';
      poolProps.latitude ? setLatitude(poolProps.latitude) : '';
      poolProps.longitude ? setLongitude(poolProps.longitude) : '';
      poolProps.availability
        ? poolProps.availability.map(month => {
            if (month === 'January') {
              setJan(true);
            }
            if (month === 'February') {
              setFeb(true);
            }
            if (month === 'March') {
              setMar(true);
            }
            if (month === 'April') {
              setApr(true);
            }
            if (month === 'May') {
              setMay(true);
            }
            if (month === 'June') {
              setJun(true);
            }
            if (month === 'July') {
              setJul(true);
            }
            if (month === 'August') {
              setAug(true);
            }
            if (month === 'September') {
              setSep(true);
            }
            if (month === 'October') {
              setOct(true);
            }
            if (month === 'November') {
              setNov(true);
            }
            if (month === 'December') {
              setDec(true);
            }
          })
        : '';
    }
  }, []);

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={false}
      hasTopSafeArea={false}
    >
      {/* View Top Navigation - Subtitle */}
      <View style={StyleSheet.applyWidth({ width: '100%' }, dimensions.width)}>
        {/* Spacer Top Nav */}
        <Spacer bottom={0} left={0} right={0} top={44} />
        {/* View Row Top Nav Container */}
        <View
          style={StyleSheet.applyWidth(
            {
              alignContent: 'center',
              alignItems: 'center',
              flexDirection: 'row',
              justifyContent: 'space-between',
              paddingBottom: 22,
              paddingLeft: '5.35%',
              paddingRight: '5.35%',
              paddingTop: 14,
            },
            dimensions.width
          )}
        >
          {/* View Back Arrow Container */}
          <View
            style={StyleSheet.applyWidth(
              { height: 24, width: 24 },
              dimensions.width
            )}
          >
            <Touchable
              onPress={() => {
                try {
                  navigation.goBack();
                } catch (err) {
                  console.error(err);
                }
              }}
              style={StyleSheet.applyWidth(
                { height: '100%', width: '100%' },
                dimensions.width
              )}
            >
              {/* Icon Back Arrow */}
              <SVG
                source={Constants['uiBackArrow']}
                style={StyleSheet.applyWidth(
                  { height: '100%', width: '100%' },
                  dimensions.width
                )}
              />
            </Touchable>
          </View>
          {/* View Page Title Container */}
          <View
            style={StyleSheet.applyWidth(
              { justifyContent: 'center' },
              dimensions.width
            )}
          >
            {/* Title - Add Pool */}
            <>
              {props.route?.params?.poolType ?? '' ? null : (
                <Text
                  accessible={true}
                  style={StyleSheet.applyWidth(
                    {
                      color: theme.colors.text.strong,
                      fontFamily: 'Poppins_600SemiBold',
                    },
                    dimensions.width
                  )}
                >
                  {'Add a Pool'}
                </Text>
              )}
            </>
            {/* Title - Edit Pool */}
            <>
              {!(props.route?.params?.poolType ?? '') ? null : (
                <Text
                  accessible={true}
                  style={StyleSheet.applyWidth(
                    {
                      color: theme.colors.text.strong,
                      fontFamily: 'Poppins_600SemiBold',
                    },
                    dimensions.width
                  )}
                >
                  {'Edit '}
                  {props.route?.params?.street1 ?? ''}
                </Text>
              )}
            </>
          </View>
          {/* View Spacer */}
          <View
            style={StyleSheet.applyWidth(
              { height: 10, justifyContent: 'center', width: 10 },
              dimensions.width
            )}
          />
        </View>
      </View>

      <KeyboardAwareScrollView
        enableAutomaticScroll={false}
        keyboardShouldPersistTaps={'never'}
        showsVerticalScrollIndicator={true}
        viewIsInsideTabBar={false}
        enableOnAndroid={true}
        enableResetScrollToCoords={true}
      >
        {/* View Body Container */}
        <View
          style={StyleSheet.applyWidth(
            { paddingLeft: '5.35%', paddingRight: '5.35%' },
            dimensions.width
          )}
        >
          {/* View Row Pool Detail */}
          <View
            style={StyleSheet.applyWidth(
              { paddingBottom: 30 },
              dimensions.width
            )}
          >
            {/* Label Container */}
            <View
              style={StyleSheet.applyWidth(
                { marginBottom: 12, paddingBottom: 12 },
                dimensions.width
              )}
            >
              {/* Pick Available Days */}
              <Text
                accessible={true}
                style={StyleSheet.applyWidth(
                  {
                    color: palettes.App['App Text 2ndary 7F868C'],
                    fontFamily: 'Poppins_400Regular',
                    textAlign: 'center',
                  },
                  dimensions.width
                )}
              >
                {
                  'Add details about your pool to make it easy for your students to meet you here 💯'
                }
              </Text>
            </View>
            {/* Pool Detail */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'baseline',
                  flexDirection: 'row',
                  marginBottom: 26,
                },
                dimensions.width
              )}
            >
              {/* H1 */}
              <Text
                accessible={true}
                style={StyleSheet.applyWidth(
                  {
                    color: palettes.App['App Black 191F32'],
                    fontFamily: 'BebasNeue_400Regular',
                    fontSize: 24,
                    marginRight: 4,
                  },
                  dimensions.width
                )}
              >
                {'Pool'}
              </Text>
              {/* H1 Accent */}
              <Text
                accessible={true}
                style={StyleSheet.applyWidth(
                  {
                    color: palettes.App['App Red EB3E26'],
                    fontFamily: 'LeckerliOne_400Regular',
                    fontSize: 22,
                  },
                  dimensions.width
                )}
              >
                {'Type'}
              </Text>
            </View>
            {/* View Error Pool Type */}
            <>
              {!errorPoolType ? null : (
                <View
                  style={StyleSheet.applyWidth(
                    {
                      backgroundColor: palettes.App['App Red EB3E26'],
                      marginBottom: 8,
                      marginTop: 0,
                      paddingBottom: 8,
                      paddingLeft: 8,
                      paddingRight: 8,
                      paddingTop: 8,
                      width: '100%',
                    },
                    dimensions.width
                  )}
                >
                  {/* Error Message */}
                  <Text
                    accessible={true}
                    style={StyleSheet.applyWidth(
                      { color: palettes.App['Custom Color'] },
                      dimensions.width
                    )}
                  >
                    {'⚠️  Uh oh, please select a pool type.'}
                  </Text>
                </View>
              )}
            </>
            {/* Picker Selected Pool Type */}
            <Picker
              autoDismissKeyboard={true}
              dropDownBackgroundColor={theme.colors.background.brand}
              dropDownBorderColor={theme.colors.border.brand}
              dropDownBorderRadius={8}
              dropDownBorderWidth={1}
              dropDownTextColor={theme.colors.text.strong}
              leftIconMode={'inset'}
              mode={'native'}
              onValueChange={newPickerSelectedPoolTypeValue => {
                try {
                  if (newPickerSelectedPoolTypeValue) {
                    setInputPoolType(newPickerSelectedPoolTypeValue);
                  }
                } catch (err) {
                  console.error(err);
                }
              }}
              placeholder={'Select an option'}
              selectedIconColor={theme.colors.text.strong}
              selectedIconName={'Feather/check'}
              selectedIconSize={20}
              type={'solid'}
              iconColor={palettes.App['App Black 191F32']}
              iconSize={16}
              options={Constants['poolTypes']}
              placeholderTextColor={palettes.App['App Grey Inputs E2E2E2']}
              rightIconName={'AntDesign/down'}
              style={StyleSheet.applyWidth(
                {
                  color: palettes.App['App Black 191F32'],
                  fontFamily: 'Poppins_400Regular',
                  paddingBottom: 16,
                  paddingLeft: 16,
                  paddingRight: 16,
                  paddingTop: 16,
                },
                dimensions.width
              )}
              value={inputPoolType}
            />
          </View>
          {/* View Row Upload Pool Photo Container */}
          <View>
            {/* View Pool Image Container */}
            <>
              {!poolPhoto ? null : (
                <View
                  style={StyleSheet.applyWidth(
                    {
                      borderRadius: 8,
                      height: 160,
                      marginBottom: 10,
                      width: '100%',
                    },
                    dimensions.width
                  )}
                >
                  <ImageBackground
                    resizeMode={'cover'}
                    source={{ uri: `${poolPhoto}` }}
                    style={StyleSheet.applyWidth(
                      { height: '100%', width: '100%' },
                      dimensions.width
                    )}
                  />
                </View>
              )}
            </>
            {/* View Button Container No Upload */}
            <>
              {poolPhoto ? null : (
                <View
                  style={StyleSheet.applyWidth(
                    { alignItems: 'center', marginBottom: 32, width: '100%' },
                    dimensions.width
                  )}
                >
                  {/* Upload Pool Image */}
                  <Button
                    iconPosition={'left'}
                    onPress={() => {
                      const handler = async () => {
                        try {
                          const poolFromCamera = await openImagePickerUtil({
                            mediaTypes: 'All',
                            allowsEditing: false,
                            quality: 0.2,
                            allowsMultipleSelection: false,
                            permissionErrorMessage:
                              'Sorry, we need media library permissions to make this work.',
                            showAlertOnPermissionError: true,
                          });

                          setPoolPhoto(poolFromCamera);
                        } catch (err) {
                          console.error(err);
                        }
                      };
                      handler();
                    }}
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor: palettes.App['Custom Color'],
                        borderBottomWidth: 1,
                        borderColor: palettes.App['App Sun Orange F4A100'],
                        borderLeftWidth: 1,
                        borderRadius: 50,
                        borderRightWidth: 1,
                        borderTopWidth: 1,
                        color: palettes.App['App Sun Orange F4A100'],
                        fontFamily: 'BebasNeue_400Regular',
                        fontSize: 18,
                        paddingBottom: 14,
                        paddingTop: 14,
                        textAlign: 'center',
                        width: '100%',
                      },
                      dimensions.width
                    )}
                    title={'UPLOAD POOL IMAGE'}
                  />
                  {/* View Activity Indicator */}
                  <>
                    {!Constants['is_loading'] ? null : (
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            backgroundColor: palettes.App['App Red EB3E26'],
                            borderRadius: 50,
                            marginTop: -50,
                            opacity: 0.8,
                            paddingBottom: 7,
                            paddingTop: 7,
                            width: '100%',
                          },
                          dimensions.width
                        )}
                      >
                        <ActivityIndicator
                          animating={true}
                          hidesWhenStopped={true}
                          size={'small'}
                          color={palettes.App['Custom Color']}
                          style={StyleSheet.applyWidth(
                            { height: 36, width: 36 },
                            dimensions.width
                          )}
                        />
                      </View>
                    )}
                  </>
                </View>
              )}
            </>
            {/* View Button Container Uploaded */}
            <>
              {!poolPhoto ? null : (
                <View
                  style={StyleSheet.applyWidth(
                    { alignItems: 'center', marginBottom: 32, width: '100%' },
                    dimensions.width
                  )}
                >
                  {/* Change Pool Image */}
                  <Button
                    iconPosition={'left'}
                    onPress={() => {
                      const handler = async () => {
                        try {
                          const poolFromCamera = await openImagePickerUtil({
                            mediaTypes: 'All',
                            allowsEditing: false,
                            quality: 0.2,
                            allowsMultipleSelection: false,
                            permissionErrorMessage:
                              'Sorry, we need media library permissions to make this work.',
                            showAlertOnPermissionError: true,
                          });

                          setPoolPhoto(poolFromCamera);
                        } catch (err) {
                          console.error(err);
                        }
                      };
                      handler();
                    }}
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor: palettes.App['Custom Color'],
                        borderBottomWidth: 1,
                        borderColor: palettes.App['App Sun Orange F4A100'],
                        borderLeftWidth: 1,
                        borderRadius: 50,
                        borderRightWidth: 1,
                        borderTopWidth: 1,
                        color: palettes.App['App Sun Orange F4A100'],
                        fontFamily: 'BebasNeue_400Regular',
                        fontSize: 18,
                        paddingBottom: 14,
                        paddingTop: 14,
                        textAlign: 'center',
                        width: '100%',
                      },
                      dimensions.width
                    )}
                    title={'Change Pool Image' ?? 'RESET PASSWORD'}
                  />
                  {/* View Activity Indicator */}
                  <>
                    {!Constants['is_loading'] ? null : (
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            backgroundColor: palettes.App['App Red EB3E26'],
                            borderRadius: 50,
                            marginTop: -50,
                            opacity: 0.8,
                            paddingBottom: 7,
                            paddingTop: 7,
                            width: '100%',
                          },
                          dimensions.width
                        )}
                      >
                        <ActivityIndicator
                          animating={true}
                          hidesWhenStopped={true}
                          size={'small'}
                          color={palettes.App['Custom Color']}
                          style={StyleSheet.applyWidth(
                            { height: 36, width: 36 },
                            dimensions.width
                          )}
                        />
                      </View>
                    )}
                  </>
                </View>
              )}
            </>
          </View>
          {/* View Row Pool Address */}
          <View
            style={StyleSheet.applyWidth(
              { paddingBottom: 25 },
              dimensions.width
            )}
          >
            {/* View Error Address */}
            <>
              {!errorAddress ? null : (
                <View
                  style={StyleSheet.applyWidth(
                    {
                      backgroundColor: palettes.App['App Red EB3E26'],
                      marginBottom: 8,
                      marginTop: 0,
                      paddingBottom: 8,
                      paddingLeft: 8,
                      paddingRight: 8,
                      paddingTop: 8,
                      width: '100%',
                    },
                    dimensions.width
                  )}
                >
                  {/* Error Message */}
                  <Text
                    accessible={true}
                    style={StyleSheet.applyWidth(
                      { color: palettes.App['Custom Color'] },
                      dimensions.width
                    )}
                  >
                    {
                      '⚠️  Uh oh, it seems your address is incomplete. Street, City, State & Zip are required.'
                    }
                  </Text>
                </View>
              )}
            </>
            {/* Pool Addresss */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'baseline',
                  flexDirection: 'row',
                  marginBottom: 26,
                },
                dimensions.width
              )}
            >
              {/* H1 */}
              <Text
                accessible={true}
                style={StyleSheet.applyWidth(
                  {
                    color: palettes.App['App Black 191F32'],
                    fontFamily: 'BebasNeue_400Regular',
                    fontSize: 24,
                    marginRight: 4,
                  },
                  dimensions.width
                )}
              >
                {'Pool'}
              </Text>
              {/* H1 Accent */}
              <Text
                accessible={true}
                style={StyleSheet.applyWidth(
                  {
                    color: palettes.App['App Red EB3E26'],
                    fontFamily: 'LeckerliOne_400Regular',
                    fontSize: 22,
                  },
                  dimensions.width
                )}
              >
                {'Address'}
              </Text>
            </View>
            {/* View Address Inputs Container */}
            <View>
              {/* Text Input Street 1 */}
              <TextInput
                autoCorrect={true}
                changeTextDelay={500}
                onChangeText={newTextInputStreet1Value => {
                  try {
                    setInputStreet1Value(newTextInputStreet1Value);
                  } catch (err) {
                    console.error(err);
                  }
                }}
                webShowOutline={true}
                autoCapitalize={'words'}
                clearTextOnFocus={false}
                editable={true}
                placeholder={'Street 1'}
                placeholderTextColor={palettes.App['App Text 2ndary 7F868C']}
                secureTextEntry={false}
                style={StyleSheet.applyWidth(
                  {
                    backgroundColor: palettes.App['Custom Color'],
                    borderBottomWidth: 1,
                    borderColor: palettes.App['App Grey Inputs E2E2E2'],
                    borderLeftWidth: 1,
                    borderRadius: 8,
                    borderRightWidth: 1,
                    borderTopWidth: 1,
                    fontFamily: 'Poppins_400Regular',
                    marginBottom: 16,
                    paddingBottom: 16,
                    paddingLeft: 16,
                    paddingRight: 16,
                    paddingTop: 16,
                  },
                  dimensions.width
                )}
                textContentType={'streetAddressLine1'}
                value={inputStreet1Value}
              />
              {/* Text Input Street 2 */}
              <TextInput
                autoCorrect={true}
                changeTextDelay={500}
                onChangeText={newTextInputStreet2Value => {
                  try {
                    setInputStreet2Value(newTextInputStreet2Value);
                  } catch (err) {
                    console.error(err);
                  }
                }}
                webShowOutline={true}
                autoCapitalize={'words'}
                clearTextOnFocus={false}
                editable={true}
                placeholder={'Street 2'}
                placeholderTextColor={palettes.App['App Text 2ndary 7F868C']}
                secureTextEntry={false}
                style={StyleSheet.applyWidth(
                  {
                    backgroundColor: palettes.App['Custom Color'],
                    borderBottomWidth: 1,
                    borderColor: palettes.App['App Grey Inputs E2E2E2'],
                    borderLeftWidth: 1,
                    borderRadius: 8,
                    borderRightWidth: 1,
                    borderTopWidth: 1,
                    fontFamily: 'Poppins_400Regular',
                    marginBottom: 16,
                    paddingBottom: 16,
                    paddingLeft: 16,
                    paddingRight: 16,
                    paddingTop: 16,
                  },
                  dimensions.width
                )}
                textContentType={'streetAddressLine2'}
                value={inputStreet2Value}
              />
              {/* Text Input City */}
              <TextInput
                autoCorrect={true}
                changeTextDelay={500}
                onChangeText={newTextInputCityValue => {
                  try {
                    setInputCityValue(newTextInputCityValue);
                  } catch (err) {
                    console.error(err);
                  }
                }}
                webShowOutline={true}
                autoCapitalize={'words'}
                clearTextOnFocus={false}
                editable={true}
                placeholder={'City'}
                placeholderTextColor={palettes.App['App Text 2ndary 7F868C']}
                secureTextEntry={false}
                style={StyleSheet.applyWidth(
                  {
                    backgroundColor: palettes.App['Custom Color'],
                    borderBottomWidth: 1,
                    borderColor: palettes.App['App Grey Inputs E2E2E2'],
                    borderLeftWidth: 1,
                    borderRadius: 8,
                    borderRightWidth: 1,
                    borderTopWidth: 1,
                    fontFamily: 'Poppins_400Regular',
                    marginBottom: 16,
                    paddingBottom: 16,
                    paddingLeft: 16,
                    paddingRight: 16,
                    paddingTop: 16,
                  },
                  dimensions.width
                )}
                textContentType={'addressCity'}
                value={inputCityValue}
              />
              {/* Text Input State */}
              <TextInput
                autoCorrect={true}
                changeTextDelay={500}
                onChangeText={newTextInputStateValue => {
                  try {
                    setInputStateValue(newTextInputStateValue);
                  } catch (err) {
                    console.error(err);
                  }
                }}
                webShowOutline={true}
                autoCapitalize={'words'}
                clearTextOnFocus={false}
                editable={true}
                placeholder={'State'}
                placeholderTextColor={palettes.App['App Text 2ndary 7F868C']}
                secureTextEntry={false}
                style={StyleSheet.applyWidth(
                  {
                    backgroundColor: palettes.App['Custom Color'],
                    borderBottomWidth: 1,
                    borderColor: palettes.App['App Grey Inputs E2E2E2'],
                    borderLeftWidth: 1,
                    borderRadius: 8,
                    borderRightWidth: 1,
                    borderTopWidth: 1,
                    fontFamily: 'Poppins_400Regular',
                    marginBottom: 16,
                    paddingBottom: 16,
                    paddingLeft: 16,
                    paddingRight: 16,
                    paddingTop: 16,
                  },
                  dimensions.width
                )}
                textContentType={'addressState'}
                value={inputStateValue}
              />
              {/* Text Input Zip */}
              <TextInput
                autoCapitalize={'none'}
                autoCorrect={true}
                changeTextDelay={500}
                onChangeText={newTextInputZipValue => {
                  try {
                    setInputZipValue(newTextInputZipValue);
                  } catch (err) {
                    console.error(err);
                  }
                }}
                webShowOutline={true}
                clearTextOnFocus={false}
                editable={true}
                keyboardType={'numeric'}
                placeholder={'Zip'}
                placeholderTextColor={palettes.App['App Text 2ndary 7F868C']}
                secureTextEntry={false}
                style={StyleSheet.applyWidth(
                  {
                    backgroundColor: palettes.App['Custom Color'],
                    borderBottomWidth: 1,
                    borderColor: palettes.App['App Grey Inputs E2E2E2'],
                    borderLeftWidth: 1,
                    borderRadius: 8,
                    borderRightWidth: 1,
                    borderTopWidth: 1,
                    fontFamily: 'Poppins_400Regular',
                    marginBottom: 16,
                    paddingBottom: 16,
                    paddingLeft: 16,
                    paddingRight: 16,
                    paddingTop: 16,
                  },
                  dimensions.width
                )}
                textContentType={'postalCode'}
                value={inputZipValue}
              />
            </View>
          </View>
          {/* View Row Available Schedule */}
          <View
            style={StyleSheet.applyWidth(
              { paddingBottom: 25 },
              dimensions.width
            )}
          >
            {/* View Title */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'baseline',
                  flexDirection: 'row',
                  marginBottom: 26,
                },
                dimensions.width
              )}
            >
              {/* H1 */}
              <Text
                accessible={true}
                style={StyleSheet.applyWidth(
                  {
                    color: palettes.App['App Black 191F32'],
                    fontFamily: 'BebasNeue_400Regular',
                    fontSize: 24,
                    marginRight: 4,
                  },
                  dimensions.width
                )}
              >
                {'Pool'}
              </Text>
              {/* H1 Accent */}
              <Text
                accessible={true}
                style={StyleSheet.applyWidth(
                  {
                    color: palettes.App['App Red EB3E26'],
                    fontFamily: 'LeckerliOne_400Regular',
                    fontSize: 22,
                  },
                  dimensions.width
                )}
              >
                {'Availability'}
              </Text>
            </View>
            {/* View Error Address */}
            <>
              {!errorAvailability ? null : (
                <View
                  style={StyleSheet.applyWidth(
                    {
                      backgroundColor: palettes.App['App Red EB3E26'],
                      marginBottom: 8,
                      marginTop: 0,
                      paddingBottom: 8,
                      paddingLeft: 8,
                      paddingRight: 8,
                      paddingTop: 8,
                      width: '100%',
                    },
                    dimensions.width
                  )}
                >
                  {/* Error Message */}
                  <Text
                    accessible={true}
                    style={StyleSheet.applyWidth(
                      { color: palettes.App['Custom Color'] },
                      dimensions.width
                    )}
                  >
                    {'⚠️  Uh oh, please select at least 1 month.'}
                  </Text>
                </View>
              )}
            </>
            {/* View Row Pick Available Months */}
            <View
              style={StyleSheet.applyWidth(
                { paddingBottom: 25 },
                dimensions.width
              )}
            >
              {/* Label Container */}
              <View
                style={StyleSheet.applyWidth(
                  { paddingBottom: 12 },
                  dimensions.width
                )}
              >
                {/* Pick Available Days */}
                <Text
                  accessible={true}
                  style={StyleSheet.applyWidth(
                    {
                      color: palettes.App['App Text 2ndary 7F868C'],
                      fontFamily: 'Poppins_400Regular',
                    },
                    dimensions.width
                  )}
                >
                  {
                    'Which months can clients come meet you to take lessons at this pool?'
                  }
                </Text>
              </View>
              {/* View Options Container */}
              <View
                style={StyleSheet.applyWidth(
                  { flexWrap: 'wrap' },
                  dimensions.width
                )}
              >
                <Utils.CustomCodeErrorBoundary>
                  <SelectSchedule.PoolAvailability
                    jan={jan}
                    setJan={setJan}
                    feb={feb}
                    setFeb={setFeb}
                    mar={mar}
                    setMar={setMar}
                    apr={apr}
                    setApr={setApr}
                    may={may}
                    setMay={setMay}
                    jun={jun}
                    setJun={setJun}
                    jul={jul}
                    setJul={setJul}
                    aug={aug}
                    setAug={setAug}
                    sep={sep}
                    setSep={setSep}
                    oct={oct}
                    setOct={setOct}
                    nov={nov}
                    setNov={setNov}
                    dec={dec}
                    setDec={setDec}
                    setPoolAvailability={setPoolAvailability}
                  />
                </Utils.CustomCodeErrorBoundary>
              </View>
            </View>
          </View>
          {/* View Row Pool Meeting Instructions */}
          <View
            style={StyleSheet.applyWidth(
              { paddingBottom: 25 },
              dimensions.width
            )}
          >
            {/* Pool Meeting Instructions */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'baseline',
                  flexDirection: 'row',
                  marginBottom: 26,
                },
                dimensions.width
              )}
            >
              {/* H1 */}
              <Text
                accessible={true}
                style={StyleSheet.applyWidth(
                  {
                    color: palettes.App['App Black 191F32'],
                    fontFamily: 'BebasNeue_400Regular',
                    fontSize: 24,
                    marginRight: 4,
                  },
                  dimensions.width
                )}
              >
                {'Pool'}
              </Text>
              {/* H1 Accent */}
              <Text
                accessible={true}
                style={StyleSheet.applyWidth(
                  {
                    color: palettes.App['App Red EB3E26'],
                    fontFamily: 'LeckerliOne_400Regular',
                    fontSize: 22,
                  },
                  dimensions.width
                )}
              >
                {'Meeting Instructions'}
              </Text>
            </View>
            {/* Label Container */}
            <View
              style={StyleSheet.applyWidth(
                { paddingBottom: 12 },
                dimensions.width
              )}
            >
              {/* Please write any notes */}
              <Text
                accessible={true}
                style={StyleSheet.applyWidth(
                  {
                    color: palettes.App['App Text 2ndary 7F868C'],
                    fontFamily: 'Poppins_400Regular',
                  },
                  dimensions.width
                )}
              >
                {
                  'Please write any notes the client may need to know to access the pool'
                }
              </Text>
            </View>
            {/* View Large Text Input Container */}
            <View
              style={StyleSheet.applyWidth(
                { flexWrap: 'wrap', width: '100%' },
                dimensions.width
              )}
            >
              {/* View Text Area Container */}
              <View
                style={StyleSheet.applyWidth(
                  { height: 145, width: '100%' },
                  dimensions.width
                )}
              >
                <TextInput
                  autoCorrect={true}
                  changeTextDelay={500}
                  multiline={true}
                  numberOfLines={4}
                  onChangeText={newTextAreaValue => {
                    try {
                      setPoolInstructionsValue(newTextAreaValue);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  textAlignVertical={'top'}
                  webShowOutline={true}
                  editable={true}
                  placeholder={'Pool meeting instructions'}
                  placeholderTextColor={palettes.App['App Text 2ndary 7F868C']}
                  style={StyleSheet.applyWidth(
                    {
                      borderBottomWidth: 1,
                      borderColor: palettes.App['App Grey Inputs E2E2E2'],
                      borderLeftWidth: 1,
                      borderRadius: 8,
                      borderRightWidth: 1,
                      borderTopWidth: 1,
                      color: palettes.App['App Black 191F32'],
                      fontFamily: 'Poppins_400Regular',
                      height: '100%',
                      paddingBottom: 16,
                      paddingLeft: 16,
                      paddingRight: 16,
                      paddingTop: 16,
                      width: '100%',
                    },
                    dimensions.width
                  )}
                  value={poolInstructionsValue}
                />
              </View>
            </View>
          </View>
        </View>
        {/* View Bottom Button Spacer */}
        <View>
          <Spacer left={8} right={8} bottom={55} top={55} />
        </View>
      </KeyboardAwareScrollView>
      {/* View Bottom Anchor */}
      <View
        style={StyleSheet.applyWidth(
          {
            backgroundColor: palettes.App['Custom Color'],
            bottom: 0,
            paddingLeft: '5.35%',
            paddingRight: '5.35%',
            position: 'absolute',
            width: '100%',
          },
          dimensions.width
        )}
      >
        {/* View Button Container */}
        <View
          style={StyleSheet.applyWidth(
            { alignItems: 'center', marginBottom: 16, width: '100%' },
            dimensions.width
          )}
        >
          {/* Save Pool */}
          <Button
            iconPosition={'left'}
            onPress={() => {
              const handler = async () => {
                try {
                  await uploadPoolData(Variables, setGlobalVariableValue);
                } catch (err) {
                  console.error(err);
                }
              };
              handler();
            }}
            disabled={disableSaveButton}
            style={StyleSheet.applyWidth(
              {
                backgroundColor: palettes.App['App Red EB3E26'],
                borderRadius: 50,
                fontFamily: 'BebasNeue_400Regular',
                fontSize: 18,
                paddingBottom: 14,
                paddingTop: 14,
                textAlign: 'center',
                width: '100%',
              },
              dimensions.width
            )}
            title={'SAVE POOL'}
          />
          {/* View Activity Indicator */}
          <>
            {!Constants['is_loading'] ? null : (
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    backgroundColor: palettes.App['App Red EB3E26'],
                    borderRadius: 50,
                    marginTop: -50,
                    opacity: 0.8,
                    paddingBottom: 7,
                    paddingTop: 7,
                    width: '100%',
                  },
                  dimensions.width
                )}
              >
                <ActivityIndicator
                  animating={true}
                  hidesWhenStopped={true}
                  size={'small'}
                  color={palettes.App['Custom Color']}
                  style={StyleSheet.applyWidth(
                    { height: 36, width: 36 },
                    dimensions.width
                  )}
                />
              </View>
            )}
          </>
        </View>
        <Spacer bottom={34} left={0} right={0} top={0} />
      </View>

      <Modal
        supportedOrientations={['portrait', 'landscape']}
        animationType={'slide'}
        presentationStyle={'fullScreen'}
        transparent={true}
        visible={Constants['gModalVisible']}
      >
        <View
          style={StyleSheet.applyWidth(
            {
              backgroundColor: palettes.App['App Transparent'],
              height: '100%',
              width: '100%',
            },
            dimensions.width
          )}
        >
          <Surface
            elevation={3}
            style={StyleSheet.applyWidth(
              {
                backgroundColor: palettes.App['App Blue eef6fa'],
                borderTopLeftRadius: 20,
                borderTopRightRadius: 20,
                bottom: 0,
                height: '62%',
                minHeight: 40,
                paddingLeft: '5.35%',
                paddingRight: '5.35%',
                paddingTop: 26,
                position: 'absolute',
                width: '100%',
              },
              dimensions.width
            )}
          >
            {/* Modal Title Container */}
            <View
              style={StyleSheet.applyWidth(
                { marginBottom: 15 },
                dimensions.width
              )}
            >
              <Text
                accessible={true}
                style={StyleSheet.applyWidth(
                  {
                    alignSelf: 'center',
                    color: palettes.App['App Black 191F32'],
                    fontFamily: 'Poppins_600SemiBold',
                    fontSize: 20,
                    textAlign: 'center',
                  },
                  dimensions.width
                )}
              >
                {modalMessage}
              </Text>
            </View>
            {/* View Loading Background */}
            <>
              {!Constants['is_loading'] ? null : (
                <View
                  style={StyleSheet.applyWidth(
                    {
                      borderRadius: 8,
                      height: 200,
                      overflow: 'hidden',
                      width: '97%',
                    },
                    dimensions.width
                  )}
                >
                  <ImageBackground
                    resizeMode={'cover'}
                    source={{
                      uri: 'https://thumbs.gfycat.com/WickedAdventurousFairyfly-size_restricted.gif',
                    }}
                    style={StyleSheet.applyWidth(
                      { alignItems: 'center', height: '100%', width: '100%' },
                      dimensions.width
                    )}
                  >
                    {/* View Activity Holder */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          alignSelf: 'center',
                          height: '100%',
                          paddingTop: 50,
                          width: '100%',
                        },
                        dimensions.width
                      )}
                    >
                      <ActivityIndicator
                        animating={true}
                        hidesWhenStopped={true}
                        color={palettes.App['Custom Color']}
                        size={'large'}
                        style={StyleSheet.applyWidth(
                          { height: 36, width: 36 },
                          dimensions.width
                        )}
                      />
                    </View>
                  </ImageBackground>
                </View>
              )}
            </>
            {/* View Ok Button Container */}
            <>
              {Constants['is_loading'] ? null : (
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      marginBottom: 32,
                      marginTop: 15,
                      width: '100%',
                    },
                    dimensions.width
                  )}
                >
                  {/* Ok button */}
                  <Button
                    iconPosition={'left'}
                    onPress={() => {
                      try {
                        modalCloseFunction(Variables, setGlobalVariableValue);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor: palettes.App['App Red EB3E26'],
                        borderRadius: 50,
                        fontFamily: 'BebasNeue_400Regular',
                        fontSize: 18,
                        paddingBottom: 14,
                        paddingTop: 14,
                        textAlign: 'center',
                        width: '100%',
                      },
                      dimensions.width
                    )}
                    title={'OK'}
                  />
                  {/* View Activity Indicator */}
                  <>
                    {!Constants['is_loading'] ? null : (
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            backgroundColor: palettes.App['App Red EB3E26'],
                            borderRadius: 50,
                            marginTop: -50,
                            opacity: 0.8,
                            paddingBottom: 7,
                            paddingTop: 7,
                            width: '100%',
                          },
                          dimensions.width
                        )}
                      >
                        <ActivityIndicator
                          animating={true}
                          hidesWhenStopped={true}
                          size={'small'}
                          color={palettes.App['Custom Color']}
                          style={StyleSheet.applyWidth(
                            { height: 36, width: 36 },
                            dimensions.width
                          )}
                        />
                      </View>
                    )}
                  </>
                </View>
              )}
            </>
          </Surface>
        </View>
      </Modal>
    </ScreenContainer>
  );
};

export default withTheme(AddPoolScreen);
