/**
 * Learn more about deep linking with React Navigation
 * https://reactnavigation.org/docs/deep-linking
 * https://reactnavigation.org/docs/configuring-links
 */

import * as Linking from 'expo-linking';
import { Platform } from 'react-native';

function renderLinkingPrefix() {
  try {
    return Linking.createURL('/');
  } catch (e) {
    return 'draftbit://';
  }
}

const prefix = renderLinkingPrefix();

const linking = {
  enabled: Platform.OS === 'web' ? false : true,
  prefixes: [prefix],
  config: {
    screens: {
      JobsScreen: {
        screens: {
          JobsScreen: {
            path: 'JobsScreen',
          },
        },
      },

      KnowledgeBaseScreen: {
        screens: {
          KnowledgeBaseScreen: {
            path: 'KnowledgeBaseScreen',
          },
        },
      },

      MyAccountScreen: {
        screens: {
          MyAccountScreen: {
            path: 'MyAccountScreen',
          },
        },
      },

      Onboarding1UploadCPRScreen: {
        screens: {
          Onboarding1UploadCPRScreen: {
            path: 'Onboarding1UploadCPRScreen',
          },
        },
      },

      Onboarding2InstructorBioScreen: {
        screens: {
          Onboarding2InstructorBioScreen: {
            path: 'Onboarding2InstructorBioScreen',
          },
        },
      },

      Onboarding3CompleteTipaltiFormsScreen: {
        screens: {
          Onboarding3CompleteTipaltiFormsScreen: {
            path: 'Onboarding3CompleteTipaltiFormsScreen',
          },
        },
      },

      OnboardingCompleteSunsational101Screen: {
        screens: {
          OnboardingCompleteSunsational101Screen: {
            path: 'OnboardingCompleteSunsational101Screen/:skip?',
          },
        },
      },

      OpportunityDetailsLiveScreen: {
        screens: {
          OpportunityDetailsLiveScreen: {
            path: 'OpportunityDetailsLiveScreen/:opportunityID?',
          },
        },
      },

      BottomTabNavigator: { screens: {} },
    },
  },
};

export default linking;
