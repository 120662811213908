import * as React from 'react';
import {
  useQuery,
  useMutation,
  useIsFetching,
  useQueryClient,
} from 'react-query';
import useFetch from 'react-fetch-hook';
import { useIsFocused } from '@react-navigation/native';
import { handleResponse, isOkStatus } from '../utils/handleRestApiResponse';
import usePrevious from '../utils/usePrevious';
import {
  encodeQueryParam,
  renderParam,
  renderQueryString,
} from '../utils/encodeQueryParam';
import * as GlobalVariables from '../config/GlobalVariableContext';

const cleanHeaders = headers =>
  Object.fromEntries(Object.entries(headers).filter(kv => kv[1] != null));

export const activelyReceiveNewOpps$SIControlled$POST = async (
  Constants,
  { siKnackID, siWantsNewOpps },
  handlers = {}
) => {
  const url = `https://hook.integromat.com/k7ksqgw29r36a3qrc0hfwh6wp3v4x9ai`;
  const options = {
    body: JSON.stringify({
      siKnackID: siKnackID,
      siWantsNewOpps: siWantsNewOpps,
    }),
    headers: cleanHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }),
    method: 'POST',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useActivelyReceiveNewOpps$SIControlled$POST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      activelyReceiveNewOpps$SIControlled$POST(
        Constants,
        { ...initialArgs, ...args },
        handlers
      ),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData(
            'notification settings',
            previousValue
          );
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('notification setting');
        queryClient.invalidateQueries('notification settings');
      },
    }
  );
};

export const FetchActivelyReceiveNewOpps$SIControlled$POST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  siKnackID,
  siWantsNewOpps,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useActivelyReceiveNewOpps$SIControlled$POST(
    { siKnackID, siWantsNewOpps },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchActivelyReceiveNewOpps$SIControlled$: refetch,
  });
};

export const agreeToTermsAndConditionsPOST = async (
  Constants,
  {
    deviceID,
    deviceIP,
    email,
    siFullName,
    siKnackID,
    termsID,
    termsText,
    termsVersion,
  },
  handlers = {}
) => {
  const url = `https://hook.integromat.com/vmpn4h3viykcitfj6r79b5dd4kx7ld8h`;
  const options = {
    body: JSON.stringify({
      siEmail: email,
      siFullName: siFullName,
      termsText: termsText,
      termsVersion: termsVersion,
      termsID: termsID,
      siKnackID: siKnackID,
      deviceID: deviceID,
      deviceIP: deviceIP,
    }),
    headers: cleanHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }),
    method: 'POST',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useAgreeToTermsAndConditionsPOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      agreeToTermsAndConditionsPOST(
        Constants,
        { ...initialArgs, ...args },
        handlers
      ),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('terms', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('term');
        queryClient.invalidateQueries('terms');
      },
    }
  );
};

export const FetchAgreeToTermsAndConditionsPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  deviceID,
  deviceIP,
  email,
  siFullName,
  siKnackID,
  termsID,
  termsText,
  termsVersion,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useAgreeToTermsAndConditionsPOST(
    {
      deviceID,
      deviceIP,
      email,
      siFullName,
      siKnackID,
      termsID,
      termsText,
      termsVersion,
    },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchAgreeToTermsAndConditions: refetch,
  });
};

export const sendEmailToMatching$BookingPOST = async (
  Constants,
  {
    lessonID,
    lessonKnackID,
    message,
    packageID,
    packageKnackID,
    siEmailAddress,
    siName,
  },
  handlers = {}
) => {
  const url = `https://hook.integromat.com/bjxblx2d8e8i9d717aksifkbz4xhjpou`;
  const options = {
    body: JSON.stringify({
      siEmailAddress: siEmailAddress,
      message: message,
      lessonID: lessonID,
      lessonKnackID: lessonKnackID,
      packageID: packageID,
      packageKnackID: packageKnackID,
      siName: siName,
    }),
    headers: cleanHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }),
    method: 'POST',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useSendEmailToMatching$BookingPOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      sendEmailToMatching$BookingPOST(
        Constants,
        { ...initialArgs, ...args },
        handlers
      ),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('send email', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('send email');
        queryClient.invalidateQueries('send emails');
      },
    }
  );
};

export const FetchSendEmailToMatching$BookingPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  lessonID,
  lessonKnackID,
  message,
  packageID,
  packageKnackID,
  siEmailAddress,
  siName,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useSendEmailToMatching$BookingPOST(
    {
      lessonID,
      lessonKnackID,
      message,
      packageID,
      packageKnackID,
      siEmailAddress,
      siName,
    },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchSendEmailToMatching$Booking: refetch,
  });
};

export const sendJobRequestsAndDeclinesPOST = async (
  Constants,
  {
    declineReason,
    opportunityId2,
    requestSource,
    requestedPayment,
    siResponse,
    siSchedule,
    supportinDetails,
  },
  handlers = {}
) => {
  const url = `https://hook.integromat.com/gejrlmg57qs16yk6xah44dim4fqx38pj`;
  const options = {
    body: JSON.stringify({
      opportunityId2: opportunityId2,
      siResponse: siResponse,
      siSchedule: siSchedule,
      requestedPayment: requestedPayment,
      supportingDetails: supportinDetails,
      requestSource: requestSource,
      declineReason: declineReason,
    }),
    headers: cleanHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }),
    method: 'POST',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useSendJobRequestsAndDeclinesPOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      sendJobRequestsAndDeclinesPOST(
        Constants,
        { ...initialArgs, ...args },
        handlers
      ),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('posts', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('post');
        queryClient.invalidateQueries('posts');
      },
    }
  );
};

export const FetchSendJobRequestsAndDeclinesPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  declineReason,
  opportunityId2,
  requestSource,
  requestedPayment,
  siResponse,
  siSchedule,
  supportinDetails,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useSendJobRequestsAndDeclinesPOST(
    {
      declineReason,
      opportunityId2,
      requestSource,
      requestedPayment,
      siResponse,
      siSchedule,
      supportinDetails,
    },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchSendJobRequestsAndDeclines: refetch,
  });
};

export const createOpportunitiesPOST = async (
  Constants,
  { AUTH_KNACK_USER_ID },
  handlers = {}
) => {
  const url = `https://hook.integromat.com/ou8ywz1qkndflgkmm24wz4xnn5medhn2`;
  const options = {
    body: JSON.stringify({ AUTH_KNACK_USER_ID: AUTH_KNACK_USER_ID }),
    headers: cleanHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }),
    method: 'POST',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useCreateOpportunitiesPOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      createOpportunitiesPOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('opportunities', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('opportunity');
        queryClient.invalidateQueries('opportunities');
      },
    }
  );
};

export const FetchCreateOpportunitiesPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  AUTH_KNACK_USER_ID,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useCreateOpportunitiesPOST(
    { AUTH_KNACK_USER_ID },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchCreateOpportunities: refetch,
  });
};

export const deleteAndUpdateOppsPOST = async (
  Constants,
  { AUTH_KNACK_USER_ID, shouldUpdateOpps },
  handlers = {}
) => {
  const url = `https://hook.integromat.com/pdffamv8i0glcrui7nf78616tgfbwmom`;
  const options = {
    body: JSON.stringify({
      AUTH_KNACK_USER_ID: AUTH_KNACK_USER_ID,
      shouldUpdateOpps: shouldUpdateOpps,
    }),
    headers: cleanHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }),
    method: 'POST',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useDeleteAndUpdateOppsPOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      deleteAndUpdateOppsPOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('opportunities', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('opportunity');
        queryClient.invalidateQueries('opportunities');
      },
    }
  );
};

export const FetchDeleteAndUpdateOppsPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  AUTH_KNACK_USER_ID,
  shouldUpdateOpps,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useDeleteAndUpdateOppsPOST(
    { AUTH_KNACK_USER_ID, shouldUpdateOpps },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchDeleteAndUpdateOpps: refetch,
  });
};

export const receiveOpenFeedbackPOST = async (
  Constants,
  { APP_VERSION, feedback, instructor, rating },
  handlers = {}
) => {
  const url = `https://hook.integromat.com/da0nos9cu65qkcpudruxzhq72b89yuhb`;
  const options = {
    body: JSON.stringify({
      instructor: instructor,
      feedback: feedback,
      rating: rating,
      APP_VERSION: APP_VERSION,
    }),
    headers: cleanHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }),
    method: 'POST',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useReceiveOpenFeedbackPOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      receiveOpenFeedbackPOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('feedback', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('feedback');
        queryClient.invalidateQueries('feedbacks');
      },
    }
  );
};

export const FetchReceiveOpenFeedbackPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  APP_VERSION,
  feedback,
  instructor,
  rating,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useReceiveOpenFeedbackPOST(
    { APP_VERSION, feedback, instructor, rating },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchReceiveOpenFeedback: refetch,
  });
};
