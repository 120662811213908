import React from 'react';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import { Button, ScreenContainer, Spacer, withTheme } from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import {
  ActivityIndicator,
  ImageBackground,
  StatusBar,
  Text,
  View,
} from 'react-native';

const ResetpasswordsuccessScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }

      const entry = StatusBar.pushStackEntry?.({ barStyle: 'light-content' });
      return () => StatusBar.popStackEntry?.(entry);
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={false}
      style={StyleSheet.applyWidth(
        {
          backgroundColor: palettes.App['App Brand Blue 2C9AD9'],
          justifyContent: 'center',
        },
        dimensions.width
      )}
    >
      {/* View Background Container */}
      <View
        style={StyleSheet.applyWidth(
          { height: '90%', justifyContent: 'center', width: '100%' },
          dimensions.width
        )}
      >
        <ImageBackground
          resizeMode={'cover'}
          source={Images.WhiteSun}
          style={StyleSheet.applyWidth(
            { height: '100%', justifyContent: 'center', width: '100%' },
            dimensions.width
          )}
        />
      </View>

      <View
        style={StyleSheet.applyWidth(
          {
            alignItems: 'center',
            paddingLeft: '5.35%',
            paddingRight: '5.35%',
            paddingTop: '75%',
            position: 'absolute',
            width: '100%',
          },
          dimensions.width
        )}
      >
        <Text
          accessible={true}
          style={StyleSheet.applyWidth(
            {
              alignSelf: 'center',
              color: palettes.App['Custom Color'],
              fontFamily: 'Poppins_600SemiBold',
              fontSize: 24,
            },
            dimensions.width
          )}
        >
          {'Success'}
        </Text>

        <Text
          accessible={true}
          style={StyleSheet.applyWidth(
            {
              alignSelf: 'center',
              color: palettes.App['Custom Color'],
              fontFamily: 'Poppins_400Regular',
              textAlign: 'center',
            },
            dimensions.width
          )}
        >
          {
            'Your password has been reset. Please check your email for your new password and use it to log in.'
          }
        </Text>
      </View>
      {/* View Bottom Anchor */}
      <View
        style={StyleSheet.applyWidth(
          {
            bottom: 0,
            paddingLeft: '5.35%',
            paddingRight: '5.35%',
            position: 'absolute',
            width: '100%',
          },
          dimensions.width
        )}
      >
        {/* View Button Container */}
        <View
          style={StyleSheet.applyWidth(
            { alignItems: 'center', marginBottom: 32, width: '100%' },
            dimensions.width
          )}
        >
          {/* Back to login */}
          <Button
            iconPosition={'left'}
            onPress={() => {
              try {
                navigation.navigate('Login2Screen');
              } catch (err) {
                console.error(err);
              }
            }}
            style={StyleSheet.applyWidth(
              {
                backgroundColor: palettes.App['App Red EB3E26'],
                borderRadius: 50,
                fontFamily: 'BebasNeue_400Regular',
                fontSize: 18,
                paddingBottom: 14,
                paddingTop: 14,
                textAlign: 'center',
                width: '100%',
              },
              dimensions.width
            )}
            title={'BACK TO LOGIN' ?? 'RESET PASSWORD'}
          />
          {/* View Activity Indicator */}
          <>
            {!Constants['is_loading'] ? null : (
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    backgroundColor: palettes.App['App Red EB3E26'],
                    borderRadius: 50,
                    marginTop: -50,
                    opacity: 0.8,
                    paddingBottom: 7,
                    paddingTop: 7,
                    width: '100%',
                  },
                  dimensions.width
                )}
              >
                <ActivityIndicator
                  animating={true}
                  hidesWhenStopped={true}
                  size={'small'}
                  color={palettes.App['Custom Color']}
                  style={StyleSheet.applyWidth(
                    { height: 36, width: 36 },
                    dimensions.width
                  )}
                />
              </View>
            )}
          </>
        </View>
        <Spacer bottom={34} left={0} right={0} top={0} />
      </View>
    </ScreenContainer>
  );
};

export default withTheme(ResetpasswordsuccessScreen);
