export default {
  HeaderHomescreen: require('../assets/images/HeaderHomescreen.png'),
  LogoRound110x110: require('../assets/images/LogoRound110x110.png'),
  IconSettingsIconlyLight: require('../assets/images/IconSettingsIconlyLight.png'),
  IconBriefcaseBlue: require('../assets/images/IconBriefcaseBlue.png'),
  IconBriefcaseGrey: require('../assets/images/IconBriefcaseGrey.png'),
  IconHomeBlue: require('../assets/images/IconHomeBlue.png'),
  IconHomeGrey: require('../assets/images/IconHomeGrey.png'),
  IconProfileBlue: require('../assets/images/IconProfileBlue.png'),
  IconProfileGrey: require('../assets/images/IconProfileGrey.png'),
  IconSearchBlue: require('../assets/images/IconSearchBlue.png'),
  IconSearchGrey: require('../assets/images/IconSearchGrey.png'),
  HeaderLogin: require('../assets/images/HeaderLogin.png'),
  Logo: require('../assets/images/Logo.png'),
  FooterWideMin1: require('../assets/images/FooterWideMin1.png'),
  WhiteSun: require('../assets/images/WhiteSun.png'),
  OnboardingWelcome: require('../assets/images/OnboardingWelcome.png'),
  ArrowLeft: require('../assets/images/ArrowLeft.png'),
  IconPaper: require('../assets/images/IconPaper.png'),
  ProfileFiller: require('../assets/images/ProfileFiller.png'),
  IconInfo: require('../assets/images/IconInfo.png'),
  LogoTipalti: require('../assets/images/LogoTipalti.png'),
  Group162: require('../assets/images/Group16(2).png'),
  IconInfoCircle: require('../assets/images/IconInfoCircle.png'),
  HelpCS: require('../assets/images/HelpCS.png'),
  HelpWA: require('../assets/images/HelpWA.png'),
  HelpWeb: require('../assets/images/HelpWeb.png'),
  HelpFB: require('../assets/images/HelpFB.png'),
  HelpTwtr: require('../assets/images/HelpTwtr.png'),
  HelpIG: require('../assets/images/HelpIG.png'),
  PaydayBg: require('../assets/images/PaydayBg.png'),
  Banner: require('../assets/images/Banner.png'),
  User: require('../assets/images/User.png'),
  Discord: require('../assets/images/Discord.png'),
  Yt: require('../assets/images/Yt.png'),
  Twtr: require('../assets/images/Twtr.png'),
  Ig: require('../assets/images/Ig.png'),
  Fb: require('../assets/images/Fb.png'),
  Telgrm: require('../assets/images/Telgrm.png'),
  LastStream: require('../assets/images/LastStream.png'),
  Games: require('../assets/images/Games.png'),
  Video: require('../assets/images/Video.png'),
  IconKnowledgeBlue: require('../assets/images/IconKnowledgeBlue.png'),
  IconKnowledgeGrey: require('../assets/images/IconKnowledgeGrey.png'),
};
