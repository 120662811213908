import React from 'react';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import * as Track from '../custom-files/Track';
import * as Utility from '../custom-files/Utility';
import amplitudeEvents from '../global-functions/amplitudeEvents';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import openImagePickerUtil from '../utils/openImagePicker';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  Button,
  Link,
  SVG,
  ScreenContainer,
  Spacer,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import {
  ActivityIndicator,
  Image,
  ImageBackground,
  Text,
  View,
} from 'react-native';

const Onboarding1UploadCPRScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [cprUploaded, setCprUploaded] = React.useState(false);
  const shouldSkipInstructorBio = () => {
    console.log('Variables.gBioText === ', Variables.gBioText);
    console.log('Variables.gBioImage === ', Variables.gBioImage);
    console.log('gBioText == ""', Variables.gBioText == '');
    console.log('gBioImage == ""', Variables.gBioImage == '');
    if (Variables.gBioText == '' || Variables.gBioImage == '') {
      return false;
    }

    return true;
  };

  const navOnboardingNext = () => {
    const {
      gCprImage,
      gBioImage,
      gBioText,
      gTipaltiComplete,
      gSunsational101Complete,
      onboardingSteps,
    } = Variables;

    if (gBioImage == '' || gBioText == '') {
      props.navigation.navigate('Onboarding2InstructorBioScreen');
    }

    if (gTipaltiComplete == false) {
      props.navigation.navigate('Onboarding3CompleteTipaltiFormsScreen');
    }

    if (!gSunsational101Complete) {
      props.navigation.navigate('OnboardingCompleteSunsational101Screen');
    }

    if (!gCprImage) {
      props.navigation.navigate('Onboard4IncompleteWarningScreen');
    }

    props.navigation.navigate('HomeScreen');
  };

  const uploadCprImage = async (
    Variables,
    setGlobalVariableValue,
    newCprImage
  ) => {
    const maxFileSize = 2 * 1024 * 1024; // 2MB in bytes

    const imageData = newCprImage.split(',');
    const base64Data = imageData[1];
    const fileSize = base64Data.length * 0.75;

    if (fileSize > maxFileSize) {
      await setGlobalVariableValue({
        key: 'is_loading',
        value: false,
      });
      alert(
        'Error: The uploaded image is too large. Please upload an image that is smaller than 2MB.'
      );
      return;
    }

    await setGlobalVariableValue({
      key: 'is_loading',
      value: true,
    });

    let requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        base64Data: base64Data,
        knackUserRecordId: Variables.AUTH_KNACK_USER_ID,
      }),
    };

    try {
      const uploadImage = await fetch(
        `https://hook.integromat.com/kwrfrc8m2pjgf3iho4qky3s776rujka4`,
        requestOptions
      );
      const uploadImageResponse = await uploadImage.json();

      if (!uploadImage.ok) {
        setGlobalVariableValue({
          key: 'gUploadError',
          value: uploadImageResponse.errorMessage,
        });

        throw new Error(`Error! status: ${response.status}`);
      }

      await setGlobalVariableValue({
        key: 'is_loading',
        value: false,
      });
    } catch (err) {
      console.log('CATCH ERROR BLOCK!');
      console.error(`What is the error?: ${err.message}`);

      setGlobalVariableValue({
        key: 'is_loading',
        value: false,
      });
    }
  };
  React.useEffect(() => {
    Track.onEnterScreen(
      'Onboarding1UploadCPRScreen',
      Variables,
      setGlobalVariableValue,
      props.navigation.navigate,
      props?.route?.params?.skipAuth
    );
  }, []);
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      if (!(props.route?.params?.skip ?? '')) {
        return;
      }
      navigation.navigate('Onboarding2InstructorBioScreen', {
        skip: shouldSkipInstructorBio(),
      });
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={false}
      hasTopSafeArea={false}
      style={StyleSheet.applyWidth(
        { backgroundColor: palettes.App['App Gold #FFC400'] },
        dimensions.width
      )}
    >
      {/* View Header Container */}
      <View
        style={StyleSheet.applyWidth(
          {
            height: '10%',
            justifyContent: 'center',
            maxHeight: '11%',
            paddingBottom: 15,
          },
          dimensions.width
        )}
      >
        {/* Spacer Top Nav */}
        <Spacer bottom={0} left={0} right={0} top={44} />
        {/* View Header Navigation */}
        <View
          style={StyleSheet.applyWidth(
            {
              flexDirection: 'row',
              justifyContent: 'space-between',
              paddingLeft: '5.35%',
              paddingRight: '5.35%',
            },
            dimensions.width
          )}
        >
          {/* View Back Button Container */}
          <View
            style={StyleSheet.applyWidth(
              { height: 24, width: 24 },
              dimensions.width
            )}
          >
            <Touchable
              onPress={() => {
                try {
                  navigation.goBack();
                } catch (err) {
                  console.error(err);
                }
              }}
              style={StyleSheet.applyWidth(
                { height: '100%', width: '100%' },
                dimensions.width
              )}
            >
              {/* View Touchable Settings */}
              <View
                style={StyleSheet.applyWidth(
                  { height: '100%', width: '100%' },
                  dimensions.width
                )}
              >
                <SVG
                  source={Constants['uiBackArrow']}
                  style={StyleSheet.applyWidth(
                    { height: '100%', width: '100%' },
                    dimensions.width
                  )}
                />
              </View>
            </Touchable>
          </View>
          {/* View Page Title Container */}
          <View>
            {/* Page Title */}
            <Text
              accessible={true}
              style={StyleSheet.applyWidth(
                {
                  alignSelf: 'center',
                  color: theme.colors.text.strong,
                  fontFamily: 'Poppins_700Bold',
                },
                dimensions.width
              )}
            >
              {'Complete Profile'}
            </Text>
          </View>
          {/* View Skip Container */}
          <View>
            {/* Skip */}
            <Link
              accessible={true}
              onPress={() => {
                try {
                  navigation.navigate('Onboarding2InstructorBioScreen', {
                    skip: shouldSkipInstructorBio(),
                  });
                  amplitudeEvents(
                    Variables,
                    'skip cpr upload',
                    { origin: 'upload cpr screen', category: 'onboarding' },
                    undefined
                  );
                } catch (err) {
                  console.error(err);
                }
              }}
              style={StyleSheet.applyWidth(
                {
                  color: palettes.App['App Black 191F32'],
                  fontFamily: 'BebasNeue_400Regular',
                  fontSize: 18,
                  textDecorationLine: 'underline',
                  textTransform: 'uppercase',
                },
                dimensions.width
              )}
              title={'SKIP'}
            />
          </View>
        </View>
      </View>
      {/* View Card Container */}
      <View
        style={StyleSheet.applyWidth(
          {
            backgroundColor: palettes.App['Custom Color'],
            borderRadius: 0,
            borderTopLeftRadius: '16',
            borderTopRightRadius: '16',
            height: '90%',
            justifyContent: 'space-between',
            paddingLeft: '5.35%',
            paddingRight: '5.35%',
            width: '100%',
          },
          dimensions.width
        )}
      >
        {/* View Card Title Container */}
        <View
          style={StyleSheet.applyWidth(
            { paddingTop: 22, width: '100%' },
            dimensions.width
          )}
        >
          {/* Title */}
          <Text
            accessible={true}
            style={StyleSheet.applyWidth(
              {
                color: palettes.App['App Black 191F32'],
                fontFamily: 'Poppins_600SemiBold',
                fontSize: 20,
                textAlign: 'center',
              },
              dimensions.width
            )}
          >
            {'Upload Your CPR Certification'}
          </Text>
        </View>
        {/* View Image Container */}
        <View
          style={StyleSheet.applyWidth(
            {
              alignItems: 'center',
              borderBottomWidth: 1,
              borderColor: palettes.App['App Grey Inputs E2E2E2'],
              borderLeftWidth: 1,
              borderRadius: 24,
              borderRightWidth: 1,
              borderStyle: 'dashed',
              borderTopWidth: 1,
              height: '70%',
              justifyContent: 'center',
              width: '100%',
            },
            dimensions.width
          )}
        >
          {/* View Image Background Container */}
          <View
            style={StyleSheet.applyWidth(
              { height: '85%', width: '100%' },
              dimensions.width
            )}
          >
            <ImageBackground
              resizeMode={'contain'}
              source={{ uri: `${Constants['gCprImage']}` }}
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  height: '100%',
                  justifyContent: 'flex-end',
                  width: '100%',
                },
                dimensions.width
              )}
            >
              {/* View CTA Upload Container */}
              <>
                {Constants['gCprImage'] ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        marginBottom: '50%',
                        width: '100%',
                      },
                      dimensions.width
                    )}
                  >
                    {/* Touchable Upload Image */}
                    <Touchable
                      onPress={() => {
                        const handler = async () => {
                          try {
                            const newCprImage = await openImagePickerUtil({
                              mediaTypes: 'Images',
                              allowsEditing: false,
                              quality: 0.2,
                              allowsMultipleSelection: false,
                              permissionErrorMessage:
                                'Sorry, we need media library permissions to make this work.',
                              showAlertOnPermissionError: true,
                            });

                            if (!newCprImage) {
                              return;
                            }
                            setGlobalVariableValue({
                              key: 'gCprImage',
                              value: newCprImage,
                            });
                            await uploadCprImage(
                              Variables,
                              setGlobalVariableValue,
                              newCprImage
                            );
                          } catch (err) {
                            console.error(err);
                          }
                        };
                        handler();
                      }}
                      style={StyleSheet.applyWidth(
                        { width: '90%' },
                        dimensions.width
                      )}
                    >
                      {/* View Icon Container */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            alignSelf: 'center',
                            borderColor: palettes.App['App Grey Inputs E2E2E2'],
                            borderRadius: 50,
                            height: 56,
                            justifyContent: 'center',
                            marginBottom: 20,
                            width: 56,
                          },
                          dimensions.width
                        )}
                      >
                        <Image
                          resizeMode={'cover'}
                          source={Images.IconPaper}
                          style={StyleSheet.applyWidth(
                            { height: 24, width: 24 },
                            dimensions.width
                          )}
                        />
                      </View>
                      {/* View Text Container */}
                      <View>
                        <Text
                          accessible={true}
                          style={StyleSheet.applyWidth(
                            {
                              color: palettes.App['App Text 2ndary 7F868C'],
                              fontFamily: 'Poppins_400Regular',
                              textAlign: 'center',
                            },
                            dimensions.width
                          )}
                        >
                          {
                            'Tap here to upload\nthe photo of your CPR certification'
                          }
                        </Text>
                      </View>
                    </Touchable>
                  </View>
                )}
              </>
            </ImageBackground>
          </View>
          {/* View Button Container */}
          <>
            {!Constants['gCprImage'] ? null : (
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    marginBottom: 5,
                    marginTop: 5,
                    width: '100%',
                  },
                  dimensions.width
                )}
              >
                {/* Reupload Image */}
                <Button
                  iconPosition={'left'}
                  onPress={() => {
                    const handler = async () => {
                      try {
                        const newCprImage = await openImagePickerUtil({
                          mediaTypes: 'All',
                          allowsEditing: false,
                          quality: 0.2,
                          allowsMultipleSelection: false,
                          permissionErrorMessage:
                            'Sorry, we need media library permissions to make this work.',
                          showAlertOnPermissionError: true,
                        });

                        if (!newCprImage) {
                          return;
                        }
                        setGlobalVariableValue({
                          key: 'gCprImage',
                          value: newCprImage,
                        });
                        await uploadCprImage(
                          Variables,
                          setGlobalVariableValue,
                          newCprImage
                        );
                      } catch (err) {
                        console.error(err);
                      }
                    };
                    handler();
                  }}
                  style={StyleSheet.applyWidth(
                    {
                      backgroundColor: palettes.App['Custom Color'],
                      borderBottomWidth: 1,
                      borderColor: palettes.App['App Sun Orange F4A100'],
                      borderLeftWidth: 1,
                      borderRadius: 50,
                      borderRightWidth: 1,
                      borderTopWidth: 1,
                      color: palettes.App['App Sun Orange F4A100'],
                      fontFamily: 'BebasNeue_400Regular',
                      fontSize: 18,
                      paddingBottom: 14,
                      paddingTop: 14,
                      textAlign: 'center',
                      width: '100%',
                    },
                    dimensions.width
                  )}
                  title={'REUPLOAD IMAGE'}
                />
                {/* View Activity Indicator */}
                <>
                  {!Constants['is_loading'] ? null : (
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          borderRadius: 50,
                          marginTop: -50,
                          opacity: 0.8,
                          paddingBottom: 7,
                          paddingTop: 7,
                          width: '100%',
                        },
                        dimensions.width
                      )}
                    >
                      <ActivityIndicator
                        animating={true}
                        hidesWhenStopped={true}
                        size={'small'}
                        color={palettes.App['App Gold #FFC400']}
                        style={StyleSheet.applyWidth(
                          { height: 36, width: 36 },
                          dimensions.width
                        )}
                      />
                    </View>
                  )}
                </>
              </View>
            )}
          </>
        </View>
        {/* View Bottom CTA Container */}
        <View
          style={StyleSheet.applyWidth(
            {
              alignItems: 'center',
              flexDirection: 'row',
              justifyContent: 'space-between',
              paddingTop: 8,
              width: '100%',
            },
            dimensions.width
          )}
        >
          {/* View Left Container */}
          <View
            style={StyleSheet.applyWidth({ width: '48%' }, dimensions.width)}
          >
            {/* View Step Text Container */}
            <View
              style={StyleSheet.applyWidth(
                { marginBottom: 8 },
                dimensions.width
              )}
            >
              <Text
                accessible={true}
                style={StyleSheet.applyWidth(
                  {
                    color: palettes.App['App Text 2ndary 7F868C'],
                    fontFamily: 'Poppins_400Regular',
                  },
                  dimensions.width
                )}
              >
                {'Step 1 of 4'}
              </Text>
            </View>
            {/* View Progress Bar Container */}
            <View
              style={StyleSheet.applyWidth(
                { borderRadius: 8, flexDirection: 'row', width: '100%' },
                dimensions.width
              )}
            >
              {/* View 1st fill */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    backgroundColor: palettes.App['App Gold #FFC400'],
                    height: 5,
                    width: '28%',
                  },
                  dimensions.width
                )}
              />
              {/* View 2nd fill */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    backgroundColor: palettes.App['App Disabled Button EDEDEF'],
                    height: 5,
                    width: '24%',
                  },
                  dimensions.width
                )}
              />
              {/* View 3rd fill */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    backgroundColor: palettes.App['App Disabled Button EDEDEF'],
                    height: 5,
                    width: '24%',
                  },
                  dimensions.width
                )}
              />
              {/* View 4th fil */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    backgroundColor: palettes.App['App Disabled Button EDEDEF'],
                    height: 5,
                    width: '23%',
                  },
                  dimensions.width
                )}
              />
            </View>
          </View>
          {/* View Right Container */}
          <View
            style={StyleSheet.applyWidth({ width: '48%' }, dimensions.width)}
          >
            {/* View Sign In Button */}
            <View
              style={StyleSheet.applyWidth(
                { alignItems: 'center', width: '100%' },
                dimensions.width
              )}
            >
              {/* Next Disabled */}
              <>
                {Constants['gCprImage'] ? null : (
                  <Button
                    iconPosition={'left'}
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor:
                          palettes.App['App Disabled Button EDEDEF'],
                        borderRadius: 50,
                        color: palettes.App['App Disabled Button Text 7F868C'],
                        fontFamily: 'BebasNeue_400Regular',
                        fontSize: 18,
                        paddingBottom: 14,
                        paddingTop: 14,
                        textAlign: 'center',
                        width: '100%',
                      },
                      dimensions.width
                    )}
                    title={'Next' ?? 'Sign In'}
                  />
                )}
              </>
              {/* Next Enabled */}
              <>
                {!Constants['gCprImage'] ? null : (
                  <Button
                    iconPosition={'left'}
                    onPress={() => {
                      try {
                        navigation.navigate('Onboarding2InstructorBioScreen', {
                          skip: shouldSkipInstructorBio(),
                        });
                        amplitudeEvents(
                          Variables,
                          'upload cpr',
                          {
                            origin: 'upload cpr screen',
                            category: 'onboarding',
                          },
                          undefined
                        );
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor: palettes.App['App Red EB3E26'],
                        borderRadius: 50,
                        fontFamily: 'BebasNeue_400Regular',
                        fontSize: 18,
                        paddingBottom: 14,
                        paddingTop: 14,
                        textAlign: 'center',
                        width: '100%',
                      },
                      dimensions.width
                    )}
                    title={'Next' ?? 'Sign In'}
                  />
                )}
              </>
              {/* View Activity Indicator */}
              <>
                {!Constants['is_loading'] ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        backgroundColor: palettes.App['App Red EB3E26'],
                        borderRadius: 50,
                        marginTop: -50,
                        opacity: 0.8,
                        paddingBottom: 7,
                        paddingTop: 7,
                        width: '100%',
                      },
                      dimensions.width
                    )}
                  >
                    <ActivityIndicator
                      animating={true}
                      hidesWhenStopped={true}
                      size={'small'}
                      color={palettes.App['Custom Color']}
                      style={StyleSheet.applyWidth(
                        { height: 36, width: 36 },
                        dimensions.width
                      )}
                    />
                  </View>
                )}
              </>
            </View>
          </View>
        </View>
        <Spacer bottom={34} left={0} right={0} top={0} />
      </View>
    </ScreenContainer>
  );
};

export default withTheme(Onboarding1UploadCPRScreen);
