import moment from 'moment-timezone';

const localTimeZone = moment.tz.guess(true);

export const toLocalMachine = (timestring, inputFormat, finalFormat) => {
  if (!timestring) {
    return '';
  }
  if (!inputFormat) {
    inputFormat = 'MM/DD/YYYY h:mm A';
  }
  if (!finalFormat) {
    finalFormat = 'MM/DD/YYYY h:mm A';
  }

  const sunsationalServerTime = moment.tz(
    timestring,
    inputFormat,
    'America/Los_Angeles'
  );
  const localTime = sunsationalServerTime.clone().tz(localTimeZone);
  const formattedLocalTime = localTime.format(finalFormat);

  return formattedLocalTime;
};

export const fromLocalMachine = ISOTimeString => {
  const time = moment(ISOTimeString);
  const sunsationalServerTime = time.tz('America/Los_Angeles');
  const formattedSunsationalServerTime =
    sunsationalServerTime.format('MM/DD/YYYY h:mmA');

  return formattedSunsationalServerTime;
};

export const toISOformat = (datestring, inputFormat) => {
  const isoString = moment.tz(datestring, inputFormat, localTimeZone);

  return isoString;
};

export const timestringToISO = (
  inputTimestring,
  inputFormat,
  inputTimeZone
) => {
  if (!inputTimeZone) {
    inputTimeZone = localTimeZone;
  }

  if (!inputFormat) {
    inputFormat = 'MM/DD/YYYY h:mma';
  }

  const inputMoment = moment.tz(inputTimestring, inputFormat, inputTimeZone);
  const isoFormat = inputMoment.toISOString();

  return isoFormat;
};

export const format = (timestring, inputFormat, finalFormat) => {
  if (!inputFormat) {
    alert(
      'Error: Need to include an input format. Example MM/DD/YYYY h:mma h:mma'
    );
    return;
  }

  if (!finalFormat) {
    finalFormat = 'MM/DD/YYYY h:mma';
  }

  const inputMoment = moment(timestring, inputFormat);
  const outputTimestring = inputMoment.format(finalFormat);
  return outputTimestring;
};

export { moment };
