import React from 'react';

const getFirstNameLastInitial = fullname => {
  if (!fullname) {
    return '';
  }

  const fullnameCollection = fullname.split(' ');

  let firstName = fullnameCollection[0];
  let lastNameFirstLetter =
    fullnameCollection[fullnameCollection.length - 1].charAt(0);

  return `${firstName} ${lastNameFirstLetter}`;
};

export default getFirstNameLastInitial;
