import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as IntegromatApi from '../apis/IntegromatApi.js';
import * as KnackAPIApi from '../apis/KnackAPIApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import * as Track from '../custom-files/Track';
import * as validateTermsAgreement from '../custom-files/validateTermsAgreement';
import amplitudeEvents from '../global-functions/amplitudeEvents';
import getFirstNameLastInitial from '../global-functions/getFirstNameLastInitial';
import getUniquePackagesFromLessonsList from '../global-functions/getUniquePackagesFromLessonsList';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import openImagePickerUtil from '../utils/openImagePicker';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  Button,
  Icon,
  KeyboardAvoidingView,
  Link,
  Picker,
  Pressable,
  SVG,
  ScreenContainer,
  Spacer,
  Surface,
  Swiper,
  SwiperItem,
  TextInput,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { FlashList } from '@shopify/flash-list';
import * as Linking from 'expo-linking';
import {
  ActivityIndicator,
  FlatList,
  Image,
  ImageBackground,
  Modal,
  Platform,
  Text,
  View,
} from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { Fetch } from 'react-request';

const KnowledgeBaseScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [activeCategoryTag, setActiveCategoryTag] = React.useState('all');
  const [activeTab, setActiveTab] = React.useState('faq');
  const [articles, setArticles] = React.useState([]);
  const [categoryTags, setCategoryTags] = React.useState([]);
  const [disableSendContactMsg, setDisableSendContactMsg] =
    React.useState(true);
  const [is_loading_screen, setIs_loading_screen] = React.useState(false);
  const [is_loading_send_msg, setIs_loading_send_msg] = React.useState(false);
  const [is_loading_submit_question, setIs_loading_submit_question] =
    React.useState(false);
  const [pickerClientsList, setPickerClientsList] = React.useState([]);
  const [questionInputValue, setQuestionInputValue] = React.useState('');
  const [questionSentConfirmationMsg, setQuestionSentConfirmationMsg] =
    React.useState('Question received!');
  const [searchInput, setSearchInput] = React.useState('');
  const [selectedClient, setSelectedClient] = React.useState('');
  const [showAskAnotherQuestion, setShowAskAnotherQuestion] =
    React.useState(false);
  const [showAskQuestionBtn, setShowAskQuestionBtn] = React.useState(true);
  const [showChooseSupportType, setShowChooseSupportType] =
    React.useState(false);
  const [showExpoundQuestionNotice, setShowExpoundQuestionNotice] =
    React.useState(false);
  const [showFooterSpacer, setShowFooterSpacer] = React.useState(true);
  const [showModalContactSuccess, setShowModalContactSuccess] =
    React.useState(false);
  const [showModalContactSupport, setShowModalContactSupport] =
    React.useState(false);
  const [showQuestionInput, setShowQuestionInput] = React.useState(false);
  const [showQuestionSentSuccess, setShowQuestionSentSuccess] =
    React.useState(false);
  const [showQuestionSubmitError, setShowQuestionSubmitError] =
    React.useState(false);
  const [showSiContactMessageInput, setShowSiContactMessageInput] =
    React.useState(false);
  const [showSubmitQuestionBtn, setShowSubmitQuestionBtn] =
    React.useState(false);
  const [siContactMessage, setSiContactMessage] = React.useState('');
  const [supportType, setSupportType] = React.useState('');
  const [uniquePackages, setUniquePackages] = React.useState([]);
  const [textInputValue, setTextInputValue] = React.useState('');
  const searchFilter = articles => {
    if (!searchInput) {
      return articles;
    }

    articles.forEach(article => {
      console.log('article.field_684 === ', article.field_684);
    });

    let filteredArticles = articles.filter(
      article =>
        // match by title
        article.field_675_raw
          ?.toLowerCase()
          .includes(searchInput.toLowerCase()) ||
        // match by tags
        article.field_684?.toLowerCase().includes(searchInput.toLowerCase())
    );

    return filteredArticles;
  };

  const filterArticleByCategorgyTag = articles => {
    if (activeCategoryTag === 'all') {
      return articles;
    }

    let filteredArticles = articles.filter(article => {
      return article.field_684
        ?.toLowerCase()
        .includes(activeCategoryTag.toLowerCase());
    });

    return filteredArticles;
  };

  const resetQuestionInputForm = () => {
    console.log('RESETTING QUESTION INPUT FORM');
    setQuestionInputValue('');
    setShowExpoundQuestionNotice(false);
    setShowQuestionInput(false);
    setShowSubmitQuestionBtn(false);
    setShowAskAnotherQuestion(true);
  };

  const getSelectedPackageKnackId = client => {
    let data = {};
    let clientPackageKnackId;
    const clientPackageId = Number(client.slice(-6));
    console.log('clientPackageId === ', clientPackageId);

    for (var i = 0; i < uniquePackages.length; i++) {
      console.log(
        'uniquePackages[i].packageId === ',
        uniquePackages[i].packageId
      );
      if (Number(uniquePackages[i].packageId) === clientPackageId) {
        console.log('packageId match!');
        clientPackageKnackId = uniquePackages[i].packageKnackId;
        break;
      }
    }

    data = {
      clientPackageId,
      clientPackageKnackId,
    };

    console.log('data === ', data);
    return data;
  };

  const shouldShowSubmitQuestionButton = () => {
    if (questionInputValue.length < 3) {
      return;
    }

    let shouldEnable = questionInputValue.length > 16;
    console.log('questionInputValue.length === ', questionInputValue.length);

    setShowSubmitQuestionBtn(shouldEnable);
    setShowExpoundQuestionNotice(!shouldEnable);
  };

  const getFeaturedArticles = articles => {
    return articles.filter(article => article.field_2519_raw === true);
  };

  const adjustKeyboardAwareViewAndroid = () => {
    // Type the code for the body of your function or hook here.
    // Functions can be triggered via Button/Touchable actions.
    // Hooks are run per ReactJS rules.
    /* String line breaks are accomplished with backticks ( example: `line one
line two` ) and will not work with special characters inside of quotes ( example: "line one line two" ) */
  };

  const getUniqueCategoryTags = articles => {
    // Map to get all 'field_684_raw' arrays from records
    let allTags = articles.map(article => article.field_684_raw);

    // Flatten the array and reduce to get unique tags
    let uniqueTags = allTags.reduce((acc, tags) => {
      tags.forEach(tag => {
        tag.toLowerCase();
        if (!acc.includes(tag) && tag != 'instructor') {
          acc.push(tag);
        }
      });
      return acc;
    }, []);

    // Sort array alphabetically
    uniqueTags.sort((a, b) => a.localeCompare(b));

    uniqueTags.unshift('all');

    console.log('uniqueTags === ', uniqueTags);
    setCategoryTags(uniqueTags);
    return uniqueTags;
  };

  const handleSendHelpMsgResponse = response => {
    setIs_loading_send_msg(false);

    if (!response.success) {
      // process failure
    }

    setSiContactMessage('');
    setSupportType('');
    setSelectedClient('');
    setShowModalContactSupport(false);
    setShowModalContactSuccess(true);
  };

  const handleSubmitQuestionResponse = response => {
    setShowQuestionInput(false);
    setShowAskAnotherQuestion(false);
    setIs_loading_submit_question(false);

    // if failure
    if (!response.record.id) {
      setShowQuestionSubmitError(true);
      return;
    }

    setShowQuestionSubmitError(false);
    setShowQuestionSentSuccess(true);
    setQuestionSentConfirmationMsg('Question received!');

    setTimeout(() => {
      setQuestionSentConfirmationMsg('Thank you!');

      setTimeout(() => {
        setQuestionSentConfirmationMsg('Ask more!');
        setTimeout(() => {
          setQuestionSentConfirmationMsg('Ask mooore!');
          setTimeout(() => {
            setQuestionSentConfirmationMsg('Ask mooooore!');
            setTimeout(() => {
              setQuestionSentConfirmationMsg('Ask mooooooore!');
              setTimeout(() => {
                setShowQuestionSentSuccess(false);
                resetQuestionInputForm();
              }, 1500);
            }, 250);
          }, 250);
        }, 250);
      }, 1000);
    }, 750);
  };

  const shouldShowSiContactMessageInput = () => {
    if (supportType !== 'general') {
      setShowSiContactMessageInput(false);
      return;
    }

    setShowSiContactMessageInput(true);
    return;
  };

  const createPickerClientsList = uniquePackages => {
    const clientsList = [];

    uniquePackages.forEach(lessonPackage => {
      let clientName = lessonPackage.client;
      let packageId = lessonPackage.packageId;
      let pickerId = `${clientName} ${packageId}`;

      clientsList.push(pickerId);
    });

    clientsList.push('Other');

    return clientsList;
  };

  const shouldDisableSendMessage = () => {
    return setDisableSendContactMsg(siContactMessage.length < 12);
  };
  React.useEffect(() => {
    Track.onEnterScreen(
      'KnowledgeBaseScreen',
      Variables,
      setGlobalVariableValue,
      props.navigation.navigate,
      props?.route?.params?.skipAuth
    );
    validateTermsAgreement.ValidateTermsAgreement(
      props.navigation.navigate,
      Variables
    );
  }, []);
  const knackAPICreateQuestionPOST = KnackAPIApi.useCreateQuestionPOST();
  const integromatSendEmailToMatching$BookingPOST =
    IntegromatApi.useSendEmailToMatching$BookingPOST();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      setGlobalVariableValue({
        key: 'screen',
        value: 'knowledge',
      });
      setIs_loading_screen(true);
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={false}
      hasTopSafeArea={false}
    >
      {/* View Top Nav - Subtitle */}
      <View
        {...GlobalStyles.ViewStyles(theme)['View Top Nav - Subtitle 2'].props}
        style={StyleSheet.applyWidth(
          StyleSheet.compose(
            GlobalStyles.ViewStyles(theme)['View Top Nav - Subtitle 2'].style,
            { backgroundColor: palettes.App['Custom Color_23'] }
          ),
          dimensions.width
        )}
      >
        {/* Spacer Top Nav */}
        <Spacer bottom={0} left={0} right={0} top={44} />
        {/* View Row Top Nav Container */}
        <View
          style={StyleSheet.applyWidth(
            {
              alignContent: 'center',
              alignItems: 'center',
              flexDirection: 'row',
              justifyContent: 'flex-start',
              paddingBottom: 16,
              paddingLeft: '5.35%',
              paddingRight: '5.35%',
            },
            dimensions.width
          )}
        >
          <Pressable
            onPress={() => {
              try {
                navigation.goBack();
              } catch (err) {
                console.error(err);
              }
            }}
          >
            {/* View Back Arrow Container */}
            <View
              style={StyleSheet.applyWidth(
                { height: 24, marginRight: 8, width: 24 },
                dimensions.width
              )}
            >
              <SVG
                source={Constants['uiBackArrow']}
                style={StyleSheet.applyWidth(
                  { height: '100%', width: '100%' },
                  dimensions.width
                )}
              />
            </View>
          </Pressable>
          {/* View Page Title Container */}
          <View
            style={StyleSheet.applyWidth(
              { justifyContent: 'center' },
              dimensions.width
            )}
          >
            {/* Title */}
            <Text
              accessible={true}
              style={StyleSheet.applyWidth(
                {
                  color: palettes.App.DarkSurface,
                  fontFamily: 'Poppins_700Bold',
                  fontSize: 18,
                },
                dimensions.width
              )}
            >
              {'Knowledge Base'}
            </Text>
          </View>
          {/* View Spacer */}
          <View
            style={StyleSheet.applyWidth(
              { height: 10, justifyContent: 'center', width: 10 },
              dimensions.width
            )}
          />
        </View>
      </View>
      {/* Loading screen placeholders */}
      <>
        {!is_loading_screen ? null : (
          <View
            style={StyleSheet.applyWidth(
              { paddingLeft: '5.35%', paddingRight: '5.35%', paddingTop: 20 },
              dimensions.width
            )}
          >
            {/* Loading placeholder featured */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  backgroundColor: palettes.App['Blue/Low Emphasis'],
                  borderRadius: 16,
                  height: 140,
                  justifyContent: 'center',
                  marginBottom: 16,
                },
                dimensions.width
              )}
            >
              <ActivityIndicator
                animating={true}
                hidesWhenStopped={true}
                size={'small'}
                {...GlobalStyles.ActivityIndicatorStyles(theme)[
                  'Activity Indicator'
                ].props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.ActivityIndicatorStyles(theme)[
                    'Activity Indicator'
                  ].style,
                  dimensions.width
                )}
              />
            </View>
            {/* Placeholder author deetz */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  flexDirection: 'row',
                  paddingBottom: 20,
                  paddingTop: 20,
                },
                dimensions.width
              )}
            >
              {/* Placeholder round */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    backgroundColor: palettes.App['App Disabled Button EDEDEF'],
                    borderRadius: 100,
                    height: 80,
                    overflow: 'hidden',
                    width: 80,
                  },
                  dimensions.width
                )}
              />
              {/* Placeholder square */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    backgroundColor: palettes.App['Blue/Low Emphasis'],
                    borderRadius: 8,
                    flex: 1,
                    height: 24,
                    marginLeft: 16,
                    paddingLeft: 16,
                  },
                  dimensions.width
                )}
              />
            </View>
            {/* Placeholder medium */}
            <View
              style={StyleSheet.applyWidth(
                {
                  backgroundColor: palettes.App['Blue/Low Emphasis'],
                  borderRadius: 8,
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  marginBottom: 16,
                  minHeight: 64,
                  paddingBottom: 16,
                  paddingTop: 16,
                },
                dimensions.width
              )}
            />
            {/* Placeholder medium */}
            <View
              style={StyleSheet.applyWidth(
                {
                  backgroundColor: palettes.App['Blue/Low Emphasis'],
                  borderRadius: 8,
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  marginBottom: 16,
                  minHeight: 64,
                  paddingBottom: 16,
                  paddingTop: 16,
                },
                dimensions.width
              )}
            />
            {/* Placeholder medium */}
            <View
              style={StyleSheet.applyWidth(
                {
                  backgroundColor: palettes.App['Blue/Low Emphasis'],
                  borderRadius: 8,
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  marginBottom: 16,
                  minHeight: 64,
                  paddingBottom: 16,
                  paddingTop: 16,
                },
                dimensions.width
              )}
            />
          </View>
        )}
      </>
      {/* KBAw scroll view */}
      <>
        {is_loading_screen ? null : (
          <KeyboardAwareScrollView
            enableAutomaticScroll={false}
            enableOnAndroid={false}
            enableResetScrollToCoords={false}
            keyboardShouldPersistTaps={'never'}
            showsVerticalScrollIndicator={true}
            viewIsInsideTabBar={false}
            contentContainerStyle={StyleSheet.applyWidth(
              { paddingTop: 20 },
              dimensions.width
            )}
          >
            {/* Featured articles */}
            <View
              style={StyleSheet.applyWidth(
                { paddingLeft: '5.35%', paddingRight: '5.35%' },
                dimensions.width
              )}
            >
              <Swiper
                data={getFeaturedArticles(articles)}
                dotColor={theme.colors.text.light}
                dotsTouchable={true}
                keyExtractor={(swiperData, index) =>
                  swiperData?.id ?? swiperData?.uuid ?? index.toString()
                }
                listKey={'2xTadOiH'}
                loop={false}
                minDistanceForAction={0.2}
                minDistanceToCapture={5}
                renderItem={({ item, index }) => {
                  const swiperData = item;
                  return (
                    <SwiperItem>
                      {/* kb featured article 2 */}
                      <View
                        {...GlobalStyles.ViewStyles(theme)[
                          'kb featured article 2'
                        ].props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.ViewStyles(theme)[
                            'kb featured article 2'
                          ].style,
                          dimensions.width
                        )}
                      >
                        <Pressable
                          onPress={() => {
                            try {
                              navigation.navigate(
                                'KnowledgeBaseArticleScreen',
                                { articleID: swiperData?.id }
                              );
                              amplitudeEvents(
                                Variables,
                                'view knowledge base article',
                                {
                                  origin: 'knowledge base',
                                  category: 'support',
                                },
                                undefined
                              );
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                        >
                          {/* Banner */}
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                borderRadius: 20,
                                height: 140,
                                overflow: 'hidden',
                              },
                              dimensions.width
                            )}
                          >
                            {/* Background svg */}
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  borderRadius: 20,
                                  height: 140,
                                  marginBottom: -140,
                                  overflow: 'hidden',
                                },
                                dimensions.width
                              )}
                            >
                              <SVG
                                {...GlobalStyles.SVGStyles(theme)['SVG'].props}
                                source={Constants['uiKbFeaturedArticleBg1']}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.SVGStyles(theme)['SVG'].style,
                                    { height: '100%', width: '100%' }
                                  ),
                                  dimensions.width
                                )}
                              />
                            </View>
                            {/* Content */}
                            <View
                              style={StyleSheet.applyWidth(
                                { height: 140, padding: 10 },
                                dimensions.width
                              )}
                            >
                              <View
                                style={StyleSheet.applyWidth(
                                  { marginBottom: 8 },
                                  dimensions.width
                                )}
                              >
                                {/* Featured article */}
                                <Text
                                  accessible={true}
                                  {...GlobalStyles.TextStyles(theme)['Text']
                                    .props}
                                  style={StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                      GlobalStyles.TextStyles(theme)['Text']
                                        .style,
                                      {
                                        color:
                                          palettes.App['Blue/Low Emphasis'],
                                        fontFamily: 'Poppins_500Medium',
                                      }
                                    ),
                                    dimensions.width
                                  )}
                                >
                                  {'Featured article'}
                                </Text>
                              </View>
                              {/* Shadow color */}
                              <View
                                style={StyleSheet.applyWidth(
                                  { height: 96, marginTop: 2, paddingLeft: 2 },
                                  dimensions.width
                                )}
                              >
                                {/* Title */}
                                <Text
                                  accessible={true}
                                  {...GlobalStyles.TextStyles(theme)['Text']
                                    .props}
                                  numberOfLines={2}
                                  style={StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                      GlobalStyles.TextStyles(theme)['Text']
                                        .style,
                                      {
                                        color: palettes.App.DarkSurface,
                                        fontFamily: 'BebasNeue_400Regular',
                                        fontSize: 32,
                                      }
                                    ),
                                    dimensions.width
                                  )}
                                >
                                  {swiperData?.field_675_raw}
                                </Text>
                              </View>
                              {/* Foreground */}
                              <View
                                style={StyleSheet.applyWidth(
                                  { height: 96, marginTop: -96 },
                                  dimensions.width
                                )}
                              >
                                {/* Title */}
                                <Text
                                  accessible={true}
                                  {...GlobalStyles.TextStyles(theme)['Text']
                                    .props}
                                  numberOfLines={2}
                                  style={StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                      GlobalStyles.TextStyles(theme)['Text']
                                        .style,
                                      {
                                        color:
                                          palettes.App['Blue/Low Emphasis'],
                                        fontFamily: 'BebasNeue_400Regular',
                                        fontSize: 32,
                                      }
                                    ),
                                    dimensions.width
                                  )}
                                >
                                  {swiperData?.field_675_raw}
                                </Text>
                              </View>
                            </View>
                          </View>
                        </Pressable>
                        {/* Author details */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: 'center',
                              flexDirection: 'row',
                              paddingBottom: 20,
                              paddingTop: 20,
                            },
                            dimensions.width
                          )}
                        >
                          <Touchable
                            onPress={() => {
                              const handler = async () => {
                                try {
                                  await openImagePickerUtil({
                                    mediaTypes: 'All',
                                    allowsEditing: false,
                                    quality: 0.2,
                                    allowsMultipleSelection: false,
                                    permissionErrorMessage:
                                      'Sorry, we need media library permissions to make this work.',
                                    showAlertOnPermissionError: true,
                                  });
                                } catch (err) {
                                  console.error(err);
                                }
                              };
                              handler();
                            }}
                          >
                            <View
                              style={StyleSheet.applyWidth(
                                { borderRadius: 100, overflow: 'hidden' },
                                dimensions.width
                              )}
                            >
                              <Image
                                resizeMode={'cover'}
                                source={{ uri: `${swiperData?.field_858_raw}` }}
                                style={StyleSheet.applyWidth(
                                  { height: 80, width: 80 },
                                  dimensions.width
                                )}
                              />
                            </View>
                          </Touchable>

                          <View
                            style={StyleSheet.applyWidth(
                              { flex: 1, paddingLeft: 16 },
                              dimensions.width
                            )}
                          >
                            {/* Contributed by */}
                            <Text
                              accessible={true}
                              style={StyleSheet.applyWidth(
                                {
                                  color: theme.colors.text.strong,
                                  fontFamily: 'Inter_400Regular',
                                  fontSize: 14,
                                },
                                dimensions.width
                              )}
                            >
                              {'Contributed by'}
                            </Text>
                            {/* Name */}
                            <Text
                              accessible={true}
                              style={StyleSheet.applyWidth(
                                {
                                  color: theme.colors.text.strong,
                                  fontFamily: 'Inter_600SemiBold',
                                  fontSize: 18,
                                  marginTop: 5,
                                },
                                dimensions.width
                              )}
                            >
                              {getFirstNameLastInitial(
                                swiperData?.field_18_raw.full
                              )}
                              {'.'}
                            </Text>
                          </View>
                        </View>
                      </View>
                    </SwiperItem>
                  );
                }}
                timeout={0}
                vertical={false}
                {...GlobalStyles.SwiperStyles(theme)['Swiper'].props}
                dotActiveColor={palettes.App['App Blue#4 007fd4']}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.SwiperStyles(theme)['Swiper'].style,
                    { height: 300 }
                  ),
                  dimensions.width
                )}
              />
            </View>
            {/* Tab switches */}
            <View
              {...GlobalStyles.ViewStyles(theme)['tab switches - 4'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.ViewStyles(theme)['tab switches - 4'].style,
                  { marginBottom: 16 }
                ),
                dimensions.width
              )}
            >
              {/* FAQ */}
              <View
                style={StyleSheet.applyWidth({ flex: 1 }, dimensions.width)}
              >
                {/* selected */}
                <>
                  {!(activeTab === 'faq') ? null : (
                    <Touchable>
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            borderBottomWidth: 3,
                            borderColor: palettes.App.DarkSurface,
                            height: 41,
                            justifyContent: 'center',
                          },
                          dimensions.width
                        )}
                      >
                        <Text
                          accessible={true}
                          style={StyleSheet.applyWidth(
                            {
                              color: palettes.App.DarkSurface,
                              fontFamily: 'Poppins_600SemiBold',
                              fontSize: 16,
                            },
                            dimensions.width
                          )}
                        >
                          {'FAQ'}
                        </Text>
                      </View>
                    </Touchable>
                  )}
                </>
                {/* unselected */}
                <>
                  {!(activeTab !== 'faq') ? null : (
                    <Touchable
                      onPress={() => {
                        try {
                          setActiveTab('faq');
                          setSearchInput(Constants['empty']);
                          setSupportType(Constants['empty']);
                          setSelectedClient(Constants['empty']);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            borderBottomWidth: 1,
                            borderColor: palettes.App['Custom Color_20'],
                            height: 41,
                            justifyContent: 'center',
                          },
                          dimensions.width
                        )}
                      >
                        <Text
                          accessible={true}
                          style={StyleSheet.applyWidth(
                            {
                              color: palettes.App['Custom Color_20'],
                              fontFamily: 'Poppins_600SemiBold',
                              fontSize: 16,
                            },
                            dimensions.width
                          )}
                        >
                          {'FAQ'}
                        </Text>
                      </View>
                    </Touchable>
                  )}
                </>
              </View>
              {/* Contact us */}
              <View
                style={StyleSheet.applyWidth({ flex: 1 }, dimensions.width)}
              >
                {/* selected */}
                <>
                  {!(activeTab === 'contact') ? null : (
                    <Touchable>
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            borderBottomWidth: 3,
                            borderColor: palettes.App.DarkSurface,
                            height: 41,
                            justifyContent: 'center',
                          },
                          dimensions.width
                        )}
                      >
                        <Text
                          accessible={true}
                          style={StyleSheet.applyWidth(
                            {
                              color: palettes.App.DarkSurface,
                              fontFamily: 'Poppins_600SemiBold',
                              fontSize: 16,
                            },
                            dimensions.width
                          )}
                        >
                          {'Contact us'}
                        </Text>
                      </View>
                    </Touchable>
                  )}
                </>
                {/* unselected */}
                <>
                  {!(activeTab !== 'contact') ? null : (
                    <Touchable
                      onPress={() => {
                        try {
                          amplitudeEvents(
                            Variables,
                            'view contact us',
                            { origin: 'knowledge base', category: 'support' },
                            undefined
                          );
                          setActiveTab('contact');
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            borderBottomWidth: 1,
                            borderColor: palettes.App['Custom Color_20'],
                            height: 41,
                            justifyContent: 'center',
                          },
                          dimensions.width
                        )}
                      >
                        <Text
                          accessible={true}
                          style={StyleSheet.applyWidth(
                            {
                              color: palettes.App['Custom Color_20'],
                              fontFamily: 'Poppins_600SemiBold',
                              fontSize: 16,
                            },
                            dimensions.width
                          )}
                        >
                          {'Contact us'}
                        </Text>
                      </View>
                    </Touchable>
                  )}
                </>
              </View>
            </View>
            {/* FAQ Content */}
            <>
              {!(activeTab === 'faq') ? null : (
                <View>
                  {/* Category filter */}
                  <View
                    style={StyleSheet.applyWidth(
                      { paddingLeft: '5.35%', paddingRight: '5.35%' },
                      dimensions.width
                    )}
                  >
                    {/* Category list */}
                    <FlatList
                      data={categoryTags}
                      inverted={false}
                      keyExtractor={(categoryListData, index) =>
                        categoryListData?.id ??
                        categoryListData?.uuid ??
                        index.toString()
                      }
                      keyboardShouldPersistTaps={'never'}
                      listKey={'vmX5ZmfM'}
                      nestedScrollEnabled={false}
                      numColumns={1}
                      onEndReachedThreshold={0.5}
                      renderItem={({ item, index }) => {
                        const categoryListData = item;
                        return (
                          <>
                            {/* selected */}
                            <Touchable>
                              <>
                                {!(
                                  categoryListData === activeCategoryTag
                                ) ? null : (
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        alignItems: 'center',
                                        backgroundColor:
                                          palettes.App.DarkSurface,
                                        borderBottomWidth: 1,
                                        borderColor:
                                          palettes.App['Custom Color'],
                                        borderLeftWidth: 1,
                                        borderRadius: 100,
                                        borderRightWidth: 1,
                                        borderTopWidth: 1,
                                        flexDirection: 'row',
                                        height: 38,
                                        justifyContent: 'center',
                                        marginRight: 10,
                                        paddingLeft: 14,
                                        paddingRight: 14,
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    <Text
                                      accessible={true}
                                      style={StyleSheet.applyWidth(
                                        {
                                          color: palettes.App['Custom #ffffff'],
                                          fontFamily: 'Poppins_500Medium',
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      {categoryListData}
                                    </Text>
                                  </View>
                                )}
                              </>
                            </Touchable>
                            {/* unselected */}
                            <>
                              {!(
                                categoryListData !== activeCategoryTag
                              ) ? null : (
                                <Touchable
                                  onPress={() => {
                                    try {
                                      setSearchInput(Constants['empty']);
                                      setActiveCategoryTag(categoryListData);
                                    } catch (err) {
                                      console.error(err);
                                    }
                                  }}
                                >
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        alignItems: 'center',
                                        borderBottomWidth: 1,
                                        borderColor: palettes.App.DarkSurface,
                                        borderLeftWidth: 1,
                                        borderRadius: 100,
                                        borderRightWidth: 1,
                                        borderTopWidth: 1,
                                        flexDirection: 'row',
                                        height: 38,
                                        justifyContent: 'center',
                                        marginRight: 10,
                                        paddingLeft: 14,
                                        paddingRight: 14,
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    <Text
                                      accessible={true}
                                      style={StyleSheet.applyWidth(
                                        {
                                          color: palettes.App.DarkSurface,
                                          fontFamily: 'Poppins_500Medium',
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      {categoryListData}
                                    </Text>
                                  </View>
                                </Touchable>
                              )}
                            </>
                          </>
                        );
                      }}
                      horizontal={true}
                      showsHorizontalScrollIndicator={false}
                      showsVerticalScrollIndicator={false}
                    />
                  </View>
                  {/* search box */}
                  <View
                    {...GlobalStyles.ViewStyles(theme)['search box'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ViewStyles(theme)['search box'].style,
                        { marginLeft: '5.35%', marginRight: '5.35%' }
                      ),
                      dimensions.width
                    )}
                  >
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          backgroundColor: palettes.App['BG Gray'],
                          borderBottomWidth: 1,
                          borderColor: theme.colors.border.brand,
                          borderLeftWidth: 1,
                          borderRadius: 16,
                          borderRightWidth: 1,
                          borderTopWidth: 1,
                          flexDirection: 'row',
                          height: 56,
                          marginBottom: 10,
                          marginTop: 16,
                          paddingBottom: 5,
                          paddingLeft: 20,
                          paddingRight: 20,
                        },
                        dimensions.width
                      )}
                    >
                      <Icon
                        size={24}
                        color={palettes.App['Custom Color_21']}
                        name={'EvilIcons/search'}
                      />
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            flex: 1,
                            justifyContent: 'center',
                            marginLeft: 5,
                            marginRight: 5,
                          },
                          dimensions.width
                        )}
                      >
                        {/* Search Input */}
                        <TextInput
                          autoCapitalize={'none'}
                          autoCorrect={true}
                          changeTextDelay={500}
                          onChangeText={newSearchInputValue => {
                            try {
                              setSearchInput(newSearchInputValue);
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          webShowOutline={true}
                          clearButtonMode={'always'}
                          editable={true}
                          placeholder={'Search'}
                          placeholderTextColor={palettes.App['Custom Color_21']}
                          style={StyleSheet.applyWidth(
                            {
                              borderColor: theme.colors.border.brand,
                              borderRadius: 8,
                              fontFamily: 'Poppins_400Regular',
                              fontSize: 15,
                              paddingBottom: 8,
                              paddingLeft: 8,
                              paddingRight: 8,
                              paddingTop: 8,
                            },
                            dimensions.width
                          )}
                          value={searchInput}
                        />
                      </View>
                    </View>
                  </View>
                  {/* FAQ Articles */}
                  <View
                    style={StyleSheet.applyWidth(
                      { paddingLeft: '5.35%', paddingRight: '5.35%' },
                      dimensions.width
                    )}
                  >
                    {/* Articles */}
                    <FlashList
                      data={searchFilter(filterArticleByCategorgyTag(articles))}
                      estimatedItemSize={50}
                      horizontal={false}
                      inverted={false}
                      keyExtractor={(articlesData, index) =>
                        articlesData?.id ??
                        articlesData?.uuid ??
                        index.toString()
                      }
                      listKey={'WJL5Flf2'}
                      numColumns={1}
                      onEndReachedThreshold={0.5}
                      renderItem={({ item, index }) => {
                        const articlesData = item;
                        return (
                          <>
                            {/* Article item */}
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  borderColor: theme.colors.border.brand,
                                  borderRadius: 20,
                                  borderWidth: 1,
                                  marginBottom: 16,
                                  paddingBottom: 14,
                                  paddingLeft: 20,
                                  paddingRight: 20,
                                  paddingTop: 14,
                                },
                                dimensions.width
                              )}
                            >
                              <Pressable
                                onPress={() => {
                                  try {
                                    navigation.navigate(
                                      'KnowledgeBaseArticleScreen',
                                      { articleID: articlesData?.id }
                                    );
                                    amplitudeEvents(
                                      Variables,
                                      'view knowledge base article',
                                      {
                                        origin: 'knowledge base',
                                        category: 'support',
                                      },
                                      undefined
                                    );
                                  } catch (err) {
                                    console.error(err);
                                  }
                                }}
                              >
                                {/* Title container */}
                                <View
                                  style={StyleSheet.applyWidth(
                                    { paddingBottom: 8, paddingTop: 8 },
                                    dimensions.width
                                  )}
                                >
                                  {/* Title */}
                                  <Text
                                    accessible={true}
                                    {...GlobalStyles.TextStyles(theme)['Text']
                                      .props}
                                    style={StyleSheet.applyWidth(
                                      StyleSheet.compose(
                                        GlobalStyles.TextStyles(theme)['Text']
                                          .style,
                                        {
                                          fontFamily: 'BebasNeue_400Regular',
                                          fontSize: 20,
                                        }
                                      ),
                                      dimensions.width
                                    )}
                                  >
                                    {articlesData?.field_675_raw}
                                  </Text>
                                </View>
                              </Pressable>
                            </View>
                          </>
                        );
                      }}
                      showsHorizontalScrollIndicator={true}
                      showsVerticalScrollIndicator={true}
                    />
                  </View>
                  {/* No search results */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'stretch',
                        justifyContent: 'center',
                        paddingBottom: 16,
                        paddingLeft: '5.25%',
                        paddingRight: '5.25%',
                        paddingTop: 16,
                      },
                      dimensions.width
                    )}
                  >
                    <View>
                      {/* Can't find */}
                      <>
                        {!showAskQuestionBtn ? null : (
                          <Text
                            accessible={true}
                            {...GlobalStyles.TextStyles(theme)['Text'].props}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['Text'].style,
                                {
                                  alignSelf: 'center',
                                  color: palettes.App['App Text 2ndary 7F868C'],
                                  fontFamily: 'Poppins_400Regular',
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {"Can't find what you're looking for?"}
                          </Text>
                        )}
                      </>
                      {/* Submit your question */}
                      <>
                        {!showQuestionInput ? null : (
                          <Text
                            accessible={true}
                            {...GlobalStyles.TextStyles(theme)['Text'].props}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['Text'].style,
                                {
                                  alignSelf: 'center',
                                  color: palettes.App['App Black 191F32'],
                                  fontFamily: 'Poppins_500Medium',
                                  textAlign: 'center',
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {'Submit your question'}
                          </Text>
                        )}
                      </>
                      {/* We'll add */}
                      <>
                        {!showQuestionInput ? null : (
                          <Text
                            accessible={true}
                            {...GlobalStyles.TextStyles(theme)['Text'].props}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['Text'].style,
                                {
                                  alignSelf: 'center',
                                  color: palettes.App['App Text 2ndary 7F868C'],
                                  fontFamily: 'Poppins_400Regular',
                                  textAlign: 'center',
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {"We'll add it and an answer to the Knowledge Base"}
                          </Text>
                        )}
                      </>
                      {/* Ask another */}
                      <>
                        {!showAskAnotherQuestion ? null : (
                          <View
                            style={StyleSheet.applyWidth(
                              { flexDirection: 'row' },
                              dimensions.width
                            )}
                          >
                            {/* Question received */}
                            <Text
                              accessible={true}
                              {...GlobalStyles.TextStyles(theme)['primary14']
                                .props}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TextStyles(theme)['primary14']
                                    .style,
                                  { textAlign: 'center' }
                                ),
                                dimensions.width
                              )}
                            >
                              {
                                "Question received! You'll get response within 24-72 hours. Need faster help? "
                              }
                              {/* Contact us */}
                              <Link
                                accessible={true}
                                onPress={() => {
                                  try {
                                    setActiveTab('contact');
                                  } catch (err) {
                                    console.error(err);
                                  }
                                }}
                                {...GlobalStyles.LinkStyles(theme)['Link']
                                  .props}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.LinkStyles(theme)['Link']
                                      .style,
                                    { fontSize: 14 }
                                  ),
                                  dimensions.width
                                )}
                                title={'Contact us'}
                              />
                            </Text>
                          </View>
                        )}
                      </>
                    </View>
                    {/* Submit a question */}
                    <View
                      style={StyleSheet.applyWidth(
                        { paddingBottom: 10, paddingTop: 10 },
                        dimensions.width
                      )}
                    >
                      {/* Open question input */}
                      <>
                        {!showAskQuestionBtn ? null : (
                          <Button
                            iconPosition={'left'}
                            onPress={() => {
                              try {
                                setShowAskQuestionBtn(false);
                                setShowQuestionInput(true);
                              } catch (err) {
                                console.error(err);
                              }
                            }}
                            {...GlobalStyles.ButtonStyles(theme)['disabled']
                              .props}
                            style={StyleSheet.applyWidth(
                              GlobalStyles.ButtonStyles(theme)['disabled']
                                .style,
                              dimensions.width
                            )}
                            title={'Submit A Question'}
                          />
                        )}
                      </>
                      {/* Ask another */}
                      <>
                        {!showAskAnotherQuestion ? null : (
                          <Button
                            iconPosition={'left'}
                            onPress={() => {
                              try {
                                setShowAskQuestionBtn(false);
                                setShowQuestionInput(true);
                                setShowAskAnotherQuestion(false);
                              } catch (err) {
                                console.error(err);
                              }
                            }}
                            {...GlobalStyles.ButtonStyles(theme)['disabled']
                              .props}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.ButtonStyles(theme)['disabled']
                                  .style,
                                {
                                  backgroundColor:
                                    palettes.App['App Text Green #1DB058'],
                                  color: palettes.App['App Mint #F0F8F2'],
                                }
                              ),
                              dimensions.width
                            )}
                            title={'Submit another question'}
                          />
                        )}
                      </>
                      {/* Question input */}
                      <>
                        {!showQuestionInput ? null : (
                          <View>
                            {/* Question input */}
                            <>
                              {is_loading_submit_question ? null : (
                                <View
                                  style={StyleSheet.applyWidth(
                                    { flex: 1, flexDirection: 'row' },
                                    dimensions.width
                                  )}
                                >
                                  <View
                                    style={StyleSheet.applyWidth(
                                      { flex: 1 },
                                      dimensions.width
                                    )}
                                  >
                                    {/* Question input */}
                                    <>
                                      {is_loading_submit_question ? null : (
                                        <TextInput
                                          autoCapitalize={'none'}
                                          autoCorrect={true}
                                          onBlur={() => {
                                            const textInputValue = undefined;
                                            try {
                                              setShowFooterSpacer(true);
                                              setShowQuestionInput(false);
                                              setShowAskQuestionBtn(true);
                                            } catch (err) {
                                              console.error(err);
                                            }
                                          }}
                                          onChangeText={newQuestionInputValue => {
                                            const textInputValue =
                                              newQuestionInputValue;
                                            try {
                                              setQuestionInputValue(
                                                newQuestionInputValue
                                              );
                                            } catch (err) {
                                              console.error(err);
                                            }
                                          }}
                                          onChangeTextDelayed={newQuestionInputValue => {
                                            const textInputValue =
                                              newQuestionInputValue;
                                            try {
                                              shouldShowSubmitQuestionButton();
                                            } catch (err) {
                                              console.error(err);
                                            }
                                          }}
                                          onFocus={() => {
                                            const textInputValue = undefined;
                                            try {
                                              setShowFooterSpacer(false);
                                            } catch (err) {
                                              console.error(err);
                                            }
                                          }}
                                          webShowOutline={true}
                                          {...GlobalStyles.TextInputStyles(
                                            theme
                                          )['Text Input'].props}
                                          autoFocus={true}
                                          changeTextDelay={750}
                                          placeholder={'Your question'}
                                          placeholderTextColor={
                                            theme.colors.text.strong
                                          }
                                          style={StyleSheet.applyWidth(
                                            StyleSheet.compose(
                                              GlobalStyles.TextInputStyles(
                                                theme
                                              )['Text Input'].style,
                                              {
                                                borderRadius: 50,
                                                fontFamily:
                                                  'Poppins_400Regular',
                                                paddingBottom: 14,
                                                paddingLeft: 14,
                                                paddingRight: 14,
                                                paddingTop: 14,
                                                width: '100%',
                                              }
                                            ),
                                            dimensions.width
                                          )}
                                          value={questionInputValue}
                                        />
                                      )}
                                    </>
                                    <>
                                      {!showExpoundQuestionNotice ? null : (
                                        <View
                                          style={StyleSheet.applyWidth(
                                            {
                                              backgroundColor:
                                                palettes.App[
                                                  'App Black 191F32'
                                                ],
                                              borderRadius: 20,
                                              marginTop: 4,
                                              paddingBottom: 4,
                                              paddingLeft: 8,
                                              paddingRight: 8,
                                              paddingTop: 4,
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          {/* Expound question */}
                                          <Text
                                            accessible={true}
                                            {...GlobalStyles.TextStyles(theme)[
                                              'secondary12'
                                            ].props}
                                            style={StyleSheet.applyWidth(
                                              StyleSheet.compose(
                                                GlobalStyles.TextStyles(theme)[
                                                  'secondary12'
                                                ].style,
                                                {
                                                  color:
                                                    palettes.App
                                                      .Studily_White_Shade_3,
                                                }
                                              ),
                                              dimensions.width
                                            )}
                                          >
                                            {
                                              'Expand on your question to submit'
                                            }
                                          </Text>
                                        </View>
                                      )}
                                    </>
                                  </View>
                                  {/* View 2 */}
                                  <>
                                    {!showSubmitQuestionBtn ? null : (
                                      <View
                                        style={StyleSheet.applyWidth(
                                          { width: '20%' },
                                          dimensions.width
                                        )}
                                      >
                                        {/* Submit question */}
                                        <Button
                                          iconPosition={'left'}
                                          onPress={() => {
                                            const handler = async () => {
                                              try {
                                                setIs_loading_submit_question(
                                                  true
                                                );
                                                const response = (
                                                  await knackAPICreateQuestionPOST.mutateAsync(
                                                    {
                                                      question:
                                                        questionInputValue,
                                                      siEmail:
                                                        Constants['gUserEmail'],
                                                    }
                                                  )
                                                )?.json;
                                                handleSubmitQuestionResponse(
                                                  response
                                                );
                                                amplitudeEvents(
                                                  Variables,
                                                  'submit knowledge base question',
                                                  {
                                                    origin: 'knowledge base',
                                                    category: 'support',
                                                  },
                                                  undefined
                                                );
                                              } catch (err) {
                                                console.error(err);
                                              }
                                            };
                                            handler();
                                          }}
                                          {...GlobalStyles.ButtonStyles(theme)[
                                            'disabled'
                                          ].props}
                                          style={StyleSheet.applyWidth(
                                            StyleSheet.compose(
                                              GlobalStyles.ButtonStyles(theme)[
                                                'disabled'
                                              ].style,
                                              {
                                                backgroundColor:
                                                  palettes.App[
                                                    'App Red EB3E26'
                                                  ],
                                                color:
                                                  palettes.App[
                                                    'Custom #ffffff'
                                                  ],
                                              }
                                            ),
                                            dimensions.width
                                          )}
                                          title={'Submit'}
                                        />
                                      </View>
                                    )}
                                  </>
                                </View>
                              )}
                            </>
                            {/* Question uploading */}
                            <>
                              {!is_loading_submit_question ? null : (
                                <View>
                                  {/* Submitting */}
                                  <Button
                                    iconPosition={'left'}
                                    {...GlobalStyles.ButtonStyles(theme)[
                                      'disabled'
                                    ].props}
                                    disabled={is_loading_submit_question}
                                    loading={is_loading_submit_question}
                                    style={StyleSheet.applyWidth(
                                      StyleSheet.compose(
                                        GlobalStyles.ButtonStyles(theme)[
                                          'disabled'
                                        ].style,
                                        {
                                          backgroundColor:
                                            palettes.App['App Red EB3E26'],
                                          color: palettes.App['Custom #ffffff'],
                                        }
                                      ),
                                      dimensions.width
                                    )}
                                    title={'Submitting . . .'}
                                  />
                                </View>
                              )}
                            </>
                          </View>
                        )}
                      </>
                      {/* Message confirmation sent */}
                      <>
                        {!showQuestionSentSuccess ? null : (
                          <View
                            {...GlobalStyles.ViewStyles(theme)[
                              'Message confirmation sent'
                            ].props}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.ViewStyles(theme)[
                                  'Message confirmation sent'
                                ].style,
                                {
                                  backgroundColor:
                                    palettes.App['App Text Green #1DB058'],
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {/* Left view */}
                            <View
                              style={StyleSheet.applyWidth(
                                { alignItems: 'center', flexDirection: 'row' },
                                dimensions.width
                              )}
                            >
                              {/* Check */}
                              <Icon
                                size={24}
                                color={palettes.App.Peoplebit_White}
                                name={'MaterialCommunityIcons/check-circle'}
                                style={StyleSheet.applyWidth(
                                  { marginRight: 6 },
                                  dimensions.width
                                )}
                              />
                              {/* Question sent */}
                              <Text
                                accessible={true}
                                {...GlobalStyles.TextStyles(theme)['Text']
                                  .props}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.TextStyles(theme)['Text']
                                      .style,
                                    {
                                      color: palettes.App.Peoplebit_White,
                                      fontFamily: 'Poppins_400Regular',
                                    }
                                  ),
                                  dimensions.width
                                )}
                              >
                                {questionSentConfirmationMsg}
                              </Text>
                            </View>
                            {/* Right view */}
                            <View>
                              {/* Sent Icon */}
                              <Icon
                                size={24}
                                color={palettes.App.Peoplebit_White}
                                name={'MaterialCommunityIcons/sort-variant'}
                              />
                            </View>
                          </View>
                        )}
                      </>
                    </View>
                    {/* Question error */}
                    <>
                      {!showQuestionSubmitError ? null : (
                        <View>
                          {/* Uh oh */}
                          <Text
                            accessible={true}
                            {...GlobalStyles.TextStyles(theme)['primary14']
                              .props}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['primary14']
                                  .style,
                                { marginBottom: 10 }
                              ),
                              dimensions.width
                            )}
                          >
                            {'Woops, something went wrong. Please try again.'}
                          </Text>
                          {/* Retry */}
                          <Button
                            iconPosition={'left'}
                            onPress={() => {
                              const handler = async () => {
                                try {
                                  setIs_loading_submit_question(true);
                                  const response = (
                                    await knackAPICreateQuestionPOST.mutateAsync(
                                      {
                                        question: questionInputValue,
                                        siEmail: Constants['gUserEmail'],
                                      }
                                    )
                                  )?.json;
                                  handleSubmitQuestionResponse(response);
                                } catch (err) {
                                  console.error(err);
                                }
                              };
                              handler();
                            }}
                            {...GlobalStyles.ButtonStyles(theme)['disabled']
                              .props}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.ButtonStyles(theme)['disabled']
                                  .style,
                                {
                                  backgroundColor:
                                    palettes.App['App Red EB3E26'],
                                  color: palettes.App['Custom #ffffff'],
                                }
                              ),
                              dimensions.width
                            )}
                            title={'Retry'}
                          />
                        </View>
                      )}
                    </>
                    {/* Input footer */}
                    <View
                      style={StyleSheet.applyWidth(
                        { marginTop: 10 },
                        dimensions.width
                      )}
                    >
                      {/* Help fellow SIs */}
                      <Text
                        accessible={true}
                        {...GlobalStyles.TextStyles(theme)['secondary12'].props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['secondary12'].style,
                            { textAlign: 'center' }
                          ),
                          dimensions.width
                        )}
                      >
                        {
                          'Help fellow instructors by submitting a question. Sunsational may contribute your question and our response into the Knowledge Base.'
                        }
                      </Text>
                    </View>
                  </View>
                </View>
              )}
            </>
            {/* Contact content */}
            <>
              {!(activeTab === 'contact') ? null : (
                <View
                  style={StyleSheet.applyWidth(
                    { paddingLeft: '5.35%', paddingRight: '5.35%' },
                    dimensions.width
                  )}
                >
                  {/* Choose type */}
                  <>
                    {supportType ? null : (
                      <View>
                        {/* General */}
                        <Button
                          iconPosition={'left'}
                          onPress={() => {
                            try {
                              setSupportType('general');
                              amplitudeEvents(
                                Variables,
                                'view general support options',
                                {
                                  origin: 'knowledge base',
                                  category: 'support',
                                },
                                undefined
                              );
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.ButtonStyles(theme)['Button'].style,
                              {
                                backgroundColor: palettes.App['Custom #ffffff'],
                                borderColor: theme.colors.border.brand,
                                borderRadius: 20,
                                borderWidth: 1,
                                color: palettes.App.DarkSurface,
                                fontFamily: 'Poppins_600SemiBold',
                                height: 128,
                                marginBottom: 8,
                              }
                            ),
                            dimensions.width
                          )}
                          title={'I have a general question'}
                        />
                        {/* Client help */}
                        <Button
                          iconPosition={'left'}
                          onPress={() => {
                            try {
                              amplitudeEvents(
                                Variables,
                                'view client support options',
                                {
                                  origin: 'knowledge base',
                                  category: 'support',
                                },
                                undefined
                              );
                              setSupportType('client');
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.ButtonStyles(theme)['Button'].style,
                              {
                                backgroundColor: palettes.App['Custom #ffffff'],
                                borderColor: theme.colors.border.brand,
                                borderRadius: 20,
                                borderWidth: 1,
                                color: palettes.App.DarkSurface,
                                fontFamily: 'Poppins_600SemiBold',
                                height: 128,
                                marginBottom: 8,
                              }
                            ),
                            dimensions.width
                          )}
                          title={'I need help with a specific client'}
                        />
                      </View>
                    )}
                  </>
                  {/* Support options */}
                  <>
                    {!supportType ? null : (
                      <View>
                        {/* Options */}
                        <View>
                          {/* Back */}
                          <Link
                            accessible={true}
                            onPress={() => {
                              try {
                                setSupportType(Constants['empty']);
                              } catch (err) {
                                console.error(err);
                              }
                            }}
                            {...GlobalStyles.LinkStyles(theme)['Link'].props}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.LinkStyles(theme)['Link'].style,
                                { paddingBottom: 8, textAlign: 'center' }
                              ),
                              dimensions.width
                            )}
                            title={'< Back'}
                          />
                          {/* Submit to kb */}
                          <>
                            {!(supportType === 'general') ? null : (
                              <Touchable
                                onPress={() => {
                                  try {
                                    setSearchInput(
                                      'I want to submit a question to the Knowledge Base'
                                    );
                                    setActiveTab('faq');
                                    setSupportType(Constants['empty']);
                                  } catch (err) {
                                    console.error(err);
                                  }
                                }}
                                {...GlobalStyles.TouchableStyles(theme)[
                                  'customer service 2'
                                ].props}
                                style={StyleSheet.applyWidth(
                                  GlobalStyles.TouchableStyles(theme)[
                                    'customer service 2'
                                  ].style,
                                  dimensions.width
                                )}
                              >
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      alignItems: 'flex-start',
                                      backgroundColor:
                                        palettes.App['Custom #ffffff'],
                                      borderBottomWidth: 1,
                                      borderColor: theme.colors.border.brand,
                                      borderLeftWidth: 1,
                                      borderRadius: 20,
                                      borderRightWidth: 1,
                                      borderTopWidth: 1,
                                      flexDirection: 'column',
                                      justifyContent: 'center',
                                      minHeight: 72,
                                      paddingBottom: 16,
                                      paddingLeft: 24,
                                      paddingRight: 8,
                                      paddingTop: 16,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  <View
                                    style={StyleSheet.applyWidth(
                                      { flexDirection: 'row' },
                                      dimensions.width
                                    )}
                                  >
                                    <Icon
                                      color={palettes.App.DarkSurface}
                                      name={'MaterialCommunityIcons/brain'}
                                      size={20}
                                    />
                                    <Text
                                      accessible={true}
                                      style={StyleSheet.applyWidth(
                                        {
                                          color: theme.colors.text.strong,
                                          fontFamily: 'Poppins_600SemiBold',
                                          fontSize: 14,
                                          marginLeft: 12,
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      {'Submit Question to Knowledge Base'}
                                    </Text>
                                  </View>
                                  {/* View 2 */}
                                  <View>
                                    <Text
                                      accessible={true}
                                      {...GlobalStyles.TextStyles(theme)[
                                        'secondary12'
                                      ].props}
                                      style={StyleSheet.applyWidth(
                                        GlobalStyles.TextStyles(theme)[
                                          'secondary12'
                                        ].style,
                                        dimensions.width
                                      )}
                                    >
                                      {
                                        'The answer to your question will be contributed to the Knowledge Base to help other instructors. Response time 24-72 hrs.'
                                      }
                                    </Text>
                                  </View>
                                </View>
                              </Touchable>
                            )}
                          </>
                          {/* Email */}
                          <>
                            {!supportType ? null : (
                              <Touchable
                                onPress={() => {
                                  try {
                                    amplitudeEvents(
                                      Variables,
                                      'open email support',
                                      {
                                        origin: 'knowledge base',
                                        category: 'support',
                                      },
                                      undefined
                                    );
                                    shouldShowSiContactMessageInput();
                                    setShowModalContactSupport(true);
                                  } catch (err) {
                                    console.error(err);
                                  }
                                }}
                                {...GlobalStyles.TouchableStyles(theme)[
                                  'customer service 2'
                                ].props}
                                style={StyleSheet.applyWidth(
                                  GlobalStyles.TouchableStyles(theme)[
                                    'customer service 2'
                                  ].style,
                                  dimensions.width
                                )}
                              >
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      alignItems: 'flex-start',
                                      backgroundColor:
                                        palettes.App['Custom #ffffff'],
                                      borderBottomWidth: 1,
                                      borderColor: theme.colors.border.brand,
                                      borderLeftWidth: 1,
                                      borderRadius: 20,
                                      borderRightWidth: 1,
                                      borderTopWidth: 1,
                                      flexDirection: 'column',
                                      justifyContent: 'center',
                                      minHeight: 72,
                                      paddingLeft: 24,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  <View
                                    style={StyleSheet.applyWidth(
                                      { flexDirection: 'row' },
                                      dimensions.width
                                    )}
                                  >
                                    <Icon
                                      color={palettes.App.DarkSurface}
                                      name={
                                        'MaterialCommunityIcons/email-send-outline'
                                      }
                                      size={20}
                                    />
                                    <Text
                                      accessible={true}
                                      style={StyleSheet.applyWidth(
                                        {
                                          color: theme.colors.text.strong,
                                          fontFamily: 'Poppins_600SemiBold',
                                          fontSize: 14,
                                          marginLeft: 12,
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      {'Email'}
                                    </Text>
                                  </View>
                                  {/* View 2 */}
                                  <View>
                                    <Text
                                      accessible={true}
                                      {...GlobalStyles.TextStyles(theme)[
                                        'secondary12'
                                      ].props}
                                      style={StyleSheet.applyWidth(
                                        GlobalStyles.TextStyles(theme)[
                                          'secondary12'
                                        ].style,
                                        dimensions.width
                                      )}
                                    >
                                      {'Response time 24-48 hours.'}
                                    </Text>
                                  </View>
                                </View>
                              </Touchable>
                            )}
                          </>
                          {/* Sms */}
                          <>
                            {!supportType ? null : (
                              <Touchable
                                onPress={() => {
                                  try {
                                    amplitudeEvents(
                                      Variables,
                                      'open sms support',
                                      {
                                        origin: 'knowledge base',
                                        category: 'support',
                                      },
                                      undefined
                                    );
                                    Linking.openURL('sms:+18583585228');
                                  } catch (err) {
                                    console.error(err);
                                  }
                                }}
                                {...GlobalStyles.TouchableStyles(theme)[
                                  'customer service 2'
                                ].props}
                                style={StyleSheet.applyWidth(
                                  GlobalStyles.TouchableStyles(theme)[
                                    'customer service 2'
                                  ].style,
                                  dimensions.width
                                )}
                              >
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      alignItems: 'flex-start',
                                      backgroundColor:
                                        palettes.App['Custom #ffffff'],
                                      borderBottomWidth: 1,
                                      borderColor: theme.colors.border.brand,
                                      borderLeftWidth: 1,
                                      borderRadius: 20,
                                      borderRightWidth: 1,
                                      borderTopWidth: 1,
                                      flexDirection: 'column',
                                      height: 72,
                                      justifyContent: 'center',
                                      paddingLeft: 24,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  <View
                                    style={StyleSheet.applyWidth(
                                      { flexDirection: 'row' },
                                      dimensions.width
                                    )}
                                  >
                                    <Icon
                                      color={palettes.App.DarkSurface}
                                      name={'MaterialIcons/phone-iphone'}
                                      size={20}
                                    />
                                    <Text
                                      accessible={true}
                                      style={StyleSheet.applyWidth(
                                        {
                                          color: theme.colors.text.strong,
                                          fontFamily: 'Poppins_600SemiBold',
                                          fontSize: 14,
                                          marginLeft: 12,
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      {'Text'}
                                    </Text>
                                  </View>
                                  {/* View 2 */}
                                  <View>
                                    <Text
                                      accessible={true}
                                      {...GlobalStyles.TextStyles(theme)[
                                        'secondary12'
                                      ].props}
                                      style={StyleSheet.applyWidth(
                                        GlobalStyles.TextStyles(theme)[
                                          'secondary12'
                                        ].style,
                                        dimensions.width
                                      )}
                                    >
                                      {'Response time 24-48 hours.'}
                                    </Text>
                                  </View>
                                </View>
                              </Touchable>
                            )}
                          </>
                          {/* Phone */}
                          <>
                            {!supportType ? null : (
                              <Touchable
                                onPress={() => {
                                  try {
                                    amplitudeEvents(
                                      Variables,
                                      'open call support',
                                      {
                                        origin: 'knowledge base',
                                        category: 'support',
                                      },
                                      undefined
                                    );
                                    Linking.openURL('tel:+18887882140');
                                  } catch (err) {
                                    console.error(err);
                                  }
                                }}
                                {...GlobalStyles.TouchableStyles(theme)[
                                  'customer service 2'
                                ].props}
                                style={StyleSheet.applyWidth(
                                  GlobalStyles.TouchableStyles(theme)[
                                    'customer service 2'
                                  ].style,
                                  dimensions.width
                                )}
                              >
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      alignItems: 'flex-start',
                                      backgroundColor:
                                        palettes.App['Custom #ffffff'],
                                      borderBottomWidth: 1,
                                      borderColor: theme.colors.border.brand,
                                      borderLeftWidth: 1,
                                      borderRadius: 20,
                                      borderRightWidth: 1,
                                      borderTopWidth: 1,
                                      flexDirection: 'column',
                                      height: 72,
                                      justifyContent: 'center',
                                      paddingLeft: 24,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  <View
                                    style={StyleSheet.applyWidth(
                                      { flexDirection: 'row' },
                                      dimensions.width
                                    )}
                                  >
                                    <Icon
                                      color={palettes.App.DarkSurface}
                                      name={'MaterialIcons/call'}
                                      size={20}
                                    />
                                    <Text
                                      accessible={true}
                                      style={StyleSheet.applyWidth(
                                        {
                                          color: theme.colors.text.strong,
                                          fontFamily: 'Poppins_600SemiBold',
                                          fontSize: 14,
                                          marginLeft: 12,
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      {'Call'}
                                    </Text>
                                  </View>
                                  {/* View 2 */}
                                  <View>
                                    <Text
                                      accessible={true}
                                      {...GlobalStyles.TextStyles(theme)[
                                        'secondary12'
                                      ].props}
                                      style={StyleSheet.applyWidth(
                                        GlobalStyles.TextStyles(theme)[
                                          'secondary12'
                                        ].style,
                                        dimensions.width
                                      )}
                                    >
                                      {'M-F 8:30am-5pm pst / 11:30am-8pm est'}
                                    </Text>
                                  </View>
                                </View>
                              </Touchable>
                            )}
                          </>
                        </View>
                      </View>
                    )}
                  </>
                </View>
              )}
            </>
          </KeyboardAwareScrollView>
        )}
      </>
      {/* Fetch Articles */}
      <KnackAPIApi.FetchGetArticlesListGET
        handlers={{
          on2xx: fetchArticlesData => {
            try {
              const valuecyVDKfdb = fetchArticlesData?.json?.records;
              setArticles(valuecyVDKfdb);
              const records = valuecyVDKfdb;
              getUniqueCategoryTags(records);
              setIs_loading_screen(false);
            } catch (err) {
              console.error(err);
            }
          },
          on401: fetchArticlesData => {
            try {
              setIs_loading_screen(false);
              navigation.navigate('SessionExpiredScreen');
            } catch (err) {
              console.error(err);
            }
          },
        }}
      >
        {({ loading, error, data, refetchGetArticlesList }) => {
          const fetchArticlesData = data?.json;
          if (loading) {
            return <ActivityIndicator />;
          }

          if (error || data?.status < 200 || data?.status >= 300) {
            return <ActivityIndicator />;
          }

          return null;
        }}
      </KnackAPIApi.FetchGetArticlesListGET>
      {/* KBAv modal contact sss support */}
      <>
        {!showModalContactSupport ? null : (
          <KeyboardAvoidingView
            behavior={'padding'}
            enabled={true}
            keyboardVerticalOffset={0}
            {...GlobalStyles.KeyboardAvoidingViewStyles(theme)[
              'contact sss success'
            ].props}
            style={StyleSheet.applyWidth(
              GlobalStyles.KeyboardAvoidingViewStyles(theme)[
                'contact sss success'
              ].style,
              dimensions.width
            )}
          >
            {/* View Card */}
            <View
              style={StyleSheet.applyWidth(
                {
                  backgroundColor: palettes.App['Custom Color'],
                  borderTopLeftRadius: 16,
                  borderTopRightRadius: 16,
                  bottom: 0,
                  paddingLeft: '5.35%',
                  paddingRight: '5.35%',
                  paddingTop: 13,
                  width: '100%',
                },
                dimensions.width
              )}
            >
              {/* View Modal Close Slide */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    marginBottom: 6,
                  },
                  dimensions.width
                )}
              >
                {/* View Spacer */}
                <View
                  style={StyleSheet.applyWidth({ width: 40 }, dimensions.width)}
                />
                {/* View Slide Close */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      backgroundColor:
                        palettes.App['App Disabled Button EDEDEF'],
                      borderRadius: 40,
                      height: 3,
                      width: 40,
                    },
                    dimensions.width
                  )}
                >
                  <Touchable
                    onPress={() => {
                      try {
                        setShowModalContactSupport(false);
                        setSelectedClient(Constants['empty']);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    style={StyleSheet.applyWidth(
                      { height: '100%', width: '100%' },
                      dimensions.width
                    )}
                  />
                </View>
                {/* View Close Button */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      backgroundColor:
                        palettes.App['App Disabled Button EDEDEF'],
                      borderRadius: 40,
                      paddingBottom: 4,
                      paddingLeft: 6,
                      paddingRight: 6,
                      paddingTop: 4,
                    },
                    dimensions.width
                  )}
                >
                  <Touchable
                    onPress={() => {
                      try {
                        setShowModalContactSupport(false);
                        setSelectedClient(Constants['empty']);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                  >
                    {/* Close */}
                    <Text
                      accessible={true}
                      style={StyleSheet.applyWidth(
                        {
                          color: palettes.App['App Text 2ndary 7F868C'],
                          fontFamily: 'Poppins_400Regular',
                          fontSize: 12,
                        },
                        dimensions.width
                      )}
                    >
                      {'close x'}
                    </Text>
                  </Touchable>
                </View>
              </View>
              {/* View Hide While Request is Uploading */}
              <>
                {Constants['is_loading'] ? null : (
                  <View>
                    {/* Select your client */}
                    <>
                      {!(supportType === 'client') ? null : (
                        <View
                          style={StyleSheet.applyWidth(
                            { paddingBottom: 24 },
                            dimensions.width
                          )}
                        >
                          {/* Title */}
                          <Text
                            accessible={true}
                            style={StyleSheet.applyWidth(
                              {
                                color: palettes.App['App Black 191F32'],
                                fontFamily: 'Poppins_500Medium',
                                fontSize: 16,
                                marginBottom: 8,
                              },
                              dimensions.width
                            )}
                          >
                            {'Select client'}
                          </Text>
                          {/* Fetch lessons */}
                          <KnackAPIApi.FetchGetLessonsListGET
                            handlers={{
                              on2xx: fetchLessonsData => {
                                try {
                                  const uniquePackages =
                                    getUniquePackagesFromLessonsList(
                                      fetchLessonsData?.json?.records
                                    );
                                  const clientsList =
                                    createPickerClientsList(uniquePackages);
                                  setPickerClientsList(clientsList);
                                  setUniquePackages(uniquePackages);
                                } catch (err) {
                                  console.error(err);
                                }
                              },
                            }}
                            rows_per_page={1000}
                          >
                            {({
                              loading,
                              error,
                              data,
                              refetchGetLessonsList,
                            }) => {
                              const fetchLessonsData = data?.json;
                              if (loading) {
                                return <ActivityIndicator />;
                              }

                              if (
                                error ||
                                data?.status < 200 ||
                                data?.status >= 300
                              ) {
                                return <ActivityIndicator />;
                              }

                              return (
                                <>
                                  {/* Pick client */}
                                  <Picker
                                    autoDismissKeyboard={true}
                                    dropDownBackgroundColor={
                                      theme.colors.background.brand
                                    }
                                    dropDownBorderColor={
                                      theme.colors.border.brand
                                    }
                                    dropDownBorderRadius={8}
                                    dropDownBorderWidth={1}
                                    dropDownTextColor={theme.colors.text.strong}
                                    iconSize={24}
                                    leftIconMode={'inset'}
                                    mode={'native'}
                                    onValueChange={newPickClientValue => {
                                      try {
                                        setSelectedClient(newPickClientValue);
                                        setShowSiContactMessageInput(true);
                                      } catch (err) {
                                        console.error(err);
                                      }
                                    }}
                                    selectedIconColor={theme.colors.text.strong}
                                    selectedIconName={'Feather/check'}
                                    selectedIconSize={20}
                                    type={'solid'}
                                    options={pickerClientsList}
                                    placeholder={'Select a client'}
                                    style={StyleSheet.applyWidth(
                                      { fontFamily: 'Poppins_400Regular' },
                                      dimensions.width
                                    )}
                                    value={selectedClient}
                                  />
                                </>
                              );
                            }}
                          </KnackAPIApi.FetchGetLessonsListGET>
                        </View>
                      )}
                    </>
                    {/* Show message input */}
                    <>
                      {!showSiContactMessageInput ? null : (
                        <View
                          style={StyleSheet.applyWidth(
                            { paddingBottom: 40 },
                            dimensions.width
                          )}
                        >
                          {/* How can we help  */}
                          <View
                            style={StyleSheet.applyWidth(
                              { paddingBottom: 8 },
                              dimensions.width
                            )}
                          >
                            {/* Title */}
                            <Text
                              accessible={true}
                              style={StyleSheet.applyWidth(
                                {
                                  color: palettes.App['App Black 191F32'],
                                  fontFamily: 'Poppins_500Medium',
                                  fontSize: 16,
                                },
                                dimensions.width
                              )}
                            >
                              {'How can we help?'}
                            </Text>
                          </View>
                          {/* View Text Area Container */}
                          <View
                            style={StyleSheet.applyWidth(
                              { height: 145, paddingBottom: 16 },
                              dimensions.width
                            )}
                          >
                            {/* siContactMessage */}
                            <TextInput
                              autoCorrect={true}
                              changeTextDelay={500}
                              multiline={true}
                              numberOfLines={4}
                              onChangeText={newSiContactMessageValue => {
                                try {
                                  setSiContactMessage(newSiContactMessageValue);
                                  shouldDisableSendMessage();
                                } catch (err) {
                                  console.error(err);
                                }
                              }}
                              textAlignVertical={'top'}
                              webShowOutline={true}
                              disabled={is_loading_send_msg}
                              editable={true}
                              placeholder={'Your message'}
                              style={StyleSheet.applyWidth(
                                {
                                  borderBottomWidth: 1,
                                  borderColor:
                                    palettes.App['App Grey Inputs E2E2E2'],
                                  borderLeftWidth: 1,
                                  borderRadius: 8,
                                  borderRightWidth: 1,
                                  borderTopWidth: 1,
                                  color: palettes.App['App Black 191F32'],
                                  fontFamily: 'Poppins_400Regular',
                                  height: '100%',
                                  paddingBottom: 16,
                                  paddingLeft: 16,
                                  paddingRight: 16,
                                  paddingTop: 16,
                                  width: '100%',
                                },
                                dimensions.width
                              )}
                            />
                          </View>
                        </View>
                      )}
                    </>
                    {/* Contact message placeholder */}
                    <>
                      {showSiContactMessageInput ? null : (
                        <View>
                          <Spacer left={8} right={8} bottom={88} top={90} />
                        </View>
                      )}
                    </>
                  </View>
                )}
              </>
              {/* View Request Button */}
              <View
                style={StyleSheet.applyWidth(
                  { alignItems: 'center', marginBottom: 32, width: '100%' },
                  dimensions.width
                )}
              >
                {/* Send Message */}
                <Button
                  iconPosition={'left'}
                  onPress={() => {
                    const handler = async () => {
                      try {
                        setIs_loading_send_msg(true);
                        amplitudeEvents(
                          Variables,
                          'submit support message',
                          { origin: 'knowledge base', category: 'support' },
                          undefined
                        );
                        const packageIDdata =
                          getSelectedPackageKnackId(selectedClient);
                        const response = (
                          await integromatSendEmailToMatching$BookingPOST.mutateAsync(
                            {
                              lessonID: '607562-1',
                              message: siContactMessage,
                              packageID: packageIDdata?.clientPackageId,
                              packageKnackID:
                                packageIDdata?.clientPackageKnackId,
                              siEmailAddress:
                                Constants['gInstructorEmailAddress'],
                              siName: Constants['userFullName'],
                            }
                          )
                        )?.json;
                        handleSendHelpMsgResponse(response);
                      } catch (err) {
                        console.error(err);
                      }
                    };
                    handler();
                  }}
                  disabled={disableSendContactMsg}
                  loading={is_loading_send_msg}
                  style={StyleSheet.applyWidth(
                    {
                      backgroundColor: palettes.App.DarkSurface,
                      borderRadius: 50,
                      fontFamily: 'BebasNeue_400Regular',
                      fontSize: 18,
                      paddingBottom: 14,
                      paddingTop: 14,
                      textAlign: 'center',
                      width: '100%',
                    },
                    dimensions.width
                  )}
                  title={'Send Message'}
                />
                {/* View Activity Indicator */}
                <>
                  {!Constants['is_loading'] ? null : (
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          backgroundColor: palettes.App['App Red EB3E26'],
                          borderRadius: 50,
                          marginTop: -50,
                          opacity: 0.8,
                          paddingBottom: 7,
                          paddingTop: 7,
                          width: '100%',
                        },
                        dimensions.width
                      )}
                    >
                      <ActivityIndicator
                        animating={true}
                        hidesWhenStopped={true}
                        size={'small'}
                        color={palettes.App['Custom Color']}
                        style={StyleSheet.applyWidth(
                          { height: 36, width: 36 },
                          dimensions.width
                        )}
                      />
                    </View>
                  )}
                </>
              </View>
              {/* Spacer Move Modal Up While Sending Request */}
              <>
                {!Constants['is_loading'] ? null : (
                  <Spacer left={8} right={8} bottom={135} top={135} />
                )}
              </>
            </View>
            {/* Footer spacer for Android */}
            <>
              {!(Platform.OS === 'android') ? null : (
                <View>
                  <>
                    {!(Platform.OS === 'android') ? null : (
                      <Spacer left={8} right={8} bottom={55} top={55} />
                    )}
                  </>
                </View>
              )}
            </>
          </KeyboardAvoidingView>
        )}
      </>
      {/* modal contact sent success */}
      <>
        {!showModalContactSuccess ? null : (
          <KeyboardAvoidingView
            behavior={'padding'}
            enabled={true}
            keyboardVerticalOffset={0}
            {...GlobalStyles.KeyboardAvoidingViewStyles(theme)[
              'contact sss success'
            ].props}
            style={StyleSheet.applyWidth(
              GlobalStyles.KeyboardAvoidingViewStyles(theme)[
                'contact sss success'
              ].style,
              dimensions.width
            )}
          >
            {/* View Manage Lesson Card */}
            <View
              style={StyleSheet.applyWidth(
                {
                  backgroundColor: palettes.App['Custom Color'],
                  borderTopLeftRadius: 16,
                  borderTopRightRadius: 16,
                  bottom: 0,
                  paddingLeft: '5.35%',
                  paddingRight: '5.35%',
                  paddingTop: 13,
                  width: '100%',
                },
                dimensions.width
              )}
            >
              {/* View Modal Close Slide */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    marginBottom: 6,
                  },
                  dimensions.width
                )}
              >
                {/* View Spacer */}
                <View
                  style={StyleSheet.applyWidth({ width: 40 }, dimensions.width)}
                />
                {/* View Slide Close */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      backgroundColor:
                        palettes.App['App Disabled Button EDEDEF'],
                      borderRadius: 40,
                      height: 3,
                      width: 40,
                    },
                    dimensions.width
                  )}
                >
                  <Touchable
                    onPress={() => {
                      try {
                        setShowModalContactSuccess(false);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    style={StyleSheet.applyWidth(
                      { height: '100%', width: '100%' },
                      dimensions.width
                    )}
                  />
                </View>
                {/* View Close Button */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      backgroundColor:
                        palettes.App['App Disabled Button EDEDEF'],
                      borderRadius: 40,
                      paddingBottom: 4,
                      paddingLeft: 6,
                      paddingRight: 6,
                      paddingTop: 4,
                    },
                    dimensions.width
                  )}
                >
                  <Touchable
                    onPress={() => {
                      try {
                        setShowModalContactSuccess(false);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                  >
                    {/* Close */}
                    <Text
                      accessible={true}
                      style={StyleSheet.applyWidth(
                        {
                          color: palettes.App['App Text 2ndary 7F868C'],
                          fontFamily: 'Poppins_400Regular',
                          fontSize: 12,
                        },
                        dimensions.width
                      )}
                    >
                      {'close x'}
                    </Text>
                  </Touchable>
                </View>
              </View>
              {/* View Hide While Request is Uploading */}
              <>
                {Constants['is_loading'] ? null : (
                  <View>
                    {/* View Modal Header */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          flexDirection: 'row',
                          paddingBottom: 24,
                        },
                        dimensions.width
                      )}
                    >
                      <Icon
                        color={palettes.App['App Text Green #1DB058']}
                        name={'MaterialCommunityIcons/email-check'}
                        size={20}
                        style={StyleSheet.applyWidth(
                          { marginRight: 8 },
                          dimensions.width
                        )}
                      />
                      {/* Modal Title */}
                      <Text
                        accessible={true}
                        style={StyleSheet.applyWidth(
                          {
                            color: palettes.App['App Black 191F32'],
                            fontFamily: 'Poppins_500Medium',
                            fontSize: 16,
                          },
                          dimensions.width
                        )}
                      >
                        {'Message received!'}
                      </Text>
                      <Icon
                        color={palettes.App['App Black 191F32']}
                        name={'MaterialCommunityIcons/sort-variant'}
                        size={20}
                        style={StyleSheet.applyWidth(
                          { marginLeft: 4, marginRight: 8 },
                          dimensions.width
                        )}
                      />
                    </View>
                    {/* Message body container */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          borderColor: palettes.App['App Grey Inputs E2E2E2'],
                          flexDirection: 'column',
                          flexWrap: 'nowrap',
                          paddingBottom: 16,
                          width: '100%',
                        },
                        dimensions.width
                      )}
                    >
                      {/* Reply fast */}
                      <Text
                        accessible={true}
                        style={StyleSheet.applyWidth(
                          {
                            color:
                              palettes.App['App Disabled Button Text 7F868C'],
                            fontFamily: 'Poppins_400Regular',
                            paddingBottom: 6,
                          },
                          dimensions.width
                        )}
                      >
                        {
                          'Glad to hear from you! We work fast. Generally, you should receive a reply within 1 business day. '
                        }
                      </Text>
                      {/* Call us */}
                      <Text
                        accessible={true}
                        style={StyleSheet.applyWidth(
                          {
                            color:
                              palettes.App['App Disabled Button Text 7F868C'],
                            fontFamily: 'Poppins_400Regular',
                          },
                          dimensions.width
                        )}
                      >
                        {
                          'If you need a immediate assistance, give us a call M-F 8:30AM - 5PM PST at 1-888-788-2140.'
                        }
                      </Text>
                    </View>
                  </View>
                )}
              </>
              {/* View Close Button */}
              <View
                style={StyleSheet.applyWidth(
                  { alignItems: 'center', paddingTop: 32, width: '100%' },
                  dimensions.width
                )}
              >
                {/* Close Enabled */}
                <Button
                  iconPosition={'left'}
                  onPress={() => {
                    try {
                      setShowModalContactSuccess(false);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  style={StyleSheet.applyWidth(
                    {
                      backgroundColor: palettes.App['Custom Color'],
                      borderBottomWidth: 1,
                      borderColor: palettes.App['App Sun Orange F4A100'],
                      borderLeftWidth: 1,
                      borderRadius: 50,
                      borderRightWidth: 1,
                      borderTopWidth: 1,
                      color: palettes.App['App Sun Orange F4A100'],
                      fontFamily: 'BebasNeue_400Regular',
                      fontSize: 18,
                      paddingBottom: 14,
                      paddingTop: 14,
                      textAlign: 'center',
                      width: '100%',
                    },
                    dimensions.width
                  )}
                  title={'Close'}
                />
              </View>
              <Spacer bottom={34} left={0} right={0} top={0} />
            </View>
          </KeyboardAvoidingView>
        )}
      </>
      {/* Spacer from bottom nav */}
      <View>
        <Spacer left={8} right={8} bottom={50} top={50} />
      </View>
      {/* Footer Navigation 2023-09-11 */}
      <View
        {...GlobalStyles.ViewStyles(theme)['Footer Navigation 2023-09-14']
          .props}
        style={StyleSheet.applyWidth(
          GlobalStyles.ViewStyles(theme)['Footer Navigation 2023-09-14'].style,
          dimensions.width
        )}
      >
        <Surface
          {...GlobalStyles.SurfaceStyles(theme)['Surface'].props}
          elevation={3}
          style={StyleSheet.applyWidth(
            GlobalStyles.SurfaceStyles(theme)['Surface'].style,
            dimensions.width
          )}
        >
          {/* View Navigation Icons Container */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                alignSelf: 'auto',
                flex: 0,
                flexDirection: 'row',
                justifyContent: 'space-around',
                marginBottom: 12,
                marginTop: 12,
              },
              dimensions.width
            )}
          >
            {/* View Home Container */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  flex: 0,
                  justifyContent: 'space-between',
                  width: '20%',
                },
                dimensions.width
              )}
            >
              <Touchable
                onPress={() => {
                  try {
                    amplitudeEvents(
                      Variables,
                      'view homescreen',
                      { origin: 'bottom navigation', category: 'general' },
                      undefined
                    );
                    setGlobalVariableValue({
                      key: 'screen',
                      value: 'home',
                    });
                    navigation.navigate('HomeScreen');
                  } catch (err) {
                    console.error(err);
                  }
                }}
                style={StyleSheet.applyWidth(
                  { width: '100%' },
                  dimensions.width
                )}
              >
                {/* View Touchable Home */}
                <View
                  style={StyleSheet.applyWidth(
                    { alignItems: 'center', justifyContent: 'space-between' },
                    dimensions.width
                  )}
                >
                  {/* View Home Icon */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        height: 24,
                        justifyContent: 'center',
                        overflow: 'hidden',
                        width: 24,
                      },
                      dimensions.width
                    )}
                  >
                    {/* Home Icon Inactive */}
                    <>
                      {!(Constants['screen'] !== 'home') ? null : (
                        <ImageBackground
                          resizeMode={'cover'}
                          source={Images.IconHomeGrey}
                          style={StyleSheet.applyWidth(
                            { height: '100%', width: '100%' },
                            dimensions.width
                          )}
                        />
                      )}
                    </>
                    {/* Home Icon Active */}
                    <>
                      {!(Constants['screen'] === 'home') ? null : (
                        <ImageBackground
                          resizeMode={'cover'}
                          source={Images.IconHomeBlue}
                          style={StyleSheet.applyWidth(
                            { height: '100%', width: '100%' },
                            dimensions.width
                          )}
                        />
                      )}
                    </>
                  </View>
                  {/* Label Inactive */}
                  <>
                    {!(Constants['screen'] !== 'home') ? null : (
                      <Text
                        accessible={true}
                        style={StyleSheet.applyWidth(
                          {
                            alignSelf: 'center',
                            color: palettes.App['App Text 2ndary 7F868C'],
                            fontFamily: 'Poppins_400Regular',
                            fontSize: 11,
                          },
                          dimensions.width
                        )}
                      >
                        {'Home'}
                      </Text>
                    )}
                  </>
                  {/* Label Active */}
                  <>
                    {!(Constants['screen'] === 'home') ? null : (
                      <Text
                        accessible={true}
                        style={StyleSheet.applyWidth(
                          {
                            alignSelf: 'center',
                            color: palettes.App['App Black 191F32'],
                            fontFamily: 'Poppins_400Regular',
                            fontSize: 11,
                          },
                          dimensions.width
                        )}
                      >
                        {'Home'}
                      </Text>
                    )}
                  </>
                </View>
              </Touchable>
            </View>
            {/* View Jobs Container */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  flex: 0,
                  justifyContent: 'space-between',
                  width: '20%',
                },
                dimensions.width
              )}
            >
              <Touchable
                onPress={() => {
                  try {
                    setGlobalVariableValue({
                      key: 'screen',
                      value: 'jobs',
                    });
                    navigation.navigate('JobsScreen');
                    amplitudeEvents(
                      Variables,
                      'view open jobs list',
                      {
                        origin: 'bottom navigation',
                        category: 'opportunity management',
                      },
                      undefined
                    );
                  } catch (err) {
                    console.error(err);
                  }
                }}
                style={StyleSheet.applyWidth(
                  { width: '100%' },
                  dimensions.width
                )}
              >
                {/* View Touchable Jobs */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      paddingBottom: 0,
                      paddingLeft: 0,
                      paddingRight: 0,
                      paddingTop: 0,
                      width: '100%',
                    },
                    dimensions.width
                  )}
                >
                  {/* View Jobs Icon */}
                  <View
                    style={StyleSheet.applyWidth(
                      { height: 24, overflow: 'hidden', width: 24 },
                      dimensions.width
                    )}
                  >
                    {/* Jobs Icon Inactive */}
                    <>
                      {!(Constants['screen'] !== 'jobs') ? null : (
                        <ImageBackground
                          resizeMode={'cover'}
                          source={{
                            uri: 'https://apps-draftbit-com.s3.amazonaws.com/apps/GZlNa4ih/assets/9VnVvkN5D5yhOBLJhFW7N',
                          }}
                          style={StyleSheet.applyWidth(
                            { height: '100%', width: '100%' },
                            dimensions.width
                          )}
                        />
                      )}
                    </>
                    {/* Jobs Icon Active */}
                    <>
                      {!(Constants['screen'] === 'jobs') ? null : (
                        <ImageBackground
                          resizeMode={'cover'}
                          source={Images.IconSearchBlue}
                          style={StyleSheet.applyWidth(
                            { height: '100%', width: '100%' },
                            dimensions.width
                          )}
                        />
                      )}
                    </>
                  </View>
                  {/* Label Inactive */}
                  <>
                    {!(Constants['screen'] !== 'jobs') ? null : (
                      <Text
                        accessible={true}
                        style={StyleSheet.applyWidth(
                          {
                            alignSelf: 'center',
                            color: palettes.App['App Text 2ndary 7F868C'],
                            fontFamily: 'Poppins_400Regular',
                            fontSize: 11,
                          },
                          dimensions.width
                        )}
                      >
                        {'Jobs'}
                      </Text>
                    )}
                  </>
                  {/* Label Active */}
                  <>
                    {!(Constants['screen'] === 'jobs') ? null : (
                      <Text
                        accessible={true}
                        style={StyleSheet.applyWidth(
                          {
                            alignSelf: 'center',
                            color: palettes.App['App Black 191F32'],
                            fontFamily: 'Poppins_400Regular',
                            fontSize: 11,
                          },
                          dimensions.width
                        )}
                      >
                        {'Jobs'}
                      </Text>
                    )}
                  </>
                </View>
              </Touchable>
            </View>
            {/* View Clients Container */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  flex: 0,
                  justifyContent: 'space-between',
                  width: '20%',
                },
                dimensions.width
              )}
            >
              <Touchable
                onPress={() => {
                  try {
                    amplitudeEvents(
                      Variables,
                      'view client list',
                      {
                        origin: 'bottom navigation',
                        category: 'client management',
                      },
                      undefined
                    );
                    setGlobalVariableValue({
                      key: 'screen',
                      value: 'clients',
                    });
                    navigation.navigate('Clients2Screen');
                  } catch (err) {
                    console.error(err);
                  }
                }}
                style={StyleSheet.applyWidth(
                  { width: '100%' },
                  dimensions.width
                )}
              >
                {/* View Touchable Clients */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      paddingBottom: 0,
                      paddingLeft: 0,
                      paddingRight: 0,
                      paddingTop: 0,
                      width: '100%',
                    },
                    dimensions.width
                  )}
                >
                  {/* View Clients Icon */}
                  <View
                    style={StyleSheet.applyWidth(
                      { height: 24, overflow: 'hidden', width: 24 },
                      dimensions.width
                    )}
                  >
                    {/* Clients Icon Active */}
                    <>
                      {!(Constants['screen'] !== 'clients') ? null : (
                        <ImageBackground
                          resizeMode={'cover'}
                          source={Images.IconBriefcaseGrey}
                          style={StyleSheet.applyWidth(
                            { height: '100%', width: '100%' },
                            dimensions.width
                          )}
                        />
                      )}
                    </>
                    {/* Clients Icon Active */}
                    <>
                      {!(Constants['screen'] === 'clients') ? null : (
                        <ImageBackground
                          resizeMode={'cover'}
                          source={Images.IconBriefcaseBlue}
                          style={StyleSheet.applyWidth(
                            { height: '100%', width: '100%' },
                            dimensions.width
                          )}
                        />
                      )}
                    </>
                  </View>
                  {/* Label Inactive */}
                  <>
                    {!(Constants['screen'] !== 'clients') ? null : (
                      <Text
                        accessible={true}
                        style={StyleSheet.applyWidth(
                          {
                            alignSelf: 'center',
                            color: palettes.App['App Text 2ndary 7F868C'],
                            fontFamily: 'Poppins_400Regular',
                            fontSize: 11,
                          },
                          dimensions.width
                        )}
                      >
                        {'Clients'}
                      </Text>
                    )}
                  </>
                  {/* Label Active */}
                  <>
                    {!(Constants['screen'] === 'clients') ? null : (
                      <Text
                        accessible={true}
                        style={StyleSheet.applyWidth(
                          {
                            alignSelf: 'center',
                            color: palettes.App['App Black 191F32'],
                            fontFamily: 'Poppins_400Regular',
                            fontSize: 11,
                          },
                          dimensions.width
                        )}
                      >
                        {'Clients'}
                      </Text>
                    )}
                  </>
                </View>
              </Touchable>
            </View>
            {/* View Profile Container */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  alignSelf: 'center',
                  flex: 0,
                  justifyContent: 'space-between',
                  width: '20%',
                },
                dimensions.width
              )}
            >
              <Touchable
                onPress={() => {
                  try {
                    setGlobalVariableValue({
                      key: 'screen',
                      value: 'profile',
                    });
                    navigation.navigate('MyAccountScreen');
                    amplitudeEvents(
                      Variables,
                      'view my account',
                      {
                        origin: 'bottom navigation',
                        category: 'account management',
                      },
                      undefined
                    );
                  } catch (err) {
                    console.error(err);
                  }
                }}
                style={StyleSheet.applyWidth(
                  { width: '100%' },
                  dimensions.width
                )}
              >
                {/* View Touchable Profile */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      paddingBottom: 0,
                      paddingLeft: 0,
                      paddingRight: 0,
                      paddingTop: 0,
                      width: '100%',
                    },
                    dimensions.width
                  )}
                >
                  {/* View Profile Icon */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        height: 24,
                        overflow: 'hidden',
                        width: 24,
                      },
                      dimensions.width
                    )}
                  >
                    {/* Profile Icon Inactive */}
                    <>
                      {!(Constants['screen'] !== 'profile') ? null : (
                        <ImageBackground
                          resizeMode={'cover'}
                          source={Images.IconProfileGrey}
                          style={StyleSheet.applyWidth(
                            { height: '100%', width: '100%' },
                            dimensions.width
                          )}
                        />
                      )}
                    </>
                    {/* Profile Icon Active */}
                    <>
                      {!(Constants['screen'] === 'profile') ? null : (
                        <ImageBackground
                          resizeMode={'cover'}
                          source={Images.IconProfileBlue}
                          style={StyleSheet.applyWidth(
                            { height: '100%', width: '100%' },
                            dimensions.width
                          )}
                        />
                      )}
                    </>
                  </View>
                  {/* Label Inactive */}
                  <>
                    {!(Constants['screen'] !== 'profile') ? null : (
                      <Text
                        accessible={true}
                        style={StyleSheet.applyWidth(
                          {
                            alignSelf: 'center',
                            color: palettes.App['App Text 2ndary 7F868C'],
                            fontFamily: 'Poppins_400Regular',
                            fontSize: 11,
                          },
                          dimensions.width
                        )}
                      >
                        {'Account'}
                      </Text>
                    )}
                  </>
                  {/* Label Active */}
                  <>
                    {!(Constants['screen'] === 'profile') ? null : (
                      <Text
                        accessible={true}
                        style={StyleSheet.applyWidth(
                          {
                            alignSelf: 'center',
                            color: palettes.App['App Black 191F32'],
                            fontFamily: 'Poppins_400Regular',
                            fontSize: 11,
                          },
                          dimensions.width
                        )}
                      >
                        {'Account'}
                      </Text>
                    )}
                  </>
                </View>
              </Touchable>
            </View>
            {/* View Knowledge Container */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  flex: 0,
                  justifyContent: 'space-between',
                  width: '20%',
                },
                dimensions.width
              )}
            >
              <Touchable
                onPress={() => {
                  try {
                    setGlobalVariableValue({
                      key: 'screen',
                      value: 'knowledge',
                    });
                    navigation.navigate('KnowledgeBaseScreen');
                  } catch (err) {
                    console.error(err);
                  }
                }}
                style={StyleSheet.applyWidth(
                  { width: '100%' },
                  dimensions.width
                )}
              >
                {/* View Touchable Profile */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      paddingBottom: 0,
                      paddingLeft: 0,
                      paddingRight: 0,
                      paddingTop: 0,
                      width: '100%',
                    },
                    dimensions.width
                  )}
                >
                  {/* View Profile Icon */}
                  <View
                    style={StyleSheet.applyWidth(
                      { height: 24, overflow: 'hidden', width: 24 },
                      dimensions.width
                    )}
                  >
                    {/* Knowledge Icon Inactive */}
                    <>
                      {!(Constants['screen'] !== 'knowledge') ? null : (
                        <ImageBackground
                          resizeMode={'cover'}
                          source={Images.IconKnowledgeGrey}
                          style={StyleSheet.applyWidth(
                            { height: '100%', width: '100%' },
                            dimensions.width
                          )}
                        />
                      )}
                    </>
                    {/* Knowledge Icon Active */}
                    <>
                      {!(Constants['screen'] === 'knowledge') ? null : (
                        <ImageBackground
                          resizeMode={'cover'}
                          source={Images.IconKnowledgeBlue}
                          style={StyleSheet.applyWidth(
                            { height: '100%', width: '100%' },
                            dimensions.width
                          )}
                        />
                      )}
                    </>
                  </View>
                  {/* Label Inactive */}
                  <>
                    {!(Constants['screen'] !== 'profile') ? null : (
                      <Text
                        accessible={true}
                        style={StyleSheet.applyWidth(
                          {
                            alignSelf: 'center',
                            color: palettes.App['App Text 2ndary 7F868C'],
                            fontFamily: 'Poppins_400Regular',
                            fontSize: 11,
                          },
                          dimensions.width
                        )}
                      >
                        {'Know'}
                      </Text>
                    )}
                  </>
                  {/* Label Active */}
                  <>
                    {!(Constants['screen'] === 'profile') ? null : (
                      <Text
                        accessible={true}
                        style={StyleSheet.applyWidth(
                          {
                            alignSelf: 'center',
                            color: palettes.App['App Black 191F32'],
                            fontFamily: 'Poppins_400Regular',
                            fontSize: 11,
                          },
                          dimensions.width
                        )}
                      >
                        {'Know'}
                      </Text>
                    )}
                  </>
                </View>
              </Touchable>
            </View>
          </View>
          <>
            {!!(Platform.OS === 'android') ? null : (
              <Spacer bottom={34} left={0} right={0} top={0} />
            )}
          </>
        </Surface>
      </View>
    </ScreenContainer>
  );
};

export default withTheme(KnowledgeBaseScreen);
