import * as React from 'react';
import {
  useQuery,
  useMutation,
  useIsFetching,
  useQueryClient,
} from 'react-query';
import useFetch from 'react-fetch-hook';
import { useIsFocused } from '@react-navigation/native';
import { handleResponse, isOkStatus } from '../utils/handleRestApiResponse';
import usePrevious from '../utils/usePrevious';
import {
  encodeQueryParam,
  renderParam,
  renderQueryString,
} from '../utils/encodeQueryParam';
import * as GlobalVariables from '../config/GlobalVariableContext';

const cleanHeaders = headers =>
  Object.fromEntries(Object.entries(headers).filter(kv => kv[1] != null));

export const completeLessonPUT = async (
  Constants,
  {
    completionDate,
    dateSubmittedForPay,
    lessonKnackId,
    payRequestSource,
    payStatus,
    scheduledDate,
    siComment,
    siLessonRating,
    siLessonStatus,
  },
  handlers = {}
) => {
  const url = `https://api.knack.com/v1/pages/scene_1158/views/view_2143/records/${encodeQueryParam(
    lessonKnackId
  )}`;
  const options = {
    body: JSON.stringify({
      field_201: siLessonStatus,
      field_205: completionDate,
      field_534: payStatus,
      field_620: dateSubmittedForPay,
      field_2401: siLessonRating,
      field_2402: siComment,
      field_202: scheduledDate,
      field_2413: payRequestSource,
    }),
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AUTH_KNACK_TOKEN'],
      'Content-Type': 'application/json',
      'X-Knack-Application-Id': Constants['APP_ID'],
    }),
    method: 'PUT',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useCompleteLessonPUT = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args => completeLessonPUT(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('lessons', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('lesson');
        queryClient.invalidateQueries('lessons');
      },
    }
  );
};

export const createQuestionPOST = async (
  Constants,
  { question, siEmail },
  handlers = {}
) => {
  const url = `https://api.knack.com/v1/pages/scene_1202/views/view_2239/records`;
  const options = {
    body: JSON.stringify({ field_675: question, field_2520: siEmail }),
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AUTH_KNACK_TOKEN'],
      'Content-Type': 'application/json',
      'X-Knack-Application-Id': Constants['APP_ID'],
    }),
    method: 'POST',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useCreateQuestionPOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      createQuestionPOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData(
            'knowledge base articles',
            previousValue
          );
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('knowledge base article');
        queryClient.invalidateQueries('knowledge base articles');
      },
    }
  );
};

export const FetchCreateQuestionPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  question,
  siEmail,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useCreateQuestionPOST(
    { question, siEmail },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchCreateQuestion: refetch });
};

export const forfeitLessonPUT = async (
  Constants,
  {
    dateSubmittedForPay,
    forfeitedDate,
    lessonKnackId,
    lessonType,
    payRate,
    payRequestSource,
    payStatus,
    scheduledDate,
    siForfeitComment,
    siLessonStatus,
  },
  handlers = {}
) => {
  const url = `https://api.knack.com/v1/pages/scene_1159/views/view_2145/records/${encodeQueryParam(
    lessonKnackId
  )}`;
  const options = {
    body: JSON.stringify({
      field_201: siLessonStatus,
      field_205: forfeitedDate,
      field_534: payStatus,
      field_620: dateSubmittedForPay,
      field_2402: siForfeitComment,
      field_202: scheduledDate,
      field_168: lessonType,
      field_68: payRate,
      field_2413: payRequestSource,
    }),
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AUTH_KNACK_TOKEN'],
      'Content-Type': 'application/json',
      'X-Knack-Application-Id': Constants['APP_ID'],
    }),
    method: 'PUT',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useForfeitLessonPUT = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args => forfeitLessonPUT(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('lessons', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('lesson');
        queryClient.invalidateQueries('lessons');
      },
    }
  );
};

export const getArticleDetailsGET = async (
  Constants,
  { recordID },
  handlers = {}
) => {
  const url = `https://api.knack.com/v1/pages/scene_1201/views/view_2237/records/${encodeQueryParam(
    recordID
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AUTH_KNACK_TOKEN'],
      'Content-Type': 'application/json',
      'X-Knack-Application-Id': Constants['APP_ID'],
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useGetArticleDetailsGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['knowledge base article', args],
    () => getArticleDetailsGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () =>
        queryClient.invalidateQueries(['knowledge base articles']),
    }
  );
};

export const FetchGetArticleDetailsGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  recordID,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetArticleDetailsGET(
    { recordID },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetArticleDetails: refetch });
};

export const getArticlesListGET = async (Constants, _args, handlers = {}) => {
  const url = `https://api.knack.com/v1/pages/scene_1200/views/view_2234/records`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AUTH_KNACK_TOKEN'],
      'Content-Type': 'application/json',
      'X-Knack-Application-Id': Constants['APP_ID'],
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useGetArticlesListGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['knowledge base articles', args],
    () => getArticlesListGET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchGetArticlesListGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetArticlesListGET(
    {},
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetArticlesList: refetch });
};

export const getOpenOpportunitiesGET = async (
  Constants,
  { filters, rows_per_page, sort_field, sort_order },
  handlers = {}
) => {
  const paramsDict = {};
  if (filters !== undefined) {
    paramsDict['filters'] = renderParam(filters);
  }
  if (sort_field !== undefined) {
    paramsDict['sort_field'] = renderParam(sort_field);
  }
  if (sort_order !== undefined) {
    paramsDict['sort_order'] = renderParam(sort_order);
  }
  if (rows_per_page !== undefined) {
    paramsDict['rows_per_page'] = renderParam(rows_per_page);
  }
  const url = `https://api.knack.com/v1/pages/scene_1125/views/view_2117/records${renderQueryString(
    paramsDict
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AUTH_KNACK_TOKEN'],
      'Content-Type': 'application/json',
      'X-Knack-Application-Id': Constants['APP_ID'],
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useGetOpenOpportunitiesGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['opportunities', args],
    () => getOpenOpportunitiesGET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchGetOpenOpportunitiesGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  filters,
  rows_per_page,
  sort_field,
  sort_order,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetOpenOpportunitiesGET(
    { filters, rows_per_page, sort_field, sort_order },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchGetOpenOpportunities: refetch,
  });
};

export const getOpportunityDetailsGET = async (
  Constants,
  { opportunityID },
  handlers = {}
) => {
  const url = `https://api.knack.com/v1/pages/scene_1138/views/view_2118/records/${encodeQueryParam(
    opportunityID
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AUTH_KNACK_TOKEN'],
      'Content-Type': 'application/json',
      'X-Knack-Application-Id': Constants['APP_ID'],
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useGetOpportunityDetailsGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['opportunity', args],
    () => getOpportunityDetailsGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['opportunities']),
    }
  );
};

export const FetchGetOpportunityDetailsGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  opportunityID,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetOpportunityDetailsGET(
    { opportunityID },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchGetOpportunityDetails: refetch,
  });
};

export const getRequestedOpportunitiesGET = async (
  Constants,
  _args,
  handlers = {}
) => {
  const paramsDict = {};
  paramsDict['sort_field'] = 'field_574';
  paramsDict['sort_order'] = 'desc';
  const url = `https://api.knack.com/v1/pages/scene_1125/views/view_2121/records${renderQueryString(
    paramsDict
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AUTH_KNACK_TOKEN'],
      'Content-Type': 'application/json',
      'X-Knack-Application-Id': Constants['APP_ID'],
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useGetRequestedOpportunitiesGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['opportunities', args],
    () => getRequestedOpportunitiesGET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchGetRequestedOpportunitiesGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetRequestedOpportunitiesGET(
    {},
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchGetRequestedOpportunities: refetch,
  });
};

export const getCurrentClientsGET = async (Constants, _args, handlers = {}) => {
  const url = `https://api.knack.com/v1/pages/scene_1104/views/view_2082/records`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AUTH_KNACK_TOKEN'],
      'Content-Type': 'application/json',
      'X-Knack-Application-Id': Constants['APP_ID'],
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useGetCurrentClientsGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['clients', args],
    () => getCurrentClientsGET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchGetCurrentClientsGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetCurrentClientsGET(
    {},
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetCurrentClients: refetch });
};

export const getLessonDetailsGET = async (
  Constants,
  { lessonKackId },
  handlers = {}
) => {
  const url = `https://api.knack.com/v1/pages/scene_1127/views/view_2107/records/${encodeQueryParam(
    lessonKackId
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AUTH_KNACK_TOKEN'],
      'Content-Type': 'application/json',
      'X-Knack-Application-Id': Constants['APP_ID'],
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useGetLessonDetailsGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['lesson', args],
    () => getLessonDetailsGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['lessons']),
    }
  );
};

export const FetchGetLessonDetailsGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  lessonKackId,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetLessonDetailsGET(
    { lessonKackId },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetLessonDetails: refetch });
};

export const getLessonsListGET = async (
  Constants,
  { filters, rows_per_page, sort_field, sort_order },
  handlers = {}
) => {
  const paramsDict = {};
  if (filters !== undefined) {
    paramsDict['filters'] = renderParam(filters);
  }
  if (sort_field !== undefined) {
    paramsDict['sort_field'] = renderParam(sort_field);
  }
  if (sort_order !== undefined) {
    paramsDict['sort_order'] = renderParam(sort_order);
  }
  if (rows_per_page !== undefined) {
    paramsDict['rows_per_page'] = renderParam(rows_per_page);
  }
  const url = `https://api.knack.com/v1/pages/scene_1127/views/view_2107/records${renderQueryString(
    paramsDict
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AUTH_KNACK_TOKEN'],
      'Content-Type': 'application/json',
      'X-Knack-Application-Id': Constants['APP_ID'],
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useGetLessonsListGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['lessons', args],
    () => getLessonsListGET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchGetLessonsListGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  filters,
  rows_per_page,
  sort_field,
  sort_order,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetLessonsListGET(
    { filters, rows_per_page, sort_field, sort_order },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetLessonsList: refetch });
};

export const getPackageDetailsGET = async (
  Constants,
  { knackId },
  handlers = {}
) => {
  const url = `https://api.knack.com/v1/pages/scene_1133/views/view_2110/records/${encodeQueryParam(
    knackId
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AUTH_KNACK_TOKEN'],
      'Content-Type': 'application/json',
      'X-Knack-Application-Id': Constants['APP_ID'],
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useGetPackageDetailsGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['package detail', args],
    () => getPackageDetailsGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['package details']),
    }
  );
};

export const FetchGetPackageDetailsGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  knackId,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetPackageDetailsGET(
    { knackId },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetPackageDetails: refetch });
};

export const getProfileGET = async (
  Constants,
  { AUTH_USER_KNACK_ID },
  handlers = {}
) => {
  const url = `https://api.knack.com/v1/pages/scene_1144/views/view_2126/records/${encodeQueryParam(
    AUTH_USER_KNACK_ID
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AUTH_KNACK_TOKEN'],
      'Content-Type': 'application/json',
      'X-Knack-Application-Id': Constants['APP_ID'],
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useGetProfileGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['profile', args],
    () => getProfileGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['profiles']),
    }
  );
};

export const FetchGetProfileGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  AUTH_USER_KNACK_ID,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetProfileGET(
    { AUTH_USER_KNACK_ID },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetProfile: refetch });
};

export const loginPOST = async (
  Constants,
  { APP_ID, emailAddress, password },
  handlers = {}
) => {
  const url = `https://api.knack.com/v1/applications/${encodeQueryParam(
    APP_ID
  )}/session`;
  const options = {
    body: JSON.stringify({
      email: emailAddress,
      password: password,
      remember: true,
    }),
    headers: cleanHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }),
    method: 'POST',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useLoginPOST = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['knackAPILoginPOST', args],
    () => loginPOST(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['knackAPILoginPOSTS']),
    }
  );
};

export const FetchLoginPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  APP_ID,
  emailAddress,
  password,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useLoginPOST(
    { APP_ID, emailAddress, password },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchLogin: refetch });
};

export const scheduleLessonPUT = async (
  Constants,
  { date, lessonKnackId },
  handlers = {}
) => {
  const url = `https://api.knack.com/v1/pages/scene_1157/views/view_2142/records/${encodeQueryParam(
    lessonKnackId
  )}`;
  const options = {
    body: JSON.stringify({ field_202: date }),
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AUTH_KNACK_TOKEN'],
      'Content-Type': 'application/json',
      'X-Knack-Application-Id': Constants['APP_ID'],
    }),
    method: 'PUT',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useScheduleLessonPUT = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args => scheduleLessonPUT(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('lessons', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('lesson');
        queryClient.invalidateQueries('lessons');
      },
    }
  );
};

export const updateBaseAddressPUT = async (
  Constants,
  { AUTH_KNACK_USER_ID, city, state, street, street2, zip },
  handlers = {}
) => {
  const url = `https://api.knack.com/v1/pages/scene_1123/views/view_2102/records/${encodeQueryParam(
    AUTH_KNACK_USER_ID
  )}`;
  const options = {
    body: JSON.stringify({
      field_46: {
        street: street,
        street2: street2,
        city: city,
        state: state,
        zip: zip,
      },
    }),
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AUTH_KNACK_TOKEN'],
      'Content-Type': 'application/json',
      'X-Knack-Application-Id': Constants['APP_ID'],
    }),
    method: 'PUT',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useUpdateBaseAddressPUT = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      updateBaseAddressPUT(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('profile', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('profile');
        queryClient.invalidateQueries('profiles');
      },
    }
  );
};

export const updateInstructorBiographyPUT = async (
  Constants,
  { AUTH_USER_KNACK_ID, inputBiography },
  handlers = {}
) => {
  const url = `https://api.knack.com/v1/pages/scene_1122/views/view_2101/records/${encodeQueryParam(
    AUTH_USER_KNACK_ID
  )}`;
  const options = {
    body: JSON.stringify({ field_45: inputBiography }),
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AUTH_KNACK_TOKEN'],
      'Content-Type': 'application/json',
      'X-Knack-Application-Id': Constants['APP_ID'],
    }),
    method: 'PUT',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useUpdateInstructorBiographyPUT = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      updateInstructorBiographyPUT(
        Constants,
        { ...initialArgs, ...args },
        handlers
      ),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('profile', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('profile');
        queryClient.invalidateQueries('profiles');
      },
    }
  );
};

export const updateNotificationSettingsPUT = async (
  Constants,
  { AUTH_USER_KNACK_ID, oppsNotificationsSettings },
  handlers = {}
) => {
  const url = `https://api.knack.com/v1/pages/scene_1130/views/view_2105/records/${encodeQueryParam(
    AUTH_USER_KNACK_ID
  )}`;
  const options = {
    body: JSON.stringify({ field_2386_raw: oppsNotificationsSettings }),
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AUTH_KNACK_TOKEN'],
      'Content-Type': 'application/json',
      'X-Knack-Application-Id': Constants['APP_ID'],
    }),
    method: 'PUT',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useUpdateNotificationSettingsPUT = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      updateNotificationSettingsPUT(
        Constants,
        { ...initialArgs, ...args },
        handlers
      ),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('profile', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('profile');
        queryClient.invalidateQueries('profiles');
      },
    }
  );
};
