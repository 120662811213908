import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as IntegromatApi from '../apis/IntegromatApi.js';
import * as KnackAPIApi from '../apis/KnackAPIApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import * as Track from '../custom-files/Track';
import * as validateAppVersionAndAuthToken from '../custom-files/validateAppVersionAndAuthToken';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  Button,
  SVG,
  ScreenContainer,
  Spacer,
  Surface,
  TextInput,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { ActivityIndicator, Modal, Text, View } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';

const MyAddressScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [addressData, setAddressData] = React.useState({});
  const [enableSaveButton, setEnableSaveButton] = React.useState(false);
  const [inputCityValue, setInputCityValue] = React.useState('');
  const [inputStateValue, setInputStateValue] = React.useState('');
  const [inputStreet1Value, setInputStreet1Value] = React.useState('');
  const [inputStreet2Value, setInputStreet2Value] = React.useState('');
  const [inputZipValue, setInputZipValue] = React.useState('');
  const [showModalAddressUpdateSuccess, setShowModalAddressUpdateSuccess] =
    React.useState(false);
  const setScreenVariables = address => {
    const { street, street2, city, state, zip } = address;

    console.log('setScreenVariables: address: ', address);
    setInputStreet1Value(street);
    setInputStreet2Value(street2);
    setInputCityValue(city);
    setInputStateValue(state);
    setInputZipValue(zip);
    setAddressData(address);
  };

  const isAddressComplete = addressData => {
    return (
      inputStreet1Value && inputCityValue && inputStateValue && inputZipValue
    );
  };

  const hasAddressChanged = addressData => {
    const { street, street2, city, state, zip } = addressData;

    console.log('hasAddressChanged: addressData: ', addressData);

    console.log(
      'hasAddressChanged: input values: ',
      inputStreet1Value,
      inputStreet2Value,
      inputCityValue,
      inputStateValue,
      inputZipValue
    );

    return (
      inputStreet1Value != street ||
      inputStreet2Value != street2 ||
      inputCityValue != city ||
      inputStateValue != state ||
      inputZipValue != zip
    );
  };

  const replaceNullWithEmptyString = address => {
    // Iterate over object properties.
    for (let key in address) {
      // Check if the property value is null. If yes, set it to an empty string
      if (address[key] === null) {
        address[key] = '';
      }
    }

    return address;
  };
  React.useEffect(() => {
    // Track.onEnterScreen('MyAddressScreen', Variables, setGlobalVariableValue, props.navigation.navigate, props?.route?.params?.skipAuth);
  }, []);

  React.useEffect(() => {
    if (addressData) {
      if (hasAddressChanged(addressData)) {
        if (isAddressComplete(addressData)) {
          setEnableSaveButton(true);
          return;
        }
      }
    }

    setEnableSaveButton(false);
  });
  const knackAPIUpdateBaseAddressPUT = KnackAPIApi.useUpdateBaseAddressPUT();
  const integromatDeleteAndUpdateOppsPOST =
    IntegromatApi.useDeleteAndUpdateOppsPOST();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    const handler = async () => {
      try {
        if (!isFocused) {
          return;
        }
        const instructorProfileResponse = (
          await KnackAPIApi.getProfileGET(Constants, {
            AUTH_USER_KNACK_ID: Constants['AUTH_KNACK_USER_ID'],
          })
        )?.json;
        const addressData = instructorProfileResponse?.field_46_raw;
        setGlobalVariableValue({
          key: 'instructorBaseAddress',
          value: replaceNullWithEmptyString(addressData),
        });
        setScreenVariables(addressData);
      } catch (err) {
        console.error(err);
      }
    };
    handler();
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={false}
      hasTopSafeArea={false}
    >
      {/* View Top Navigation - Subtitle */}
      <View style={StyleSheet.applyWidth({ width: '100%' }, dimensions.width)}>
        {/* Spacer Top Nav */}
        <Spacer bottom={0} left={0} right={0} top={44} />
        {/* View Row Top Nav Container */}
        <View
          style={StyleSheet.applyWidth(
            {
              alignContent: 'center',
              alignItems: 'center',
              flexDirection: 'row',
              justifyContent: 'space-between',
              paddingBottom: 22,
              paddingLeft: '5.35%',
              paddingRight: '5.35%',
              paddingTop: 14,
            },
            dimensions.width
          )}
        >
          {/* View Back Arrow Container */}
          <View
            style={StyleSheet.applyWidth(
              { height: 24, width: 24 },
              dimensions.width
            )}
          >
            <Touchable
              onPress={() => {
                try {
                  navigation.goBack();
                } catch (err) {
                  console.error(err);
                }
              }}
              style={StyleSheet.applyWidth(
                { height: '100%', width: '100%' },
                dimensions.width
              )}
            >
              {/* Icon Back Arrow */}
              <SVG
                source={Constants['uiBackArrow']}
                style={StyleSheet.applyWidth(
                  { height: '100%', width: '100%' },
                  dimensions.width
                )}
              />
            </Touchable>
          </View>
          {/* View Page Title Container */}
          <View
            style={StyleSheet.applyWidth(
              { justifyContent: 'center' },
              dimensions.width
            )}
          >
            {/* Title */}
            <Text
              accessible={true}
              style={StyleSheet.applyWidth(
                {
                  color: theme.colors.text.strong,
                  fontFamily: 'Poppins_600SemiBold',
                },
                dimensions.width
              )}
            >
              {'My Address'}
            </Text>
          </View>
          {/* View Spacer */}
          <View
            style={StyleSheet.applyWidth(
              { height: 10, justifyContent: 'center', width: 10 },
              dimensions.width
            )}
          />
        </View>
      </View>

      <KeyboardAwareScrollView
        enableResetScrollToCoords={false}
        keyboardShouldPersistTaps={'never'}
        showsVerticalScrollIndicator={true}
        viewIsInsideTabBar={false}
        enableAutomaticScroll={true}
        enableOnAndroid={true}
      >
        {/* View Body Container */}
        <View
          style={StyleSheet.applyWidth(
            { paddingLeft: '5.35%', paddingRight: '5.35%' },
            dimensions.width
          )}
        >
          {/* View Row My Address */}
          <View
            style={StyleSheet.applyWidth(
              { paddingBottom: 25 },
              dimensions.width
            )}
          >
            {/* My Addresss */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'baseline',
                  flexDirection: 'row',
                  marginBottom: 26,
                },
                dimensions.width
              )}
            >
              {/* H1 */}
              <Text
                accessible={true}
                style={StyleSheet.applyWidth(
                  {
                    color: palettes.App['App Black 191F32'],
                    fontFamily: 'BebasNeue_400Regular',
                    fontSize: 24,
                    marginRight: 4,
                  },
                  dimensions.width
                )}
              >
                {'My'}
              </Text>
              {/* H1 Accent */}
              <Text
                accessible={true}
                style={StyleSheet.applyWidth(
                  {
                    color: palettes.App['App Red EB3E26'],
                    fontFamily: 'LeckerliOne_400Regular',
                    fontSize: 22,
                  },
                  dimensions.width
                )}
              >
                {'Address'}
              </Text>
            </View>
            {/* View Map Container */}
            <View />
            {/* View Address About Container */}
            <View
              style={StyleSheet.applyWidth(
                { paddingBottom: 25 },
                dimensions.width
              )}
            >
              {/* About Text */}
              <View
                style={StyleSheet.applyWidth(
                  { paddingBottom: 12 },
                  dimensions.width
                )}
              >
                {/* Pick Available Days */}
                <Text
                  accessible={true}
                  style={StyleSheet.applyWidth(
                    {
                      color: palettes.App['App Text 2ndary 7F868C'],
                      fontFamily: 'Poppins_400Regular',
                    },
                    dimensions.width
                  )}
                >
                  {
                    'Important: Keep your address up-to-date and accurate to receive important documents like your 1099 Tax Forms and relevant notifications about new clients near you.'
                  }
                </Text>
              </View>
            </View>
            {/* View Address Inputs Container */}
            <View>
              {/* Text Input Street 1 */}
              <TextInput
                autoCorrect={true}
                changeTextDelay={500}
                onChangeText={newTextInputStreet1Value => {
                  try {
                    setInputStreet1Value(newTextInputStreet1Value);
                  } catch (err) {
                    console.error(err);
                  }
                }}
                webShowOutline={true}
                autoCapitalize={'words'}
                autoComplete={'address-line1'}
                clearTextOnFocus={false}
                defaultValue={Constants['instructorBaseAddress']?.street}
                editable={true}
                placeholder={'Street 1'}
                placeholderTextColor={palettes.App['App Text 2ndary 7F868C']}
                secureTextEntry={false}
                style={StyleSheet.applyWidth(
                  {
                    backgroundColor: palettes.App['Custom Color'],
                    borderBottomWidth: 1,
                    borderColor: palettes.App['App Grey Inputs E2E2E2'],
                    borderLeftWidth: 1,
                    borderRadius: 8,
                    borderRightWidth: 1,
                    borderTopWidth: 1,
                    fontFamily: 'Poppins_400Regular',
                    marginBottom: 16,
                    paddingBottom: 16,
                    paddingLeft: 16,
                    paddingRight: 16,
                    paddingTop: 16,
                  },
                  dimensions.width
                )}
                textContentType={'streetAddressLine1'}
              />
              {/* Text Input Street 2 */}
              <TextInput
                autoCorrect={true}
                changeTextDelay={500}
                onChangeText={newTextInputStreet2Value => {
                  try {
                    setInputStreet2Value(newTextInputStreet2Value);
                  } catch (err) {
                    console.error(err);
                  }
                }}
                webShowOutline={true}
                autoCapitalize={'words'}
                autoComplete={'address-line2'}
                clearTextOnFocus={false}
                defaultValue={Constants['instructorBaseAddress']?.street2}
                editable={true}
                placeholder={'Street 2'}
                placeholderTextColor={palettes.App['App Text 2ndary 7F868C']}
                secureTextEntry={false}
                style={StyleSheet.applyWidth(
                  {
                    backgroundColor: palettes.App['Custom Color'],
                    borderBottomWidth: 1,
                    borderColor: palettes.App['App Grey Inputs E2E2E2'],
                    borderLeftWidth: 1,
                    borderRadius: 8,
                    borderRightWidth: 1,
                    borderTopWidth: 1,
                    fontFamily: 'Poppins_400Regular',
                    marginBottom: 16,
                    paddingBottom: 16,
                    paddingLeft: 16,
                    paddingRight: 16,
                    paddingTop: 16,
                  },
                  dimensions.width
                )}
                textContentType={'streetAddressLine2'}
              />
              {/* Text Input City */}
              <TextInput
                autoCorrect={true}
                changeTextDelay={500}
                onChangeText={newTextInputCityValue => {
                  try {
                    setInputCityValue(newTextInputCityValue);
                  } catch (err) {
                    console.error(err);
                  }
                }}
                webShowOutline={true}
                autoCapitalize={'words'}
                clearTextOnFocus={false}
                defaultValue={Constants['instructorBaseAddress']?.city}
                editable={true}
                placeholder={'City'}
                placeholderTextColor={palettes.App['App Text 2ndary 7F868C']}
                secureTextEntry={false}
                style={StyleSheet.applyWidth(
                  {
                    backgroundColor: palettes.App['Custom Color'],
                    borderBottomWidth: 1,
                    borderColor: palettes.App['App Grey Inputs E2E2E2'],
                    borderLeftWidth: 1,
                    borderRadius: 8,
                    borderRightWidth: 1,
                    borderTopWidth: 1,
                    fontFamily: 'Poppins_400Regular',
                    marginBottom: 16,
                    paddingBottom: 16,
                    paddingLeft: 16,
                    paddingRight: 16,
                    paddingTop: 16,
                  },
                  dimensions.width
                )}
                textContentType={'addressCity'}
              />
              {/* Text Input State */}
              <TextInput
                autoCorrect={true}
                changeTextDelay={500}
                onChangeText={newTextInputStateValue => {
                  try {
                    setInputStateValue(newTextInputStateValue);
                  } catch (err) {
                    console.error(err);
                  }
                }}
                webShowOutline={true}
                autoCapitalize={'words'}
                clearTextOnFocus={false}
                defaultValue={Constants['instructorBaseAddress']?.state}
                editable={true}
                placeholder={'State'}
                placeholderTextColor={palettes.App['App Text 2ndary 7F868C']}
                secureTextEntry={false}
                style={StyleSheet.applyWidth(
                  {
                    backgroundColor: palettes.App['Custom Color'],
                    borderBottomWidth: 1,
                    borderColor: palettes.App['App Grey Inputs E2E2E2'],
                    borderLeftWidth: 1,
                    borderRadius: 8,
                    borderRightWidth: 1,
                    borderTopWidth: 1,
                    fontFamily: 'Poppins_400Regular',
                    marginBottom: 16,
                    paddingBottom: 16,
                    paddingLeft: 16,
                    paddingRight: 16,
                    paddingTop: 16,
                  },
                  dimensions.width
                )}
                textContentType={'addressState'}
              />
              {/* Text Input Zip */}
              <TextInput
                autoCapitalize={'none'}
                autoCorrect={true}
                changeTextDelay={500}
                onChangeText={newTextInputZipValue => {
                  try {
                    setInputZipValue(newTextInputZipValue);
                  } catch (err) {
                    console.error(err);
                  }
                }}
                webShowOutline={true}
                autoComplete={'postal-code'}
                clearTextOnFocus={false}
                defaultValue={Constants['instructorBaseAddress']?.zip}
                editable={true}
                keyboardType={'numeric'}
                placeholder={'Zip'}
                placeholderTextColor={palettes.App['App Text 2ndary 7F868C']}
                secureTextEntry={false}
                style={StyleSheet.applyWidth(
                  {
                    backgroundColor: palettes.App['Custom Color'],
                    borderBottomWidth: 1,
                    borderColor: palettes.App['App Grey Inputs E2E2E2'],
                    borderLeftWidth: 1,
                    borderRadius: 8,
                    borderRightWidth: 1,
                    borderTopWidth: 1,
                    fontFamily: 'Poppins_400Regular',
                    marginBottom: 16,
                    paddingBottom: 16,
                    paddingLeft: 16,
                    paddingRight: 16,
                    paddingTop: 16,
                  },
                  dimensions.width
                )}
                textContentType={'postalCode'}
              />
            </View>
          </View>
        </View>
        {/* View Bottom Button Spacer */}
        <View>
          <Spacer left={8} right={8} bottom={55} top={55} />
        </View>
      </KeyboardAwareScrollView>
      {/* View Bottom Anchor */}
      <View
        style={StyleSheet.applyWidth(
          {
            backgroundColor: palettes.App['Custom Color'],
            bottom: 0,
            paddingLeft: '5.35%',
            paddingRight: '5.35%',
            position: 'absolute',
            width: '100%',
          },
          dimensions.width
        )}
      >
        {/* View Button Container */}
        <View
          style={StyleSheet.applyWidth(
            { alignItems: 'center', marginBottom: 16, width: '100%' },
            dimensions.width
          )}
        >
          {/* Save Address Disabled */}
          <>
            {enableSaveButton ? null : (
              <Button
                iconPosition={'left'}
                {...GlobalStyles.ButtonStyles(theme)['disabled'].props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.ButtonStyles(theme)['disabled'].style,
                  dimensions.width
                )}
                title={'Save Address'}
              />
            )}
          </>
          {/* Save Address Enabled */}
          <>
            {!enableSaveButton ? null : (
              <Button
                iconPosition={'left'}
                onPress={() => {
                  const handler = async () => {
                    try {
                      setGlobalVariableValue({
                        key: 'is_loading',
                        value: true,
                      });
                      const updateAddressResponse = (
                        await knackAPIUpdateBaseAddressPUT.mutateAsync({
                          AUTH_KNACK_USER_ID: Constants['AUTH_KNACK_USER_ID'],
                          city: inputCityValue,
                          state: inputStateValue,
                          street: inputStreet1Value,
                          street2: inputStreet2Value,
                          zip: inputZipValue,
                        })
                      )?.json;
                      const updateOppsResponse = (
                        await integromatDeleteAndUpdateOppsPOST.mutateAsync({
                          AUTH_KNACK_USER_ID: Constants['AUTH_KNACK_USER_ID'],
                          shouldUpdateOpps: true,
                        })
                      )?.json;
                      setGlobalVariableValue({
                        key: 'is_loading',
                        value: false,
                      });
                      setShowModalAddressUpdateSuccess(true);
                    } catch (err) {
                      console.error(err);
                    }
                  };
                  handler();
                }}
                style={StyleSheet.applyWidth(
                  {
                    backgroundColor: palettes.App['App Red EB3E26'],
                    borderRadius: 50,
                    fontFamily: 'BebasNeue_400Regular',
                    fontSize: 18,
                    paddingBottom: 14,
                    paddingTop: 14,
                    textAlign: 'center',
                    width: '100%',
                  },
                  dimensions.width
                )}
                title={'Save Address'}
              />
            )}
          </>
          {/* View Activity Indicator */}
          <>
            {!Constants['is_loading'] ? null : (
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    backgroundColor: palettes.App['App Red EB3E26'],
                    borderRadius: 50,
                    marginTop: -50,
                    opacity: 0.8,
                    paddingBottom: 7,
                    paddingTop: 7,
                    width: '100%',
                  },
                  dimensions.width
                )}
              >
                <ActivityIndicator
                  animating={true}
                  hidesWhenStopped={true}
                  size={'small'}
                  color={palettes.App['Custom Color']}
                  style={StyleSheet.applyWidth(
                    { height: 36, width: 36 },
                    dimensions.width
                  )}
                />
              </View>
            )}
          </>
        </View>
        <Spacer bottom={34} left={0} right={0} top={0} />
      </View>
      {/* Modal Address Update Success */}
      <>
        {!showModalAddressUpdateSuccess ? null : (
          <Modal
            supportedOrientations={['portrait', 'landscape']}
            animationType={'slide'}
            presentationStyle={'fullScreen'}
            transparent={true}
          >
            <View
              style={StyleSheet.applyWidth(
                {
                  backgroundColor: palettes.App['App Transparent'],
                  height: '100%',
                  width: '100%',
                },
                dimensions.width
              )}
            >
              <Surface
                elevation={3}
                style={StyleSheet.applyWidth(
                  {
                    backgroundColor: palettes.App['App Blue eef6fa'],
                    borderTopLeftRadius: 20,
                    borderTopRightRadius: 20,
                    bottom: 0,
                    minHeight: 40,
                    paddingLeft: '5.35%',
                    paddingRight: '5.35%',
                    paddingTop: 26,
                    position: 'absolute',
                    width: '100%',
                  },
                  dimensions.width
                )}
              >
                {/* Modal Title Container */}
                <View
                  style={StyleSheet.applyWidth(
                    { marginBottom: 15 },
                    dimensions.width
                  )}
                >
                  <Text
                    accessible={true}
                    style={StyleSheet.applyWidth(
                      {
                        alignSelf: 'center',
                        color: palettes.App['App Black 191F32'],
                        fontFamily: 'Poppins_600SemiBold',
                        fontSize: 20,
                        textAlign: 'center',
                      },
                      dimensions.width
                    )}
                  >
                    {'Your address has been updated.'}
                  </Text>
                </View>
                {/* Body text */}
                <View
                  style={StyleSheet.applyWidth(
                    { marginBottom: 15 },
                    dimensions.width
                  )}
                >
                  <Text
                    accessible={true}
                    style={StyleSheet.applyWidth(
                      {
                        alignSelf: 'center',
                        color: palettes.App['App Disabled Button Text 7F868C'],
                        fontFamily: 'Poppins_400Regular',
                        fontSize: 14,
                        textAlign: 'center',
                      },
                      dimensions.width
                    )}
                  >
                    {
                      "We're updating your nearby job opportunities. Please allow 3 minutes for this process to complete before you can request or decline new job opportunities."
                    }
                  </Text>
                </View>
                {/* View Ok Button Container */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignContent: 'center',
                      alignItems: 'center',
                      alignSelf: 'center',
                      marginBottom: 32,
                      marginTop: 15,
                      width: '100%',
                    },
                    dimensions.width
                  )}
                >
                  {/* Ok button */}
                  <Button
                    iconPosition={'left'}
                    onPress={() => {
                      try {
                        setShowModalAddressUpdateSuccess(false);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor: palettes.App['App Red EB3E26'],
                        borderRadius: 50,
                        fontFamily: 'BebasNeue_400Regular',
                        fontSize: 18,
                        paddingBottom: 14,
                        paddingTop: 14,
                        textAlign: 'center',
                        width: '100%',
                      },
                      dimensions.width
                    )}
                    title={'OK'}
                  />
                  {/* View Activity Indicator */}
                  <>
                    {!Constants['is_loading'] ? null : (
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            backgroundColor: palettes.App['App Red EB3E26'],
                            borderRadius: 50,
                            marginTop: -50,
                            opacity: 0.8,
                            paddingBottom: 7,
                            paddingTop: 7,
                            width: '100%',
                          },
                          dimensions.width
                        )}
                      >
                        <ActivityIndicator
                          animating={true}
                          hidesWhenStopped={true}
                          size={'small'}
                          color={palettes.App['Custom Color']}
                          style={StyleSheet.applyWidth(
                            { height: 36, width: 36 },
                            dimensions.width
                          )}
                        />
                      </View>
                    )}
                  </>
                  <Spacer bottom={34} left={0} right={0} top={0} />
                </View>
              </Surface>
            </View>
          </Modal>
        )}
      </>
    </ScreenContainer>
  );
};

export default withTheme(MyAddressScreen);
