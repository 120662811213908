// TRACK

// commented out code is for tracking screen time. Currently not working until can reliably trigger onLeaveScreen().
import moment from 'moment-timezone';
import { Validate } from './validateAppVersionAndAuthToken';
import { syncAppActivityData } from './Sync.js';

const formatDate = date => moment(date).format('MM/DD/YYYY');
const currentDate = formatDate(new Date());

const shouldUpdateLastDateUsed = appActivityData => {
  console.log('Check if last date used is up-to-date.');
  return appActivityData.lastDateUsed !== currentDate;
};

const shouldSyncAppActivityDataToServer = appActivityData => {
  console.log(
    'Check if appActivityData is out of sync with server: ',
    appActivityData.lastDateSyncedWithServer !== currentDate
  );
  return appActivityData.lastDateSyncedWithServer !== currentDate;
};

export const shouldSkipAuthAndForwardToHomeScreen = (
  loginStatusValid,
  currentScreen,
  localAppActivityData,
  setGlobalVariableValue
) => {
  setGlobalVariableValue({
    key: 'is_loading_login_screen',
    value: false,
  });

  // const { lastScreenVisited } = localAppActivityData.screensData;

  if (
    loginStatusValid &&
    currentScreen === 'LoginScreen'
    // lastScreenVisited !== 'LoginScreen' &&
    // lastScreenVisited
  ) {
    return true;
  }
  return false;
};

export const authenticationRequired = (screenName, skipAuth) => {
  if (
    screenName === 'LoginHelpScreen' ||
    screenName === 'UpdateAppRequiredScreen' ||
    screenName === 'SessionExpiredScreen' ||
    skipAuth === true
  ) {
    console.log('Skip Authentication.');
    return false;
  }
  return true;
};

export const trackScreenActivity = async (
  screenName,
  localAppData,
  setGlobalVariableValue,
  screenTimeInSeconds
) => {
  console.log('Execute screen data tracking.');
  const { screensData } = localAppData;

  if (!screensData[screenName]) {
    screensData[screenName] = {
      visits: 1,
      screenTime: 0,
    };
  } else {
    screensData[screenName].visits++;
    screensData[screenName].screenTime += screenTimeInSeconds;
  }

  if (screensData?.lastScreenVisited !== screenName) {
    screensData.lastScreenVisited = screenName;
  }

  let updatedLocalAppData = localAppData;

  setGlobalVariableValue({
    key: 'appActivityData',
    value: updatedLocalAppData,
  });

  console.log('App activity tracking complete.');
};

// let currentScreen = '';
// let screenEnterTimestamp = 0;

export const getCurrentTimestamp = () => {
  return Math.floor(Date.now() / 1000);
};

export const onEnterScreen = async (
  screenName,
  Variables,
  setGlobalVariableValue,
  nav,
  skipAuth
) => {
  // switch appActivityData to regular js variable so can manipulate it without set method.
  let localAppActivityData = Variables.appActivityData;

  if (!Variables.AUTH_KNACK_TOKEN) {
    setGlobalVariableValue({
      key: 'is_loading_login_screen',
      value: false,
    });
    // console.log(
    //   'No Auth Token. User has not logged into app before. End onEnterScreen function.'
    // );
    // return;
  }

  if (shouldUpdateLastDateUsed(localAppActivityData)) {
    console.log('Update last date used: ', currentDate);
    localAppActivityData.lastDateUsed = currentDate;
  }

  if (authenticationRequired(screenName, skipAuth)) {
    const loginStatusValid = await Validate(
      nav,
      Variables,
      screenName,
      setGlobalVariableValue
    );

    if (!loginStatusValid) {
      setGlobalVariableValue({
        key: 'is_loading_login_screen',
        value: false,
      });
      return;
    }

    if (
      shouldSkipAuthAndForwardToHomeScreen(
        loginStatusValid,
        screenName,
        localAppActivityData,
        setGlobalVariableValue
      )
    ) {
      console.log('Navigate to home screen: ');
      nav('HomeScreen', {
        skipAuth: true,
      });
      return;
    }

    if (shouldSyncAppActivityDataToServer(localAppActivityData)) {
      console.log('Syncing required.');
      syncAppActivityData(
        Variables,
        setGlobalVariableValue,
        localAppActivityData
      );
    }
  }

  // track screen data must come after shouldReturnToLastScreen, otherwise, when user returns from closing the app, the app goes to LoginScreen, and LoginScreen will always be last screen visited.
  trackScreenActivity(
    screenName,
    localAppActivityData,
    setGlobalVariableValue,
    0
  );

  // currentScreen = screenName;
  // screenEnterTimestamp = getCurrentTimestamp();
  // console.log('currentScreen: ', currentScreen);
  // console.log('Enter timestamp: ', screenEnterTimestamp);
};

// export const onLeaveScreen = (appActivityData, setGlobalVariableValue) => {
//   console.log('Execute onLeaveScreen');
//   console.log('currentScreen: ', currentScreen);
//   console.log('Enter timestamp: ', screenEnterTimestamp);
//   const screenExitTimestamp = getCurrentTimestamp();
//   const screenTime = screenExitTimestamp - screenEnterTimestamp;
//   trackScreenActivity(
//     currentScreen,
//     screenTime,
//     appActivityData,
//     setGlobalVariableValue
//   );
// };
