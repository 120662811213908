import React from 'react';
import { View, ActivityIndicator } from 'react-native';
import * as SplashScreen from 'expo-splash-screen';
import AsyncStorage from '@react-native-async-storage/async-storage';

export const DeviceVariables = {
  appActivityData: {
    screensData: { lastScreenVisited: '' },
    lastDateUsed: '',
    lastDateSyncedWithServer: '',
  },
  APP_ID: '583c6d88028139b22d1ad517',
  APP_TERMS_VERSION: { id: '6463d6b09629f1002726aef0', identifier: '' },
  APP_VERSION: { id: '648a2929dbaa3e002952e43f', identifier: '0.0.3' },
  AUTH_KNACK_TOKEN: '',
  AUTH_KNACK_USER_ID: '',
  DEVICE_ID: '',
  DEVICE_IP: '',
  DEVICE_OS: '',
  DEVICE_PUSH_TOKEN: null,
  existingPushTokens: [],
  gAccountComplete: '',
  gAccountReadyForClients: '',
  gAlternateScheduleTimes_gv: [],
  gBioComplete: '',
  gBioImage: '',
  gBioImageUrl: '',
  gBioText: '',
  gCprActive: '',
  gCprExpirationDate: '',
  gCprImage: '',
  gInstructorAvailability: {},
  gInstructorAvailabilityDays: [],
  gInstructorBioStatus: '',
  gInstructorEmailAddress: '',
  gInstructorTravelRange: '',
  gInstructorWantsNewOpps: '',
  gNotificationsAllOptions: [
    'New Opps - Email',
    'New Opps - SMS',
    'New Opps - Mobile Banner',
    'Daily Opps Summary - Email',
    'Daily Opps Summary - Mobile Banner',
  ],
  gNotificationsOppsNewBanner: '',
  gNotificationsOppsNewEmail: '',
  gNotificationsOppsNewSMS: '',
  gNotificationsOppsSumBanner: '',
  gNotificationsOppsSumEmail: '',
  gOnboardingCallsAttended: '',
  gPoolAvailability: [],
  gProfileStatus: '',
  gPushNotificationsSavedToServer: '',
  gSiPools: [],
  gSunsational101Complete: '',
  gTipaltiComplete: '',
  gUserEmail: '',
  instructorBaseAddress: {},
  require_sign_in: true,
  userFirstName: '',
  userFullName: '',
  __env__: 'Production',
};
export const AppVariables = {
  authError: '',
  conditionalStop: false,
  dummyClients: [
    {
      ui: 'https://sss-app-graphics.s3.us-west-1.amazonaws.com/avatars/avatar_1.svg',
      name: 'Raphael',
    },
    {
      ui: 'https://sss-app-graphics.s3.us-west-1.amazonaws.com/avatars/avatar_1.svg',
      name: 'Donatello',
    },
    {
      ui: 'https://sss-app-graphics.s3.us-west-1.amazonaws.com/avatars/avatar_1.svg',
      name: 'Michael',
    },
    {
      ui: 'https://sss-app-graphics.s3.us-west-1.amazonaws.com/avatars/avatar_1.svg',
      name: 'Leonardo',
    },
  ],
  empty: '',
  gAlternateScheduleTimes: [
    '6AM',
    '7AM',
    '8AM',
    '9AM',
    '10AM',
    '11AM',
    '12PM',
    '1PM',
    '2PM',
    '3PM',
    '4PM',
    '5PM',
    '6PM',
    '7PM',
    '8PM',
  ],
  gEnvironment: '5ed73499dac53e2a8b866f49528a9bef',
  gModalVisible: false,
  gMondays: true,
  gUploadError: '',
  instructorAddressCity: '',
  instructorAddressLat: null,
  instructorAddressLong: null,
  instructorAddressState: '',
  instructorAddressStreet1: '',
  instructorAddressStreet2: '',
  instructorAddressZip: '',
  is_loading: false,
  is_loading_login_screen: false,
  LATEST_APP_VERSION: { id: '66aa5c2fa2f18c00283937ec', identifier: '1.0.10' },
  LOGIN_RESPONSE: '',
  onboardingSteps: {
    step1: 'Onboarding1UploadCPRScreen',
    step2: 'askForPushToken',
    step3: 'Onboarding2InstructorBioScreen',
    step4: 'Onboarding3CompleteTipaltiFormsScreen',
    step5: 'OnboardingCompleteSunsational101Screen',
    step6: 'Onboard4IncompleteWarningScreen',
  },
  openJobsCount: '',
  pauseJobsPageTimer: false,
  payFreeze: false,
  poolTypes: ['', 'Private Home Pool', 'Housing Community Pool', 'Other'],
  screen: '',
  sendDeclinePackageResponse: '',
  sendRequestPackageResponse: '',
  SHOT: false,
  showErrorMessage: false,
  showModalDeclineSuccess: false,
  showModalDeclineSurvey: false,
  travelRanges: ['Within 10 Miles', 'Within 25 Miles', 'Within 40 Miles'],
  uiAnimationChatBubble:
    'https://sss-app-graphics.s3.us-west-1.amazonaws.com/animations/chat-bubbles.gif',
  uiAnimationCheckmarkDone:
    'https://sss-app-graphics.s3.us-west-1.amazonaws.com/animations/checkmark-done.gif',
  uiAnimationSendingEmail:
    'https://sss-app-graphics.s3.us-west-1.amazonaws.com/animations/sending-email.gif',
  uiAnimationSpeedoSwimmer:
    'https://sss-app-graphics.s3.us-west-1.amazonaws.com/animations/speedo-swimmer.gif',
  uiArrowRightBlack:
    'https://sss-app-graphics.s3.us-west-1.amazonaws.com/icons/icon_arrow_right.svg',
  uiAvatar:
    'https://sss-app-graphics.s3.us-west-1.amazonaws.com/avatars/avatar_6.svg',
  uiAvatar1:
    'https://sss-app-graphics.s3.us-west-1.amazonaws.com/avatars/avatar_1.svg',
  uiAvatar2:
    'https://sss-app-graphics.s3.us-west-1.amazonaws.com/avatars/avatar_2.svg',
  uiAvatar3:
    'https://sss-app-graphics.s3.us-west-1.amazonaws.com/avatars/avatar_3.svg',
  uiAvatar4:
    'https://sss-app-graphics.s3.us-west-1.amazonaws.com/avatars/avatar_4.svg',
  uiAvatar5:
    'https://sss-app-graphics.s3.us-west-1.amazonaws.com/avatars/avatar_5.svg',
  uiAvatars: [
    'https://sss-app-graphics.s3.us-west-1.amazonaws.com/avatars/avatar_1.svg',
    'https://sss-app-graphics.s3.us-west-1.amazonaws.com/avatars/avatar_2.svg',
    'https://sss-app-graphics.s3.us-west-1.amazonaws.com/avatars/avatar_3.svg',
    'https://sss-app-graphics.s3.us-west-1.amazonaws.com/avatars/avatar_4.svg',
    'https://sss-app-graphics.s3.us-west-1.amazonaws.com/avatars/avatar_5.svg',
    'https://sss-app-graphics.s3.us-west-1.amazonaws.com/avatars/avatar_6.svg',
  ],
  uiBackArrow:
    'https://sss-app-graphics.s3.us-west-1.amazonaws.com/icons/icon_arrow_left_black_square.svg',
  uiBgHomescreen:
    'https://sss-app-graphics.s3.us-west-1.amazonaws.com/backgrounds/bg_homescreen.svg',
  uiBgSunsetRectangle:
    'https://sss-app-graphics.s3.us-west-1.amazonaws.com/backgrounds/bg_sunset_orange_rectangle.svg',
  uiBgWavesBlueRectangle:
    'https://sss-app-graphics.s3.us-west-1.amazonaws.com/backgrounds/bg_waves_blue_rectangle.svg',
  uiBgWhiteSunBeams:
    'https://sss-app-graphics.s3.us-west-1.amazonaws.com/backgrounds/bg_white_sun_beams.svg',
  uiDrawingStarfishLounging:
    'https://sss-app-graphics.s3.us-west-1.amazonaws.com/drawings/starfish_lounging_under_umbrella.svg',
  uiFilterBlack:
    'https://sss-app-graphics.s3.us-west-1.amazonaws.com/icon_filter_black.svg',
  uiGearBlack:
    'https://sss-app-graphics.s3.us-west-1.amazonaws.com/icons/icon_gear_black_hallow.svg',
  uiGearOrange:
    'https://sss-app-graphics.s3.us-west-1.amazonaws.com/icons/icon_gear_orange_hallow.svg',
  uiIconArrowLeftWhite:
    'https://sss-app-graphics.s3.us-west-1.amazonaws.com/icons/icon_arrow_left_white.svg',
  uiIconEmailOrangeRound:
    'https://sss-app-graphics.s3.us-west-1.amazonaws.com/icons/icon_email_orange_round.svg',
  uiIconPencilOrange:
    'https://sss-app-graphics.s3.us-west-1.amazonaws.com/icons/icon_pencil_orange.svg',
  uiIconPhoneOrangeRound:
    'https://sss-app-graphics.s3.us-west-1.amazonaws.com/icons/icon_phone_orange_round.svg',
  uiIconSmsOrangeRound:
    'https://sss-app-graphics.s3.us-west-1.amazonaws.com/icons/icon_sms_orange_round.svg',
  uiIconSortWhite:
    'https://sss-app-graphics.s3.us-west-1.amazonaws.com/icons/icon_sort_white.svg',
  uiKbFeaturedArticleBg1:
    'https://sss-app-graphics.s3.us-west-1.amazonaws.com/backgrounds/bg_featured_article_1.svg',
  uiLocationArrow:
    'https://sss-app-graphics.s3.us-west-1.amazonaws.com/icons/icon_location_arrow.svg',
  uiLocationIcon:
    'https://sss-app-graphics.s3.us-west-1.amazonaws.com/icons/icon_location_orange_bg_circle.svg',
  uiLocationPin:
    'https://sss-app-graphics.s3.us-west-1.amazonaws.com/icons/icon_location_pin.svg',
  uiLogoRound:
    'https://sss-app-graphics.s3.us-west-1.amazonaws.com/brand-logos/logo_round.svg',
  uiProfileOrangeBgCircle:
    'https://sss-app-graphics.s3.us-west-1.amazonaws.com/icons/icon_profile_orange_bg_circle.svg',
  uiRelaxingPup:
    'https://sss-app-graphics.s3.us-west-1.amazonaws.com/drawings/relaxing_pup.svg',
  uiSearchIcon:
    'https://sss-app-graphics.s3.us-west-1.amazonaws.com/icons/icon_search.svg',
  uiSearchIconWhite:
    'https://sss-app-graphics.s3.us-west-1.amazonaws.com/icons/icon_search_white.svg',
  uiSortBlack:
    'https://sss-app-graphics.s3.us-west-1.amazonaws.com/icons/icon_sort_black.svg',
  userProfilePic: 'https://via.placeholder.com/450',
};
const GlobalVariableContext = React.createContext();
const GlobalVariableUpdater = React.createContext();
const keySuffix = '';

// Attempt to parse a string as JSON. If the parse fails, return the string as-is.
// This is necessary to account for variables which are already present in local
// storage, but were not stored in JSON syntax (e.g. 'hello' instead of '"hello"').
function tryParseJson(str) {
  try {
    return JSON.parse(str);
  } catch {
    return str;
  }
}

class GlobalVariable {
  /**
   *  Filters an object of key-value pairs for those that should be
   *  persisted to storage, and persists them.
   *
   *  @param values Record<string, string>
   */
  static async syncToLocalStorage(values) {
    const update = Object.entries(values)
      .filter(([key]) => key in DeviceVariables)
      .map(([key, value]) => [key + keySuffix, JSON.stringify(value)]);

    if (update.length > 0) {
      await AsyncStorage.multiSet(update);
    }

    return update;
  }

  static async loadLocalStorage() {
    const keys = Object.keys(DeviceVariables);
    const entries = await AsyncStorage.multiGet(
      keySuffix ? keys.map(k => k + keySuffix) : keys
    );

    // If values isn't set, use the default. These will be written back to
    // storage on the next render.
    const withDefaults = entries.map(([key_, value]) => {
      // Keys only have the suffix appended in storage; strip the key
      // after they are retrieved
      const key = keySuffix ? key_.replace(keySuffix, '') : key_;
      return [key, value ? tryParseJson(value) : DeviceVariables[key]];
    });

    return Object.fromEntries(withDefaults);
  }
}

class State {
  static defaultValues = {
    ...AppVariables,
    ...DeviceVariables,
  };

  static reducer(state, { type, payload }) {
    switch (type) {
      case 'RESET':
        return { values: State.defaultValues, __loaded: true };
      case 'LOAD_FROM_ASYNC_STORAGE':
        return { values: { ...state.values, ...payload }, __loaded: true };
      case 'UPDATE':
        return state.__loaded
          ? {
              ...state,
              values: {
                ...state.values,
                [payload.key]: payload.value,
              },
            }
          : state;
      default:
        return state;
    }
  }

  static initialState = {
    __loaded: false,
    values: State.defaultValues,
  };
}

export function GlobalVariableProvider({ children }) {
  const [state, dispatch] = React.useReducer(State.reducer, State.initialState);

  React.useEffect(() => {
    async function prepare() {
      await SplashScreen.preventAutoHideAsync();
    }

    prepare();
  }, []);

  // This effect runs on mount to overwrite the default value of any
  // key that has a local value.
  React.useEffect(() => {
    async function initialStorageLoader() {
      try {
        const payload = await GlobalVariable.loadLocalStorage();
        if (
          payload?.__env__ &&
          DeviceVariables.__env__ &&
          payload.__env__ !== DeviceVariables.__env__
        ) {
          console.log(
            `Publication Environment changed from ${payload.__env__} to ${DeviceVariables.__env__}. Refreshing variables`
          );
          dispatch({
            type: 'LOAD_FROM_ASYNC_STORAGE',
            payload: DeviceVariables,
          });
        } else {
          dispatch({ type: 'LOAD_FROM_ASYNC_STORAGE', payload });
        }
      } catch (err) {
        console.error(err);
      }
    }
    initialStorageLoader();
  }, []);

  // This effect runs on every state update after the initial load. Gives us
  // best of both worlds: React state updates sync, but current state made
  // durable next async tick.
  React.useEffect(() => {
    async function syncToAsyncStorage() {
      try {
        await GlobalVariable.syncToLocalStorage(state.values);
      } catch (err) {
        console.error(err);
      }
    }
    if (state.__loaded) {
      syncToAsyncStorage();
    }
  }, [state]);

  const onLayoutRootView = React.useCallback(async () => {
    if (state.__loaded) {
      await SplashScreen.hideAsync();
    }
  }, [state.__loaded]);

  // We won't want an app to read a default state when there might be one
  // incoming from storage.
  if (!state.__loaded) {
    return null;
  }

  return (
    <GlobalVariableUpdater.Provider
      value={dispatch}
      onLayout={onLayoutRootView}
    >
      <GlobalVariableContext.Provider value={state.values}>
        {children}
      </GlobalVariableContext.Provider>
    </GlobalVariableUpdater.Provider>
  );
}

// Hooks
export function useSetValue() {
  const dispatch = React.useContext(GlobalVariableUpdater);
  return ({ key, value }) => {
    dispatch({ type: 'UPDATE', payload: { key, value } });
    return value;
  };
}

export function useValues() {
  return React.useContext(GlobalVariableContext);
}
