import React from 'react';

const useCustomerAvatar = (Variables, avatar) => {
  const { uiAvatars } = Variables;
  const randomIndex = Math.floor(Math.random() * uiAvatars.length);
  const randomAvatar = uiAvatars[randomIndex];

  if (!avatar) {
    return randomAvatar;
  }

  if (Array.isArray(avatar) && avatar.length === 0) {
    return randomAvatar;
  }

  return avatar;
};

export default useCustomerAvatar;
