export default {
  AbyssinicaSIL_400Regular: require('../assets/fonts/AbyssinicaSIL_400Regular.ttf'),
  BebasNeue_400Regular: require('../assets/fonts/BebasNeue_400Regular.ttf'),
  Inter_400Regular: require('../assets/fonts/Inter_400Regular.ttf'),
  Inter_500Medium: require('../assets/fonts/Inter_500Medium.ttf'),
  Inter_600SemiBold: require('../assets/fonts/Inter_600SemiBold.ttf'),
  LeckerliOne_400Regular: require('../assets/fonts/LeckerliOne_400Regular.ttf'),
  Poppins_400Regular: require('../assets/fonts/Poppins_400Regular.ttf'),
  Poppins_500Medium: require('../assets/fonts/Poppins_500Medium.ttf'),
  Poppins_600SemiBold: require('../assets/fonts/Poppins_600SemiBold.ttf'),
  Poppins_700Bold: require('../assets/fonts/Poppins_700Bold.ttf'),
  Poppins_400Regular_Italic: require('../assets/fonts/Poppins_400Regular_Italic.ttf'),
};
