import React, { useState } from 'react';
import { Modal, StyleSheet, Text, Pressable, View } from 'react-native';

export const modal = ({ navigation, visible }) => {
  const [modalVisible, setModalVisible] = useState(true);

  const handleClose = () => {
    setModalVisible(!modalVisible);
    navigation.navigate('ProfileScreen');
  };

  const handleOpen = () => {
    setModalVisible(true);
  };

  return (
    <View style={styles.centeredView}>
      <Modal
        animationType="slide"
        transparent={true}
        visible={modalVisible}
        onRequestClose={() => {
          setModalVisible(!modalVisible);
        }}
      >
        <View style={[styles.centeredViewYellow]}>
          <View style={styles.modalView}>
            <Text style={styles.modalTextHeader}>You got it</Text>
            <Text style={styles.modalText}>
              Your notification settings have been updated.
            </Text>
            <Pressable style={[styles.button]} onPress={handleClose}>
              <Text style={styles.buttonText}>OK</Text>
            </Pressable>
          </View>
        </View>
      </Modal>
    </View>
  );
};

const styles = StyleSheet.create({
  centeredViewYellow: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 0,
    paddingTop: '55%',
    backgroundColor: '#000000bf',
    opacity: 1,
  },
  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 0,
    backgroundColor: 'white',
    opacity: 1,
    paddingRight: '5.35%',
    paddingLeft: '5.35%',
  },
  modalContainer: {
    width: '100%',
    marginTop: '50%',
    borderRadius: 20,
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    opacity: 1,
  },
  modalView: {
    width: '100%',
    marginTop: 20,
    backgroundColor: 'white',
    borderRadius: 20,
    paddingTop: 35,
    paddingRight: '5.35%',
    paddingBottom: 35,
    paddingLeft: '5.35%',
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    height: '100%',
    opacity: 1,
  },
  button: {
    backgroundColor: '#EB3E26',
    borderRadius: 50,
    padding: 10,
    paddingBottom: 14,
    paddingTop: 14,
    textAlign: 'center',
    width: '100%',
  },
  buttonText: {
    color: 'white',
    textAlign: 'center',
    fontFamily: 'BebasNeue_400Regular',
    fontSize: 18,
  },
  modalText: {
    marginBottom: 35,
    textAlign: 'center',
    fontFamily: 'Poppins_400Regular',
    fontSize: 14,
    color: '#7F868C',
  },
  modalTextHeader: {
    marginBottom: 15,
    textAlign: 'center',
    fontFamily: 'Poppins_600SemiBold',
    fontSize: 20,
    color: '#191F32',
  },
});
