export const Validate = async (Variables, setGlobalVariableValue) => {
  const authHeaders = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-Knack-Application-Id': Variables.APP_ID,
    Authorization: Variables.AUTH_KNACK_TOKEN,
  };

  try {
    const checkPayFreezeStatus = await fetch(
      `https://api.knack.com/v1/pages/scene_1144/views/view_2126/records/${Variables.AUTH_KNACK_USER_ID}`,
      {
        method: 'GET',
        headers: authHeaders,
      }
    );

    const data = await checkPayFreezeStatus.json();
    const currentPayFreezeStatus = data.field_2425_raw;

    if (!Variables.payFreeze === currentPayFreezeStatus) {
      console.log('Payfreeze status needs updating.');
      setGlobalVariableValue({
        key: 'payFreeze',
        value: currentPayFreezeStatus,
      });
    }
  } catch (error) {
    console.error('ERROR BLOCK: validatePayFreezeStatus: ', error);
    return;
  }
};
