import React from 'react';
import * as Sync from '../custom-files/Sync';

const saveDevicePushToken = async (
  Variables,
  setGlobalVariableValue,
  devicePushToken
) => {
  if (!devicePushToken) {
    console.log(
      'Saving expo push token STOPPED. Unknown Fetch Token ID.Fetched Token ID: ',
      devicePushToken
    );
    return;
  }

  console.log(
    'Saving expo push token to knack. Fetched Token ID: ',
    devicePushToken
  );

  const {
    existingPushTokens,
    APP_ID,
    AUTH_KNACK_USER_ID,
    AUTH_KNACK_TOKEN,
    DEVICE_PUSH_TOKEN,
  } = Variables;

  if (!existingPushTokens) {
    // fetch tokens from knack
    console.log('// fetch tokens from knack');
  }

  if (existingPushTokens.includes(devicePushToken)) {
    return;
  }

  let pushTokensToSave = `"${devicePushToken}"`;

  if (existingPushTokens) {
    pushTokensToSave += `, ${existingPushTokens}`;
  }

  const API_BASE_URL = 'https://api.knack.com/v1';
  const PAGE_ID = 'scene_1130';
  const VIEW_ID = 'view_2105';

  const data = {
    field_2430: pushTokensToSave,
  };

  const url = `${API_BASE_URL}/pages/${PAGE_ID}/views/${VIEW_ID}/records/${AUTH_KNACK_USER_ID}`;

  const requestOptions = {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-Knack-Application-Id': APP_ID,
      Authorization: AUTH_KNACK_TOKEN,
    },
    body: JSON.stringify(data),
  };

  try {
    const savePushTokenToKnack = await fetch(url, requestOptions);
    const savePushTokenToKnackResponse = await savePushTokenToKnack.json();

    console.log(
      'savePushTokenToKnackResponse === ',
      savePushTokenToKnackResponse
    );

    if (!savePushTokenToKnack.ok) {
      throw new Error(
        `Error! status: ${savePushTokenToKnackResponse.errors[0].message}`
      );
    }

    const validTokens =
      savePushTokenToKnackResponse.records?.field_2430_raw ??
      savePushTokenToKnackResponse.record?.field_2430_raw;

    setGlobalVariableValue({
      key: 'DEVICE_PUSH_TOKEN',
      value: devicePushToken,
    });

    setGlobalVariableValue({
      key: 'existingPushTokens',
      value: validTokens,
    });
  } catch (error) {
    console.error('Error:', error);

    setGlobalVariableValue({
      key: 'DEVICE_PUSH_TOKEN',
      value: '',
    });
  }
};

export default saveDevicePushToken;
