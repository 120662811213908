import React from 'react';
import * as GlobalVariables from '../config/GlobalVariableContext';
import * as Track from '../custom-files/Track';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  Button,
  SVG,
  ScreenContainer,
  Spacer,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { FlashList } from '@shopify/flash-list';
import {
  ActivityIndicator,
  ImageBackground,
  ScrollView,
  Text,
  View,
} from 'react-native';

const PoolAccessSettingsScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [myPools, setMyPools] = React.useState('');
  const [selectedTravelRange, setSelectedTravelRange] = React.useState('');
  React.useEffect(() => {
    Track.onEnterScreen(
      'PoolAccessSettingsScreen',
      Variables,
      setGlobalVariableValue,
      props.navigation.navigate,
      props?.route?.params?.skipAuth
    );
  }, []);
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      setGlobalVariableValue({
        key: 'empty',
        value: Constants['empty'],
      });
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={false}
      hasTopSafeArea={false}
    >
      {/* View Top Navigation - Subtitle */}
      <View style={StyleSheet.applyWidth({ width: '100%' }, dimensions.width)}>
        {/* Spacer Top Nav */}
        <Spacer bottom={0} left={0} right={0} top={44} />
        {/* View Row Top Nav Container */}
        <View
          style={StyleSheet.applyWidth(
            {
              alignContent: 'center',
              alignItems: 'center',
              flexDirection: 'row',
              justifyContent: 'space-between',
              paddingBottom: 22,
              paddingLeft: '5.35%',
              paddingRight: '5.35%',
              paddingTop: 14,
            },
            dimensions.width
          )}
        >
          {/* View Back Arrow Container */}
          <View
            style={StyleSheet.applyWidth(
              { height: 24, width: 24 },
              dimensions.width
            )}
          >
            <Touchable
              onPress={() => {
                try {
                  navigation.goBack();
                } catch (err) {
                  console.error(err);
                }
              }}
              style={StyleSheet.applyWidth(
                { height: '100%', width: '100%' },
                dimensions.width
              )}
            >
              {/* Icon Back Arrow */}
              <SVG
                source={Constants['uiBackArrow']}
                style={StyleSheet.applyWidth(
                  { height: '100%', width: '100%' },
                  dimensions.width
                )}
              />
            </Touchable>
          </View>
          {/* View Page Title Container */}
          <View
            style={StyleSheet.applyWidth(
              { justifyContent: 'center' },
              dimensions.width
            )}
          >
            {/* Title */}
            <Text
              accessible={true}
              style={StyleSheet.applyWidth(
                {
                  color: theme.colors.text.strong,
                  fontFamily: 'Poppins_600SemiBold',
                },
                dimensions.width
              )}
            >
              {'Pool Access Settings'}
            </Text>
          </View>
          {/* View Spacer */}
          <View
            style={StyleSheet.applyWidth(
              { height: 10, justifyContent: 'center', width: 10 },
              dimensions.width
            )}
          />
        </View>
      </View>

      <ScrollView
        bounces={true}
        horizontal={false}
        keyboardShouldPersistTaps={'never'}
        nestedScrollEnabled={false}
        showsHorizontalScrollIndicator={true}
        showsVerticalScrollIndicator={true}
      >
        {/* View Body Container */}
        <View
          style={StyleSheet.applyWidth(
            { paddingLeft: '5.35%', paddingRight: '5.35%' },
            dimensions.width
          )}
        >
          {/* View Row Into Instructions */}
          <View
            style={StyleSheet.applyWidth(
              { paddingBottom: 25 },
              dimensions.width
            )}
          >
            {/* View Text Container */}
            <View>
              <Text
                accessible={true}
                style={StyleSheet.applyWidth(
                  {
                    color: palettes.App['App Text 2ndary 7F868C'],
                    fontFamily: 'Poppins_400Regular',
                  },
                  dimensions.width
                )}
              >
                {
                  'Sunsational Swim School accepts clients in your area who do not have a pool. You can get matched with these clients and earn more money if you have access to a pool where you can teach lessons.'
                }
              </Text>
            </View>
          </View>
          {/* My Pools Title */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'baseline',
                flexDirection: 'row',
                marginBottom: 26,
              },
              dimensions.width
            )}
          >
            {/* H1 */}
            <Text
              accessible={true}
              style={StyleSheet.applyWidth(
                {
                  color: palettes.App['App Black 191F32'],
                  fontFamily: 'BebasNeue_400Regular',
                  fontSize: 24,
                  marginRight: 4,
                },
                dimensions.width
              )}
            >
              {'My'}
            </Text>
            {/* H1 Accent */}
            <Text
              accessible={true}
              style={StyleSheet.applyWidth(
                {
                  color: palettes.App['App Red EB3E26'],
                  fontFamily: 'LeckerliOne_400Regular',
                  fontSize: 22,
                },
                dimensions.width
              )}
            >
              {'Pools'}
            </Text>
          </View>
          {/* View No Pool Yet Container */}
          <>
            {!(Constants['gSiPools']?.length === 0) ? null : (
              <View>
                <Text
                  accessible={true}
                  style={StyleSheet.applyWidth(
                    {
                      color: palettes.App['App Black 191F32'],
                      fontFamily: 'Poppins_600SemiBold',
                      fontSize: 14,
                      paddingBottom: 18,
                      textAlign: 'center',
                    },
                    dimensions.width
                  )}
                >
                  {"Looks like you don't have\nany pools yet"}
                </Text>
                {/* Image Container */}
                <View
                  style={StyleSheet.applyWidth(
                    { alignItems: 'center' },
                    dimensions.width
                  )}
                >
                  <SVG
                    source={
                      'https://sss-app-graphics.s3.us-west-1.amazonaws.com/drawings/icon_starfish_umbrella.svg'
                    }
                    style={StyleSheet.applyWidth(
                      { height: 100, width: 100 },
                      dimensions.width
                    )}
                  />
                </View>
              </View>
            )}
          </>
          {/* View My Pools Container */}
          <View>
            {/* View Pools List Container */}
            <View>
              <FlashList
                data={Constants['gSiPools']}
                estimatedItemSize={50}
                horizontal={false}
                inverted={false}
                keyExtractor={(flashListData, index) =>
                  flashListData?.id ?? flashListData?.uuid ?? index.toString()
                }
                listKey={'5gXbAo80'}
                numColumns={1}
                onEndReachedThreshold={0.5}
                renderItem={({ item, index }) => {
                  const flashListData = item;
                  return (
                    <Touchable
                      onPress={() => {
                        try {
                          navigation.navigate('AddPoolScreen', {
                            longitude: flashListData?.field_1044_raw?.longitude,
                            instructions: flashListData?.field_1133_raw,
                            latitude: flashListData?.field_1044_raw?.latitude,
                            poolPhoto: flashListData?.field_2393_raw?.url,
                            availability: flashListData?.field_1046_raw,
                            prevState: flashListData,
                            state: flashListData?.field_1044_raw?.state,
                            street2: flashListData?.field_1044_raw?.street2,
                            zip: flashListData?.field_1044_raw?.zip,
                            pool_id: flashListData?.id,
                            street1: flashListData?.field_1044_raw?.street,
                            poolType: flashListData?.field_1045_raw,
                            city: flashListData?.field_1044_raw?.city,
                          });
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                    >
                      {/* View Pool One */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            borderBottomWidth: 1,
                            borderColor: palettes.App['App Grey Inputs E2E2E2'],
                            borderLeftWidth: 1,
                            borderRadius: 16,
                            borderRightWidth: 1,
                            borderTopWidth: 1,
                            marginBottom: 25,
                            paddingBottom: 16,
                            paddingLeft: 16,
                            paddingRight: 16,
                            paddingTop: 16,
                          },
                          dimensions.width
                        )}
                      >
                        {/* View Content Container */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignContent: 'center',
                              alignItems: 'center',
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                            },
                            dimensions.width
                          )}
                        >
                          {/* View Main Content Container */}
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: 'center',
                                flexDirection: 'row',
                                flexWrap: 'wrap',
                              },
                              dimensions.width
                            )}
                          >
                            {/* View Pool Image Container */}
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  borderRadius: 8,
                                  height: 64,
                                  marginRight: 16,
                                  overflow: 'hidden',
                                  width: 64,
                                },
                                dimensions.width
                              )}
                            >
                              <ImageBackground
                                resizeMode={'cover'}
                                source={{
                                  uri: `${flashListData?.field_2393_raw?.thumb_url}`,
                                }}
                                style={StyleSheet.applyWidth(
                                  { height: '100%', width: '100%' },
                                  dimensions.width
                                )}
                              />
                              {/* Placeholder */}
                              <>
                                {flashListData?.field_2393_raw
                                  ?.thumb_url ? null : (
                                  <SVG
                                    source={Constants['uiLogoRound']}
                                    style={StyleSheet.applyWidth(
                                      { height: '100%', width: '100%' },
                                      dimensions.width
                                    )}
                                  />
                                )}
                              </>
                            </View>
                            {/* View Text Container */}
                            <View
                              style={StyleSheet.applyWidth(
                                { flexWrap: 'nowrap', maxWidth: '80%' },
                                dimensions.width
                              )}
                            >
                              {/* Text Pool Name */}
                              <Text
                                accessible={true}
                                style={StyleSheet.applyWidth(
                                  {
                                    color: palettes.App['App Black 191F32'],
                                    fontFamily: 'Poppins_700Bold',
                                    fontSize: 14,
                                  },
                                  dimensions.width
                                )}
                                textBreakStrategy={'highQuality'}
                              >
                                {flashListData?.field_1044_raw?.street}
                              </Text>
                              {/* Text Pool Type */}
                              <Text
                                accessible={true}
                                style={StyleSheet.applyWidth(
                                  {
                                    color:
                                      palettes.App['App Text 2ndary 7F868C'],
                                    fontFamily: 'Poppins_400Regular',
                                  },
                                  dimensions.width
                                )}
                              >
                                {flashListData?.field_1045_raw}
                              </Text>
                            </View>
                          </View>
                          {/* View Edit Icon Container */}
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                height: 24,
                                justifyContent: 'center',
                                width: 24,
                              },
                              dimensions.width
                            )}
                          >
                            <SVG
                              source={
                                'https://sss-app-graphics.s3.us-west-1.amazonaws.com/icons/icon_edit_black.svg'
                              }
                              style={StyleSheet.applyWidth(
                                { height: '100%', width: '100%' },
                                dimensions.width
                              )}
                            />
                          </View>
                        </View>
                      </View>
                    </Touchable>
                  );
                }}
                showsHorizontalScrollIndicator={true}
                showsVerticalScrollIndicator={true}
                contentContainerStyle={StyleSheet.applyWidth(
                  { flex: 1 },
                  dimensions.width
                )}
              />
            </View>
          </View>
        </View>
        {/* View Bottom Button Spacer */}
        <View>
          <Spacer left={8} right={8} bottom={70} top={70} />
        </View>
      </ScrollView>
      {/* View Bottom Anchor */}
      <View
        style={StyleSheet.applyWidth(
          {
            backgroundColor: palettes.App['Custom Color'],
            bottom: 0,
            paddingLeft: '5.35%',
            paddingRight: '5.35%',
            position: 'absolute',
            width: '100%',
          },
          dimensions.width
        )}
      >
        {/* View Button Container */}
        <View
          style={StyleSheet.applyWidth(
            { alignItems: 'center', marginBottom: 16, width: '100%' },
            dimensions.width
          )}
        >
          {/* Add Pool */}
          <Button
            iconPosition={'left'}
            onPress={() => {
              try {
                navigation.navigate('AddPoolScreen');
              } catch (err) {
                console.error(err);
              }
            }}
            style={StyleSheet.applyWidth(
              {
                backgroundColor: palettes.App['App Red EB3E26'],
                borderRadius: 50,
                fontFamily: 'BebasNeue_400Regular',
                fontSize: 18,
                paddingBottom: 14,
                paddingTop: 14,
                textAlign: 'center',
                width: '100%',
              },
              dimensions.width
            )}
            title={'Add Pool'}
          />
          {/* View Activity Indicator */}
          <>
            {!Constants['is_loading'] ? null : (
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    backgroundColor: palettes.App['App Red EB3E26'],
                    borderRadius: 50,
                    marginTop: -50,
                    opacity: 0.8,
                    paddingBottom: 7,
                    paddingTop: 7,
                    width: '100%',
                  },
                  dimensions.width
                )}
              >
                <ActivityIndicator
                  animating={true}
                  hidesWhenStopped={true}
                  size={'small'}
                  color={palettes.App['Custom Color']}
                  style={StyleSheet.applyWidth(
                    { height: 36, width: 36 },
                    dimensions.width
                  )}
                />
              </View>
            )}
          </>
        </View>
        <Spacer bottom={34} left={0} right={0} top={0} />
      </View>
    </ScreenContainer>
  );
};

export default withTheme(PoolAccessSettingsScreen);
