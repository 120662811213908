import React, { useState } from 'react';
import { View, Text, Pressable, StyleSheet } from 'react-native';
import * as GlobalVariableContext from '../config/GlobalVariableContext';

export function availableDays({
  availableDaysClient,
  availableDaysInstructor,
  filterDays,
}) {
  const allDays =
    'Mondays Tuesdays Wednesdays Thursdays Fridays Saturdays Sundays';

  availableDaysClient
    ? (availableDaysClient = availableDaysClient)
    : (availableDaysClient = '');
  availableDaysInstructor
    ? (availableDaysInstructor = availableDaysInstructor)
    : (availableDaysInstructor = allDays);
  filterDays ? (filterDays = filterDays) : (filterDays = allDays);

  const daysOfWeek = [
    {
      id: 1,
      nameShort: 'MON',
      isAvailable: false,
      name: 'Mondays',
    },
    {
      id: 2,
      nameShort: 'TUE',
      isAvailable: false,
      name: 'Tuesdays',
    },
    {
      id: 3,
      nameShort: 'WED',
      isAvailable: false,
      name: 'Wednesdays',
    },
    {
      id: 4,
      nameShort: 'THU',
      isAvailable: false,
      name: 'Thursdays',
    },
    {
      id: 5,
      nameShort: 'FRI',
      isAvailable: false,
      name: 'Fridays',
    },
    {
      id: 6,
      nameShort: 'SAT',
      isAvailable: false,
      name: 'Saturdays',
    },
    {
      id: 0,
      nameShort: 'SUN',
      isAvailable: false,
      name: 'Sundays',
    },
  ];

  daysOfWeek.map(day => {
    if (
      availableDaysClient.includes(day.name) &&
      availableDaysInstructor.includes(day.name) &&
      filterDays.includes(day.name)
    ) {
      day.isAvailable = true;
    }
  });

  return (
    <View style={styles.container}>
      {daysOfWeek.map(day => {
        return (
          <View
            style={[
              styles.item,
              day.isAvailable ? styles.available : styles.unavailable,
            ]}
            key={day.id}
          >
            <Text
              style={[
                day.isAvailable
                  ? styles.availableTextStyle
                  : styles.unavailableTextStyle,
                { width: '100%', textAlign: 'center' },
              ]}
            >
              {day.nameShort}
            </Text>
          </View>
        );
      })}
    </View>
  );
}

export function selectDaysForFilter({
  monday,
  setMonday,
  tuesday,
  setTuesday,
  wednesday,
  setWednesday,
  thursday,
  setThursday,
  friday,
  setFriday,
  saturday,
  setSaturday,
  sunday,
  setSunday,
  any,
  setAny,
  filterDays,
}) {
  const daysOfWeek = [
    {
      id: 1,
      nameShort: 'MON',
      isAvailable: monday,
      changeAvailability: setMonday,
      name: 'Mondays',
    },
    {
      id: 2,
      nameShort: 'TUE',
      isAvailable: tuesday,
      changeAvailability: setTuesday,
      name: 'Tuesdays',
    },
    {
      id: 3,
      nameShort: 'WED',
      isAvailable: wednesday,
      changeAvailability: setWednesday,
      name: 'Wednesdays',
    },
    {
      id: 4,
      nameShort: 'THU',
      isAvailable: thursday,
      changeAvailability: setThursday,
      name: 'Thursdays',
    },
    {
      id: 5,
      nameShort: 'FRI',
      isAvailable: friday,
      changeAvailability: setFriday,
      name: 'Fridays',
    },
    {
      id: 6,
      nameShort: 'SAT',
      isAvailable: saturday,
      changeAvailability: setSaturday,
      name: 'Saturdays',
    },
    {
      id: 0,
      nameShort: 'SUN',
      isAvailable: sunday,
      changeAvailability: setSunday,
      name: 'Sundays',
    },
    {
      id: 7,
      nameShort: 'ANY DAY',
      isAvailable: any,
      changeAvailability: function (input) {
        if (input) {
          daysOfWeek.map(day => {
            if (day.name !== 'Any') {
              day.changeAvailability(false);
            }
          });
        }
        setAny(input);
      },
      name: 'Any',
    },
  ];

  const handlePress = async (day, daysOfWeek) => {
    if (day.name !== 'Any' && any) {
      setAny(false);
    }
    day.changeAvailability(!day.isAvailable);
  };

  return (
    <View style={stylesFilter.container}>
      {daysOfWeek.map(day => {
        return (
          <Pressable
            onPress={() => {
              handlePress(day, daysOfWeek);
            }}
            style={[
              stylesFilter.item,
              day.isAvailable
                ? stylesFilter.available
                : stylesFilter.unavailable,
            ]}
            key={day.id}
          >
            <Text
              style={[
                day.isAvailable
                  ? stylesFilter.availableTextStyle
                  : stylesFilter.unavailableTextStyle,
                { width: '100%', textAlign: 'center' },
              ]}
            >
              {day.nameShort}
            </Text>
          </Pressable>
        );
      })}
    </View>
  );
}

export function selectNumberOfLessonsPerWeek({ maxLessons, setMaxLessons }) {
  const [one, setOne] = useState(false);
  const [two, setTwo] = useState(false);
  const [three, setThree] = useState(false);
  const [four, setFour] = useState(false);
  const [five, setFive] = useState(false);
  const [six, setSix] = useState(false);
  const [seven, setSeven] = useState(false);

  const numberOfLessonsPerWeek = [
    {
      id: 1,
      nameShort: '1',
      isAvailable: one,
      changeAvailability: setOne,
    },
    {
      id: 2,
      nameShort: '2',
      isAvailable: two,
      changeAvailability: setTwo,
    },
    {
      id: 3,
      nameShort: '3',
      isAvailable: three,
      changeAvailability: setThree,
    },
    {
      id: 4,
      nameShort: '4',
      isAvailable: four,
      changeAvailability: setFour,
    },
    {
      id: 5,
      nameShort: '5',
      isAvailable: five,
      changeAvailability: setFive,
    },
    {
      id: 6,
      nameShort: '6',
      isAvailable: six,
      changeAvailability: setSix,
    },
    {
      id: 7,
      nameShort: '7',
      isAvailable: seven,
      changeAvailability: setSeven,
    },
  ];

  // if (maxLessons) {
  //   console.log('maxLessons === ', maxLessons)
  //   numberOfLessonsPerWeek.map(quantity => {
  //     if (quantity.id <= maxLessons) {
  //       quantity.changeAvailability(true)
  //     }
  //   });
  // }

  const handlePress = async limit => {
    // numberOfLessonsPerWeek.map(quantity => {
    //   if (quantity.id <= limit) {
    //     quantity.changeAvailability(true);
    //   } else {
    //     quantity.changeAvailability(false);
    //   }
    // })
    setMaxLessons(limit);
  };

  return (
    <View style={stylesFilter.container}>
      {numberOfLessonsPerWeek.map(limit => {
        return (
          <Pressable
            onPress={() => {
              handlePress(limit.id);
            }}
            style={[
              stylesFilter.item,
              stylesFilter.lessonsLimit,
              limit.id <= maxLessons
                ? stylesFilter.available
                : stylesFilter.unavailable,
            ]}
            key={limit.id}
          >
            <Text
              style={[
                limit.isAvailable
                  ? stylesFilter.availableTextStyle
                  : stylesFilter.unavailableTextStyle,
                { width: '100%', textAlign: 'center' },
              ]}
            >
              {limit.nameShort}
            </Text>
          </Pressable>
        );
      })}
    </View>
  );
}

export function TimesAvailable({
  availableTimesClient, // string
  availableTimesInstructor, // string
}) {
  const allTimes =
    '6AM - 8AM 8AM - 10AM 10AM - 12PM 12PM - 2PM 2PM - 4PM 4PM - 6PM 6PM - 8PM';

  availableTimesClient
    ? (availableTimesClient = availableTimesClient)
    : (availableTimesClient = '');
  availableTimesInstructor
    ? (availableTimesInstructor = availableTimesInstructor)
    : (availableTimesInstructor = '');

  const timeOptions = [
    {
      id: 0,
      range: '6AM - 8AM',
      clientAvailable: false,
      instructorAvailable: false,
      clientAndInstructorAvailable: false,
    },
    {
      id: 1,
      range: '8AM - 10AM',
      clientAvailable: false,
      instructorAvailable: false,
      clientAndInstructorAvailable: false,
    },
    {
      id: 2,
      range: '10AM - 12PM',
      clientAvailable: false,
      instructorAvailable: false,
      clientAndInstructorAvailable: false,
    },
    {
      id: 3,
      range: '12PM - 2PM',
      clientAvailable: false,
      instructorAvailable: false,
      clientAndInstructorAvailable: false,
    },
    {
      id: 4,
      range: '2PM - 4PM',
      clientAvailable: false,
      instructorAvailable: false,
      clientAndInstructorAvailable: false,
    },
    {
      id: 5,
      range: '4PM - 6PM',
      clientAvailable: false,
      instructorAvailable: false,
      clientAndInstructorAvailable: false,
    },
    {
      id: 6,
      range: '6PM - 8PM',
      clientAvailable: false,
      instructorAvailable: false,
      clientAndInstructorAvailable: false,
    },
  ];

  if (availableTimesClient.length < 1) {
    return (
      <Text
        style={[
          stylesTimesAvailable.item,
          styles.unavailable,
          stylesTimesAvailable.unavailableText,
        ]}
      >
        Not available
      </Text>
    );
  }

  if (availableTimesClient.length > 0) {
    timeOptions.map(time => {
      if (availableTimesClient.includes(time.range)) {
        time.clientAvailable = true;
      }

      if (availableTimesInstructor.includes(time.range)) {
        time.instructorAvailable = true;
      }

      if (
        availableTimesClient.includes(time.range) &&
        availableTimesInstructor.includes(time.range)
      ) {
        time.clientAndInstructorAvailable = true;
      }
    });
  }

  return (
    <View style={stylesTimesAvailable.container}>
      {timeOptions.map(time => {
        return (
          <View
            style={[
              stylesTimesAvailable.item,
              time.clientAndInstructorAvailable
                ? stylesTimesAvailable.clientAndInstructorAvailable
                : time.clientAvailable
                ? stylesTimesAvailable.clientAvailable
                : stylesTimesAvailable.unavailable,
            ]}
            key={timeOptions.id}
          >
            <Text
              style={[
                time.clientAndInstructorAvailable
                  ? stylesTimesAvailable.clientAndInstructorAvailableText
                  : time.clientAvailable
                  ? stylesTimesAvailable.clientAvailableText
                  : stylesTimesAvailable.unavailableText,
                { width: '100%', textAlign: 'center' },
              ]}
            >
              {time.range}
            </Text>
          </View>
        );
      })}
    </View>
  );
}

export function placeMapMarker({ title }) {
  return (
    <View style={stylesMarker.marker}>
      <Text style={markerTitle}>{title}</Text>
    </View>
  );
}

export function determinePackageStatus(
  packageStatus,
  currentlyBookedSI,
  instructorRequestStatus,
  loggedInSI
) {
  if (packageStatus === 'Unmatched') {
    if (instructorRequestStatus === 'Requested') {
      return 'Requested';
    }
    if (instructorRequestStatus === 'Declined') {
      return 'Declined';
    }
    return 'Available';
  }

  if (packageStatus === 'Matched') {
    if (currentlyBookedSI === loggedInSI) {
      return 'Matched';
    }
    return 'Unavailable';
  }

  return 'Unavailable';
}

const stylesMarker = StyleSheet.create({
  marker: {
    padding: '8 16',
    backgroundColor: 'white',
    borderRadius: 50,
  },
  markerTitle: {
    fontFamily: 'Poppins_500Medium',
    fontWeight: '500',
    fontSize: 12,
  },
});

const stylesFilter = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  item: {
    borderBottomWidth: 1,
    borderLeftWidth: 1,
    borderRadius: 50,
    borderRightWidth: 1,
    borderTopWidth: 1,
    fontFamily: 'Poppins_500Medium',
    fontWeight: '500',
    fontSize: 12,
    marginRight: '1.5%',
    marginBottom: '2.5%',
    paddingBottom: 10,
    paddingTop: 10,
    paddingRight: 16,
    paddingLeft: 16,
    textAlign: 'center',
    minWidth: '21%',
  },
  lessonsLimit: {
    minWidth: 0,
    flex: 1,
  },
  available: {
    backgroundColor: '#F4A100',
    borderColor: '#F4A100',
    color: 'white',
  },
  availableTextStyle: {
    color: 'white',
  },
  unavailable: {
    backgroundColor: 'white',
    borderColor: '#E2E2E2',
    color: '#191F32',
  },
  unavailableTextStyle: {
    color: '#191F32',
  },
});
const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'row',
  },
  item: {
    borderBottomWidth: 1,
    borderLeftWidth: 1,
    borderRadius: 8,
    borderRightWidth: 1,
    borderTopWidth: 1,
    fontFamily: 'Poppins_500Medium',
    fontWeight: '500',
    fontSize: 12,
    marginRight: '1.5%',
    marginBottom: '2.5%',
    paddingBottom: 6,
    paddingTop: 6,
    textAlign: 'center',
    flex: 1,
    flexWrap: 'wrap',
  },
  available: {
    backgroundColor: '#EDF4FB',
    borderColor: '#2C9AD9',
    color: '#2C9AD9',
  },
  availableTime: {
    backgroundColor: '#EDF4FB',
    borderColor: '#2C9AD9',
    color: '#2C9AD9',
    width: '31%',
    marginRight: '1.5%',
    marginBottom: '2.5%',
  },
  availableTextStyle: {
    color: '#2C9AD9',
  },
  unavailable: {
    backgroundColor: '#EDEDEF',
    borderColor: '#E2E2E2',
  },
  unavailableTime: {
    backgroundColor: '#EDEDEF',
    borderColor: '#E2E2E2',
    width: '31%',
    marginRight: '1.5%',
    marginBottom: '2.5%',
  },
  unavailableTextStyle: {
    color: '#7F868C',
  },
});
const stylesTimesAvailable = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  item: {
    borderBottomWidth: 1,
    borderLeftWidth: 1,
    borderRadius: 8,
    borderRightWidth: 1,
    borderTopWidth: 1,
    fontFamily: 'Poppins_500Medium',
    fontWeight: '500',
    fontSize: 12,
    paddingBottom: 6,
    paddingTop: 6,
    textAlign: 'center',
    width: '31%',
    marginRight: '1.5%',
    marginBottom: '2.5%',
    flexWrap: 'wrap',
  },
  clientAndInstructorAvailable: {
    backgroundColor: '#FCEFD4',
    borderColor: '#F8A00A',
  },
  clientAndInstructorAvailableText: {
    color: '#F8A00A',
  },
  clientAvailable: {
    backgroundColor: '#EDF4FB',
    borderColor: '#2C9AD9',
  },
  clientAvailableText: {
    color: '#2C9AD9',
  },
  unavailable: {
    display: 'none',
    backgroundColor: '#EDEDEF',
    borderColor: '#E2E2E2',
  },
  unavailableText: {
    color: '#7F868C',
  },
});
