export const ValidateTermsAgreement = async (nav, Variables) => {
  console.log('Validating Terms and Conditions Agreement is up to date.');
  if (!Variables.AUTH_KNACK_TOKEN) {
    console.log('No Auth token. Aborting.');
    return;
  }
  const authHeaders = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-Knack-Application-Id': Variables.APP_ID,
    Authorization: Variables.AUTH_KNACK_TOKEN,
  };

  try {
    const validate = await fetch(
      `https://api.knack.com/v1/pages/scene_1148/views/view_2162/records`,
      {
        method: 'GET',
        headers: authHeaders,
      }
    );

    const data = await validate.json();
    const mostCurrentTermsAndConditions = data.records[0].id;
    const termsText = data.records[0].field_2455_raw;
    const termsDate = data.records[0].field_2453;
    const termsName = data.records[0].field_2452;

    if (Variables.APP_TERMS_VERSION.id !== mostCurrentTermsAndConditions) {
      nav('UpdateTermsAgreementRequiredScreen', {
        termsText: termsText,
        termsDate: termsDate,
        termsKnackID: mostCurrentTermsAndConditions,
        termsName: termsName,
      });
      return;
    }

    return true;
  } catch (error) {
    console.error('ERROR BLOCK: ValidateTermsAndConditionsAgreement: ', error);
    return;
  }
};
