import React, { useState } from 'react';
import { View, Text, Pressable, StyleSheet } from 'react-native';
import * as GlobalVariableContext from '../config/GlobalVariableContext';

export function Days({
  mondays,
  setMondays,
  tuesdays,
  setTuesdays,
  wednesdays,
  setWednesdays,
  thursdays,
  setThursdays,
  fridays,
  setFridays,
  saturdays,
  setSaturdays,
  sundays,
  setSundays,
}) {
  const daysOfWeek = [
    {
      id: 1,
      nameShort: 'MON',
      isAvailable: mondays,
      changeAvailability: setMondays,
      name: 'Mondays',
    },
    {
      id: 2,
      nameShort: 'TUE',
      isAvailable: tuesdays,
      changeAvailability: setTuesdays,
      name: 'Tuesdays',
    },
    {
      id: 3,
      nameShort: 'WED',
      isAvailable: wednesdays,
      changeAvailability: setWednesdays,
      name: 'Wednesdays',
    },
    {
      id: 4,
      nameShort: 'THU',
      isAvailable: thursdays,
      changeAvailability: setThursdays,
      name: 'Thursdays',
    },
    {
      id: 5,
      nameShort: 'FRI',
      isAvailable: fridays,
      changeAvailability: setFridays,
      name: 'Fridays',
    },
    {
      id: 6,
      nameShort: 'SAT',
      isAvailable: saturdays,
      changeAvailability: setSaturdays,
      name: 'Saturdays',
    },
    {
      id: 0,
      nameShort: 'SUN',
      isAvailable: sundays,
      changeAvailability: setSundays,
      name: 'Sundays',
    },
  ];

  const handlePress = async (day, daysOfWeek) => {
    day.changeAvailability(!day.isAvailable);
  };

  return (
    <View style={styles.container}>
      {daysOfWeek.map(day => {
        return (
          <Pressable
            onPress={() => {
              handlePress(day, daysOfWeek);
            }}
            style={[
              styles.item,
              day.isAvailable ? styles.available : styles.unavailable,
            ]}
            key={day.id}
          >
            <Text
              style={[
                day.isAvailable
                  ? styles.availableTextStyle
                  : styles.unavailableTextStyle,
                { width: '100%', textAlign: 'center' },
              ]}
            >
              {day.nameShort}
            </Text>
          </Pressable>
        );
      })}
    </View>
  );
}

export function WeekdayTimes({
  avail6amTo8am,
  setAvail6amTo8am,
  avail8amTo10am,
  setAvail8amTo10am,
  avail10amTo12pm,
  setAvail10amTo12pm,
  avail12pmTo2pm,
  setAvail12pmTo2pm,
  avail2pmTo4pm,
  setAvail2pmTo4pm,
  avail4pmTo6pm,
  setAvail4pmTo6pm,
  avail6pmTo8pm,
  setAvail6pmTo8pm,
}) {
  const availableTimes = [
    {
      id: 0,
      range: '6AM - 8AM',
      isAvailable: avail6amTo8am,
      changeAvailability: setAvail6amTo8am,
    },
    {
      id: 1,
      range: '8AM - 10AM',
      isAvailable: avail8amTo10am,
      changeAvailability: setAvail8amTo10am,
    },
    {
      id: 2,
      range: '10AM - 12PM',
      isAvailable: avail10amTo12pm,
      changeAvailability: setAvail10amTo12pm,
    },
    {
      id: 3,
      range: '12PM - 2PM',
      isAvailable: avail12pmTo2pm,
      changeAvailability: setAvail12pmTo2pm,
    },
    {
      id: 4,
      range: '2PM - 4PM',
      isAvailable: avail2pmTo4pm,
      changeAvailability: setAvail2pmTo4pm,
    },
    {
      id: 5,
      range: '4PM - 6PM',
      isAvailable: avail4pmTo6pm,
      changeAvailability: setAvail4pmTo6pm,
    },
    {
      id: 6,
      range: '6PM - 8PM',
      isAvailable: avail6pmTo8pm,
      changeAvailability: setAvail6pmTo8pm,
    },
  ];

  const handlePress = time => {
    time.changeAvailability(!time.isAvailable);
  };

  return (
    <View style={styles.container}>
      {availableTimes.map(time => {
        return (
          <Pressable
            onPress={() => {
              handlePress(time);
            }}
            style={[
              styles.item,
              time.isAvailable ? styles.availableTime : styles.unavailableTime,
            ]}
            key={time.id}
          >
            <Text
              style={[
                time.isAvailable
                  ? styles.availableTextStyle
                  : styles.unavailableTextStyle,
                { width: '100%', textAlign: 'center' },
              ]}
            >
              {time.range}
            </Text>
          </Pressable>
        );
      })}
    </View>
  );
}

export function WeekendTimes({
  avail6amTo8amWeekend,
  setAvail6amTo8amWeekend,
  avail8amTo10amWeekend,
  setAvail8amTo10amWeekend,
  avail10amTo12pmWeekend,
  setAvail10amTo12pmWeekend,
  avail12pmTo2pmWeekend,
  setAvail12pmTo2pmWeekend,
  avail2pmTo4pmWeekend,
  setAvail2pmTo4pmWeekend,
  avail4pmTo6pmWeekend,
  setAvail4pmTo6pmWeekend,
  avail6pmTo8pmWeekend,
  setAvail6pmTo8pmWeekend,
}) {
  const availableTimes = [
    {
      id: 0,
      range: '6AM - 8AM',
      isAvailable: avail6amTo8amWeekend,
      changeAvailability: setAvail6amTo8amWeekend,
    },
    {
      id: 1,
      range: '8AM - 10AM',
      isAvailable: avail8amTo10amWeekend,
      changeAvailability: setAvail8amTo10amWeekend,
    },
    {
      id: 2,
      range: '10AM - 12PM',
      isAvailable: avail10amTo12pmWeekend,
      changeAvailability: setAvail10amTo12pmWeekend,
    },
    {
      id: 3,
      range: '12PM - 2PM',
      isAvailable: avail12pmTo2pmWeekend,
      changeAvailability: setAvail12pmTo2pmWeekend,
    },
    {
      id: 4,
      range: '2PM - 4PM',
      isAvailable: avail2pmTo4pmWeekend,
      changeAvailability: setAvail2pmTo4pmWeekend,
    },
    {
      id: 5,
      range: '4PM - 6PM',
      isAvailable: avail4pmTo6pmWeekend,
      changeAvailability: setAvail4pmTo6pmWeekend,
    },
    {
      id: 6,
      range: '6PM - 8PM',
      isAvailable: avail6pmTo8pmWeekend,
      changeAvailability: setAvail6pmTo8pmWeekend,
    },
  ];

  const handlePress = time => {
    time.changeAvailability(!time.isAvailable);
  };

  return (
    <View style={styles.container}>
      {availableTimes.map(time => {
        return (
          <Pressable
            onPress={() => {
              handlePress(time);
            }}
            style={[
              styles.item,
              time.isAvailable ? styles.availableTime : styles.unavailableTime,
            ]}
            key={time.id}
          >
            <Text
              style={[
                time.isAvailable
                  ? styles.availableTextStyle
                  : styles.unavailableTextStyle,
                { width: '100%', textAlign: 'center' },
              ]}
            >
              {time.range}
            </Text>
          </Pressable>
        );
      })}
    </View>
  );
}

export function PoolAvailability({
  jan,
  setJan,
  feb,
  setFeb,
  mar,
  setMar,
  apr,
  setApr,
  may,
  setMay,
  jun,
  setJun,
  jul,
  setJul,
  aug,
  setAug,
  sep,
  setSep,
  oct,
  setOct,
  nov,
  setNov,
  dec,
  setDec,
}) {
  const months = [
    {
      id: 0,
      name: 'JAN',
      value: 'January',
      isAvailable: jan,
      changeAvailability: setJan,
    },
    {
      id: 1,
      name: 'FEB',
      isAvailable: feb,
      changeAvailability: setFeb,
    },
    {
      id: 2,
      name: 'MAR',
      isAvailable: mar,
      changeAvailability: setMar,
    },
    {
      id: 3,
      name: 'APR',
      isAvailable: apr,
      changeAvailability: setApr,
    },
    {
      id: 4,
      name: 'MAY',
      isAvailable: may,
      changeAvailability: setMay,
    },
    {
      id: 5,
      name: 'JUNE',
      isAvailable: jun,
      changeAvailability: setJun,
    },
    {
      id: 6,
      name: 'JUL',
      isAvailable: jul,
      changeAvailability: setJul,
    },
    {
      id: 7,
      name: 'AUG',
      isAvailable: aug,
      changeAvailability: setAug,
    },
    {
      id: 8,
      name: 'SEP',
      isAvailable: sep,
      changeAvailability: setSep,
    },
    {
      id: 9,
      name: 'OCT',
      isAvailable: oct,
      changeAvailability: setOct,
    },
    {
      id: 10,
      name: 'NOV',
      isAvailable: nov,
      changeAvailability: setNov,
    },
    {
      id: 11,
      name: 'DEC',
      isAvailable: dec,
      changeAvailability: setDec,
    },
  ];

  const handlePress = month => {
    month.changeAvailability(!month.isAvailable);
  };

  return (
    <View style={styles.container}>
      {months.map(month => {
        return (
          <Pressable
            onPress={() => {
              handlePress(month);
            }}
            style={[
              styles.item,
              month.isAvailable ? styles.available : styles.unavailable,
            ]}
          >
            <Text
              style={[
                month.isAvailable
                  ? styles.availableTextStyle
                  : styles.unavailableTextStyle,
                { width: '100%', textAlign: 'center' },
              ]}
            >
              {month.name}
            </Text>
          </Pressable>
        );
      })}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  item: {
    borderBottomWidth: 1,
    borderLeftWidth: 1,
    borderRadius: 8,
    borderRightWidth: 1,
    borderTopWidth: 1,
    fontFamily: 'Poppins_500Medium',
    fontWeight: 500,
    fontSize: 12,
    marginRight: '1.5%',
    marginBottom: '2.5%',
    paddingBottom: 6,
    paddingTop: 6,
    textAlign: 'center',
    width: '23%',
    flexWrap: 'wrap',
  },
  available: {
    backgroundColor: '#EDF4FB',
    borderColor: '#2C9AD9',
    color: '#2C9AD9',
  },
  availableTime: {
    backgroundColor: '#EDF4FB',
    borderColor: '#2C9AD9',
    color: '#2C9AD9',
    width: '31%',
    marginRight: '1.5%',
    marginBottom: '2.5%',
  },
  availableTextStyle: {
    color: '#2C9AD9',
  },
  unavailable: {
    backgroundColor: '#EDEDEF',
    borderColor: '#E2E2E2',
  },
  unavailableTime: {
    backgroundColor: '#EDEDEF',
    borderColor: '#E2E2E2',
    width: '31%',
    marginRight: '1.5%',
    marginBottom: '2.5%',
  },
  unavailableTextStyle: {
    color: '#7F868C',
  },
});
