import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as IntegromatApi from '../apis/IntegromatApi.js';
import * as KnackAPIApi from '../apis/KnackAPIApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import * as CalcPayDays from '../custom-files/CalcPayDays';
import * as Track from '../custom-files/Track';
import * as convertTime from '../custom-files/convertTime';
import * as validateTermsAgreement from '../custom-files/validateTermsAgreement';
import amplitudeEvents from '../global-functions/amplitudeEvents';
import checkAccountStatus from '../global-functions/checkAccountStatus';
import convertTimeToLocalMachine from '../global-functions/convertTimeToLocalMachine';
import getFirstNameLastInitial from '../global-functions/getFirstNameLastInitial';
import getUniquePackagesFromLessonsList from '../global-functions/getUniquePackagesFromLessonsList';
import getUserInitials from '../global-functions/getUserInitials';
import openMap from '../global-functions/openMap';
import returnFirstInitial from '../global-functions/returnFirstInitial';
import saveDevicePushToken from '../global-functions/saveDevicePushToken';
import useCustomerAvatar from '../global-functions/useCustomerAvatar';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import getPushTokenUtil from '../utils/getPushToken';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  Button,
  Icon,
  IconButton,
  KeyboardAvoidingView,
  Link,
  Pressable,
  SVG,
  ScreenContainer,
  Spacer,
  Surface,
  TextInput,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { FlashList } from '@shopify/flash-list';
import {
  ActivityIndicator,
  ImageBackground,
  Modal,
  Platform,
  ScrollView,
  Text,
  View,
} from 'react-native';
import { Fetch } from 'react-request';

const HomeScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [clientsCount, setClientsCount] = React.useState(0);
  const [feedbackConfirmationMsg, setFeedbackConfirmationMsg] =
    React.useState('');
  const [filterClientsThisYear, setFilterClientsThisYear] = React.useState({
    match: 'and',
    rules: [
      { field: 'field_231', value: 'Paused', operator: 'is not' },
      { field: 'field_54', value: 'Unbooked', operator: 'is not' },
      {
        field: 'field_312',
        range: '2022-12-31T23:00:00.000Z',
        operator: 'is after',
      },
      { field: 'field_312', operator: 'is not blank' },
    ],
  });
  const [filterCurentClients, setFilterCurentClients] = React.useState({});
  const [is_loading_clients, setIs_loading_clients] = React.useState(true);
  const [is_loading_open_jobs, setIs_loading_open_jobs] = React.useState(true);
  const [is_loading_pay, setIs_loading_pay] = React.useState(true);
  const [is_loading_scheduled_jobs, setIs_loading_scheduled_jobs] =
    React.useState(true);
  const [lastIndice, setLastIndice] = React.useState(3);
  const [lessonRecords, setLessonRecords] = React.useState([]);
  const [lessonsForPayNextPeriod, setLessonsForPayNextPeriod] = React.useState(
    []
  );
  const [lessonsForPayThisPeriod, setLessonsForPayThisPeriod] = React.useState(
    []
  );
  const [nextPayDayAmount, setNextPayDayAmount] = React.useState(0);
  const [nextPayDayDate, setNextPayDayDate] = React.useState('');
  const [openJobsCount, setOpenJobsCount] = React.useState(-1);
  const [paidLessons, setPaidLessons] = React.useState([]);
  const [scheduledLessonFilters, setScheduledLessonFilters] = React.useState({
    match: 'and',
    rules: [
      { field: 'field_202', operator: 'is today or after' },
      { field: 'field_202', operator: 'is not blank' },
      { field: 'field_54', value: 'Matched', operator: 'is' },
    ],
  });
  const [scheduledLessonsRowsPerPage, setScheduledLessonsRowsPerPage] =
    React.useState(3);
  const [scheduledLessonsSortField, setScheduledLessonsSortField] =
    React.useState('field_202');
  const [scheduledLessonsSortOrder, setScheduledLessonsSortOrder] =
    React.useState('asc');
  const [showFeedbackConfirmBlock, setShowFeedbackConfirmBlock] =
    React.useState(false);
  const [showFeedbackForm, setShowFeedbackForm] = React.useState(false);
  const [showFeedbackShareButton, setShowFeedbackShareButton] =
    React.useState(true);
  const [showPayEarnings, setShowPayEarnings] = React.useState(false);
  const [showPayEarningsNextPayout, setShowPayEarningsNextPayout] =
    React.useState(false);
  const [showPayEarningsThisPayout, setShowPayEarningsThisPayout] =
    React.useState(true);
  const [showPayEarningsTotal, setShowPayEarningsTotal] = React.useState(false);
  const [showPayEstimateDisclaimer, setShowPayEstimateDisclaimer] =
    React.useState(false);
  const [showPayIcon, setShowPayIcon] = React.useState(false);
  const [showScheduleBanner, setShowScheduleBanner] = React.useState(true);
  const [showScheduleCard, setShowScheduleCard] = React.useState(false);
  const [showViewMoreScheduledButton, setShowViewMoreScheduledButton] =
    React.useState(false);
  const [siFeedbackComment, setSiFeedbackComment] = React.useState('');
  const [slicedUpcomingScheduledLessons, setSlicedUpcomingScheduledLessons] =
    React.useState([]);
  const [thisPayDayAmount, setThisPayDayAmount] = React.useState(0);
  const [thisPayDayDate, setThisPayDayDate] = React.useState('');
  const [totalEarnings, setTotalEarnings] = React.useState(0);
  const [uniquePackages, setUniquePackages] = React.useState([]);
  const [upcomingScheduledLessons, setUpcomingScheduledLessons] =
    React.useState([]);
  const [visibleScheduledLessons, setVisibleScheduledLessons] = React.useState(
    []
  );
  const fixVisibleScheduledLessons = upcomingScheduledLessons => {
    let visibleLessons = upcomingScheduledLessons.slice(0, lastIndice);
    return visibleLessons;
  };

  const closeScheduledLessonsView = () => {
    setShowScheduleCard(false);
    setShowScheduleBanner(true);
    setLastIndice(3);
  };

  const shouldShowViewMoreScheduledButton = endLimit => {
    console.log(
      'upcomingScheduledLessons.length === ',
      upcomingScheduledLessons.length
    );
    console.log('endLimit === ', endLimit);
    upcomingScheduledLessons.length > endLimit
      ? setShowViewMoreScheduledButton(true)
      : setShowViewMoreScheduledButton(false);
  };

  const fixLimitForScheduledLessons = change => {
    let endLimit;

    change ? (endLimit = lastIndice + change) : (endLimit = lastIndice);

    setLastIndice(endLimit);
    return endLimit;
  };

  const selectPayoutPeriod = period => {
    console.log('show pay period: ', period);
    if (period === 'this') {
      setShowPayEarningsThisPayout(true);
      setShowPayEarningsNextPayout(false);
      setShowPayEarningsTotal(false);
    }

    if (period === 'next') {
      setShowPayEarningsNextPayout(true);
      setShowPayEarningsThisPayout(false);
      setShowPayEarningsTotal(false);
    }

    if (period === 'total') {
      setShowPayEarningsTotal(true);
      setShowPayEarningsNextPayout(false);
      setShowPayEarningsThisPayout(false);
    }
  };

  const closeEarningsView = () => {
    setShowPayEarnings(false);
  };

  const shouldShowPayIcon = lessonsForPay => {
    setShowPayIcon(true);

    // setShowPayIcon(false);

    // const {
    //   paidLessonsa,
    //   lessonsForPayThisPerioda,
    //   lessonsForPayNextPerioda,
    // } = lessonsForPay

    // if (paidLessonsa.length || lessonsForPayThisPerioda.length || lessonsForPayNextPerioda.length) {
    //     setShowPayIcon(true)
    // }
  };

  const sortLessonsByScheduleDate = (lessons, order) => {
    const sortedLessons = lessons.sort((a, b) => {
      const dateA = new Date(a.field_202_raw.iso_timestamp);
      const dateB = new Date(b.field_202_raw.iso_timestamp);

      if (order === 'desc') {
        return dateB - dateA;
      } else {
        return dateA - dateB;
      }
    });

    console.log(
      'SORTED LESSONS ================================= ',
      sortedLessons
    );
    return sortedLessons;
  };

  const feedbackConfirmationSequence = () => {
    setShowFeedbackForm(false);
    setShowFeedbackConfirmBlock(true);
    setFeedbackConfirmationMsg('Feedback sent');

    setTimeout(() => {
      setFeedbackConfirmationMsg('Thank you!');

      setTimeout(() => {
        setFeedbackConfirmationMsg('Send more!');
        setTimeout(() => {
          setFeedbackConfirmationMsg('Send mooore!');
          setTimeout(() => {
            setFeedbackConfirmationMsg('Send mooooore!');
            setTimeout(() => {
              setFeedbackConfirmationMsg('Send mooooooore!');
              setTimeout(() => {
                setShowFeedbackShareButton(true);
                setShowFeedbackConfirmBlock(false);
              }, 1500);
            }, 250);
          }, 250);
        }, 250);
      }, 1000);
    }, 750);
  };

  const filterCompletedPackages = uniquePackages => {
    return uniquePackages.filter(lessonPackage => {
      return (
        lessonPackage.lessonsCompleted + lessonPackage.lessonsCancelled !==
        lessonPackage.totalLessons
      );
    });
  };

  const getCurrentClientsCount = uniquePackages => {
    let count = uniquePackages.length;
    return count;
  };

  const calculatePay = lessonRecords => {
    const getShortLessonPaymentStatusName = lessonStatus => {
      if (lessonStatus.includes('Submitted - pending Approval')) {
        return 'pending';
      }

      if (lessonStatus.includes('Submitted - approved')) {
        return 'approved';
      }

      return lessonStatus;
    };

    const isCurrentYear = date => {
      const dateConverted = new Date(date);
      const now = new Date();
      const startOfYear = new Date(now.getFullYear(), 0, 1); // January 1st of this year
      return dateConverted >= startOfYear && dateConverted <= now;
    };

    const paidLessonsa = [];
    const lessonsForPayThisPerioda = [];
    const lessonsForPayNextPerioda = [];

    let thisPayDayAmounta = 0;
    let nextPayDayAmounta = 0;
    let totalEarningsYearToDate = 0;
    let thisPayDayDatea = CalcPayDays.getThisPayDay();
    let nextPayDayDatea = CalcPayDays.getNextPayDay();

    for (const lesson of lessonRecords) {
      let recordId = lesson.id;
      let packageId = lesson.field_58_raw[0].identifier;
      let packageKnackId = lesson.field_58_raw[0].id;
      let lessonID = lesson.field_51_raw;
      let lessonNumber = lesson.field_320_raw;
      let clientName = lesson.field_455_raw;

      let lessonPaymentStatus = lesson.field_534;
      let lessonPay = lesson.field_69_raw;
      let lessonDuration = lesson.field_65_raw;

      let dateLessonSubmittedForPay = lesson.field_620;
      let dateLessonSubmittedForPayFormatted = lesson.field_620_raw.date;

      let dateLessonPaid = lesson.field_313;
      let dateLessonPaidISO = new Date(lesson.field_313);
      let dateLessonPaidFormatted = lesson.field_313_raw.date;

      let lessonPaymentStatusShort =
        getShortLessonPaymentStatusName(lessonPaymentStatus);

      let lessonObj = {
        recordId,
        packageId,
        packageKnackId,
        lessonID,
        lessonNumber,
        clientName,
        lessonPaymentStatus,
        lessonPaymentStatusShort,
        lessonPay,
        lessonDuration,
        dateLessonSubmittedForPay,
        dateLessonSubmittedForPayFormatted,
        dateLessonPaid,
        dateLessonPaidISO,
        dateLessonPaidFormatted,
      };

      let isPayable =
        lessonPaymentStatus.includes('Submitted - pending Approval') ||
        lessonPaymentStatus.includes('Submitted - approved');

      if (dateLessonSubmittedForPay && isPayable) {
        let estimatedPayDate = CalcPayDays.getLessonPayDate(
          dateLessonSubmittedForPay
        );

        if (estimatedPayDate <= thisPayDayDatea) {
          thisPayDayAmounta += lessonPay;
          lessonsForPayThisPerioda.push(lessonObj);
        }

        if (
          estimatedPayDate > thisPayDayDatea &&
          estimatedPayDate <= nextPayDayDatea
        ) {
          nextPayDayAmounta += lessonPay;
          lessonsForPayNextPerioda.push(lessonObj);
        }
      }

      if (
        lessonPaymentStatus === 'Paid' &&
        dateLessonPaidISO.toString() == thisPayDayDatea.toString()
      ) {
        thisPayDayAmounta += lessonPay;
        lessonsForPayThisPerioda.push(lessonObj);
      }

      if (lessonPaymentStatus === 'Paid' && isCurrentYear(dateLessonPaid)) {
        totalEarningsYearToDate += lessonPay;
        paidLessonsa.push(lessonObj);
      }
    }

    setThisPayDayDate(thisPayDayDatea.toLocaleDateString());
    setNextPayDayDate(nextPayDayDatea.toLocaleDateString());
    setThisPayDayAmount(thisPayDayAmounta);
    setNextPayDayAmount(nextPayDayAmounta);
    setTotalEarnings(totalEarningsYearToDate);
    setPaidLessons(paidLessonsa);
    setLessonsForPayThisPeriod(lessonsForPayThisPerioda);
    setLessonsForPayNextPeriod(lessonsForPayNextPerioda);

    return {
      paidLessonsa,
      lessonsForPayThisPerioda,
      lessonsForPayNextPerioda,
    };
  };

  const filterScheduledLessons = lessons => {
    return lessons.filter(lesson => {
      const now = new Date();
      const scheduledDate = lesson.field_202;
      const lessonStatus = lesson.field_54;

      if (scheduledDate != '' && lessonStatus == 'Matched') {
        const parsedDate = new Date(lesson.field_202_raw.iso_timestamp);
        return !isNaN(parsedDate.getTime());
        // return !isNaN(parsedDate.getTime()) && parsedDate > now;
      }
    });
  };

  const filterCurrentClients = () => {
    const getDateXDaysAgo = () => {
      const currentDate = convertTime.moment();
      const dateXDaysAgo = currentDate.subtract(365, 'days');
      return dateXDaysAgo.toDate();
    };

    function FilterLessonBookingDate() {
      this.field = 'field_312';
      this.operator = 'is after';
      this.range = getDateXDaysAgo();
    }

    function FilterLessonBookingDateNotBlank() {
      this.field = 'field_312';
      this.operator = 'is not blank';
    }

    function FilterPackageStatusNotCompleted() {
      this.field = 'field_231';
      this.operator = 'is not';
      this.value = 'Completed';
    }

    function FilterPackageStatusNotPaused() {
      this.field = 'field_231';
      this.operator = 'is not';
      this.value = 'Paused';
    }

    function FilterLessonStatusNotUnbooked() {
      this.field = 'field_54';
      this.operator = 'is not';
      this.value = 'Unbooked';
    }

    let match = 'and';
    const rules = [];

    rules.push(new FilterPackageStatusNotCompleted());
    rules.push(new FilterPackageStatusNotPaused());
    rules.push(new FilterLessonStatusNotUnbooked());
    rules.push(new FilterLessonBookingDate());
    rules.push(new FilterLessonBookingDateNotBlank());

    console.log('Current Client Filter Rules ========== ', rules);

    const filterRules = {
      match,
      rules,
    };

    return filterRules;
  };
  React.useEffect(() => {
    Track.onEnterScreen(
      'HomeScreen',
      Variables,
      setGlobalVariableValue,
      props.navigation.navigate,
      props?.route?.params?.skipAuth
    );
    validateTermsAgreement.ValidateTermsAgreement(
      props.navigation.navigate,
      Variables
    );
  }, []);
  const integromatReceiveOpenFeedbackPOST =
    IntegromatApi.useReceiveOpenFeedbackPOST();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    const handler = async () => {
      try {
        if (!isFocused) {
          return;
        }
        setGlobalVariableValue({
          key: 'screen',
          value: 'home',
        });
        filterCurrentClients();
        if (Platform.OS === 'web') {
          return;
        }
        if (Constants['DEVICE_PUSH_TOKEN']?.length > 5) {
          return;
        }
        const devicePushToken = await getPushTokenUtil({
          permissionErrorMessage: 'Unable to retrieve push token.',
          deviceErrorMessage: 'Must use physical device for Push Notifications',
          showAlertOnPermissionError: true,
          showAlertOnDeviceError: true,
        });

        await saveDevicePushToken(
          Variables,
          setGlobalVariableValue,
          devicePushToken
        );
      } catch (err) {
        console.error(err);
      }
    };
    handler();
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={false}
      hasTopSafeArea={false}
    >
      {/* View Background */}
      <View
        style={StyleSheet.applyWidth(
          { height: 400, marginBottom: -272 },
          dimensions.width
        )}
      >
        <SVG
          {...GlobalStyles.SVGStyles(theme)['SVG'].props}
          source={Constants['uiBgHomescreen']}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(GlobalStyles.SVGStyles(theme)['SVG'].style, {
              height: '100%',
              marginTop: -32,
              width: '100%',
            }),
            dimensions.width
          )}
        />
      </View>
      {/* View Header */}
      <View
        style={StyleSheet.applyWidth(
          { position: 'absolute', top: 0, width: '100%' },
          dimensions.width
        )}
      >
        {/* Spacer Top Nav */}
        <Spacer bottom={0} left={0} right={0} top={44} />
        {/* View Top Navigation */}
        <View
          style={StyleSheet.applyWidth(
            {
              alignContent: 'flex-start',
              alignItems: 'center',
              backgroundColor: '"rgba(0, 0, 0, 0)"',
              flexDirection: 'row',
              justifyContent: 'space-between',
              paddingLeft: 20,
              paddingRight: 20,
              width: '100%',
            },
            dimensions.width
          )}
        >
          {/* View Logo */}
          <View
            style={StyleSheet.applyWidth(
              { height: 52, width: 52 },
              dimensions.width
            )}
          >
            <SVG
              source={Constants['uiLogoRound']}
              style={StyleSheet.applyWidth(
                { height: '100%', width: '100%' },
                dimensions.width
              )}
            />
          </View>
          {/* View Greeting */}
          <View>
            {/* Hello Firstname */}
            <Text
              accessible={true}
              style={StyleSheet.applyWidth(
                {
                  color: theme.colors.text.strong,
                  fontFamily: 'Poppins_700Bold',
                  fontSize: 20,
                },
                dimensions.width
              )}
            >
              {'Hello, '}
              {Constants['userFirstName']}
              {'!'}
            </Text>
          </View>
          {/* View Pay Icon Container */}
          <View
            style={StyleSheet.applyWidth(
              { alignItems: 'center', width: 72 },
              dimensions.width
            )}
          >
            <>
              {!is_loading_pay ? null : (
                <ActivityIndicator
                  animating={true}
                  hidesWhenStopped={true}
                  size={'small'}
                  {...GlobalStyles.ActivityIndicatorStyles(theme)[
                    'Activity Indicator'
                  ].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.ActivityIndicatorStyles(theme)[
                      'Activity Indicator'
                    ].style,
                    dimensions.width
                  )}
                />
              )}
            </>
            <Touchable
              onPress={() => {
                try {
                  setShowPayEarnings(!showPayEarnings);
                  amplitudeEvents(
                    Variables,
                    'view pay history',
                    { origin: 'homescreen', category: 'pay' },
                    undefined
                  );
                } catch (err) {
                  console.error(err);
                }
              }}
              disabled={is_loading_pay}
              style={StyleSheet.applyWidth(
                { height: '100%', width: '100%' },
                dimensions.width
              )}
            >
              {/* View Touchable Pay */}
              <>
                {!showPayIcon ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        height: '100%',
                        justifyContent: 'center',
                        width: '100%',
                      },
                      dimensions.width
                    )}
                  >
                    {/* Payday Background */}
                    <ImageBackground
                      {...GlobalStyles.ImageBackgroundStyles(theme)[
                        'Image Background'
                      ].props}
                      resizeMode={'contain'}
                      source={Images.PaydayBg}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ImageBackgroundStyles(theme)[
                            'Image Background'
                          ].style,
                          { alignItems: 'center', justifyContent: 'center' }
                        ),
                        dimensions.width
                      )}
                    >
                      {/* This pay amount */}
                      <Text
                        accessible={true}
                        {...GlobalStyles.TextStyles(theme)['Text'].props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'].style,
                            {
                              fontFamily: 'Poppins_700Bold',
                              padding: 10,
                              paddingTop: 10,
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {'$'}
                        {thisPayDayAmount}
                      </Text>
                    </ImageBackground>
                  </View>
                )}
              </>
            </Touchable>
          </View>
        </View>
      </View>

      <ScrollView
        bounces={true}
        horizontal={false}
        keyboardShouldPersistTaps={'never'}
        showsHorizontalScrollIndicator={true}
        showsVerticalScrollIndicator={true}
        contentContainerStyle={StyleSheet.applyWidth(
          { paddingBottom: 100, paddingLeft: '5.35%', paddingRight: '5.35%' },
          dimensions.width
        )}
        nestedScrollEnabled={true}
      >
        {/* Pay Details Container */}
        <>
          {!showPayEarnings ? null : (
            <View
              style={StyleSheet.applyWidth(
                { borderRadius: 8, marginBottom: 8, maxHeight: 640 },
                dimensions.width
              )}
            >
              {/* Earnings Container */}
              <View>
                {/* Pay Earnings */}
                <Surface
                  {...GlobalStyles.SurfaceStyles(theme)['Surface'].props}
                  elevation={3}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.SurfaceStyles(theme)['Surface'].style,
                      { borderRadius: 16 }
                    ),
                    dimensions.width
                  )}
                >
                  <View
                    {...GlobalStyles.ViewStyles(theme)['card block'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ViewStyles(theme)['card block'].style,
                        {
                          backgroundColor: palettes.App['Custom Color'],
                          borderRadius: 16,
                          marginBottom: 8,
                          padding: 20,
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {/* Header row */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'flex-start',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                        },
                        dimensions.width
                      )}
                    >
                      {/* View Title */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'baseline',
                            flexDirection: 'row',
                            marginBottom: 16,
                          },
                          dimensions.width
                        )}
                      >
                        {/* H1 */}
                        <Text
                          accessible={true}
                          style={StyleSheet.applyWidth(
                            {
                              color: palettes.App['App Black 191F32'],
                              fontFamily: 'BebasNeue_400Regular',
                              fontSize: 24,
                              marginRight: 4,
                            },
                            dimensions.width
                          )}
                        >
                          {'Your'}
                        </Text>
                        {/* H1 Accent */}
                        <Text
                          accessible={true}
                          style={StyleSheet.applyWidth(
                            {
                              color: palettes.App['App Red EB3E26'],
                              fontFamily: 'LeckerliOne_400Regular',
                              fontSize: 22,
                            },
                            dimensions.width
                          )}
                        >
                          {'Earnings'}
                        </Text>
                      </View>
                      {/* Close Schedule Card */}
                      <View
                        {...GlobalStyles.ViewStyles(theme)['modal close 2']
                          .props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.ViewStyles(theme)['modal close 2'].style,
                          dimensions.width
                        )}
                      >
                        {/* View Close Button */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              backgroundColor:
                                palettes.App['App Disabled Button EDEDEF'],
                              borderRadius: 40,
                              paddingBottom: 4,
                              paddingLeft: 6,
                              paddingRight: 6,
                              paddingTop: 4,
                            },
                            dimensions.width
                          )}
                        >
                          <Pressable
                            onPress={() => {
                              try {
                                closeEarningsView();
                              } catch (err) {
                                console.error(err);
                              }
                            }}
                          >
                            {/* Close */}
                            <Text
                              accessible={true}
                              style={StyleSheet.applyWidth(
                                {
                                  color: palettes.App['App Text 2ndary 7F868C'],
                                  fontFamily: 'Poppins_400Regular',
                                  fontSize: 12,
                                },
                                dimensions.width
                              )}
                            >
                              {'close x'}
                            </Text>
                          </Pressable>
                        </View>
                      </View>
                    </View>
                    {/* Pay Periods Row - This Active */}
                    <>
                      {!showPayEarningsThisPayout ? null : (
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              marginBottom: 16,
                            },
                            dimensions.width
                          )}
                        >
                          {/* This Payout */}
                          <View
                            style={StyleSheet.applyWidth(
                              { flex: 3 },
                              dimensions.width
                            )}
                          >
                            {/* This Payout */}
                            <Text
                              accessible={true}
                              {...GlobalStyles.TextStyles(theme)['Text'].props}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TextStyles(theme)['Text'].style,
                                  {
                                    color: palettes.App['App Black 191F32'],
                                    fontFamily: 'Poppins_400Regular',
                                  }
                                ),
                                dimensions.width
                              )}
                            >
                              {'This payout'}
                            </Text>
                            {/* Pay and Info button */}
                            <View
                              style={StyleSheet.applyWidth(
                                { flexDirection: 'row' },
                                dimensions.width
                              )}
                            >
                              {/* Pay Amount */}
                              <Text
                                accessible={true}
                                {...GlobalStyles.TextStyles(theme)['Text']
                                  .props}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.TextStyles(theme)['Text']
                                      .style,
                                    {
                                      color: palettes.App['App Black 191F32'],
                                      fontFamily: 'BebasNeue_400Regular',
                                      fontSize: 32,
                                    }
                                  ),
                                  dimensions.width
                                )}
                              >
                                {'$'}
                                {thisPayDayAmount}
                              </Text>
                              {/* Info icon */}
                              <IconButton
                                onPress={() => {
                                  try {
                                    setShowPayEstimateDisclaimer(true);
                                    amplitudeEvents(
                                      Variables,
                                      'view pay disclaimer',
                                      { origin: 'homescreen', category: 'pay' },
                                      undefined
                                    );
                                  } catch (err) {
                                    console.error(err);
                                  }
                                }}
                                color={palettes.App['App Text Green #1DB058']}
                                icon={
                                  'MaterialCommunityIcons/information-outline'
                                }
                                size={16}
                              />
                            </View>
                            {/* Pay date */}
                            <Text
                              accessible={true}
                              {...GlobalStyles.TextStyles(theme)['Text'].props}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TextStyles(theme)['Text'].style,
                                  {
                                    color:
                                      palettes.App['App Text 2ndary 7F868C'],
                                    fontFamily: 'Poppins_400Regular',
                                    fontSize: 12,
                                    marginTop: -6,
                                  }
                                ),
                                dimensions.width
                              )}
                            >
                              {thisPayDayDate}
                            </Text>
                          </View>
                          {/* Next Payday */}
                          <View
                            style={StyleSheet.applyWidth(
                              { flex: 1 },
                              dimensions.width
                            )}
                          >
                            {/* Pressable Next Payout */}
                            <Pressable
                              onPress={() => {
                                try {
                                  selectPayoutPeriod('next');
                                  amplitudeEvents(
                                    Variables,
                                    'view next payout',
                                    { origin: 'homescreen', category: 'pay' },
                                    undefined
                                  );
                                } catch (err) {
                                  console.error(err);
                                }
                              }}
                            >
                              {/* Next Payday */}
                              <Text
                                accessible={true}
                                {...GlobalStyles.TextStyles(theme)['Text']
                                  .props}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.TextStyles(theme)['Text']
                                      .style,
                                    {
                                      color:
                                        palettes.App['App Text 2ndary 7F868C'],
                                      fontFamily: 'Poppins_400Regular',
                                    }
                                  ),
                                  dimensions.width
                                )}
                              >
                                {'Next'}
                              </Text>
                              {/* Pay Amount */}
                              <Text
                                accessible={true}
                                {...GlobalStyles.TextStyles(theme)['Text']
                                  .props}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.TextStyles(theme)['Text']
                                      .style,
                                    {
                                      color:
                                        palettes.App['App Text 2ndary 7F868C'],
                                    }
                                  ),
                                  dimensions.width
                                )}
                              >
                                {'$'}
                                {nextPayDayAmount}
                              </Text>
                            </Pressable>
                          </View>
                          {/* YTD Earnings */}
                          <View
                            style={StyleSheet.applyWidth(
                              { flex: 1 },
                              dimensions.width
                            )}
                          >
                            {/* Pressable YTD */}
                            <Pressable
                              onPress={() => {
                                try {
                                  selectPayoutPeriod('total');
                                  amplitudeEvents(
                                    Variables,
                                    'view ytd payout',
                                    { origin: 'homescreen', category: 'pay' },
                                    undefined
                                  );
                                } catch (err) {
                                  console.error(err);
                                }
                              }}
                            >
                              {/* YTD */}
                              <Text
                                accessible={true}
                                {...GlobalStyles.TextStyles(theme)['Text']
                                  .props}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.TextStyles(theme)['Text']
                                      .style,
                                    {
                                      color:
                                        palettes.App['App Text 2ndary 7F868C'],
                                      fontFamily: 'Poppins_400Regular',
                                    }
                                  ),
                                  dimensions.width
                                )}
                              >
                                {'YTD'}
                              </Text>
                              {/* Pay Amount */}
                              <Text
                                accessible={true}
                                {...GlobalStyles.TextStyles(theme)['Text']
                                  .props}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.TextStyles(theme)['Text']
                                      .style,
                                    {
                                      color:
                                        palettes.App['App Text 2ndary 7F868C'],
                                    }
                                  ),
                                  dimensions.width
                                )}
                              >
                                {'$'}
                                {totalEarnings}
                              </Text>
                            </Pressable>
                          </View>
                        </View>
                      )}
                    </>
                    {/* Pay Periods Row - Next Active */}
                    <>
                      {!showPayEarningsNextPayout ? null : (
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              marginBottom: 16,
                            },
                            dimensions.width
                          )}
                        >
                          {/* This Payout */}
                          <View
                            style={StyleSheet.applyWidth(
                              { flex: 1 },
                              dimensions.width
                            )}
                          >
                            {/* Pressable - This */}
                            <Pressable
                              onPress={() => {
                                try {
                                  selectPayoutPeriod('this');
                                  amplitudeEvents(
                                    Variables,
                                    'view current payout',
                                    { origin: 'homescreen', category: 'pay' },
                                    undefined
                                  );
                                } catch (err) {
                                  console.error(err);
                                }
                              }}
                            >
                              {/* This Payout */}
                              <Text
                                accessible={true}
                                {...GlobalStyles.TextStyles(theme)['Text']
                                  .props}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.TextStyles(theme)['Text']
                                      .style,
                                    {
                                      color:
                                        palettes.App['App Text 2ndary 7F868C'],
                                      fontFamily: 'Poppins_400Regular',
                                    }
                                  ),
                                  dimensions.width
                                )}
                              >
                                {'This'}
                              </Text>
                              {/* Pay and Info button */}
                              <View
                                style={StyleSheet.applyWidth(
                                  { flexDirection: 'row' },
                                  dimensions.width
                                )}
                              >
                                {/* Pay Amount */}
                                <Text
                                  accessible={true}
                                  {...GlobalStyles.TextStyles(theme)['Text']
                                    .props}
                                  style={StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                      GlobalStyles.TextStyles(theme)['Text']
                                        .style,
                                      {
                                        color:
                                          palettes.App[
                                            'App Text 2ndary 7F868C'
                                          ],
                                        fontFamily: 'Poppins_400Regular',
                                        fontSize: 14,
                                      }
                                    ),
                                    dimensions.width
                                  )}
                                >
                                  {'$'}
                                  {thisPayDayAmount}
                                </Text>
                              </View>
                            </Pressable>
                          </View>
                          {/* Next Payout */}
                          <View
                            style={StyleSheet.applyWidth(
                              { alignItems: 'center', flex: 3 },
                              dimensions.width
                            )}
                          >
                            {/* Center Sections */}
                            <View>
                              {/* Next Payout */}
                              <Text
                                accessible={true}
                                {...GlobalStyles.TextStyles(theme)['Text']
                                  .props}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.TextStyles(theme)['Text']
                                      .style,
                                    {
                                      color: palettes.App['App Black 191F32'],
                                      fontFamily: 'Poppins_400Regular',
                                    }
                                  ),
                                  dimensions.width
                                )}
                              >
                                {'Next payout'}
                              </Text>
                              {/* Pay and info button */}
                              <View
                                style={StyleSheet.applyWidth(
                                  { flexDirection: 'row' },
                                  dimensions.width
                                )}
                              >
                                {/* Pay Amount */}
                                <Text
                                  accessible={true}
                                  {...GlobalStyles.TextStyles(theme)['Text']
                                    .props}
                                  style={StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                      GlobalStyles.TextStyles(theme)['Text']
                                        .style,
                                      {
                                        color: palettes.App['App Black 191F32'],
                                        fontFamily: 'BebasNeue_400Regular',
                                        fontSize: 32,
                                      }
                                    ),
                                    dimensions.width
                                  )}
                                >
                                  {'$'}
                                  {nextPayDayAmount}
                                </Text>
                                {/* Info icon */}
                                <IconButton
                                  onPress={() => {
                                    try {
                                      setShowPayEstimateDisclaimer(true);
                                      amplitudeEvents(
                                        Variables,
                                        'view pay disclaimer',
                                        {
                                          origin: 'homescreen',
                                          category: 'pay',
                                        },
                                        undefined
                                      );
                                    } catch (err) {
                                      console.error(err);
                                    }
                                  }}
                                  color={palettes.App['App Text Green #1DB058']}
                                  icon={
                                    'MaterialCommunityIcons/information-outline'
                                  }
                                  size={16}
                                />
                              </View>
                              {/* Pay date */}
                              <Text
                                accessible={true}
                                {...GlobalStyles.TextStyles(theme)['Text']
                                  .props}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.TextStyles(theme)['Text']
                                      .style,
                                    {
                                      color:
                                        palettes.App['App Text 2ndary 7F868C'],
                                      fontFamily: 'Poppins_400Regular',
                                      fontSize: 12,
                                      marginTop: -6,
                                    }
                                  ),
                                  dimensions.width
                                )}
                              >
                                {nextPayDayDate}
                              </Text>
                            </View>
                          </View>
                          {/* YTD Earnings */}
                          <View
                            style={StyleSheet.applyWidth(
                              { flex: 1 },
                              dimensions.width
                            )}
                          >
                            {/* Pressable - Total */}
                            <Pressable
                              onPress={() => {
                                try {
                                  selectPayoutPeriod('total');
                                  amplitudeEvents(
                                    Variables,
                                    'view ytd pay',
                                    { origin: 'homescreen', category: 'pay' },
                                    undefined
                                  );
                                } catch (err) {
                                  console.error(err);
                                }
                              }}
                            >
                              {/* YTD */}
                              <Text
                                accessible={true}
                                {...GlobalStyles.TextStyles(theme)['Text']
                                  .props}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.TextStyles(theme)['Text']
                                      .style,
                                    {
                                      color:
                                        palettes.App['App Text 2ndary 7F868C'],
                                      fontFamily: 'Poppins_400Regular',
                                    }
                                  ),
                                  dimensions.width
                                )}
                              >
                                {'YTD'}
                              </Text>
                              {/* Pay Amount */}
                              <Text
                                accessible={true}
                                {...GlobalStyles.TextStyles(theme)['Text']
                                  .props}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.TextStyles(theme)['Text']
                                      .style,
                                    {
                                      color:
                                        palettes.App['App Text 2ndary 7F868C'],
                                    }
                                  ),
                                  dimensions.width
                                )}
                              >
                                {'$'}
                                {totalEarnings}
                              </Text>
                            </Pressable>
                          </View>
                        </View>
                      )}
                    </>
                    {/* Pay Periods Row - Total Active */}
                    <>
                      {!showPayEarningsTotal ? null : (
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              marginBottom: 16,
                            },
                            dimensions.width
                          )}
                        >
                          {/* This Payout */}
                          <View
                            style={StyleSheet.applyWidth(
                              { flex: 1 },
                              dimensions.width
                            )}
                          >
                            {/* Pressable - This */}
                            <Pressable
                              onPress={() => {
                                try {
                                  selectPayoutPeriod('this');
                                  amplitudeEvents(
                                    Variables,
                                    'view current payout',
                                    { origin: 'homescreen', category: 'pay' },
                                    undefined
                                  );
                                } catch (err) {
                                  console.error(err);
                                }
                              }}
                            >
                              {/* This Payout */}
                              <Text
                                accessible={true}
                                {...GlobalStyles.TextStyles(theme)['Text']
                                  .props}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.TextStyles(theme)['Text']
                                      .style,
                                    {
                                      color:
                                        palettes.App['App Text 2ndary 7F868C'],
                                      fontFamily: 'Poppins_400Regular',
                                    }
                                  ),
                                  dimensions.width
                                )}
                              >
                                {'This'}
                              </Text>
                              {/* Pay and Info button */}
                              <View
                                style={StyleSheet.applyWidth(
                                  { flexDirection: 'row' },
                                  dimensions.width
                                )}
                              >
                                {/* Pay Amount */}
                                <Text
                                  accessible={true}
                                  {...GlobalStyles.TextStyles(theme)['Text']
                                    .props}
                                  style={StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                      GlobalStyles.TextStyles(theme)['Text']
                                        .style,
                                      {
                                        color:
                                          palettes.App[
                                            'App Text 2ndary 7F868C'
                                          ],
                                        fontFamily: 'Poppins_400Regular',
                                        fontSize: 14,
                                      }
                                    ),
                                    dimensions.width
                                  )}
                                >
                                  {'$'}
                                  {thisPayDayAmount}
                                </Text>
                              </View>
                            </Pressable>
                          </View>
                          {/* Next Payout */}
                          <View
                            style={StyleSheet.applyWidth(
                              { flex: 1 },
                              dimensions.width
                            )}
                          >
                            {/* Pressable - Next */}
                            <Pressable
                              onPress={() => {
                                try {
                                  selectPayoutPeriod('next');
                                  amplitudeEvents(
                                    Variables,
                                    'view next payout',
                                    { origin: 'homescreen', category: 'pay' },
                                    undefined
                                  );
                                } catch (err) {
                                  console.error(err);
                                }
                              }}
                            >
                              {/* Next */}
                              <Text
                                accessible={true}
                                {...GlobalStyles.TextStyles(theme)['Text']
                                  .props}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.TextStyles(theme)['Text']
                                      .style,
                                    {
                                      color:
                                        palettes.App['App Text 2ndary 7F868C'],
                                      fontFamily: 'Poppins_400Regular',
                                    }
                                  ),
                                  dimensions.width
                                )}
                              >
                                {'Next'}
                              </Text>
                              {/* Pay Amount */}
                              <Text
                                accessible={true}
                                {...GlobalStyles.TextStyles(theme)['Text']
                                  .props}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.TextStyles(theme)['Text']
                                      .style,
                                    {
                                      color:
                                        palettes.App['App Text 2ndary 7F868C'],
                                    }
                                  ),
                                  dimensions.width
                                )}
                              >
                                {'$'}
                                {nextPayDayAmount}
                              </Text>
                            </Pressable>
                          </View>
                          {/* YTD */}
                          <View
                            style={StyleSheet.applyWidth(
                              { flex: 3 },
                              dimensions.width
                            )}
                          >
                            {/* YTD Payout */}
                            <Text
                              accessible={true}
                              {...GlobalStyles.TextStyles(theme)['Text'].props}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TextStyles(theme)['Text'].style,
                                  {
                                    color: palettes.App['App Black 191F32'],
                                    fontFamily: 'Poppins_400Regular',
                                  }
                                ),
                                dimensions.width
                              )}
                            >
                              {'Total earned this year'}
                            </Text>
                            {/* Pay and info button */}
                            <View
                              style={StyleSheet.applyWidth(
                                { flexDirection: 'row' },
                                dimensions.width
                              )}
                            >
                              {/* Pay Amount */}
                              <Text
                                accessible={true}
                                {...GlobalStyles.TextStyles(theme)['Text']
                                  .props}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.TextStyles(theme)['Text']
                                      .style,
                                    {
                                      color: palettes.App['App Black 191F32'],
                                      fontFamily: 'BebasNeue_400Regular',
                                      fontSize: 32,
                                    }
                                  ),
                                  dimensions.width
                                )}
                              >
                                {'$'}
                                {totalEarnings}
                              </Text>
                              {/* Info icon */}
                              <IconButton
                                onPress={() => {
                                  try {
                                    setShowPayEstimateDisclaimer(true);
                                    amplitudeEvents(
                                      Variables,
                                      'view pay disclaimer',
                                      { origin: 'homescreen', category: 'pay' },
                                      undefined
                                    );
                                  } catch (err) {
                                    console.error(err);
                                  }
                                }}
                                color={palettes.App['App Text Green #1DB058']}
                                icon={
                                  'MaterialCommunityIcons/information-outline'
                                }
                                size={16}
                              />
                            </View>
                            {/* Year to Date */}
                            <Text
                              accessible={true}
                              {...GlobalStyles.TextStyles(theme)['Text'].props}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TextStyles(theme)['Text'].style,
                                  {
                                    color:
                                      palettes.App['App Text 2ndary 7F868C'],
                                    fontFamily: 'Poppins_400Regular',
                                    fontSize: 12,
                                    marginTop: -6,
                                  }
                                ),
                                dimensions.width
                              )}
                            >
                              {'Year to Date'}
                            </Text>
                          </View>
                        </View>
                      )}
                    </>
                    {/* Lessons Summary */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          flexDirection: 'row',
                          marginBottom: 4,
                          paddingLeft: 4,
                        },
                        dimensions.width
                      )}
                    >
                      {/* Total Lessons */}
                      <Text
                        accessible={true}
                        {...GlobalStyles.TextStyles(theme)['Text'].props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'].style,
                            {
                              color: palettes.App['App Black 191F32'],
                              fontFamily: 'BebasNeue_400Regular',
                              fontSize: 16,
                              marginRight: 4,
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {'Total Lessons'}
                      </Text>
                      {/* This Payout */}
                      <>
                        {!showPayEarningsThisPayout ? null : (
                          <View>
                            {/* Total Lessons */}
                            <Text
                              accessible={true}
                              {...GlobalStyles.TextStyles(theme)['Text'].props}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TextStyles(theme)['Text'].style,
                                  {
                                    color: palettes.App['App Black 191F32'],
                                    fontFamily: 'BebasNeue_400Regular',
                                    fontSize: 16,
                                  }
                                ),
                                dimensions.width
                              )}
                            >
                              {lessonsForPayThisPeriod?.length}
                            </Text>
                          </View>
                        )}
                      </>
                      {/* Next Payout */}
                      <>
                        {!showPayEarningsNextPayout ? null : (
                          <View>
                            {/* Total Lessons */}
                            <Text
                              accessible={true}
                              {...GlobalStyles.TextStyles(theme)['Text'].props}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TextStyles(theme)['Text'].style,
                                  {
                                    color: palettes.App['App Black 191F32'],
                                    fontFamily: 'BebasNeue_400Regular',
                                    fontSize: 16,
                                  }
                                ),
                                dimensions.width
                              )}
                            >
                              {lessonsForPayNextPeriod?.length}
                            </Text>
                          </View>
                        )}
                      </>
                      {/* Paid Lessons Year to Date */}
                      <>
                        {!showPayEarningsTotal ? null : (
                          <View>
                            {/* Total Lessons */}
                            <Text
                              accessible={true}
                              {...GlobalStyles.TextStyles(theme)['Text'].props}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TextStyles(theme)['Text'].style,
                                  {
                                    color: palettes.App['App Black 191F32'],
                                    fontFamily: 'BebasNeue_400Regular',
                                    fontSize: 16,
                                  }
                                ),
                                dimensions.width
                              )}
                            >
                              {paidLessons?.length}
                            </Text>
                          </View>
                        )}
                      </>
                    </View>
                    {/* This Pay Period Lessons List */}
                    <>
                      {!showPayEarningsThisPayout ? null : (
                        <View
                          style={StyleSheet.applyWidth(
                            { maxHeight: 320 },
                            dimensions.width
                          )}
                        >
                          <ScrollView
                            bounces={true}
                            horizontal={false}
                            keyboardShouldPersistTaps={'never'}
                            showsHorizontalScrollIndicator={true}
                            showsVerticalScrollIndicator={true}
                            nestedScrollEnabled={true}
                          >
                            {/* This Pay Period Lessons */}
                            <FlashList
                              data={lessonsForPayThisPeriod}
                              estimatedItemSize={50}
                              horizontal={false}
                              inverted={false}
                              keyExtractor={(thisPayPeriodLessonsData, index) =>
                                thisPayPeriodLessonsData?.id ??
                                thisPayPeriodLessonsData?.uuid ??
                                index.toString()
                              }
                              listKey={'q7XulmU9'}
                              numColumns={1}
                              onEndReachedThreshold={0.5}
                              renderItem={({ item, index }) => {
                                const thisPayPeriodLessonsData = item;
                                return (
                                  <>
                                    {/* Lesson Details */}
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          backgroundColor:
                                            palettes.App['Custom Color'],
                                          borderColor:
                                            palettes.App[
                                              'App Grey Inputs E2E2E2'
                                            ],
                                          borderRadius: 8,
                                          borderWidth: 1,
                                          marginBottom: 8,
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      {/* Nav to Lesson Details */}
                                      <Pressable
                                        onPress={() => {
                                          try {
                                            amplitudeEvents(
                                              Variables,
                                              'view lesson details',
                                              {
                                                origin: 'homescreen',
                                                category: 'client management',
                                              },
                                              undefined
                                            );
                                            navigation.navigate(
                                              'LessonDetailsAndManagementScreen',
                                              {
                                                lessonKnackId:
                                                  thisPayPeriodLessonsData?.recordId,
                                                lessonPackageKnackId:
                                                  thisPayPeriodLessonsData?.packageKnackId,
                                              }
                                            );
                                          } catch (err) {
                                            console.error(err);
                                          }
                                        }}
                                      >
                                        {/* Client Block */}
                                        <View
                                          {...GlobalStyles.ViewStyles(theme)[
                                            'client header component'
                                          ].props}
                                          style={StyleSheet.applyWidth(
                                            StyleSheet.compose(
                                              GlobalStyles.ViewStyles(theme)[
                                                'client header component'
                                              ].style,
                                              {
                                                alignItems: 'center',
                                                borderBottomWidth: 0,
                                                paddingBottom: 4,
                                                paddingLeft: 8,
                                                paddingRight: 8,
                                                paddingTop: 4,
                                              }
                                            ),
                                            dimensions.width
                                          )}
                                        >
                                          {/* View Left Details */}
                                          <View
                                            style={StyleSheet.applyWidth(
                                              {
                                                flexDirection: 'column',
                                                width: '75%',
                                              },
                                              dimensions.width
                                            )}
                                          >
                                            <View>
                                              {/* View Client Details */}
                                              <View
                                                style={StyleSheet.applyWidth(
                                                  {
                                                    alignItems: 'center',
                                                    flexDirection: 'row',
                                                    justifyContent:
                                                      'space-between',
                                                  },
                                                  dimensions.width
                                                )}
                                              >
                                                {/* Client */}
                                                <View
                                                  style={StyleSheet.applyWidth(
                                                    { flexDirection: 'column' },
                                                    dimensions.width
                                                  )}
                                                >
                                                  {/* Client Name */}
                                                  <Text
                                                    accessible={true}
                                                    style={StyleSheet.applyWidth(
                                                      {
                                                        color:
                                                          palettes.App[
                                                            'App Black 191F32'
                                                          ],
                                                        fontFamily:
                                                          'Poppins_600SemiBold',
                                                        fontSize: 12,
                                                      },
                                                      dimensions.width
                                                    )}
                                                  >
                                                    {getFirstNameLastInitial(
                                                      thisPayPeriodLessonsData?.clientName
                                                    )}{' '}
                                                  </Text>
                                                </View>
                                                {/* Lesson */}
                                                <View>
                                                  {/* Lesson Number */}
                                                  <Text
                                                    accessible={true}
                                                    style={StyleSheet.applyWidth(
                                                      {
                                                        color:
                                                          palettes.App[
                                                            'App Text 2ndary 7F868C'
                                                          ],
                                                        fontFamily:
                                                          'Poppins_400Regular',
                                                        fontSize: 12,
                                                        textTransform:
                                                          'capitalize',
                                                      },
                                                      dimensions.width
                                                    )}
                                                  >
                                                    {'Lesson '}
                                                    {
                                                      thisPayPeriodLessonsData?.lessonNumber
                                                    }
                                                  </Text>
                                                </View>
                                              </View>
                                              {/* Lesson ID and Duration */}
                                              <View
                                                style={StyleSheet.applyWidth(
                                                  {
                                                    flexDirection: 'row',
                                                    justifyContent:
                                                      'space-between',
                                                  },
                                                  dimensions.width
                                                )}
                                              >
                                                {/* Submission date */}
                                                <Text
                                                  accessible={true}
                                                  style={StyleSheet.applyWidth(
                                                    {
                                                      color:
                                                        palettes.App[
                                                          'App Text 2ndary 7F868C'
                                                        ],
                                                      fontFamily:
                                                        'Poppins_400Regular',
                                                      fontSize: 12,
                                                    },
                                                    dimensions.width
                                                  )}
                                                >
                                                  {'Submitted '}
                                                  {
                                                    thisPayPeriodLessonsData?.dateLessonSubmittedForPayFormatted
                                                  }
                                                </Text>
                                                {/* Lesson duration */}
                                                <Text
                                                  accessible={true}
                                                  style={StyleSheet.applyWidth(
                                                    {
                                                      color:
                                                        palettes.App[
                                                          'App Text 2ndary 7F868C'
                                                        ],
                                                      fontFamily:
                                                        'Poppins_400Regular',
                                                      fontSize: 12,
                                                    },
                                                    dimensions.width
                                                  )}
                                                >
                                                  {
                                                    thisPayPeriodLessonsData?.lessonDuration
                                                  }
                                                  {' mins'}
                                                </Text>
                                              </View>
                                            </View>
                                          </View>
                                          {/* Pay amount wrapper */}
                                          <View
                                            style={StyleSheet.applyWidth(
                                              { alignItems: 'flex-end' },
                                              dimensions.width
                                            )}
                                          >
                                            {/* Pay Background */}
                                            <View
                                              style={StyleSheet.applyWidth(
                                                {
                                                  backgroundColor:
                                                    palettes.App[
                                                      'App Mint #F0F8F2'
                                                    ],
                                                  borderRadius: 8,
                                                  paddingBottom: 8,
                                                  paddingLeft: 8,
                                                  paddingRight: 8,
                                                  paddingTop: 8,
                                                },
                                                dimensions.width
                                              )}
                                            >
                                              {/* Pay amount */}
                                              <Text
                                                accessible={true}
                                                style={StyleSheet.applyWidth(
                                                  {
                                                    color:
                                                      palettes.App[
                                                        'App Text Green #1DB058'
                                                      ],
                                                    fontFamily:
                                                      'Poppins_400Regular',
                                                    fontSize: 12,
                                                  },
                                                  dimensions.width
                                                )}
                                              >
                                                {'$'}
                                                {
                                                  thisPayPeriodLessonsData?.lessonPay
                                                }
                                              </Text>
                                            </View>
                                          </View>
                                        </View>
                                      </Pressable>
                                    </View>
                                  </>
                                );
                              }}
                              showsHorizontalScrollIndicator={true}
                              showsVerticalScrollIndicator={true}
                            />
                          </ScrollView>
                        </View>
                      )}
                    </>
                    {/* Next Pay Period Lessons List */}
                    <>
                      {!showPayEarningsNextPayout ? null : (
                        <View
                          style={StyleSheet.applyWidth(
                            { maxHeight: 320 },
                            dimensions.width
                          )}
                        >
                          <ScrollView
                            bounces={true}
                            horizontal={false}
                            keyboardShouldPersistTaps={'never'}
                            showsHorizontalScrollIndicator={true}
                            showsVerticalScrollIndicator={true}
                            nestedScrollEnabled={true}
                          >
                            {/* Next Pay Period Lessons */}
                            <FlashList
                              data={lessonsForPayNextPeriod}
                              estimatedItemSize={50}
                              horizontal={false}
                              inverted={false}
                              keyExtractor={(nextPayPeriodLessonsData, index) =>
                                nextPayPeriodLessonsData?.id ??
                                nextPayPeriodLessonsData?.uuid ??
                                index.toString()
                              }
                              listKey={'K7LeRkvr'}
                              numColumns={1}
                              onEndReachedThreshold={0.5}
                              renderItem={({ item, index }) => {
                                const nextPayPeriodLessonsData = item;
                                return (
                                  <>
                                    {/* Lesson Details */}
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          backgroundColor:
                                            palettes.App['Custom Color'],
                                          borderColor:
                                            palettes.App[
                                              'App Grey Inputs E2E2E2'
                                            ],
                                          borderRadius: 8,
                                          borderWidth: 1,
                                          marginBottom: 8,
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      {/* Nav to Lesson Details */}
                                      <Pressable
                                        onPress={() => {
                                          try {
                                            amplitudeEvents(
                                              Variables,
                                              'view client details',
                                              {
                                                origin: 'homescreen',
                                                category: 'client management',
                                              },
                                              undefined
                                            );
                                            navigation.navigate(
                                              'LessonDetailsAndManagementScreen',
                                              {
                                                lessonKnackId:
                                                  nextPayPeriodLessonsData?.recordId,
                                                lessonPackageKnackId:
                                                  nextPayPeriodLessonsData?.packageKnackId,
                                              }
                                            );
                                          } catch (err) {
                                            console.error(err);
                                          }
                                        }}
                                      >
                                        {/* Client Block */}
                                        <View
                                          {...GlobalStyles.ViewStyles(theme)[
                                            'client header component'
                                          ].props}
                                          style={StyleSheet.applyWidth(
                                            StyleSheet.compose(
                                              GlobalStyles.ViewStyles(theme)[
                                                'client header component'
                                              ].style,
                                              {
                                                alignItems: 'center',
                                                borderBottomWidth: 0,
                                                paddingBottom: 4,
                                                paddingLeft: 8,
                                                paddingRight: 8,
                                                paddingTop: 4,
                                              }
                                            ),
                                            dimensions.width
                                          )}
                                        >
                                          {/* View Left Details */}
                                          <View
                                            style={StyleSheet.applyWidth(
                                              {
                                                flexDirection: 'column',
                                                width: '75%',
                                              },
                                              dimensions.width
                                            )}
                                          >
                                            <View>
                                              {/* View Client Details */}
                                              <View
                                                style={StyleSheet.applyWidth(
                                                  {
                                                    alignItems: 'center',
                                                    flexDirection: 'row',
                                                    justifyContent:
                                                      'space-between',
                                                  },
                                                  dimensions.width
                                                )}
                                              >
                                                {/* Client */}
                                                <View
                                                  style={StyleSheet.applyWidth(
                                                    { flexDirection: 'column' },
                                                    dimensions.width
                                                  )}
                                                >
                                                  {/* Client Name */}
                                                  <Text
                                                    accessible={true}
                                                    style={StyleSheet.applyWidth(
                                                      {
                                                        color:
                                                          palettes.App[
                                                            'App Black 191F32'
                                                          ],
                                                        fontFamily:
                                                          'Poppins_600SemiBold',
                                                        fontSize: 12,
                                                      },
                                                      dimensions.width
                                                    )}
                                                  >
                                                    {getFirstNameLastInitial(
                                                      nextPayPeriodLessonsData?.clientName
                                                    )}{' '}
                                                  </Text>
                                                </View>
                                                {/* Lesson */}
                                                <View>
                                                  {/* Lesson Number */}
                                                  <Text
                                                    accessible={true}
                                                    style={StyleSheet.applyWidth(
                                                      {
                                                        color:
                                                          palettes.App[
                                                            'App Text 2ndary 7F868C'
                                                          ],
                                                        fontFamily:
                                                          'Poppins_400Regular',
                                                        fontSize: 12,
                                                        textTransform:
                                                          'capitalize',
                                                      },
                                                      dimensions.width
                                                    )}
                                                  >
                                                    {'Lesson '}
                                                    {
                                                      nextPayPeriodLessonsData?.lessonNumber
                                                    }
                                                  </Text>
                                                </View>
                                              </View>
                                              {/* Lesson ID and Duration */}
                                              <View
                                                style={StyleSheet.applyWidth(
                                                  {
                                                    flexDirection: 'row',
                                                    justifyContent:
                                                      'space-between',
                                                  },
                                                  dimensions.width
                                                )}
                                              >
                                                {/* Submission date */}
                                                <Text
                                                  accessible={true}
                                                  style={StyleSheet.applyWidth(
                                                    {
                                                      color:
                                                        palettes.App[
                                                          'App Text 2ndary 7F868C'
                                                        ],
                                                      fontFamily:
                                                        'Poppins_400Regular',
                                                      fontSize: 12,
                                                    },
                                                    dimensions.width
                                                  )}
                                                >
                                                  {'Submitted '}
                                                  {
                                                    nextPayPeriodLessonsData?.dateLessonSubmittedForPayFormatted
                                                  }
                                                </Text>
                                                {/* Lesson duration */}
                                                <Text
                                                  accessible={true}
                                                  style={StyleSheet.applyWidth(
                                                    {
                                                      color:
                                                        palettes.App[
                                                          'App Text 2ndary 7F868C'
                                                        ],
                                                      fontFamily:
                                                        'Poppins_400Regular',
                                                      fontSize: 12,
                                                    },
                                                    dimensions.width
                                                  )}
                                                >
                                                  {
                                                    nextPayPeriodLessonsData?.lessonDuration
                                                  }
                                                  {' mins'}
                                                </Text>
                                              </View>
                                            </View>
                                          </View>
                                          {/* Pay amount wrapper */}
                                          <View
                                            style={StyleSheet.applyWidth(
                                              { alignItems: 'flex-end' },
                                              dimensions.width
                                            )}
                                          >
                                            {/* Pay Background */}
                                            <View
                                              style={StyleSheet.applyWidth(
                                                {
                                                  backgroundColor:
                                                    palettes.App[
                                                      'App Mint #F0F8F2'
                                                    ],
                                                  borderRadius: 8,
                                                  paddingBottom: 8,
                                                  paddingLeft: 8,
                                                  paddingRight: 8,
                                                  paddingTop: 8,
                                                },
                                                dimensions.width
                                              )}
                                            >
                                              {/* Pay amount */}
                                              <Text
                                                accessible={true}
                                                style={StyleSheet.applyWidth(
                                                  {
                                                    color:
                                                      palettes.App[
                                                        'App Text Green #1DB058'
                                                      ],
                                                    fontFamily:
                                                      'Poppins_400Regular',
                                                    fontSize: 12,
                                                  },
                                                  dimensions.width
                                                )}
                                              >
                                                {'$'}
                                                {
                                                  nextPayPeriodLessonsData?.lessonPay
                                                }
                                              </Text>
                                            </View>
                                          </View>
                                        </View>
                                      </Pressable>
                                    </View>
                                  </>
                                );
                              }}
                              showsHorizontalScrollIndicator={true}
                              showsVerticalScrollIndicator={true}
                            />
                          </ScrollView>
                        </View>
                      )}
                    </>
                    {/* Total Paid Year to Date */}
                    <>
                      {!showPayEarningsTotal ? null : (
                        <View
                          style={StyleSheet.applyWidth(
                            { maxHeight: 320 },
                            dimensions.width
                          )}
                        >
                          <ScrollView
                            bounces={true}
                            horizontal={false}
                            keyboardShouldPersistTaps={'never'}
                            showsHorizontalScrollIndicator={true}
                            showsVerticalScrollIndicator={true}
                            nestedScrollEnabled={true}
                          >
                            {/* Lessons Paid Year to Date */}
                            <FlashList
                              data={paidLessons}
                              estimatedItemSize={50}
                              horizontal={false}
                              inverted={false}
                              keyExtractor={(
                                lessonsPaidYearToDateData,
                                index
                              ) =>
                                lessonsPaidYearToDateData?.id ??
                                lessonsPaidYearToDateData?.uuid ??
                                index.toString()
                              }
                              listKey={'S3qVwrBF'}
                              numColumns={1}
                              onEndReachedThreshold={0.5}
                              renderItem={({ item, index }) => {
                                const lessonsPaidYearToDateData = item;
                                return (
                                  <>
                                    {/* Lesson Details */}
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          backgroundColor:
                                            palettes.App['Custom Color'],
                                          borderColor:
                                            palettes.App[
                                              'App Grey Inputs E2E2E2'
                                            ],
                                          borderRadius: 8,
                                          borderWidth: 1,
                                          marginBottom: 8,
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      {/* Nav to Lesson Details */}
                                      <Pressable
                                        onPress={() => {
                                          try {
                                            amplitudeEvents(
                                              Variables,
                                              'view client details',
                                              {
                                                origin: 'homescreen',
                                                category: 'client management',
                                              },
                                              undefined
                                            );
                                            navigation.navigate(
                                              'LessonDetailsAndManagementScreen',
                                              {
                                                lessonKnackId:
                                                  lessonsPaidYearToDateData?.recordId,
                                                lessonPackageKnackId:
                                                  lessonsPaidYearToDateData?.packageKnackId,
                                              }
                                            );
                                          } catch (err) {
                                            console.error(err);
                                          }
                                        }}
                                      >
                                        {/* Client Block */}
                                        <View
                                          {...GlobalStyles.ViewStyles(theme)[
                                            'client header component'
                                          ].props}
                                          style={StyleSheet.applyWidth(
                                            StyleSheet.compose(
                                              GlobalStyles.ViewStyles(theme)[
                                                'client header component'
                                              ].style,
                                              {
                                                alignItems: 'center',
                                                borderBottomWidth: 0,
                                                paddingBottom: 4,
                                                paddingLeft: 8,
                                                paddingRight: 8,
                                                paddingTop: 4,
                                              }
                                            ),
                                            dimensions.width
                                          )}
                                        >
                                          {/* View Left Details */}
                                          <View
                                            style={StyleSheet.applyWidth(
                                              {
                                                flexDirection: 'column',
                                                width: '75%',
                                              },
                                              dimensions.width
                                            )}
                                          >
                                            <View>
                                              {/* View Client Details */}
                                              <View
                                                style={StyleSheet.applyWidth(
                                                  {
                                                    alignItems: 'center',
                                                    flexDirection: 'row',
                                                    justifyContent:
                                                      'space-between',
                                                  },
                                                  dimensions.width
                                                )}
                                              >
                                                {/* Client */}
                                                <View
                                                  style={StyleSheet.applyWidth(
                                                    { flexDirection: 'column' },
                                                    dimensions.width
                                                  )}
                                                >
                                                  {/* Client Name */}
                                                  <Text
                                                    accessible={true}
                                                    style={StyleSheet.applyWidth(
                                                      {
                                                        color:
                                                          palettes.App[
                                                            'App Black 191F32'
                                                          ],
                                                        fontFamily:
                                                          'Poppins_600SemiBold',
                                                        fontSize: 12,
                                                      },
                                                      dimensions.width
                                                    )}
                                                  >
                                                    {getFirstNameLastInitial(
                                                      lessonsPaidYearToDateData?.clientName
                                                    )}{' '}
                                                  </Text>
                                                </View>
                                                {/* Lesson */}
                                                <View>
                                                  {/* Lesson Number */}
                                                  <Text
                                                    accessible={true}
                                                    style={StyleSheet.applyWidth(
                                                      {
                                                        color:
                                                          palettes.App[
                                                            'App Text 2ndary 7F868C'
                                                          ],
                                                        fontFamily:
                                                          'Poppins_400Regular',
                                                        fontSize: 12,
                                                        textTransform:
                                                          'capitalize',
                                                      },
                                                      dimensions.width
                                                    )}
                                                  >
                                                    {'Lesson '}
                                                    {
                                                      lessonsPaidYearToDateData?.lessonNumber
                                                    }
                                                  </Text>
                                                </View>
                                              </View>
                                              {/* Pay date */}
                                              <View
                                                style={StyleSheet.applyWidth(
                                                  {
                                                    flexDirection: 'row',
                                                    justifyContent:
                                                      'space-between',
                                                  },
                                                  dimensions.width
                                                )}
                                              >
                                                {/* Paid date */}
                                                <Text
                                                  accessible={true}
                                                  style={StyleSheet.applyWidth(
                                                    {
                                                      color:
                                                        palettes.App[
                                                          'App Text 2ndary 7F868C'
                                                        ],
                                                      fontFamily:
                                                        'Poppins_400Regular',
                                                      fontSize: 12,
                                                    },
                                                    dimensions.width
                                                  )}
                                                >
                                                  {'Paid '}
                                                  {
                                                    lessonsPaidYearToDateData?.dateLessonPaidFormatted
                                                  }
                                                </Text>
                                                {/* Lesson duration */}
                                                <Text
                                                  accessible={true}
                                                  style={StyleSheet.applyWidth(
                                                    {
                                                      color:
                                                        palettes.App[
                                                          'App Text 2ndary 7F868C'
                                                        ],
                                                      fontFamily:
                                                        'Poppins_400Regular',
                                                      fontSize: 12,
                                                    },
                                                    dimensions.width
                                                  )}
                                                >
                                                  {
                                                    lessonsPaidYearToDateData?.lessonDuration
                                                  }
                                                  {' mins'}
                                                </Text>
                                              </View>
                                            </View>
                                          </View>
                                          {/* Pay amount wrapper */}
                                          <View
                                            style={StyleSheet.applyWidth(
                                              { alignItems: 'flex-end' },
                                              dimensions.width
                                            )}
                                          >
                                            {/* Pay Background */}
                                            <View
                                              style={StyleSheet.applyWidth(
                                                {
                                                  backgroundColor:
                                                    palettes.App[
                                                      'App Mint #F0F8F2'
                                                    ],
                                                  borderRadius: 8,
                                                  paddingBottom: 8,
                                                  paddingLeft: 8,
                                                  paddingRight: 8,
                                                  paddingTop: 8,
                                                },
                                                dimensions.width
                                              )}
                                            >
                                              {/* Pay amount */}
                                              <Text
                                                accessible={true}
                                                style={StyleSheet.applyWidth(
                                                  {
                                                    color:
                                                      palettes.App[
                                                        'App Text Green #1DB058'
                                                      ],
                                                    fontFamily:
                                                      'Poppins_400Regular',
                                                    fontSize: 12,
                                                  },
                                                  dimensions.width
                                                )}
                                              >
                                                {'$'}
                                                {
                                                  lessonsPaidYearToDateData?.lessonPay
                                                }
                                              </Text>
                                            </View>
                                          </View>
                                        </View>
                                      </Pressable>
                                    </View>
                                  </>
                                );
                              }}
                              showsHorizontalScrollIndicator={true}
                              showsVerticalScrollIndicator={true}
                            />
                          </ScrollView>
                        </View>
                      )}
                    </>
                    {/* Close Schedule Lesson List */}
                    <View
                      style={StyleSheet.applyWidth(
                        { alignItems: 'center', marginTop: 16, paddingTop: 0 },
                        dimensions.width
                      )}
                    >
                      {/* Close */}
                      <Link
                        accessible={true}
                        onPress={() => {
                          try {
                            closeEarningsView();
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        {...GlobalStyles.LinkStyles(theme)['Link'].props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.LinkStyles(theme)['Link'].style,
                            { color: palettes.App['App Text 2ndary 7F868C'] }
                          ),
                          dimensions.width
                        )}
                        title={'close x'}
                      />
                    </View>
                  </View>
                </Surface>
              </View>
            </View>
          )}
        </>
        {/* Upcoming Lessons Container */}
        <View
          style={StyleSheet.applyWidth(
            { borderRadius: 8, marginBottom: 8 },
            dimensions.width
          )}
        >
          {/* Banner Container Loading */}
          <>
            {!is_loading_scheduled_jobs ? null : (
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    alignSelf: 'auto',
                    backgroundColor: palettes.App['Custom Color'],
                    borderRadius: 8,
                    padding: 4,
                  },
                  dimensions.width
                )}
              >
                <ActivityIndicator
                  animating={true}
                  hidesWhenStopped={true}
                  size={'small'}
                  {...GlobalStyles.ActivityIndicatorStyles(theme)[
                    'Activity Indicator'
                  ].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.ActivityIndicatorStyles(theme)[
                      'Activity Indicator'
                    ].style,
                    dimensions.width
                  )}
                />
              </View>
            )}
          </>
          {/* Upcoming Lessons */}
          <>
            {!(upcomingScheduledLessons?.length > 0) ? null : (
              <View>
                {/* Banner Container */}
                <>
                  {!showScheduleBanner ? null : (
                    <View>
                      <Pressable
                        onPress={() => {
                          try {
                            setShowScheduleBanner(false);
                            setShowScheduleCard(true);
                            const endLimit =
                              fixLimitForScheduledLessons(undefined);
                            shouldShowViewMoreScheduledButton(endLimit);
                            amplitudeEvents(
                              Variables,
                              'expand upcoming lessons',
                              {
                                origin: 'homescreen',
                                category: 'lesson management',
                              },
                              undefined
                            );
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                      >
                        {/* Banner */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              backgroundColor: palettes.App['Custom Color'],
                              borderRadius: 8,
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              paddingBottom: 8,
                              paddingLeft: 20,
                              paddingRight: 20,
                              paddingTop: 12,
                            },
                            dimensions.width
                          )}
                        >
                          {/* Label Container */}
                          <View
                            style={StyleSheet.applyWidth(
                              { flexDirection: 'row' },
                              dimensions.width
                            )}
                          >
                            {/* Calendar */}
                            <Icon
                              size={24}
                              color={palettes.App['App Black 191F32']}
                              name={'MaterialCommunityIcons/calendar-clock'}
                              style={StyleSheet.applyWidth(
                                { marginRight: 4 },
                                dimensions.width
                              )}
                            />
                            {/* Next Scheduled Lesson */}
                            <Text
                              accessible={true}
                              {...GlobalStyles.TextStyles(theme)['Text'].props}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TextStyles(theme)['Text'].style,
                                  {
                                    color: palettes.App['App Black 191F32'],
                                    fontFamily: 'Poppins_400Regular',
                                    fontSize: 14,
                                  }
                                ),
                                dimensions.width
                              )}
                            >
                              {'Next Scheduled Lesson'}
                            </Text>
                          </View>
                          {/* Date Container */}
                          <View>
                            {/* Date */}
                            <Text
                              accessible={true}
                              style={StyleSheet.applyWidth(
                                {
                                  color: palettes.App['App Black 191F32'],
                                  fontFamily: 'Poppins_600SemiBold',
                                  fontSize: 14,
                                },
                                dimensions.width
                              )}
                            >
                              {convertTimeToLocalMachine(
                                upcomingScheduledLessons &&
                                  upcomingScheduledLessons[0].field_202,
                                'MM/DD/YYYY h:mm a',
                                'MMM DD h:mma'
                              )}
                            </Text>
                          </View>
                        </View>
                      </Pressable>
                    </View>
                  )}
                </>
                {/* Upcoming Card Container */}
                <>
                  {!showScheduleCard ? null : (
                    <Surface
                      {...GlobalStyles.SurfaceStyles(theme)['Surface'].props}
                      elevation={3}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.SurfaceStyles(theme)['Surface'].style,
                          { borderRadius: 16 }
                        ),
                        dimensions.width
                      )}
                    >
                      <View
                        {...GlobalStyles.ViewStyles(theme)['card block'].props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.ViewStyles(theme)['card block'].style,
                            {
                              backgroundColor: palettes.App['Custom Color'],
                              borderRadius: 16,
                              marginBottom: 8,
                              padding: 20,
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {/* Header row */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: 'flex-start',
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                            },
                            dimensions.width
                          )}
                        >
                          {/* View Title */}
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: 'baseline',
                                flexDirection: 'row',
                                marginBottom: 16,
                              },
                              dimensions.width
                            )}
                          >
                            {/* H1 */}
                            <Text
                              accessible={true}
                              style={StyleSheet.applyWidth(
                                {
                                  color: palettes.App['App Black 191F32'],
                                  fontFamily: 'BebasNeue_400Regular',
                                  fontSize: 24,
                                  marginRight: 4,
                                },
                                dimensions.width
                              )}
                            >
                              {'Upcoming'}
                            </Text>
                            {/* H1 Accent */}
                            <Text
                              accessible={true}
                              style={StyleSheet.applyWidth(
                                {
                                  color: palettes.App['App Red EB3E26'],
                                  fontFamily: 'LeckerliOne_400Regular',
                                  fontSize: 22,
                                },
                                dimensions.width
                              )}
                            >
                              {'Lessons'}
                            </Text>
                          </View>
                          {/* Close Schedule Card */}
                          <View
                            {...GlobalStyles.ViewStyles(theme)['modal close 2']
                              .props}
                            style={StyleSheet.applyWidth(
                              GlobalStyles.ViewStyles(theme)['modal close 2']
                                .style,
                              dimensions.width
                            )}
                          >
                            {/* View Close Button */}
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  backgroundColor:
                                    palettes.App['App Disabled Button EDEDEF'],
                                  borderRadius: 40,
                                  paddingBottom: 4,
                                  paddingLeft: 6,
                                  paddingRight: 6,
                                  paddingTop: 4,
                                },
                                dimensions.width
                              )}
                            >
                              <Pressable
                                onPress={() => {
                                  try {
                                    closeScheduledLessonsView();
                                  } catch (err) {
                                    console.error(err);
                                  }
                                }}
                              >
                                {/* Close */}
                                <Text
                                  accessible={true}
                                  style={StyleSheet.applyWidth(
                                    {
                                      color:
                                        palettes.App['App Text 2ndary 7F868C'],
                                      fontFamily: 'Poppins_400Regular',
                                      fontSize: 12,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {'close x'}
                                </Text>
                              </Pressable>
                            </View>
                          </View>
                        </View>
                        {/* Scheduled Lessons */}
                        <FlashList
                          data={fixVisibleScheduledLessons(
                            upcomingScheduledLessons
                          )}
                          estimatedItemSize={50}
                          horizontal={false}
                          inverted={false}
                          keyExtractor={(scheduledLessonsData, index) =>
                            scheduledLessonsData?.id ??
                            scheduledLessonsData?.uuid ??
                            index.toString()
                          }
                          listKey={'FqHwVz4n'}
                          numColumns={1}
                          onEndReachedThreshold={0.5}
                          renderItem={({ item, index }) => {
                            const scheduledLessonsData = item;
                            return (
                              <>
                                {/* Lesson Details */}
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      backgroundColor:
                                        palettes.App['Custom Color'],
                                      borderColor:
                                        palettes.App['App Grey Inputs E2E2E2'],
                                      borderRadius: 16,
                                      borderWidth: 1,
                                      marginBottom: 8,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {/* Nav to Client Details */}
                                  <Pressable
                                    onPress={() => {
                                      try {
                                        amplitudeEvents(
                                          Variables,
                                          'view client details',
                                          {
                                            origin: 'homescreen',
                                            category: 'client management',
                                          },
                                          undefined
                                        );
                                        navigation.navigate(
                                          'LessonDetailsAndManagementScreen',
                                          {
                                            lessonKnackId:
                                              scheduledLessonsData?.id,
                                            lessonPackageKnackId:
                                              scheduledLessonsData
                                                ?.field_58_raw[0].id,
                                          }
                                        );
                                      } catch (err) {
                                        console.error(err);
                                      }
                                    }}
                                  >
                                    {/* Client Block */}
                                    <View
                                      {...GlobalStyles.ViewStyles(theme)[
                                        'client header component'
                                      ].props}
                                      style={StyleSheet.applyWidth(
                                        StyleSheet.compose(
                                          GlobalStyles.ViewStyles(theme)[
                                            'client header component'
                                          ].style,
                                          {
                                            alignItems: 'center',
                                            borderBottomWidth: 1,
                                            borderColor:
                                              palettes.App[
                                                'App Grey Inputs E2E2E2'
                                              ],
                                            paddingBottom: 16,
                                            paddingLeft: 16,
                                            paddingRight: 16,
                                            paddingTop: 18,
                                          }
                                        ),
                                        dimensions.width
                                      )}
                                    >
                                      {/* View Left Details */}
                                      <View
                                        style={StyleSheet.applyWidth(
                                          { flexDirection: 'row' },
                                          dimensions.width
                                        )}
                                      >
                                        {/* View Client PP */}
                                        <View
                                          style={StyleSheet.applyWidth(
                                            {
                                              height: 48,
                                              marginRight: 8,
                                              width: 48,
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          <Surface
                                            elevation={3}
                                            style={StyleSheet.applyWidth(
                                              {
                                                borderBottomWidth: 1.25,
                                                borderColor:
                                                  palettes.App['Custom Color'],
                                                borderLeftWidth: 1.25,
                                                borderRadius: 100,
                                                borderRightWidth: 1.25,
                                                borderTopWidth: 1.25,
                                                height: '100%',
                                                width: '100%',
                                              },
                                              dimensions.width
                                            )}
                                          >
                                            {/* SVG Avatar */}
                                            <SVG
                                              source={Constants['uiAvatar3']}
                                              style={StyleSheet.applyWidth(
                                                {
                                                  height: '100%',
                                                  width: '100%',
                                                },
                                                dimensions.width
                                              )}
                                            />
                                          </Surface>
                                          {/* View Initials Container */}
                                          <View
                                            style={StyleSheet.applyWidth(
                                              {
                                                alignItems: 'center',
                                                height: 48,
                                                justifyContent: 'center',
                                                marginTop: -48,
                                                width: 48,
                                              },
                                              dimensions.width
                                            )}
                                          >
                                            {/* Initials */}
                                            <Text
                                              accessible={true}
                                              style={StyleSheet.applyWidth(
                                                {
                                                  color:
                                                    palettes.App[
                                                      'Custom Color'
                                                    ],
                                                  fontFamily:
                                                    'Poppins_500Medium',
                                                  fontSize: 24,
                                                  textTransform: 'uppercase',
                                                },
                                                dimensions.width
                                              )}
                                            >
                                              {getUserInitials(
                                                scheduledLessonsData?.field_455_raw
                                              )}
                                            </Text>
                                          </View>
                                        </View>
                                        {/* View Client Details */}
                                        <View>
                                          {/* Lesson */}
                                          <View>
                                            {/* Lesson Number */}
                                            <Text
                                              accessible={true}
                                              style={StyleSheet.applyWidth(
                                                {
                                                  color:
                                                    palettes.App[
                                                      'App Black 191F32'
                                                    ],
                                                  fontFamily:
                                                    'Poppins_600SemiBold',
                                                  textTransform: 'capitalize',
                                                },
                                                dimensions.width
                                              )}
                                            >
                                              {'Lesson '}
                                              {
                                                scheduledLessonsData?.field_320_raw
                                              }
                                            </Text>
                                          </View>
                                          {/* Client */}
                                          <View>
                                            {/* Client Name */}
                                            <Text
                                              accessible={true}
                                              style={StyleSheet.applyWidth(
                                                {
                                                  color:
                                                    palettes.App[
                                                      'App Text 2ndary 7F868C'
                                                    ],
                                                  fontFamily:
                                                    'Poppins_400Regular',
                                                  fontSize: 12,
                                                },
                                                dimensions.width
                                              )}
                                            >
                                              {getFirstNameLastInitial(
                                                scheduledLessonsData?.field_455_raw
                                              )}
                                            </Text>
                                          </View>
                                        </View>
                                      </View>
                                      {/* Time Wrapper */}
                                      <View
                                        style={StyleSheet.applyWidth(
                                          { alignItems: 'flex-end' },
                                          dimensions.width
                                        )}
                                      >
                                        {/* Time Background */}
                                        <View
                                          style={StyleSheet.applyWidth(
                                            {
                                              backgroundColor:
                                                palettes.App[
                                                  'App Blue/Low Emphasis'
                                                ],
                                              borderRadius: 8,
                                              paddingBottom: 8,
                                              paddingLeft: 8,
                                              paddingRight: 8,
                                              paddingTop: 8,
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          {/* Lesson Date Time */}
                                          <Text
                                            accessible={true}
                                            style={StyleSheet.applyWidth(
                                              {
                                                color:
                                                  palettes.App[
                                                    'App Blue#4 007fd4'
                                                  ],
                                                fontFamily:
                                                  'Poppins_400Regular',
                                                fontSize: 12,
                                              },
                                              dimensions.width
                                            )}
                                          >
                                            {convertTimeToLocalMachine(
                                              scheduledLessonsData?.field_202,
                                              'MM/DD/YYYY h:mm A',
                                              'ddd MMM DD, h:mmA'
                                            )}
                                          </Text>
                                        </View>
                                      </View>
                                    </View>
                                  </Pressable>
                                  {/* Address Block */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        alignItems: 'center',
                                        flexDirection: 'row',
                                        justifyContent: 'space-between',
                                        paddingBottom: 18,
                                        paddingLeft: 16,
                                        paddingRight: 16,
                                        paddingTop: 16,
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    {/* Street */}
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          alignItems: 'center',
                                          flexDirection: 'row',
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      {/* Pin */}
                                      <View
                                        style={StyleSheet.applyWidth(
                                          {
                                            height: 15,
                                            marginRight: 8,
                                            width: 12,
                                          },
                                          dimensions.width
                                        )}
                                      >
                                        <SVG
                                          {...GlobalStyles.SVGStyles(theme)[
                                            'SVG'
                                          ].props}
                                          source={Constants['uiLocationPin']}
                                          style={StyleSheet.applyWidth(
                                            StyleSheet.compose(
                                              GlobalStyles.SVGStyles(theme)[
                                                'SVG'
                                              ].style,
                                              { height: '100%', width: '100%' }
                                            ),
                                            dimensions.width
                                          )}
                                        />
                                      </View>
                                      {/* Street */}
                                      <View>
                                        <Text
                                          accessible={true}
                                          style={StyleSheet.applyWidth(
                                            {
                                              color:
                                                palettes.App[
                                                  'App Text 2ndary 7F868C'
                                                ],
                                              fontFamily: 'Poppins_400Regular',
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          {
                                            scheduledLessonsData?.field_98_raw
                                              .street
                                          }
                                        </Text>
                                      </View>
                                    </View>
                                    {/* Navigation Button */}
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          alignItems: 'center',
                                          backgroundColor:
                                            palettes.App['App Red EB3E26'],
                                          borderRadius: 100,
                                          height: 32,
                                          justifyContent: 'center',
                                          width: 32,
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      <Pressable
                                        onPress={() => {
                                          try {
                                            openMap(
                                              scheduledLessonsData?.field_98_raw
                                                .street,
                                              scheduledLessonsData?.field_98_raw
                                                .city,
                                              scheduledLessonsData?.field_98_raw
                                                .zip
                                            );
                                            amplitudeEvents(
                                              Variables,
                                              'view directions to lesson in native map',
                                              {
                                                origin: 'homescreen',
                                                category: 'lesson management',
                                              },
                                              undefined
                                            );
                                          } catch (err) {
                                            console.error(err);
                                          }
                                        }}
                                      >
                                        <SVG
                                          {...GlobalStyles.SVGStyles(theme)[
                                            'SVG'
                                          ].props}
                                          source={Constants['uiLocationArrow']}
                                          style={StyleSheet.applyWidth(
                                            StyleSheet.compose(
                                              GlobalStyles.SVGStyles(theme)[
                                                'SVG'
                                              ].style,
                                              { height: 12, width: 12 }
                                            ),
                                            dimensions.width
                                          )}
                                        />
                                      </Pressable>
                                    </View>
                                  </View>
                                </View>
                              </>
                            );
                          }}
                          showsHorizontalScrollIndicator={true}
                          showsVerticalScrollIndicator={true}
                        />
                        {/* View More Lessons */}
                        <>
                          {!showViewMoreScheduledButton ? null : (
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: 'center',
                                  marginTop: 16,
                                  paddingTop: 0,
                                },
                                dimensions.width
                              )}
                            >
                              {/* View more */}
                              <Link
                                accessible={true}
                                onPress={() => {
                                  try {
                                    const endLimit =
                                      fixLimitForScheduledLessons(3);
                                    shouldShowViewMoreScheduledButton(endLimit);
                                  } catch (err) {
                                    console.error(err);
                                  }
                                }}
                                {...GlobalStyles.LinkStyles(theme)['Link']
                                  .props}
                                style={StyleSheet.applyWidth(
                                  GlobalStyles.LinkStyles(theme)['Link'].style,
                                  dimensions.width
                                )}
                                title={'View more'}
                              />
                            </View>
                          )}
                        </>
                        {/* All scheduled viewed */}
                        <>
                          {showViewMoreScheduledButton ? null : (
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: 'center',
                                  marginTop: 16,
                                  paddingTop: 0,
                                },
                                dimensions.width
                              )}
                            >
                              {/* All scheduled lessons viewed */}
                              <Link
                                accessible={true}
                                onPress={() => {
                                  try {
                                    navigation.navigate('Clients2Screen');
                                  } catch (err) {
                                    console.error(err);
                                  }
                                }}
                                {...GlobalStyles.LinkStyles(theme)['Link']
                                  .props}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.LinkStyles(theme)['Link']
                                      .style,
                                    { textAlign: 'center' }
                                  ),
                                  dimensions.width
                                )}
                                title={
                                  'All scheduled lessons in view. Schedule more lessons.'
                                }
                              />
                            </View>
                          )}
                        </>
                        {/* Close Schedule Lesson List */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: 'center',
                              marginTop: 16,
                              paddingTop: 0,
                            },
                            dimensions.width
                          )}
                        >
                          {/* Close */}
                          <Link
                            accessible={true}
                            onPress={() => {
                              try {
                                closeScheduledLessonsView();
                              } catch (err) {
                                console.error(err);
                              }
                            }}
                            {...GlobalStyles.LinkStyles(theme)['Link'].props}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.LinkStyles(theme)['Link'].style,
                                {
                                  color: palettes.App['App Text 2ndary 7F868C'],
                                }
                              ),
                              dimensions.width
                            )}
                            title={'close x'}
                          />
                        </View>
                      </View>
                    </Surface>
                  )}
                </>
              </View>
            )}
          </>
        </View>
        {/* Loading Open Jobs */}
        <>
          {!is_loading_open_jobs ? null : (
            <Surface
              {...GlobalStyles.SurfaceStyles(theme)['Surface'].props}
              elevation={3}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.SurfaceStyles(theme)['Surface'].style,
                  { borderRadius: 16, marginBottom: 32 }
                ),
                dimensions.width
              )}
            >
              {/* View Card */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    backgroundColor: palettes.App['Custom Color'],
                    borderRadius: 16,
                    padding: 20,
                  },
                  dimensions.width
                )}
              >
                {/* Card Title Placeholder */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      backgroundColor:
                        palettes.App['App Disabled Button EDEDEF'],
                      borderRadius: 8,
                      flexDirection: 'row',
                      height: 32,
                      justifyContent: 'center',
                      marginBottom: 20,
                    },
                    dimensions.width
                  )}
                />
                {/* Open Job Count Placeholder */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      backgroundColor:
                        palettes.App['App Disabled Button EDEDEF'],
                      borderRadius: 8,
                      flexDirection: 'row',
                      height: 256,
                      justifyContent: 'center',
                      marginBottom: 20,
                    },
                    dimensions.width
                  )}
                >
                  <ActivityIndicator
                    animating={true}
                    hidesWhenStopped={true}
                    size={'small'}
                    {...GlobalStyles.ActivityIndicatorStyles(theme)[
                      'Activity Indicator'
                    ].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.ActivityIndicatorStyles(theme)[
                        'Activity Indicator'
                      ].style,
                      dimensions.width
                    )}
                  />
                </View>
                {/* H2 Container Placeholder */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      backgroundColor:
                        palettes.App['App Disabled Button EDEDEF'],
                      borderRadius: 8,
                      height: 32,
                      marginBottom: 8,
                    },
                    dimensions.width
                  )}
                />
                {/* Placeholder */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      backgroundColor:
                        palettes.App['App Disabled Button EDEDEF'],
                      borderRadius: 8,
                      height: 96,
                    },
                    dimensions.width
                  )}
                />
              </View>
            </Surface>
          )}
        </>
        {/* Jobs Load Complete */}
        <>
          {is_loading_open_jobs ? null : (
            <View>
              {/* Open Jobs Available */}
              <>
                {!(openJobsCount >= 1) ? null : (
                  <Surface
                    {...GlobalStyles.SurfaceStyles(theme)['Surface'].props}
                    elevation={3}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.SurfaceStyles(theme)['Surface'].style,
                        { borderRadius: 16, marginBottom: 32 }
                      ),
                      dimensions.width
                    )}
                  >
                    {/* View Card */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          backgroundColor: palettes.App['Custom Color'],
                          borderRadius: 16,
                          padding: 20,
                        },
                        dimensions.width
                      )}
                    >
                      {/* Card Title */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            flexDirection: 'row',
                            justifyContent: 'center',
                            marginBottom: 20,
                          },
                          dimensions.width
                        )}
                      >
                        {/* H1 */}
                        <Text
                          accessible={true}
                          style={StyleSheet.applyWidth(
                            {
                              color: palettes.App['App Black 191F32'],
                              fontFamily: 'BebasNeue_400Regular',
                              fontSize: 24,
                              marginRight: 4,
                            },
                            dimensions.width
                          )}
                        >
                          {'Job'}
                        </Text>
                        {/* H1 Accent */}
                        <Text
                          accessible={true}
                          style={StyleSheet.applyWidth(
                            {
                              color: palettes.App['App Red EB3E26'],
                              fontFamily: 'LeckerliOne_400Regular',
                              fontSize: 22,
                            },
                            dimensions.width
                          )}
                        >
                          {'Alert'}
                        </Text>
                      </View>

                      <Pressable
                        onPress={() => {
                          try {
                            amplitudeEvents(
                              Variables,
                              'view open jobs list',
                              {
                                origin: 'homescreen',
                                category: 'opportunity management',
                              },
                              undefined
                            );
                            navigation.navigate('JobsScreen');
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                      >
                        {/* Open Job Count */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              flexDirection: 'row',
                              justifyContent: 'center',
                              marginBottom: 20,
                            },
                            dimensions.width
                          )}
                        >
                          {/* Count */}
                          <Text
                            accessible={true}
                            style={StyleSheet.applyWidth(
                              {
                                color: palettes.App['App Red EB3E26'],
                                fontFamily: 'Poppins_500Medium',
                                fontSize: 124,
                                marginRight: 4,
                              },
                              dimensions.width
                            )}
                          >
                            {openJobsCount}
                          </Text>
                        </View>
                        {/* H2 Container */}
                        <View
                          style={StyleSheet.applyWidth(
                            { marginBottom: 8 },
                            dimensions.width
                          )}
                        >
                          <Text
                            accessible={true}
                            {...GlobalStyles.TextStyles(theme)['Text'].props}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['Text'].style,
                                {
                                  fontFamily: 'Poppins_600SemiBold',
                                  fontSize: 20,
                                  textAlign: 'center',
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {'Open Jobs Near Me'}
                          </Text>
                        </View>

                        <View>
                          <Text
                            accessible={true}
                            {...GlobalStyles.TextStyles(theme)['Text'].props}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['Text'].style,
                                {
                                  color:
                                    palettes.App[
                                      'App Disabled Button Text 7F868C'
                                    ],
                                  fontFamily: 'Poppins_400Regular',
                                  textAlign: 'center',
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {"View and request jobs now before they're taken!"}
                          </Text>
                        </View>
                      </Pressable>
                      {/* Button Container */}
                      <View
                        style={StyleSheet.applyWidth(
                          { alignItems: 'center', marginTop: 20 },
                          dimensions.width
                        )}
                      >
                        {/* Browse open jobs */}
                        <Button
                          iconPosition={'left'}
                          onPress={() => {
                            try {
                              amplitudeEvents(
                                Variables,
                                'view open jobs list',
                                {
                                  origin: 'homescreen',
                                  category: 'opportunity management',
                                },
                                undefined
                              );
                              navigation.navigate('JobsScreen');
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.ButtonStyles(theme)['Button'].style,
                              {
                                backgroundColor: palettes.App['App Red EB3E26'],
                                borderRadius: 50,
                                fontFamily: 'BebasNeue_400Regular',
                                fontSize: 18,
                                minWidth: '60%',
                                paddingBottom: 14,
                                paddingLeft: 20,
                                paddingRight: 20,
                                paddingTop: 14,
                              }
                            ),
                            dimensions.width
                          )}
                          title={'View Jobs'}
                        />
                      </View>
                    </View>
                  </Surface>
                )}
              </>
              {/* Open Jobs Not Available */}
              <>
                {!(openJobsCount === 0) ? null : (
                  <Surface
                    {...GlobalStyles.SurfaceStyles(theme)['Surface'].props}
                    elevation={3}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.SurfaceStyles(theme)['Surface'].style,
                        { borderRadius: 16, marginBottom: 32 }
                      ),
                      dimensions.width
                    )}
                  >
                    {/* View Card */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          backgroundColor: palettes.App['Custom Color'],
                          borderRadius: 16,
                          padding: 20,
                        },
                        dimensions.width
                      )}
                    >
                      {/* Card Title */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            flexDirection: 'row',
                            justifyContent: 'center',
                            marginBottom: 20,
                          },
                          dimensions.width
                        )}
                      >
                        {/* H1 */}
                        <Text
                          accessible={true}
                          style={StyleSheet.applyWidth(
                            {
                              color: palettes.App['App Black 191F32'],
                              fontFamily: 'BebasNeue_400Regular',
                              fontSize: 24,
                              marginRight: 4,
                            },
                            dimensions.width
                          )}
                        >
                          {'Job'}
                        </Text>
                        {/* H1 Accent */}
                        <Text
                          accessible={true}
                          style={StyleSheet.applyWidth(
                            {
                              color: palettes.App['App Red EB3E26'],
                              fontFamily: 'LeckerliOne_400Regular',
                              fontSize: 22,
                            },
                            dimensions.width
                          )}
                        >
                          {'Alert'}
                        </Text>
                      </View>
                      {/* Open Job Count */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            flexDirection: 'row',
                            justifyContent: 'center',
                            marginBottom: 20,
                          },
                          dimensions.width
                        )}
                      >
                        <SVG
                          {...GlobalStyles.SVGStyles(theme)['SVG'].props}
                          source={Constants['uiDrawingStarfishLounging']}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.SVGStyles(theme)['SVG'].style,
                              { height: 256, width: 232 }
                            ),
                            dimensions.width
                          )}
                        />
                      </View>
                      {/* H2 Container */}
                      <View
                        style={StyleSheet.applyWidth(
                          { marginBottom: 8 },
                          dimensions.width
                        )}
                      >
                        <Text
                          accessible={true}
                          {...GlobalStyles.TextStyles(theme)['Text'].props}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'].style,
                              {
                                fontFamily: 'Poppins_600SemiBold',
                                fontSize: 20,
                                textAlign: 'center',
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {'All Caught Up'}
                        </Text>
                      </View>

                      <View>
                        <Text
                          accessible={true}
                          {...GlobalStyles.TextStyles(theme)['Text'].props}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'].style,
                              {
                                color:
                                  palettes.App[
                                    'App Disabled Button Text 7F868C'
                                  ],
                                fontFamily: 'Poppins_400Regular',
                                textAlign: 'center',
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {
                            "Take a breather. There are currently no open jobs that need your attention. We'll notify you once a new job becomes available!"
                          }
                        </Text>
                      </View>
                    </View>
                  </Surface>
                )}
              </>
            </View>
          )}
        </>
        {/* Loading Current Clients */}
        <>
          {!is_loading_clients ? null : (
            <Surface
              {...GlobalStyles.SurfaceStyles(theme)['Surface'].props}
              elevation={3}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.SurfaceStyles(theme)['Surface'].style,
                  {
                    alignItems: 'center',
                    backgroundColor: palettes.App['App Disabled Button EDEDEF'],
                    borderRadius: 16,
                    justifyContent: 'center',
                    marginBottom: 32,
                    minHeight: 120,
                  }
                ),
                dimensions.width
              )}
            >
              <ActivityIndicator
                animating={true}
                hidesWhenStopped={true}
                size={'small'}
                {...GlobalStyles.ActivityIndicatorStyles(theme)[
                  'Activity Indicator'
                ].props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.ActivityIndicatorStyles(theme)[
                    'Activity Indicator'
                  ].style,
                  dimensions.width
                )}
              />
            </Surface>
          )}
        </>
        {/* Current Clients Load Complete */}
        <>
          {is_loading_clients ? null : (
            <View>
              {/* Current Clients Card */}
              <>
                {!(clientsCount > 0) ? null : (
                  <Surface
                    {...GlobalStyles.SurfaceStyles(theme)['Surface'].props}
                    elevation={3}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.SurfaceStyles(theme)['Surface'].style,
                        { borderRadius: 16, marginBottom: 32 }
                      ),
                      dimensions.width
                    )}
                  >
                    {/* View Card */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          backgroundColor: palettes.App['Custom Color'],
                          borderRadius: 16,
                          padding: 20,
                        },
                        dimensions.width
                      )}
                    >
                      {/* Card Title */}
                      <View
                        style={StyleSheet.applyWidth(
                          { flexDirection: 'row', marginBottom: 20 },
                          dimensions.width
                        )}
                      >
                        {/* H1 */}
                        <Text
                          accessible={true}
                          style={StyleSheet.applyWidth(
                            {
                              color: palettes.App['App Black 191F32'],
                              fontFamily: 'BebasNeue_400Regular',
                              fontSize: 24,
                              marginRight: 4,
                            },
                            dimensions.width
                          )}
                        >
                          {'Current'}
                        </Text>
                        {/* H1 Accent */}
                        <Text
                          accessible={true}
                          style={StyleSheet.applyWidth(
                            {
                              color: palettes.App['App Red EB3E26'],
                              fontFamily: 'LeckerliOne_400Regular',
                              fontSize: 22,
                            },
                            dimensions.width
                          )}
                        >
                          {'Clients'}
                        </Text>
                      </View>
                      {/* Clients */}
                      <View
                        style={StyleSheet.applyWidth(
                          { flexDirection: 'row' },
                          dimensions.width
                        )}
                      >
                        <ScrollView
                          bounces={true}
                          keyboardShouldPersistTaps={'never'}
                          nestedScrollEnabled={false}
                          showsHorizontalScrollIndicator={true}
                          showsVerticalScrollIndicator={true}
                          contentContainerStyle={StyleSheet.applyWidth(
                            { alignItems: 'center', flexDirection: 'row' },
                            dimensions.width
                          )}
                          horizontal={true}
                        >
                          <FlashList
                            data={filterCompletedPackages(uniquePackages)}
                            estimatedItemSize={50}
                            inverted={false}
                            keyExtractor={(flashListData, index) =>
                              flashListData?.id ??
                              flashListData?.uuid ??
                              index.toString()
                            }
                            listKey={'KhtNRUiJ'}
                            numColumns={1}
                            onEndReachedThreshold={0.5}
                            renderItem={({ item, index }) => {
                              const flashListData = item;
                              return (
                                <>
                                  {/* Container */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        alignContent: 'center',
                                        alignItems: 'center',
                                        width: 90,
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    <Pressable
                                      onPress={() => {
                                        try {
                                          navigation.navigate(
                                            'ClientDetailsScreen',
                                            {
                                              packageKnackId:
                                                flashListData?.packageKnackId,
                                            }
                                          );
                                        } catch (err) {
                                          console.error(err);
                                        }
                                      }}
                                    >
                                      {/* View Client PP */}
                                      <View
                                        {...GlobalStyles.ViewStyles(theme)[
                                          'name icon'
                                        ].props}
                                        style={StyleSheet.applyWidth(
                                          StyleSheet.compose(
                                            GlobalStyles.ViewStyles(theme)[
                                              'name icon'
                                            ].style,
                                            {
                                              alignContent: 'center',
                                              alignItems: 'center',
                                              height: 64,
                                              width: 64,
                                            }
                                          ),
                                          dimensions.width
                                        )}
                                      >
                                        <Surface
                                          elevation={3}
                                          style={StyleSheet.applyWidth(
                                            {
                                              borderBottomWidth: 1.25,
                                              borderColor:
                                                palettes.App['Custom Color'],
                                              borderLeftWidth: 1.25,
                                              borderRadius: 100,
                                              borderRightWidth: 1.25,
                                              borderTopWidth: 1.25,
                                              height: '100%',
                                              width: '100%',
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          {/* SVG Avatar */}
                                          <SVG
                                            source={useCustomerAvatar(
                                              Variables,
                                              flashListData?.clientAvatar
                                            )}
                                            style={StyleSheet.applyWidth(
                                              { height: '100%', width: '100%' },
                                              dimensions.width
                                            )}
                                          />
                                        </Surface>
                                        {/* View Initials Container */}
                                        <View
                                          style={StyleSheet.applyWidth(
                                            {
                                              alignItems: 'center',
                                              height: 64,
                                              justifyContent: 'center',
                                              marginTop: -64,
                                              width: 64,
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          {/* Initials */}
                                          <Text
                                            accessible={true}
                                            style={StyleSheet.applyWidth(
                                              {
                                                color:
                                                  palettes.App['Custom Color'],
                                                fontFamily: 'Poppins_500Medium',
                                                fontSize: 24,
                                              },
                                              dimensions.width
                                            )}
                                          >
                                            {returnFirstInitial(
                                              flashListData?.client
                                            )}
                                          </Text>
                                        </View>
                                      </View>
                                      {/* View Client First Name */}
                                      <View
                                        style={StyleSheet.applyWidth(
                                          {
                                            alignContent: 'center',
                                            alignItems: 'center',
                                            marginTop: 8,
                                          },
                                          dimensions.width
                                        )}
                                      >
                                        <Text
                                          accessible={true}
                                          {...GlobalStyles.TextStyles(theme)[
                                            'Text'
                                          ].props}
                                          style={StyleSheet.applyWidth(
                                            StyleSheet.compose(
                                              GlobalStyles.TextStyles(theme)[
                                                'Text'
                                              ].style,
                                              {
                                                color:
                                                  palettes.App[
                                                    'App Disabled Button Text 7F868C'
                                                  ],
                                                fontFamily:
                                                  'Poppins_400Regular',
                                                textTransform: 'capitalize',
                                              }
                                            ),
                                            dimensions.width
                                          )}
                                        >
                                          {getFirstNameLastInitial(
                                            flashListData?.client
                                          )}
                                        </Text>
                                      </View>
                                    </Pressable>
                                  </View>
                                </>
                              );
                            }}
                            showsHorizontalScrollIndicator={true}
                            showsVerticalScrollIndicator={true}
                            horizontal={true}
                          />
                          {/* View Client List */}
                          <Button
                            iconPosition={'left'}
                            onPress={() => {
                              try {
                                navigation.navigate('Clients2Screen');
                              } catch (err) {
                                console.error(err);
                              }
                            }}
                            {...GlobalStyles.ButtonStyles(theme)['Button']
                              .props}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.ButtonStyles(theme)['Button']
                                  .style,
                                {
                                  backgroundColor:
                                    palettes.App['App Blue C7EAF3'],
                                  fontFamily: 'Poppins_500Medium',
                                  marginBottom: 24,
                                }
                              ),
                              dimensions.width
                            )}
                            title={'View Client List →'}
                          />
                        </ScrollView>
                      </View>
                    </View>
                  </Surface>
                )}
              </>
            </View>
          )}
        </>
        <KeyboardAvoidingView
          behavior={'padding'}
          enabled={true}
          keyboardVerticalOffset={0}
        >
          {/* Feedback Card */}
          <Surface
            {...GlobalStyles.SurfaceStyles(theme)['Surface'].props}
            elevation={3}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.SurfaceStyles(theme)['Surface'].style,
                { borderRadius: 16, marginBottom: 32 }
              ),
              dimensions.width
            )}
          >
            {/* View Card */}
            <View
              style={StyleSheet.applyWidth(
                {
                  backgroundColor: palettes.App['Custom Color'],
                  borderRadius: 16,
                  padding: 20,
                },
                dimensions.width
              )}
            >
              {/* Title */}
              <View
                style={StyleSheet.applyWidth(
                  { marginBottom: 8 },
                  dimensions.width
                )}
              >
                {/* Feedback and Support */}
                <Text
                  accessible={true}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Text'].style,
                      {
                        alignSelf: 'center',
                        fontFamily: 'Poppins_600SemiBold',
                        fontSize: 20,
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {'Feedback & App Support'}
                </Text>
              </View>
              {/* Prompt */}
              <View
                style={StyleSheet.applyWidth(
                  { marginBottom: 16 },
                  dimensions.width
                )}
              >
                {/* Your feedback is important */}
                <Text
                  accessible={true}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Text'].style,
                      {
                        color: palettes.App['App Disabled Button Text 7F868C'],
                        fontFamily: 'Poppins_400Regular',
                        textAlign: 'center',
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {
                    "Your feedback's important. Any time while using the app, please drop your comments, thoughts, or support requests here. "
                  }
                </Text>
              </View>
              {/* View Feedback Form */}
              <>
                {!showFeedbackForm ? null : (
                  <View>
                    {/* View Text Area Container */}
                    <View
                      {...GlobalStyles.ViewStyles(theme)['input text area']
                        .props}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ViewStyles(theme)['input text area']
                            .style,
                          { height: 175, marginTop: 10 }
                        ),
                        dimensions.width
                      )}
                    >
                      {/* SI Feedback Comment */}
                      <TextInput
                        changeTextDelay={500}
                        multiline={true}
                        numberOfLines={4}
                        onChangeText={newSIFeedbackCommentValue => {
                          try {
                            setSiFeedbackComment(newSIFeedbackCommentValue);
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        textAlignVertical={'top'}
                        webShowOutline={true}
                        autoCorrect={true}
                        editable={true}
                        placeholder={
                          'Your feedback, feature or support request'
                        }
                        style={StyleSheet.applyWidth(
                          {
                            borderBottomWidth: 1,
                            borderColor: palettes.App['App Grey Inputs E2E2E2'],
                            borderLeftWidth: 1,
                            borderRadius: 8,
                            borderRightWidth: 1,
                            borderTopWidth: 1,
                            color: palettes.App['App Black 191F32'],
                            fontFamily: 'Poppins_400Regular',
                            height: '100%',
                            paddingBottom: 16,
                            paddingLeft: 16,
                            paddingRight: 16,
                            paddingTop: 16,
                            width: '100%',
                          },
                          dimensions.width
                        )}
                        value={siFeedbackComment}
                      />
                    </View>
                    {/* View Send Button */}
                    <View
                      {...GlobalStyles.ViewStyles(theme)['View Buttons 2']
                        .props}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ViewStyles(theme)['View Buttons 2']
                            .style,
                          { marginTop: 10 }
                        ),
                        dimensions.width
                      )}
                    >
                      {/* Send */}
                      <>
                        {siFeedbackComment ? null : (
                          <Button
                            iconPosition={'left'}
                            style={StyleSheet.applyWidth(
                              {
                                backgroundColor:
                                  palettes.App['App Disabled Button EDEDEF'],
                                borderRadius: 50,
                                color:
                                  palettes.App[
                                    'App Disabled Button Text 7F868C'
                                  ],
                                fontFamily: 'BebasNeue_400Regular',
                                fontSize: 18,
                                paddingBottom: 14,
                                paddingTop: 14,
                                textAlign: 'center',
                                width: '100%',
                              },
                              dimensions.width
                            )}
                            title={'SEND'}
                          />
                        )}
                      </>
                      {/* Send */}
                      <>
                        {!siFeedbackComment ? null : (
                          <Button
                            iconPosition={'left'}
                            onPress={() => {
                              const handler = async () => {
                                try {
                                  setGlobalVariableValue({
                                    key: 'is_loading',
                                    value: true,
                                  });
                                  (
                                    await integromatReceiveOpenFeedbackPOST.mutateAsync(
                                      {
                                        APP_VERSION:
                                          Constants['APP_VERSION']?.identifier,
                                        feedback: siFeedbackComment,
                                        instructor:
                                          Constants['gInstructorEmailAddress'],
                                        rating: 5,
                                      }
                                    )
                                  )?.json;
                                  setGlobalVariableValue({
                                    key: 'is_loading',
                                    value: false,
                                  });
                                  setShowFeedbackForm(false);
                                  feedbackConfirmationSequence();
                                  amplitudeEvents(
                                    Variables,
                                    'sent feedback',
                                    {
                                      step: '02',
                                      funnel: 'feedback',
                                      origin: 'homescreen',
                                      category: 'support',
                                    },
                                    undefined
                                  );
                                } catch (err) {
                                  console.error(err);
                                }
                              };
                              handler();
                            }}
                            style={StyleSheet.applyWidth(
                              {
                                backgroundColor: palettes.App['App Red EB3E26'],
                                borderRadius: 50,
                                fontFamily: 'BebasNeue_400Regular',
                                fontSize: 18,
                                paddingBottom: 14,
                                paddingTop: 14,
                                textAlign: 'center',
                                width: '100%',
                              },
                              dimensions.width
                            )}
                            title={'SEND'}
                          />
                        )}
                      </>
                      {/* View Activity Indicator */}
                      <>
                        {!Constants['is_loading'] ? null : (
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: 'center',
                                backgroundColor: palettes.App['App Red EB3E26'],
                                borderRadius: 50,
                                marginTop: -50,
                                opacity: 0.8,
                                paddingBottom: 7,
                                paddingTop: 7,
                                width: '100%',
                              },
                              dimensions.width
                            )}
                          >
                            <ActivityIndicator
                              animating={true}
                              hidesWhenStopped={true}
                              size={'small'}
                              color={palettes.App['Custom Color']}
                              style={StyleSheet.applyWidth(
                                { height: 36, width: 36 },
                                dimensions.width
                              )}
                            />
                          </View>
                        )}
                      </>
                    </View>
                  </View>
                )}
              </>
              {/* Open Feedback Button */}
              <>
                {!showFeedbackShareButton ? null : (
                  <View
                    {...GlobalStyles.ViewStyles(theme)['View Buttons 2'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ViewStyles(theme)['View Buttons 2'].style,
                        { marginBottom: 0, marginTop: 10 }
                      ),
                      dimensions.width
                    )}
                  >
                    {/* Share Feedback Button */}
                    <Button
                      iconPosition={'left'}
                      onPress={() => {
                        try {
                          setShowFeedbackForm(true);
                          setShowFeedbackShareButton(false);
                          amplitudeEvents(
                            Variables,
                            'start share feedback',
                            {
                              step: '01',
                              funnel: 'feedback',
                              origin: 'homescreen',
                              category: 'support',
                            },
                            undefined
                          );
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      style={StyleSheet.applyWidth(
                        {
                          backgroundColor: palettes.App['App Red EB3E26'],
                          borderRadius: 50,
                          fontFamily: 'BebasNeue_400Regular',
                          fontSize: 18,
                          paddingBottom: 14,
                          paddingTop: 14,
                          textAlign: 'center',
                          width: '100%',
                        },
                        dimensions.width
                      )}
                      title={'Share Feedback Now'}
                    />
                    {/* View Activity Indicator */}
                    <>
                      {!Constants['is_loading'] ? null : (
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: 'center',
                              backgroundColor: palettes.App['App Red EB3E26'],
                              borderRadius: 50,
                              marginTop: -50,
                              opacity: 0.8,
                              paddingBottom: 7,
                              paddingTop: 7,
                              width: '100%',
                            },
                            dimensions.width
                          )}
                        >
                          <ActivityIndicator
                            animating={true}
                            hidesWhenStopped={true}
                            size={'small'}
                            color={palettes.App['Custom Color']}
                            style={StyleSheet.applyWidth(
                              { height: 36, width: 36 },
                              dimensions.width
                            )}
                          />
                        </View>
                      )}
                    </>
                  </View>
                )}
              </>
              {/* Feedback Sent Confirmation */}
              <>
                {!showFeedbackConfirmBlock ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        borderColor: palettes.App['App Text 2ndary 7F868C'],
                        borderRadius: 50,
                        borderWidth: 1,
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        marginTop: 10,
                        paddingBottom: 14,
                        paddingLeft: 16,
                        paddingRight: 16,
                        paddingTop: 14,
                      },
                      dimensions.width
                    )}
                  >
                    {/* Left view */}
                    <View
                      style={StyleSheet.applyWidth(
                        { alignItems: 'center', flexDirection: 'row' },
                        dimensions.width
                      )}
                    >
                      {/* Check */}
                      <Icon
                        size={24}
                        color={palettes.App['App Text Green #1DB058']}
                        name={'MaterialCommunityIcons/check-circle'}
                        style={StyleSheet.applyWidth(
                          { marginRight: 6 },
                          dimensions.width
                        )}
                      />
                      {/* Feedback sent */}
                      <Text
                        accessible={true}
                        {...GlobalStyles.TextStyles(theme)['Text'].props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'].style,
                            {
                              color: palettes.App['App Black 191F32'],
                              fontFamily: 'Poppins_400Regular',
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {feedbackConfirmationMsg}
                      </Text>
                    </View>
                    {/* Right view */}
                    <View>
                      {/* Sent Icon */}
                      <Icon
                        size={24}
                        color={palettes.App['App Text Green #1DB058']}
                        name={'MaterialCommunityIcons/sort-variant'}
                      />
                    </View>
                  </View>
                )}
              </>
            </View>
          </Surface>
        </KeyboardAvoidingView>
      </ScrollView>
      {/* View Footer Navigation 2023-09-11 */}
      <View
        style={StyleSheet.applyWidth(
          {
            backgroundColor: palettes.App['Custom #ffffff'],
            bottom: 0,
            minHeight: 67,
            position: 'absolute',
            width: '100%',
            zIndex: 100,
          },
          dimensions.width
        )}
      >
        <Surface
          {...GlobalStyles.SurfaceStyles(theme)['Surface'].props}
          elevation={3}
          style={StyleSheet.applyWidth(
            GlobalStyles.SurfaceStyles(theme)['Surface'].style,
            dimensions.width
          )}
        >
          {/* View Navigation Icons Container */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                alignSelf: 'auto',
                flex: 0,
                flexDirection: 'row',
                justifyContent: 'space-around',
                marginBottom: 12,
                marginTop: 12,
              },
              dimensions.width
            )}
          >
            {/* View Home Container */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  flex: 0,
                  justifyContent: 'space-between',
                  width: '20%',
                },
                dimensions.width
              )}
            >
              <Touchable
                onPress={() => {
                  try {
                    setGlobalVariableValue({
                      key: 'screen',
                      value: 'home',
                    });
                    navigation.navigate('HomeScreen');
                  } catch (err) {
                    console.error(err);
                  }
                }}
                style={StyleSheet.applyWidth(
                  { width: '100%' },
                  dimensions.width
                )}
              >
                {/* View Touchable Home */}
                <View
                  style={StyleSheet.applyWidth(
                    { alignItems: 'center', justifyContent: 'space-between' },
                    dimensions.width
                  )}
                >
                  {/* View Home Icon */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        height: 24,
                        justifyContent: 'center',
                        overflow: 'hidden',
                        width: 24,
                      },
                      dimensions.width
                    )}
                  >
                    {/* Home Icon Inactive */}
                    <>
                      {!(Constants['screen'] !== 'home') ? null : (
                        <ImageBackground
                          resizeMode={'cover'}
                          source={Images.IconHomeGrey}
                          style={StyleSheet.applyWidth(
                            { height: '100%', width: '100%' },
                            dimensions.width
                          )}
                        />
                      )}
                    </>
                    {/* Home Icon Active */}
                    <>
                      {!(Constants['screen'] === 'home') ? null : (
                        <ImageBackground
                          resizeMode={'cover'}
                          source={Images.IconHomeBlue}
                          style={StyleSheet.applyWidth(
                            { height: '100%', width: '100%' },
                            dimensions.width
                          )}
                        />
                      )}
                    </>
                  </View>
                  {/* Label Inactive */}
                  <>
                    {!(Constants['screen'] !== 'home') ? null : (
                      <Text
                        accessible={true}
                        style={StyleSheet.applyWidth(
                          {
                            alignSelf: 'center',
                            color: palettes.App['App Text 2ndary 7F868C'],
                            fontFamily: 'Poppins_400Regular',
                            fontSize: 11,
                          },
                          dimensions.width
                        )}
                      >
                        {'Home'}
                      </Text>
                    )}
                  </>
                  {/* Label Active */}
                  <>
                    {!(Constants['screen'] === 'home') ? null : (
                      <Text
                        accessible={true}
                        style={StyleSheet.applyWidth(
                          {
                            alignSelf: 'center',
                            color: palettes.App['App Black 191F32'],
                            fontFamily: 'Poppins_400Regular',
                            fontSize: 11,
                          },
                          dimensions.width
                        )}
                      >
                        {'Home'}
                      </Text>
                    )}
                  </>
                </View>
              </Touchable>
            </View>
            {/* View Jobs Container */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  flex: 0,
                  justifyContent: 'space-between',
                  width: '20%',
                },
                dimensions.width
              )}
            >
              <Touchable
                onPress={() => {
                  try {
                    setGlobalVariableValue({
                      key: 'screen',
                      value: 'jobs',
                    });
                    navigation.navigate('JobsScreen');
                    amplitudeEvents(
                      Variables,
                      'view open jobs list',
                      {
                        step: '01',
                        funnel: 'request job',
                        origin: 'bottom navigation',
                        category: 'opportunity management',
                      },
                      undefined
                    );
                  } catch (err) {
                    console.error(err);
                  }
                }}
                style={StyleSheet.applyWidth(
                  { width: '100%' },
                  dimensions.width
                )}
              >
                {/* View Touchable Jobs */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      paddingBottom: 0,
                      paddingLeft: 0,
                      paddingRight: 0,
                      paddingTop: 0,
                      width: '100%',
                    },
                    dimensions.width
                  )}
                >
                  {/* View Jobs Icon */}
                  <View
                    style={StyleSheet.applyWidth(
                      { height: 24, overflow: 'hidden', width: 24 },
                      dimensions.width
                    )}
                  >
                    {/* Jobs Icon Inactive */}
                    <>
                      {!(Constants['screen'] !== 'jobs') ? null : (
                        <ImageBackground
                          resizeMode={'cover'}
                          source={{
                            uri: 'https://apps-draftbit-com.s3.amazonaws.com/apps/GZlNa4ih/assets/9VnVvkN5D5yhOBLJhFW7N',
                          }}
                          style={StyleSheet.applyWidth(
                            { height: '100%', width: '100%' },
                            dimensions.width
                          )}
                        />
                      )}
                    </>
                    {/* Jobs Icon Active */}
                    <>
                      {!(Constants['screen'] === 'jobs') ? null : (
                        <ImageBackground
                          resizeMode={'cover'}
                          source={Images.IconSearchBlue}
                          style={StyleSheet.applyWidth(
                            { height: '100%', width: '100%' },
                            dimensions.width
                          )}
                        />
                      )}
                    </>
                  </View>
                  {/* Label Inactive */}
                  <>
                    {!(Constants['screen'] !== 'jobs') ? null : (
                      <Text
                        accessible={true}
                        style={StyleSheet.applyWidth(
                          {
                            alignSelf: 'center',
                            color: palettes.App['App Text 2ndary 7F868C'],
                            fontFamily: 'Poppins_400Regular',
                            fontSize: 11,
                          },
                          dimensions.width
                        )}
                      >
                        {'Jobs'}
                      </Text>
                    )}
                  </>
                  {/* Label Active */}
                  <>
                    {!(Constants['screen'] === 'jobs') ? null : (
                      <Text
                        accessible={true}
                        style={StyleSheet.applyWidth(
                          {
                            alignSelf: 'center',
                            color: palettes.App['App Black 191F32'],
                            fontFamily: 'Poppins_400Regular',
                            fontSize: 11,
                          },
                          dimensions.width
                        )}
                      >
                        {'Jobs'}
                      </Text>
                    )}
                  </>
                </View>
              </Touchable>
            </View>
            {/* View Clients Container */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  flex: 0,
                  justifyContent: 'space-between',
                  width: '20%',
                },
                dimensions.width
              )}
            >
              <Touchable
                onPress={() => {
                  try {
                    setGlobalVariableValue({
                      key: 'screen',
                      value: 'clients',
                    });
                    navigation.navigate('Clients2Screen');
                    amplitudeEvents(
                      Variables,
                      'view client list',
                      {
                        origin: 'bottom navigation',
                        category: 'client management',
                      },
                      undefined
                    );
                  } catch (err) {
                    console.error(err);
                  }
                }}
                style={StyleSheet.applyWidth(
                  { width: '100%' },
                  dimensions.width
                )}
              >
                {/* View Touchable Clients */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      paddingBottom: 0,
                      paddingLeft: 0,
                      paddingRight: 0,
                      paddingTop: 0,
                      width: '100%',
                    },
                    dimensions.width
                  )}
                >
                  {/* View Clients Icon */}
                  <View
                    style={StyleSheet.applyWidth(
                      { height: 24, overflow: 'hidden', width: 24 },
                      dimensions.width
                    )}
                  >
                    {/* Clients Icon Active */}
                    <>
                      {!(Constants['screen'] !== 'clients') ? null : (
                        <ImageBackground
                          resizeMode={'cover'}
                          source={Images.IconBriefcaseGrey}
                          style={StyleSheet.applyWidth(
                            { height: '100%', width: '100%' },
                            dimensions.width
                          )}
                        />
                      )}
                    </>
                    {/* Clients Icon Active */}
                    <>
                      {!(Constants['screen'] === 'clients') ? null : (
                        <ImageBackground
                          resizeMode={'cover'}
                          source={Images.IconBriefcaseBlue}
                          style={StyleSheet.applyWidth(
                            { height: '100%', width: '100%' },
                            dimensions.width
                          )}
                        />
                      )}
                    </>
                  </View>
                  {/* Label Inactive */}
                  <>
                    {!(Constants['screen'] !== 'clients') ? null : (
                      <Text
                        accessible={true}
                        style={StyleSheet.applyWidth(
                          {
                            alignSelf: 'center',
                            color: palettes.App['App Text 2ndary 7F868C'],
                            fontFamily: 'Poppins_400Regular',
                            fontSize: 11,
                          },
                          dimensions.width
                        )}
                      >
                        {'Clients'}
                      </Text>
                    )}
                  </>
                  {/* Label Active */}
                  <>
                    {!(Constants['screen'] === 'clients') ? null : (
                      <Text
                        accessible={true}
                        style={StyleSheet.applyWidth(
                          {
                            alignSelf: 'center',
                            color: palettes.App['App Black 191F32'],
                            fontFamily: 'Poppins_400Regular',
                            fontSize: 11,
                          },
                          dimensions.width
                        )}
                      >
                        {'Clients'}
                      </Text>
                    )}
                  </>
                </View>
              </Touchable>
            </View>
            {/* View Profile Container */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  alignSelf: 'center',
                  flex: 0,
                  justifyContent: 'space-between',
                  width: '20%',
                },
                dimensions.width
              )}
            >
              <Touchable
                onPress={() => {
                  try {
                    amplitudeEvents(
                      Variables,
                      'view my account',
                      {
                        origin: 'bottom navigation',
                        category: 'account management',
                      },
                      undefined
                    );
                    setGlobalVariableValue({
                      key: 'screen',
                      value: 'profile',
                    });
                    navigation.navigate('MyAccountScreen');
                  } catch (err) {
                    console.error(err);
                  }
                }}
                style={StyleSheet.applyWidth(
                  { width: '100%' },
                  dimensions.width
                )}
              >
                {/* View Touchable Profile */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      paddingBottom: 0,
                      paddingLeft: 0,
                      paddingRight: 0,
                      paddingTop: 0,
                      width: '100%',
                    },
                    dimensions.width
                  )}
                >
                  {/* View Profile Icon */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        height: 24,
                        overflow: 'hidden',
                        width: 24,
                      },
                      dimensions.width
                    )}
                  >
                    {/* Profile Icon Inactive */}
                    <>
                      {!(Constants['screen'] !== 'profile') ? null : (
                        <ImageBackground
                          resizeMode={'cover'}
                          source={Images.IconProfileGrey}
                          style={StyleSheet.applyWidth(
                            { height: '100%', width: '100%' },
                            dimensions.width
                          )}
                        />
                      )}
                    </>
                    {/* Profile Icon Active */}
                    <>
                      {!(Constants['screen'] === 'profile') ? null : (
                        <ImageBackground
                          resizeMode={'cover'}
                          source={Images.IconProfileBlue}
                          style={StyleSheet.applyWidth(
                            { height: '100%', width: '100%' },
                            dimensions.width
                          )}
                        />
                      )}
                    </>
                  </View>
                  {/* Label Inactive */}
                  <>
                    {!(Constants['screen'] !== 'profile') ? null : (
                      <Text
                        accessible={true}
                        style={StyleSheet.applyWidth(
                          {
                            alignSelf: 'center',
                            color: palettes.App['App Text 2ndary 7F868C'],
                            fontFamily: 'Poppins_400Regular',
                            fontSize: 11,
                          },
                          dimensions.width
                        )}
                      >
                        {'Account'}
                      </Text>
                    )}
                  </>
                  {/* Label Active */}
                  <>
                    {!(Constants['screen'] === 'profile') ? null : (
                      <Text
                        accessible={true}
                        style={StyleSheet.applyWidth(
                          {
                            alignSelf: 'center',
                            color: palettes.App['App Black 191F32'],
                            fontFamily: 'Poppins_400Regular',
                            fontSize: 11,
                          },
                          dimensions.width
                        )}
                      >
                        {'Account'}
                      </Text>
                    )}
                  </>
                </View>
              </Touchable>
            </View>
            {/* View Knowledge Container */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  flex: 0,
                  justifyContent: 'space-between',
                  width: '20%',
                },
                dimensions.width
              )}
            >
              <Touchable
                onPress={() => {
                  try {
                    setGlobalVariableValue({
                      key: 'screen',
                      value: 'knowledge',
                    });
                    navigation.navigate('KnowledgeBaseScreen');
                    amplitudeEvents(
                      Variables,
                      'view knowledge base',
                      {
                        origin: 'bottom navigation',
                        category: 'account management',
                      },
                      undefined
                    );
                  } catch (err) {
                    console.error(err);
                  }
                }}
                style={StyleSheet.applyWidth(
                  { width: '100%' },
                  dimensions.width
                )}
              >
                {/* View Touchable Profile */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      paddingBottom: 0,
                      paddingLeft: 0,
                      paddingRight: 0,
                      paddingTop: 0,
                      width: '100%',
                    },
                    dimensions.width
                  )}
                >
                  {/* View Profile Icon */}
                  <View
                    style={StyleSheet.applyWidth(
                      { height: 24, overflow: 'hidden', width: 24 },
                      dimensions.width
                    )}
                  >
                    {/* Knowledge Icon Inactive */}
                    <>
                      {!(Constants['screen'] !== 'knowledge') ? null : (
                        <ImageBackground
                          resizeMode={'cover'}
                          source={Images.IconKnowledgeGrey}
                          style={StyleSheet.applyWidth(
                            { height: '100%', width: '100%' },
                            dimensions.width
                          )}
                        />
                      )}
                    </>
                    {/* Knowledge Icon Active */}
                    <>
                      {!(Constants['screen'] === 'knowledge') ? null : (
                        <ImageBackground
                          resizeMode={'cover'}
                          source={Images.IconKnowledgeBlue}
                          style={StyleSheet.applyWidth(
                            { height: '100%', width: '100%' },
                            dimensions.width
                          )}
                        />
                      )}
                    </>
                  </View>
                  {/* Label Inactive */}
                  <>
                    {!(Constants['screen'] !== 'profile') ? null : (
                      <Text
                        accessible={true}
                        style={StyleSheet.applyWidth(
                          {
                            alignSelf: 'center',
                            color: palettes.App['App Text 2ndary 7F868C'],
                            fontFamily: 'Poppins_400Regular',
                            fontSize: 11,
                          },
                          dimensions.width
                        )}
                      >
                        {'Know'}
                      </Text>
                    )}
                  </>
                  {/* Label Active */}
                  <>
                    {!(Constants['screen'] === 'profile') ? null : (
                      <Text
                        accessible={true}
                        style={StyleSheet.applyWidth(
                          {
                            alignSelf: 'center',
                            color: palettes.App['App Black 191F32'],
                            fontFamily: 'Poppins_400Regular',
                            fontSize: 11,
                          },
                          dimensions.width
                        )}
                      >
                        {'Know'}
                      </Text>
                    )}
                  </>
                </View>
              </Touchable>
            </View>
          </View>
          <>
            {!!(Platform.OS === 'android') ? null : (
              <Spacer bottom={34} left={0} right={0} top={0} />
            )}
          </>
        </Surface>
      </View>
      {/* Modal Pay Estimate Disclaimer */}
      <Modal
        supportedOrientations={['portrait', 'landscape']}
        animationType={'slide'}
        presentationStyle={'overFullScreen'}
        transparent={true}
        visible={showPayEstimateDisclaimer}
      >
        {/* Transparency */}
        <View
          style={StyleSheet.applyWidth(
            {
              alignItems: 'center',
              alignSelf: 'center',
              backgroundColor: palettes.App['App Transparent'],
              height: '100%',
              justifyContent: 'center',
              paddingLeft: '5.35%',
              paddingRight: '5.35%',
              width: '100%',
            },
            dimensions.width
          )}
        >
          {/* Disclaimer text container */}
          <View
            style={StyleSheet.applyWidth(
              {
                backgroundColor: palettes.App['Custom Color'],
                borderRadius: 16,
                maxHeight: '80%',
                padding: 16,
              },
              dimensions.width
            )}
          >
            {/* View Modal Close */}
            <Touchable
              onPress={() => {
                try {
                  setShowPayEstimateDisclaimer(false);
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              {/* View Modal Title */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignContent: 'flex-end',
                    alignItems: 'flex-end',
                    alignSelf: 'flex-end',
                    backgroundColor: palettes.App['App Disabled Button EDEDEF'],
                    borderRadius: 30,
                    flexDirection: 'column',
                    paddingBottom: 4,
                    paddingLeft: 6,
                    paddingRight: 6,
                    paddingTop: 4,
                  },
                  dimensions.width
                )}
              >
                {/* close */}
                <Text
                  accessible={true}
                  style={StyleSheet.applyWidth(
                    {
                      alignSelf: 'flex-start',
                      color: palettes.App['App Disabled Button Text 7F868C'],
                      fontFamily: 'Poppins_400Regular',
                      fontSize: 12,
                      textAlign: 'left',
                    },
                    dimensions.width
                  )}
                >
                  {'close x'}
                </Text>
              </View>
            </Touchable>

            <ScrollView
              bounces={true}
              horizontal={false}
              keyboardShouldPersistTaps={'never'}
              nestedScrollEnabled={false}
              showsHorizontalScrollIndicator={true}
              showsVerticalScrollIndicator={true}
              contentContainerStyle={StyleSheet.applyWidth(
                { marginTop: 16 },
                dimensions.width
              )}
            >
              {/* View H1 */}
              <View
                {...GlobalStyles.ViewStyles(theme)['View H2'].props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.ViewStyles(theme)['View H2'].style,
                  dimensions.width
                )}
              >
                {/* H1 */}
                <Text
                  accessible={true}
                  style={StyleSheet.applyWidth(
                    {
                      color: palettes.App['App Black 191F32'],
                      fontFamily: 'BebasNeue_400Regular',
                      fontSize: 24,
                      marginRight: 4,
                    },
                    dimensions.width
                  )}
                >
                  {'Your pay estimate'}
                </Text>
              </View>
              {/* Body */}
              <View>
                {/* Disclaimer */}
                <Text
                  accessible={true}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Text'].style,
                      {
                        color: palettes.App['App Disabled Button Text 7F868C'],
                        fontFamily: 'Poppins_400Regular',
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {
                    "Please note that the pay estimate provided only represents compensation for completed lessons and applicable lesson package completion bonuses. This estimate does not include any other forms of compensation, such as Social Media Review Bonuses, or any deductions that may apply, such as Background Check Fees, pay fines, or other costs and/or deductions associated with your role as an independent contractor.\n\nFurthermore, the indicated payment date is purely an estimate. Actual payment may be processed before or after this date, depending on various factors including but not limited to lesson confirmation, completion status, and banking procedures.\n\nIt's important to understand that this pay estimate is not a guarantee of payment to be received. Sunsational Swim School makes every effort to provide accurate estimates but cannot be held responsible for any errors or discrepancies. Changes in pay estimate may be applied for reasons including corrections, pay fines, fraud prevention and others. Sunsational reserves the right to stop or modify payment for any reason and its sole discretion.\n\nPlease review your contractor agreement if you have any questions about your compensation, deductions, or estimated pay dates, or reach out to our team at office@sunsationalswimschool.com or 1-888-788-2140 xt 3."
                  }
                </Text>
              </View>
            </ScrollView>
          </View>
        </View>
      </Modal>
      {/* Fetch Open Jobs */}
      <>
        {!Constants['AUTH_KNACK_TOKEN'] ? null : (
          <KnackAPIApi.FetchGetOpenOpportunitiesGET
            handlers={{
              on401: fetchOpenJobsData => {
                try {
                  setGlobalVariableValue({
                    key: 'is_loading',
                    value: false,
                  });
                  navigation.navigate('SessionExpiredScreen');
                } catch (err) {
                  console.error(err);
                }
              },
              onData: fetchOpenJobsData => {
                try {
                  console.log('fetching jobs');
                  setIs_loading_open_jobs(true);
                  setOpenJobsCount(fetchOpenJobsData?.total_records);
                  setIs_loading_open_jobs(false);
                } catch (err) {
                  console.error(err);
                }
              },
            }}
            rows_per_page={1000}
          >
            {({ loading, error, data, refetchGetOpenOpportunities }) => {
              const fetchOpenJobsData = data?.json;
              if (loading) {
                return <ActivityIndicator />;
              }

              if (error || data?.status < 200 || data?.status >= 300) {
                return <ActivityIndicator />;
              }

              return null;
            }}
          </KnackAPIApi.FetchGetOpenOpportunitiesGET>
        )}
      </>
      {/* Fetch SI's lessons */}
      <>
        {!Constants['AUTH_KNACK_TOKEN'] ? null : (
          <KnackAPIApi.FetchGetLessonsListGET
            filters={filterCurentClients}
            handlers={{
              on2xx: fetchSISLessonsData => {
                try {
                  console.log('Fetch Lessons');
                  setIs_loading_clients(true);
                  setLessonRecords(fetchSISLessonsData?.json?.records);
                  const uniquePackages = getUniquePackagesFromLessonsList(
                    fetchSISLessonsData?.json?.records
                  );
                  const filteredCurrentClients =
                    filterCompletedPackages(uniquePackages);
                  setUniquePackages(filteredCurrentClients);
                  const count = getCurrentClientsCount(
                    fetchSISLessonsData?.json?.records
                  );
                  setClientsCount(count);
                  const scheduledLessons = filterScheduledLessons(
                    fetchSISLessonsData?.json?.records
                  );
                  const sortedScheduledLessons = sortLessonsByScheduleDate(
                    scheduledLessons,
                    undefined
                  );
                  setUpcomingScheduledLessons(sortedScheduledLessons);
                  setIs_loading_clients(false);
                  setIs_loading_scheduled_jobs(false);
                  const lessonPayDetails = calculatePay(
                    fetchSISLessonsData?.json?.records
                  );
                  shouldShowPayIcon(lessonPayDetails);
                  setIs_loading_pay(false);
                } catch (err) {
                  console.error(err);
                }
              },
            }}
            rows_per_page={1000}
          >
            {({ loading, error, data, refetchGetLessonsList }) => {
              const fetchSISLessonsData = data?.json;
              if (loading) {
                return <ActivityIndicator />;
              }

              if (error || data?.status < 200 || data?.status >= 300) {
                return <ActivityIndicator />;
              }

              return null;
            }}
          </KnackAPIApi.FetchGetLessonsListGET>
        )}
      </>
      {/* Fetch SI Profile */}
      <>
        {!Constants['AUTH_KNACK_TOKEN'] ? null : (
          <KnackAPIApi.FetchGetProfileGET
            AUTH_USER_KNACK_ID={Constants['AUTH_KNACK_USER_ID']}
            handlers={{
              on2xx: fetchSIProfileData => {
                const handler = async () => {
                  try {
                    await checkAccountStatus(
                      Variables,
                      setGlobalVariableValue,
                      fetchSIProfileData?.json
                    );
                  } catch (err) {
                    console.error(err);
                  }
                };
                handler();
              },
            }}
          >
            {({ loading, error, data, refetchGetProfile }) => {
              const fetchSIProfileData = data?.json;
              if (loading) {
                return <ActivityIndicator />;
              }

              if (error || data?.status < 200 || data?.status >= 300) {
                return <ActivityIndicator />;
              }

              return null;
            }}
          </KnackAPIApi.FetchGetProfileGET>
        )}
      </>
    </ScreenContainer>
  );
};

export default withTheme(HomeScreen);
