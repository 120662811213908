// VALIDATE APP VERSION AND AUTH TOKEN NEW
const isVersionDeprecated = (usersAppVersionId, appVersionsList) => {
  const index = appVersionsList.findIndex(
    appVersion => appVersion.id === usersAppVersionId
  );

  if (index === -1) {
    return true;
  }

  return appVersionsList[index].field_2407_raw === 'deprecated';
};

export const Validate = async (
  nav,
  Variables,
  currentScreen,
  setGlobalVariableValue
) => {
  //if authentication fails, it will throw an error
  console.log('Validating Auth Token and App Version');
  const authHeaders = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-Knack-Application-Id': Variables.APP_ID,
    Authorization: Variables.AUTH_KNACK_TOKEN,
  };

  try {
    const validate = await fetch(
      `https://api.knack.com/v1/pages/scene_1148/views/view_2163/records`,
      {
        method: 'GET',
        headers: authHeaders,
      }
    );

    const data = await validate.json();
    const appVersions = data.records;

    if (isVersionDeprecated(Variables.LATEST_APP_VERSION.id, appVersions)) {
      nav('UpdateAppRequiredScreen');
      return;
    }

    if (!Variables.AUTH_KNACK_USER_ID) {
      console.log('FETCH AUTH KNACK USER ID BECAUSE ITS MISSING');
      const getUserData = await fetch(
        `https://api.knack.com/v1/pages/scene_1148/views/view_2339/records`,
        {
          method: 'GET',
          headers: authHeaders,
        }
      );

      const userData = await getUserData.json();
      const authKnackUserID = userData.records[0].id;

      setGlobalVariableValue({
        key: 'AUTH_KNACK_USER_ID',
        value: authKnackUserID,
      });
    }

    return true;
  } catch (error) {
    console.error('ERROR BLOCK: validateAppVersionAndAuthKey: ', error);
    if (currentScreen === 'LoginScreen') {
      return;
    }

    nav('SessionExpiredScreen');
    return;
  }
};
