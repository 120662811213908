import React from 'react';

const triggerCannon = (Variables, setGlobalVariableValue) => {
  if (Variables.SHOT) {
    console.log('please wait, cannon is resetting');
    return;
  }

  setGlobalVariableValue({
    key: 'SHOT',
    value: true,
  });

  setTimeout(() => {
    setGlobalVariableValue({
      key: 'SHOT',
      value: false,
    });
    console.log('cannon reset');
  }, 3000);
};

export default triggerCannon;
