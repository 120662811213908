import React from 'react';
import * as Progress from 'react-native-progress';

export const Circle = ({ progress }) => {
  progress = progress > 0 ? progress : 0;
  return (
    <Progress.Circle
      progress={Number(progress)}
      size={50}
      color={'rgba(244, 161, 0, 1)'}
      showsText={true}
      animated={false}
    />
  );
};

export const Bar = ({
  lessonsCompleted,
  lessonsQuantity,
  fillColor,
  unfilledColor,
  height,
}) => {
  let progress = lessonsCompleted / lessonsQuantity;

  if (isNaN(progress)) {
    progress = 0;
  }
  if (!fillColor) {
    fillColor = '#FFC400';
  }
  if (!unfilledColor) {
    unfilledColor = '#F0F0F0';
  }
  if (!height) {
    height = 6;
  }
  return (
    <Progress.Bar
      progress={Number(progress)}
      color={fillColor}
      unfilledColor={unfilledColor}
      borderWidth={0}
      borderRadius={1000}
      width={null}
      height={height}
      animationConfig={{ bounciness: 100 }}
    />
  );
};
