import React from 'react';

const fetchUserData = async Variables => {
  const BASEURL = 'https://api.knack.com/v1/pages';
  const SCENE_ID = 'scene_1144';
  const VIEW_ID = 'view_2126';

  const { APP_ID, AUTH_KNACK_USER_ID, AUTH_KNACK_TOKEN } = Variables;

  const url = `${BASEURL}/${SCENE_ID}/views/${VIEW_ID}/records/${AUTH_KNACK_USER_ID}`;

  const requestOptions = {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-Knack-Application-Id': APP_ID,
      Authorization: AUTH_KNACK_TOKEN,
    },
  };

  try {
    const userData = await fetch(url, requestOptions);
    const userDataResponse = await userData.json();

    console.log('userDataResponse === ', userDataResponse);

    if (!userData.ok) {
      console.log('REPONSE NOT OK BLOCK');
      throw new Error(`Error! status: ${userData.errors[0].message}`);
    }

    return userDataResponse;
  } catch (err) {
    return err;
  }
};

export default fetchUserData;
