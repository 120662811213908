import * as Haptics from 'expo-haptics';

function selection() {
  Haptics.selectionAsync();
}

function success() {
  Haptics.notificationAsync(Haptics.NotificationFeedbackType.Success);
}

function error() {
  Haptics.notificationAsync(Haptics.NotificationFeedbackType.Error);
}

function warning() {
  Haptics.notificationAsync(Haptics.NotificationFeedbackType.Warning);
}

function light() {
  Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Light);
}

function medium() {
  Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Medium);
}

function heavy() {
  Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Heavy);
}

export { selection, light, medium, heavy, success, error, warning };
