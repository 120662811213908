import React from 'react';
import * as GlobalVariables from '../config/GlobalVariableContext';
import * as SelectSchedule from '../custom-files/SelectSchedule';
import * as Track from '../custom-files/Track';
import * as validateAppVersionAndAuthToken from '../custom-files/validateAppVersionAndAuthToken';
import palettes from '../themes/palettes';
import * as Utils from '../utils';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  Button,
  Picker,
  SVG,
  ScreenContainer,
  Spacer,
  Surface,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { ActivityIndicator, Modal, ScrollView, Text, View } from 'react-native';

const JobPreferencesScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [avail10amTo12pm, setAvail10amTo12pm] = React.useState(false);
  const [avail10amTo12pmWeekend, setAvail10amTo12pmWeekend] =
    React.useState(false);
  const [avail12pmTo2pm, setAvail12pmTo2pm] = React.useState(false);
  const [avail12pmTo2pmWeekend, setAvail12pmTo2pmWeekend] =
    React.useState(false);
  const [avail2pmTo4pm, setAvail2pmTo4pm] = React.useState(false);
  const [avail2pmTo4pmWeekend, setAvail2pmTo4pmWeekend] = React.useState(false);
  const [avail4pmTo6pm, setAvail4pmTo6pm] = React.useState(false);
  const [avail4pmTo6pmWeekend, setAvail4pmTo6pmWeekend] = React.useState(false);
  const [avail6amTo8am, setAvail6amTo8am] = React.useState(false);
  const [avail6amTo8amWeekend, setAvail6amTo8amWeekend] = React.useState(false);
  const [avail6pmTo8pm, setAvail6pmTo8pm] = React.useState(false);
  const [avail6pmTo8pmWeekend, setAvail6pmTo8pmWeekend] = React.useState(false);
  const [avail8amTo10am, setAvail8amTo10am] = React.useState(false);
  const [avail8amTo10amWeekend, setAvail8amTo10amWeekend] =
    React.useState(false);
  const [availableDays, setAvailableDays] = React.useState([]);
  const [availableWeekdayTimes, setAvailableWeekdayTimes] = React.useState([]);
  const [availableWeekendTimes, setAvailableWeekendTimes] = React.useState([]);
  const [disableSaveButton, setDisableSaveButton] = React.useState(false);
  const [fridays, setFridays] = React.useState(false);
  const [message, setMessage] = React.useState('');
  const [mondays, setMondays] = React.useState(false);
  const [saturdays, setSaturdays] = React.useState(false);
  const [selectedTravelRange, setSelectedTravelRange] = React.useState('');
  const [showModal, setShowModal] = React.useState(false);
  const [sundays, setSundays] = React.useState(false);
  const [thursdays, setThursdays] = React.useState(false);
  const [tuesdays, setTuesdays] = React.useState(false);
  const [wednesdays, setWednesdays] = React.useState(false);
  const pushNewSchedule = async (Variables, setGlobalVariableValue) => {
    const {
      gInstructorAvailability,
      gInstructorTravelRange,
      APP_ID,
      AUTH_KNACK_USER_ID,
      AUTH_KNACK_TOKEN,
    } = Variables;

    const { days, weekendTimes, weekdayTimes } = gInstructorAvailability;

    console.log('selectedTravelRange == ', selectedTravelRange);

    // CONVERT SELECTED TRAVEL RANGE FOR KNACK
    const convertedTravelRange = travelRange => {
      console.log('travel range === ', travelRange);
      let travelRangeNumber;

      if (travelRange.includes('10')) {
        travelRangeNumber = 10;
      }

      if (travelRange.includes('25')) {
        travelRangeNumber = 25;
      }

      if (travelRange.includes('40')) {
        travelRangeNumber = 40;
      }

      return travelRangeNumber;
    };

    // COMPARE SCHEDULE CHANGES
    function schedIsSame(prevSched, currentSched) {
      return (
        prevSched.length === currentSched.length &&
        currentSched.every(available => prevSched.indexOf(available) > -1)
      );
    }

    let prevDays = days;
    let prevWeekdayTimes = weekdayTimes;
    let prevWeekendTimes = weekendTimes;
    let prevInstructorTravelRange = gInstructorTravelRange;

    let currentAvailableDays = [];
    let currentWeekdayTimes = [];
    let currentWeekendTimes = [];

    // AVAILABLE WEEKDAYS
    if (mondays) {
      currentAvailableDays.push('Mondays');
    }

    if (tuesdays) {
      currentAvailableDays.push('Tuesdays');
    }

    if (wednesdays) {
      currentAvailableDays.push('Wednesdays');
    }

    if (thursdays) {
      currentAvailableDays.push('Thursdays');
    }

    if (fridays) {
      currentAvailableDays.push('Fridays');
    }

    if (saturdays) {
      currentAvailableDays.push('Saturdays');
    }

    if (sundays) {
      currentAvailableDays.push('Sundays');
    }

    // AVAILABLE WEEKDAY TIMES
    if (avail6amTo8am) {
      currentWeekdayTimes.push('6AM - 8AM');
    }

    if (avail8amTo10am) {
      currentWeekdayTimes.push('8AM - 10AM');
    }

    if (avail10amTo12pm) {
      currentWeekdayTimes.push('10AM - 12PM');
    }

    if (avail12pmTo2pm) {
      currentWeekdayTimes.push('12PM - 2PM');
    }

    if (avail2pmTo4pm) {
      currentWeekdayTimes.push('2PM - 4PM');
    }

    if (avail4pmTo6pm) {
      currentWeekdayTimes.push('4PM - 6PM');
    }

    if (avail6pmTo8pm) {
      currentWeekdayTimes.push('6PM - 8PM');
    }

    // AVAILABLE WEEKEND TIMES
    if (avail6amTo8amWeekend) {
      currentWeekendTimes.push('6AM - 8AM');
    }

    if (avail8amTo10amWeekend) {
      currentWeekendTimes.push('8AM - 10AM');
    }

    if (avail10amTo12pmWeekend) {
      currentWeekendTimes.push('10AM - 12PM');
    }

    if (avail12pmTo2pmWeekend) {
      currentWeekendTimes.push('12PM - 2PM');
    }

    if (avail2pmTo4pmWeekend) {
      currentWeekendTimes.push('2PM - 4PM');
    }

    if (avail4pmTo6pmWeekend) {
      currentWeekendTimes.push('4PM - 6PM');
    }

    if (avail6pmTo8pmWeekend) {
      currentWeekendTimes.push('6PM - 8PM');
    }

    const currentSched = {
      days: currentAvailableDays,
      weekdayTimes: currentWeekdayTimes,
      weekendTimes: currentWeekendTimes,
    };

    // SEND TO KNACK IF CHANGE IN PREFERENCES MADE
    if (
      !schedIsSame(prevDays, currentAvailableDays) ||
      !schedIsSame(prevWeekdayTimes, currentWeekdayTimes) ||
      !schedIsSame(prevWeekendTimes, currentWeekendTimes) ||
      selectedTravelRange !== gInstructorTravelRange
    ) {
      console.log('schedule has changed! send update to Knack');
      try {
        setGlobalVariableValue({
          key: 'is_loading',
          value: true,
        });

        const knackData = {
          field_930: currentAvailableDays,
          field_931: currentWeekdayTimes,
          field_932: currentWeekendTimes,
          field_47: convertedTravelRange(selectedTravelRange),
        };

        const sendJobPreferences = await fetch(
          `https://api.knack.com/v1/pages/scene_1124/views/view_2103/records/${AUTH_KNACK_USER_ID}`,
          {
            method: 'PUT',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              'X-Knack-Application-Id': APP_ID,
              Authorization: AUTH_KNACK_TOKEN,
            },
            body: JSON.stringify(knackData),
          }
        );

        const sendJobPreferencesResponse = await sendJobPreferences.json();

        if (!sendJobPreferences.ok) {
          console.log('RESPONSE NOT OK BLOCK');
          setMessage('Error updating your job preferences. Please try again.');
          throw new Error(`Error! status: ${sendJobPreferences.status}`);
        }

        // SI Mobile App - Generate Opps After Address or Radius Change
        if (prevInstructorTravelRange !== selectedTravelRange) {
          const updateSiOpps = await fetch(
            `https://hook.integromat.com/ou8ywz1qkndflgkmm24wz4xnn5medhn2`,
            {
              method: 'PUT',
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({ AUTH_KNACK_USER_ID }),
            }
          );

          const updateSiOppsResponse = await updateSiOpps.json();

          if (!updateSiOpps.ok) {
            console.log('RESPONSE NOT OK BLOCK');
            setMessage(
              'Error updating your job preferences. Please try again.'
            );
            throw new Error(`Error! status: ${updateSiOpps.status}`);
          }
        }

        const updateAppVariables = [
          setGlobalVariableValue({
            key: 'gInstructorAvailability',
            value: currentSched,
          }),
          setGlobalVariableValue({
            key: 'is_loading',
            value: false,
          }),
        ];

        const done = await Promise.all(updateAppVariables);

        console.log('uploaded new schedule successful!');
        console.log(
          'sendJobPreferencesResponse === ',
          sendJobPreferencesResponse
        );
        setMessage('Your job preferences have been updated.');
        setShowModal(true);
      } catch (err) {
        console.log('CATCH ERROR BLOCK!');
        console.error(`What is the error?: ${err.message}`);

        setGlobalVariableValue({
          key: 'is_loading',
          value: false,
        });
      }
    } else {
      console.log('instructors schedule has not changed, do nothing');
    }
  };

  const alertNoChangesMade = () => {
    if (disabledSaveButton) {
      alert(
        'No changes to your preferences have been made. Make changes to save your new preferences.'
      );
    }
  };
  React.useEffect(() => {
    const {
      gInstructorAvailability,
      gInstructorTravelRange,
      APP_ID,
      AUTH_KNACK_USER_ID,
      AUTH_KNACK_TOKEN,
    } = Variables;

    const { days, weekendTimes, weekdayTimes } = gInstructorAvailability;

    function schedIsSame(prevSched, currentSched) {
      prevSched = prevSched ?? [];
      currentSched = currentSched ?? [];

      return (
        prevSched.length === currentSched.length &&
        currentSched.every(available => prevSched.indexOf(available) > -1)
      );
    }

    let prevDays = days;
    let prevWeekdayTimes = weekdayTimes;
    let prevWeekendTimes = weekendTimes;
    let prevTravelRange = gInstructorTravelRange;

    let currentAvailableDays = [];
    let currentWeekdayTimes = [];
    let currentWeekendTimes = [];

    // AVAILABLE WEEKDAYS
    if (mondays) {
      currentAvailableDays.push('Mondays');
    }

    if (tuesdays) {
      currentAvailableDays.push('Tuesdays');
    }

    if (wednesdays) {
      currentAvailableDays.push('Wednesdays');
    }

    if (thursdays) {
      currentAvailableDays.push('Thursdays');
    }

    if (fridays) {
      currentAvailableDays.push('Fridays');
    }

    if (saturdays) {
      currentAvailableDays.push('Saturdays');
    }

    if (sundays) {
      currentAvailableDays.push('Sundays');
    }

    // AVAILABLE WEEKDAY TIMES
    if (avail6amTo8am) {
      currentWeekdayTimes.push('6AM - 8AM');
    }

    if (avail8amTo10am) {
      currentWeekdayTimes.push('8AM - 10AM');
    }

    if (avail10amTo12pm) {
      currentWeekdayTimes.push('10AM - 12PM');
    }

    if (avail12pmTo2pm) {
      currentWeekdayTimes.push('12PM - 2PM');
    }

    if (avail2pmTo4pm) {
      currentWeekdayTimes.push('2PM - 4PM');
    }

    if (avail4pmTo6pm) {
      currentWeekdayTimes.push('4PM - 6PM');
    }

    if (avail6pmTo8pm) {
      currentWeekdayTimes.push('6PM - 8PM');
    }

    // AVAILABLE WEEKEND TIMES
    if (avail6amTo8amWeekend) {
      currentWeekendTimes.push('6AM - 8AM');
    }

    if (avail8amTo10amWeekend) {
      currentWeekendTimes.push('8AM - 10AM');
    }

    if (avail10amTo12pmWeekend) {
      currentWeekendTimes.push('10AM - 12PM');
    }

    if (avail12pmTo2pmWeekend) {
      currentWeekendTimes.push('12PM - 2PM');
    }

    if (avail2pmTo4pmWeekend) {
      currentWeekendTimes.push('2PM - 4PM');
    }

    if (avail4pmTo6pmWeekend) {
      currentWeekendTimes.push('4PM - 6PM');
    }

    if (avail6pmTo8pmWeekend) {
      currentWeekendTimes.push('6PM - 8PM');
    }

    const currentSched = {
      days: currentAvailableDays,
      weekdayTimes: currentWeekdayTimes,
      weekendTimes: currentWeekendTimes,
    };

    // CHECK IF WE NEED TO SEND TO KNACK
    if (
      !schedIsSame(prevDays, currentAvailableDays) ||
      !schedIsSame(prevWeekdayTimes, currentWeekdayTimes) ||
      !schedIsSame(prevWeekendTimes, currentWeekendTimes) ||
      prevTravelRange !== selectedTravelRange
    ) {
      setDisableSaveButton(false);
    } else {
      setDisableSaveButton(true);
    }
  });

  React.useEffect(() => {
    Track.onEnterScreen(
      'JobPreferencesScreen',
      Variables,
      setGlobalVariableValue,
      props.navigation.navigate,
      props?.route?.params?.skipAuth
    );

    const { gInstructorAvailability, gInstructorTravelRange } = Variables;

    const { days, weekdayTimes, weekendTimes } = gInstructorAvailability;

    days?.map(day => {
      if (day === 'Mondays') {
        setMondays(true);
      }

      if (day === 'Tuesdays') {
        setTuesdays(true);
      }

      if (day === 'Wednesdays') {
        setWednesdays(true);
      }

      if (day === 'Thursdays') {
        setThursdays(true);
      }

      if (day === 'Fridays') {
        setFridays(true);
      }

      if (day === 'Saturdays') {
        setSaturdays(true);
      }

      if (day === 'Sundays') {
        setSundays(true);
      }
    });

    weekdayTimes?.map(time => {
      if (time === '6AM - 8AM') {
        setAvail6amTo8am(true);
      }

      if (time === '8AM - 10AM') {
        setAvail8amTo10am(true);
      }

      if (time === '10AM - 12PM') {
        setAvail10amTo12pm(true);
      }

      if (time === '12PM - 2PM') {
        setAvail12pmTo2pm(true);
      }

      if (time === '2PM - 4PM') {
        setAvail2pmTo4pm(true);
      }

      if (time === '4PM - 6PM') {
        setAvail4pmTo6pm(true);
      }

      if (time === '6PM - 8PM') {
        setAvail6pmTo8pm(true);
      }
    });

    weekendTimes?.map(time => {
      if (time === '6AM - 8AM') {
        setAvail6amTo8amWeekend(true);
      }

      if (time === '8AM - 10AM') {
        setAvail8amTo10amWeekend(true);
      }

      if (time === '10AM - 12PM') {
        setAvail10amTo12pmWeekend(true);
      }

      if (time === '12PM - 2PM') {
        setAvail12pmTo2pmWeekend(true);
      }

      if (time === '2PM - 4PM') {
        setAvail2pmTo4pmWeekend(true);
      }

      if (time === '4PM - 6PM') {
        setAvail4pmTo6pmWeekend(true);
      }

      if (time === '6PM - 8PM') {
        setAvail6pmTo8pmWeekend(true);
      }
    });

    setAvailableDays(days);
    setAvailableWeekdayTimes(weekdayTimes);
    setAvailableWeekendTimes(weekendTimes);
    setSelectedTravelRange(gInstructorTravelRange);
  }, []);

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={false}
      hasTopSafeArea={false}
    >
      {/* View Top Navigation - Subtitle */}
      <View style={StyleSheet.applyWidth({ width: '100%' }, dimensions.width)}>
        {/* Spacer Top Nav */}
        <Spacer bottom={0} left={0} right={0} top={44} />
        {/* View Row Top Nav Container */}
        <View
          style={StyleSheet.applyWidth(
            {
              alignContent: 'center',
              alignItems: 'center',
              flexDirection: 'row',
              justifyContent: 'space-between',
              paddingBottom: 22,
              paddingLeft: '5.35%',
              paddingRight: '5.35%',
              paddingTop: 14,
            },
            dimensions.width
          )}
        >
          {/* View Back Arrow Container */}
          <View
            style={StyleSheet.applyWidth(
              { height: 24, width: 24 },
              dimensions.width
            )}
          >
            <Touchable
              onPress={() => {
                try {
                  navigation.goBack();
                } catch (err) {
                  console.error(err);
                }
              }}
              style={StyleSheet.applyWidth(
                { height: '100%', width: '100%' },
                dimensions.width
              )}
            >
              {/* Icon Back Arrow */}
              <SVG
                source={Constants['uiBackArrow']}
                style={StyleSheet.applyWidth(
                  { height: '100%', width: '100%' },
                  dimensions.width
                )}
              />
            </Touchable>
          </View>
          {/* View Page Title Container */}
          <View
            style={StyleSheet.applyWidth(
              { justifyContent: 'center' },
              dimensions.width
            )}
          >
            {/* Title */}
            <Text
              accessible={true}
              style={StyleSheet.applyWidth(
                {
                  color: theme.colors.text.strong,
                  fontFamily: 'Poppins_600SemiBold',
                },
                dimensions.width
              )}
            >
              {'Job Preferences'}
            </Text>
          </View>
          {/* View Spacer */}
          <View
            style={StyleSheet.applyWidth(
              { height: 10, justifyContent: 'center', width: 10 },
              dimensions.width
            )}
          />
        </View>
      </View>

      <ScrollView
        bounces={true}
        horizontal={false}
        keyboardShouldPersistTaps={'never'}
        nestedScrollEnabled={false}
        showsHorizontalScrollIndicator={true}
        showsVerticalScrollIndicator={true}
      >
        {/* View Body Container */}
        <View
          style={StyleSheet.applyWidth(
            { paddingLeft: '5.35%', paddingRight: '5.35%' },
            dimensions.width
          )}
        >
          {/* View Row Travel Distance */}
          <View
            style={StyleSheet.applyWidth(
              { paddingBottom: 30 },
              dimensions.width
            )}
          >
            {/* View Title */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'baseline',
                  flexDirection: 'row',
                  marginBottom: 26,
                },
                dimensions.width
              )}
            >
              {/* H1 */}
              <Text
                accessible={true}
                style={StyleSheet.applyWidth(
                  {
                    color: palettes.App['App Black 191F32'],
                    fontFamily: 'BebasNeue_400Regular',
                    fontSize: 24,
                    marginRight: 4,
                  },
                  dimensions.width
                )}
              >
                {'Preferred'}
              </Text>
              {/* H1 Accent */}
              <Text
                accessible={true}
                style={StyleSheet.applyWidth(
                  {
                    color: palettes.App['App Red EB3E26'],
                    fontFamily: 'LeckerliOne_400Regular',
                    fontSize: 22,
                  },
                  dimensions.width
                )}
              >
                {'Travel Distance'}
              </Text>
            </View>
            {/* Picker Selected Travel Range */}
            <Picker
              autoDismissKeyboard={true}
              dropDownBackgroundColor={theme.colors.background.brand}
              dropDownBorderColor={theme.colors.border.brand}
              dropDownBorderRadius={8}
              dropDownBorderWidth={1}
              dropDownTextColor={theme.colors.text.strong}
              leftIconMode={'inset'}
              mode={'native'}
              onValueChange={newPickerSelectedTravelRangeValue => {
                try {
                  setSelectedTravelRange(newPickerSelectedTravelRangeValue);
                } catch (err) {
                  console.error(err);
                }
              }}
              placeholder={'Select an option'}
              selectedIconColor={theme.colors.text.strong}
              selectedIconName={'Feather/check'}
              selectedIconSize={20}
              type={'solid'}
              iconColor={palettes.App['App Black 191F32']}
              iconSize={16}
              options={Constants['travelRanges']}
              rightIconName={'AntDesign/down'}
              style={StyleSheet.applyWidth(
                {
                  color: palettes.App['App Black 191F32'],
                  fontFamily: 'Poppins_400Regular',
                  paddingBottom: 16,
                  paddingLeft: 16,
                  paddingRight: 16,
                  paddingTop: 16,
                },
                dimensions.width
              )}
              value={selectedTravelRange}
            />
          </View>
          {/* View Row Available Schedule */}
          <View
            style={StyleSheet.applyWidth(
              { paddingBottom: 25 },
              dimensions.width
            )}
          >
            {/* View Title */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'baseline',
                  flexDirection: 'row',
                  marginBottom: 26,
                },
                dimensions.width
              )}
            >
              {/* H1 */}
              <Text
                accessible={true}
                style={StyleSheet.applyWidth(
                  {
                    color: palettes.App['App Black 191F32'],
                    fontFamily: 'BebasNeue_400Regular',
                    fontSize: 24,
                    marginRight: 4,
                  },
                  dimensions.width
                )}
              >
                {'Available'}
              </Text>
              {/* H1 Accent */}
              <Text
                accessible={true}
                style={StyleSheet.applyWidth(
                  {
                    color: palettes.App['App Red EB3E26'],
                    fontFamily: 'LeckerliOne_400Regular',
                    fontSize: 22,
                  },
                  dimensions.width
                )}
              >
                {'Schedule'}
              </Text>
            </View>
            {/* View Text Container */}
            <View>
              <Text
                accessible={true}
                style={StyleSheet.applyWidth(
                  {
                    color: palettes.App['App Text 2ndary 7F868C'],
                    fontFamily: 'Poppins_400Regular',
                  },
                  dimensions.width
                )}
              >
                {
                  'Select default days & times you will be available to teach your clients'
                }
              </Text>
            </View>
          </View>
          {/* View Row Pick Available Days */}
          <View
            style={StyleSheet.applyWidth(
              { paddingBottom: 25 },
              dimensions.width
            )}
          >
            {/* Label Container */}
            <View
              style={StyleSheet.applyWidth(
                { paddingBottom: 12 },
                dimensions.width
              )}
            >
              {/* Pick Available Days */}
              <Text
                accessible={true}
                style={StyleSheet.applyWidth(
                  {
                    color: palettes.App['App Text 2ndary 7F868C'],
                    fontFamily: 'Poppins_400Regular',
                  },
                  dimensions.width
                )}
              >
                {'Pick Available Days'}
              </Text>
            </View>
            {/* View Options Container */}
            <View
              style={StyleSheet.applyWidth(
                { flexWrap: 'wrap' },
                dimensions.width
              )}
            >
              <Utils.CustomCodeErrorBoundary>
                <SelectSchedule.Days
                  mondays={mondays}
                  setMondays={setMondays}
                  tuesdays={tuesdays}
                  setTuesdays={setTuesdays}
                  wednesdays={wednesdays}
                  setWednesdays={setWednesdays}
                  thursdays={thursdays}
                  setThursdays={setThursdays}
                  fridays={fridays}
                  setFridays={setFridays}
                  saturdays={saturdays}
                  setSaturdays={setSaturdays}
                  sundays={sundays}
                  setSundays={setSundays}
                  availableDays={availableDays}
                ></SelectSchedule.Days>
              </Utils.CustomCodeErrorBoundary>
            </View>
          </View>
          {/* View Row Pick Available Weekday Times */}
          <View
            style={StyleSheet.applyWidth(
              { paddingBottom: 25 },
              dimensions.width
            )}
          >
            {/* Label Container */}
            <View
              style={StyleSheet.applyWidth(
                { paddingBottom: 12 },
                dimensions.width
              )}
            >
              {/* Pick Available Weekday Times */}
              <Text
                accessible={true}
                style={StyleSheet.applyWidth(
                  {
                    color: palettes.App['App Text 2ndary 7F868C'],
                    fontFamily: 'Poppins_400Regular',
                  },
                  dimensions.width
                )}
              >
                {'Pick Available Weekday Times'}
              </Text>
            </View>
            {/* View Options Container */}
            <View
              style={StyleSheet.applyWidth(
                { flexWrap: 'wrap' },
                dimensions.width
              )}
            >
              <Utils.CustomCodeErrorBoundary>
                <SelectSchedule.WeekdayTimes
                  avail6amTo8am={avail6amTo8am}
                  setAvail6amTo8am={setAvail6amTo8am}
                  avail8amTo10am={avail8amTo10am}
                  setAvail8amTo10am={setAvail8amTo10am}
                  avail10amTo12pm={avail10amTo12pm}
                  setAvail10amTo12pm={setAvail10amTo12pm}
                  avail12pmTo2pm={avail12pmTo2pm}
                  setAvail12pmTo2pm={setAvail12pmTo2pm}
                  avail2pmTo4pm={avail2pmTo4pm}
                  setAvail2pmTo4pm={setAvail2pmTo4pm}
                  avail4pmTo6pm={avail4pmTo6pm}
                  setAvail4pmTo6pm={setAvail4pmTo6pm}
                  avail6pmTo8pm={avail6pmTo8pm}
                  setAvail6pmTo8pm={setAvail6pmTo8pm}
                />
              </Utils.CustomCodeErrorBoundary>
            </View>
          </View>
          {/* View Row Pick Available Weekend Times */}
          <View>
            {/* Label Container */}
            <View
              style={StyleSheet.applyWidth(
                { paddingBottom: 12 },
                dimensions.width
              )}
            >
              {/* Pick Available Weekend Times */}
              <Text
                accessible={true}
                style={StyleSheet.applyWidth(
                  {
                    color: palettes.App['App Text 2ndary 7F868C'],
                    fontFamily: 'Poppins_400Regular',
                  },
                  dimensions.width
                )}
              >
                {'Pick Available Weekend Times'}
              </Text>
            </View>
            {/* View Options Container */}
            <View
              style={StyleSheet.applyWidth(
                { flexWrap: 'wrap' },
                dimensions.width
              )}
            >
              <Utils.CustomCodeErrorBoundary>
                <SelectSchedule.WeekendTimes
                  avail6amTo8amWeekend={avail6amTo8amWeekend}
                  setAvail6amTo8amWeekend={setAvail6amTo8amWeekend}
                  avail8amTo10amWeekend={avail8amTo10amWeekend}
                  setAvail8amTo10amWeekend={setAvail8amTo10amWeekend}
                  avail10amTo12pmWeekend={avail10amTo12pmWeekend}
                  setAvail10amTo12pmWeekend={setAvail10amTo12pmWeekend}
                  avail12pmTo2pmWeekend={avail12pmTo2pmWeekend}
                  setAvail12pmTo2pmWeekend={setAvail12pmTo2pmWeekend}
                  avail2pmTo4pmWeekend={avail2pmTo4pmWeekend}
                  setAvail2pmTo4pmWeekend={setAvail2pmTo4pmWeekend}
                  avail4pmTo6pmWeekend={avail4pmTo6pmWeekend}
                  setAvail4pmTo6pmWeekend={setAvail4pmTo6pmWeekend}
                  avail6pmTo8pmWeekend={avail6pmTo8pmWeekend}
                  setAvail6pmTo8pmWeekend={setAvail6pmTo8pmWeekend}
                />
              </Utils.CustomCodeErrorBoundary>
            </View>
          </View>
        </View>
        {/* View Bottom Button Spacer */}
        <View>
          <Spacer left={8} right={8} bottom={70} top={70} />
        </View>
      </ScrollView>
      {/* View Bottom Anchor */}
      <View
        style={StyleSheet.applyWidth(
          {
            backgroundColor: palettes.App['Custom Color'],
            bottom: 0,
            paddingLeft: '5.35%',
            paddingRight: '5.35%',
            position: 'absolute',
            width: '100%',
          },
          dimensions.width
        )}
      >
        {/* View Button Container */}
        <View
          style={StyleSheet.applyWidth(
            { alignItems: 'center', marginBottom: 16, width: '100%' },
            dimensions.width
          )}
        >
          {/* Save Preferences */}
          <Button
            iconPosition={'left'}
            onPress={() => {
              const handler = async () => {
                try {
                  await pushNewSchedule(Variables, setGlobalVariableValue);
                } catch (err) {
                  console.error(err);
                }
              };
              handler();
            }}
            disabled={disableSaveButton}
            style={StyleSheet.applyWidth(
              {
                backgroundColor: palettes.App['App Red EB3E26'],
                borderRadius: 50,
                fontFamily: 'BebasNeue_400Regular',
                fontSize: 18,
                paddingBottom: 14,
                paddingTop: 14,
                textAlign: 'center',
                width: '100%',
              },
              dimensions.width
            )}
            title={'Save Preferences' ?? 'RESET PASSWORD'}
          />
          {/* View Activity Indicator */}
          <>
            {!Constants['is_loading'] ? null : (
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    backgroundColor: palettes.App['App Red EB3E26'],
                    borderRadius: 50,
                    marginTop: -50,
                    opacity: 0.8,
                    paddingBottom: 7,
                    paddingTop: 7,
                    width: '100%',
                  },
                  dimensions.width
                )}
              >
                <ActivityIndicator
                  animating={true}
                  hidesWhenStopped={true}
                  size={'small'}
                  color={palettes.App['Custom Color']}
                  style={StyleSheet.applyWidth(
                    { height: 36, width: 36 },
                    dimensions.width
                  )}
                />
              </View>
            )}
          </>
        </View>
        <Spacer bottom={34} left={0} right={0} top={0} />
      </View>

      <Modal
        supportedOrientations={['portrait', 'landscape']}
        animationType={'slide'}
        presentationStyle={'overFullScreen'}
        transparent={true}
        visible={showModal}
      >
        <View
          style={StyleSheet.applyWidth(
            {
              backgroundColor: palettes.App['App Transparent'],
              height: '100%',
              width: '100%',
            },
            dimensions.width
          )}
        >
          <Surface
            elevation={3}
            style={StyleSheet.applyWidth(
              {
                backgroundColor: palettes.App['App Blue eef6fa'],
                borderTopLeftRadius: 20,
                borderTopRightRadius: 20,
                bottom: 0,
                height: '60%',
                minHeight: 40,
                paddingLeft: '5.35%',
                paddingRight: '5.35%',
                paddingTop: 26,
                position: 'absolute',
                width: '100%',
              },
              dimensions.width
            )}
          >
            {/* Modal Title Container */}
            <View
              style={StyleSheet.applyWidth(
                { marginBottom: 15 },
                dimensions.width
              )}
            >
              <>
                {!message ? null : (
                  <Text
                    accessible={true}
                    style={StyleSheet.applyWidth(
                      {
                        alignSelf: 'center',
                        color: palettes.App['App Black 191F32'],
                        fontFamily: 'Poppins_600SemiBold',
                        fontSize: 20,
                        textAlign: 'center',
                      },
                      dimensions.width
                    )}
                  >
                    {message}
                  </Text>
                )}
              </>
            </View>
            {/* View Ok Button Container */}
            <View
              style={StyleSheet.applyWidth(
                { alignItems: 'center', marginBottom: 32, width: '100%' },
                dimensions.width
              )}
            >
              {/* Ok button */}
              <Button
                iconPosition={'left'}
                onPress={() => {
                  try {
                    setShowModal(false);
                  } catch (err) {
                    console.error(err);
                  }
                }}
                style={StyleSheet.applyWidth(
                  {
                    backgroundColor: palettes.App['App Red EB3E26'],
                    borderRadius: 50,
                    fontFamily: 'BebasNeue_400Regular',
                    fontSize: 18,
                    paddingBottom: 14,
                    paddingTop: 14,
                    textAlign: 'center',
                    width: '100%',
                  },
                  dimensions.width
                )}
                title={'OK'}
              />
              {/* View Activity Indicator */}
              <>
                {!Constants['is_loading'] ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        backgroundColor: palettes.App['App Red EB3E26'],
                        borderRadius: 50,
                        marginTop: -50,
                        opacity: 0.8,
                        paddingBottom: 7,
                        paddingTop: 7,
                        width: '100%',
                      },
                      dimensions.width
                    )}
                  >
                    <ActivityIndicator
                      animating={true}
                      hidesWhenStopped={true}
                      size={'small'}
                      color={palettes.App['Custom Color']}
                      style={StyleSheet.applyWidth(
                        { height: 36, width: 36 },
                        dimensions.width
                      )}
                    />
                  </View>
                )}
              </>
            </View>
          </Surface>
        </View>
      </Modal>
    </ScreenContainer>
  );
};

export default withTheme(JobPreferencesScreen);
