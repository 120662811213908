import React from 'react';

const resetSendRequestPackageResponse = (Variables, setGlobalVariableValue) => {
  setGlobalVariableValue({
    key: 'sendRequestPackageResponse',
    value: '',
  });
};

export default resetSendRequestPackageResponse;
