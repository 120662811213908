import React from 'react';

const resetSendDeclinePackageResponse = (Variables, setGlobalVariableValue) => {
  setGlobalVariableValue({
    key: 'sendDeclinePackageResponse',
    value: '',
  });

  setGlobalVariableValue({
    key: 'showModalDeclineSuccess',
    value: false,
  });
};

export default resetSendDeclinePackageResponse;
