import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import * as Track from '../custom-files/Track';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  Button,
  ScreenContainer,
  Spacer,
  SwitchRow,
  TextInput,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { ActivityIndicator, Modal, Text, View } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';

const LoginHelpScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [enableSendButton, setEnableSendButton] = React.useState(false);
  const [fullName, setFullName] = React.useState('');
  const [inputEmailAddress, setInputEmailAddress] = React.useState('');
  const [modalMessage, setModalMessage] = React.useState('');
  const [responseModalFail, setResponseModalFail] = React.useState(false);
  const [responseModalSuccess, setResponseModalSuccess] = React.useState(false);
  const [shouldShowErrorMessage, setShouldShowErrorMessage] =
    React.useState(false);
  const [showModalResponse, setShowModalResponse] = React.useState(false);
  const [switchRowValue, setSwitchRowValue] = React.useState(false);
  const [textAreaValue, setTextAreaValue] = React.useState('');
  const shouldEnableSendButton = () => {
    inputEmailAddress && textAreaValue && fullName && switchRowValue
      ? setEnableSendButton(true)
      : setEnableSendButton(false);
  };

  const alertAllFieldsRequired = () => {
    alert('All fields required.');
  };

  // Send a request to reset user password.
  const sendLoginHelpRequest = async (
    Variables,
    setGlobalVariableValue,
    email,
    message,
    name
  ) => {
    setGlobalVariableValue({
      key: 'is_loading',
      value: true,
    });

    try {
      const data = {
        siEmailAddress: email,
        message: message,
        siName: name,
      };

      const rawResponse = await fetch(
        `https://hook.integromat.com/bjxblx2d8e8i9d717aksifkbz4xhjpou`,
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        }
      );

      const response = await rawResponse.json();

      if (!rawResponse.ok) {
        setGlobalVariableValue({
          key: 'is_loading',
          value: false,
        });

        throw new Error(`Error! Status: ${rawResponse.status}`);
      }

      setGlobalVariableValue({
        key: 'is_loading',
        value: false,
      });

      return response;
    } catch (err) {
      console.error(`What is the error?: ${err.message}`);
      setGlobalVariableValue({
        key: 'is_loading',
        value: false,
      });

      if (err.message.includes('Unexpected')) {
        return { success: false };
      }

      return response;
    }
  };

  const processResponse = (setGlobalVariableValue, response) => {
    if (!response.success) {
      setModalMessage('Woops, something went wrong. Please try again.');
      setResponseModalSuccess(false);
      setResponseModalFail(true);
      return;
    }

    setModalMessage(
      'Your message has been sent. You should hear from us within 1 business day.'
    );
    setResponseModalSuccess(true);
    setResponseModalFail(false);
  };
  React.useEffect(() => {
    shouldEnableSendButton();
  });

  React.useEffect(() => {
    Track.onEnterScreen(
      'LoginHelpScreen',
      Variables,
      setGlobalVariableValue,
      props.navigation.navigate
    );
  }, []);

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={true}
      style={StyleSheet.applyWidth(
        { backgroundColor: '"rgba(0, 0, 0, 0)"' },
        dimensions.width
      )}
    >
      {/* Spacer Top Nav */}
      <Spacer bottom={0} left={0} right={0} top={44} />
      {/* View Main Content Container */}
      <View
        style={StyleSheet.applyWidth(
          {
            marginTop: '0%',
            paddingLeft: '5.35%',
            paddingRight: '5.35%',
            paddingTop: '6%',
          },
          dimensions.width
        )}
      >
        <Spacer bottom={8} left={8} right={8} top={'5%'} />
      </View>

      <KeyboardAwareScrollView
        enableAutomaticScroll={false}
        enableOnAndroid={false}
        enableResetScrollToCoords={false}
        keyboardShouldPersistTaps={'never'}
        showsVerticalScrollIndicator={true}
        viewIsInsideTabBar={false}
        contentContainerStyle={StyleSheet.applyWidth(
          { paddingLeft: '5.35%', paddingRight: '5.35%' },
          dimensions.width
        )}
      >
        {/* View Text Container */}
        <View
          style={StyleSheet.applyWidth(
            { marginBottom: 24, marginTop: 24 },
            dimensions.width
          )}
        >
          <Text
            accessible={true}
            style={StyleSheet.applyWidth(
              {
                color: palettes.App['App Text 2ndary 7F868C'],
                fontFamily: 'Poppins_400Regular',
              },
              dimensions.width
            )}
          >
            {
              'This app is only available for current Sunsational Swim School Instructors. If you are a current Sunsational instructor and are having trouble logging in, please send us a message for assistance.'
            }
          </Text>
        </View>
        {/* View Inputs Container */}
        <View
          style={StyleSheet.applyWidth({ marginBottom: 8 }, dimensions.width)}
        >
          {/* Text Input Full Name */}
          <TextInput
            autoCapitalize={'none'}
            autoCorrect={true}
            changeTextDelay={500}
            onChangeText={newTextInputFullNameValue => {
              try {
                setFullName(newTextInputFullNameValue);
              } catch (err) {
                console.error(err);
              }
            }}
            webShowOutline={true}
            clearTextOnFocus={true}
            editable={true}
            keyboardType={'default'}
            placeholder={'Full name'}
            returnKeyType={'next'}
            secureTextEntry={false}
            style={StyleSheet.applyWidth(
              {
                backgroundColor: palettes.App['Custom Color'],
                borderBottomWidth: 1,
                borderColor: palettes.App['App Grey Inputs E2E2E2'],
                borderLeftWidth: 1,
                borderRadius: 8,
                borderRightWidth: 1,
                borderTopWidth: 1,
                fontFamily: 'Poppins_400Regular',
                marginBottom: 16,
                paddingBottom: 16,
                paddingLeft: 16,
                paddingRight: 16,
                paddingTop: 16,
              },
              dimensions.width
            )}
            textContentType={'name'}
          />
          {/* Text Input Email */}
          <TextInput
            autoCapitalize={'none'}
            autoCorrect={true}
            changeTextDelay={500}
            onChangeText={newTextInputEmailValue => {
              try {
                setInputEmailAddress(newTextInputEmailValue);
              } catch (err) {
                console.error(err);
              }
            }}
            webShowOutline={true}
            clearTextOnFocus={true}
            editable={true}
            keyboardType={'email-address'}
            placeholder={'Email'}
            returnKeyType={'next'}
            secureTextEntry={false}
            style={StyleSheet.applyWidth(
              {
                backgroundColor: palettes.App['Custom Color'],
                borderBottomWidth: 1,
                borderColor: palettes.App['App Grey Inputs E2E2E2'],
                borderLeftWidth: 1,
                borderRadius: 8,
                borderRightWidth: 1,
                borderTopWidth: 1,
                fontFamily: 'Poppins_400Regular',
                marginBottom: 16,
                paddingBottom: 16,
                paddingLeft: 16,
                paddingRight: 16,
                paddingTop: 16,
              },
              dimensions.width
            )}
            textContentType={'emailAddress'}
          />
          {/* Enter your message */}
          <TextInput
            changeTextDelay={500}
            multiline={true}
            onChangeText={newEnterYourMessageValue => {
              try {
                setTextAreaValue(newEnterYourMessageValue);
              } catch (err) {
                console.error(err);
              }
            }}
            textAlignVertical={'top'}
            webShowOutline={true}
            autoCapitalize={'sentences'}
            autoCorrect={true}
            editable={true}
            numberOfLines={8}
            placeholder={'Enter your message'}
            placeholderTextColor={palettes.App['App Text 2ndary 7F868C']}
            style={StyleSheet.applyWidth(
              {
                backgroundColor: palettes.App['Custom Color'],
                borderBottomWidth: 1,
                borderColor: palettes.App['App Grey Inputs E2E2E2'],
                borderLeftWidth: 1,
                borderRadius: 8,
                borderRightWidth: 1,
                borderTopWidth: 1,
                fontFamily: 'Poppins_400Regular',
                height: 152,
                paddingBottom: 16,
                paddingLeft: 16,
                paddingRight: 16,
                paddingTop: 16,
              },
              dimensions.width
            )}
            value={textAreaValue}
          />
        </View>
        {/* View Switch Container */}
        <View
          style={StyleSheet.applyWidth(
            {
              backgroundColor: palettes.App['App Blue eef6fa'],
              borderRadius: 8,
              marginBottom: 32,
              paddingBottom: 10,
              paddingLeft: 10,
              paddingRight: 10,
              paddingTop: 10,
            },
            dimensions.width
          )}
        >
          <SwitchRow
            onValueChange={newSwitchRowValue => {
              try {
                setSwitchRowValue(newSwitchRowValue);
              } catch (err) {
                console.error(err);
              }
            }}
            activeTrackColor={palettes.App['App Blue#4 007fd4']}
            label={'I am a Sunsational Instructor'}
            style={StyleSheet.applyWidth(
              {
                color: palettes.App['App Brand Blue 2C9AD9'],
                fontFamily: 'Poppins_400Regular',
                marginLeft: 0,
                marginRight: 0,
              },
              dimensions.width
            )}
          />
        </View>
      </KeyboardAwareScrollView>
      {/* View Bottom Anchor */}
      <View
        style={StyleSheet.applyWidth(
          {
            bottom: 0,
            paddingLeft: '5.35%',
            paddingRight: '5.35%',
            position: 'absolute',
            width: '100%',
          },
          dimensions.width
        )}
      >
        {/* View Button Container */}
        <View
          style={StyleSheet.applyWidth(
            { alignItems: 'center', marginBottom: 32, width: '100%' },
            dimensions.width
          )}
        >
          {/* Send Help Request Disabled */}
          <>
            {enableSendButton ? null : (
              <Button
                iconPosition={'left'}
                onPress={() => {
                  try {
                    alertAllFieldsRequired();
                  } catch (err) {
                    console.error(err);
                  }
                }}
                style={StyleSheet.applyWidth(
                  {
                    backgroundColor: palettes.App['App Disabled Button EDEDEF'],
                    borderRadius: 50,
                    color: palettes.App['App Disabled Button Text 7F868C'],
                    fontFamily: 'BebasNeue_400Regular',
                    fontSize: 18,
                    paddingBottom: 14,
                    paddingTop: 14,
                    textAlign: 'center',
                    width: '100%',
                  },
                  dimensions.width
                )}
                title={'SEND HELP REQUEST' ?? 'RESET PASSWORD'}
              />
            )}
          </>
          {/* Send Help Request Enabled */}
          <>
            {!enableSendButton ? null : (
              <Button
                iconPosition={'left'}
                onPress={() => {
                  const handler = async () => {
                    try {
                      const requestResponse = await sendLoginHelpRequest(
                        Variables,
                        setGlobalVariableValue,
                        inputEmailAddress,
                        textAreaValue,
                        fullName
                      );
                      processResponse(setGlobalVariableValue, requestResponse);
                    } catch (err) {
                      console.error(err);
                    }
                  };
                  handler();
                }}
                style={StyleSheet.applyWidth(
                  {
                    backgroundColor: palettes.App['App Red EB3E26'],
                    borderRadius: 50,
                    fontFamily: 'BebasNeue_400Regular',
                    fontSize: 18,
                    paddingBottom: 14,
                    paddingTop: 14,
                    textAlign: 'center',
                    width: '100%',
                  },
                  dimensions.width
                )}
                title={'SEND HELP REQUEST' ?? 'RESET PASSWORD'}
              />
            )}
          </>
          {/* View Activity Indicator */}
          <>
            {!Constants['is_loading'] ? null : (
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    backgroundColor: palettes.App['App Red EB3E26'],
                    borderRadius: 50,
                    marginTop: -50,
                    opacity: 0.8,
                    paddingBottom: 7,
                    paddingTop: 7,
                    width: '100%',
                  },
                  dimensions.width
                )}
              >
                <ActivityIndicator
                  animating={true}
                  hidesWhenStopped={true}
                  size={'small'}
                  color={palettes.App['Custom Color']}
                  style={StyleSheet.applyWidth(
                    { height: 36, width: 36 },
                    dimensions.width
                  )}
                />
              </View>
            )}
          </>
        </View>
        <Spacer bottom={34} left={0} right={0} top={0} />
      </View>
      {/* Modal Response Success */}
      <>
        {!responseModalSuccess ? null : (
          <Modal
            supportedOrientations={['portrait', 'landscape']}
            animationType={'slide'}
            presentationStyle={'pageSheet'}
            transparent={true}
          >
            <View
              style={StyleSheet.applyWidth(
                {
                  backgroundColor: palettes.App['Custom Color'],
                  borderTopLeftRadius: 16,
                  borderTopRightRadius: 16,
                  bottom: 0,
                  height: '70%',
                  paddingLeft: '5.35%',
                  paddingRight: '5.35%',
                  paddingTop: 13,
                  position: 'absolute',
                  width: '100%',
                },
                dimensions.width
              )}
            >
              {/* View Modal Close Slide */}
              <View
                {...GlobalStyles.ViewStyles(theme)['modal close'].props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.ViewStyles(theme)['modal close'].style,
                  dimensions.width
                )}
              >
                {/* View Spacer */}
                <View
                  style={StyleSheet.applyWidth({ width: 40 }, dimensions.width)}
                />
                {/* View Slide Close */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      backgroundColor:
                        palettes.App['App Disabled Button EDEDEF'],
                      borderRadius: 40,
                      height: 3,
                      width: 40,
                    },
                    dimensions.width
                  )}
                >
                  <Touchable
                    onPress={() => {
                      try {
                        setResponseModalSuccess(false);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    style={StyleSheet.applyWidth(
                      { height: '100%', width: '100%' },
                      dimensions.width
                    )}
                  />
                </View>
                {/* View Close Button */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      backgroundColor:
                        palettes.App['App Disabled Button EDEDEF'],
                      borderRadius: 40,
                      paddingBottom: 4,
                      paddingLeft: 6,
                      paddingRight: 6,
                      paddingTop: 4,
                    },
                    dimensions.width
                  )}
                >
                  <Touchable
                    onPress={() => {
                      try {
                        setResponseModalSuccess(false);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                  >
                    {/* Close */}
                    <Text
                      accessible={true}
                      style={StyleSheet.applyWidth(
                        {
                          color: palettes.App['App Text 2ndary 7F868C'],
                          fontFamily: 'Poppins_400Regular',
                          fontSize: 12,
                        },
                        dimensions.width
                      )}
                    >
                      {'close x'}
                    </Text>
                  </Touchable>
                </View>
              </View>
              {/* View Response Message */}
              <View>
                <Text
                  accessible={true}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Text'].style,
                      {
                        color: palettes.App['App Black 191F32'],
                        fontFamily: 'Poppins_400Regular',
                        fontSize: 16,
                        textAlign: 'center',
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {modalMessage}
                </Text>
              </View>
              {/* View Button Container */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    alignSelf: 'center',
                    bottom: 0,
                    marginBottom: 32,
                    position: 'absolute',
                    width: '100%',
                  },
                  dimensions.width
                )}
              >
                {/* Ok */}
                <Button
                  iconPosition={'left'}
                  onPress={() => {
                    try {
                      setResponseModalSuccess(false);
                      navigation.navigate('Login2Screen');
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  style={StyleSheet.applyWidth(
                    {
                      backgroundColor: palettes.App['App Red EB3E26'],
                      borderRadius: 50,
                      color: palettes.App['Custom Color'],
                      fontFamily: 'BebasNeue_400Regular',
                      fontSize: 18,
                      paddingBottom: 14,
                      paddingTop: 14,
                      textAlign: 'center',
                      width: '100%',
                    },
                    dimensions.width
                  )}
                  title={'BACK TO LOGIN'}
                />
                <Spacer bottom={34} left={0} right={0} top={0} />
              </View>
            </View>
          </Modal>
        )}
      </>
      {/* Modal Response Fail */}
      <>
        {!responseModalFail ? null : (
          <Modal
            supportedOrientations={['portrait', 'landscape']}
            animationType={'slide'}
            presentationStyle={'pageSheet'}
            transparent={true}
          >
            <View
              style={StyleSheet.applyWidth(
                {
                  backgroundColor: palettes.App['Custom Color'],
                  borderTopLeftRadius: 16,
                  borderTopRightRadius: 16,
                  bottom: 0,
                  height: '70%',
                  paddingLeft: '5.35%',
                  paddingRight: '5.35%',
                  paddingTop: 13,
                  position: 'absolute',
                  width: '100%',
                },
                dimensions.width
              )}
            >
              {/* View Modal Close Slide */}
              <View
                {...GlobalStyles.ViewStyles(theme)['modal close'].props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.ViewStyles(theme)['modal close'].style,
                  dimensions.width
                )}
              >
                {/* View Spacer */}
                <View
                  style={StyleSheet.applyWidth({ width: 40 }, dimensions.width)}
                />
                {/* View Slide Close */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      backgroundColor:
                        palettes.App['App Disabled Button EDEDEF'],
                      borderRadius: 40,
                      height: 3,
                      width: 40,
                    },
                    dimensions.width
                  )}
                >
                  <Touchable
                    onPress={() => {
                      try {
                        setResponseModalFail(false);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    style={StyleSheet.applyWidth(
                      { height: '100%', width: '100%' },
                      dimensions.width
                    )}
                  />
                </View>
                {/* View Close Button */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      backgroundColor:
                        palettes.App['App Disabled Button EDEDEF'],
                      borderRadius: 40,
                      paddingBottom: 4,
                      paddingLeft: 6,
                      paddingRight: 6,
                      paddingTop: 4,
                    },
                    dimensions.width
                  )}
                >
                  <Touchable
                    onPress={() => {
                      try {
                        setResponseModalFail(false);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                  >
                    {/* Close */}
                    <Text
                      accessible={true}
                      style={StyleSheet.applyWidth(
                        {
                          color: palettes.App['App Text 2ndary 7F868C'],
                          fontFamily: 'Poppins_400Regular',
                          fontSize: 12,
                        },
                        dimensions.width
                      )}
                    >
                      {'close x'}
                    </Text>
                  </Touchable>
                </View>
              </View>
              {/* View Response Message */}
              <View>
                <Text
                  accessible={true}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Text'].style,
                      {
                        color: palettes.App['App Black 191F32'],
                        fontFamily: 'Poppins_400Regular',
                        fontSize: 16,
                        textAlign: 'center',
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {modalMessage}
                </Text>
              </View>
              {/* View Button Container */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    alignSelf: 'center',
                    bottom: 0,
                    marginBottom: 32,
                    position: 'absolute',
                    width: '100%',
                  },
                  dimensions.width
                )}
              >
                {/* Retry */}
                <Button
                  iconPosition={'left'}
                  onPress={() => {
                    const handler = async () => {
                      try {
                        const response = await sendLoginHelpRequest(
                          Variables,
                          setGlobalVariableValue,
                          inputEmailAddress,
                          textAreaValue,
                          fullName
                        );
                        processResponse(setGlobalVariableValue, response);
                      } catch (err) {
                        console.error(err);
                      }
                    };
                    handler();
                  }}
                  style={StyleSheet.applyWidth(
                    {
                      backgroundColor: palettes.App['App Red EB3E26'],
                      borderRadius: 50,
                      color: palettes.App['Custom Color'],
                      fontFamily: 'BebasNeue_400Regular',
                      fontSize: 18,
                      paddingBottom: 14,
                      paddingTop: 14,
                      textAlign: 'center',
                      width: '100%',
                    },
                    dimensions.width
                  )}
                  title={'RETRY'}
                />
                <Spacer bottom={34} left={0} right={0} top={0} />
              </View>
            </View>
          </Modal>
        )}
      </>
    </ScreenContainer>
  );
};

export default withTheme(LoginHelpScreen);
