import React from 'react';
import { Platform } from 'react-native';
import * as Calendar from 'expo-calendar';

const getDefaultCalendarSource = async () => {
  if (Platform.OS === 'ios') {
    for (const source of await getSourcesAsync()) {
      if (source.type === SourceType.CALDAV && source.name === 'iCloud')
        return source;
    }
  }

  const defaultCalendar = await Calendar.getDefaultCalendarAsync();
  return defaultCalendar.source;
};

const getCalendars = async () => {
  // console.log('Getting Calendars ====== ');
  const calendars = await Calendar.getCalendarsAsync(
    Calendar.EntityTypes.EVENT
  );
  return calendars;
};

const createCalendar = async () => {
  // console.log('Creating new calendar ===== ');
  const defaultCalendarSource =
    Platform.OS === 'ios'
      ? await getDefaultCalendarSource()
      : { isLocalAccount: true, name: 'Sunsational Swim School Calendar' };
  const newCalendarID = await Calendar.createCalendarAsync({
    title: 'Sunsational Swim School',
    color: '#EB3E26',
    entityType: Calendar.EntityTypes.EVENT,
    sourceId: defaultCalendarSource.id,
    source: defaultCalendarSource,
    name: 'Sunsational Swim School',
    ownerAccount: 'personal',
    accessLevel: Calendar.CalendarAccessLevel.OWNER,
  });
  console.log(`Your new calendar ID is: ${newCalendarID}`);
  return newCalendarID;
};

export async function addCalendarEvent(
  lessonNumber,
  clientName,
  scheduledDateTime,
  lessonDuration
) {
  const { status } = await Calendar.requestCalendarPermissionsAsync();
  if (status === 'granted') {
    try {
      const usersCalendars = await getCalendars();

      const existingSSSCalendar = await usersCalendars.reduce(
        (sssCalendar, calendar) => {
          if (calendar.title === 'Sunsational Swim School') {
            sssCalendar = calendar;
          }

          return sssCalendar;
        },
        {}
      );

      const startDate = new Date(scheduledDateTime);
      const startDateUnixTimestamp = Math.floor(startDate.getTime());
      const endDate = new Date(
        startDateUnixTimestamp + lessonDuration * 60 * 1000
      );

      const event = {
        title: `Sunsational Lesson ${lessonNumber} with ${clientName}`,
        startDate: startDate,
        endDate: endDate,
      };

      const id = existingSSSCalendar.id ?? (await createCalendar());

      const eventCalendarID = await Calendar.createEventAsync(id, event);
      alert(`This lesson has been added to your phone's calendar.`);

      return eventCalendarID;
    } catch (err) {
      alert(`Error: ${err}. Please try again.`);
      return false;
    }
  } else {
    alert(
      'Cannot access calendar. Go to phone settings to grant calendar access.'
    );
    return false;
  }
}
